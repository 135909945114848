import { useBidsPrices } from "@/contractor/pages/home/rfq-quotes/providers/BidsPricesProvider";
import { useRfqQuotes } from "@/contractor/pages/home/rfq-quotes/providers/RfqQuotesProvider";
import {
  QuoteStatus,
  RfqQuotesAuxiliaryQuoteItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useAuxItemCard = ({
  item,
  quote,
}: {
  item: RfqQuotesAuxiliaryQuoteItemFieldsFragment;
  quote: RfqQuotesQuoteFieldsFragment;
}) => {
  const {
    selectedQuotes,
    setSelectedQuotes,
    multiQuote,
    selectedAuxiliaryQuoteItems,
    setSelectedAuxiliaryQuoteItems,
  } = useRfqQuotes();
  const { selectEntireQuoteByBestPrice } = useBidsPrices();

  const isAwarded = useMemo(() => {
    return quote.status === QuoteStatus.Accepted;
  }, [quote.status]);

  const isSelected = useMemo(() => {
    return selectedAuxiliaryQuoteItems.some(
      (aux) => aux.itemId === item?.id && aux.quoteId === quote.id,
    );
  }, [item?.id, quote?.id, selectedAuxiliaryQuoteItems]);

  const selectAuxItem = useCallback(() => {
    if (item) {
      if (isSelected) {
        setSelectedAuxiliaryQuoteItems(
          selectedAuxiliaryQuoteItems.filter(
            (selected) => selected.itemId !== item.id,
          ),
        );
      } else if (multiQuote) {
        setSelectedAuxiliaryQuoteItems([
          ...selectedAuxiliaryQuoteItems,
          {
            itemId: item.id,
            quoteId: quote?.id || "",
          },
        ]);
      } else {
        const sameQuoteSelected =
          selectedQuotes.length &&
          selectedQuotes.every((qi) => qi.quoteId === quote?.id);

        const entireQuote = selectEntireQuoteByBestPrice(quote?.id);
        setSelectedAuxiliaryQuoteItems([
          ...(sameQuoteSelected
            ? [
                ...selectedAuxiliaryQuoteItems,
                {
                  itemId: item.id,
                  quoteId: quote?.id || "",
                },
              ]
            : entireQuote.auxQuoteItems),
        ]);

        setSelectedQuotes(
          sameQuoteSelected ? selectedQuotes : entireQuote.quoteItems,
        );
      }
    }
  }, [
    item,
    isSelected,
    quote?.id,
    selectedAuxiliaryQuoteItems,
    setSelectedAuxiliaryQuoteItems,
    multiQuote,
    selectEntireQuoteByBestPrice,
    setSelectedQuotes,
    selectedQuotes,
  ]);

  return {
    selectAuxItem,
    isSelected,
    isAwarded,
  };
};
