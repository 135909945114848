import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { Check, Close, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { CostCode, useCostCodesList } from "../providers/CostCodesListProvider";

type Props = {
  code: CostCode;
  readonly?: boolean;
  setEditMode: (value: boolean) => void;
};
const IconButtonContainer = tw.div`grid gap-2 grid-cols-[repeat(3,_19px)] px-1`;

export const CostCodeActions: FC<Props> = ({
  code,
  readonly = false,
  setEditMode,
}: Props) => {
  const {
    initialCodes,
    costCodes,
    setCostCodes,
    saveCostCode,
    deleteCostCode,
    loading,
  } = useCostCodesList();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();

  const onEdit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const onCancel = useCallback(() => {
    if (code.id === "") {
      setCostCodes(costCodes.filter((c) => c.id !== code.id));
    } else {
      setCostCodes(
        costCodes.map((c) =>
          c.id === code.id
            ? {
                ...c,
                ...initialCodes.find((i) => i.id === c.id),
              }
            : c,
        ),
      );
    }
    setEditMode(false);
  }, [code.id, costCodes, initialCodes, setCostCodes, setEditMode]);

  const onSave = useCallback(async () => {
    setCostCodes(
      costCodes.map((c) =>
        c.id === code.id ? { ...c, ...code, isSubmitted: true } : c,
      ),
    );
    if (code.code && code.description) {
      await saveCostCode(code.id);
      setEditMode(false);
    }
  }, [setCostCodes, costCodes, code, saveCostCode, setEditMode]);

  const onRemove = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "DELETE" }),
      includeWarningIcon: true,
      title: intl.$t({ id: "DELETE_COST_CODE" }),
      text: intl.$t({
        id: code.inUse
          ? "DELETE_COST_CODE_IN_USE_CONFIRMATION"
          : "DELETE_COST_CODE_CONFIRMATION",
      }),
      handleConfirm: async () => {
        await deleteCostCode(code.id);
        setSuccessAlert(intl.$t({ id: "COST_CODE_DELETED_SUCCESS" }));
      },
    });
  }, [code.id, code.inUse, deleteCostCode, intl, openDialog, setSuccessAlert]);

  const icons = useMemo(() => {
    if (!readonly) {
      return [
        <IconButtonBorderless
          onClick={onSave}
          key={1}
          className="col-start-2"
          loading={loading}
        >
          <Check />
        </IconButtonBorderless>,
        <IconButtonBorderless
          key={2}
          onClick={onCancel}
          className="col-start-3"
        >
          <Close />
        </IconButtonBorderless>,
      ];
    } else if (code.mappings.length > 0) {
      return [
        <IconButtonBorderless
          key={2}
          onClick={onRemove}
          className="col-start-3"
        >
          <DeleteOutline />
        </IconButtonBorderless>,
      ];
    } else {
      return [
        <IconButtonBorderless
          key={1}
          onClick={onEdit}
          loading={loading}
          className="col-start-2"
        >
          <EditOutlined />
        </IconButtonBorderless>,
        <IconButtonBorderless
          key={2}
          onClick={onRemove}
          className="col-start-3"
        >
          <DeleteOutline />
        </IconButtonBorderless>,
      ];
    }
  }, [
    readonly,
    onSave,
    loading,
    onCancel,
    code.mappings.length,
    onRemove,
    onEdit,
  ]);

  return <IconButtonContainer>{icons.map((icon) => icon)}</IconButtonContainer>;
};
