import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useImportExternalServiceCodesModal } from "../../hooks/useImportExternalServiceCodesModal";
import { useImportServiceCodesStore } from "../../stores/useImportServiceCodesStore";

export const ImportExternalServiceCodesModal = () => {
  const { modalOpened, closeModal } = useImportServiceCodesStore();
  const pages = useImportExternalServiceCodesModal();

  return (
    <WizardModal
      mode={WizardModalMode.SINGLE_PAGE}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};
