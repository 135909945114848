import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useDeliverySlipImportExternalPO } from "../../../hooks/useDeliverySlipImportExternalPO";

export const DeliverySlipImportExternalPOWizard = () => {
  const { pages, importModalOpened, closeModal } =
    useDeliverySlipImportExternalPO();

  return (
    <WizardModal
      pages={pages}
      opened={importModalOpened}
      mode={WizardModalMode.MULTIPLE_PAGES}
      onClose={closeModal}
    />
  );
};
