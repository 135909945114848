import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ProjectOrderedQuantityProvider } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import { ProjectReportType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { FC, useState } from "react";
import tw from "tailwind-styled-components";
import { SpendingReportValueType } from "../SpendingReportListItem.configuration";
import { CurrencyDisplay } from "./CurrencyDisplay";
import {
  HeaderType,
  ReportItemOrderedInvoicedModal,
  ReportType,
} from "./report-item-orders-modal/ReportItemOrderedInvoicedModal";

const LinkLikeStyled = tw(LinkLike)`font-medium text-xs`;

type Props = {
  item: ProjectReportType;
  valueType: SpendingReportValueType;
};

export const SpendingReportValue: FC<Props> = ({ item, valueType }) => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      <LinkLikeStyled
        onClick={() => {
          setModalOpened(true);
        }}
      >
        <CurrencyDisplay
          value={
            (valueType === SpendingReportValueType.ORDERED
              ? item.ordered
              : item.invoiced) ?? "0.00"
          }
        />
      </LinkLikeStyled>
      <If isTrue={modalOpened}>
        <ProjectOrderedQuantityProvider item={item}>
          <ReportItemOrderedInvoicedModal
            opened={true}
            close={() => setModalOpened(false)}
            reportType={ReportType.Material}
            headerType={HeaderType.Total}
            itemCostCodeId={item.costCodeId}
          />
        </ProjectOrderedQuantityProvider>
      </If>
    </>
  );
};
