import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { GridViewToggle } from "@/common/components/grid-view-toggle/GridViewToggle";
import { If } from "@/common/components/if/If";
import { SpreadsheetSaveType } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useProject } from "../providers/ProjectProvider";
import { useSyncEstimatedItems } from "../providers/SyncEstimatedItemsProvider";
import { ProjectAddItems } from "./project-add-items/ProjectAddItems";
import { UploadMaterialsDrawer } from "./upload-materials/UploadMaterialsDrawer";

const FooterContent = tw.div`grid grid-cols-[80px_1fr_auto] gap-4 w-full h-10 items-center`;
const SwitchContainer = tw.div`flex justify-start`;

export const ProjectContentFooter = () => {
  const { syncEstimatedItems, saving } = useSyncEstimatedItems();
  const { viewState, setViewState } = useTableViewStore(
    useShallow((state) => ({
      viewState: state.viewState,
      setViewState: state.setViewState,
    })),
  );
  const { project } = useProject();

  useEffect(() => {
    if (project?.estimatedItemGroups.length === 0) {
      setViewState(TableViewState.spreadsheet);
    }
  }, [project, setViewState]);

  if (!project) {
    return null;
  }

  return (
    <FloatingFooter>
      <FooterContent>
        <ProjectAddItems project={project} />
        <span />
        <SwitchContainer>
          <GridViewToggle saveState={syncEstimatedItems} saving={saving} />
        </SwitchContainer>
        <If isTrue={viewState === TableViewState.spreadsheet}>
          <PrimaryButton
            onClick={() => syncEstimatedItems(SpreadsheetSaveType.SaveButton)}
          >
            <FormattedMessage id="SAVE_CHANGES" />
          </PrimaryButton>
        </If>
      </FooterContent>
      <UploadMaterialsDrawer project={project} />
    </FloatingFooter>
  );
};
