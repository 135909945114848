import { SpecifyCostCode } from "@/common/components/specify-cost-code/SpecifyCostCode";
import { ProjectCostCodeFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";

type Props = {
  costCode?: ProjectCostCodeFieldsFragment | null | undefined;
};

export const SpecifyCostCodeExpandable: FC<Props> = ({ costCode }) => {
  const intl = useIntl();

  if (!costCode) {
    return null;
  }
  return (
    <ExpandablePanel
      header={intl.$t({ id: "SPECIFIED_COST_CODE" })}
      isOpened={true}
    >
      <SpecifyCostCode costCode={costCode} />
    </ExpandablePanel>
  );
};
