import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useStartupDataUpdate } from "@/common/stores/hooks/useStartupDataUpdate";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ExternalPoFieldsFragment,
  useImportCostCodesMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useImportExternalCostCodes = () => {
  const { connectedSourceSystem } = useOrgSettings();
  const { refetchStartupData } = useStartupDataUpdate();
  const { setError } = useGlobalError();

  const [importCostCodesMutation, { loading: importingExternalCostCodes }] =
    useImportCostCodesMutation();
  const importExternalCostCodes = useCallback(
    async (externalPO: ExternalPoFieldsFragment) => {
      try {
        if (
          (externalPO?.itemGroups.missingCostCode ?? []).length > 0 &&
          connectedSourceSystem
        ) {
          const externalCostCodeIds = (
            externalPO?.itemGroups.missingCostCode ?? []
          )
            .filter((c) => !!c.externalCostCode?.externalId)
            .map((c) => c.externalCostCode?.externalId);
          const result = await importCostCodesMutation({
            variables: {
              input: {
                sourceSystem: connectedSourceSystem,
                externalCostCodeIds: externalCostCodeIds as string[],
              },
            },
          });
          if (result.data?.importCostCodes) {
            refetchStartupData();
          }
        }
      } catch (error) {
        setError(error);
      }
    },
    [
      connectedSourceSystem,
      importCostCodesMutation,
      setError,
      refetchStartupData,
    ],
  );

  return { importExternalCostCodes, importingExternalCostCodes };
};
