import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { defaultTableItemSelectionClassNames } from "@/common/components/searchable-list/utils";
import { ObjectType, useSortingStore } from "@/common/stores/useSortingStore";
import { routes } from "@/config/routes";
import {
  QueryInvoicesSortBy,
  ReceiptSummaryFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useReceipts } from "../../providers/ReceiptsProvider";
import {
  ReceiptSequenceData,
  useReceiptSequence,
} from "../../providers/ReceiptsSequenceProvider";
import { useReceiptsConfiguration } from "./Receipts.configuration";

type Props = {
  receipts: ReceiptSummaryFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isFiltered: boolean;
};

export const ReceiptsList: FC<Props> = ({
  receipts,
  loading,
  totalCount,
  isFiltered,
}) => {
  const { exportEnabled } = useReceipts();
  const { sequenceIds, selectedReceipts, setSelectedReceipts } =
    useReceiptSequence();
  const configuration = useReceiptsConfiguration();
  const navigate = useNavigate();
  const setSortEntity = useSortingStore((state) => state.setSortEntity);

  useEffect(() => {
    setSortEntity({
      objectType: ObjectType.Receipt,
      field: QueryInvoicesSortBy.CreatedAt,
      descending: true,
    });
  }, [setSortEntity]);

  const openReceipt = useCallback(
    (receipt: ReceiptSummaryFieldsFragment) => {
      if (exportEnabled) {
        const isIncluded = sequenceIds.includes(receipt.id);
        setSelectedReceipts(
          !isIncluded
            ? ([...selectedReceipts, receipt] as ReceiptSequenceData[])
            : selectedReceipts.filter((i) => i.id !== receipt.id),
        );
      } else {
        const path = generatePath(routes.processReceipt, {
          receiptId: receipt.id,
        });
        navigate(path);
      }
    },
    [
      exportEnabled,
      navigate,
      selectedReceipts,
      sequenceIds,
      setSelectedReceipts,
    ],
  );
  const itemFn = useCallback(
    (item: ReceiptSummaryFieldsFragment) => {
      if (item.archivedAt) {
        return {
          className: "bg-gray-300 opacity-50",
        };
      }
      if (exportEnabled && sequenceIds.includes(item.id)) {
        return { className: defaultTableItemSelectionClassNames };
      }
      return { className: "" };
    },
    [exportEnabled, sequenceIds],
  );

  return (
    <ListRenderer totalCount={totalCount} count={receipts.length} paginated>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            itemContent: "grid-cols-[50px_repeat(5,_auto)]",
            header: "top-[80px] xl:top-[90px]",
            root: "pt-2",
            itemFn,
          },
          hasCardItems: true,
          toggle: {
            item: openReceipt,
          },
        }}
        items={receipts}
        loading={loading}
        sortObjectType={ObjectType.Receipt}
        emptyList={
          <NoResults
            isFiltered={isFiltered}
            filteredTranslationKey="NO_RESULTS_RECEIPTS_FILTERED"
            translationKey="NO_RESULTS_RECEIPTS"
          />
        }
      />
    </ListRenderer>
  );
};
