import { PaginationArgs } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  readValueFromKeys,
  removeValue,
  setValue,
} from "@/common/utils/localStorage";
import { useScheduleRelease } from "@/contractor/pages/home/release/hooks/useScheduleRelease";
import {
  CancelReleaseInput,
  QueryReleasesFilter,
  ReleasePartialFieldsFragment,
  ReleasesDocument,
  ScheduleReleaseInput,
  useArchiveReleaseMutation,
  useCancelReleaseMutation,
  useUnarchiveReleasesMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useDeliveriesWithPagination } from "../hooks/useDeliveriesWithPagination";

type ProviderContextType = {
  deliveries: ReleasePartialFieldsFragment[];
  filter?: QueryReleasesFilter | undefined;
  setFilter: (filter: QueryReleasesFilter | undefined) => void;
  loading: boolean;
  error: boolean;
  totalCount: number;
  archiveRelease: (id: string) => Promise<boolean>;
  cancelRelease: (input: CancelReleaseInput) => Promise<boolean>;
  unarchiveRelease: (ids: string) => Promise<boolean>;
  isFiltered: boolean;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  scheduleRelease: (input: ScheduleReleaseInput) => Promise<boolean>;
  refetch: () => void;
  paginationArgs: PaginationArgs;
  page: number | undefined;
};

export const DEFAULT_RELEASES_FILTER: QueryReleasesFilter = {
  deleted: false,
  closedProjects: false,
};

const ProviderContext = createContext<ProviderContextType>({
  deliveries: [],
  filter: undefined,
  setFilter: NoFunction,
  loading: false,
  error: false,
  totalCount: 0,
  archiveRelease: NoFunctionBooleanPromise,
  cancelRelease: NoFunctionBooleanPromise,
  unarchiveRelease: NoFunctionBooleanPromise,
  isFiltered: false,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  scheduleRelease: NoFunctionBooleanPromise,
  refetch: NoFunction,
  paginationArgs: {},
  page: 0,
});

export const DeliveriesProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { setError } = useGlobalError();
  const { orgId } = useStartupDataStore(
    useShallow((state) => ({
      orgId: state.viewer?.org.id,
    })),
  );

  const [filter, setFilter] = useState<QueryReleasesFilter | undefined>(
    undefined,
  );

  const { deliveries, loading, error, totalCount, pagination, refetch, page } =
    useDeliveriesWithPagination(filter, { skip: !filter });
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);

  useErrorEffect(error);

  useEffect(() => {
    const localStorageSettings = readValueFromKeys<QueryReleasesFilter>([
      [orgId || "", LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER],
      LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER,
    ]) as QueryReleasesFilter;
    setFilter({
      ...DEFAULT_RELEASES_FILTER,
      ...(localStorageSettings || {}),
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, orgId]);

  const { scheduleReleaseMutation } = useScheduleRelease();
  const scheduleRelease = async (input: ScheduleReleaseInput) => {
    const result = await scheduleReleaseMutation(input);
    if (result) {
      refetch();
    }
    return result;
  };

  const [archiveDeliveryMutation] = useArchiveReleaseMutation();
  const archiveRelease = async (id: string) => {
    try {
      const { errors } = await archiveDeliveryMutation({
        variables: {
          id,
        },
        refetchQueries: [
          { query: ReleasesDocument, variables: { ...pagination, filter } },
        ],
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const [unarchiveReleasesMutation] = useUnarchiveReleasesMutation();
  const unarchiveRelease = async (id: string) => {
    try {
      const { errors } = await unarchiveReleasesMutation({
        variables: {
          ids: [id],
        },
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const [cancelReleaseMutation] = useCancelReleaseMutation();
  const cancelRelease = async (input: CancelReleaseInput) => {
    try {
      const { errors } = await cancelReleaseMutation({
        variables: {
          input,
        },
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const setFilterAndUpdateQueryString = (
    updatedFilter: QueryReleasesFilter | undefined,
  ) => {
    setFiltersQueryParams(updatedFilter);
    removeValue(LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER);
    setValue(
      [orgId || "", LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER],
      updatedFilter,
    );
    setFilter(updatedFilter);
  };

  return (
    <ProviderContext.Provider
      value={{
        deliveries,
        isFiltered: !!filter?.projectIds || !!filter?.statuses,
        loading,
        error: !!error,
        totalCount,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        archiveRelease,
        cancelRelease,
        unarchiveRelease,
        exportEnabled,
        setExportEnabled,
        scheduleRelease,
        refetch,
        paginationArgs: pagination,
        page,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliveries = (): ProviderContextType =>
  useContext(ProviderContext);
