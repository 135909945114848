import { Select } from "@/common/components/select/components/single/Select";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useSpendingReport } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";

const ALL_VENDORS = "-1";

type GroupedVendor = {
  id: string;
  org: OrgPreferredVendorsFieldsFragment["sellerOrgLocation"]["org"];
};

export const SpendingReportVendorsSelector: FC = () => {
  const { sellerOrgIds, setSellerOrgIds } = useSpendingReport();
  const { vendors } = useVendors();
  const intl = useIntl();
  const groupedVendors = useMemo(() => {
    const list: GroupedVendor[] = [
      {
        id: ALL_VENDORS,
        org: {
          id: ALL_VENDORS,
          name: intl.$t({ id: "ALL_VENDORS" }),
        },
      },
    ];
    vendors.forEach((vendor) => {
      if (!list.find((item) => item.id === vendor.sellerOrgLocation.org.id)) {
        list.push({
          id: vendor.sellerOrgLocation.org.id,
          org: vendor.sellerOrgLocation.org,
        });
      }
    });
    return list;
  }, [intl, vendors]);

  return (
    <Select
      options={groupedVendors || []}
      value={sellerOrgIds?.[0] ?? ALL_VENDORS}
      onChange={(value) =>
        setSellerOrgIds(value === ALL_VENDORS ? [] : [value as string])
      }
      className="w-full"
      getLabel={(option) => option.org.name}
      getValue={(option) => option.id}
    />
  );
};
