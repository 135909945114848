import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import {
  ZoneExtendedFieldsFragment,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import {
  CloseOutlined,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import { FormControl } from "@mui/material";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  Actions,
  ItemContainer,
  ListItem,
} from "../../../project-form-with-stepper/ProjectFormWithStepper.styles";

const SourceSystemLogo = tw.img`mr-2 w-6 h-6`;

type Props = {
  zone: ZoneExtendedFieldsFragment;
  key: number;
  zoneId: string | null | undefined;
  disabled: boolean;
  readonly: boolean | undefined;
  updateZoneName: string;
  setUpdateZoneName: (id: string) => void;
  saveUpdatedZoneName: () => void;
  setUpdateName: (id: string) => void;
  setDeleteZone: (zone: ZoneFieldsFragment) => void;
  reset: () => void;
};

export const ProjectZone = ({
  zone,
  key,
  zoneId,
  readonly,
  disabled,
  setUpdateZoneName,
  saveUpdatedZoneName,
  setUpdateName,
  setDeleteZone,
  updateZoneName,
  reset,
}: Props) => {
  const intl = useIntl();

  const { sourceSystemLogo } = useSourceSystemLogo(
    zone.externalSubJob?.sourceSystem,
    true,
  );

  return (
    <ListItem key={key}>
      <ItemContainer>
        <If isTrue={zone.id !== zoneId}>
          {zone.name}
          <If isTrue={!readonly && zone.id !== zoneId}>
            <Actions>
              {zone.externalSubJob ? (
                <SourceSystemLogo
                  loading="lazy"
                  src={sourceSystemLogo}
                  alt="logo"
                />
              ) : (
                <LinkLike onClick={() => setUpdateName(zone.id)}>
                  <EditOutlined />
                </LinkLike>
              )}
              <LinkLike onClick={() => setDeleteZone(zone)}>
                <DeleteOutline />
              </LinkLike>
            </Actions>
          </If>
          <If isTrue={zone.id === zoneId}>
            <Actions>
              <LinkLike onClick={reset}>
                <CloseOutlined />
              </LinkLike>
            </Actions>
          </If>
        </If>
        <If isTrue={zone.id === zoneId}>
          <FormControl fullWidth>
            <TextfieldWithActions
              label={intl.$t({ id: "UPDATE_ZONE_NAME" })}
              value={updateZoneName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setUpdateZoneName(event.target.value)
              }
              disableSave={disabled || updateZoneName === ""}
              onClickSave={saveUpdatedZoneName}
              onClickClose={reset}
              showActions={true}
            />
          </FormControl>
        </If>
      </ItemContainer>
    </ListItem>
  );
};
