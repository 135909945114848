import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { usePoValidationMappings } from "@/contractor/pages/home/common/hooks/usePoValidationMappings";
import { MissingBudgetCombinationType } from "@/contractor/pages/home/release/components/connections/providers/MissingBudgetCombinationsProvider";
import { MaterialType } from "@/contractor/pages/home/release/components/connections/providers/MissingItemsFromInventoryProvider";
import {
  BuyoutFieldsFragment,
  PoFormat,
  PoMaterialConversionFieldsFragment,
  SourceSystem,
  usePoValidationLazyQuery,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";

type Input = {
  poNumber: string;
  buyout: BuyoutFieldsFragment;
  openMissingConversionsModal: (args: {
    sourceSystem?: SourceSystem;
    conversions: PoMaterialConversionFieldsFragment[];
  }) => void;
  openMissingItemsModal: (args: {
    sourceSystem: SourceSystem;
    materials: MaterialType[];
  }) => void;
  openMissingBudgetCombinationsModal: (args: {
    sourceSystem?: SourceSystem;
    missingBudgetCombinations: MissingBudgetCombinationType[];
    projectId: string | undefined;
  }) => void;
};

export const useBuyoutPoValidation = ({
  poNumber,
  buyout,
  openMissingConversionsModal,
  openMissingItemsModal,
  openMissingBudgetCombinationsModal,
}: Input) => {
  const { connectedSourceSystem } = useOrgSettings();
  const [
    validatePO,
    { data: poValidationData, loading: loadingBuyoutPoValidation },
  ] = usePoValidationLazyQuery();

  const validateBuyoutPo = useCallback(() => {
    if (connectedSourceSystem) {
      validatePO({
        variables: {
          input: {
            sourceSystem: connectedSourceSystem,
            poNumber,
            autoSync: false,
            format: PoFormat.Basic,
            buyoutId: buyout.id,
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [connectedSourceSystem, poNumber, buyout.id, validatePO]);

  useEffect(() => {
    validateBuyoutPo();
  }, [validateBuyoutPo]);

  const buyoutPoValidation = usePoValidationMappings({
    type: "Buyout",
    item: buyout,
    poValidation: poValidationData?.poValidation,
    sourceSystem: connectedSourceSystem,
    openMissingItemsModal,
    openMissingConversionsModal,
    openMissingBudgetCombinationsModal,
  });

  return {
    validateBuyoutPo,
    buyoutPoValidation,
    loadingBuyoutPoValidation,
    isBuyoutPoValidated:
      !loadingBuyoutPoValidation &&
      (poValidationData?.poValidation.errors ?? []).length === 0,
  };
};
