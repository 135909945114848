import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ImportProjectTagsInput,
  ImportProjectTagsMutation,
  useImportProjectTagsMutation,
} from "@/generated/graphql";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../store/useProjectStore";

type Output = {
  importProjectTags: (
    input: ImportProjectTagsInput,
  ) => Promise<ImportProjectTagsMutation["importProjectTags"] | undefined>;
  importingProjectTags: boolean;
};

export const useImportProjectTags = (): Output => {
  const { setError } = useGlobalError();
  const [importProjectTagsMutation, { loading: importingProjectTags }] =
    useImportProjectTagsMutation();
  const { updateStoreData } = useProjectStore(
    useShallow((state) => ({
      updateStoreData: state.updateStoreData,
    })),
  );

  const importProjectTags = async (input: ImportProjectTagsInput) => {
    try {
      const { data } = await importProjectTagsMutation({
        variables: {
          input,
        },
      });
      if (data?.importProjectTags) {
        updateStoreData({ tags: data.importProjectTags.tags });
      }
      return data?.importProjectTags;
    } catch (error) {
      setError(error);
    }
  };
  return {
    importProjectTags,
    importingProjectTags,
  };
};
