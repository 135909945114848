import { LocalPaginationProvider } from "@/common/components/pagination-local/LocalPaginationProvider";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { Switch } from "@/common/components/switch/Switch";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalTaxCodes } from "../../../hooks/useExternalTaxCodes";
import { useImportExternalTaxCodes } from "../../../providers/ImportExternalTaxCodesProvider";
import { useImportExternalTaxCodesTableConfiguration } from "./ImportExternalTaxCodesTable.configuration";

const SearchBarTitleComponent = tw.div`flex flex-col pr-2`;
const ToggleText = tw.div`flex flex-row font-normal text-sm items-center mt-1`;

const ImportExternalTaxCodesTableWithProviders = () => {
  const intl = useIntl();
  const {
    sourceSystem,
    selectedExternalTaxCodes,
    setSelectedExternalTaxCodes,
    setTotalExternalTaxCodesCount,
    importAllExternalItems,
    setImportAllExternalItems,
  } = useImportExternalTaxCodes();
  const { fetchExternalTaxCodes, externalTaxCodes, loading } =
    useExternalTaxCodes();
  const configuration = useImportExternalTaxCodesTableConfiguration(
    selectedExternalTaxCodes,
  );

  useEffect(() => {
    if (sourceSystem) {
      fetchExternalTaxCodes(sourceSystem);
    }
  }, [fetchExternalTaxCodes, sourceSystem]);

  useEffect(() => {
    setTotalExternalTaxCodesCount(externalTaxCodes.length);
  }, [externalTaxCodes, setTotalExternalTaxCodesCount]);

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      items={externalTaxCodes}
      loadingItems={loading}
      searchKeys={["code", "description"]}
      searchBarTitle={
        <SearchBarTitleComponent>
          <FormattedMessage id="SELECT_TAX_CODES_TO_IMPORT" />
          <ToggleText>
            <FormattedMessage
              id="IMPORT_ALL_TAX_CODES_FROM_SYSTEM"
              values={{
                system: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
              }}
            />
            <Switch
              className="ml-2"
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              value={importAllExternalItems}
              onChange={(value) => {
                setImportAllExternalItems(value);
                setSelectedExternalTaxCodes(
                  value ? externalTaxCodes.map((i) => i.id) : [],
                );
              }}
            />
          </ToggleText>
        </SearchBarTitleComponent>
      }
      itemSelection={{
        type: "multiple",
        selectedItemIds: selectedExternalTaxCodes,
        setSelectedItemIds: setSelectedExternalTaxCodes,
      }}
    />
  );
};

export const ImportExternalTaxCodesTable: FC = () => {
  return (
    <LocalPaginationProvider>
      <ImportExternalTaxCodesTableWithProviders />
    </LocalPaginationProvider>
  );
};
