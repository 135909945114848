import Decimal from "decimal.js";
import { FC, useCallback, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { OutlinedButton } from "../../../../../../../../../common/components/button/OutlinedButton";
import { useExpandableFooterStore } from "../../../../../../../../../common/components/footer/stores/useExpandableFooterStore";
import { If } from "../../../../../../../../../common/components/if/If";
import { InvoiceFooterState } from "../../../../../../../../../common/components/invoices/invoice-details/types/InvoiceFooterState";
import { ExclamationCircleRed } from "../../../../../../../../../common/components/release-additional-charges-and-taxes/ReleaseAdditionalCharges.styles";
import { ReleaseAdditionalChargesAndTaxes } from "../../../../../../../../../common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "../../../../../../../../../common/components/sales-tax-input/hooks/useTaxCalculation";
import { Tooltip } from "../../../../../../../../../common/components/tooltip/Tooltip";
import { vendorLabelFormatter } from "../../../../../../../../../common/components/vendor-picker/VendorPickerCustomRender";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "../../../../../../../../../generated/graphql";
import { DashedDivider } from "../../../../../../receipts/pages/receipt-record-order/components/order/itemized/DashedDivider";
import { ContractorReleaseAdditionalChargesAndTaxes } from "../../../../../../release/components/contractor-release-additional-charges-and-taxes/ContractorReleaseAdditionalChargesAndTaxes";
import { usePriceCalculation } from "../../../../../../release/hooks/usePriceCalculation";
import { useRelease } from "../../../../../../release/providers/ReleaseProvider";
import { useInvoiceImportExternalPO } from "../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceTotals } from "../../../hooks/useInvoiceTotals";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceUpdateRelease } from "../../../providers/InvoiceUpdateReleaseProvider";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";
import {
  Column,
  ContentColumn,
  PanelTitle,
  QuarterPanel,
  TitleRow,
} from "./InvoiceExpandable.styled";

const TotalDivider = tw.div`h-2`;

type Props = {
  form: UseFormReturn<InvoiceCreateReleaseFormValues>;
};

export const ReleasePanel: FC<Props> = ({ form: { watch, setValue } }) => {
  const { release } = useRelease();
  const { invoice, setFooterState } = useInvoiceVerification();
  const { matchedOrderViewState, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const { itemized } = useInvoiceUpdateRelease();
  const { importedPoItemized, importedPoIsImmutable } =
    useInvoiceImportExternalPO();
  const { invoiceSubtotal } = useInvoiceTotals({ release });
  const { getTaxAmount } = useTaxCalculation();
  const { calcAdditionalChargesPrice, calcTotalPrice } = usePriceCalculation();
  const { setExpanded } = useExpandableFooterStore();

  const customTaxAmount = watch("customTaxAmount");
  const orderTypeId = watch("orderTypeId");
  const taxRate = watch("taxRate");
  const netAmount = watch("netAmount");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const chargesAmount = watch("additionalCharges");
  const taxVariance = watch("taxVariance");

  const taxAmount = getTaxAmount(taxRate, customTaxAmount, netAmount);

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      netAmount,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(chargesAmount),
      taxVariance,
    }),
    [
      calcAdditionalChargesPrice,
      chargesAmount,
      customTaxAmount,
      orderTypeId,
      netAmount,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
      taxVariance,
    ],
  );

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue(
          "customTaxAmount",
          values.customTaxAmount || release?.taxAmount || "0",
        );
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.paymentTerm || values.paymentTerm === 0) {
        setValue("paymentTerm", values.paymentTerm.toString());
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }

      return true;
    },
    [customTaxAmount, release?.taxAmount, setValue],
  );

  const total = useMemo(
    () =>
      calcTotalPrice({
        netAmount,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
        additionalCharges: chargesAmount,
      }),
    [
      calcTotalPrice,
      netAmount,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      chargesAmount,
    ],
  );

  const itemsCount = useMemo(
    () => ({
      partial: release?.items.filter((i) => i.unitPrice !== null).length || 0,
      total: release?.items.length || 0,
    }),
    [release?.items],
  );

  const lessInvoiceTotal = useMemo(() => {
    return new Decimal(invoiceSubtotal || 0)
      .toDP(2)
      .greaterThan(new Decimal(release?.netAmount || 0).toDP(2));
  }, [invoiceSubtotal, release?.netAmount]);

  return (
    <QuarterPanel>
      <Column>
        <If
          isTrue={
            invoice?.release ||
            matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER
          }
        >
          <TitleRow>
            <FormattedMessage id="ORDER" tagName={PanelTitle} />
            <If
              isTrue={
                matchedOrderViewState !== MatchedOrderViewState.EDIT_ORDER &&
                matchedOrderViewState !== MatchedOrderViewState.CREATE_ORDER &&
                matchedOrderViewState !== MatchedOrderViewState.MATCH_ORDER &&
                !release?.poLink?.immutable
              }
            >
              <OutlinedButton
                className="h-6 min-w-14"
                $small
                onClick={() => {
                  setMatchedOrderViewState(MatchedOrderViewState.EDIT_ORDER);
                  setFooterState(InvoiceFooterState.EDIT_ORDER);
                }}
              >
                <FormattedMessage id="EDIT" />
              </OutlinedButton>
            </If>
            <If
              isTrue={
                matchedOrderViewState !== MatchedOrderViewState.MATCH_ORDER &&
                matchedOrderViewState !==
                  MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
                matchedOrderViewState !== MatchedOrderViewState.EDIT_ORDER
              }
            >
              <OutlinedButton
                className="h-6 min-w-14"
                $small
                onClick={() => {
                  setMatchedOrderViewState(
                    MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
                  );
                  setFooterState(InvoiceFooterState.EDIT_INVOICE_COVERAGES);
                  setExpanded(false);
                }}
              >
                <FormattedMessage id="ADJUST_INVOICE_COVERAGE" />
              </OutlinedButton>
            </If>
          </TitleRow>
        </If>
      </Column>
      <ContentColumn>
        <If
          isTrue={
            (matchedOrderViewState === MatchedOrderViewState.DEFAULT ||
              matchedOrderViewState ===
                MatchedOrderViewState.EDIT_INVOICE_COVERAGES ||
              matchedOrderViewState === MatchedOrderViewState.FULFILLED) &&
            invoice?.release
          }
        >
          <ReleaseAdditionalChargesAndTaxes
            release={release}
            total={release?.total}
            includeNotesPanel={false}
            includePaymentTerms={true}
            itemsCount={itemsCount}
            taxExempt={{
              isProjectTaxExempt: release?.project?.taxExempt,
              isVendorTaxExempt: release?.preferredVendor?.taxExempt,
              vendorName: vendorLabelFormatter(release?.sellerOrgLocation),
            }}
            totalTooltip={
              <If isTrue={lessInvoiceTotal}>
                <Tooltip
                  id="INVOICED_TOTAL_WARNING_TOOLTIP"
                  element={<ExclamationCircleRed />}
                >
                  <FormattedMessage id="ORDER_TOTAL_TOOLTIP" />
                </Tooltip>
              </If>
            }
          />
        </If>
        <If
          isTrue={matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER}
        >
          <ContractorReleaseAdditionalChargesAndTaxes release={release} />
        </If>
        <If
          isTrue={
            matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER ||
            matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
          }
        >
          <If
            isTrue={
              importedPoItemized ||
              matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
            }
          >
            <ReleaseAdditionalChargesAndTaxes
              total={watch("total")}
              releaseInput={releaseInput}
              customPaymentTerm={watch("paymentTerm")}
              editableByContractor={!importedPoIsImmutable}
              updateRelease={(values) =>
                handleUpdateRelease({ ...values, version: -1 })
              }
              includePaymentTerms
              editablePaymentTerms
              additionalCharges={
                watch("additionalCharges") as AdditionalChargesFieldsFragment[]
              }
              totalDivider={<TotalDivider />}
            />
          </If>
          <If
            isTrue={
              !importedPoItemized &&
              matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER
            }
          >
            <ReleaseAdditionalChargesAndTaxes
              readonly
              total={`${total}`}
              releaseInput={releaseInput}
              editableByContractor
              includeSubtotal
              includePaymentTerms
              customPaymentTerm={watch("paymentTerm")}
              includeAdditionalCharges
              updateRelease={(values) =>
                handleUpdateRelease({ ...values, version: -1 })
              }
              additionalCharges={
                watch("additionalCharges") as AdditionalChargesFieldsFragment[]
              }
            />
          </If>
        </If>
        <If isTrue={matchedOrderViewState === MatchedOrderViewState.EDIT_ORDER}>
          <ReleaseAdditionalChargesAndTaxes
            total={total.toString()}
            releaseInput={releaseInput}
            editableByContractor
            editableAdditionalCharges={itemized}
            editablePaymentTerms={itemized}
            includePaymentTerms={itemized}
            includeAdditionalCharges={itemized}
            release={itemized ? release : undefined}
            updateRelease={(values) =>
              handleUpdateRelease({
                ...values,
                releaseId: release?.id ?? "",
                version: release?.version ?? -1,
              })
            }
            additionalCharges={
              chargesAmount as AdditionalChargesFieldsFragment[]
            }
            hideTotalBorder={!itemized}
            totalDivider={!itemized ? <DashedDivider /> : undefined}
            includeSubtotal={itemized}
            classes={{
              total: "mt-2",
            }}
          />
        </If>
      </ContentColumn>
    </QuarterPanel>
  );
};
