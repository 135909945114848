import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterItemInputContainer,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

type Props = {
  includeAdditionalCharges?: boolean;
};

const PaymentTermsText = tw.div`font-light`;

export const BuyoutPaymentFooter: FC<Props> = ({
  includeAdditionalCharges,
}) => {
  const { buyout } = useContractorBuyout();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  if (!canViewPrices) {
    return null;
  }
  return (
    <FooterCol>
      <If isTrue={includeAdditionalCharges}>
        <FooterItemInputContainer />
      </If>
      <FooterHeader />
      <FooterCell />
      <FooterCell />
      <FooterDashedLine />
      <FooterTotal className="justify-start">
        <FooterCellCol className="ml-5" data-testid="paymentTerms">
          <FormattedMessage id="PAYMENT_TERMS" tagName={FooterHeaderText} />
          <NotNullableRenderer value={buyout?.paymentTermDays}>
            <FormattedMessage
              id="PAYMENT_TERM_NET_WITH_DAYS"
              values={{ days: buyout?.paymentTermDays }}
              tagName={PaymentTermsText}
            />
          </NotNullableRenderer>
        </FooterCellCol>
      </FooterTotal>
    </FooterCol>
  );
};
