import { gql } from "@apollo/client";
import { AGGREGATED_COST_CODE_FIELDS } from "./aggregatedCostCodeFragment";
import { ZONE_FIELDS } from "./zoneFragment";

export const AGGREGATED_ZONE_FIELDS = gql`
  ${AGGREGATED_COST_CODE_FIELDS}
  ${ZONE_FIELDS}
  fragment AggregatedZoneFields on AggregatedZone {
    zone {
      ...ZoneFields
    }
    costCodes {
      ...AggregatedCostCodeFields
    }
  }
`;
