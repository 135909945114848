type POLinkType = {
  release?: {
    id: string;
    sequenceNumber?: number | null;
    deletedAt?: number | null;
  } | null;
};

export const usePOLinksCount = (poLinks?: POLinkType[] | null) => {
  if (!poLinks) {
    return 0;
  }
  return poLinks.reduce((acc, poLink) => {
    return acc + (poLink.release?.deletedAt ? 0 : 1);
  }, 0);
};
