import { ConnectionIcon } from "@/common/components/connection-icon/ConnectionIcon";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import {
  ExternalItemFieldsFragment,
  OrgMaterialFieldsFragment,
  SourceSystemMappingFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { ConnectExternalMaterialsButton } from "../../../admin/org-items/pages/materials/components/list/components/ConnectExternalMaterialButton";
import { useImportExternalItems } from "../../../admin/org-items/pages/materials/providers/ImportExternalItemsProvider";

const ConnectionsList = tw.div`flex h-12 items-center justify-center space-x-1`;

type Connection = Pick<SourceSystemMappingFieldsFragment, "sourceSystem">;

type Props = {
  connections: Connection[];
  item?: OrgMaterialFieldsFragment;
  displayConnectButton?: boolean;
  disableClick?: boolean;
};

const ConnectionsConnectionIcon: FC<
  Connection & Omit<Props, "displayConnectButton">
> = ({ sourceSystem, connections, item, disableClick }) => {
  const { openModal } = useImportExternalItems();

  return (
    <ConnectionIcon
      sourceSystem={sourceSystem}
      disableClick={disableClick}
      onClick={
        disableClick
          ? undefined
          : () =>
              openModal({
                sourceSystem,
                externalItem: connections.find(
                  (c) => c.sourceSystem === sourceSystem,
                ) as ExternalItemFieldsFragment,
                item,
              })
      }
    />
  );
};

export const Connections: FC<Props> = ({
  connections,
  item,
  disableClick = true,
  displayConnectButton = false,
}) => {
  if (connections.length === 0 && displayConnectButton) {
    return <ConnectExternalMaterialsButton item={item} />;
  }
  return (
    <NotNullableRenderer value={connections.length > 0}>
      <ConnectionsList>
        {connections.map((connection, key) => (
          <ConnectionsConnectionIcon
            key={key}
            {...connection}
            connections={connections}
            item={item}
            disableClick={disableClick}
          />
        ))}
      </ConnectionsList>
    </NotNullableRenderer>
  );
};
