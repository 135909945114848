import { If } from "@/common/components/if/If";
import {
  InvoiceIssueFieldsFragment,
  InvoiceKickbackFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const IssueDetails = tw.div`font-normal text-2xs text-gray-600 group-hover:text-blue-300 group-hover:cursor-pointer`;
const Tabs = tw.div`flex flex-row items-center gap-2 mx-3`;
const Issue = tw.div<{
  $active: boolean;
}>`group flex flex-row items-center justify-center rounded-xl w-1/2 border-dashed p-3 font-medium gap-3 group-hover:text-blue-300 group-hover:cursor-pointer hover:bg-white hover:cursor-pointer
${({ $active }) =>
  $active
    ? "bg-white rounded-b-none relative -bottom-[1px]"
    : "bg-gray-100 border border-gray-300"}
`;
const TabContent = tw.div`flex flex-col group-hover:text-blue-300 group-hover:cursor-pointer`;
const Number = tw.div`text-2xl font-bold group-hover:text-blue-300 group-hover:cursor-pointer`;
const TabName = tw.div`text-sm`;

type Props = {
  kickbacks?: InvoiceKickbackFieldsFragment[];
  issues?: InvoiceIssueFieldsFragment[];
  setShowKickbacks: (show: boolean) => void;
  setShowIssues: (show: boolean) => void;
  showKickbacks: boolean;
  showIssues: boolean;
};

export const InvoiceIssuesTab: FC<Props> = ({
  kickbacks = [],
  issues = [],
  setShowKickbacks,
  setShowIssues,
  showIssues,
  showKickbacks,
}) => {
  if (!kickbacks.length || !issues.length) {
    return null;
  }
  return (
    <Tabs>
      <Issue
        onClick={() => {
          setShowKickbacks(false);
          setShowIssues(true);
        }}
        $active={showIssues}
      >
        <TabContent>
          <FormattedMessage id="VENDOR_ISSUES" tagName={TabName} />
        </TabContent>
        <Number>{issues.length}</Number>
      </Issue>
      <Issue
        onClick={() => {
          setShowKickbacks(true);
          setShowIssues(false);
        }}
        $active={showKickbacks}
      >
        <TabContent>
          <FormattedMessage id="INTERNAL_KICKBACKS" tagName={TabName} />
          <If isTrue={kickbacks.some((k) => !k.resolvedAt)}>
            <FormattedMessage
              id="UNRESOLVED"
              values={{ number: kickbacks.filter((k) => !k.resolvedAt).length }}
              tagName={IssueDetails}
            />
          </If>
        </TabContent>
        <Number>{kickbacks.length}</Number>
      </Issue>
    </Tabs>
  );
};
