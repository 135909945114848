import { LocalPaginationProvider } from "@/common/components/pagination-local/LocalPaginationProvider";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { Switch } from "@/common/components/switch/Switch";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalPaymentMethods } from "../../../hooks/useExternalPaymentMethods";
import { useImportPaymentMethodsStore } from "../../../stores/useImportPaymentMethodsStore";
import { useImportExternalPaymentMethodsTableConfiguration } from "./ImportExternalPaymentsTable.configuration";

const SearchBarTitleComponent = tw.div`flex flex-col pr-2`;
const ToggleText = tw.div`flex flex-row font-normal text-sm items-center mt-1`;

const ImportExternalPaymentMethodsTableWithProviders = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const {
    sourceSystem,
    selectedExternalPaymentMethods,
    totalExternalPaymentMethodsCount,
    setSelectedExternalPaymentMethods,
    setTotalExternalPaymentMethodsCount,
    importAllExternalItems,
    setImportAllExternalItems,
  } = useImportPaymentMethodsStore();
  const { externalPaymentMethods, loadingExternalPaymentMethods } =
    useExternalPaymentMethods(connectedSourceSystem?.system);
  const configuration = useImportExternalPaymentMethodsTableConfiguration(
    selectedExternalPaymentMethods,
  );

  useEffect(() => {
    setTotalExternalPaymentMethodsCount(externalPaymentMethods.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalPaymentMethods.length]);

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      items={externalPaymentMethods.map((externalPaymentMethod) => ({
        ...externalPaymentMethod,
        id: externalPaymentMethod.externalId,
      }))}
      loadingItems={loadingExternalPaymentMethods}
      searchBarTitle={
        <SearchBarTitleComponent>
          <FormattedMessage id="SELECT_PAYMENT_METHODS_TO_IMPORT" />
          <ToggleText>
            <FormattedMessage
              id="IMPORT_ALL_PAYMENT_METHODS_FROM_SYSTEM"
              values={{
                system: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
              }}
            />
            <Switch
              className="ml-2"
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              value={importAllExternalItems}
              onChange={(value) => {
                setImportAllExternalItems(value);
                setSelectedExternalPaymentMethods(
                  value ? externalPaymentMethods.map((i) => i.externalId) : [],
                );
              }}
            />
          </ToggleText>
        </SearchBarTitleComponent>
      }
      searchKeys={["code", "name"]}
      itemSelection={{
        type: "multiple",
        selectedItemIds: selectedExternalPaymentMethods,
        setSelectedItemIds: (ids: string[]) => {
          setImportAllExternalItems(
            ids.length === totalExternalPaymentMethodsCount,
          );
          setSelectedExternalPaymentMethods(ids);
        },
      }}
    />
  );
};

export const ImportExternalPaymentMethodsTable: FC = () => {
  return (
    <LocalPaginationProvider>
      <ImportExternalPaymentMethodsTableWithProviders />
    </LocalPaginationProvider>
  );
};
