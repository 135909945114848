import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import {
  SplitType,
  useSplittingInvoicesWizard,
} from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardProvider";
import { ReceiptUploadModal } from "@/contractor/pages/home/receipts/pages/receipt-record-order/components/receipt-upload-modal/ReceiptUploadModal";
import { useReceiptUploadModal } from "@/contractor/pages/home/receipts/pages/receipt-record-order/providers/UploadReceiptProvider";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceType,
  ReceiptFieldsFragment,
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
  UpdateReceiptMutation,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { AssetViewer } from "../../asset-viewer/AssetViewer";
import { InvoiceDocumentTabs } from "./InvoiceDocumentTabs";
import { InvoiceDetailsHeader } from "./header/InvoiceDetailsHeader";
import { InvoiceDownloadButton } from "./header/InvoiceDownloadButton";

const Container = tw.div`flex flex-col bg-blue-100 rounded-3xl pt-6 text-black h-full overflow-scroll`;
const PageAdjustmentContainer = tw.div`flex flex-row gap-2 items-center text-xs`;

type Props = {
  invoice:
    | InvoiceFieldsFragment
    | null
    | ReceiptFieldsFragment
    | DistributorInvoiceFieldsFragment;
  displayInvoiceDetails?: boolean;
  invoiceReadonly?: boolean;
  invoicePoNumberReadonly?: boolean;
  updateInvoice?: (
    input: UpdateInvoiceInput,
  ) => Promise<
    UpdateInvoiceMutation | UpdateReceiptMutation | null | undefined
  >;
  type?: InvoiceType;
  hasError?: boolean;
  rescanInvoice?: () => void;
  skipAutoMatching?: boolean;
};

export const InvoiceDetails: FC<Props> = ({
  invoice,
  displayInvoiceDetails = true,
  invoiceReadonly = false,
  invoicePoNumberReadonly = false,
  updateInvoice,
  type = InvoiceType.Invoice,
  hasError = false,
  rescanInvoice,
  skipAutoMatching = false,
}) => {
  const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);
  const { openWizard } = useSplittingInvoicesWizard();
  const { openUploadReceiptModal } = useReceiptUploadModal();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hasError) {
      ref.current?.scrollTo({ top: 0 });
    }
  }, [hasError]);

  const originalDocument = useMemo(
    () =>
      (invoice?.documents ?? []).length > 0 ? invoice?.documents[0] : null,
    [invoice?.documents],
  );

  const newDocument = useMemo(
    () =>
      (invoice?.documents ?? []).length > 1
        ? invoice?.documents[invoice?.documents.length - 1]
        : null,
    [invoice?.documents],
  );

  const documents = useMemo(() => {
    const docs = [];
    if (newDocument) {
      docs.push(newDocument);
    }
    if (originalDocument) {
      docs.push(originalDocument);
    }
    return docs;
  }, [newDocument, originalDocument]);

  const readjust = useCallback(() => {
    const document = newDocument ?? originalDocument;
    if (document) {
      if (type === InvoiceType.Receipt) {
        openUploadReceiptModal();
      } else {
        openWizard({
          initialPage: 0,
          urls: [document.assetUrl],
          pageRanges: [document.pageRange],
          type: SplitType.Restructure,
        });
      }
    }
  }, [newDocument, openUploadReceiptModal, openWizard, originalDocument, type]);

  return (
    <Container ref={ref}>
      <InvoiceDetailsHeader
        invoice={invoice}
        updateInvoice={updateInvoice}
        displayHeaderDetails={displayInvoiceDetails}
        readonly={invoiceReadonly}
        invoicePoNumberReadonly={invoicePoNumberReadonly}
        type={type}
        rescanInvoice={rescanInvoice}
        readjust={readjust}
        skipAutoMatching={skipAutoMatching}
      />
      <InvoiceDocumentTabs
        documents={documents ?? []}
        activeDocumentIndex={activeDocumentIndex}
        setActiveDocumentIndex={setActiveDocumentIndex}
        type={type}
        invoice={invoice}
      />
      <AssetViewer
        asset={{
          ...documents[activeDocumentIndex]?.asset,
          url: documents[activeDocumentIndex]?.assetUrl,
        }}
        pageRange={documents[activeDocumentIndex]?.pageRange}
        downloadButton={
          <InvoiceDownloadButton
            invoice={invoice}
            version={documents[activeDocumentIndex]?.version}
          />
        }
        customFooter={
          <If isTrue={type === InvoiceType.Receipt}>
            <If isTrue={!invoiceReadonly}>
              <PageAdjustmentContainer>
                <FormattedMessage id="REPLACE_RECEIPT" />
                <PrimaryButton $small onClick={readjust} className="h-6 px-0">
                  <FormattedMessage id="EDIT" />
                </PrimaryButton>
              </PageAdjustmentContainer>
              <ReceiptUploadModal />
            </If>
          </If>
        }
      />
    </Container>
  );
};
