import { If } from "@/common/components/if/If";
import { ImportExternalResultTitle } from "@/common/components/import-external-result-title/ImportExternalResultTitle";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportEquipmentItemsStore } from "../../../stores/useImportEquipmentItemsStore";
import { useImportExternalEquipmentItemsResultConfiguration } from "./ImportExternalEquipmentItemsResults.configuration";

const Container = tw.div`flex flex-1 self-stretch flex-col`;

export const ImportExternalEquipmentItemsImportResults = () => {
  const intl = useIntl();
  const configuration = useImportExternalEquipmentItemsResultConfiguration();
  const { importedEquipmentItems } = useImportEquipmentItemsStore();

  return (
    <Container>
      <If isTrue={(importedEquipmentItems?.created ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedEquipmentItems?.created ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "CREATED_EQUIPMENT_ITEMS_COUNT" },
                { count: (importedEquipmentItems?.created ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16"
        />
      </If>
      <If isTrue={(importedEquipmentItems?.mapped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedEquipmentItems?.mapped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "MATCHED_EQUIPMENT_ITEMS_COUNT" },
                { count: (importedEquipmentItems?.mapped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
      <If isTrue={(importedEquipmentItems?.skipped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedEquipmentItems?.skipped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "SKIPPED_EQUIPMENT_ITEMS_COUNT" },
                { count: (importedEquipmentItems?.skipped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
    </Container>
  );
};
