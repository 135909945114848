import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-col gap-1 justify-between border-t border-dashed pt-4 border-gray-300`;
const Item = tw.div`flex flex-row items-center  justify-between gap-2
${({ $disabled }: { $disabled?: boolean }) => $disabled && "opacity-50"}
`;

type Props = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  timeTbd: boolean | undefined | null;
  setTimeTbd: (value: boolean | undefined) => void;
  hideItemsDeliveryDates?: boolean;
  labels?: {
    timeTbd?: string;
  };
};

export const DeliveriesDateRangePickerActionBar: FC<Props> = ({
  value,
  onChange,
  timeTbd,
  setTimeTbd,
  hideItemsDeliveryDates,
  labels,
}) => {
  const intl = useIntl();

  return (
    <Row>
      <If isTrue={!hideItemsDeliveryDates}>
        <Item $disabled={!!timeTbd}>
          <FormattedMessage id="INCLUDE_ITEM_DELIVERY_DATES" />
          <Switch
            value={value || false}
            onChange={(val) => onChange?.(val)}
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
            disabled={!!timeTbd}
          />
        </Item>
      </If>
      <Item>
        {labels?.timeTbd ?? (
          <FormattedMessage id="FILTER_BY_HOLD_FOR_RELEASE" />
        )}
        <Switch
          value={timeTbd || false}
          onChange={setTimeTbd}
          onLabel={intl.$t({ id: "YES" })}
          offLabel={intl.$t({ id: "NO" })}
        />
      </Item>
    </Row>
  );
};
