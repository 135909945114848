import { If } from "@/common/components/if/If";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { getDiscountByAmount } from "@/common/components/price/utils/getDiscountByAmount";
import { getDiscountByPercentage } from "@/common/components/price/utils/getDiscountByPercentage";
import { sumQuoteGroupFullPrices } from "@/common/components/price/utils/sumQuoteGroupFullPrices";
import { getFileName } from "@/common/components/upload-asset/getFileName";
import { useOpenFile } from "@/common/components/upload-asset/useOpenFile";
import { formattedDate } from "@/common/utils/dates/DateView";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  QuoteStatus,
  RfqQuotesQuoteFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import { Attachment } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import tw from "tailwind-styled-components";
import { MAX_COLUMN_WIDTH } from "../../../constants";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { AdditionalCharges } from "./AdditionalCharges";
import { DiscountView } from "./DiscountView";
import NotesPreview, { NotesLink } from "./NotesPreview";

type QuoteInfoValuesProps = {
  quote: RfqQuotesQuoteFieldsFragment;
  index: number;
};

const NotesGroup = tw.div`
 grid w-full
`;

const FileName = tw.div`
  text-xs text-center text-ellipsis truncate pl-1 w-full 
`;

const Label = tw(styled.div`
  max-width: ${() => MAX_COLUMN_WIDTH}px;
`)`
  text-right text-sm pr-4 ${({ $deactivated }: { $deactivated: boolean }) =>
    $deactivated && "text-gray-500"}
`;

export const QuoteInfoValues: FC<QuoteInfoValuesProps> = ({ quote }) => {
  const { rfq, selectedQuotes, allQuoteItems, selectedAuxiliaryQuoteItems } =
    useRfqQuotes();
  const { toggle } = useGlobalDrawer();
  const { open } = useOpenFile();
  const { calcAdditionalChargesPrice } = usePriceCalculation();

  const isDeactivated = useMemo(() => {
    return (
      (rfq?.status === RfqStatus.Awarded ||
        rfq?.status === RfqStatus.Cancelled) &&
      quote.status !== QuoteStatus.Accepted
    );
  }, [rfq, quote]);

  const chargesAmount = useMemo(
    () => Number(calcAdditionalChargesPrice(quote.additionalCharges) || "0"),
    [quote.additionalCharges, calcAdditionalChargesPrice],
  );

  const total = useMemo(() => {
    return (
      allQuoteItems
        .filter(
          (i) => i.status !== QuoteStatus.Withdrawn && i.quoteId === quote.id,
        )
        .reduce((prevValue, item) => {
          let currentPrice = new Decimal(0);
          const includePrice =
            selectedQuotes.length === 0 ||
            !!selectedQuotes.find(
              (quote) =>
                quote.quoteItemId === item.id &&
                quote.rfqItemId === item.rfqItemId,
            );
          currentPrice = includePrice
            ? new Decimal(item.unitPrice || 0).mul(item.quantityDecimal || 0)
            : new Decimal(0);

          return Number(currentPrice.add(prevValue));
        }, chargesAmount) +
      quote.auxiliaryItems.reduce((prevValue, item) => {
        const price = selectedAuxiliaryQuoteItems.find(
          (aux) => aux.itemId === item.id,
        )
          ? new Decimal(item.unitPrice || 0).mul(item.quantityDecimal || 0)
          : new Decimal(0);
        return Number(price.add(new Decimal(prevValue)));
      }, 0)
    );
  }, [
    allQuoteItems,
    quote.auxiliaryItems,
    quote.id,
    chargesAmount,
    selectedQuotes,
    selectedAuxiliaryQuoteItems,
  ]);

  const discount = useMemo(() => {
    if (quote.discount) {
      let amount;
      if (quote.discount.percentage) {
        amount = getDiscountByPercentage(total, quote.discount.percentage);
      }
      if (quote.discount.amount) {
        amount = getDiscountByAmount(
          quote.discount.amount ?? "0",
          quote.itemGroups.reduce(sumQuoteGroupFullPrices, 0),
        ).mul(total);
      }

      return {
        amount,
        percentage: quote.discount.percentage,
      };
    }
    return undefined;
  }, [quote, total]);

  const openNotes = () => {
    toggle("notes-panel", true, quote.id);
  };

  return (
    <>
      <Label $deactivated={isDeactivated}>
        <DiscountView discount={discount} total={total} />
      </Label>
      <Label $deactivated={isDeactivated}>
        <AdditionalCharges additionalCharges={quote.additionalCharges} />
      </Label>
      <Label $deactivated={isDeactivated}>
        {quote.expirationDate ? (
          formattedDate({ date: quote.expirationDate })
        ) : (
          <FormattedMessage id="NONE" />
        )}
      </Label>
      <Label $deactivated={isDeactivated}>
        {quote.paymentTermDays ? (
          <FormattedMessage
            id="PAYMENT_TERM_NET_WITH_DAYS"
            values={{ days: quote.paymentTermDays }}
          />
        ) : (
          <FormattedMessage id="NONE" />
        )}
      </Label>
      <Label $deactivated={isDeactivated}>
        {quote.notes || quote.assets.length ? (
          <NotesGroup>
            <If isTrue={quote.notes}>
              <NotesPreview text={quote.notes} openNotes={openNotes} />
            </If>

            {quote.assets.map((asset, index) => (
              <NotesLink key={index} onClick={() => open(asset)}>
                <Attachment />
                <FileName>{getFileName(asset.url)}</FileName>
              </NotesLink>
            ))}
          </NotesGroup>
        ) : (
          <FormattedMessage id="NONE" />
        )}
      </Label>
    </>
  );
};
