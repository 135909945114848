import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { AdminOrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useExternalVendorMapping } from "../providers/ExternalVendorMappingProvider";
import { VendorForm } from "./vendor-form/VendorForm";

type Props = {
  vendors: AdminOrgPreferredVendorsFieldsFragment[];
};

export const EditVendorPanelId = "add-edit-vendor-panel";

export const ToggleEditVendor: FC<Props> = ({ vendors }) => {
  const intl = useIntl();
  const { isOpened, toggle, getObjectId } = useGlobalDrawer();
  const { setSelectedExternalVendorData, setSourceSystem } =
    useExternalVendorMapping();

  return (
    <DrawerView
      isOpened={isOpened(EditVendorPanelId)}
      content={(togglePanel) => (
        <VendorForm
          title={intl.$t({ id: "VENDORS_EDIT_TITLE" })}
          onClose={() => {
            togglePanel(false);
            setSourceSystem(null);
            setSelectedExternalVendorData(null);
          }}
          vendor={vendors.find((v) => v.id === getObjectId(EditVendorPanelId))}
        />
      )}
      setIsOpened={(value) => {
        toggle(EditVendorPanelId, value);
      }}
    />
  );
};
