import { FC, PropsWithChildren, ReactNode } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { useExpandableFooterStore } from "./stores/useExpandableFooterStore";

const Container = tw.div`fixed bottom-0 left-0 right-0 z-[190] overflow-hidden flex flex-col`;
const InnerContainer = tw.div`pr-2 pl-2 md:pl-16 md:pr-16 bg-blue-450 min-h-15 py-3.5`;
const ExpandableContainer = tw.div`relative z-20`;
const ExpandBar = tw.div`justify-center flex h-6 left-0 right-0 items-center bg-blue-400 cursor-pointer text-sm text-white`;
const ChevronUp = tw(BsChevronUp)`mx-2`;
const ChevronDown = tw(BsChevronDown)`mx-2`;

type Props = PropsWithChildren<{
  className?: string;
  expandedChildren?: ReactNode;
}>;

export const FloatingFooter: FC<Props> = ({
  children,
  className,
  expandedChildren,
}) => {
  const { expanded, setExpanded } = useExpandableFooterStore();
  return (
    <Container
      className={`${className} ${expandedChildren ? "shadow-[rgba(0,0,15,0.2)_0px_6px_8px_12px]" : ""}`}
    >
      <If isTrue={expandedChildren}>
        <ExpandableContainer>
          <ExpandBar onClick={() => setExpanded(!expanded)}>
            <If isTrue={expanded}>
              <ChevronDown />
              <FormattedMessage id="COLLAPSE" />
              <ChevronDown />
            </If>
            <If isTrue={!expanded}>
              <ChevronUp />
              <FormattedMessage id="EXPAND" />
              <ChevronUp />
            </If>
          </ExpandBar>
          <If isTrue={expanded}>{expandedChildren}</If>
        </ExpandableContainer>
      </If>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
};
