import { TaxType } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ViewToggle } from "../../../../view-toggle/ViewToggle";

const toggleOptions = [
  {
    label: <FormattedMessage id="USE_TAX" />,
    viewState: TaxType.Use,
  },
  {
    label: <FormattedMessage id="SALES_TAX" />,
    viewState: TaxType.Sales,
  },
];

type Props = {
  type: TaxType | undefined;
  changeType: (type: TaxType) => void;
  readonly?: boolean;
};

export const UseTaxSwitch: FC<Props> = ({ type, changeType, readonly }) => (
  <ViewToggle
    options={toggleOptions}
    setView={changeType}
    tableView={type}
    readonly={readonly}
  />
);
