import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { PrintButton } from "@/common/components/print-button/PrintButton";
import { BuyoutFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { usePrintView } from "../../../hooks/usePrintView";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

const IconButtonStyled = tw(IconButtonBorderless)`
  text-blue-500 w-50 hover:cursor-pointer
`;

type PrintButtonProps = {
  buyout: BuyoutFieldsFragment;
};

export const BuyoutPrintButton: FC<PrintButtonProps> = ({ buyout }) => {
  const { groupedByCostCode, costCodes } = useBuyoutGrouped();
  const { savePDF } = usePrintView(buyout, costCodes, groupedByCostCode);

  return (
    <IconButtonStyled onClick={savePDF}>
      <PrintButton />
    </IconButtonStyled>
  );
};
