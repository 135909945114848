import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  updateDialogVisible: boolean;
  setUpdateDialogVisible: (visible: boolean) => void;
};

export const useReleaseFooterActionsStore = create<State>()(
  devtools((set) => ({
    updateDialogVisible: false,
    setUpdateDialogVisible: (visible) => set({ updateDialogVisible: visible }),
  })),
);
