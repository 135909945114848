import { If } from "@/common/components/if/If";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC, useEffect, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { UNSPECIFIED_COST_CODE_ID } from "../../../../../common/hooks/useUnspecifiedCostCode";
import { useProjectMaps } from "../hooks/useProjectMaps";
import { useProjectZones } from "../hooks/useProjectZones";
import {
  ProjectItemsZonesProvider,
  useProjectItemsZones,
} from "../providers/ProjectItemsZonesProvider";
import {
  SyncEstimatedItemsProvider,
  useSyncEstimatedItems,
} from "../providers/SyncEstimatedItemsProvider";
import { ProjectContentFooter } from "./ProjectFooter";
import { ProjectHeader } from "./project-header/ProjectHeader";
import { ProjectList } from "./project-list/ProjectList";
import { useProjectListItemConfiguration } from "./project-list/ProjectListItem.configuration";

type Props = {
  project: ProjectExtendedFieldsFragment;
  loading?: boolean;
};

const ProjectContentWithProviders: FC<Props> = ({ project, loading }) => {
  const { zones } = useProjectItemsZones();
  const readonly = useMemo(() => !!project?.deletedAt, [project]);
  const { estimatedItemsMap } = useProjectMaps(project);
  const { saving } = useSyncEstimatedItems();
  const { zones: projectZones } = useProjectZones();
  const hasZones = useMemo(() => projectZones.length > 0, [projectZones]);
  const viewState = useTableViewStore((state) => state.viewState);

  const items = useMemo(
    () =>
      zones
        .map((zone) =>
          zone.items.map((code) =>
            code.items.map((item) =>
              item.estimatedItems
                .filter((ei) => estimatedItemsMap.get(ei.id))
                .map((ei) => ({
                  ...item,
                  zone,
                  code:
                    code.id !== UNSPECIFIED_COST_CODE_ID
                      ? code
                      : {
                          ...code,
                          name: "",
                        },
                  ...estimatedItemsMap.get(ei.id),
                })),
            ),
          ),
        )
        .flat(3),
    [zones, estimatedItemsMap],
  );

  const { configuration, spreadsheetConfig } = useProjectListItemConfiguration(
    hasZones,
    readonly,
    items,
  );
  return (
    <>
      <ProjectHeader />
      <If isTrue={viewState === TableViewState.normal}>
        <ProjectList
          project={project}
          loading={loading}
          columns={configuration}
          readonly={readonly}
        />
      </If>
      <If isTrue={viewState === TableViewState.spreadsheet}>
        <SpreadSheetTable
          items={items}
          columns={spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 350px)"
        />
      </If>
      <ProjectContentFooter />
    </>
  );
};

export const ProjectContent: FC<Props> = ({ project, loading }) => {
  const { spreadsheetConfig } = useProjectListItemConfiguration(true, true);
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.normal);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  return (
    <ProjectItemsZonesProvider project={project}>
      <ColumnMapperProvider config={spreadsheetConfig}>
        <SyncEstimatedItemsProvider>
          <ProjectContentWithProviders project={project} loading={loading} />
        </SyncEstimatedItemsProvider>
      </ColumnMapperProvider>
    </ProjectItemsZonesProvider>
  );
};
