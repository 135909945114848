import { useImportExternalPos } from "@/common/components/import-external-po/hooks/useImportExternalPos";
import { ExternalPoImportRelease } from "@/common/components/import-external-po/types/ExternalPoImportRelease";
import {
  ExternalPoBaseFieldsFragment,
  ExternalPoFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { usePOLinksCount } from "./usePOLinksCount";

export const useExternalPoItemsValidation = ({
  release,
  readonly,
}: {
  release: ExternalPoImportRelease | null | undefined;
  readonly?: boolean;
}) => {
  const intl = useIntl();

  const { externalPo, selectedBaseExternalPO, hasMissingPoLink } =
    useImportExternalPos();

  const poAlreadyImported = useMemo(
    () =>
      selectedBaseExternalPO?.poLinks.some(
        (poLink) => poLink.release?.id === release?.id,
      ) ||
      externalPo?.poLinks.some((poLink) => poLink.release?.id === release?.id),
    [selectedBaseExternalPO?.poLinks, externalPo?.poLinks, release?.id],
  );

  const poBaseLinksCount = usePOLinksCount(selectedBaseExternalPO?.poLinks);
  const poLinksCount = usePOLinksCount(externalPo?.poLinks);

  const validate = useCallback(
    (
      selectedBaseExternalPO: ExternalPoBaseFieldsFragment | undefined,
      externalPo: ExternalPoFieldsFragment | null | undefined,
    ) => {
      if (poAlreadyImported || hasMissingPoLink) {
        return null;
      }
      if (
        !selectedBaseExternalPO?.project?.project &&
        !externalPo?.project?.project
      ) {
        return intl.$t(
          { id: "PO_IMPORT_FM_PROJECT_NOT_IMPORTED" },
          {
            projectName:
              selectedBaseExternalPO?.project?.name ??
              externalPo?.project?.name ??
              "",
          },
        );
      }
      if (
        (selectedBaseExternalPO?.vendor?.orgPreferredVendors ?? []).length ===
          0 &&
        (externalPo?.vendor?.orgPreferredVendors ?? []).length === 0
      ) {
        return intl.$t(
          { id: "PO_IMPORT_FM_VENDOR_NOT_IMPORTED" },
          {
            vendorName:
              selectedBaseExternalPO?.vendor?.name ??
              externalPo?.vendor?.name ??
              "",
          },
        );
      }
      if (poBaseLinksCount > 0 || poLinksCount > 0) {
        return intl.$t({ id: "EXTERNAL_PO_ORDER_ALREADY_ASSOCIATED" });
      }
      return null;
    },
    [poAlreadyImported, hasMissingPoLink, intl, poBaseLinksCount, poLinksCount],
  );

  const validationError = useMemo(() => {
    if (readonly) {
      return null;
    }
    return validate(selectedBaseExternalPO, externalPo);
  }, [readonly, validate, selectedBaseExternalPO, externalPo]);

  return {
    validationError,
    validate,
    poAlreadyImported,
  };
};
