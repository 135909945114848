import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AddZoneInput,
  AddZonesInput,
  UpdateZoneInput,
  useAddZoneMutation,
  useAddZonesMutation,
  useRemoveZoneMutation,
  useUpdateZoneMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProject } from "../providers/ProjectProvider";
import { useProjectStore } from "../store/useProjectStore";
import { useSetCurrentProjectId } from "./useSetCurrentProjectId";

export const useProjectZones = () => {
  const { project } = useProject();
  const { zones, loading, error, updateStoreData } = useProjectStore(
    useShallow((state) => ({
      zones: state.zones,
      loading: state.loading,
      error: state.error,
      updateStoreData: state.updateStoreData,
    })),
  );

  const [addZoneMutation] = useAddZoneMutation();
  const [addZonesMutation] = useAddZonesMutation();
  const [updateZoneMutation] = useUpdateZoneMutation();
  const [removeZoneMutation] = useRemoveZoneMutation();
  const { setError } = useGlobalError();

  useSetCurrentProjectId(project?.id);

  const updateZone = useCallback(
    async (zone: UpdateZoneInput) => {
      try {
        const { data, errors } = await updateZoneMutation({
          variables: {
            input: zone,
          },
        });
        if (data?.updateZone) {
          updateStoreData({
            zones: zones.map((zone) =>
              zone.id === data.updateZone.id ? data.updateZone : zone,
            ),
          });
        }
        setError(errors);
        if (data?.updateZone) {
          return !!data?.updateZone;
        }
      } catch (errors) {
        setError(errors);
      }
    },
    [updateZoneMutation, setError, updateStoreData, zones],
  );

  const addZone = useCallback(
    async (zone: AddZoneInput) => {
      try {
        const { data, errors } = await addZoneMutation({
          variables: {
            input: zone,
          },
        });
        setError(errors);
        if (data?.addZone) {
          updateStoreData({ zones: data.addZone });
          return data?.addZone;
        }
      } catch (errors) {
        setError(errors);
      }
    },
    [addZoneMutation, setError, updateStoreData],
  );

  const addZones = useCallback(
    async (input: AddZonesInput) => {
      try {
        const { data, errors } = await addZonesMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        if (data?.addZones) {
          updateStoreData({ zones: data.addZones });
          return data?.addZones;
        }
      } catch (errors) {
        setError(errors);
      }
    },
    [addZonesMutation, setError, updateStoreData],
  );

  const deleteZone = useCallback(
    async (zoneId: string) => {
      try {
        const { data, errors } = await removeZoneMutation({
          variables: {
            zoneId,
          },
        });
        setError(errors);
        if (data?.removeZone) {
          updateStoreData({
            zones: zones.filter((zone) => zone.id !== zoneId),
          });
          return !!data?.removeZone;
        }
      } catch (errors) {
        setError(errors);
      }
    },
    [removeZoneMutation, setError, updateStoreData, zones],
  );

  return {
    addZone,
    addZones,
    updateZone,
    deleteZone,
    zones,
    loading,
    error,
  };
};
