import { useShallow } from "zustand/react/shallow";
import { useStartupDataStore } from "../useStartupDataStore";

export const useStartupDataUpdate = () => {
  const { fetchStartupData, updateStartupData } = useStartupDataStore(
    useShallow((state) => ({
      fetchStartupData: state.fetchStartupData,
      updateStartupData: state.updateStartupData,
    })),
  );

  return {
    refetchStartupData: () => fetchStartupData(true),
    updateStartupData,
  };
};
