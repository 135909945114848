import { DateView } from "@/common/utils/dates/DateView";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderTypesConfig } from "../../../../../../common/hooks/order-types-config/useOrderTypesConfig";
import { ExpandedRelease } from "../../providers/ReleaseProvider";

type Props = {
  release: ExpandedRelease;
};

const Container = tw.div`text-sm flex font-medium gap-2 mb-2`;
const Info = tw.div``;

export const DeliveryHeaderInformation: FC<Props> = ({ release }) => {
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const intl = useIntl();

  return (
    <Container>
      {orderTypeConfig.labels.datePickerReadonlyLabel}:
      <Info>
        <DateView
          date={release?.time}
          noDateLabel={
            release?.timeTBD ? intl.$t({ id: "HOLD_FOR_RELEASE" }) : undefined
          }
        />
      </Info>
    </Container>
  );
};
