import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

const Container = tw.div`pr-2`;

export const BuyoutInlineAllowance: FC = () => {
  const { buyout } = useContractorBuyout();

  return (
    <Container>
      <NotNullableRenderer
        value={buyout?.additionalChargesAllowance}
        testId="allowance"
      >
        <Price price={buyout?.additionalChargesAllowance} testId="allowance" />
      </NotNullableRenderer>
    </Container>
  );
};
