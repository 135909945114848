import { If } from "@/common/components/if/If";
import { LineItemNameNotes } from "@/common/components/line-item-name-notes/LineItemNameNotes";
import { useUser } from "@/common/providers/UserProvider";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  DistributorReleaseItemFieldsFragment,
  TransactionKind,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { LineItemCostInfo } from "../../line-item-cost-info/LineItemCostInfo";
const Detail = tw.div`grid grid-flow-col justify-start text-2xs pl-8 gap-1 items-start`;
const Label = tw.span`text-gray-600`;
const InfoOutlinedStyled = tw(InfoOutlined)`text-red-500 w-[12px] h-[12px]`;
const IssueText = tw.span`text-red-500 grid grid-flow-col gap-1`;

const DATE_FORMAT = "MMM dd, yyyy";

type Props = {
  item: ExpandedReleaseItem | DistributorReleaseItemFieldsFragment;
  costTypeId?: string | null | undefined;
  costCodeId?: string | null | undefined;
  zone?: string | undefined;
  phaseCode?: string | undefined;
};

export const InvoiceReleaseItemDetails: FC<Props> = ({
  item,
  costTypeId,
  costCodeId,
  zone,
  phaseCode,
}) => {
  const { isContractor } = useUser();
  const { formatCostType } = useCostTypes();
  const { formatCostCode } = useCostCodes();
  const { release } = useRelease();

  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  if (!release) {
    return null;
  }

  const issue = item.issues?.[0];
  return (
    <>
      <If isTrue={item.instructions?.text || item.instructions?.assets?.length}>
        <Detail>
          <FormattedMessage
            id={isContractor ? "YOUR_NOTE_LABEL" : "CONTRACTOR_NOTE_LABEL"}
          />
          <LineItemNameNotes
            className="pl-0"
            notes={item.instructions?.text}
            assets={item.instructions?.assets}
          />
        </Detail>
      </If>
      <If isTrue={item.notes || item.assets.length}>
        <Detail>
          <FormattedMessage
            id={isContractor ? "VENDOR_NOTE_LABEL" : "YOUR_NOTE_LABEL"}
          />
          <LineItemNameNotes
            className="pl-0"
            notes={item.notes}
            assets={item.assets}
          />
        </Detail>
      </If>
      <LineItemCostInfo
        costType={formatCostType(costTypeId)}
        costCode={formatCostCode(costCodeId)}
        phaseCode={phaseCode}
        zone={zone}
      />
      {item.deliveryDate &&
        (hasEndDate ? (
          <Detail>
            <FormattedMessage
              id={
                release?.type.transactionKind === TransactionKind.Rental
                  ? "RENTAL_END_DATE"
                  : "SERVICES_END_DATE"
              }
            />
            :<Label>{format(new Date(item.deliveryDate), DATE_FORMAT)}</Label>
          </Detail>
        ) : (
          <Detail>
            <FormattedMessage id="DELIVERY_DATE" />:
            <Label>{format(new Date(item.deliveryDate), DATE_FORMAT)}</Label>
          </Detail>
        ))}
      <If isTrue={issue}>
        <Detail>
          <FormattedMessage id="ORDER_ISSUE" />
          <InfoOutlinedStyled />
          <IssueText>
            {issue?.quantityDecimal}
            {item.uom?.mnemonic ?? item.uom?.pluralDescription}{" "}
            <FormattedMessage id={`RELEASE_ISSUE_${issue?.issueType}`} />
          </IssueText>
          <If isTrue={issue?.resolution}>
            <FormattedMessage id="RESOLUTION" />
            <FormattedMessage
              id={issue?.resolution?.resolutionType}
              tagName={IssueText}
            />
          </If>
        </Detail>
      </If>
    </>
  );
};
