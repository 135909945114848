import { FC, useCallback, useMemo } from "react";

import { DateTimeView } from "@/common/components/date/DateTimeView";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { ExternalNotificationFieldsFragment } from "@/generated/graphql";
import { Replay } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalNotifications } from "../../providers/ExternalNotificationsProvider";
import {
  DetailsContainer,
  IconButtonStyled,
  MessageBody,
  NotificationContainer,
} from "../common/Notification.styles";

const Text = tw.div`flex flex-col`;
const Contacts = tw.div`font-medium`;
const Subject = tw.div``;

type NotificationListItemProps = {
  notification: ExternalNotificationFieldsFragment;
  index: number;
};

export const ExternalNotificationListItem: FC<NotificationListItemProps> = ({
  notification,
  index,
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { resendExternalNotification } = useExternalNotifications();

  const sellerOrgLocation = useMemo(
    () =>
      notification.release?.sellerOrgLocation ||
      notification.buyout?.sellerOrgLocation ||
      notification.quote?.sellerOrgLocation ||
      notification.invoice?.release?.sellerOrgLocation,
    [notification],
  );

  const preferredVendor = useMemo(
    () =>
      notification.release?.preferredVendor ||
      notification.buyout?.preferredVendor ||
      notification.quote?.preferredVendor ||
      notification.invoice?.release?.preferredVendor,
    [notification],
  );

  const resend = useCallback(() => {
    resendExternalNotification(notification.id);
  }, [notification, resendExternalNotification]);

  if (!notification) {
    return null;
  }

  return (
    <NotificationContainer $disabled>
      <VendorLogo
        logoImageUrl={sellerOrgLocation?.org.photoUrl}
        address={sellerOrgLocation?.address}
        contactName={getVendorContacts(
          preferredVendor?.contacts.filter((c) =>
            notification.release
              ? c.receivesOrderNotifications
                ? notification.buyout
                  ? c.receivesBuyoutNotifications
                  : notification.quote
                    ? c.receivesQuoteNotifications
                    : false
                : false
              : false,
          ),
        )}
        orgName={sellerOrgLocation?.org.name}
        index={index}
      />
      <DetailsContainer>
        <MessageBody>
          <Text>
            <Contacts>{notification.recipient}</Contacts>
            <Subject>{notification.subject}</Subject>
          </Text>
        </MessageBody>
      </DetailsContainer>
      <DateTimeView date={notification.createdAt} />
      <Tooltip
        id="resend-email-tooltip"
        element={
          <IconButtonStyled
            onClick={() =>
              openDialog({
                cancelButtonText: intl.$t({ id: "NO" }),
                confirmButtonText: intl.$t({ id: "YES" }),
                includeWarningIcon: true,
                title: intl.$t({ id: "RESEND_EMAIL" }),
                text: intl.$t({ id: "RESEND_EMAIL_MESSAGE" }),
                handleConfirm: resend,
              })
            }
          >
            <Replay />
          </IconButtonStyled>
        }
      >
        <FormattedMessage id="RESEND_EMAIL_TOOLTIP" />
      </Tooltip>
    </NotificationContainer>
  );
};
