export const getUserName = (
  user:
    | {
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email?: string | null | undefined;
      }
    | null
    | undefined,
) => {
  if (!user) {
    return "";
  }
  return user.firstName
    ? `${user.firstName}${user.lastName ? ` ${user.lastName}` : ""}`
    : user.email
      ? user.email
      : "";
};
