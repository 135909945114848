import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { useSyncReleaseItems } from "./useSyncReleaseItems";

type SubmitOptions = {
  callback?: () => void;
  validationKey: string;
};

export const useSubmitChanges = () => {
  const [loading, setLoading] = useState(false);
  const { hasChanges } = useReleaseStore();
  const { submitUpdate } = useReleaseUpdate();
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();
  const { syncReleaseItems } = useSyncReleaseItems();

  const submitChanges = useCallback(
    async ({ callback, validationKey }: SubmitOptions) => {
      setLoading(true);
      const isValid = true;
      if (hasChanges) {
        if (!isValid) {
          setWarningAlert(
            intl.$t({
              id: validationKey,
            }),
          );
          setLoading(false);
        }
        if (!(await syncReleaseItems())) {
          setLoading(false);
          return;
        }
        submitUpdate(undefined, {
          callback: (result) => {
            setLoading(false);
            if (result) {
              callback?.();
            } else {
              setWarningAlert(
                intl.$t({
                  id: validationKey,
                }),
              );
            }
          },
        });
        return;
      }
      setLoading(false);
      callback?.();
    },
    [hasChanges, intl, setWarningAlert, submitUpdate, syncReleaseItems],
  );

  return { submitChanges, submitting: loading };
};
