import { Select } from "@/common/components/select/components/single/Select";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid grid-flow-col items-center gap-2 p-0.5 px-1
`;

type Props = {
  setLoading: (loading: boolean) => void;
  update: (code: string) => Promise<void>;
  count: number;
};

export const BulkZoneSelector: FC<Props> = ({ setLoading, update, count }) => {
  const intl = useIntl();
  const { zones } = useProjectZones();
  const [zoneId, setZoneId] = useState<string | null>(null);

  const updateZone = useCallback(
    async (zone: string | null) => {
      if (!zone) {
        return;
      }

      setZoneId(zone);
      setLoading(true);
      await update(zone);
      setLoading(false);
    },
    [setLoading, update],
  );

  return (
    <Container>
      <FormattedMessage id="ASSIGN_ZONE_WITH_NUM" values={{ count }} />
      <Select
        testId="zone-selector"
        options={zones || []}
        className="w-72"
        value={zoneId}
        placeholder={intl.$t({ id: "SELECT_ZONE" })}
        InputLabelProps={{
          classes: {
            focused: "text-transparent",
          },
        }}
        onChange={(value) => updateZone(value as string)}
        getLabel={(option) => `${option.name}`}
        getValue={(option) => option.id}
        noOptionsText={intl.$t({ id: "NO_ZONES_FOUND" })}
      />
    </Container>
  );
};
