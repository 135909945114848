import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUnarchiveReleasesMutation } from "@/generated/graphql";
import { useCallback } from "react";

export const useRestoreReleases = () => {
  const { setError } = useGlobalError();

  const [unarchiveReleasesMutation, { loading }] =
    useUnarchiveReleasesMutation();
  const restoreReleases = useCallback(
    async (ids: string[]): Promise<boolean> => {
      try {
        const { data, errors } = await unarchiveReleasesMutation({
          variables: {
            ids,
          },
        });
        setError(errors);
        return Boolean(!!data?.unarchiveReleases && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, unarchiveReleasesMutation],
  );

  return { restoreReleases, loading };
};
