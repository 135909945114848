import { LOCAL_STORAGE_KEYS } from "@/common/const";
import {
  CategoryState,
  useToggleCategory,
} from "@/common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useBuyoutItemsGrouping } from "@/common/utils/hooks/useBuyoutItemsGrouping";
import { readValue, setValue } from "@/common/utils/localStorage";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type CostCodeCategory = CategoryState<DistributorBuyoutItemFieldsFragment>;

type ProviderContextType = {
  costCodes: CostCodeCategory[];
  toggleCostCode: (name: string) => void;
  groupedByCostCode: boolean;
  setGroupedByCostCode: (grouped: boolean) => void;
};

type Props = {
  items: DistributorBuyoutItemFieldsFragment[];
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  costCodes: [],
  toggleCostCode: NoFunction,
  groupedByCostCode: false,
  setGroupedByCostCode: NoFunction,
});

export const DistributorBuyoutItemsProvider: FC<Props> = ({
  children,
  items,
}) => {
  const [costCodes, setCostCodes] = useState<CostCodeCategory[]>([]);
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { toggleCategory } = useToggleCategory(costCodes, setCostCodes);

  const initialValue = readValue<boolean>(
    LOCAL_STORAGE_KEYS.GROUPED_BY_COST_CODE,
    true,
  );
  const [groupedByCostCode, setGroupedByCostCode] = useState(
    Boolean(initialValue),
  );
  const { getProjectCodes, filterBasedOnCostCodeAndPhaseCode } =
    useBuyoutItemsGrouping({
      items,
      forceUsePhaseCodes: items.some((buyoutItem) =>
        buyoutItem.tags.some((tag) => tag.hasMapping),
      ),
    });

  useEffect(() => {
    if (!groupedByCostCode) {
      setCostCodes([
        {
          id: unassignedCostCode.id,
          name: unassignedCostCode.description,
          isOpened: true,
          items:
            items.toSorted((a, b) => (a.position || 0) - (b.position || 0)) ??
            [],
        },
      ]);
      return;
    }
    const costCodeGroups = getProjectCodes();
    const costCodeCategories: CostCodeCategory[] = costCodeGroups.map(
      (costCode) => {
        return {
          id: costCode.id,
          name: costCode.description,
          isOpened: true,
          items: items
            .filter((i) => filterBasedOnCostCodeAndPhaseCode(i, costCode))
            .sort((a, b) => {
              if (
                a.projectItem.material.material.name <
                b.projectItem.material.material.name
              ) {
                return -1;
              }
              if (
                a.projectItem.material.material.name >
                b.projectItem.material.material.name
              ) {
                return 1;
              }
              return 0;
            }),
        };
      },
    );
    setCostCodes(costCodeCategories);
  }, [
    filterBasedOnCostCodeAndPhaseCode,
    getProjectCodes,
    groupedByCostCode,
    items,
    unassignedCostCode,
  ]);

  const setGroupedByCostCodeAndUpdateLocalStorage = useCallback(
    (grouped: boolean) => {
      setGroupedByCostCode(grouped);
      setValue(LOCAL_STORAGE_KEYS.GROUPED_BY_COST_CODE, grouped);
    },
    [],
  );

  return (
    <ProviderContext.Provider
      value={{
        costCodes,
        toggleCostCode: toggleCategory,
        groupedByCostCode,
        setGroupedByCostCode: setGroupedByCostCodeAndUpdateLocalStorage,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorBuyoutItemsProvider = (): ProviderContextType =>
  useContext(ProviderContext);
