import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ClearFiltersButton } from "@/common/components/clean-filters-button/ClearFiltersButton";
import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { If } from "@/common/components/if/If";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { ListStatusesFilter } from "@/common/components/list-statuses-filter/ListStatusesFilter";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { useProjectStatusOptions } from "@/common/hooks/useProjectStatusOptions";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import {
  DEFAULT_PROJECTS_FILTER,
  useProjects,
} from "@/contractor/pages/home/projects/providers/ProjectsProvider";
import { AuthorizationStatus } from "@/generated/graphql";
import { difference } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { SourceSystemWrapper } from "../../../../../common/components/source-system-wrapper/SourceSystemWrapper";
import { Filters, HeaderContainerStyled } from "../Projects.styles";
import { ProjectsAdditionalFilters } from "./ProjectsAdditionalFilters";
import { ToggleProjectForm } from "./ToggleProjectForm";
import { useImportExternalProjectsWizard } from "./import-external-projects/ImportExternalProjectsWizardProvider";

const Actions = tw.div`flex gap-3 items-center`;

export const ProjectsHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useProjects();
  const { openModal } = useImportExternalProjectsWizard();
  const { locations } = useUserLocations();
  const projectStatusOptions = useProjectStatusOptions();

  const canCreateProjects = useMemo(() => {
    return locations.some(
      (location) =>
        location.permissions.createProject === AuthorizationStatus.Authorized,
    );
  }, [locations]);

  const appliedFilters = useMemo(() => {
    return Number(filter?.deleted !== false);
  }, [filter?.deleted]);

  const appliedDateFilters = useMemo(() => {
    return Number(filter?.minStartDate != null || filter?.maxStartDate != null);
  }, [filter?.minStartDate, filter?.maxStartDate]);

  const countNonDefaultFilters = useMemo(() => {
    return (
      appliedFilters +
      appliedDateFilters +
      Number(filter?.name != null && filter?.name !== "") +
      Number(
        difference(DEFAULT_PROJECTS_FILTER.statuses, filter?.statuses || [])
          .length !== 0,
      )
    );
  }, [appliedDateFilters, appliedFilters, filter?.name, filter?.statuses]);

  const resetFilterToDefault = useCallback(() => {
    setFilter(DEFAULT_PROJECTS_FILTER);
  }, [setFilter]);

  return (
    <HeaderContainerStyled>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, name: value })}
          value={filter?.name}
          tooltip="PROJECTS_SEARCH_INFO"
          applyHeaderAccent
          clear={() => setFilter({ ...filter, name: undefined })}
        />
        <ListStatusesFilter
          options={projectStatusOptions}
          filter={filter}
          setFilter={setFilter}
          values={filter?.statuses || null}
          applyHeaderAccent
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<ProjectsAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ListDatesButton
          isDefault={appliedDateFilters === 0}
          appliedFilters={appliedDateFilters}
          hideDates
          dates={[
            {
              startDate: filter?.minStartDate,
              endDate: filter?.maxStartDate,
            },
          ]}
          clearValues={() =>
            setFilter({
              ...filter,
              minStartDate: undefined,
              maxStartDate: undefined,
            })
          }
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "START_DATE" }),
                  content: (
                    <DateRangePicker
                      open
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.minStartDate || filter?.maxStartDate
                          ? {
                              startDate: filter?.minStartDate
                                ? new Date(filter.minStartDate)
                                : new Date(),
                              endDate: filter?.maxStartDate
                                ? new Date(filter.maxStartDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minStartDate: dateRange.startDate?.getTime(),
                          maxStartDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: "startDate",
                },
              ]}
              activeTab="startDate"
            />
          )}
        </ListDatesButton>
        <ClearFiltersButton
          count={countNonDefaultFilters}
          onClick={resetFilterToDefault}
        />
      </Filters>
      <If isTrue={canCreateProjects}>
        <Actions>
          <SourceSystemWrapper anySourceSystem>
            <OutlinedButton onClick={openModal}>
              <FormattedMessage id="IMPORT" />
            </OutlinedButton>
          </SourceSystemWrapper>
          <ToggleProjectForm>
            <PrimaryButton>
              <FormattedMessage id="ADD_PROJECT" />
            </PrimaryButton>
          </ToggleProjectForm>
        </Actions>
      </If>
    </HeaderContainerStyled>
  );
};
