import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { MaterialView } from "@/common/components/material/MaterialView";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  MdFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import {
  BuyoutFieldsFragment,
  BuyoutStatus,
  ReleaseStatus,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BuyoutStandaloneReleaseItemAmountsCount } from "../../document/components/BuyoutStandaloneReleaseItemAmountsCount";
import { BuyoutStandaloneReleaseItemOrderedAmounts } from "../../document/components/BuyoutStandaloneReleaseItemOrderedAmounts";
import { BuyoutStandaloneReleaseItemReceivedAmounts } from "../../document/components/BuyoutStandaloneReleaseItemReceivedAmounts";
import { GroupStandaloneBuyoutItemsByCostCodeToggle } from "../GroupStandaloneBuyoutItemsByCostCodeToggle";
import { BuyoutStandaloneReleaseItemFieldsFragmentWithId } from "../buyout-standalone-release-items-list/BuyoutStandaloneReleaseItemsList";
import { BuyoutEmptyFooter } from "../footer/BuyoutEmptyFooter";
import { BuyoutPaymentFooter } from "../footer/BuyoutPaymentFooter";
import { BuyoutStandaloneItemsOrderedTotalFooter } from "../footer/BuyoutStandaloneItemsOrderedTotalFooter";
import { BuyoutStandaloneItemsReceivedTotalFooter } from "../footer/BuyoutStandaloneItemsReceivedTotalFooter";
import { BuyoutTitleColFooter } from "../footer/BuyoutTitleColFooter";
import { BuyoutRfqItemContainer } from "./BuyoutListItemsConfiguration";

export const useBuyoutStandaloneReleaseItems = ({
  buyout,
}: {
  buyout: BuyoutFieldsFragment | null;
}) => {
  const intl = useIntl();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);
  const showFooter =
    buyout?.status && [BuyoutStatus.Active].includes(buyout.status);
  const configuration: Array<
    GridCol<BuyoutStandaloneReleaseItemFieldsFragmentWithId>
  > = useMemo(
    () => [
      {
        wrapper: BuyoutRfqItemContainer,
        header: (
          <>
            <FormattedMessage id="ORDERED_OUTSIDE_OF_THIS_BUYOUT" />
            <GroupStandaloneBuyoutItemsByCostCodeToggle />
          </>
        ),
        item: ({ item, count, readonly }) => (
          <MaterialView
            material={item.orgMaterial.material}
            count={count}
            readonly={readonly}
            hideActions
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={buyout?.project?.id}
          />
        ),
        includesCounter: true,
        footer: showFooter && <BuyoutPaymentFooter />,
      },
      {
        header: <FormattedMessage id="ORDERED_QTY" />,
        wrapper: MdFixedContainer,
        position: "center",
        item: ({ item }) => (
          <ValueUnit
            value={item.orderedSoFar || 0}
            uom={item.uom}
            mnemonicFirst={false}
            classNames={{ footer: "truncate" }}
          />
        ),
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
        footer: showFooter && <BuyoutEmptyFooter Wrapper={MdFixedContainer} />,
      },
      {
        header: <FormattedMessage id="PRICE" />,
        wrapper: SmFixedContainer,
        position: "center",
        item: ({ item }) => (
          <ValueCurrency
            value={item.unitPrice || 0}
            perUom={item.uom}
            dynamicDigits
            mnemonicFirst={false}
          />
        ),
        hidden: !canViewPrices,
        footer: showFooter && <BuyoutTitleColFooter />,
      },
      {
        wrapper: MdFixedContainer,
        position: "end",
        item: () => <></>,
        hidden: !canViewPrices,
        footer: showFooter && <BuyoutEmptyFooter Wrapper={MdFixedContainer} />,
      },
      {
        header: <FormattedMessage id="ORDERED_SO_FAR" />,
        wrapper: MdFixedContainer,
        position: "end",
        item: ({ item }) => (
          <BuyoutStandaloneReleaseItemOrderedAmounts
            title={intl.$t({ id: "ORDERED_SO_FAR" })}
            item={item}
            releaseStatuses={[
              ReleaseStatus.Requested,
              ReleaseStatus.Scheduled,
              ReleaseStatus.Received,
              ReleaseStatus.PartiallyReceived,
            ]}
          />
        ),
        group: (category) => (
          <BuyoutStandaloneReleaseItemAmountsCount
            items={category.items}
            releaseStatuses={[
              ReleaseStatus.Requested,
              ReleaseStatus.Scheduled,
              ReleaseStatus.Received,
              ReleaseStatus.PartiallyReceived,
            ]}
          />
        ),
        hidden: checkBuyoutStatus(buyout, [BuyoutStatus.Draft]),
        footer: showFooter && (
          <BuyoutStandaloneItemsOrderedTotalFooter Wrapper={MdFixedContainer} />
        ),
      },
      {
        header: <FormattedMessage id="RECEIVED_SO_FAR" />,
        wrapper: MdFixedContainer,
        position: "end",
        item: ({ item }) => (
          <BuyoutStandaloneReleaseItemReceivedAmounts item={item} />
        ),
        group: (category) => (
          <BuyoutStandaloneReleaseItemAmountsCount
            items={category.items}
            releaseStatuses={[ReleaseStatus.Received]}
          />
        ),
        hidden: checkBuyoutStatus(buyout, [BuyoutStatus.Draft]),
        footer: showFooter && (
          <BuyoutStandaloneItemsReceivedTotalFooter
            Wrapper={MdFixedContainer}
          />
        ),
      },
    ],
    [buyout, canViewPrices, intl, showFooter],
  );

  return configuration;
};
