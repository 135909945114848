import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { sumTableUnitPrices } from "@/common/components/spreadsheet-table/utils/sumTableUnitPrices";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceCreateRelease } from "../../../../providers/InvoiceCreateReleaseProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "./InvoiceCreateRelease.config";

const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;

export const InvoiceCreateReleaseItemizedView = () => {
  const {
    importedItems,
    importedPoItemized,
    isNonItemizedPO,
    importedTaxCodeId,
    importedPoIsImmutable,
  } = useInvoiceImportExternalPO();
  const { setValue, watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { loading, invoiceItems } = useInvoiceCreateRelease();
  const spreadsheetViewColumns = useInvoiceCreateReleaseSpreadsheetConfig();
  const { calcTableTotal } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { setGlobalVendorId } = useVendorPrices();

  const netAmount = watch("netAmount");
  const vendorId = watch("vendorId");
  const projectId = watch("projectId");

  useSetCurrentProjectId(projectId);

  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = importedPoItemized
        ? calcTableTotal(data)
        : sumTableUnitPrices(data);
      if (newTotal !== netAmount) {
        setValue("netAmount", newTotal);
      }
    },
    [importedPoItemized, calcTableTotal, setValue, netAmount],
  );

  useEffect(() => {
    if (importedTaxCodeId) {
      setValue("taxCodeId", importedTaxCodeId);
    }
  }, [importedTaxCodeId, setValue]);

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const items = useMemo(() => {
    if (matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER) {
      if (importedPoItemized && isNonItemizedPO) {
        return invoiceItems;
      }
      return importedItems || [];
    }
    return importedItems ?? invoiceItems;
  }, [
    importedItems,
    invoiceItems,
    importedPoItemized,
    matchedOrderViewState,
    isNonItemizedPO,
  ]);

  return (
    <SpreadSheetView>
      <SpreadSheetTable
        items={items}
        columns={spreadsheetViewColumns}
        saving={loading}
        height="380px"
        onChanges={updateSubtotal}
        includePermissionCheck={false}
        readOnly={importedPoIsImmutable}
      />
    </SpreadSheetView>
  );
};
