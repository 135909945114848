import { ReleaseReassignmentFieldsFragment } from "@/generated/graphql";
import { useEffect } from "react";
import { useRelease } from "../../../providers/ReleaseProvider";

export const useLoadVendorReleaseReassignments = (
  setVendorReassignmentReleases: (
    newState: ReleaseReassignmentFieldsFragment[],
  ) => void,
) => {
  const { release } = useRelease();

  useEffect(() => {
    if (
      release &&
      release.originalVendorReassignmentRelease?.vendorReassignmentReleases
    ) {
      setVendorReassignmentReleases(
        release.originalVendorReassignmentRelease.vendorReassignmentReleases,
      );
    }
  }, [release, setVendorReassignmentReleases]);
};
