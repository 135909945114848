import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { OutlinedButton } from "../../../../../../../common/components/button/OutlinedButton";
import { If } from "../../../../../../../common/components/if/If";
import { InvoiceStatus } from "../../../../../../../generated/graphql";
import { useDistributorInvoice } from "../../../../providers/DistributorInvoiceProvider";
import { useUploadInvoiceModal } from "../../../../providers/UploadInvoiceProvider";

const ButtonContainer = tw.div`flex gap-1.5 h-10 justify-end items-center ml-auto`;

export const InvoiceFooterButtons = () => {
  const { invoice, resolvingInvoiceIssue: resolvingInvoiceIssues } =
    useDistributorInvoice();
  const { openUploadInvoiceModal } = useUploadInvoiceModal();

  return (
    <ButtonContainer>
      <If
        isTrue={
          invoice?.status === InvoiceStatus.IssuesReported ||
          invoice?.status === InvoiceStatus.AwaitingApproval
        }
      >
        <OutlinedButton
          className="py-0"
          onClick={openUploadInvoiceModal}
          disabled={resolvingInvoiceIssues}
        >
          <FormattedMessage id="INVOICE_CORRECTION_DESCRIPTION" />
        </OutlinedButton>
      </If>
    </ButtonContainer>
  );
};
