import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Progress } from "@/common/components/progress/Progress";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import {
  ExportBatchProvider,
  useExportBatch,
} from "@/common/providers/ExportBatchProvider";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { MissingBudgetCombinationsProvider } from "@/contractor/pages/home/release/components/connections/providers/MissingBudgetCombinationsProvider";
import { BatchType } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ExportCompleted,
  ExportType,
} from "../../../../../../../../common/components/export-completed/ExportCompleted";
import {
  ExportInvoiceProvider,
  useExportInvoice,
} from "../../providers/ExportInvoiceProvider";
import {
  InvoicesByIdsProvider,
  useInvoicesByIds,
} from "../../providers/InvoicesByIdsProvider";
import { InvoiceAgaveConnections } from "./InvoiceAgaveConnections";
import { InvoiceConnectionOptions } from "./InvoiceConnectionOptions";
const OverlayPanelStyled = tw(OverlayPanel)`justify-between gap-3`;
const CardList = tw.div`flex flex-col gap-3`;
const PanelTitle = tw.div`flex flex-row gap-1 items-center`;

type Props = {
  onClose: () => void;
  invoiceIds: string[];
};

const ConnectInvoicesPanelWithProvider: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { invoiceIds, loading, validatedInvoices } = useInvoicesByIds();
  const invoicesToExportCount = useMemo(
    () => validatedInvoices.filter((i) => i.validated).length,
    [validatedInvoices],
  );
  const { linking, linkInvoices } = useExportInvoice();
  const { openDialog } = useDialog();
  const { newBatch, externalBatch, postAutomatically } = useExportBatch();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const onSave = useCallback(async () => {
    const linkingResult = await linkInvoices();
    if (!linkingResult?.success) {
      return;
    }
    openDialog({
      content: (
        <ExportCompleted
          count={invoicesToExportCount}
          type={ExportType.INVOICE}
          batch={linkingResult.batch}
        />
      ),
      closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      hideTitle: true,
    });
    onClose();
  }, [openDialog, invoicesToExportCount, onClose, linkInvoices]);

  const disableSave = useMemo(() => {
    if (
      hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching) &&
      !postAutomatically &&
      !newBatch &&
      !externalBatch
    ) {
      return true;
    }
    return (
      invoicesToExportCount === 0 || validatedInvoices.some((v) => v.loading)
    );
  }, [
    externalBatch,
    hasFeatureInConnectedSourceSystem,
    invoicesToExportCount,
    newBatch,
    postAutomatically,
    validatedInvoices,
  ]);

  return (
    <OverlayPanelStyled
      title={
        <PanelTitle>
          {intl.$t({ id: "CONNECT_INVOICE_PANEL_TITLE" })}{" "}
          <InfoTooltip
            message={intl.$t({ id: "CONNECT_INVOICE_TITLE_INFO" })}
            id="title"
            iconClassName="text-white"
          />
        </PanelTitle>
      }
      isLoading={loading}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t({ id: "EXPORT" })}
      disableSave={disableSave}
    >
      <CardList>
        <InvoiceConnectionOptions
          invoice={invoiceIds[0]}
          mode={ConnectionMode.Connect}
        />
        <InvoiceAgaveConnections invoices={invoiceIds} linking={linking} />
      </CardList>
      <If isTrue={linking.linking && invoiceIds.length > 1}>
        <Progress
          progress={linking.percentage}
          label={intl.$t(
            { id: "EXPORTING_WITH_VALUE" },
            {
              value: (
                <FormattedNumber
                  value={linking.percentage * 100}
                  maximumFractionDigits={0}
                />
              ),
            },
          )}
        />
      </If>
    </OverlayPanelStyled>
  );
};

export const ConnectInvoicesPanel: FC<Props> = (props) => (
  <InvoicesByIdsProvider invoiceIds={props.invoiceIds}>
    <ExportBatchProvider type={BatchType.Invoice}>
      <ExportInvoiceProvider>
        <MissingBudgetCombinationsProvider>
          <ConnectInvoicesPanelWithProvider {...props} />
        </MissingBudgetCombinationsProvider>
      </ExportInvoiceProvider>
    </ExportBatchProvider>
  </InvoicesByIdsProvider>
);
