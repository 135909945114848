import TypeServices from "@/assets/icons/type-services.svg?react";
import Logo from "@/assets/images/Logo.svg?react";
import * as Sentry from "@sentry/react";
import { FC, PropsWithChildren } from "react";

export const SentryBoundary: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      onError={(error, errorInfo) => {
        console.error(error, errorInfo);
      }}
      fallback={
        <div className="grid h-screen justify-items-center bg-white">
          <div className="grid h-14 min-h-14 w-full grid-flow-col items-center justify-start bg-blue-800">
            <a
              href="/home"
              target="_self"
              rel="noopener noreferrer"
              className="grid h-full grid-flow-col items-center pl-2.5 text-white"
            >
              <Logo className="mr-1.5 h-6 w-10 text-white" />
              <span className="mr-5 hidden pt-0.5 text-base font-light uppercase leading-4 tracking-widest lg:inline-block print:hidden">
                Field <br /> Materials
              </span>
            </a>
            <a
              href="/home"
              target="_self"
              rel="noopener noreferrer"
              className="mr-auto grid h-full cursor-pointer grid-flow-col items-center px-3 py-5 text-sm text-white hover:bg-blue-500 lg:p-4 lg:px-6"
            >
              Home
            </a>
          </div>
          <div className="grid h-fit w-[90%] max-w-screen-xl justify-items-center rounded-3xl px-12 py-18 shadow-2xl xl:w-[920px]">
            <TypeServices className="mb-11 h-30 w-30 self-center justify-self-center text-yellow-600" />
            <p className="text-center">
              An unexpected error occurred. We&apos;ve received an automated
              report about this error and are working on resolving it. If the
              issue persists, please contact us at{" "}
              <a
                href="mailto:support@fieldmaterials.com"
                target="_blank"
                rel="noopene noreferrer"
                className="text-blue-500"
              >
                support@fieldmaterials.com
              </a>
              <div className="mt-6">
                <a
                  href="/home"
                  target="_self"
                  rel="noopener noreferrer"
                  className="rounded-4xl bg-blue-800 px-4 py-2 text-white"
                >
                  Return to Home Page
                </a>
              </div>
            </p>
          </div>
        </div>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
