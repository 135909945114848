import { UnarchiveOutlined } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { BaseButton } from "./BaseButton";
import { IconButtonBorderless } from "./IconButton";

const UnarchiveIcon = tw(UnarchiveOutlined)`w-5 h-5`;

type Props = React.ComponentProps<typeof BaseButton> & {
  iconClassName?: string;
};

export const UnarchiveButton: FC<Props> = ({ iconClassName, ...props }) => {
  return (
    <IconButtonBorderless autoScale {...props} testId="unarchive-button">
      <UnarchiveIcon className={iconClassName} />
    </IconButtonBorderless>
  );
};
