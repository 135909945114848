import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  CreateReleaseGroupInput,
  ReleaseGroupFieldsFragment,
  ReleasesDocument,
  UpdateReleaseGroupInput,
  useCreateOrderGroupMutation,
  useDeleteOrderGroupMutation,
  useUpdateOrderGroupMutation,
} from "@/generated/graphql";
import { useShallow } from "zustand/react/shallow";
import { useDeliveries } from "../providers/DeliveriesProvider";

export const useOrderGroups = () => {
  const [deleteFolderMutation, { loading: deleteFolderLoading }] =
    useDeleteOrderGroupMutation();
  const [createFolderMutation, { loading: createFolderLoading }] =
    useCreateOrderGroupMutation();
  const [updateFolderMutation, { loading: updateFolderLoading }] =
    useUpdateOrderGroupMutation();
  const { setError } = useGlobalError();
  const { releaseGroups, loading, updateStartupData } = useStartupDataStore(
    useShallow((state) => ({
      releaseGroups: state.releaseGroups,
      loading: state.loading,
      updateStartupData: state.updateStartupData,
    })),
  );
  const { paginationArgs, page, filter } = useDeliveries();

  const deleteGroup = async (id: string) => {
    try {
      const { errors } = await deleteFolderMutation({
        variables: { id },
        refetchQueries: [
          {
            query: ReleasesDocument,
            variables: {
              ...paginationArgs,
              page,
              filter,
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      setError(errors);
      updateStartupData({
        releaseGroups: releaseGroups.filter((f) => f.id !== id),
      });
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const createGroup = async (input: CreateReleaseGroupInput) => {
    try {
      const { data: createdData, errors } = await createFolderMutation({
        variables: { input },
      });

      setError(errors);
      updateStartupData({
        releaseGroups: [
          ...(releaseGroups || []),
          createdData?.createReleaseGroup as ReleaseGroupFieldsFragment,
        ],
      });
      if (createdData) {
        return createdData?.createReleaseGroup.id;
      }
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  const updateGroup = async (input: UpdateReleaseGroupInput) => {
    try {
      const { data: updatedData, errors } = await updateFolderMutation({
        variables: { input },
      });
      setError(errors);
      updateStartupData({
        releaseGroups: releaseGroups.map((f) =>
          f.id === input.id
            ? (updatedData?.updateReleaseGroup as ReleaseGroupFieldsFragment)
            : f,
        ),
      });
      if (updatedData) {
        return updatedData?.updateReleaseGroup.id;
      }
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  return {
    groups: releaseGroups || [],
    loading:
      loading ||
      deleteFolderLoading ||
      createFolderLoading ||
      updateFolderLoading,
    deleteGroup,
    createGroup,
    updateGroup,
  };
};
