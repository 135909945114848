import { FC, useCallback } from "react";
import {
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormTrigger,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { OutlinedButton } from "../../../../../../../../../common/components/button/OutlinedButton";
import { PrimaryButton } from "../../../../../../../../../common/components/button/PrimaryButton";
import { If } from "../../../../../../../../../common/components/if/If";
import { InvoiceFooterState } from "../../../../../../../../../common/components/invoices/invoice-details/types/InvoiceFooterState";
import { INVOICE_READONLY_STATUSES } from "../../../../../../../../../distributor/pages/invoices/providers/DistributorInvoiceProvider";
import { useAssignOrderToInvoice } from "../../../hooks/useAssignOrderToInvoice";
import { useInvoiceImportExternalPO } from "../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceCreateRelease } from "../../../providers/InvoiceCreateReleaseProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";
import { ButtonContainer } from "../InvoiceFooterButtons";

const ButtonSub = tw.div`text-2xs`;

type Props = {
  createReleaseFormTrigger: UseFormTrigger<InvoiceCreateReleaseFormValues>;
  createReleaseFormGetValues: UseFormGetValues<InvoiceCreateReleaseFormValues>;
  createReleaseFormHandleSubmit: UseFormHandleSubmit<
    InvoiceCreateReleaseFormValues,
    undefined
  >;
  fetchInvoicePermissions: (
    invoiceId: string,
    projectId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
};

export const CreateOrderButtons: FC<Props> = ({
  createReleaseFormTrigger,
  createReleaseFormGetValues,
  createReleaseFormHandleSubmit,
  fetchInvoicePermissions,
}) => {
  const { footerState, setFooterState, invoice } = useInvoiceVerification();
  const { matchedOrderViewState, setMatchedOrderViewState, selectedRelease } =
    useInvoiceMatchedOrder();
  const { assignOrderToInvoice, isAssigningOrderToInvoice } =
    useAssignOrderToInvoice();
  const { loading } = useInvoiceCreateRelease();
  const { importedPoItemized } = useInvoiceImportExternalPO();
  const { syncCreateReleaseFromInvoice, createRelease } =
    useInvoiceCreateRelease();

  const handleCreateReleaseFromInvoice = useCallback(async () => {
    await createReleaseFormHandleSubmit(
      async (values: InvoiceCreateReleaseFormValues) => {
        if (
          importedPoItemized ||
          matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
        ) {
          const sync = await syncCreateReleaseFromInvoice(values);
          if (!sync) {
            return null;
          }
        } else {
          const release = await createRelease(values);
          if (!release) {
            return null;
          }
        }
        if (invoice && !INVOICE_READONLY_STATUSES.includes(invoice.status)) {
          setMatchedOrderViewState(
            MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
          );
        } else {
          setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        }
        setFooterState(InvoiceFooterState.DEFAULT);
      },
    )();
  }, [
    createReleaseFormHandleSubmit,
    importedPoItemized,
    matchedOrderViewState,
    invoice,
    setFooterState,
    syncCreateReleaseFromInvoice,
    createRelease,
    setMatchedOrderViewState,
  ]);

  const recordOrder = useCallback(async () => {
    createReleaseFormTrigger();
    if (!createReleaseFormGetValues().projectId || !invoice?.id) {
      return;
    }
    const { data } = await fetchInvoicePermissions(
      invoice?.id,
      createReleaseFormGetValues().projectId,
    );
    if (!data) {
      return;
    }
    handleCreateReleaseFromInvoice();
  }, [
    fetchInvoicePermissions,
    createReleaseFormGetValues,
    handleCreateReleaseFromInvoice,
    invoice?.id,
    createReleaseFormTrigger,
  ]);

  if (
    footerState !== InvoiceFooterState.MATCH_ORDER &&
    footerState !== InvoiceFooterState.IMPORT_ORDER &&
    footerState !== InvoiceFooterState.CREATE_ORDER
  ) {
    return null;
  }
  return (
    <ButtonContainer>
      <OutlinedButton
        onClick={() => {
          setFooterState(InvoiceFooterState.DEFAULT);
          setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        }}
      >
        <FormattedMessage id="CLOSE" />
      </OutlinedButton>
      <If
        isTrue={
          footerState === InvoiceFooterState.CREATE_ORDER ||
          footerState === InvoiceFooterState.IMPORT_ORDER
        }
      >
        <PrimaryButton
          className="py-0"
          onClick={recordOrder}
          loading={loading}
          testId="record-order"
        >
          <FormattedMessage
            id={
              footerState === InvoiceFooterState.IMPORT_ORDER
                ? "IMPORT_ORDER"
                : "RECORD_ORDER"
            }
          />
          <If isTrue={footerState === InvoiceFooterState.CREATE_ORDER}>
            <ButtonSub>
              <FormattedMessage id="WITHOUT_VENDOR_CONFIRMATION" />
            </ButtonSub>
          </If>
        </PrimaryButton>
      </If>
      <If isTrue={footerState === InvoiceFooterState.MATCH_ORDER}>
        <PrimaryButton
          onClick={assignOrderToInvoice}
          disabled={!selectedRelease}
          loading={isAssigningOrderToInvoice}
        >
          <FormattedMessage id="CONFIRM" />
        </PrimaryButton>
      </If>
    </ButtonContainer>
  );
};
