import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Multiselect } from "../select/components/multiple/Multiselect";

type Props<T> = {
  onChange: (values: T[] | undefined) => void;
  selectedStatuses: T[] | undefined | null;
  options: { name: string; value: string }[];
  applyHeaderAccent?: boolean;
};

export const StatusesFilter = <T,>({
  selectedStatuses,
  options,
  onChange,
  applyHeaderAccent = false,
}: Props<T>) => {
  const intl = useIntl();

  const filterValues = useMemo(() => {
    if (selectedStatuses?.length) {
      return selectedStatuses as string[];
    }
    return null;
  }, [selectedStatuses]);

  return (
    <Multiselect
      testId="statusFilter"
      options={options}
      getLabel={(o) => o.name}
      getValue={(o) => o.value as string}
      values={filterValues}
      placeholder={filterValues?.length ? "" : intl.$t({ id: "ALL_STATUSES" })}
      onMultipleChange={(values: string[] | null) => {
        onChange(values?.length ? (values as T[]) : undefined);
      }}
      chipSize="small"
      includeCheckbox
      limitTags={1}
      disableCloseOnSelect
      className="min-w-45"
      applyHeaderAccent={applyHeaderAccent}
    />
  );
};
