import { IntegrationType } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { Select } from "../select/components/single/Select";

type EstimationSoftwareSelectorProps = {
  integrationTypes: IntegrationType[];
  onChange: (event: IntegrationType) => void;
  value: string;
  placeholder?: string;
};

export const INTEGRATION_TYPE_TRANSLATIONS = {
  [IntegrationType.Equotes]: "INTEGRATION_TYPE_ON_CENTER_EQUOTES",
  [IntegrationType.Oncenter]: "INTEGRATION_TYPE_ON_CENTER",
  [IntegrationType.Foundation]: "INTEGRATION_FOUNDATION",
  [IntegrationType.Sage300]: "INTEGRATION_SAGE300",
  [IntegrationType.Custom]: "",
  [IntegrationType.Edge]: "",
};

export const EstimationSoftwareSelector: FC<
  EstimationSoftwareSelectorProps
> = ({ onChange, value, integrationTypes, placeholder }) => {
  const intl = useIntl();
  const estimationSoftwareList = useMemo(() => {
    return [
      {
        id: IntegrationType.Equotes,
        name: intl.$t({ id: INTEGRATION_TYPE_TRANSLATIONS.EQUOTES }),
      },
      {
        id: IntegrationType.Oncenter,
        name: intl.$t({ id: INTEGRATION_TYPE_TRANSLATIONS.ONCENTER }),
      },
      {
        id: IntegrationType.Foundation,
        name: intl.$t({ id: INTEGRATION_TYPE_TRANSLATIONS.FOUNDATION }),
      },
    ].filter((item) => integrationTypes.includes(item.id));
  }, [integrationTypes, intl]);

  return (
    <Select
      value={value}
      testId="source-selector"
      placeholder={placeholder || intl.$t({ id: "SELECT_ESTIMATION_SOFTWARE" })}
      onChange={(value: string | null) =>
        onChange((value || "") as IntegrationType)
      }
      options={estimationSoftwareList}
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      required
    />
  );
};
