import { FC, memo, PropsWithChildren, ReactNode } from "react";
import tw from "tailwind-styled-components";

export const NOT_NULLABLE_DEFAULT_VALUE = "--";
const DefaultValue = tw.span``;

type NotNullableRendererProps = PropsWithChildren<{
  value?: unknown;
  defaultValue?: string | ReactNode;
  testId?: string;
}>;

export const NotNullableRenderer: FC<NotNullableRendererProps> = memo(
  ({ children, value, defaultValue = NOT_NULLABLE_DEFAULT_VALUE, testId }) => (
    <>
      {value ? (
        children || (value as ReactNode)
      ) : (
        <DefaultValue data-testid={testId}>{defaultValue}</DefaultValue>
      )}
    </>
  ),
);
