import { If } from "@/common/components/if/If";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { BuyoutReleaseFieldsFragment } from "@/generated/graphql";
import { Error } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Validations = tw.div`flex-1 lg:grid`;
const ErrorContainer = tw.div`flex text-red-500 items-center gap-2`;

export const ReleaseValidations: FC<{
  release: BuyoutReleaseFieldsFragment;
}> = ({ release }) => {
  const isMissingCostCode = useMemo(() => {
    return release.hasMissingCostCodes;
  }, [release]);
  const { hasPhaseCodes } = useOrgSettings();

  return (
    <Validations>
      <If isTrue={isMissingCostCode && !hasPhaseCodes}>
        <ErrorContainer>
          <Error />
          <FormattedMessage id="MISSING_COST_CODES" />
        </ErrorContainer>
      </If>
    </Validations>
  );
};
