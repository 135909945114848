import {
  EquipmentItemFieldsFragment,
  ServiceCodeFieldsFragment,
} from "@/generated/graphql";
import tw from "tailwind-styled-components";

const Item = tw.div`py-2 flex text-wrap max-w-[400px]`;
const Code = tw.div`font-medium mr-1`;

export const EquipmentItemServiceCodeSelectorItem = (
  item: EquipmentItemFieldsFragment | ServiceCodeFieldsFragment,
) => (
  <Item>
    <Code>({item.code})</Code>
    {item.description}
  </Item>
);
