import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { PoFormat, UpdateInvoicedReleaseItemInput } from "@/generated/graphql";
import { CheckCircleOutline } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import Decimal from "decimal.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PiWarningCircleBold } from "react-icons/pi";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceValidation } from "../../../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceExtPrices } from "../../../../hooks/useInvoiceExtPrices";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`relative`;
const ReadonlyField = tw.div`flex flex-col items-center text-xs`;
const CheckIcon = tw(
  CheckCircleOutline,
)`text-green-600 absolute left-1 top-1/2 transform -translate-y-1/2 text-lg`;
const InvoiceSoFarLabel = tw.span`text-[9px] text-gray-600`;
const InvoiceSoFarLabelError = tw(InvoiceSoFarLabel)`text-red-500`;
const InfoIcon = tw(
  PiWarningCircleBold,
)`text-lg absolute right-1 top-1/2 transform -translate-y-1/2
${({ $isNegative }: { $isNegative: boolean }) => ($isNegative ? "text-red-500" : "")}`;
const Error = tw.span`text-red-500`;
const TooltipItem = tw.li``;

export const InvoiceExtPriceInput = ({
  item,
}: {
  item: ExpandedReleaseItem;
}) => {
  const { matchedOrderViewState, useInvoiceItemPrice } =
    useInvoiceMatchedOrder();
  const { updateInvoice, invoice } = useInvoiceVerification();
  const { calcExtPrice, calcUnitPrice } = usePriceCalculation();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { loading } = useInvoiceValidation();

  const { extPrice: initialExtPrice, invoicedItem } = useInvoiceExtPrices(item);
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { invoiceValidation } = useInvoiceValidation();
  const { hasPhaseCodes } = useOrgSettings();

  const [extPrice, setExtPrice] = useState<string>(initialExtPrice);

  useEffect(() => {
    if (!isLumpSumItem(item)) {
      setExtPrice(initialExtPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialExtPrice, useInvoiceItemPrice]);

  const onSave = useCallback(
    async (extPrice: string | null) => {
      if (extPrice) {
        setExtPrice(extPrice);
      }

      if (!invoicedItem) {
        return;
      }

      let updatedItem: UpdateInvoicedReleaseItemInput & {
        releaseItemId: string;
        quantitySoFar?: string | null;
      } = {
        id: invoicedItem.id,
        releaseItemId: item.id,
        quantitySoFar: invoicedItem.quantitySoFar || "0",
      };
      if (isLumpSumItem(item)) {
        updatedItem = {
          ...updatedItem,
          unitPrice: "1",
          quantity: extPrice ? extPrice.toString() : undefined,
        };
      } else {
        updatedItem = {
          ...updatedItem,
          quantity: invoicedItem.quantity ?? undefined,
          unitPrice: calcUnitPrice(
            invoicedItem.quantity ?? 0,
            extPrice,
          ).toString(),
        };
      }

      await updateInvoice(
        {
          id: invoice?.id || "",
          updatedInvoicedReleaseItems: [updatedItem],
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
    },
    [
      item,
      invoice?.id,
      invoice?.release?.id,
      invoicedItem,
      updateInvoice,
      calcUnitPrice,
    ],
  );

  const inputClassName = useMemo(() => {
    if (!isLumpSumItem(item)) {
      return "text-blue-800 bg-white relative";
    }

    if (
      new Decimal(extPrice)
        .plus(invoicedItem?.quantitySoFar ?? 0)
        .greaterThan(item.quantityDecimal)
    ) {
      return "text-red-500 bg-white relative";
    }
    return "text-blue-800 bg-white relative";
  }, [extPrice, invoicedItem?.quantitySoFar, item]);

  const isFullyInvoiced = useMemo(() => {
    return (
      isLumpSumItem(item) &&
      Number(extPrice) > 0 &&
      new Decimal(item.quantityDecimal)
        .sub(invoicedItem?.quantitySoFar || 0)
        .equals(Number(extPrice))
    );
  }, [extPrice, invoicedItem?.quantitySoFar, item]);

  const quantitySoFar = useMemo(() => {
    return new Decimal(item.invoiceItems?.[0]?.quantitySoFar || "0").plus(
      item.invoiceItems?.[0]?.quantity || "0",
    );
  }, [item.invoiceItems]);

  const usingExternalPoRemainingAmount = useMemo(
    () =>
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.POItemRemainingAmount,
      ) &&
      !!invoice?.release?.poLink &&
      invoice.release.type.poFormat === PoFormat.Detail &&
      !hasPhaseCodes,
    [
      hasFeatureInConnectedSourceSystem,
      invoice?.release?.poLink,
      invoice?.release?.type.poFormat,
      hasPhaseCodes,
    ],
  );

  const externalPoItem = useMemo(
    () =>
      invoiceValidation?.po?.items.find(
        (externalItem) =>
          externalItem.externalId === item.poItemLink?.poItemExternalId,
      ),
    [invoiceValidation?.po?.items, item.poItemLink?.poItemExternalId],
  );

  const currentCoverage = useMemo(
    () =>
      invoice?.link
        ? 0
        : calcExtPrice(item.invoiceItems?.[0]?.quantity, item.unitPrice),
    [invoice?.link, calcExtPrice, item.invoiceItems, item.unitPrice],
  );

  const remaining = useMemo(() => {
    if (usingExternalPoRemainingAmount && externalPoItem) {
      return new Decimal(externalPoItem.remainingAmount || 0)
        .minus(item.invoicedRemainingAdjustment || 0)
        .minus(currentCoverage)
        .toNumber();
    }

    const releaseItemExtPrice = calcExtPrice(
      item.unitPrice,
      item.quantityDecimal,
    );
    return new Decimal(releaseItemExtPrice)
      .minus(item.invoicedRemainingAdjustment || 0)
      .minus(currentCoverage)
      .toNumber();
  }, [
    usingExternalPoRemainingAmount,
    externalPoItem,
    calcExtPrice,
    item.unitPrice,
    item.quantityDecimal,
    item.invoicedRemainingAdjustment,
    currentCoverage,
  ]);

  const isRemainingNegative = useMemo(() => remaining < 0, [remaining]);

  const remainingAmount = useMemo(() => {
    if (usingExternalPoRemainingAmount) {
      return new Decimal(externalPoItem?.remainingAmount || 0).toNumber();
    }
  }, [externalPoItem?.remainingAmount, usingExternalPoRemainingAmount]);

  const pendingInvoicesTotal = useMemo(() => {
    if (usingExternalPoRemainingAmount) {
      return new Decimal(item.invoicedRemainingAdjustment || 0)
        .plus(currentCoverage)
        .toNumber();
    }
  }, [
    currentCoverage,
    item.invoicedRemainingAdjustment,
    usingExternalPoRemainingAmount,
  ]);

  const invoiceTotal = useMemo(() => {
    if (usingExternalPoRemainingAmount) {
      return;
    }
    return new Decimal(item.invoicedRemainingAdjustment || 0)
      .plus(extPrice || 0)
      .toNumber();
  }, [
    extPrice,
    item.invoicedRemainingAdjustment,
    usingExternalPoRemainingAmount,
  ]);

  const tooltipItems = useMemo(() => {
    if (usingExternalPoRemainingAmount) {
      return [
        {
          id: "INVOICE_TOOLTIP_REMAINING_AMOUNT_FOR_PO_ITEM",
          values: {
            value: formatCurrency(remainingAmount),
          },
        },
        {
          id: "INVOICE_TOOLTIP_PENDING_TOTAL",
          values: {
            value: formatCurrency(pendingInvoicesTotal),
          },
        },
      ];
    }
    return [
      {
        id: "INVOICE_TOOLTIP_TOTAL",
        values: {
          value: formatCurrency(invoiceTotal),
        },
      },
    ];
  }, [
    usingExternalPoRemainingAmount,
    formatCurrency,
    invoiceTotal,
    remainingAmount,
    pendingInvoicesTotal,
  ]);

  return (
    <If isTrue={(item.invoiceItems || []).length > 0}>
      <ReadonlyField>
        {(matchedOrderViewState ===
          MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
          isLumpSumItem(item)) ||
        useInvoiceItemPrice ? (
          <ReadonlyField>
            <Container>
              <PricePicker
                value={extPrice}
                onBlur={onSave}
                className={inputClassName}
                helperText={
                  <FormattedMessage
                    id="REMAINING_VALUE"
                    values={{
                      value: formatCurrency(remaining),
                    }}
                    tagName={isRemainingNegative ? Error : undefined}
                  />
                }
              />
              {isFullyInvoiced && <CheckIcon />}
            </Container>
          </ReadonlyField>
        ) : (
          <>
            <Tooltip
              hideTooltip={!quantitySoFar.greaterThan(item.quantityDecimal)}
              element={
                <Price
                  price={calcExtPrice(
                    item.invoiceItems?.[0]?.quantity,
                    item.unitPrice,
                  )}
                  className={
                    quantitySoFar.greaterThan(item.quantityDecimal)
                      ? "text-red-500"
                      : ""
                  }
                />
              }
              id="invoiced-ordered-quantity"
            >
              <FormattedMessage id="INVOICED_AMOUNT_GREATER_THAN_ORDERED_AMOUNT" />
            </Tooltip>

            {loading ? (
              <div className="w-16">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />{" "}
              </div>
            ) : (
              <FormattedMessage
                id="REMAINING_VALUE"
                tagName={
                  isRemainingNegative
                    ? InvoiceSoFarLabelError
                    : InvoiceSoFarLabel
                }
                values={{
                  value: formatCurrency(remaining),
                }}
              />
            )}
          </>
        )}
      </ReadonlyField>
      <Tooltip
        id="po-amounts"
        noWrap
        element={<InfoIcon $isNegative={isRemainingNegative} />}
      >
        {tooltipItems.map((item) => (
          <FormattedMessage
            key={item.id}
            id={item.id}
            values={item.values}
            tagName={TooltipItem}
          />
        ))}
      </Tooltip>
    </If>
  );
};
