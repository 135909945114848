import { useMemo } from "react";
import { CategoryType } from "../enums/CategoryType";
import {
  CategoryCard as DefaultCategoryCard,
  CategoryCardContent as DefaultCategoryCardContent,
  SubCategoryCard,
  SubCategoryCardContent,
} from "../GridTable.styles";

export const useCategoryCards = (categoryType: CategoryType | undefined) => {
  const { CategoryCard, CategoryCardContent } = useMemo(() => {
    return categoryType === CategoryType.THREE_LEVELS
      ? {
          CategoryCard: DefaultCategoryCard,
          CategoryCardContent: DefaultCategoryCardContent,
        }
      : {
          CategoryCard: SubCategoryCard,
          CategoryCardContent: SubCategoryCardContent,
        };
  }, [categoryType]);

  return { CategoryCard, CategoryCardContent };
};
