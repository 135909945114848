import { isBefore, startOfDay } from "date-fns";

export const isRetroactiveRelease = (
  date: number | undefined | Date | null,
) => {
  if (date === undefined || date === null) {
    return false;
  }

  const now = startOfDay(new Date());
  const dateToCompare = startOfDay(new Date(date));

  return isBefore(dateToCompare, now);
};
