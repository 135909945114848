import { GroupTagPicker } from "@/common/components/groups/components/group-tag-picker/GroupTagPicker";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useInvoicesUpdate } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoicesUpdate";
import { useReceipt } from "@/contractor/pages/home/receipts/pages/receipt-record-order/providers/ReceiptProvider";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

const MORE_TAG_COUNT = 2;

export const ReceiptGroups = () => {
  const { invoiceFolders } = useStartupDataStore(
    useShallow((state) => ({ invoiceFolders: state.invoiceFolders })),
  );
  const { receipt } = useReceipt();
  const { updateInvoices, loading } = useInvoicesUpdate();

  const onRemove = useCallback(
    async (group: { id: string }) => {
      if (!receipt) {
        return;
      }
      await updateInvoices({
        invoiceIds: [receipt.id],
        unassignedFolderIds: [group.id],
      });
    },
    [receipt, updateInvoices],
  );

  const onAdd = useCallback(
    async (selectedFolderId: string | null) => {
      if (!receipt || !selectedFolderId) {
        return;
      }
      await updateInvoices({
        invoiceIds: [receipt.id],
        assignedFolderIds: [selectedFolderId],
      });
    },
    [receipt, updateInvoices],
  );

  if (!receipt) {
    return null;
  }

  return (
    <GroupTagPicker
      groups={invoiceFolders}
      existingGroups={receipt.folders}
      moreTagCount={MORE_TAG_COUNT}
      onRemove={onRemove}
      onAdd={onAdd}
      loading={loading}
      placeholders={{ add: "ADD_TO_RECEIPT_GROUP" }}
    />
  );
};
