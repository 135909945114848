import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterItemInputContainer,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { useTaxType } from "@/common/components/sales-tax-input/hooks/useTaxType";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

type Props = {
  includeAdditionalCharges?: boolean;
  includeLabels?: boolean;
};

const TitleNormal = tw.div`text-right`;
const Title = tw.div`font-medium`;
const Container = tw.div`text-sm whitespace-nowrap`;

export const BuyoutTitleColFooter: FC<Props> = ({
  includeAdditionalCharges,
  includeLabels,
}) => {
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);
  const { buyout } = useContractorBuyout();
  const { useTaxKey } = useTaxType(buyout?.releaseType.id, [], buyout?.taxType);

  if (!canViewPrices) {
    return null;
  }

  return (
    <FooterCol className="min-w-[50px] flex-col items-end">
      <If isTrue={includeAdditionalCharges}>
        <FooterItemInputContainer>
          <If isTrue={includeLabels}>
            <FormattedMessage
              id="ADDITIONAL_CHARGES_ALLOWANCE"
              tagName={Container}
            />
          </If>
        </FooterItemInputContainer>
      </If>
      <FooterHeader />
      <FooterCell>
        <FormattedMessage id="SUBTOTAL" tagName={TitleNormal} />:
      </FooterCell>
      <FooterCell>
        <TitleNormal>
          <FormattedMessage id={useTaxKey} />:
        </TitleNormal>
      </FooterCell>
      <FooterDashedLine />
      <FooterTotal>
        <FooterCellCol className="items-end">
          <Title>
            <FormattedMessage id="TOTAL" />:
          </Title>
          <FormattedMessage
            id="INCL_TAXES_AND_FEES"
            tagName={FooterHeaderText}
          />
        </FooterCellCol>
      </FooterTotal>
    </FooterCol>
  );
};
