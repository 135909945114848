import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { GridSortDirection } from "@/common/components/grid-table/types/GridSortDirection";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { ProjectNameListDetails } from "@/common/components/projects-filter-selector/ProjectNameListDetails";
import { InvoiceStatusChip } from "@/common/components/statuses/InvoiceStatusChip";
import { TypeIndicator } from "@/common/components/type-indicator/TypeIndicator";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import {
  Base,
  LogoContainer,
  MdContainer,
  MdFixedContainer,
  SmContainer,
  SmFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { getUserName } from "@/common/utils/users/getUserName";
import { InvoiceOrderAndBuyout } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/list/InvoiceOrderAndBuyout";
import {
  InvoiceType,
  QueryInvoicesSortBy,
  ReceiptSummaryFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceipts } from "../../providers/ReceiptsProvider";
import { ReceiptActions } from "./ReceiptActions";
import { ReceiptAllItemsCheckbox } from "./ReceiptAllItemsCheckbox";
import { ReceiptCheckbox } from "./ReceiptCheckbox";
import { ReceiptScanDate } from "./ReceiptScanDate";
import { ReceiptTransactionDate } from "./ReceiptTransactionDate";

const Item = tw.div`text-left text-sm`;
const ActionsContainer = tw(Base)`w-10 basis-10`;
const FoldersContainer = tw.div`grid`;
const FolderContainer = tw.div`text-gray-600 text-xs w-full`;

export const useReceiptsConfiguration = () => {
  const { receipts } = useReceipts();

  const configuration: Array<GridCol<ReceiptSummaryFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: XxsFixedContainer,
        position: "center",
        item: ({ item }) => <ReceiptCheckbox receipt={item} />,
        header: <ReceiptAllItemsCheckbox />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <NotNullableRenderer value={item.folders.length}>
            <FoldersContainer>
              {item.folders.map((folder) => (
                <FolderContainer key={folder.id}>{folder.name}</FolderContainer>
              ))}
            </FoldersContainer>
          </NotNullableRenderer>
        ),
        header: <FormattedMessage id="RECEIPT_GROUPS" />,
        hidden: receipts.every((receipt) => receipt.folders.length === 0),
      },
      {
        wrapper: SmContainer,
        item: ({ item }) => <ReceiptScanDate receipt={item} />,
        header: <FormattedMessage id="SCAN_DATE" />,
        sortKey: QueryInvoicesSortBy.CreatedAt,
        defaultSort: GridSortDirection.Descending,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="SUBMITTED_BY" />,
        item: ({ item }) => (
          <NotNullableRenderer value={item.createdBy}>
            <Item>{getUserName(item.createdBy)}</Item>
          </NotNullableRenderer>
        ),
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => <ReceiptTransactionDate receipt={item} />,
        header: <FormattedMessage id="TRANSACTION_DATE" />,
        sortKey: QueryInvoicesSortBy.IssueDate,
        defaultSort: GridSortDirection.Descending,
      },
      {
        wrapper: MdContainer,
        header: <FormattedMessage id="PROJECT" />,
        item: ({ item }) => (
          <NotNullableRenderer
            value={item.project?.name ?? item.release?.project?.name}
          >
            <ProjectNameListDetails
              project={item.project || item.release?.project}
            />
          </NotNullableRenderer>
        ),
      },
      {
        wrapper: LogoContainer,
        header: <FormattedMessage id="VENDOR" />,
        item: ({ item, index }) => (
          <VendorLogo
            logoImageUrl={item.release?.sellerOrgLocation?.org.photoUrl}
            address={item.release?.sellerOrgLocation?.address}
            contactName={getVendorContacts(
              item.release?.preferredVendor?.contacts.filter(
                (c) => c.receivesInvoiceNotifications,
              ),
            )}
            orgName={item.release?.sellerOrgLocation?.org.name}
            index={index}
          />
        ),
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="ORDER" />,
        item: ({ item }) => (
          <NotNullableRenderer value={item.release}>
            <InvoiceOrderAndBuyout
              item={item}
              disabled
              type={InvoiceType.Receipt}
            />
          </NotNullableRenderer>
        ),
      },
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => <TypeIndicator orderType={item?.release?.type} />,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="RECEIPT_TOTAL" />,
        item: ({ item }) => <Price price={item.total} className="text-sm" />,
        position: "center",
        sortKey: QueryInvoicesSortBy.Total,
        defaultSort: GridSortDirection.Descending,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="STATUS" />,
        item: ({ item }) => <InvoiceStatusChip status={item.status} />,
        position: "center",
      },
      {
        wrapper: ActionsContainer,
        item: ({ item }) => <ReceiptActions receipt={item} />,
        position: "end",
      },
    ],
    [receipts],
  );

  return configuration;
};
