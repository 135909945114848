import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ApproveInvoiceInput,
  InvoiceStatsDocument,
  useApproveReceiptMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useApproveReceipt = () => {
  const { setError } = useGlobalError();
  const [approveReceiptMutation, { loading }] = useApproveReceiptMutation();

  const approveReceipt = useCallback(
    async (input: ApproveInvoiceInput) => {
      try {
        const { data, errors } = await approveReceiptMutation({
          variables: {
            input,
          },
          refetchQueries: [{ query: InvoiceStatsDocument }],
        });
        setError(errors);
        return Boolean(!!data?.approveReceipt && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [approveReceiptMutation, setError],
  );

  return {
    approveReceipt,
    loading,
  };
};
