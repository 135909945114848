import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useReceipts } from "@/contractor/pages/home/receipts/pages/receipts/providers/ReceiptsProvider";
import {
  CreateInvoiceFolderInput,
  InvoiceFolderFieldsFragment,
  InvoiceType,
  UpdateInvoiceFolderInput,
  useCreateInvoiceFolderMutation,
  useDeleteInvoiceFolderMutation,
  useUpdateInvoiceFolderMutation,
} from "@/generated/graphql";
import { useShallow } from "zustand/react/shallow";
import { useInvoices } from "../../../providers/InvoicesProvider";

export const useInvoiceFolders = (type: InvoiceType) => {
  const [deleteFolderMutation, { loading: deleteFolderLoading }] =
    useDeleteInvoiceFolderMutation();
  const [createFolderMutation, { loading: createFolderLoading }] =
    useCreateInvoiceFolderMutation();
  const [updateFolderMutation, { loading: updateFolderLoading }] =
    useUpdateInvoiceFolderMutation();
  const { setError } = useGlobalError();
  const { invoiceFolders, loading, updateStartupData } = useStartupDataStore(
    useShallow((state) => ({
      invoiceFolders: state.invoiceFolders,
      loading: state.loading,
      updateStartupData: state.updateStartupData,
    })),
  );
  const { refetchInvoices } = useInvoices();
  const { refetchReceipts } = useReceipts();

  const deleteFolder = async (id: string) => {
    try {
      const { errors } = await deleteFolderMutation({
        variables: { id },
      });

      setError(errors);
      updateStartupData({
        invoiceFolders: invoiceFolders.filter((f) => f.id !== id),
      });
      if (type === InvoiceType.Invoice) {
        await refetchInvoices();
      } else {
        await refetchReceipts();
      }
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const createFolder = async (input: CreateInvoiceFolderInput) => {
    try {
      const { data: createdData, errors } = await createFolderMutation({
        variables: { input },
      });

      setError(errors);
      updateStartupData({
        invoiceFolders: [
          ...(invoiceFolders || []),
          createdData?.createInvoiceFolder as InvoiceFolderFieldsFragment,
        ],
      });
      if (createdData) {
        return createdData?.createInvoiceFolder.id;
      }
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  const updateFolder = async (input: UpdateInvoiceFolderInput) => {
    try {
      const { data: updatedData, errors } = await updateFolderMutation({
        variables: { input },
      });
      setError(errors);
      updateStartupData({
        invoiceFolders: invoiceFolders.map((f) =>
          f.id === input.id
            ? (updatedData?.updateInvoiceFolder as InvoiceFolderFieldsFragment)
            : f,
        ),
      });
      if (updatedData) {
        return updatedData?.updateInvoiceFolder.id;
      }
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  return {
    folders: invoiceFolders,
    loading:
      loading ||
      deleteFolderLoading ||
      createFolderLoading ||
      updateFolderLoading,
    deleteFolder,
    createFolder,
    updateFolder,
  };
};
