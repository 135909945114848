import { useLayoutEffect, useRef } from "react";
import { useExpandableFooterStore } from "../stores/useExpandableFooterStore";

export const useExpandableFooterRef = () => {
  const { setHeight } = useExpandableFooterStore();
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setHeight?.(ref.current?.clientHeight ?? 0);
    return () => {
      setHeight?.(0);
    };
  }, [setHeight]);

  return ref;
};
