import {
  CheckCircleOutlineRounded,
  SettingsSuggestOutlined,
} from "@mui/icons-material";
import tw from "tailwind-styled-components";

export enum SystemAlertType {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

interface SystemAlertProps {
  messages: string[];
  type?: SystemAlertType;
}

const AlertContainer = tw.div`bg-green-800 flex`;
const AlertIconContainer = tw.div`bg-white w-20 min-h-20 flex justify-center items-center mr-4`;
const AlertInfoContainer = tw.div`flex flex-col gap-2 flex-1 py-4 justify-center`;
const AlertInfoBlock = tw.p`text-white`;
const GearIcon = tw(SettingsSuggestOutlined)`w-10 h-10 text-green-800`;
const CheckCircleOutlineRoundedStyled = tw(
  CheckCircleOutlineRounded,
)`w-10 h-10 text-green-800`;

export const SystemAlert = ({
  messages,
  type = SystemAlertType.AUTOMATIC,
}: SystemAlertProps) => {
  return (
    <AlertContainer>
      <AlertIconContainer>
        {type === SystemAlertType.AUTOMATIC ? (
          <GearIcon />
        ) : (
          <CheckCircleOutlineRoundedStyled />
        )}
      </AlertIconContainer>
      <AlertInfoContainer>
        {messages.map((message, index) => (
          <AlertInfoBlock key={index}>{message}</AlertInfoBlock>
        ))}
      </AlertInfoContainer>
    </AlertContainer>
  );
};
