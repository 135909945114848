import { useEffect } from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useFormContext,
} from "react-hook-form";

export const useUpdateFormFieldBasedOnValue = <T extends FieldValues>(
  value: FieldPathValue<T, FieldPath<T>>,
  fieldName: FieldPath<T>,
) => {
  const { setValue } = useFormContext<T>();
  useEffect(() => {
    if (value) {
      setValue(fieldName, value);
    }
  }, [value, fieldName, setValue]);
};
