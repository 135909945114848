import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import { useEquipmentItemsQuery } from "@/generated/graphql";

export const useEquipmentItems = () => {
  const { isContractor } = useUser();
  const { data, error, loading } = useEquipmentItemsQuery({
    skip: !isContractor,
  });
  useErrorEffect(error);

  return {
    equipmentItems: data?.viewer?.org?.equipmentItems || [],
    loading,
  };
};
