import { GroupBySegment } from "@/common/components/group-by-segment/GroupBySegment";
import { FC } from "react";
import { useBuyoutGrouped } from "../document/providers/BuyoutGroupedProvider";

export const GroupQuotedBuyoutByCostCodeToggle: FC = () => {
  const { groupedByCostCode, setGroupedByCostCode } = useBuyoutGrouped();
  return (
    <GroupBySegment
      groupedByCostCode={groupedByCostCode}
      setGroupedByCostCode={setGroupedByCostCode}
    />
  );
};
