import { WatcherTagPicker } from "@/common/components/watchers/WatcherTagPicker";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

type Props = {
  moreTagCount?: number;
  verticalItems?: boolean;
  readonly?: boolean;
  name: string;
  users: UsersUserFieldsFragment[];
};

export const ReleaseWatchersControlled = ({
  moreTagCount,
  verticalItems = false,
  name,
  readonly,
  users,
}: Props) => {
  const intl = useIntl();
  const { watch, setValue } = useFormContext();

  const watcherIds = watch(name) as string[] | undefined;

  const existingWatchers = useMemo(() => watcherIds || [], [watcherIds]);

  const onRemove = useCallback(
    (id: string) => {
      setValue(
        name,
        existingWatchers.filter((watcher) => watcher !== id),
      );
    },
    [existingWatchers, name, setValue],
  );

  const onAdd = useCallback(
    (userId: string | null) => {
      if (userId) {
        setValue(name, [
          ...existingWatchers.filter((watcher) => watcher !== userId),
          userId,
        ]);
      }
    },
    [existingWatchers, name, setValue],
  );

  return (
    <WatcherTagPicker
      users={users}
      existingWatchers={existingWatchers}
      moreTagCount={moreTagCount || users.length}
      onRemove={onRemove}
      onAdd={onAdd}
      placeholder={intl.$t({ id: "SELECT_WATCHERS" })}
      verticalItems={verticalItems}
      readonly={readonly}
    />
  );
};
