import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";

export const InvoiceImportExternalPOWizard = () => {
  const { pages, importModalOpened, setShowModal } =
    useInvoiceImportExternalPO();

  return (
    <WizardModal
      pages={pages}
      opened={importModalOpened}
      mode={WizardModalMode.MULTIPLE_PAGES}
      onClose={() => setShowModal(false)}
    />
  );
};
