import { useCallback } from "react";

interface UsePointerDragProps {
  onDragStart?: () => void;
  onDragMove?: (e: PointerEvent | TouchEvent) => void;
  onDragEnd?: (e: PointerEvent | TouchEvent) => void;
}

export const usePointerDrag = ({
  onDragStart,
  onDragMove,
  onDragEnd,
}: UsePointerDragProps) => {
  const handlePointerDown = useCallback(
    (e: React.PointerEvent | React.TouchEvent) => {
      e.preventDefault();
      onDragStart?.();

      if ("pointerId" in e) {
        (e.target as HTMLElement).setPointerCapture(e.pointerId);
      }

      const handleMove = (e: PointerEvent | TouchEvent) => {
        const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
        onDragMove?.({ ...e, clientX } as PointerEvent);
      };

      const handleEnd = (e: PointerEvent | TouchEvent) => {
        onDragEnd?.(e);
        if ("pointerId" in e) {
          (e.target as HTMLElement).releasePointerCapture(e.pointerId);
        }
        window.removeEventListener("pointermove", handleMove);
        window.removeEventListener("touchmove", handleMove);
        window.removeEventListener("pointerup", handleEnd);
        window.removeEventListener("touchend", handleEnd);
      };

      window.addEventListener("pointermove", handleMove);
      window.addEventListener("touchmove", handleMove);
      window.addEventListener("pointerup", handleEnd);
      window.addEventListener("touchend", handleEnd);
    },
    [onDragStart, onDragMove, onDragEnd],
  );

  return { handlePointerDown };
};
