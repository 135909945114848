import { If } from "@/common/components/if/If";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useEnabledPoInSourceSystem } from "@/common/hooks/useEnabledPoInSourceSystem";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { SourceSystemWrapper } from "../../../../../common/components/source-system-wrapper/SourceSystemWrapper";

const IconContainer = tw.div`h-fit flex flex-row gap-1 flex-1 justify-end`;
const Rounded = tw.div<{
  $disabled: boolean;
  $poExists: boolean | null | undefined;
}>`
${({ $disabled, $poExists }) =>
  $disabled
    ? "bg-white text-gray-500"
    : $poExists
      ? "bg-blue-450 text-black"
      : "bg-green-300 text-black"}
rounded-full p-3 text-xs border border-gray-300 w-5 h-5 flex items-center justify-center`;

export const BuyoutExportStatus = ({
  buyout,
}: {
  buyout: BuyoutsBuyoutFieldsFragment | undefined;
}) => {
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { connectedSourceSystem } = useOrgSettings();
  const intl = useIntl();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();

  const tooltipKey = useMemo(() => {
    if (buyout?.exportedAt || buyout?.poLink?.syncedAt) {
      return buyout?.poLink?.createdBy ? "PO_EXPORTED_AT_BY" : "PO_EXPORTED_AT";
    } else if (buyout?.poExists) {
      return "PO_EXISTS_IN_SOURCE_SYSTEM";
    }

    return "PO_NOT_EXPORTED";
  }, [
    buyout?.exportedAt,
    buyout?.poExists,
    buyout?.poLink?.createdBy,
    buyout?.poLink?.syncedAt,
  ]);

  if (!isPoEnabledInSourceSystem) {
    return null;
  }

  return (
    <SourceSystemWrapper anySourceSystem anyIntegrationType>
      <IconContainer>
        <If
          isTrue={
            includePoNumbering ||
            buyout?.exportedAt ||
            buyout?.poLink?.syncedAt ||
            buyout?.poExists
          }
        >
          <Tooltip
            id={`po-export-${buyout?.id}`}
            element={
              <Rounded
                $disabled={
                  !buyout?.exportedAt &&
                  !buyout?.poLink?.syncedAt &&
                  !buyout?.poExists
                }
                $poExists={
                  !buyout?.exportedAt &&
                  !buyout?.poLink?.syncedAt &&
                  buyout?.poExists
                }
              >
                P
              </Rounded>
            }
          >
            <FormattedMessage
              id={tooltipKey}
              values={{
                date: (
                  <DateView
                    date={buyout?.exportedAt || buyout?.poLink?.syncedAt}
                    options={SHORT_DATE_OPTION}
                  />
                ),
                username: getUserName(buyout?.poLink?.createdBy),
                sourceSystem: connectedSourceSystem
                  ? intl.$t({
                      id: `INTEGRATION_${connectedSourceSystem}`,
                    })
                  : "",
              }}
            />
          </Tooltip>
        </If>
      </IconContainer>
    </SourceSystemWrapper>
  );
};
