import { CategoryState } from "@/common/hooks/useToggleCategory";
import { hasProperty } from "@/common/utils/objectUtils";
import { Identity } from "@/types/Identity";
import { CategoryType } from "../enums/CategoryType";

export const getCategoryType = <T extends Identity>(
  category: T | CategoryState<T> | CategoryState<CategoryState<T>>,
) => {
  if (!hasProperty(category, "isOpened")) {
    return CategoryType.ONE_LEVEL;
  }

  if (!hasProperty((category as CategoryState<T>).items[0], "isOpened")) {
    return CategoryType.TWO_LEVELS;
  }

  return CategoryType.THREE_LEVELS;
};
