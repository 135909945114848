import { useProjectPredictedPoNumberQuery } from "@/generated/graphql";

export const useProjectPredictedPoNumber = (projectId: string) => {
  const { data, loading } = useProjectPredictedPoNumberQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId,
    fetchPolicy: "no-cache",
  });

  return {
    predictedPoNumber: data?.project?.predictedPoNumber,
    loading,
  };
};
