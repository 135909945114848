import { If } from "@/common/components/if/If";
import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { InvoiceDetails } from "@/common/components/invoices/invoice-details/InvoiceDetails";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { Loader } from "@/common/components/loader/Loader";
import { ResizableColumns } from "@/common/components/resizable-columns/ResizableColumns";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { WarehousesProvider } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import {
  ReleaseProvider,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { AuthorizationStatus } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useExpandableFooterStore } from "../../../../../../common/components/footer/stores/useExpandableFooterStore";
import { ContractorBuyoutProvider } from "../../../buyout/providers/ContractorBuyoutProvider";
import { useSetCurrentProjectId } from "../../../project/hooks/useSetCurrentProjectId";
import { useProjectListOptions } from "../../../projects/hooks/useProjectListOptions";
import { usePriceCalculation } from "../../../release/hooks/usePriceCalculation";
import { ReleaseUpdateProvider } from "../../../release/providers/ReleaseUpdateProvider";
import { SplittingInvoicesWizardProvider } from "../scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardProvider";
import { InvoiceCreationProvider } from "../scanned-invoices/providers/InvoiceCreationProvider";
import { InvoiceSequenceProvider } from "../scanned-invoices/providers/InvoiceSequenceProvider";
import {
  InvoiceValidationProvider,
  useInvoiceValidation,
} from "../scanned-invoices/providers/InvoiceValidationProvider";
import { InvoiceBreadcrumbs } from "./components/breadcrumbs/InvoiceBreadcrumbs";
import { InvoiceFooter } from "./components/footer/InvoiceFooter";
import { InvoiceMatchedOrder } from "./components/matched-order/InvoiceMatchedOrder";
import {
  InvoiceCreateReleaseFormValues,
  InvoiceVerificationForm,
} from "./components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "./components/matched-order/components/invoice-create-release/InvoiceCreateRelease.config";
import { InvoiceImportExternalPOWizard } from "./components/matched-order/components/invoice-import-external-po/InvoiceImportExternalPOWizard";
import { useInvoiceUpdateReleaseSpreadsheetConfig } from "./components/matched-order/components/invoice-update-release/InvoiceUpdateRelease.config";
import { useInvoiceImportExternalPO } from "./hooks/useInvoiceImportExternalPO";
import { useInvoiceSuggestedOrders } from "./hooks/useInvoiceSuggestedOrders";
import { useInvoiceViewAutoSelection } from "./hooks/useInvoiceViewAutoSelection";
import { InvoiceCreateReleaseProvider } from "./providers/InvoiceCreateReleaseProvider";
import {
  InvoiceMatchedOrderProvider,
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "./providers/InvoiceMatchedOrderProvider";
import { InvoiceUpdateReleaseProvider } from "./providers/InvoiceUpdateReleaseProvider";
import {
  InvoiceVerificationProvider,
  useInvoiceVerification,
} from "./providers/InvoiceVerificationProvider";

const Container = tw.div`relative mt-5 xl:-mx-10`;

const InvoiceVerificationProviderWithProvider: FC = () => {
  const { invoice, updateInvoice, footerState, hasError, rescanInvoice } =
    useInvoiceVerification();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { height, setExpanded } = useExpandableFooterStore();

  const externalPOsQueryForm = useFormContext<ExternalPOsQueryInputType>();

  const { connectedSourceSystem } = useOrgSettings();
  const { projects } = useProjectListOptions();
  const { importModalOpened } = useInvoiceImportExternalPO();
  const { watch, setValue } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { taxCodes } = useTaxCodeSummaries();
  const { calcTotalPrice } = usePriceCalculation();

  const netAmount = watch("netAmount");
  const additionalCharges = watch("additionalCharges");
  const customTaxAmount = watch("customTaxAmount");
  const taxRate = watch("taxRate");
  const projectId = watch("projectId");
  const taxCodeId = watch("taxCodeId");
  const orderTypeId = watch("orderTypeId");
  const taxVariance = watch("taxVariance");

  useSetCurrentProjectId(projectId);

  useEffect(() => {
    setExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.id]);

  useEffect(() => {
    const selectedProject = projects.some(
      (p) =>
        p.externalProjects.some(
          (m) => m.sourceSystem === connectedSourceSystem,
        ) && p.id === projectId,
    );
    if (selectedProject) {
      externalPOsQueryForm?.setValue("projectId", projectId ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const totalTax = calcTotalPrice({
      netAmount,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      additionalCharges,
      taxVariance,
    });

    setValue("total", totalTax ? new Decimal(totalTax).toString() : "0");
  }, [
    netAmount,
    additionalCharges,
    customTaxAmount,
    taxRate,
    invoice?.chargesAmount,
    setValue,
    taxCodes,
    taxCodeId,
    calcTotalPrice,
    orderTypeId,
    taxVariance,
  ]);

  const readonly = useMemo(() => {
    return (
      invoice?.permissions.edit !== AuthorizationStatus.Authorized ||
      !!invoice.archivedAt
    );
  }, [invoice]);

  const invoicePoNumberReadonly = useMemo(
    () =>
      invoice?.permissions.edit !== AuthorizationStatus.Authorized ||
      !!invoice.archivedAt,
    [invoice],
  );

  const hasFooter = useMemo(() => {
    if (!invoice) {
      return false;
    }

    if (invoice.permissions.archive) {
      return true;
    }

    return !!(
      footerState === InvoiceFooterState.CREATE_ORDER ||
      footerState === InvoiceFooterState.MATCH_ORDER ||
      footerState === InvoiceFooterState.EDIT_ORDER
    );
  }, [invoice, footerState]);

  const skipAutoMatching = useMemo(
    () =>
      matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER ||
      matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER,
    [matchedOrderViewState],
  );

  return (
    <Container>
      <InvoiceBreadcrumbs />
      <ResizableColumns
        hasFooter={hasFooter}
        tallFooter={footerState === InvoiceFooterState.KICKBACK}
        paddingBottom={height}
      >
        <InvoiceMatchedOrder />
        <InvoiceDetails
          invoice={invoice}
          updateInvoice={(changes) => {
            refetchInvoiceValidation();
            return updateInvoice(changes);
          }}
          rescanInvoice={rescanInvoice}
          invoiceReadonly={readonly}
          invoicePoNumberReadonly={invoicePoNumberReadonly}
          hasError={hasError}
          skipAutoMatching={skipAutoMatching}
        />
      </ResizableColumns>
      <If isTrue={hasFooter}>
        <InvoiceFooter />
      </If>
      <If isTrue={importModalOpened}>
        <InvoiceImportExternalPOWizard />
      </If>
    </Container>
  );
};

const InvoiceVerificationWithReleaseProvider: FC = () => {
  const initialized = useRef<boolean>(false);

  const { invoice } = useInvoiceVerification();

  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { matchedOrderViewState, setMatchedOrderViewState, setAutoMatching } =
    useInvoiceMatchedOrder();
  const { connectedSourceSystem, loading: loadingOrgSettings } =
    useOrgSettings();
  const { autoSelectView } = useInvoiceViewAutoSelection();
  const {
    loading: loadingReleases,
    called,
    releases,
  } = useInvoiceSuggestedOrders();

  const enableValidation = useMemo(
    () =>
      (hasFeatureInConnectedSourceSystem(
        IntegrationFeature.InvoiceFileExports,
      ) ||
        hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceWrites)) &&
      !invoice?.link,
    [hasFeatureInConnectedSourceSystem, invoice?.link],
  );
  useEffect(() => {
    if (
      (invoice?.poNumber ||
        invoice?.poNumber === "" ||
        invoice?.poNumber === null) &&
      matchedOrderViewState !== MatchedOrderViewState.CREATE_ORDER &&
      matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER
    ) {
      initialized.current = false;
      setAutoMatching(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.poNumber, setAutoMatching]);

  useEffect(() => {
    if (
      !initialized.current &&
      invoice?.id &&
      (called || !invoice?.poNumber) &&
      !loadingReleases &&
      (connectedSourceSystem || !loadingOrgSettings)
    ) {
      initialized.current = true;
      autoSelectView(releases);
    }
  }, [
    setMatchedOrderViewState,
    invoice?.id,
    matchedOrderViewState,
    releases.length,
    invoice,
    releases,
    loadingReleases,
    called,
    connectedSourceSystem,
    autoSelectView,
    loadingOrgSettings,
  ]);

  if (!invoice) {
    return <Loader loading />;
  }

  return (
    <InvoiceValidationProvider invoice={invoice} skip={!enableValidation}>
      <ReleaseProvider id={invoice?.release?.id} invoiceId={invoice.id}>
        <ContractorBuyoutProvider id={invoice?.release?.buyout?.id}>
          <SpreadsheetWrapper />
        </ContractorBuyoutProvider>
      </ReleaseProvider>
    </InvoiceValidationProvider>
  );
};

const InvoiceCreateReleaseWithSpreadsheetWrapper = () => {
  const invoiceCreateReleaseSpreadsheetConfig =
    useInvoiceCreateReleaseSpreadsheetConfig();
  return (
    <ColumnMapperProvider config={invoiceCreateReleaseSpreadsheetConfig}>
      <InvoiceCreateReleaseProvider>
        <InvoiceVerificationProviderWithProvider />
      </InvoiceCreateReleaseProvider>
    </ColumnMapperProvider>
  );
};

const InvoiceUpdateReleaseWithSpreadsheetWrapper = () => {
  const { release } = useRelease();

  const invoiceUpdateReleaseSpreadsheetConfig =
    useInvoiceUpdateReleaseSpreadsheetConfig();

  if (!release) {
    return;
  }
  return (
    <ReleaseUpdateProvider
      release={release}
      project={release?.project}
      items={release.items}
    >
      <ColumnMapperProvider config={invoiceUpdateReleaseSpreadsheetConfig}>
        <InvoiceCreateReleaseProvider>
          <InvoiceUpdateReleaseProvider>
            <InvoiceVerificationProviderWithProvider />
          </InvoiceUpdateReleaseProvider>
        </InvoiceCreateReleaseProvider>
      </ColumnMapperProvider>
    </ReleaseUpdateProvider>
  );
};

const SpreadsheetWrapper = () => {
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  if (
    matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER ||
    matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
  ) {
    return <InvoiceCreateReleaseWithSpreadsheetWrapper />;
  } else if (matchedOrderViewState === MatchedOrderViewState.EDIT_ORDER) {
    return <InvoiceUpdateReleaseWithSpreadsheetWrapper />;
  } else {
    return <InvoiceVerificationProviderWithProvider />;
  }
};

export const InvoiceVerification = () => {
  return (
    <NestedStepperProvider>
      <InvoiceSequenceProvider>
        <WarehousesProvider>
          <InvoiceCreationProvider>
            <InvoiceVerificationForm>
              <InvoiceVerificationProvider>
                <InvoiceMatchedOrderProvider>
                  <SplittingInvoicesWizardProvider>
                    <InvoiceVerificationWithReleaseProvider />
                  </SplittingInvoicesWizardProvider>
                </InvoiceMatchedOrderProvider>
              </InvoiceVerificationProvider>
            </InvoiceVerificationForm>
          </InvoiceCreationProvider>
        </WarehousesProvider>
      </InvoiceSequenceProvider>
    </NestedStepperProvider>
  );
};
