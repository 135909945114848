import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useReleaseImportExternalPO } from "./hooks/useReleaseImportExternalPO";

export const ReleaseImportExternalPOWizard = () => {
  const { pages, importModalOpened, setShowModal } =
    useReleaseImportExternalPO();

  return (
    <WizardModal
      pages={pages}
      opened={importModalOpened}
      mode={WizardModalMode.MULTIPLE_PAGES}
      onClose={() => setShowModal(false)}
    />
  );
};
