import { PAGE_OPTIONS } from "@/common/const";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { usePagination } from "./PaginationProvider";

type PaginationProps = {
  total?: number;
  count: number;
  page: number;
  itemsPerPage: number;
  nextPage: () => void;
  previousPage: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
  itemLabel?: string;
  setPageSizeFn?: (size: number) => void;
  setPageFn?: (page: number) => void;
};

const PaginationContainer = tw.div`
  inline-flex items-center gap-4
`;
const FormControlStyled = tw(FormControl)`w-24`;

type PaginationLinkProps = {
  $active: boolean;
};

const PaginationLink = tw.div<PaginationLinkProps>`
  flex items-center 
  ${(props: PaginationLinkProps) =>
    props.$active ? "cursor-pointer" : "text-gray-500"};
`;

const InputLabelStyled = tw(InputLabel)`bg-transparent`;

export const Pagination: FC<PaginationProps> = ({
  total,
  count,
  page,
  itemsPerPage,
  nextPage,
  previousPage,
  hasNext,
  hasPrevious,
  itemLabel,
  setPageFn,
  setPageSizeFn,
}) => {
  const { setPageSize, setPage, customPageSize } = usePagination();
  const intl = useIntl();

  const startNumber = useMemo(
    () => page * itemsPerPage + 1,
    [itemsPerPage, page],
  );
  const onChangePageSize = useCallback(
    (pageSize: number) => {
      setPageSizeFn?.(pageSize);
      setPageSize(pageSize);
      setPageFn?.(0);
      setPage({ page: 0, pagination: { first: pageSize } });
    },
    [setPage, setPageSize, setPageSizeFn, setPageFn],
  );

  const pageOptions = useMemo(
    () =>
      [...PAGE_OPTIONS, ...(customPageSize ? [customPageSize] : [])].toSorted(
        (a, b) => a - b,
      ),
    [customPageSize],
  );

  return (
    <PaginationContainer>
      <If isTrue={total === undefined || total !== 0}>
        {intl.$t(
          {
            id:
              total === undefined
                ? "PAGE_COUNTER_WITHOUT_TOTAL"
                : "PAGE_COUNTER",
          },
          {
            startNumber,
            endNumber: startNumber + (count - 1),
            total,
            itemLabel,
          },
        )}
      </If>
      <PaginationLink
        $active={hasPrevious}
        onClick={() => (hasPrevious ? previousPage() : null)}
      >
        <NavigateBefore />
      </PaginationLink>
      <PaginationLink
        $active={hasNext}
        onClick={() => (hasNext ? nextPage() : null)}
      >
        <NavigateNext />
      </PaginationLink>{" "}
      <FormControlStyled>
        <InputLabelStyled id="pagination-select">
          {intl.$t({ id: "SHOW_ITEMS" })}
        </InputLabelStyled>
        <Select
          value={itemsPerPage.toString()}
          labelId="pagination-select"
          label={intl.$t({ id: "SHOW_ITEMS" })}
          onChange={(event: SelectChangeEvent) =>
            onChangePageSize(Number(event.target.value))
          }
          size="small"
          inputProps={{ className: "text-center bg-white" }}
        >
          {pageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControlStyled>
    </PaginationContainer>
  );
};
