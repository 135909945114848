import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const useRfqFromNoteStore = create<State>()(
  devtools((set) => ({
    loading: false,
    setLoading: (loading: boolean) => set({ loading }),
  })),
);
