import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { textRenderer } from "handsontable/renderers";
import { isNumber } from "lodash";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { useTableHelpers } from "../hooks/useTableHelpers";
import { getPhysicalColumnIndex } from "../utils/getPhysicalColumnIndex";
import { useRenderHelpers } from "./useRenderHelpers";

export const usePrefillPriceRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getPrefilledValue, isBuyoutItem } = useTableHelpers();
  const {
    applyTooltip,
    applyClasses,
    formatPrice,
    checkReadOnly,
    addIconWithTooltip,
  } = useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      let formattedValue = formatPrice(row, instance, value);
      textRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      applyClasses(td, "htCenter htMiddle relative pr-4");
      const rows = instance?.getData();

      const uom = rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
      if (isLumpSumUomText(uom)) {
        return;
      }

      const price = getPrefilledValue({
        material:
          rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        vendor: rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Vendor)],
        manufacturer:
          rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)],
        uom: rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      });

      const materialOptions = instance.getCellMeta(
        row,
        getPhysicalColumnIndex(instance, COLUMN_TYPE.Material),
      ).extraOptions;

      const buyoutItem = materialOptions
        ? isBuyoutItem(
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Vendor)],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)
            ],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.PrefilledPrice)
            ],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.CostCode)],
          )
        : null;

      if (value) {
        addIconWithTooltip({
          element: td,
          icon: "i",
          tooltipText: intl.formatMessage({
            id: buyoutItem
              ? "BUYOUT_ITEM_PRICE_NOTICE"
              : price.isVendorPrice
                ? "VENDOR_PRICE_NOTICE"
                : "ESTIMATED_PRICE_NOTICE",
          }),
          iconClasses: buyoutItem
            ? "border-green-800 bg-green-800 text-white hover:!text-green-800 hover:bg-green-200"
            : "",
        });
      }

      const hasExtPriceColumn = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.ExtPrice,
      );
      if (hasExtPriceColumn !== -1) {
        return;
      }
      const quantityIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Quantity,
      );
      const priceIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.PrefilledPrice,
      );

      const extendedPrice = rows[row][quantityIndex] * rows[row][priceIndex];
      if (extendedPrice && isNumber(extendedPrice)) {
        const extPrice = document.createElement("div");
        extPrice.className = "text-gray-500 text-3xs select-none mb-1";
        const formattedValue = extendedPrice
          ? formatCurrency(extendedPrice)
          : "";
        extPrice.innerText = intl.formatMessage(
          { id: "EXT_WITH_PRICE" },
          { price: formattedValue },
        );
        td.appendChild(extPrice);

        applyTooltip(
          extPrice,
          intl.formatMessage(
            { id: "EXT_PRICE_TOOLTIP" },
            { price: formattedValue },
          ),
        );
      }
      checkReadOnly(td, row, instance, cellProperties);
    },
    [
      formatPrice,
      applyClasses,
      getPrefilledValue,
      isBuyoutItem,
      checkReadOnly,
      applyTooltip,
      intl,
      formatCurrency,
      addIconWithTooltip,
    ],
  );

  return renderer;
};
