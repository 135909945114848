import Decimal from "decimal.js";

/**
 * Splits a monetary amount into parts according to their weights,
 * ensuring the sum of resulting amounts equals the input amount.
 *
 * @param amount - The monetary amount to split.
 * @param weights - An array of weights to divide the amount by.
 * @returns An array of monetary amounts.
 */
export function monetarySplit(amount: Decimal, weights: Decimal[]): Decimal[] {
  if (weights.length === 0) {
    return [];
  }

  amount = amount.toDecimalPlaces(2);
  const adjustedWeights: Decimal[] = new Array(weights.length);
  let weightsSum = new Decimal(0);
  let hasNoWeights = true;

  // Adjust weights and calculate their sum
  weights.forEach((weight, i) => {
    const roundedWeight = weight.toDecimalPlaces(2);
    adjustedWeights[i] = roundedWeight;
    weightsSum = weightsSum.plus(roundedWeight);
    if (!roundedWeight.isZero()) {
      hasNoWeights = false;
    }
  });

  // Handle case where all weights are zero
  if (hasNoWeights) {
    const equalWeight = new Decimal(1)
      .div(new Decimal(weights.length))
      .toDecimalPlaces(2);
    adjustedWeights.fill(equalWeight);
    weightsSum = equalWeight.times(weights.length);
  } else if (weightsSum.isZero()) {
    // Handle case where the sum of weights is zero
    adjustedWeights.forEach((weight, i) => {
      if (weight.isNegative()) {
        adjustedWeights[i] = new Decimal(0);
      } else {
        weightsSum = weightsSum.plus(adjustedWeights[i]);
      }
    });
  }

  const result: Decimal[] = new Array(weights.length);
  let total = new Decimal(0);

  // Calculate initial split amounts
  adjustedWeights.forEach((weight, i) => {
    const part = amount
      .times(weight)
      .div(weightsSum)
      .toDecimalPlaces(2, Decimal.ROUND_DOWN);
    result[i] = part;
    total = total.plus(part);
  });

  // If total matches the amount, return the result
  if (total.equals(amount)) {
    return result;
  }

  const penny = new Decimal(0.01);

  // Distribute the remaining difference
  while (total.lessThan(amount)) {
    let maxWeight = new Decimal(0);
    let maxIndex = 0;

    adjustedWeights.forEach((weight, i) => {
      if (weight.greaterThan(maxWeight)) {
        maxWeight = weight;
        maxIndex = i;
      }
    });

    result[maxIndex] = result[maxIndex].plus(penny);
    total = total.plus(penny);

    // Mark weight as zero to avoid repeated adjustment if necessary
    adjustedWeights[maxIndex] = new Decimal(0);
  }

  return result;
}
