import { HeaderContainerWithSecondaryNavigation } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

import { useLocalSearch } from "@/common/hooks/useLocalSearch";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { ImportExternalServiceCodesModal } from "./components/import-external-service-codes/ImportExternalServiceCodesModal";
import { ImportExternalServiceCodesButton } from "./components/ImportExternalServiceCodesButton";
import { ServiceCodesList } from "./components/ServiceCodesList";
import { useServiceCodes } from "./hooks/useServiceCodes";

const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start
`;

const ServiceCodesWithProvider = () => {
  const intl = useIntl();
  const viewState = useTableViewStore((state) => state.viewState);
  const { serviceCodes, loading } = useServiceCodes();
  const { setSearchText, filteredItems } = useLocalSearch(serviceCodes);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "SERVICE_CODES" })}</title>
      </Helmet>
      <If isTrue={viewState === TableViewState.normal}>
        <HeaderContainerWithSecondaryNavigation>
          <Filters>
            <SearchInput
              placeHolder={intl.$t({ id: "SEARCH_SERVICE_CODES" })}
              onChange={setSearchText}
            />
          </Filters>
          <HeaderRightPanel>
            <ImportExternalServiceCodesButton />
          </HeaderRightPanel>
        </HeaderContainerWithSecondaryNavigation>
        <ServiceCodesList items={filteredItems} loading={loading} />
      </If>
      <ImportExternalServiceCodesModal />
    </>
  );
};

export const ServiceCodes = () => {
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.normal);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  return (
    <NestedStepperProvider>
      <ServiceCodesWithProvider />
    </NestedStepperProvider>
  );
};
