import { xor } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { CategoryType } from "../enums/CategoryType";

export type GridTableProps = {
  virtualized: boolean;
  visibleCategories: string[];
  categoryType: CategoryType | undefined;
  hideGroup: boolean;
  readonly: boolean;
};

export type GridTableState = {
  table: GridTableProps;
  setTableCategoriesVisibility: (
    categories: string[],
    visible: boolean,
  ) => void;
  setTable: (table: GridTableProps) => void;
  setCategoryType: (categoryType: CategoryType) => void;
  countMap: Map<string, number>;
  resetCountMap: () => void;
};

const initTable = (table: Partial<GridTableProps>): GridTableProps => {
  return {
    virtualized: table.virtualized ?? false,
    visibleCategories: table.visibleCategories ?? [],
    categoryType: table.categoryType ?? undefined,
    hideGroup: table.hideGroup ?? false,
    readonly: table.readonly ?? false,
  };
};

export type GridTableStore = ReturnType<typeof createGridTableStore>;

export const createGridTableStore = (initialTable: Partial<GridTableProps>) =>
  create<GridTableState>()(
    devtools((set, get) => ({
      table: initTable(initialTable),
      setTable: (table: GridTableProps) => set({ table }),
      setTableCategoriesVisibility: (
        categories: string[],
        visible: boolean,
      ) => {
        const { visibleCategories } = get().table;

        const newVisibleCategories = visible
          ? [...new Set([...visibleCategories, ...categories])]
          : visibleCategories.filter(
              (category) => !categories.includes(category),
            );

        if (xor(newVisibleCategories, visibleCategories).length === 0) {
          return;
        }

        set((state) => ({
          table: {
            ...state.table,
            visibleCategories: newVisibleCategories,
          },
        }));
      },
      setCategoryType: (categoryType: CategoryType) =>
        set((state) => ({
          table: {
            ...state.table,
            categoryType,
          },
        })),
      countMap: new Map<string, number>(),
      resetCountMap: () => set(() => ({ countMap: new Map<string, number>() })),
    })),
  );
