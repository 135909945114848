import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import { SCREEN_BREAKPOINTS } from "@/common/const";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import { useWindowSize } from "@/common/hooks/useWindowResize";
import { ManufacturerFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDialog } from "../dialog/DialogProvider";

const addNewManufacturerId = "add-new-manufacturer-id";
export const anyManufacturerId = "any-manufacturer-id";

type Props = {
  item?: ManufacturerFieldsFragment | null | undefined;
  index?: number;
  error?: boolean;
  readonly?: boolean;
  staticText?: boolean;
  canAddNewManufacturer?: boolean;
  canClearManufacturer?: boolean;
  saveManufacturer: (manufacturerId: string | null) => void;
  hasAnyManufacturer?: boolean;
  disabled?: boolean;
};

const CreatableOption = tw.div`font-medium`;

export const ManufacturerPicker: FC<Props> = ({
  item,
  error = false,
  readonly = false,
  canAddNewManufacturer = true,
  canClearManufacturer = false,
  saveManufacturer,
  hasAnyManufacturer = true,
  index,
  disabled = false,
}) => {
  const intl = useIntl();
  const previousManufacturer = usePreviousValue(item);
  const { openDialog } = useDialog();
  const anyManufacturer = useMemo(() => {
    if (!hasAnyManufacturer) {
      return null;
    }
    return {
      id: anyManufacturerId,
      name: intl.$t({ id: "ANY_MANUFACTURER" }),
    };
  }, [intl, hasAnyManufacturer]);
  const [manufacturerSearchText, setManufacturerSearchText] = useState(
    item?.name || anyManufacturer?.name || "",
  );
  const { manufacturers, createManufacturer } = useManufacturers();
  const [selectedManufacturerId, setSelectedManufacturerId] = useState<
    string | null
  >(item?.id ? item.id : (anyManufacturer?.id ?? null));
  const viewPort = useWindowSize();
  const hideLabel = viewPort.width > SCREEN_BREAKPOINTS.xs;

  useEffect(() => {
    if (item) {
      setSelectedManufacturerId(item.id);
      setManufacturerSearchText(item.name);
    }
  }, [item]);

  const onChange = useCallback(
    async (newManufacturerId: string | null) => {
      setSelectedManufacturerId(newManufacturerId);
      if (newManufacturerId || canClearManufacturer) {
        await saveManufacturer(newManufacturerId);
      }
    },
    [canClearManufacturer, saveManufacturer],
  );

  const handleAddNewManufacturer = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "CONFIRM" }),
      includeWarningIcon: true,
      title: intl.$t(
        { id: "ADD_NEW_MANUFACTURER_TO_OUR_LIST" },
        { manufacturerName: manufacturerSearchText },
      ),
      text: intl.$t({
        id: "ADD_NEW_MANUFACTURER_WARNING",
      }),
      handleConfirm: async () => {
        const manufacturer = await createManufacturer(manufacturerSearchText);
        if (manufacturer) {
          await onChange(manufacturer.id);
        }
      },
      handleCancel: () => {
        setManufacturerSearchText(manufacturerSearchText);
      },
    });
  }, [
    manufacturerSearchText,
    createManufacturer,
    onChange,
    intl,
    openDialog,
    setManufacturerSearchText,
  ]);

  const onBlur = useCallback(async () => {
    if (!manufacturerSearchText && canClearManufacturer) {
      await onChange(null);
      setManufacturerSearchText("");
    } else if (previousManufacturer?.id === selectedManufacturerId) {
      setManufacturerSearchText(previousManufacturer?.name || "");
    }
  }, [
    manufacturerSearchText,
    canClearManufacturer,
    onChange,
    previousManufacturer,
    selectedManufacturerId,
    setManufacturerSearchText,
  ]);

  const options = useMemo(() => {
    const defaultOptions = [
      ...((selectedManufacturerId === anyManufacturer?.id ||
        canClearManufacturer) &&
      hasAnyManufacturer
        ? [anyManufacturer]
        : []),
      ...manufacturers,
    ];
    if (
      !canAddNewManufacturer ||
      !manufacturerSearchText ||
      defaultOptions.some(
        (m) => m?.name.toLowerCase() === manufacturerSearchText.toLowerCase(),
      )
    ) {
      return defaultOptions;
    }
    return [
      {
        id: addNewManufacturerId,
        name: intl.$t(
          { id: "CREATABLE_ADD_NEW" },
          { inputValue: manufacturerSearchText },
        ),
      },
      ...defaultOptions,
    ];
  }, [
    anyManufacturer,
    canAddNewManufacturer,
    canClearManufacturer,
    intl,
    manufacturerSearchText,
    manufacturers,
    selectedManufacturerId,
    hasAnyManufacturer,
  ]);

  return (
    <StyledSelect
      className="w-full [&_input]:text-left [&_input]:md:text-left"
      error={error}
      getLabel={(manufacturer) => manufacturer?.name ?? ""}
      getValue={(manufacturer) => manufacturer?.id ?? ""}
      handleInputChange={setManufacturerSearchText}
      InputLabelProps={{
        className: "text-sm",
      }}
      disabled={disabled}
      inputValue={manufacturerSearchText}
      onBlur={onBlur}
      onChange={async (newManufacturerId: string | null) => {
        if (newManufacturerId === addNewManufacturerId) {
          handleAddNewManufacturer();
        } else {
          await onChange(newManufacturerId);
        }
      }}
      options={options}
      staticText={readonly}
      value={selectedManufacturerId}
      placeholder={
        selectedManufacturerId
          ? undefined
          : intl.$t({ id: "SELECT_MANUFACTURER" })
      }
      label={!hideLabel ? intl.$t({ id: "SELECT_MANUFACTURER" }) : undefined}
      openOnFocus={false}
      centered
      testId={`manufacturer-picker-${index}`}
      customRender={(option) => {
        if (option?.id === addNewManufacturerId) {
          return <CreatableOption>{option?.name}</CreatableOption>;
        }
        return <>{option?.name}</>;
      }}
      size="xs"
    />
  );
};
