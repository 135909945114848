import { usePagination } from "@/common/components/pagination/PaginationProvider";
import {
  QueryExternalVendorsFilter,
  SourceSystem,
  useExternalVendorsQuery,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";

export const useAgaveExternalVendorsWithPagination = (
  sourceSystem: SourceSystem | null,
  filter?: QueryExternalVendorsFilter,
) => {
  const { paginationArgs, setPageInfo, setPage } = usePagination();
  const { data, loading, error, refetch } = useExternalVendorsQuery({
    variables: {
      ...paginationArgs,
      filter,
      sourceSystem: sourceSystem || SourceSystem.Foundation,
    },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first",
    skip: !sourceSystem,
  });

  useEffect(() => {
    if (data?.externalVendors?.pageInfo) {
      setPageInfo({ ...data.externalVendors.pageInfo });
    }
  }, [data, setPageInfo]);

  const refetchExternalVendors = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return {
    externalVendors: data?.externalVendors.edges.map((edge) => edge.node) || [],
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.externalVendors.totalCount || 0,
    setPage,
    refetchExternalVendors,
  };
};
