import { Address } from "@/common/components/address/Address";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Loader } from "@/common/components/loader/Loader";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { routes } from "@/config/routes";
import {
  ConnectionError,
  ExportErrorList,
  ValidationType,
} from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { ReceiptToExportFieldsFragment } from "@/generated/graphql";
import { ArrowForward, ErrorOutlineOutlined } from "@mui/icons-material";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";

const ProjectAndVendorContainer = tw.div`grid grid-cols-[1.5fr_2fr] flex-row gap-5`;
const ProjectContainer = tw.div`flex flex-col justify-start border-r border-gray-400 border-dashed pr-2`;
const Item = tw.div`flex flex-row gap-1 items-center whitespace-nowrap`;
const ProjectName = tw.div``;
const VendorContainer = tw.div`flex flex-row gap-2`;
const Details = tw.div`flex flex-col`;
const Container = tw.div`flex flex-col gap-3 text-sm`;
const CardStyled = tw.div<{
  $hasError: boolean;
}>`rounded-3xl p-4 shadow-md ${({ $hasError }) =>
  $hasError && "border border-red-500 bg-gray-100"}`;
const IconContainer = tw.div`border border-dashed border-gray-500 rounded-full`;
const Arrow = tw(ArrowForward)`transition-all duration-500 animate-slide`;
const Error = tw.div`flex items-center bg-red-200 text-red-500 rounded p-2 mb-2 gap-2`;

type Props = PropsWithChildren<{
  receipt: ReceiptToExportFieldsFragment;
  includedValidations: ConnectionError[];
  editablePoNumber?: boolean;
  linking?: boolean;
  loading?: boolean;
  error?: string;
}>;

export const ReceiptReleaseDetails: FC<Props> = ({
  receipt,
  includedValidations,
  linking,
  loading,
  children,
  error,
}) => {
  const generateReleasePath = useCallback(() => {
    if (receipt.release) {
      return generatePath(routes.delivery, { deliveryId: receipt.release?.id });
    }

    return "";
  }, [receipt.release]);

  const generateReceiptPath = useCallback(
    () =>
      generatePath(routes.processReceipt, {
        receiptId: receipt?.id,
      }),
    [receipt?.id],
  );

  const errorsAndWarnings = useMemo(
    () => includedValidations.filter((v) => v.condition),
    [includedValidations],
  );

  const errors = useMemo(
    () =>
      includedValidations.filter(
        (v) => v.condition && v.validationType !== ValidationType.Warning,
      ),
    [includedValidations],
  );

  if (linking && errors.length > 0) {
    return null;
  }

  return (
    <CardStyled
      $hasError={
        errorsAndWarnings.filter(
          (e) => !e.validationType || e.validationType === ValidationType.Error,
        ).length > 0
      }
    >
      <Container>
        <ProjectAndVendorContainer>
          <ProjectContainer>
            <Item>
              <If isTrue={loading}>
                <Loader loading small className="w-5" />
              </If>
              <If isTrue={linking}>
                <IconContainer>
                  <Arrow />
                </IconContainer>
              </If>
              <FormattedMessage id="RECEIPT" />:
              <LinkLike to={generateReceiptPath()} target="_blank">
                #{receipt.number}
              </LinkLike>
            </Item>
            <Item>
              <FormattedMessage id="ORDER" />:
              <LinkLike to={generateReleasePath()} target="_blank">
                #{receipt.release?.sequenceNumber}
              </LinkLike>
            </Item>
            <If isTrue={receipt.paymentMethod}>
              <Item>
                <FormattedMessage id="PAYMENT" />
                {": "}
                {receipt.paymentMethod?.description}
              </Item>
            </If>
            <ProjectName>{receipt.release?.project?.name}</ProjectName>
          </ProjectContainer>
          <VendorContainer>
            <OrgLogo
              logoImageUrl={receipt.release?.sellerOrgLocation?.org.photoUrl}
              name={receipt.release?.sellerOrgLocation?.org.name || ""}
              width={40}
            />
            <Details>
              {receipt.release?.sellerOrgLocation?.org.name || ""}
              <Address address={receipt.release?.sellerOrgLocation?.address} />
            </Details>
          </VendorContainer>
        </ProjectAndVendorContainer>
        <If isTrue={error}>
          <Error>
            <ErrorOutlineOutlined />
            {error}
          </Error>
        </If>
        {children}
        <ExportErrorList includedValidations={errorsAndWarnings} />
      </Container>
    </CardStyled>
  );
};
