import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { GridViewToggle } from "@/common/components/grid-view-toggle/GridViewToggle";
import { If } from "@/common/components/if/If";
import { SpreadsheetSaveType } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useSyncMaterials } from "../../providers/SyncMaterialsProvider";

const FooterContent = tw.div`grid grid-cols-[80px_1fr_auto] gap-4 w-full h-10 items-center`;
const SwitchContainer = tw.div`flex justify-start`;

export const MaterialsFooter = () => {
  const { syncMaterials, saving } = useSyncMaterials();
  const viewState = useTableViewStore((state) => state.viewState);

  return (
    <FloatingFooter>
      <FooterContent>
        <span />
        <SwitchContainer>
          <GridViewToggle saveState={syncMaterials} saving={saving} />
        </SwitchContainer>
        <If isTrue={viewState === TableViewState.spreadsheet}>
          <PrimaryButton
            onClick={() => syncMaterials(SpreadsheetSaveType.SaveButton)}
          >
            <FormattedMessage id="SAVE_CHANGES" />
          </PrimaryButton>
        </If>
      </FooterContent>
    </FloatingFooter>
  );
};
