import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import { CategoryLike } from "@/common/hooks/useToggleCategory";
import {
  UNSPECIFIED_COST_CODE_ID,
  useUnspecifiedCostCode,
} from "@/common/hooks/useUnspecifiedCostCode";
import {
  UNSPECIFIED_ZONE_ID,
  useUnspecifiedZone,
} from "@/common/hooks/useUnspecifiedZone";
import { useUser } from "@/common/providers/UserProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { AUXILIARY_ITEMS_ID } from "@/distributor/pages/quote/providers/DistributorQuoteItemsProvider";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { CostCodeRenderer } from "../cost-code-renderer/CostCodeRenderer";
import { If } from "../if/If";

const CategoryGroup = tw.div`
  grid ml-1
`;

const CategoryHeader = tw.div`
  grid grid-flow-col bg-transparent items-center justify-start
`;

const Counter = tw.span`
text-2xs md:text-xs font-normal text-gray-600
`;

const CategoryName = tw.span`
  font-medium text-xs md:text-sm inline-flex gap-1
  ${({ $includeCounter }: { $includeCounter: boolean }) =>
    $includeCounter ? "" : "text-xs md:text-sm"}
`;

const HeaderDetails = tw.div`
  font-light whitespace-nowrap text-2xs md:text-xs text-gray-600
`;

export enum ProductCategoryType {
  Zone = "zone",
  CostCode = "cost-code",
  AuxiliaryItem = "auxiliary-item",
}

type ProductCategoryProps = {
  category: Pick<CategoryLike, "isOpened" | "name" | "id" | "parentId">;
  classes?: {
    root?: string;
    header?: string;
  };
  categoryClassName?: string;
  items?: number;
  includeCounter?: boolean;
  includePopover?: boolean;
  includeToggle?: boolean;
  type: ProductCategoryType;
  projectId?: string;
  itemsHavePhaseCodes?: boolean;
};

export const ProductCategory: FC<ProductCategoryProps> = ({
  category,
  items = 0,
  classes = {
    root: "",
    header: "",
  },
  includeCounter = false,
  type,
  includeToggle = true,
  projectId,
  itemsHavePhaseCodes,
}) => {
  const intl = useIntl();
  const { isContractor } = useUser();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { unassignedZone } = useUnspecifiedZone();
  const { hasPhaseCodes } = useOrgSettings();

  const categoryName = useMemo(() => {
    if (intl.messages[category.name]) {
      return intl.$t({ id: category.name }, { isDistributor: !isContractor });
    } else {
      return category.name
        ? category.name
        : type === ProductCategoryType.CostCode
          ? unassignedCostCode.description
          : type === ProductCategoryType.Zone
            ? unassignedZone.name
            : "";
    }
  }, [
    category.name,
    intl,
    isContractor,
    type,
    unassignedCostCode.description,
    unassignedZone.name,
  ]);

  const isAssigned = useMemo(
    () =>
      type &&
      category.id !== UNSPECIFIED_COST_CODE_ID &&
      category.id !== UNSPECIFIED_ZONE_ID &&
      category.id !== AUXILIARY_ITEMS_ID,
    [type, category.id],
  );

  const includeCostCodeLabel = useMemo(
    () =>
      [ProductCategoryType.CostCode, ProductCategoryType.Zone].includes(type),
    [type],
  );

  const details = useMemo(() => {
    if (type === ProductCategoryType.Zone) {
      return "ZONE";
    }
    if (type === ProductCategoryType.CostCode) {
      return hasPhaseCodes || itemsHavePhaseCodes ? "PHASE_CODE" : "COST_CODE";
    }
  }, [type, hasPhaseCodes, itemsHavePhaseCodes]);

  return (
    <CategoryHeader className={classes.root}>
      <If isTrue={includeToggle}>
        <GroupToggle isOpened={category.isOpened} />
      </If>
      <CategoryGroup>
        <CategoryName
          $includeCounter={includeCounter}
          className={classes.header}
        >
          {isContractor &&
          type === ProductCategoryType.CostCode &&
          !(hasPhaseCodes || itemsHavePhaseCodes) ? (
            <CostCodeRenderer
              costCode={category}
              projectId={projectId}
              zoneId={category.parentId}
            />
          ) : (
            categoryName
          )}
          <If isTrue={includeCostCodeLabel}>
            {isAssigned ? (
              <HeaderDetails>
                (
                <FormattedMessage id={details} />)
              </HeaderDetails>
            ) : (
              <FormattedMessage id={details} tagName="div" />
            )}
          </If>
        </CategoryName>
        <If isTrue={includeCounter}>
          <Counter>
            <FormattedMessage id="COUNT_PRODUCTS" values={{ count: items }} />
          </Counter>
        </If>
      </CategoryGroup>
    </CategoryHeader>
  );
};
