import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterProps,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useBuyoutGrouped } from "../../document/providers/BuyoutGroupedProvider";

const marginRightClass = "pr-2";

export const BuyoutStandaloneItemsReceivedTotalFooter: FC<FooterProps> = ({
  Wrapper,
}) => {
  const {
    receivedStandaloneSalesTax,
    receivedStandaloneSubtotal,
    receivedStandaloneTotal,
  } = useBuyoutGrouped();

  return (
    <FooterCol>
      <Wrapper className="flex-col items-end">
        <FooterHeader className={marginRightClass}>
          <FormattedMessage id="RECEIVED" tagName={FooterHeaderText} />
        </FooterHeader>
        <FooterCell className={marginRightClass}>
          <Price price={receivedStandaloneSubtotal} className="font-light" />
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={receivedStandaloneSalesTax}>
            <Price price={receivedStandaloneSalesTax} className="font-light" />
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal className="pr-0">
          <FooterCellCol className={marginRightClass}>
            <Price price={receivedStandaloneTotal} />
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
