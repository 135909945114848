import { CostCodesSelector } from "@/common/components/cost-codes-selector/CostCodesSelector";
import { CostCode } from "@/generated/graphql";
import { FC } from "react";
import { useProjectItemsZones } from "../../providers/ProjectItemsZonesProvider";

export const ProjectCostCodesSelector: FC<{
  options: Pick<
    CostCode & { disabled?: boolean },
    "id" | "description" | "disabled"
  >[];
  disabled?: boolean;
  className?: string;
}> = ({ options, disabled, className }) => {
  const { costCodes, setCostCodes } = useProjectItemsZones();

  return (
    <CostCodesSelector
      options={options}
      selectedCostCodes={costCodes}
      setSelectedCostCodes={setCostCodes}
      disabled={disabled}
      limitTags={2}
      className={className}
    />
  );
};
