import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { QuoteDocumentItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddQuoteItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { quoteDocument } = useQuoteDocument();
  const { findMaterialByName } = useTableHelpers();
  const { showModal } = useAddItemsModal();
  const { formatCostCode } = useCostCodes();
  const { calcExtPrice } = usePriceCalculation();

  const missingItems = useMemo(
    () =>
      quoteDocument?.items.filter(
        (item) =>
          !quoteDocument?.releaseItemHints.some(
            (hint) => hint.quoteDocumentItem?.id === item.id,
          ),
      ) || [],
    [quoteDocument?.items, quoteDocument?.releaseItemHints],
  );

  const addAllItems = useCallback(
    (items?: QuoteDocumentItemFieldsFragment[]) => {
      const newItems =
        (items || quoteDocument?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          return {
            [COLUMN_TYPE.Material]:
              material?.material.name ?? item.description ?? "",
            [COLUMN_TYPE.Manufacturer]: item.manufacturer ?? "",
            [COLUMN_TYPE.UOM]: String(item.uom) || "UT",
            [COLUMN_TYPE.Quantity]: item.quantity || "",
            [COLUMN_TYPE.UnitPrice]: item.unitPrice || "",
            [COLUMN_TYPE.ExtPrice]: String(
              calcExtPrice(item.quantity, item.unitPrice),
            ),
            [COLUMN_TYPE.CostCode]: formatCostCode(material?.costCode ?? ""),
          };
        }) || [];
      appendTableRows(newItems, handsonInstance);
    },
    [
      quoteDocument?.items,
      handsonInstance,
      findMaterialByName,
      calcExtPrice,
      formatCostCode,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(missingItems);
  }, [addAllItems, missingItems]);

  const addQuoteItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = quoteDocument?.items.length === missingItems.length;
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      quoteDocument?.items.length || 0,
      missingItems.length,
    );
  }, [
    handsonInstance,
    showModal,
    addAllItems,
    addMissingItems,
    quoteDocument?.items.length,
    missingItems,
  ]);

  return {
    addQuoteItems,
  };
};
