import { TagItem } from "@/common/components/tag-picker/TagItem";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { ProjectFieldsFragment, TagFieldsFragment } from "@/generated/graphql";
import { FC, ReactNode, useCallback } from "react";
import { Multiselect } from "../select/components/multiple/Multiselect";
import { getRandomTagColor } from "../tag-picker/TagColors";

type CustomTagType = TagFieldsFragment & { disabled?: boolean };

type Props = {
  options: CustomTagType[];
  selectedTags: string[] | null;
  setSelectedTags: (values: string[]) => void;
  disabled?: boolean;
  required?: boolean;
  limitTags?: number;
  placeholder?: string;
  popup?: ReactNode;
  project?: Pick<ProjectFieldsFragment, "id"> | null;
  creatable?: boolean;
  multiple?: boolean;
  className?: string;
};

export const TagsSelector: FC<Props> = ({
  options,
  selectedTags,
  setSelectedTags,
  limitTags,
  project,
  creatable = true,
  multiple = true,
  className = "",
  ...props
}) => {
  const { allTags, addTag } = useProjectTags();
  useSetCurrentProjectId(project?.id);

  const onChangeTags = useCallback(
    async (tags: string[] | null) => {
      const excludedColors = allTags.map((t) => t.color ?? "") || [];
      const newTags: string[] = [];
      for await (const tag of tags || []) {
        if (options.find((o) => o.id === tag)) {
          if (multiple) {
            newTags.push(tag);
          } else {
            newTags[0] = tag;
          }
        } else {
          const color = getRandomTagColor(excludedColors);

          const result = await addTag({
            color,
            name: tag,
          });
          if (result) {
            const option = result.find((t) => t.name === tag);
            if (option) {
              if (multiple) {
                newTags.push(option.id);
              } else {
                newTags[0] = option.id;
              }
            }
          }
        }
      }
      setSelectedTags(newTags);
    },
    [addTag, options, setSelectedTags, allTags, multiple],
  );
  return (
    <Multiselect<CustomTagType>
      creatable={creatable}
      creatableFn={
        creatable
          ? (value) =>
              ({
                name: value,
                color: "#333333",
              }) as TagFieldsFragment
          : undefined
      }
      filterSelectedOptions={true}
      className={`${className} w-full`}
      chipSize="small"
      options={options}
      values={selectedTags}
      limitTags={limitTags}
      moreItems={options
        .filter(
          (tag) =>
            !limitTags ||
            selectedTags?.slice(1, selectedTags.length).includes(tag.id),
        )
        .map((tag) => (
          <TagItem key={tag.id} tag={tag} className="mr-1" />
        ))}
      renderTag={(tag) => (
        <TagItem
          tag={tag}
          key={tag.id}
          className="mr-1 mt-1"
          onRemove={(value) =>
            setSelectedTags([
              ...(selectedTags?.filter((t) => t !== value.id) || []),
            ])
          }
        />
      )}
      onMultipleChange={onChangeTags}
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      disableCloseOnSelect
      getOptionDisabled={(option) => !!option.disabled}
      customRender={(option) => <TagItem tag={option} className="m-1" />}
      testId="tags-selector"
      sx={{
        ".MuiInputBase-formControl.MuiAutocomplete-inputRoot": {
          flexWrap: "nowrap",
        },
      }}
      {...props}
    />
  );
};
