import { CostCodesSelectorControlled } from "@/common/components/cost-code-selector/CostCodeSelectorControlled";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col gap-1`;
const Label = tw.div`font-medium`;
const InputContainer = tw.div``;

export const VendorCostCode = () => {
  return (
    <Container>
      <Label>
        <FormattedMessage id="DEFAULT_COST_CODE" />
      </Label>
      <InputContainer>
        <CostCodesSelectorControlled name="costCodeId" />
      </InputContainer>
    </Container>
  );
};
