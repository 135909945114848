import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NewBuyoutForm } from "./NewBuyoutForm";

type Props = {
  disabled?: boolean;
  wide?: boolean;
  project?: ProjectExtendedFieldsFragment | null;
  small?: boolean;
};

export const NewBuyoutButton: FC<Props> = ({
  disabled,
  wide,
  project,
  small = false,
}) => {
  const intl = useIntl();

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton
          wide={wide}
          $small={small}
          testId="new-release-button"
          onClick={() => togglePanel(true)}
          disabled={disabled}
        >
          <FormattedMessage id="NEW_BUYOUT" />
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <NewBuyoutForm
          project={project}
          label={intl.$t({ id: "NEW_BUYOUT" })}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};
