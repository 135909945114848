import { GroupTagPicker } from "@/common/components/groups/components/group-tag-picker/GroupTagPicker";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useInvoicesUpdate } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoicesUpdate";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

const MORE_TAG_COUNT = 3;

export const InvoiceGroups = () => {
  const { invoiceFolders } = useStartupDataStore(
    useShallow((state) => ({ invoiceFolders: state.invoiceFolders })),
  );
  const { invoice } = useInvoiceVerification();
  const { updateInvoices, loading } = useInvoicesUpdate();

  const onRemove = useCallback(
    async (group: { id: string }) => {
      if (!invoice) {
        return;
      }
      await updateInvoices({
        invoiceIds: [invoice.id],
        unassignedFolderIds: [group.id],
      });
    },
    [invoice, updateInvoices],
  );

  const onAdd = useCallback(
    async (selectedFolderId: string | null) => {
      if (!invoice || !selectedFolderId) {
        return;
      }
      await updateInvoices({
        invoiceIds: [invoice.id],
        assignedFolderIds: [selectedFolderId],
      });
    },
    [invoice, updateInvoices],
  );

  if (!invoice) {
    return null;
  }

  return (
    <GroupTagPicker
      groups={invoiceFolders}
      existingGroups={invoice.folders}
      moreTagCount={MORE_TAG_COUNT}
      onRemove={onRemove}
      onAdd={onAdd}
      loading={loading}
      placeholders={{ add: "ADD_TO_INVOICE_GROUP" }}
    />
  );
};
