import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DateView } from "@/common/utils/dates/DateView";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Item = tw.div`text-2xs flex gap-1 text-gray-500`;

type Props = {
  invoice: InvoiceSummaryFieldsFragment;
};

export const InvoiceDueDate: FC<Props> = ({ invoice }) => {
  return (
    <NotNullableRenderer value={!!invoice.dueDate} defaultValue="">
      <Item>
        <FormattedMessage id="INVOICE_DUE_DATE" tagName="span" />
        <DateView date={invoice.dueDate} />
      </Item>
    </NotNullableRenderer>
  );
};
