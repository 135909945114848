import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  SourceSystem,
  useExternalServiceCodesQuery,
} from "@/generated/graphql";

export const useExternalServiceCodes = (defaultSourceSystem?: SourceSystem) => {
  const { connectedSourceSystem } = useOrgSettings();
  const {
    data,
    loading: loadingExternalServiceCodes,
    error: externalServiceCodesError,
  } = useExternalServiceCodesQuery({
    variables: {
      sourceSystem:
        defaultSourceSystem ?? connectedSourceSystem ?? SourceSystem.Foundation,
    },
    skip: !defaultSourceSystem && !connectedSourceSystem,
  });

  useErrorEffect(externalServiceCodesError);

  return {
    externalServiceCodes: data?.externalServiceCodes ?? [],
    loadingExternalServiceCodes,
    externalServiceCodesError,
  };
};
