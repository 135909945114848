import { GroupFilter } from "@/common/components/groups/components/group-filter/GroupFilter";
import { useInvoiceFolders } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/invoice-folder-picker/hooks/useInvoiceFolders";
import { InvoiceType } from "@/generated/graphql";
import { FC } from "react";

type Props = {
  value: string[] | null | undefined;
  onChange: (folderId: string[] | undefined) => void;
  label?: string;
  withCustomView?: boolean;
  clear?: () => void;
};

export const ReceiptFolderFilter: FC<Props> = (props: Props) => {
  const { folders, createFolder, updateFolder, deleteFolder } =
    useInvoiceFolders(InvoiceType.Receipt);

  return (
    <GroupFilter
      createGroup={createFolder}
      folders={folders}
      translationKeys={{
        addNew: "ADD_NEW_RECEIPT_FOLDER",
        groupName: "RECEIPT_GROUP_NAME",
        all: "ALL_RECEIPT_GROUPS",
        unassigned: "UNASSIGNED_RECEIPT_GROUPS",
        archiveTitle: "DELETE_RECEIPT_GROUP_WITH_NAME",
        archiveContent: "DELETE_RECEIPT_GROUP_CONFIRMATION",
      }}
      updateGroup={updateFolder}
      deleteGroup={deleteFolder}
      withCustomView
      {...props}
    />
  );
};
