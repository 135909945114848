import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  OrgPreferredVendor,
  useEnterpriseVendorsQuery,
} from "@/generated/graphql";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";

const useEnterprisePreferredVendors = () => {
  const { enterprise, viewer } = useStartupDataStore(
    useShallow((state) => ({
      enterprise: state.enterprise,
      viewer: state.viewer,
    })),
  );

  const [vendors, setVendors] = useState<OrgPreferredVendor[] | null>(null);

  const { data, error } = useEnterpriseVendorsQuery({
    variables: {
      excludedOrgIDs: [viewer?.org?.id || ""],
    },
    skip:
      !enterprise?.name || enterprise?.orgs.length === 1 || !viewer?.org?.id,
  });

  useEffect(() => {
    if (error) {
      return;
    }

    const orgPreferredVendors =
      data?.viewer?.org?.enterprise?.orgPreferredVendors;

    if (orgPreferredVendors) {
      setVendors(orgPreferredVendors as OrgPreferredVendor[]);
    }
  }, [data, error]);

  return {
    vendors,
  };
};

export default useEnterprisePreferredVendors;
