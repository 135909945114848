import { useCounter } from "../../circle-counter/useCounter";

const GRID_COUNT_KEY = "count";

/**
 * Generates a zero-padded count index.
 * For example:
 * - index = 1 → "001"
 * - index = 10 → "010"
 * - index = 100 → "100"
 */
export const generateCountIndex = (index?: number): string =>
  index != null ? index.toString().padStart(3, "0") : "000";

/**
 * Generates a compound count key based on up to five indices.
 * The indices are zero-padded and joined by underscores.
 */
export const getCountKey = (...indices: (number | undefined)[]): string =>
  indices
    .filter((index) => index != null && !isNaN(Number(index)))
    .map((index) => generateCountIndex(index!))
    .join("_");

/**
 * Extracts the root part (first segment) from a count key.
 */
export const extractRootFromCountKey = (countKey: string): string =>
  countKey?.split("_")[0] || "";

/**
 * Converts the root part of a count key to a number.
 */
export const extractRootPositionFromCountKey = (countKey: string): number =>
  Number(extractRootFromCountKey(countKey)) || 0;

/**
 * Hook for generating counts using a count map.
 */
export const useCountGeneration = (countMap: Map<string, number>) => {
  const { getCount } = useCounter(countMap);

  /**
   * Generates a count key and retrieves the corresponding count value.
   */
  const generateCount = (...indices: (number | undefined)[]) =>
    getCount(`${GRID_COUNT_KEY}_${getCountKey(...indices)}`);

  /**
   * Generates a count key and retrieves the corresponding count value.
   * Additionally, returns the count key.
   */
  const generateCountAndItemKey = (...indices: (number | undefined)[]) => {
    const itemKey = getCountKey(...indices);
    const count = generateCount(...indices);
    return {
      count,
      itemKey,
    };
  };

  return { generateCount, generateCountIndex, generateCountAndItemKey };
};
