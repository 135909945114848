import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectItemsZones } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import {
  AddToReleaseItemInput,
  ReleaseFieldsFragment,
  ReleaseProjectItemFieldsFragment,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import Decimal from "decimal.js";
import { isEqual } from "lodash";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { useReleaseProject } from "./release-items-list/ReleaseProjectProvider";

export type SelectableItem = {
  itemId: string;
  buyoutItemId?: string;
  estimatedItemId?: string | null;
  zoneId: string;
  quantityDecimal: string;
  costCodeId?: string;
};

type ProviderContextType = {
  selectedItems: SelectableItem[];
  setSelectedItems: (items: SelectableItem[]) => void;
  addItemsToRelease: (
    providedRelease?: ReleaseFieldsFragment,
  ) => Promise<boolean>;
  updating: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  selectedItems: [],
  setSelectedItems: NoFunction,
  addItemsToRelease: () => Promise.resolve(false),
  updating: false,
});

export const AddItemsToReleaseProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { release } = useRelease();
  const { addItems, updating } = useReleaseUpdate();
  const { buyout } = useContractorBuyout();
  const { project: releaseProject } = useReleaseProject();
  const project = releaseProject;
  const { setError } = useGlobalError();
  const [previousItems, setPreviousItems] = useState<SelectableItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectableItem[]>([]);
  const { zones, groupedByZones } = useProjectItemsZones();

  const [updateContractorRelease] = useUpdateContractorReleaseMutation();

  const addItemsToBuyoutRelease = async (
    providedRelease: ReleaseFieldsFragment,
    itemsToAdd: AddToReleaseItemInput[],
  ): Promise<boolean> => {
    if (itemsToAdd.length > 0) {
      try {
        const { data, errors } = await updateContractorRelease({
          variables: {
            input: {
              releaseId: providedRelease.id,
              version: providedRelease.version,
              addedItems: itemsToAdd,
              assignDefaultCostCodes: false,
            },
          },
        });
        setError(errors);
        return Boolean(!!data?.updateContractorRelease && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    let initialItems = [] as SelectableItem[];
    if (zones.length > 0) {
      zones.forEach((zone) => {
        zone.items.forEach((group) => {
          group.items.forEach((item) => {
            if (
              (item as ReleaseProjectItemFieldsFragment).buyoutItems.filter(
                (b) => b.buyout?.id && b.buyout?.id === buyout?.id,
              ).length > 0
            ) {
              const items = (
                item as ReleaseProjectItemFieldsFragment
              ).buyoutItems
                .filter((b) => b.buyout?.id === buyout?.id)
                .map((b) => ({
                  itemId: b.id,
                  zoneId: zone?.id ?? UNSPECIFIED_ZONE_ID,
                  quantityDecimal: "0",
                }));
              initialItems = [...initialItems, ...items];
            } else if (item.estimatedItems?.length > 0) {
              item.estimatedItems.forEach((estimatedItem) => {
                initialItems = [
                  ...initialItems,
                  {
                    itemId: item.id,
                    zoneId: zone?.id ?? UNSPECIFIED_ZONE_ID,
                    estimatedItemId: estimatedItem.id,
                    quantityDecimal: "0",
                  },
                ];
              });
            } else {
              initialItems = [
                ...initialItems,
                {
                  itemId: item.id,
                  zoneId: zone?.id ?? UNSPECIFIED_ZONE_ID,
                  estimatedItemId: null,
                  quantityDecimal: "0",
                },
              ];
            }
          });
        });
      });
    }
    if (previousItems.length !== initialItems.length) {
      setPreviousItems(initialItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zones]);

  const addItemsToRelease = async (
    providedRelease?: ReleaseFieldsFragment,
  ): Promise<boolean> => {
    const items = selectedItems.map((selectedItem) => {
      const buyoutItem = buyout?.items.find(
        (bi) => bi.id === selectedItem.itemId,
      );

      const projectItem =
        project?.items.find((pi) => pi.id === selectedItem.itemId) ??
        buyoutItem?.projectItem;

      const estimatedItems = projectItem?.estimatedItems.filter(
        (ei) =>
          (groupedByZones
            ? (ei.zone?.id ?? UNSPECIFIED_ZONE_ID) === selectedItem.zoneId
            : true) && ei.id === selectedItem.estimatedItemId,
      );

      return {
        projectItemId: projectItem?.id,
        zoneId:
          selectedItem.zoneId === UNSPECIFIED_ZONE_ID
            ? null
            : selectedItem.zoneId,
        buyoutItemId: buyoutItem?.id ?? undefined,
        quantityDecimal: selectedItem.quantityDecimal ?? "0",
        manufacturerId:
          estimatedItems?.find((ei) => ei.id === selectedItem.estimatedItemId)
            ?.manufacturer?.id ??
          projectItem?.manufacturer?.id ??
          undefined,
        tags: buyoutItem?.tags,
        estimatedItemId: selectedItem.estimatedItemId,
        costCodeId: selectedItem.costCodeId,
      };
    });

    const releaseObj = providedRelease || release;
    const currentLastPosition = release?.items?.length ?? 0;

    try {
      if (releaseObj) {
        const itemsToAdd: AddToReleaseItemInput[] = [];
        items.forEach((item, index) => {
          const projectItem = project?.items.find(
            (pi) => pi.id === item.projectItemId,
          );

          const itemToAdd: AddToReleaseItemInput = {
            buyoutItemId: item.buyoutItemId,
            projectItem:
              !item.buyoutItemId && !item.estimatedItemId
                ? {
                    orgCatalogSkuId: isOrgCatalogSku(
                      projectItem?.material.material,
                    )
                      ? projectItem?.material.material.id
                      : undefined,
                    estimateUom:
                      projectItem?.estimateUom.pluralDescription || "",
                    masterProductId: isProductSku(
                      projectItem?.material.material,
                    )
                      ? projectItem?.material.material.id
                      : undefined,
                    masterSkuId: isMasterSku(projectItem?.material.material)
                      ? projectItem?.material.material.id
                      : undefined,
                  }
                : undefined,
            estimatedItemId: !item.buyoutItemId
              ? item.estimatedItemId || undefined
              : undefined,
            quantityDecimal: Decimal.max(item.quantityDecimal, 0).toString(),
            zoneId: item.zoneId,
            tags: item.tags?.map((tag) => tag.id),
            manufacturerId: item.manufacturerId,
            position: currentLastPosition + index,
            costCodeId: item.costCodeId,
          };

          if (groupedByZones) {
            const existingItem = itemsToAdd.find(
              (i) =>
                i.zoneId === itemToAdd.zoneId &&
                ((i.projectItem &&
                  itemToAdd.projectItem &&
                  isEqual(i.projectItem, itemToAdd.projectItem)) ||
                  (i.buyoutItemId &&
                    itemToAdd.buyoutItemId &&
                    i.buyoutItemId === itemToAdd.buyoutItemId)),
            );
            if (existingItem) {
              existingItem.quantityDecimal = Decimal.sum(
                existingItem.quantityDecimal || 0,
                itemToAdd.quantityDecimal || 0,
              ).toString();
            } else {
              itemsToAdd.push(itemToAdd);
            }
          } else {
            const existingItem = itemsToAdd.find(
              (i) =>
                (i.projectItem &&
                  itemToAdd.projectItem &&
                  isEqual(i.projectItem, itemToAdd.projectItem)) ||
                (i.buyoutItemId &&
                  itemToAdd.buyoutItemId &&
                  i.buyoutItemId === itemToAdd.buyoutItemId),
            );
            if (existingItem) {
              existingItem.quantityDecimal = Decimal.sum(
                existingItem.quantityDecimal || 0,
                itemToAdd.quantityDecimal || 0,
              ).toString();
            } else {
              itemsToAdd.push(itemToAdd);
            }
          }
        });
        if (providedRelease) {
          return await addItemsToBuyoutRelease(providedRelease, itemsToAdd);
        }
        return await addItems(itemsToAdd);
      }
    } catch (errors) {
      setError(errors);
      return false;
    }
    return false;
  };

  return (
    <ProviderContext.Provider
      value={{
        selectedItems,
        setSelectedItems,
        addItemsToRelease,
        updating,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAddItemsToRelease = (): ProviderContextType =>
  useContext(ProviderContext);
