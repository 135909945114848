import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useImportExternalTaxCodes } from "../../providers/ImportExternalTaxCodesProvider";

export const ImportExternalTaxCodesModal = () => {
  const { modalOpened, pages, closeModal } = useImportExternalTaxCodes();

  return (
    <WizardModal
      mode={WizardModalMode.SINGLE_PAGE}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};
