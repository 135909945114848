import { FC } from "react";
import { useIntl } from "react-intl";
import { StyledNumericalInput } from "../../../styles/SalesTaxInput.styles";

type Props = {
  value: string | number | null;
  setValue: (value: number | null) => void;
  formattedTaxAmount: string;
  onBlur: () => void;
};

export const SalesTaxPercentageInput: FC<Props> = ({
  value,
  setValue,
  formattedTaxAmount,
  onBlur,
}) => {
  const intl = useIntl();
  return (
    <StyledNumericalInput
      value={value}
      onChange={(e) => setValue(Number(e.target.value))}
      onBlur={onBlur}
      decimals={4}
      label={intl.$t(
        { id: "TAX_WITH_AMOUNT" },
        {
          taxAmount: formattedTaxAmount,
        },
      )}
      suffix="%"
      className="w-full"
      xs
      inputProps={{
        className: "text-right bg-white",
      }}
    />
  );
};
