import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { DateTimePickerControlled } from "@/common/components/picker/components/DateTimePickerControlled";
import { RELEASE_DRAFT_STATUSES } from "@/common/const";
import { CreateOrderFromQuoteFormValues } from "@/contractor/pages/home/releases/pages/deliveries/components/order-from-quote/create-order-from-quote/components/CreateOrderFromQuoteForm";
import { useReleaseDatePickerConfig } from "@/contractor/pages/home/releases/pages/deliveries/hooks/useReleaseDatePickerConfig";
import { ReleaseStatus } from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRelease } from "../../../../providers/ReleaseProvider";

type Props = Pick<
  CreateOrderFromQuoteFormValues,
  "timeTBD" | "orderDate" | "orderTypeId"
>;

export const ReleaseDateTimePickerControlled = () => {
  const { release } = useRelease();
  const { watch, setValue } = useFormContext<Props>();
  const orderTypeId = watch("orderTypeId");
  const { getOrderType } = useOrderTypeOptions();
  const orderDate = watch("orderDate");
  const intl = useIntl();
  const timeTBD = watch("timeTBD");

  useEffect(() => {
    if (orderDate) {
      setValue("timeTBD", false);
    }
  }, [timeTBD, setValue, orderDate]);

  const releaseInput = useMemo(
    () => ({
      timeTBD,
      type: getOrderType(orderTypeId),
    }),
    [timeTBD, getOrderType, orderTypeId],
  );

  const {
    actionBar,
    labelProps: { label, styling },
    disabled,
  } = useReleaseDatePickerConfig({
    date: orderDate,
    labels: {
      defaultLabel: intl.$t({ id: "REQUESTED_FULFILLMENT_DATE" }),
    },
    release: releaseInput,
  });

  return (
    <DateTimePickerControlled
      name="orderDate"
      label={label}
      rules={{
        required:
          !disabled &&
          !RELEASE_DRAFT_STATUSES.includes(release?.status as ReleaseStatus),
      }}
      disabled={disabled}
      InputLabelProps={styling}
      actionBar={actionBar}
      slotProps={{
        actionBar: {
          onClick: () => {
            setValue("timeTBD", true);
            setValue("orderDate", null);
          },
        },
      }}
    />
  );
};
