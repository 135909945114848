import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Tabs } from "@/common/components/tabs/Tabs";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { setValue } from "@/common/utils/localStorage";
import { routes } from "@/config/routes";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useInvoiceStats } from "../../invoices/pages/scanned-invoices/hooks/useInvoiceStats";
import { useDeliverySlipStats } from "../../releases/pages/delivery-slips/providers/DeliverySlipStatsProvider";

export const MainTabNavigation = () => {
  const intl = useIntl();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);
  const { invoiceStats } = useInvoiceStats();
  const { deliverySlipsStats } = useDeliverySlipStats();

  const updateLocalStorage = useCallback((path?: string) => {
    if (path) {
      setValue(LOCAL_STORAGE_KEYS.DEFAULT_PAGE, path);
    }
  }, []);

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "PROJECTS" }),
        path: routes.projects,
      },
      {
        label: intl.$t({ id: "QUOTES" }),
        path: routes.quotes,
        excluded: !canViewPrices,
      },
      {
        label: intl.$t({ id: "BUYOUTS" }),
        path: routes.buyouts,
      },
      {
        label: intl.$t({ id: "DELIVERIES" }),
        path: routes.deliveries,
        skipCase: true,
        count: deliverySlipsStats?.pendingCount || undefined,
      },
      {
        label: intl.$t({ id: "INVOICES" }),
        path: routes.invoices,
        skipCase: true,
      },
      {
        label: intl.$t({ id: "RECEIPTS" }),
        path: routes.receipts,
        count: invoiceStats?.pendingReceipts || undefined,
        skipCase: true,
      },
    ],
    [
      canViewPrices,
      deliverySlipsStats?.pendingCount,
      intl,
      invoiceStats?.pendingReceipts,
    ],
  );

  return (
    <Tabs
      tabs={tabs}
      className="-top-14 -mb-10 -mt-14 pt-6 xl:pt-10"
      onTabItemClick={updateLocalStorage}
    />
  );
};
