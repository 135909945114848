import { If } from "@/common/components/if/If";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { TextFieldProps } from "@/common/components/textfield/TextField";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUser } from "@/common/providers/UserProvider";
import { CostTypeFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FaCalculator } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { NumberFormatValues } from "react-number-format";
import tw from "tailwind-styled-components";

const Container = tw.div`relative`;
const CostTypeInfoIcon = tw(FaCalculator)`
  text-blue-800 absolute top-1/2 -translate-y-1/2 right-2 hover:text-blue-500
`;

type Props = TextFieldProps &
  ControlledProps & {
    costTypes?: CostTypeFieldsFragment[];
  };

const NumericalInputStyled = tw(NumericalInputControlled)`w-full bg-white`;

export const AmountWithCurrencyTextField: FC<Props> = (props) => {
  const { costTypes } = props;
  const { currencySymbol } = useUser();

  return (
    <Container>
      <NumericalInputStyled
        inputProps={{ className: "text-center" }}
        prefix={currencySymbol}
        isAllowed={(values: NumberFormatValues) => {
          const { floatValue } = values;
          return !floatValue || floatValue > 0;
        }}
        useWatchValue
        {...props}
      />
      <If isTrue={costTypes && costTypes.length > 0}>
        <Tooltip
          element={<CostTypeInfoIcon />}
          id="cost-types-tooltip"
          classes={{
            tooltip: "-top-10",
          }}
        >
          <FormattedMessage id="COST_TYPES" />:{" "}
          {costTypes?.map((costType) => costType.description).join(", ")}
        </Tooltip>
      </If>
    </Container>
  );
};
