import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { BarChart, ChevronRight } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Breadcrumbs = tw.div`grid grid-flow-col gap-1 w-fit items-end justify-items-start text-xs text-center mb-1`;
const Breadcrumb = tw.div`grid grid-flow-col gap-1 w-fit items-end justify-items-start text-xs text-center`;
const Link = tw(LinkLike)`grid grid-flow-col justify-items-start text-xs`;
const Label = tw.div`grid gap-1 grid-flow-col justify-items-start text-gray-600 text-xs`;
const BreadcrumbChart = tw(BarChart)`w-3.5 h-3.5`;
const ChevronRightStyled = tw(ChevronRight)`w-4 h-4 text-gray-600`;

type ChartDataType = {
  id?: string;
  name?: string;
  type?: string;
  estimated?: string | null | undefined;
  ordered?: string | null | undefined;
  invoiced?: string | null | undefined;
  items?: ChartDataType[];
};

type Props = {
  breadcrumbs: ChartDataType[];
  setCurrentSelection: (items: ChartDataType[]) => void;
  setBreadcrumbs: (items: ChartDataType[]) => void;
};

export const ReportChartBreadcrumbs: FC<Props> = ({
  breadcrumbs,
  setCurrentSelection,
  setBreadcrumbs,
}) => {
  const onClick = (item: ChartDataType, index: number) => {
    if (item.items?.length) {
      setCurrentSelection(item.items);
      setBreadcrumbs(breadcrumbs.slice(0, index + 1));
    }
  };

  return (
    <Breadcrumbs>
      {breadcrumbs.map((item, index) => {
        return (
          <Breadcrumb key={index}>
            <If isTrue={index < breadcrumbs.length - 1}>
              <Link key={index} onClick={() => onClick(item, index)}>
                <BreadcrumbChart />
                {item.name}{" "}
                <If isTrue={item.type}>
                  (<FormattedMessage id={item.type} />)
                </If>
              </Link>
              <ChevronRightStyled />
            </If>
            <If isTrue={index === breadcrumbs.length - 1}>
              <Label key={index}>
                <BreadcrumbChart />
                {item.name}{" "}
                <If isTrue={item.type}>
                  (<FormattedMessage id={item.type} />)
                </If>
              </Label>
            </If>
          </Breadcrumb>
        );
      })}
    </Breadcrumbs>
  );
};
