import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { SelectCustomItemView } from "@/common/components/select/components/single/components/SelectCustomItemView";
import { Select } from "@/common/components/select/components/single/Select";
import { TextField } from "@/common/components/textfield/TextField";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import Close from "@mui/icons-material/Close";
import { Popover } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ArchiveGroupFunction,
  CreateGroupFunction,
  Group,
  UpdateGroupFunction,
} from "../../common/types/types";
import { useGroupEditStore } from "../../stores/useGroupEditStore";
import { GroupFilterCustomRender } from "./custom-render/GroupFilterCustomRender";
import { EditGroupDialog } from "./edit/EditGroupDialog";

const NewGroup = tw.div`z-50 flex flex-row justify-between items-center pl-4 bg-blue-100 border border-gray-300 rounded-t-lg border-b-0 text-sm`;
const NewGroupItem = tw.div`truncate 2xl:whitespace-normal`;
const GroupForm = tw.div`flex flex-row gap-2 p-3 bg-blue-100`;
const Container = tw.div``;
const TextFieldStyled = tw(TextField)`bg-white min-w-52`;

export const ADD_NEW_GROUP_ID = "ADD_NEW_GROUP";
export const ALL_GROUP_ID = "ALL_GROUP";
export const UNASSIGNED_GROUP_ID = "UNASSIGNED_GROUP";

type GroupInput = {
  name: string;
};

type Option = {
  id: string;
  name: string;
};

type Props = {
  value: string[] | null | undefined;
  onChange: (groupId: string[] | undefined) => void;
  label?: string;
  withCustomView?: boolean;
  clear?: () => void;
  translationKeys: {
    all: string;
    unassigned: string;
    addNew: string;
    groupName: string;
    archiveTitle: string;
    archiveContent: string;
  };
  folders: Group[];
  createGroup: CreateGroupFunction;
  updateGroup: UpdateGroupFunction;
  deleteGroup: ArchiveGroupFunction;
  classes?: {
    paper?: string;
  };
};

export const GroupFilter: FC<Props> = ({
  value,
  onChange,
  label,
  withCustomView = false,
  clear,
  translationKeys,
  folders,
  createGroup,
  updateGroup,
  deleteGroup,
  classes,
}: Props) => {
  const intl = useIntl();
  const [newFolder, setNewFolder] = useState<GroupInput>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { reset } = useGroupEditStore();
  const ref = useRef<HTMLDivElement>(null);
  const { setWarningAlert } = useSnackbar();

  useEffect(() => {
    reset();
  }, [reset]);

  const emptyFolder: Option = useMemo(
    () => ({
      id: ADD_NEW_GROUP_ID,
      name: intl.$t({ id: translationKeys.addNew }),
      readOnly: true,
    }),
    [intl, translationKeys.addNew],
  );

  const defaultOptions = useMemo(() => {
    return [
      {
        id: ALL_GROUP_ID,
        name: intl.$t({ id: translationKeys.all }),
      },
      {
        id: UNASSIGNED_GROUP_ID,
        name: intl.$t({ id: translationKeys.unassigned }),
      },
    ];
  }, [intl, translationKeys.all, translationKeys.unassigned]);

  const options = useMemo(() => {
    return [
      ...defaultOptions,
      ...folders.toSorted((a, b) => (a.name || "").localeCompare(b.name || "")),
    ] as Option[];
  }, [folders, defaultOptions]);

  const [text, setInputText] = useState<string>(
    options.find((v) =>
      value?.length === 0
        ? v.id === UNASSIGNED_GROUP_ID
        : value?.includes(v.id),
    )?.name || "",
  );

  useEffect(() => {
    setInputText(
      options.find((v) =>
        value?.length === 0
          ? v.id === UNASSIGNED_GROUP_ID
          : value?.includes(v.id),
      )?.name || "",
    );
  }, [options, value, intl]);

  const blurInput = useCallback(() => {
    if (ref.current) {
      const input = ref.current.querySelector("input");
      if (input) {
        input.blur();
      }
    }
  }, []);

  const handleChange = useCallback(
    (folderId: string | null) => {
      if (folderId === ADD_NEW_GROUP_ID) {
        setNewFolder({
          name: text,
        });
        setAnchorEl(ref.current);
      } else if (folderId === ALL_GROUP_ID || folderId === ADD_NEW_GROUP_ID) {
        setInputText("");
        onChange(undefined);
        blurInput();
      } else if (folderId) {
        onChange(folderId === UNASSIGNED_GROUP_ID ? [] : [folderId]);
      } else {
        onChange(undefined);
      }
    },
    [onChange, ref, text, blurInput],
  );

  const onClose = useCallback(() => {
    setInputText("");
    setNewFolder(undefined);
    setAnchorEl(null);
  }, []);

  const handleCreate = useCallback(async () => {
    if (newFolder) {
      const existingFolder = folders.find(
        (folder) => folder.name === newFolder.name.trim(),
      );
      if (existingFolder) {
        setWarningAlert(intl.$t({ id: "GROUP_ALREADY_EXISTS" }));
        return;
      }
      setLoading(true);
      const result = await createGroup(newFolder);
      if (result) {
        onClose();
        blurInput();
      }
    }
    setLoading(false);
  }, [
    newFolder,
    createGroup,
    onClose,
    blurInput,
    folders,
    setWarningAlert,
    intl,
  ]);

  const handleInputChange = useCallback(
    (text: string) => {
      if (text === defaultOptions[0].name) {
        setInputText("");
      }
      setInputText(text);
    },
    [defaultOptions],
  );

  const selectedOption = useMemo(
    () => options.find((option) => option.id === value?.[0]),
    [options, value],
  );

  const handleClearChipClick = useCallback(() => {
    if (clear) {
      clear();
      setInputText("");
    }
  }, [clear]);

  const computedValue = useMemo(
    () => (value && value.length === 0 ? UNASSIGNED_GROUP_ID : value?.[0]),
    [value],
  );

  const onChangeNewFolder = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewFolder({
        ...newFolder,
        name: event.target.value,
      });
    },
    [newFolder],
  );

  const handleDeleteGroup = useCallback(
    async (id: string) => {
      onChange(undefined);
      return await deleteGroup(id);
    },
    [deleteGroup, onChange],
  );

  return (
    <Container ref={ref}>
      <If isTrue={!newFolder}>
        <Select
          className="max-w-full"
          testId="folder-picker"
          label={
            !value ? label || intl.$t({ id: translationKeys.all }) : undefined
          }
          getLabel={(folder) => folder.name}
          getValue={(folder) => folder.id}
          creatableFn={() => emptyFolder}
          creatableTextKey={translationKeys.addNew}
          creatable
          creatableFirstOption
          creatableAlwaysVisible
          value={computedValue}
          options={options}
          onChange={handleChange}
          handleInputChange={handleInputChange}
          customRender={(item: Option) => (
            <GroupFilterCustomRender
              item={item}
              archiveGroup={handleDeleteGroup}
              contentKey={translationKeys.archiveContent}
              titleKey={translationKeys.archiveTitle}
            />
          )}
          loading={loading}
          inputValue={text}
          onBlur={() => {
            setInputText(
              options.find((v) =>
                value?.length === 0
                  ? v.id === UNASSIGNED_GROUP_ID
                  : value?.includes(v.id),
              )?.name || "",
            );
          }}
          forcePopupIcon
          selectedOptionCustomView={
            withCustomView && selectedOption ? (
              <SelectCustomItemView
                text={selectedOption.name}
                clear={clear ? handleClearChipClick : undefined}
              />
            ) : null
          }
        />
      </If>
      <If isTrue={newFolder}>
        <NewGroup>
          <FormattedMessage
            id={translationKeys.addNew}
            tagName={NewGroupItem}
          />
          <IconButtonBorderless onClick={() => setNewFolder(undefined)}>
            <Close />
          </IconButtonBorderless>
        </NewGroup>
      </If>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        open={!!newFolder}
        classes={{
          paper: `mt-10 xl:-ml-[61px] md:-ml-[9px] shadow-none border border-gray-300 border-t-0 ${classes?.paper}`,
        }}
      >
        <GroupForm>
          <TextFieldStyled
            testId="group-name"
            label={intl.$t({ id: translationKeys.groupName })}
            value={newFolder?.name}
            onChange={onChangeNewFolder}
            onClickEnter={handleCreate}
          />
          <PrimaryButton onClick={handleCreate} testId="addBtn">
            <FormattedMessage id="ADD" />
          </PrimaryButton>
        </GroupForm>
      </Popover>
      <EditGroupDialog updateGroup={updateGroup} />
    </Container>
  );
};
