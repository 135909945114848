import { ConnectionStatusAdornment } from "@/common/components/connection-status-adornment/ConnectionStatusAdornment";
import { If } from "@/common/components/if/If";
import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { DatePickerControlled } from "@/common/components/picker/components/DatePickerControlled";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorPickerControlled } from "@/common/components/vendor-picker/VendorPickerControlled";
import { useUpdateFormFieldBasedOnValue } from "@/common/hooks/useUpdateFormFieldBasedOnValue";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { SearchOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";
import { InvoiceOrderMagicWand } from "./InvoiceOrderMagicWand";

const Container = tw.div`w-full grid grid-row-2 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md grid-cols-3`;
const SearchIcon = tw(
  SearchOutlined,
)`text-blue-500 cursor-pointer focus:text-black hover:text-black -mr-1`;
const RoundedLetter = tw.div`rounded-full bg-gray-300 text-black text-center w-6 h-6 text-xs flex items-center justify-center`;

export const InvoiceCreateReleaseHeader: FC = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { setValue, watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { warehouses, loading: loadingWarehouses } = useWarehouses();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const externalPOsQueryForm = useFormContext<ExternalPOsQueryInputType>();

  const {
    openModal,
    externalPo,
    loadingExternalPo,
    importedItems,
    isNonItemizedPO,
    importedPoIsImmutable,
  } = useInvoiceImportExternalPO();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");

  useSetCurrentProjectId(projectId);
  useUpdateFormFieldBasedOnValue<InvoiceCreateReleaseFormValues>(
    projectId,
    "fulfillmentLocationId",
  );

  const fulfillmentOptions = useMemo(() => {
    const options = [];
    const selectedProject = projects.find((p) => p.id === projectId);
    if (projectId && selectedProject) {
      options.push(selectedProject);
    }
    options.push(...warehouses);
    return options;
  }, [projectId, projects, warehouses]);

  const onPoNumberSearchIconClick = useCallback(() => {
    if (!externalPo && poNumber) {
      externalPOsQueryForm?.setValue("search", poNumber);
    }
    openModal({ skipPoList: !!externalPo });
  }, [externalPo, poNumber, externalPOsQueryForm, openModal]);

  return (
    <Container>
      <SelectControlled
        name="projectId"
        options={projects}
        getValue={(o) => o.id}
        getLabel={(o) => getProjectSelectorLabel(o)}
        placeholder={intl.$t({ id: "PROJECT_NAME" })}
        loading={loadingProjects}
        disabled={
          !!externalPo &&
          matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
        }
        rules={{
          required: true,
        }}
      />
      <VendorPickerControlled
        disabled={
          matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER &&
          !!externalPo &&
          importedPoIsImmutable
        }
        required
        contactsFilter={(c) => c.receivesInvoiceNotifications}
        formatFullLabel
      />
      <SelectControlled
        name="fulfillmentLocationId"
        options={fulfillmentOptions}
        getValue={(o) => o?.id}
        getLabel={(o) => o.name}
        placeholder={intl.$t({ id: "FULFILLMENT_LOCATION" })}
        loading={loadingWarehouses}
        rules={{
          required: true,
        }}
      />
      <PoNumberingSettingsCheck>
        <TextFieldControlled
          name="poNumber"
          label={intl.$t({ id: "PO_NUMBER" })}
          className="bg-white"
          InputProps={{
            startAdornment: (
              <ConnectionStatusAdornment
                connectedSourceSystem={connectedSourceSystem}
                loading={loadingExternalPo}
                isActive={
                  !!externalPo &&
                  matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                }
                onClick={() =>
                  openModal({
                    skipPoList:
                      !!externalPo &&
                      matchedOrderViewState ===
                        MatchedOrderViewState.IMPORT_ORDER,
                  })
                }
                enabledTooltip="PO_FOUND_IN_SOURCE_SYSTEM"
                disabledTooltip="PO_NOT_FOUND_IN_SOURCE_SYSTEM"
              />
            ),
            endAdornment: connectedSourceSystem ? (
              <div className="flex">
                <InvoiceOrderMagicWand
                  poNumber={poNumber}
                  projectId={projectId}
                  setPoNUmber={(value) => {
                    setValue("poNumber", value);
                  }}
                />
                <If
                  isTrue={
                    matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                  }
                >
                  <Tooltip
                    id="po-type"
                    element={
                      <RoundedLetter>
                        {isNonItemizedPO ? "B" : "D"}
                      </RoundedLetter>
                    }
                  >
                    <FormattedMessage
                      id={isNonItemizedPO ? "BASIC" : "DETAILED"}
                    />
                  </Tooltip>
                </If>
                <SearchIcon onClick={onPoNumberSearchIconClick} />
              </div>
            ) : (
              <InvoiceOrderMagicWand
                poNumber={poNumber}
                projectId={projectId}
                setPoNUmber={(value) => {
                  setValue("poNumber", value);
                }}
              />
            ),
          }}
          disabled={!!importedItems}
        />
      </PoNumberingSettingsCheck>
      <OrderTypePickerControlled
        name="orderTypeId"
        disabled={!!importedItems}
      />
      <DatePickerControlled
        name="orderDate"
        label={intl.$t({ id: "ORDER_DATE" })}
        className="bg-white"
        maxDate={new Date()}
        rules={{
          required: true,
        }}
        utc
      />
    </Container>
  );
};
