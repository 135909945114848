import { Select } from "@/common/components/select/components/single/Select";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`grid grid-cols-[1fr_1.5fr] justify-between items-center gap-4 mt-2`;

type Props = {
  warehouseIDs: string[] | null | undefined;
  onChange: (
    warehouseIDs: string[] | undefined,
    willCall?: boolean | null,
  ) => void;
  label?: string;
  withCustomView?: boolean;
  clear?: () => void;
  willCall?: boolean | null;
};

const ALL_LOCATIONS = "-1";
const JOB_SITE = "-2";
const WILL_CALL = "-3";

export const OrderWarehousesFilter = ({
  warehouseIDs,
  onChange,
  willCall,
}: Props) => {
  const { warehouses, loading: loadingWarehouses } = useWarehouses();
  const intl = useIntl();

  const options = useMemo(
    () => [
      { id: ALL_LOCATIONS, label: intl.$t({ id: "ALL_LOCATIONS" }) },
      { id: WILL_CALL, label: intl.$t({ id: "CLIENT_DELIVERY" }) },
      { id: JOB_SITE, label: intl.$t({ id: "PROJECT_LOCATION_WITH_ADDRESS" }) },
      ...warehouses.map((warehouse) => ({
        id: warehouse.id,
        label: warehouse.name,
      })),
    ],
    [intl, warehouses],
  );

  const handleOnChange = useCallback(
    (value: string | null) => {
      if (value === WILL_CALL) {
        onChange(undefined, true);
        return;
      }
      if (value === ALL_LOCATIONS || !value) {
        onChange(undefined, undefined);
        return;
      }
      if (value === JOB_SITE) {
        onChange([], false);
        return;
      }
      onChange([value], false);
    },
    [onChange],
  );

  const calculatedValue = useMemo(() => {
    if (willCall) {
      return WILL_CALL;
    }
    if (!warehouseIDs) {
      return ALL_LOCATIONS;
    }
    if (warehouseIDs.length === 1) {
      const warehouse = warehouses.find(
        (warehouse) => warehouse.id === warehouseIDs[0],
      );
      if (warehouse) {
        return warehouse.id;
      }
      return JOB_SITE;
    }
    return JOB_SITE;
  }, [warehouseIDs, warehouses, willCall]);

  return (
    <Container>
      <FormattedMessage id="DELIVERY_LOCATION" />
      <Select
        testId="locationFilter"
        options={options}
        getValue={(option) => option.id}
        getLabel={(option) => option.label}
        loading={loadingWarehouses}
        value={calculatedValue}
        onChange={handleOnChange}
        inputProps={{
          className: calculatedValue === ALL_LOCATIONS ? "text-gray-600" : "",
        }}
      />
    </Container>
  );
};
