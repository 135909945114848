import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useClearPoNumberMutation } from "@/generated/graphql";

export const useClearPoNumber = () => {
  const [clearPoNumberMutation] = useClearPoNumberMutation();
  const { setError } = useGlobalError();

  const clearPoNumber = async (invoiceId: string) => {
    try {
      const { data, errors } = await clearPoNumberMutation({
        variables: {
          invoiceId,
        },
      });

      setError(errors);
      return !!data?.clearPoNumber;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return { clearPoNumber };
};
