import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import {
  Base,
  InputContainer,
  MdContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";

import {
  sxGroupHide,
  sxHiddenHide,
} from "@/common/components/grid-table/components/grid-item/utils";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import {
  EstimatedItemExtendedFieldsFragment,
  EstimatedItemFieldsFragment,
  ProjectItemExtendedFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ProjectItemMaterialView } from "../../../../../../common/components/project-item-material-view/ProjectItemMaterialView";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";
import { getEstimatedItem } from "../../utils/getEstimatedItem";
import { IncludeGroupedItems } from "../project-items/IncludeGroupedItems";
import { ProjectItemActions } from "../project-items/ProjectItemActions";
import { ProjectItemCostCodePicker } from "../project-items/ProjectItemCostCodePicker";
import { ProjectItemExtendedUnitPrice } from "../project-items/ProjectItemExtendedUnitPrice";
import { ProjectItemItemUomPicker } from "../project-items/ProjectItemItemUomPicker";
import { ProjectItemManufacturerSelector } from "../project-items/ProjectItemManufacturerSelector";
import { ProjectItemMaterialEditableView } from "../project-items/ProjectItemMaterialEditableView";
import { ProjectItemTagsPicker } from "../project-items/ProjectItemTagsPicker";
import { ProjectItemUnitPrice } from "../project-items/ProjectItemUnitPrice";
import { ProjectItemUnitsInput } from "../project-items/ProjectItemUnitsInput";
import { ProjectItemZonePicker } from "../project-items/ProjectItemZonePicker";
import { ProjectItemCheckbox } from "./ProjectItemCheckbox";
import { ProjectItemGroupCheckbox } from "./ProjectItemGroupCheckbox";
import { ProjectItemWholeSelectionCheckbox } from "./ProjectItemWholeSelectionCheckbox";

const ManufacturerContainer = tw(MdContainer)`
  col-span-2 lg:basis-30 2xl:basis-52
`;

const ItemContainer = tw(Base)`
  col-span-full
  basis-full 
  lg:basis-1/12 lg:w-1/12
  2xl:basis-2/12 2xl:w-2/12 flex-1
`;

const TagContainer = tw(Base)`
  lg:basis-1/12 lg:w-1/12
  max-w-full
  ${sxGroupHide} ${sxHiddenHide} justify-items-start
`;

export const useProjectListItemConfiguration = (
  includeZones: boolean,
  readonly?: boolean,
  items: Omit<ProjectItemFieldsFragment, "__typename">[] = [],
) => {
  const { newProjectEstimatedItem } = useEstimatedItems();
  const { project } = useProject();
  const intl = useIntl();
  const { uoms } = useUomOptions();
  const { hasManufacturersSetting } = useManufacturersSettings();
  const { hasPhaseCodes } = useOrgSettings();
  const configuration: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  > = useMemo(
    () => [
      {
        wrapper: Base,
        position: "center",
        item: ({ item }) => {
          return <ProjectItemCheckbox items={item.estimatedItems} />;
        },
        details: ({ item, index }) => {
          const estimatedItem = getEstimatedItem({ item, index });

          if (!estimatedItem) {
            return;
          }
          return <ProjectItemCheckbox items={[estimatedItem]} />;
        },
        group: (category) => <ProjectItemGroupCheckbox group={category} />,
        subgroup: (category) => <ProjectItemGroupCheckbox group={category} />,
        header: <ProjectItemWholeSelectionCheckbox items={items} />,
      },
      {
        wrapper: ItemContainer,
        item: ({ item, count }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <ProjectItemMaterialEditableView
              estimatedItem={estimatedItem}
              item={item}
              count={count}
              costCode={estimatedItem?.costCode}
              manufacturer={
                item.estimatedItems.length < 2
                  ? estimatedItem?.manufacturer
                  : undefined
              }
              projectId={project?.id}
            />
          );
        },
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={project?.id}
          />
        ),
        header: <FormattedMessage id="PROJECT_ITEM_ESTIMATED_ITEMS" />,
        details: ({ item, index }) => (
          <ProjectItemMaterialView
            item={item}
            includeCounter={false}
            estimatedItem={getEstimatedItem({ item, index })}
            manufacturer={
              getEstimatedItem({ item, index })?.manufacturer ||
              item.manufacturer
            }
          />
        ),
        includesCounter: true,
      },
      {
        header: <FormattedMessage id="MANUFACTURER" />,
        wrapper: ManufacturerContainer,
        item: ({ item, error, index, editMode }) => {
          return (
            <IncludeGroupedItems item={item}>
              <ProjectItemManufacturerSelector
                error={error && !item.manufacturer}
                item={item}
                estimatedItem={getEstimatedItem({ item })}
                index={index}
                readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
              />
            </IncludeGroupedItems>
          );
        },
        details: ({ item, index, error, editMode }) => (
          <ProjectItemManufacturerSelector
            error={error && !item.manufacturer}
            item={item}
            estimatedItem={getEstimatedItem({ item, index })}
            index={index}
            readonly={!editMode}
          />
        ),
        position: "center",
        hidden: !hasManufacturersSetting,
      },
      {
        wrapper: TagContainer,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />,
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <IncludeGroupedItems item={item}>
              <ProjectItemTagsPicker
                estimatedItem={estimatedItem}
                readonly={readonly}
              />
            </IncludeGroupedItems>
          );
        },
        details: ({ item, index }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          return (
            <ProjectItemTagsPicker
              estimatedItem={estimatedItem}
              readonly={readonly}
            />
          );
        },
      },
      {
        wrapper: InputContainer,
        item: ({ item, editMode }) => {
          const estimatedItem = getEstimatedItem({
            item,
            sumIfMultiple: true,
          });
          return (
            <ProjectItemUnitsInput
              item={item}
              estimatedItem={estimatedItem}
              readonly={
                !(editMode || newProjectEstimatedItem.id === item.id) ||
                item.estimatedItems.length > 1
              }
            />
          );
        },
        subgroup: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
        header: <FormattedMessage id="ESTIMATED_QUANTITY_SHORT" />,
        details: ({ item, index, editMode }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          return (
            <ProjectItemUnitsInput
              item={item}
              estimatedItem={estimatedItem}
              readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
            />
          );
        },
      },
      {
        wrapper: InputContainer,
        item: ({ item, editMode }) => {
          const estimatedItem =
            (getEstimatedItem({
              item,
            }) as EstimatedItemExtendedFieldsFragment) ?? {};

          return (
            <ProjectItemUnitPrice
              item={item}
              estimatedItem={estimatedItem}
              readonly={
                !(editMode || newProjectEstimatedItem.id === item.id) ||
                item.estimatedItems.length > 1
              }
            />
          );
        },
        details: ({ item, index, editMode }) => (
          <ProjectItemUnitPrice
            item={item}
            estimatedItem={getEstimatedItem({ item, index })}
            readonly={!editMode}
          />
        ),
        header: <FormattedMessage id="ESTIMATED_UNIT_PRICE" />,
      },
      {
        wrapper: InputContainer,
        item: ({ item }) => {
          const estimatedItem =
            (getEstimatedItem({
              item,
            }) as EstimatedItemExtendedFieldsFragment) ?? {};

          return (
            <ProjectItemExtendedUnitPrice
              item={item}
              estimatedItem={estimatedItem}
            />
          );
        },
        details: ({ item, index }) => (
          <ProjectItemExtendedUnitPrice
            estimatedItem={getEstimatedItem({ item, index })}
            item={item}
            index={index}
          />
        ),
        header: <FormattedMessage id="EXT_UNIT_PRICE" />,
      },
      {
        wrapper: InputContainer,
        item: ({ item, index, editMode }) => {
          return (
            <ProjectItemItemUomPicker
              item={item}
              index={index}
              readonly={
                !(editMode || newProjectEstimatedItem.id === item.id) ||
                item.estimatedItems.length > 1
              }
            />
          );
        },
        header: <FormattedMessage id="UOM" />,
        details: ({ item, index, editMode }) => {
          return (
            <ProjectItemItemUomPicker
              index={index}
              item={item}
              readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
            />
          );
        },
      },
      {
        wrapper: InputContainer,
        item: ({ item, editMode }) => {
          return (
            <ProjectItemCostCodePicker
              item={item}
              estimatedItem={getEstimatedItem({ item })}
              readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
            />
          );
        },
        header: <FormattedMessage id="COST_CODE" />,
        details: ({ item, editMode, index }) => {
          return (
            <ProjectItemCostCodePicker
              item={item}
              estimatedItem={getEstimatedItem({ item, index })}
              readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
            />
          );
        },
      },
      {
        wrapper: InputContainer,
        item: ({ item, editMode }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <IncludeGroupedItems item={item}>
              <ProjectItemZonePicker
                estimatedItem={estimatedItem}
                readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
              />
            </IncludeGroupedItems>
          );
        },
        hidden: !includeZones,
        header: <FormattedMessage id="ZONE" />,
        details: ({ item, index, editMode }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          return (
            <ProjectItemZonePicker
              estimatedItem={estimatedItem}
              readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
            />
          );
        },
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item, editMode, setEditMode }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <If isTrue={!readonly}>
              <ProjectItemActions
                estimatedItem={estimatedItem}
                item={item as ProjectItemExtendedFieldsFragment}
                readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
                setEditMode={setEditMode}
                canRemove={item.estimatedItems.length === 1}
              />
            </If>
          );
        },
        details: ({ item, index, editMode, setEditMode }) => {
          const estimatedItem = getEstimatedItem({ item, index });
          return (
            <If isTrue={!readonly}>
              <ProjectItemActions
                estimatedItem={estimatedItem}
                item={item as ProjectItemExtendedFieldsFragment}
                readonly={!(editMode || newProjectEstimatedItem.id === item.id)}
                setEditMode={setEditMode}
                canRemove
              />
            </If>
          );
        },
      },
    ],
    [
      hasManufacturersSetting,
      includeZones,
      items,
      newProjectEstimatedItem.id,
      project?.id,
      readonly,
      hasPhaseCodes,
    ],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "material",
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "TAG" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.Tag,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "estimateUom.pluralDescription",
        options: uoms.filter((o) => o.mnemonic).map((o) => o.pluralDescription),
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "ESTIMATED_QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.PositiveQuantity,
      },
      {
        header: intl.$t({ id: "ESTIMATED_UNIT_PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.PrefilledPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "code.name",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        columnType: COLUMN_TYPE.Zone,
      },
    ],
    [intl, uoms],
  );

  return { configuration, spreadsheetConfig };
};
