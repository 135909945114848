import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import {
  ProjectReportReleasesFieldsFragment,
  ProjectReportVendorFieldsFragment,
  useProjectReportReleasesQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useMemo, useState } from "react";
import { useProject } from "./ProjectProvider";
import {
  ProjectReportType,
  useSpendingReport,
} from "./ProjectSpendingReportProvider";

type ProviderContextType = {
  item: ProjectReportReleasesFieldsFragment | undefined;
  zoneId?: string | undefined;
  costCodeId?: string | undefined;
  ordered: string | undefined | null;
  orderedTotal: string | undefined | null;
  invoiced: string | undefined | null;
  invoicedTotal: string | undefined | null;
  loading: boolean;
  searchQuery: string | undefined;
  setSearchQuery: (query: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  item: undefined,
  zoneId: undefined,
  costCodeId: undefined,
  ordered: undefined,
  orderedTotal: undefined,
  invoiced: undefined,
  invoicedTotal: undefined,
  loading: false,
  searchQuery: "",
  setSearchQuery: NoFunction,
});

export const ProjectOrderedQuantityProvider: FC<{
  children: React.ReactNode;
  item: Pick<
    ProjectReportType,
    "zoneId" | "costCodeId" | "ordered" | "invoiced"
  > & {
    vendor?: ProjectReportVendorFieldsFragment["vendor"];
    orderedTotal?: string | null;
    invoicedTotal?: string | null;
    id?: string | null;
  };
}> = ({ children, item }) => {
  const { groupedByZones } = useSpendingReport();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { project } = useProject();

  const zoneId = useMemo(
    () =>
      groupedByZones && item.zoneId !== UNSPECIFIED_COST_CODE_ID
        ? item.zoneId
        : undefined,
    [groupedByZones, item.zoneId],
  );

  const costCodeId = useMemo(
    () =>
      item.costCodeId !== UNSPECIFIED_COST_CODE_ID
        ? item.costCodeId
        : undefined,
    [item.costCodeId],
  );

  const { data, error, loading } = useProjectReportReleasesQuery({
    variables: {
      input: {
        projectId: project?.id || "",
        costCodeId,
        orgMaterialId: item.id ?? undefined,
        zoneId,
        sellerOrgId:
          (item as ProjectReportVendorFieldsFragment)?.vendor?.id ?? undefined,
      },
    },
  });

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        item: data?.projectReportReleases,
        zoneId,
        costCodeId,
        ordered: item.ordered,
        orderedTotal: item.orderedTotal,
        invoiced: item.invoiced,
        invoicedTotal: item.invoicedTotal,
        loading,
        searchQuery,
        setSearchQuery,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useProjectOrderedQuantity = (): ProviderContextType =>
  useContext(ProviderContext);
