import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useInstruction } from "@/common/hooks/useInsruction";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectPhaseCodes } from "@/contractor/pages/home/project/hooks/useProjectPhaseCodes";
import { DashedDivider } from "@/contractor/pages/home/receipts/pages/receipt-record-order/components/order/itemized/DashedDivider";
import { ReleaseDescription } from "@/contractor/pages/home/release/components/ReleaseDescription";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";

const Container = tw.div`flex flex-col h-full pb-7`;
const InnerContainer = tw.div`flex flex-row items-end justify-end`;
const Item = tw.div`flex flex-col items-center justify-end gap-4 mt-10`;
const InputContainer = tw.div`w-full pr-5`;

type Props = {
  form: UseFormReturn<CreateOrderFromQuoteFormValues, unknown, undefined>;
};

export const CreateOrderFromQuoteNonItemizedView: FC<Props> = ({
  form: { watch, setValue },
}) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { hasPhaseCodes } = useOrgSettings();
  const { phaseCodes } = useProjectPhaseCodes();
  const { saveDescription, saveInstruction } = useInstruction();

  const netAmount = watch("netAmount");
  const customTaxAmount = watch("customTaxAmount");
  const taxRate = watch("taxRate");
  const projectId = watch("projectId");
  const orderTypeId = watch("orderTypeId");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const chargesAmount = watch("additionalCharges");
  const taxVariance = watch("taxVariance");

  const { costCodes, loading } = useProjectCostCodes();
  const { getTaxAmount } = useTaxCalculation();
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, netAmount);

  const { calcTotalPrice, calcAdditionalChargesPrice } = usePriceCalculation();
  const total = useMemo(
    () =>
      calcTotalPrice({
        netAmount,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
        taxVariance,
      }),
    [
      calcTotalPrice,
      netAmount,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      taxVariance,
    ],
  );

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || "0");
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }
      if (values.timeTBD !== undefined && values.timeTBD !== null) {
        setValue("timeTBD", values.timeTBD);
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      netAmount,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(chargesAmount),
      taxVariance,
    }),
    [
      calcAdditionalChargesPrice,
      chargesAmount,
      customTaxAmount,
      orderTypeId,
      netAmount,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
      taxVariance,
    ],
  );

  return (
    <Container>
      <InnerContainer>
        <Item>
          <InputContainer>
            <If isTrue={!hasPhaseCodes}>
              <SelectControlled
                name="costCodeId"
                options={costCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.formatted}
                placeholder={intl.$t({ id: "COST_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_COST_CODE" })}
                disableClearable={false}
              />
            </If>
            <If isTrue={hasPhaseCodes}>
              <SelectControlled
                name="phaseCodeId"
                options={phaseCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.name}
                placeholder={intl.$t({ id: "PHASE_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_PHASE_CODE" })}
                disableClearable={false}
              />
            </If>
          </InputContainer>
          <InputContainer>
            <NumericalInputControlled
              name="netAmount"
              label={intl.$t({ id: "SUBTOTAL" })}
              includeCurrency
              inputProps={{ className: "bg-white text-center" }}
            />
          </InputContainer>
          <ReleaseAdditionalChargesAndTaxes
            classes={{
              tax: "grid-cols-[auto_164px] mr-5",
            }}
            total={`${total}`}
            releaseInput={releaseInput}
            editableByContractor
            includeSubtotal={false}
            includeAdditionalCharges={false}
            editableAdditionalCharges={false}
            updateRelease={(values) =>
              handleUpdateRelease({ ...values, version: -1 })
            }
            hideTotalBorder
            totalDivider={<DashedDivider />}
          />
        </Item>
      </InnerContainer>
      <Instructions
        projectId={projectId}
        instruction={release?.instructions}
        saveInstruction={saveInstruction}
      >
        <ReleaseDescription
          release={release}
          directlyEditable
          saveDescriptionCustomMethod={saveDescription}
        />
      </Instructions>
    </Container>
  );
};
