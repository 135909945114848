import { Switch } from "@/common/components/switch/Switch";
import {
  PaymentMethodFieldsFragment,
  PaymentMethodsDocument,
  PaymentMethodsQuery,
  useUpdatePaymentMethodsMutation,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";

type Props = {
  item: PaymentMethodFieldsFragment;
};

export const PaymentMethodDefaultSwitch: FC<Props> = ({ item }) => {
  const intl = useIntl();
  const [updatePaymentMethodsMutation] = useUpdatePaymentMethodsMutation();

  const onChangePaymentMethodDefault = useCallback(
    async (isDefault: boolean) => {
      await updatePaymentMethodsMutation({
        variables: {
          input: {
            updated: [
              {
                id: item.id,
                default: isDefault,
              },
            ],
          },
        },
        update: (cache) => {
          const queryPaymentMethods = cache.readQuery<PaymentMethodsQuery>({
            query: PaymentMethodsDocument,
          });
          cache.writeQuery({
            query: PaymentMethodsDocument,
            data: {
              viewer: {
                ...queryPaymentMethods?.viewer,
                org: {
                  ...queryPaymentMethods?.viewer?.org,
                  equipmentItems:
                    queryPaymentMethods?.viewer?.org.paymentMethods.map(
                      (paymentMethod) =>
                        paymentMethod.id === item.id
                          ? {
                              ...item,
                              default: isDefault,
                            }
                          : item,
                    ),
                },
              },
            },
          });
        },
      });
    },
    [item, updatePaymentMethodsMutation],
  );

  return (
    <Switch
      value={item.default}
      onChange={onChangePaymentMethodDefault}
      offLabel={intl.$t({ id: "NO" })}
      onLabel={intl.$t({ id: "YES" })}
    />
  );
};
