import { useReceipt } from "@/contractor/pages/home/receipts/pages/receipt-record-order/providers/ReceiptProvider";
import {
  EquipmentItemFieldsFragment,
  ReceiptFieldsFragment,
  ServiceCodeFieldsFragment,
} from "@/generated/graphql";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";
import { CheckIcon, SaveStatusPanel } from "./InvoiceDocumentOptions";
import { EquipmentItemServiceCodeSelectors } from "./components/EquipmentItemServiceCodeSelectors";

const Container = tw.div`flex flex-col rounded-3xl bg-blue-450 px-6 py-3 rounded-tl-none`;

type ReceiptDocumentOptionsProps = {
  receipt: ReceiptFieldsFragment | null;
  readonly?: boolean;
  equipmentItems: EquipmentItemFieldsFragment[];
  loadingEquipmentItems: boolean;
  serviceCodes: ServiceCodeFieldsFragment[];
  loadingServiceCodes: boolean;
};

export const ReceiptDocumentOptions = ({
  receipt,
  readonly = false,
  equipmentItems,
  loadingEquipmentItems,
  serviceCodes,
  loadingServiceCodes,
}: ReceiptDocumentOptionsProps) => {
  const { updateReceipt } = useReceipt();

  const [updatesCount, setUpdatesCount] = useState(0);

  return (
    <Container>
      <EquipmentItemServiceCodeSelectors
        invoice={receipt}
        readonly={readonly}
        setUpdatesCount={setUpdatesCount}
        updateInvoice={updateReceipt}
        equipmentItems={equipmentItems}
        loadingEquipmentItems={loadingEquipmentItems}
        serviceCodes={serviceCodes}
        loadingServiceCodes={loadingServiceCodes}
      />
      <If isTrue={updatesCount}>
        <SaveStatusPanel>
          <CheckIcon />
          <FormattedMessage id="CHANGES_SAVED" />
        </SaveStatusPanel>
      </If>
    </Container>
  );
};
