import { defaultDataIdFromObject, InMemoryCache } from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

const mergeMessages = (
  existingMessages: Record<string, unknown>,
  newMessages: Record<string, unknown>,
  readField: ReadFieldFunction,
  after: boolean,
) => {
  if (!existingMessages) {
    return newMessages;
  }

  const nonDuplicatedNewMessages = (
    newMessages.edges as Record<string, Record<string, string>>[]
  ).filter((edge) => {
    return !(
      existingMessages.edges as Record<string, Record<string, string>>[]
    ).find(
      (existingEdge) =>
        readField("id", existingEdge.node) === readField("id", edge.node),
    );
  });

  const updatedEdges = after
    ? [
        ...(existingMessages.edges as Record<string, string>[]),
        ...nonDuplicatedNewMessages,
      ]
    : [
        ...nonDuplicatedNewMessages,
        ...(existingMessages.edges as Record<string, string>[]),
      ];

  return {
    ...existingMessages,
    ...{
      edges: updatedEdges,
    },
    ...{ pageInfo: newMessages.pageInfo },
  };
};

export const createApolloCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          messages: {
            keyArgs: ["input", ["context", ["kind", "id"], "isPrivate"]],
            merge(existingMessages, newMessages, { readField, args }) {
              return mergeMessages(
                existingMessages,
                newMessages,
                readField,
                !!args?.after,
              );
            },
          },
        },
      },
      Release: {
        fields: {
          permissions: {
            merge(existing, incoming) {
              if (existing) {
                return { ...existing, ...(incoming || {}) };
              }
              return incoming;
            },
          },
          items: {
            merge(existing, incoming) {
              if (incoming) {
                return incoming;
              }
              return existing;
            },
          },
        },
      },
      OrgLocation: {
        fields: {
          address: {
            merge(existing, incoming) {
              if (existing) {
                return { ...existing, ...(incoming || {}) };
              }
              return incoming;
            },
          },
        },
      },
      Organization: {
        fields: {
          settings: {
            merge(existing, incoming) {
              if (existing) {
                return { ...existing, ...(incoming || {}) };
              }
              return incoming;
            },
          },
        },
      },
      Invoice: {
        fields: {
          permissions: {
            merge(existing, incoming) {
              if (existing) {
                return { ...existing, ...(incoming || {}) };
              }
              return incoming;
            },
          },
        },
      },
    },
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case "Facet":
          return `Facet:${responseObject.id}:${(
            (responseObject.values as { count: number }[]) || []
          )
            .map((value) => value.count)
            .join(",")}`;
        case "FacetValue": {
          const obj = responseObject as { name: string; count: number };
          return `${obj.name}:${obj.count}`;
        }
        case "ReleaseApprovalSettings": {
          const obj = responseObject as { enabled: boolean };
          return `${obj.enabled}`;
        }
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  });
};
