import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { routes } from "@/config/routes";
import {
  AuthorizationStatus,
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { getUserName } from "../../../../../../../common/utils/users/getUserName";
import { useAddDeliverySlipStore } from "../../delivery-slips/store/useAddDeliverySlipStore";

const RejectedText = tw.div`
  text-xs font-light text-center
`;

const ButtonsContainerStyled = tw(ButtonsContainer)`
  w-full justify-center
`;

export const DeliveryActionButton: FC<{
  delivery: ReleasePartialFieldsFragment;
}> = ({ delivery }) => {
  const navigate = useNavigate();
  const { orderTypeConfig } = useOrderTypesConfig({
    release: delivery,
  });
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();

  return (
    <ButtonsContainerStyled>
      <If
        isTrue={
          delivery.status === ReleaseStatus.Scheduled ||
          delivery.status === ReleaseStatus.Requested ||
          delivery.status === ReleaseStatus.PartiallyReceived
        }
      >
        <PrimaryButton
          onClick={() => {
            setSlipRelease(delivery);
            setAddSlipVisible(true);
          }}
          testId="receive-release"
          className="min-w-20"
        >
          {orderTypeConfig?.labels.buttons.completeShort}
        </PrimaryButton>
      </If>
      <If
        isTrue={
          delivery.status === ReleaseStatus.AwaitingApproval &&
          delivery.approvalProgress.canApprove
        }
      >
        <PrimaryButton
          onClick={() => {
            if (
              delivery.permissions.approve === AuthorizationStatus.Authorized
            ) {
              navigate(
                generatePath(routes.specifyDeliveryDetails, {
                  deliveryId: delivery.id,
                }),
              );
            }
          }}
          testId="receive-release"
        >
          <FormattedMessage id="REVIEW" />
        </PrimaryButton>
      </If>
      <If isTrue={delivery.status === ReleaseStatus.Rejected}>
        <FormattedMessage
          id="REJECTED_BY_USER"
          values={{
            b: (...chunks) => (
              <div>
                <strong>{chunks}</strong>
              </div>
            ),
            user: getUserName(delivery.rejectedBy),
          }}
          tagName={RejectedText}
        />
      </If>
    </ButtonsContainerStyled>
  );
};
