import { IMAGE_EXTENSIONS } from "@/common/components/upload/FileUploadArea";
import { AssetType } from "@/generated/graphql";
import { getFileExtension } from "./getFileExtension";

// This represents a type of single element in IMAGE_EXTENSIONS
type ImageExtension = (typeof IMAGE_EXTENSIONS)[number];

export const getAssetType = (filename: string): AssetType => {
  const extension = getFileExtension(filename);
  if (IMAGE_EXTENSIONS.includes(extension.toLowerCase() as ImageExtension)) {
    return AssetType.Image;
  }

  return AssetType.Document;
};
