import { useDialog } from "@/common/components/dialog/DialogProvider";
import { INTEGRATION_TYPE_TRANSLATIONS } from "@/common/components/estimation-software-selector/EstimationSoftwareSelector";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useTabActive } from "@/common/hooks/useTabActive";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ExportReleaseProvider,
  useExportRelease,
} from "@/contractor/pages/home/release/components/connections/providers/ExportReleaseProvider";
import {
  BatchType,
  IntegrationType,
  PoFormat,
  ReleaseSummaryFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ExportCompleted,
  ExportType,
} from "../../../../../../../../common/components/export-completed/ExportCompleted";
import { SourceSystemWrapper } from "../../../../../../../../common/components/source-system-wrapper/SourceSystemWrapper";
import { ExportBatchProvider } from "../../../../../../../../common/providers/ExportBatchProvider";
import { useIsValidFoundationReleaseExport } from "../../hooks/isValidFoundationReleaseExport";
import { useIsValidSage300ReleaseExport } from "../../hooks/isValidSage300ReleaseExport";
import { useDefaultReleasePo } from "../../hooks/useDefaultReleasePo";
import {
  ReleaseConnectionOptionsProvider,
  useReleaseConnectionOptions,
} from "../../providers/ReleaseConnectionOptionsProvider";
import {
  ReleasesByIdsProvider,
  useReleasesByIds,
} from "../../providers/ReleasesByIdsProvider";
import { ReleaseConnectionOptions } from "../common/ReleaseConnectionOptions";
import { FoundationReleaseExport } from "../connection-types/foundation/FoundationReleaseExport";

const CardList = tw.div`flex flex-col gap-3`;

type Props = {
  onClose: () => void;
  releaseIds: string[];
  titleId?: string;
};

const ExportMultiplePOsPanelWithProvider: FC<Props> = ({
  onClose,
  titleId = "EXPORT_ORDERS",
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { settings, refetchSettings, connectedIntegrationType } =
    useOrgSettings();
  const { releasesByIds, loading, refetchReleasesByIds } = useReleasesByIds();
  const { exportReleases } = useExportRelease();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { updatedReleases } = useReleaseConnectionOptions();
  const { isTabActive } = useTabActive();
  const { getDefaultReleasePo } = useDefaultReleasePo();

  useEffect(() => {
    if (isTabActive && !loading) {
      refetchReleasesByIds();

      refetchSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  const { isValidFoundationReleaseExport } =
    useIsValidFoundationReleaseExport();
  const { isValidSage300ReleaseExport } = useIsValidSage300ReleaseExport();

  const isValid = useCallback(
    (release: ReleaseSummaryFieldsFragment) => {
      switch (connectedIntegrationType) {
        case IntegrationType.Foundation:
          return isValidFoundationReleaseExport({
            release,
            updatedReleases,
            poFormatBasic: release.type.poFormat === PoFormat.Basic,
          });
        case IntegrationType.Sage300:
          return isValidSage300ReleaseExport({
            release,
            updatedReleases,
          });
        default:
          return false;
      }
    },
    [
      connectedIntegrationType,
      isValidFoundationReleaseExport,
      isValidSage300ReleaseExport,
      updatedReleases,
    ],
  );

  const deliveriesToExportCount = useMemo(
    () => releasesByIds.filter(isValid).length,
    [isValid, releasesByIds],
  );

  const onSave = useCallback(async () => {
    if (!connectedIntegrationType) {
      return;
    }
    const connectedAccountingSystem = settings?.integrations.accounting.find(
      (a) => a.enabled,
    );
    if (
      await exportReleases({
        integration: connectedIntegrationType,
        releases: releasesByIds.filter(isValid).map((d) => ({
          releaseID: d.id,
          poNumber: includePoNumbering
            ? updatedReleases.find((po) => po.releaseId === d.id)?.poNumber ||
              d.poNumber ||
              getDefaultReleasePo(d)
            : undefined,
          taxAuthorityCode:
            d.customTaxAmount === null
              ? connectedAccountingSystem?.taxAuthorities.find(
                  (t) => t.rate === d?.taxRate,
                )?.code
              : undefined,
          vendorExternalCode: updatedReleases.find(
            (po) => po.releaseId === d.id,
          )?.externalVendorCode,
        })),
        GLExpense: connectedAccountingSystem?.toJobLedgerAccount,
        chargesCostCodeID:
          connectedAccountingSystem?.additionalChargesCostCode?.id,
        // for now agreed to hardcode it to false and later it will be entirely dropped and the backend will handle it taking it from the release type
        aggregatedByCostCode: false,
      })
    ) {
      openDialog({
        content: (
          <ExportCompleted
            count={deliveriesToExportCount}
            type={ExportType.ORDER}
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        hideTitle: true,
      });
      onClose();
    }
  }, [
    connectedIntegrationType,
    settings?.integrations.accounting,
    exportReleases,
    releasesByIds,
    isValid,
    includePoNumbering,
    updatedReleases,
    getDefaultReleasePo,
    openDialog,
    deliveriesToExportCount,
    onClose,
  ]);

  return (
    <OverlayPanel
      title={`${intl.$t({ id: titleId })} (${
        settings
          ? intl.$t({
              id: INTEGRATION_TYPE_TRANSLATIONS[
                settings?.integrations.accounting.find((a) => a.enabled)
                  ?.integration as IntegrationType
              ],
            })
          : ""
      })`}
      isLoading={loading && releasesByIds.length === 0}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t(
        {
          id:
            !deliveriesToExportCount || releasesByIds.length > 1
              ? "EXPORT_WITH_NUMBER"
              : "EXPORT",
        },
        { number: deliveriesToExportCount },
      )}
      disableSave={deliveriesToExportCount === 0}
    >
      <CardList>
        <SourceSystemWrapper
          anySourceSystem
          allowedIntegrationTypes={[IntegrationType.Foundation]}
        >
          <ReleaseConnectionOptions />
        </SourceSystemWrapper>
        <FoundationReleaseExport releases={releasesByIds} />
      </CardList>
    </OverlayPanel>
  );
};

export const ExportMultipleReleasePOsPanel: FC<Props> = (props) => (
  <NestedStepperProvider>
    <ReleasesByIdsProvider releasesIds={props.releaseIds}>
      <ExportBatchProvider type={BatchType.PurchaseOrder}>
        <ReleaseConnectionOptionsProvider type="onPremise">
          <ExportReleaseProvider>
            <ExportMultiplePOsPanelWithProvider {...props} />
          </ExportReleaseProvider>
        </ReleaseConnectionOptionsProvider>
      </ExportBatchProvider>
    </ReleasesByIdsProvider>
  </NestedStepperProvider>
);
