export const isEmptyString = (str: string | null): boolean => {
  return !str || str.length === 0 || /^\s*$/.test(str);
};

export const isValidUSPostalCode = (str: string | null): boolean => {
  return !str || str.length === 0 || /^\d{5}(-\d{4})?$/.test(str);
};

export const isValidCellPhone = (str: string | null): boolean => {
  return !str || str.length === 0 || /^\d{11}$/.test(str);
};

export const isValidEmail = (str: string | null): boolean => {
  return !str || str.length === 0 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
};
