import { formattedDate } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { ReceiptSequenceData } from "@/contractor/pages/home/receipts/pages/receipts/providers/ReceiptsSequenceProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";

export const useExportToCsvReceiptData = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();

  const getReceiptExportToCsvData = useCallback(
    (receipts: ReceiptSequenceData[]) => {
      const headers = [
        intl.$t({ id: "SCAN_DATE" }),
        intl.$t({ id: "SUBMITTED_BY" }),
        intl.$t({ id: "TRANSACTION_DATE" }),
        intl.$t({ id: "PROJECT" }),
        intl.$t({ id: "VENDOR" }),
        intl.$t({ id: "ORDER" }),
        intl.$t({ id: "PO_HASH" }),
        intl.$t({ id: "TYPE" }),
        intl.$t({ id: "RECEIPT_TOTAL" }),
        intl.$t({ id: "STATUS" }),
      ];
      const currentDate = formattedDate({ date: new Date() });
      const fileName = `${intl.$t({ id: "RECEIPTS" })} - ${currentDate}`;
      const data = receipts.map((receipt) => [
        receipt.createdAt ? formattedDate({ date: receipt.createdAt }) : "",
        getUserName(receipt.createdBy),
        receipt.issueDate ? formattedDate({ date: receipt.issueDate }) : "",
        receipt.release?.project?.jobNumber,
        receipt.release?.sellerOrgLocation?.org?.name,
        receipt.release?.sequenceNumber
          ? intl.$t(
              { id: "ORDER_WITH_NUMBER" },
              { orderNumber: receipt.release?.sequenceNumber },
            )
          : "",
        receipt.release?.poNumber,
        receipt.release?.type.name,
        receipt.total ? formatCurrency(receipt.total) : "",
        intl.$t({ id: `RECEIPT_STATUS_${receipt.status}` }),
      ]);

      return { data, fileName, headers };
    },
    [intl, formatCurrency],
  );

  return { getReceiptExportToCsvData };
};
