import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useImportEquipmentItemsStore } from "../stores/useImportEquipmentItemsStore";

export const ImportExternalEquipmentItemsButton = () => {
  const intl = useIntl();
  const { settings } = useOrgSettings();
  const { openModal } = useImportEquipmentItemsStore();

  const connectedSourceSystem = useMemo(
    () => (settings?.integrations.sourceSystems ?? []).find((s) => s.connected),
    [settings],
  );

  if (!connectedSourceSystem) {
    return null;
  }

  return (
    <OutlinedButton
      onClick={() => openModal({ sourceSystem: connectedSourceSystem.system })}
    >
      <FormattedMessage
        id="IMPORT_FROM_SYSTEM"
        values={{
          system: intl.$t({
            id: `INTEGRATION_${connectedSourceSystem.system}`,
          }),
        }}
      />
    </OutlinedButton>
  );
};
