import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import {
  ArrowBack,
  ArrowForward,
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { useScrollOnDrag } from "@/common/hooks/useScrollOnDrag";
import tw from "tailwind-styled-components";
import { Loader } from "../loader/Loader";

const Container = tw.div``;
const Footer = tw.div``;
const IconButtonBorderlessStyled = tw(
  IconButtonBorderless,
)`disabled:bg-transparent`;
const DocumentContainer = tw.div`overflow-auto cursor-move`;
const Actions = tw.div`flex flex-col gap-3 bg-black absolute right-3 top-5 p-3 rounded-xl z-70 opacity-0 group-hover:opacity-70`;
const PageStyled = tw(Page)<{ scale: number }>`
  flex max-h-[100vh] justify-center
  ${({ scale }) => scale <= 0.9 && "!bg-gray-100"}
`;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};
type Props = {
  url: string;
  page: number;
  pageWidth?: number;
  includeFooter?: boolean;
  containerClassName?: string;
};

export const PdfViewer = memo(
  ({
    url,
    includeFooter,
    page = 1,
    pageWidth,
    containerClassName = "h-full w-full relative",
  }: Props) => {
    const [pageNumber, setPageNumber] = useState(page);
    const [numPages, setNumPages] = useState<number | null>(null);
    const onLoadSuccess = useCallback(
      async (document: pdfjs.PDFDocumentProxy) => {
        try {
          await document.getPage(1).then((page) => {
            const viewport = page.getViewport({ scale: 1 });
            const pdfWidth = viewport.width;
            const containerWidth = containerRef.current?.clientWidth || 0;
            if (pdfWidth > containerWidth) {
              const newScale = containerWidth / pdfWidth;
              setScale(newScale);
            }
          });
        } catch (er) {
          console.error("Failed to get first page of the document", er);
        }
        setNumPages(document?.numPages);
      },
      [],
    );
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const { handleMouseDown, handleMouseMove, onDragEnd } =
      useScrollOnDrag(containerRef);

    useEffect(() => {
      if (page) {
        setPageNumber(page);
      }
    }, [page]);

    useEffect(() => {
      if (url) {
        setRotate(0);
        setScale(1);
      }
    }, [url]);

    const onLoadError = useCallback((error: Error) => console.error(error), []);

    const onNext = useCallback(() => {
      setPageNumber((prev) => prev + 1);
    }, []);

    const onPrevious = useCallback(() => {
      setPageNumber((prev) => prev - 1);
    }, []);

    const zoomIn = useCallback(() => {
      setScale((prev) => prev + 0.1);
    }, []);

    const zoomOut = useCallback(() => {
      setScale((prev) => prev - 0.1);
    }, []);

    return (
      <Container className={containerClassName}>
        <DocumentContainer
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={onDragEnd}
          onMouseLeave={onDragEnd}
        >
          <Document
            file={url}
            onLoadSuccess={onLoadSuccess}
            onLoadError={onLoadError}
            options={options}
            className="h-full"
            loading={<Loader loading />}
          >
            <PageStyled
              pageNumber={pageNumber}
              width={pageWidth}
              scale={scale}
              rotate={rotate === 0 ? null : rotate}
            />
          </Document>
        </DocumentContainer>
        <If isTrue={numPages}>
          <Actions>
            <ZoomIn className="text-white" onClick={zoomIn} />
            <ZoomOut className="text-white" onClick={zoomOut} />
            <RotateLeft
              className="text-white"
              onClick={() => setRotate(rotate - 90)}
            />
            <RotateRight
              className="text-white"
              onClick={() => setRotate(rotate + 90)}
            />
          </Actions>
        </If>
        <If isTrue={includeFooter}>
          <Footer>
            <FormattedMessage
              id="PAGE_OF_NUMBER"
              values={{ pageNumber, numPages }}
            />
            <IconButtonBorderlessStyled
              onClick={onPrevious}
              disabled={pageNumber === 1}
            >
              <ArrowBack />
            </IconButtonBorderlessStyled>
            <IconButtonBorderlessStyled
              onClick={onNext}
              disabled={pageNumber === numPages}
            >
              <ArrowForward />
            </IconButtonBorderlessStyled>
          </Footer>
        </If>
      </Container>
    );
  },
);
