import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ManufacturerFieldsFragment,
  useCreateManufacturerMutation,
  useCreateManufacturersMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { useStartupDataStore } from "../stores/useStartupDataStore";

export const useManufacturers = () => {
  const { setError } = useGlobalError();
  const [createManufacturerMutation, { loading: createManufacturerLoader }] =
    useCreateManufacturerMutation();
  const { manufacturers, updateStartupData } = useStartupDataStore(
    useShallow((state) => ({
      manufacturers: state.manufacturers,
      updateStartupData: state.updateStartupData,
    })),
  );

  const createManufacturer = useCallback(
    async (manufacturerName: string) => {
      try {
        const { data, errors } = await createManufacturerMutation({
          variables: {
            input: {
              name: manufacturerName,
            },
          },
        });
        setError(errors);
        updateStartupData({
          manufacturers: [
            ...manufacturers,
            data?.createManufacturer as ManufacturerFieldsFragment,
          ],
        });
        return data?.createManufacturer;
      } catch (error) {
        setError(error);
      }
    },
    [createManufacturerMutation, manufacturers, setError, updateStartupData],
  );

  const [createManufacturersMutation, { loading: createManufacturersLoader }] =
    useCreateManufacturersMutation();

  const createManufacturers = useCallback(
    async (manufacturerNames: string[]) => {
      try {
        const { data, errors } = await createManufacturersMutation({
          variables: {
            input: manufacturerNames.map((name) => ({
              name,
            })),
          },
        });
        setError(errors);
        updateStartupData({
          manufacturers: [
            ...manufacturers,
            ...(data?.createManufacturers as ManufacturerFieldsFragment[]),
          ],
        });
        return data?.createManufacturers;
      } catch (error) {
        setError(error);
      }
    },
    [createManufacturersMutation, manufacturers, setError, updateStartupData],
  );

  return {
    manufacturers,
    createManufacturer,
    createManufacturerLoader,
    createManufacturers,
    createManufacturersLoader,
  };
};
