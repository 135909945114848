import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import {
  LocalPaginationProvider,
  useLocalPagination,
} from "@/common/components/pagination-local/LocalPaginationProvider";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { useLocalSearch } from "@/common/hooks/useLocalSearch";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useOrgSettings } from "../../../org-settings/hooks/useOrgSettings";
import { LoaderStyled } from "../common/CostStructure.styles";
import { AddCodeMenu } from "./components/AddCodeMenu";
import { CostCodeList } from "./components/CostCodeList";
import { useCostCodesConfiguration } from "./components/CostCodes.tableConfiguration";
import { CostCodesFooter } from "./components/CostCodesFooter";
import { ImportExternalCostCodesButton } from "./components/ImportExternalCostCodesButton";
import { ImportExternalCostCodesModal } from "./components/import-external-codes/ImportExternalCostCodesModal";
import {
  CostCodesListProvider,
  useCostCodesList,
} from "./providers/CostCodesListProvider";
import { ImportExternalCostCodesProvider } from "./providers/ImportExternalCostCodesProvider";
import { SyncCostCodesProvider } from "./providers/SyncCostCodesProvider";

const ITEMS_PER_PAGE = 24;

const HeaderContainerStyled = tw(HeaderContainer)`
  grid grid-flow-col gap-5 items-center justify-between lg:bg-gray-100 top-[57px] xl:top-[57px]
`;
const Spacer = tw.div`h-12`;
const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start
`;
const SpreadSheetContainer = tw.div`pt-12`;

const CostCodesWithProvider = () => {
  const intl = useIntl();
  const { costCodes, loading: loadingCostCodes } = useCostCodesList();
  const { spreadsheetConfig } = useCostCodesConfiguration();
  const viewState = useTableViewStore((state) => state.viewState);
  const { loading } = useOrgSettings();
  const { setSearchText, filteredItems, searchText } =
    useLocalSearch(costCodes);
  const { getPaginatedItems } = useLocalPagination();

  const currentCostCodes = useMemo(() => {
    const newItem = costCodes.find((c) => c.id === "");
    if (newItem && searchText) {
      return getPaginatedItems([newItem, ...filteredItems]);
    }
    return getPaginatedItems(filteredItems);
  }, [costCodes, filteredItems, getPaginatedItems, searchText]);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "COST_CODES" })}</title>
      </Helmet>
      <LoaderStyled loading={loading}>
        <If isTrue={viewState === TableViewState.normal}>
          <HeaderContainerStyled>
            <Filters>
              <SearchInput
                placeHolder={intl.$t({ id: "SEARCH_COST_CODE" })}
                onChange={setSearchText}
              />
            </Filters>
            <HeaderRightPanel>
              <ImportExternalCostCodesButton />
              <AddCodeMenu />
            </HeaderRightPanel>
          </HeaderContainerStyled>
          <CostCodeList
            items={currentCostCodes}
            totalCount={filteredItems.length}
            loading={loadingCostCodes}
          />
        </If>
        <If isTrue={viewState === TableViewState.spreadsheet}>
          <SpreadSheetContainer>
            <SpreadSheetTable
              items={costCodes}
              columns={spreadsheetConfig}
              height="calc(100vh - 320px)"
            />
          </SpreadSheetContainer>
        </If>
        <Spacer />
        <CostCodesFooter />
        <ImportExternalCostCodesModal />
      </LoaderStyled>
    </>
  );
};

export const CostCodes = () => {
  const { spreadsheetConfig } = useCostCodesConfiguration();
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.normal);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  return (
    <NestedStepperProvider>
      <CostCodesListProvider>
        <ImportExternalCostCodesProvider>
          <LocalPaginationProvider itemsPerPage={ITEMS_PER_PAGE}>
            <ColumnMapperProvider config={spreadsheetConfig}>
              <SyncCostCodesProvider>
                <CostCodesWithProvider />
              </SyncCostCodesProvider>
            </ColumnMapperProvider>
          </LocalPaginationProvider>
        </ImportExternalCostCodesProvider>
      </CostCodesListProvider>
    </NestedStepperProvider>
  );
};
