import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "../const";

type Type =
  | {
      uom?: { pluralDescription: string } | undefined | null;
      projectItem?: { estimateUom: { pluralDescription: string } } | null;
    }
  | null
  | undefined;

export const isLumpSumItem = (item: Type) => {
  const uom = item?.uom ?? item?.projectItem?.estimateUom;
  return (
    uom?.pluralDescription?.toLocaleLowerCase() ===
      LUMP_SUM_UOM_PLURAL_DESCRIPTION.toLocaleLowerCase() ||
    uom?.pluralDescription?.toLocaleLowerCase() ===
      LUMP_SUM_UOM.toLocaleLowerCase()
  );
};

export const isLumpSumUomText = (uomText: string | undefined | null) =>
  uomText &&
  typeof uomText === "string" &&
  (uomText?.toLocaleLowerCase() === LUMP_SUM_UOM.toLocaleLowerCase() ||
    uomText?.toLocaleLowerCase() ===
      LUMP_SUM_UOM_PLURAL_DESCRIPTION.toLocaleLowerCase());
