import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUnlinkInvoiceMutation } from "@/generated/graphql";

export const useUnlinkInvoice = () => {
  const [unlinkInvoiceMutation] = useUnlinkInvoiceMutation();
  const { setError } = useGlobalError();

  const unlinkInvoice = async (linkId?: string) => {
    if (linkId) {
      try {
        const { data, errors } = await unlinkInvoiceMutation({
          variables: {
            invoiceLinkId: linkId,
          },
        });

        setError(errors);
        return !!data?.unlinkInvoice;
      } catch (error) {
        setError(error);
        return false;
      }
    }
  };

  return { unlinkInvoice };
};
