import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { AuthorizationStatus } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";

const Container = tw.div`grid grid-flow-col gap-2 justify-between text-xs font-normal items-center px-4 my-2`;
const ImmutableInfo = tw.div`flex text-gray-600 items-center gap-2 mt-2`;
const InfoIcon = tw(InfoOutlined)`text-gray-500 w-6 h-6 `;
const LinkLikeStyled = tw(LinkLike)`text-xs`;

export const DeliverySlipReleaseEditInfo: FC = () => {
  const intl = useIntl();
  const {
    deliverySlip,
    packingSlipReceiveViewState,
    setPackingSlipReceiveViewState,
  } = useDeliverySlipVerification();

  return (
    <Container>
      <If
        isTrue={
          deliverySlip?.permissions.edit === AuthorizationStatus.Authorized &&
          !deliverySlip.archivedAt
        }
      >
        <Container className="px-0">
          {deliverySlip?.release?.poLink?.immutable ? (
            <ImmutableInfo>
              <InfoIcon />
              <FormattedMessage
                id="ORDER_IMMUTABLE_SHORT_DESCRIPTION"
                values={{
                  sourceSystem:
                    deliverySlip?.release?.poLink?.sourceSystem ??
                    intl.$t({ id: "SOURCE_SYSTEM" }),
                }}
              />
            </ImmutableInfo>
          ) : (
            <OutlinedButton
              $small
              onClick={() =>
                setPackingSlipReceiveViewState(
                  PackingSlipReceiveViewState.EDIT_ORDER,
                )
              }
            >
              <FormattedMessage
                id="EDIT_ORDER_WITH_NUMBER"
                values={{
                  orderNumber: deliverySlip?.release?.sequenceNumber,
                }}
              />
            </OutlinedButton>
          )}
          <If
            isTrue={
              packingSlipReceiveViewState !==
              PackingSlipReceiveViewState.EDIT_COVERAGE
            }
          >
            <OutlinedButton
              $small
              onClick={() =>
                setPackingSlipReceiveViewState(
                  PackingSlipReceiveViewState.EDIT_COVERAGE,
                )
              }
            >
              <FormattedMessage id="EDIT_RECEIVED_QUANTITIES" />
            </OutlinedButton>
          </If>
        </Container>
      </If>
      <If
        isTrue={
          deliverySlip?.permissions.edit === AuthorizationStatus.SelfAssignable
        }
      >
        <Container>
          <InfoIcon />
          <FormattedMessage id="RELEASE_SELF_ASSIGNED_EDIT_INFO" />
          <LinkLikeStyled
            to={generatePath(routes.projectDetails, {
              id: deliverySlip?.release?.project?.id ?? "",
            })}
          >
            {deliverySlip?.release?.project?.name}
          </LinkLikeStyled>
        </Container>
      </If>
    </Container>
  );
};
