import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { DateView } from "@/common/utils/dates/DateView";
import { ReleaseSelectorFieldsFragment } from "@/generated/graphql";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

interface SelectedReleaseCustomViewProps {
  selectedRelease: ReleaseSelectorFieldsFragment;
}

const Container = tw.div`absolute inset-0 flex items-center justify-between pl-4 right-10 overflow-hidden`;
const LeftInfo = tw.div`flex items-center`;
const RightInfo = tw.div`flex flex-col items-end text-xs text-gray-600`;
const ReleaseStatus = tw.div`rounded border px-1 text-xs text-gray-600`;
const ReleaseNumber = tw.span`text-xs leading-tight 2xl:leading-normal 2xl:text-sm font-medium 2xl:font-normal`;
const ReleaseTotalAmount = tw.span``;
const PONumber = tw.span`text-blue-500 text-xs ml-[2px] leading-tight 2xl:leading-normal`;
const NumbersContainer = tw.div`mr-1 flex flex-col 2xl:flex-row 2xl:items-center`;

export const SelectedReleaseCustomView = ({
  selectedRelease,
}: SelectedReleaseCustomViewProps) => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();

  return (
    <Container>
      <LeftInfo>
        <NumbersContainer>
          <ReleaseNumber>{`#${selectedRelease.sequenceNumber ?? ""}`}</ReleaseNumber>
          {selectedRelease.poNumber ? (
            <PONumber>{`(${selectedRelease.poNumber})`}</PONumber>
          ) : null}
        </NumbersContainer>
        <ReleaseStatus>
          {intl.$t({
            id: `RELEASE_STATUS_${selectedRelease.status}`,
          })}
        </ReleaseStatus>
      </LeftInfo>
      <RightInfo>
        <DateView date={selectedRelease.time} />
        <ReleaseTotalAmount>{`(${formatCurrency(selectedRelease.total || 0)})`}</ReleaseTotalAmount>
      </RightInfo>
    </Container>
  );
};
