import { AuthorizationStatus, ReleaseStatus } from "@/generated/graphql";
import { useMemo } from "react";
import { useRelease } from "../../../providers/ReleaseProvider";

export const useCanEditReleaseVendor = () => {
  const { release } = useRelease();

  const canEditVendor = useMemo(
    () =>
      !release?.buyout &&
      (release?.status === ReleaseStatus.Draft ||
        release?.status === ReleaseStatus.Reserved ||
        release?.status === ReleaseStatus.AwaitingApproval ||
        release?.status === ReleaseStatus.Rejected) &&
      (release?.permissions.approve === AuthorizationStatus.Authorized ||
        release?.permissions.submit === AuthorizationStatus.Authorized),
    [release],
  );

  return { canEditVendor };
};
