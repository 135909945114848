import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  CostCodeFieldsFragment,
  EstimatedItemExtendedFieldsFragment,
  ProjectExtendedFieldsFragment,
  ProjectItemFieldsFragment,
  ProjectMaterialFieldsFragment,
  TagFieldsFragment,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import {
  UNSPECIFIED_COST_CODE_ID,
  useUnspecifiedCostCode,
} from "../../../../../common/hooks/useUnspecifiedCostCode";
import {
  UNSPECIFIED_ZONE_ID,
  useUnspecifiedZone,
} from "../../../../../common/hooks/useUnspecifiedZone";
import { useProjectTags } from "./useProjectTags";
import { useProjectZones } from "./useProjectZones";

export const useProjectMaps = (
  project: ProjectExtendedFieldsFragment | null,
) => {
  const { unassignedZone } = useUnspecifiedZone();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { manufacturers } = useManufacturers();
  const { allTags } = useProjectTags();
  const { zones } = useProjectZones();

  const costCodeMap = useMemo(() => {
    const map = new Map<string, CostCodeFieldsFragment>();
    map.set(UNSPECIFIED_COST_CODE_ID, unassignedCostCode);
    if (project) {
      project.location.org.costCodes.forEach((costCode) => {
        map.set(costCode.id, costCode);
      });
    }
    return map;
  }, [project, unassignedCostCode]);

  const tagsMap = useMemo(() => {
    const map = new Map<string, TagFieldsFragment>();
    if (project) {
      allTags.forEach((tag) => {
        map.set(tag.id, tag);
      });
    }
    return map;
  }, [project, allTags]);

  const projectItemsMap = useMemo(() => {
    const map = new Map<string, ProjectItemFieldsFragment>();
    if (project) {
      project.items.forEach((item) => {
        map.set(item.id, {
          ...item,
          estimatedItems:
            item.estimatedItems.map((e) => ({
              id: e.id,
              quantityDecimal: "0",
              tags: [],
            })) || [],
          buyoutItems: item.buyoutItems || [],
        });
      });
    }
    return map;
  }, [project]);

  const materialsMap = useMemo(() => {
    const map = new Map<string, ProjectMaterialFieldsFragment>();
    if (project) {
      project.items.forEach((item) => {
        map.set(item.material.id, {
          ...item.material,
        });
      });
    }
    return map;
  }, [project]);

  const estimatedItemsMap = useMemo(() => {
    const map = new Map<string, EstimatedItemExtendedFieldsFragment>();
    if (project) {
      project.aggregatedZones.forEach((zone) => {
        zone.costCodes.forEach((costCode) => {
          costCode.items.forEach((item) => {
            item.estimatedItems.forEach((estimatedItem) => {
              map.set(estimatedItem.id, {
                ...estimatedItem,
                tags: estimatedItem.tags.map((tag) => {
                  const projectTag = allTags.find(
                    (projectTag) => projectTag.id === tag.id,
                  );
                  return {
                    name: projectTag?.name || "",
                    color: projectTag?.color || "",
                    inUse: projectTag ? true : false,
                    hasMapping: projectTag?.hasMapping || false,
                    ...tag,
                  };
                }),
                manufacturer:
                  manufacturers.find(
                    (m) => m.id === estimatedItem.manufacturer?.id,
                  ) || null,
                zone: zone.zone
                  ? {
                      ...zone.zone,
                    }
                  : null,
              });
            });
          });
        });
      });
    }
    return map;
  }, [project, manufacturers, allTags]);

  const zoneMap = useMemo(() => {
    const map = new Map<string, ZoneFieldsFragment>();

    if (project) {
      zones.forEach((zone) => {
        map.set(zone?.id || UNSPECIFIED_ZONE_ID, zone ?? unassignedZone);
      });
    }
    return map;
  }, [zones, unassignedZone, project]);

  return {
    costCodeMap,
    tagsMap,
    projectItemsMap,
    zoneMap,
    estimatedItemsMap,
    materialsMap,
  };
};
