import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { useAddInvoiceItems } from "@/common/hooks/add-missing-items-to-order/useAddInvoiceItems";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceSelectModeHeader } from "../invoice-select-mode-header/InvoiceSelectModeHeader";
import { InvoiceCreateReleaseHeader } from "./InvoiceCreateReleaseHeader";
import { InvoiceCreateReleaseItemizedView } from "./InvoiceCreateReleaseItemizedView";
import { InvoiceCreateReleaseNonItemizedView } from "./InvoiceCreateReleaseNonItemizedView";
import { InvoiceExternalPOCostCodes } from "./InvoiceExternalPOCostCodes";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const HeaderGroup = tw.div`flex w-full place-items-center gap-1 mb-2`;
const ButtonGroup = tw.div`flex flex-row gap-2 min-w-screen-xs justify-end pr-2 items-center`;

export const InvoiceCreateRelease: FC = () => {
  const { addInvoiceItems } = useAddInvoiceItems();
  const {
    importedPoItemized,
    isNonItemizedPO,
    importedPoIsImmutable,
    setVariables,
  } = useInvoiceImportExternalPO();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { initViewStore, resetViewStore, setViewState } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
      setViewState: state.setViewState,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    setViewState(
      importedPoItemized ? TableViewState.spreadsheet : TableViewState.normal,
    );
  }, [importedPoItemized, setViewState]);

  return (
    <Container>
      <HeaderGroup>
        <InvoiceSelectModeHeader
          classes={{
            root: "flex flex-full justify-evenly",
            row: "justify-start",
          }}
        >
          <ButtonGroup>
            <If isTrue={importedPoItemized && !importedPoIsImmutable}>
              <OutlinedButton $small onClick={() => addInvoiceItems()}>
                <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_INVOICE" />
              </OutlinedButton>
            </If>
            <If
              isTrue={
                (isNonItemizedPO ||
                  matchedOrderViewState !==
                    MatchedOrderViewState.IMPORT_ORDER) &&
                !importedPoIsImmutable
              }
            >
              <ItemizedNonItemizedToggle
                handleChange={(view) =>
                  setVariables({
                    importedPoItemized: view === ViewType.Itemized,
                  })
                }
                isItemized={!!importedPoItemized}
              />
            </If>
          </ButtonGroup>
        </InvoiceSelectModeHeader>
      </HeaderGroup>
      <InvoiceCreateReleaseHeader />
      <InvoiceExternalPOCostCodes />
      <If
        isTrue={
          importedPoItemized ||
          matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
        }
      >
        <InvoiceCreateReleaseItemizedView />
      </If>
      <If
        isTrue={
          !importedPoItemized &&
          matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER
        }
      >
        <InvoiceCreateReleaseNonItemizedView />
      </If>
    </Container>
  );
};
