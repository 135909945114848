import { AssetViewer } from "@/common/components/asset-viewer/AssetViewer";
import { Loader } from "@/common/components/loader/Loader";
import {
  NoteDocumentFieldsFragment,
  NoteDocumentSummaryFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useNoteDocument } from "../../providers/NoteDocumentProvider";
import { ReplaceNoteFile } from "../replace-note/ReplaceNoteFile";
const Container = tw.div`flex flex-col bg-blue-100 rounded-3xl pt-6 pb-5 text-black h-full overflow-scroll`;
const Header = tw.div`grid grid-flow-col w-full place-items-center justify-between text-base font-medium px-6 pb-4 `;

type Props = {
  document?: NoteDocumentSummaryFieldsFragment | null;
  replaceCallback?: (document: NoteDocumentFieldsFragment) => void;
};

export const NoteDocumentDetails: FC<Props> = ({
  document,
  replaceCallback,
}) => {
  const { noteDocument } = useNoteDocument();

  if (!noteDocument && !document) {
    return <Loader loading />;
  }

  return (
    <Container>
      <Header>
        <FormattedMessage id="UPLOADED_NOTE" />
      </Header>
      <AssetViewer
        asset={document?.asset || noteDocument?.asset}
        customFooter={<ReplaceNoteFile replaceCallback={replaceCallback} />}
      />
    </Container>
  );
};
