import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import {
  LgContainer,
  LgFixedContainer,
  MdFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { MissingBudgetCombinationType } from "../../../../../providers/MissingBudgetCombinationsProvider";

const CostCodeContainer = tw.div`flex flex-row gap-2 items-center`;

export const useMissingBudgetCombinationsListConfiguration = ({
  includeZones,
}: {
  includeZones: boolean;
}) => {
  const { formatCostCode } = useCostCodes();
  const { formatCostType } = useCostTypes();

  const configuration: Array<GridCol<MissingBudgetCombinationType>> = useMemo(
    () => [
      {
        wrapper: LgFixedContainer,
        item: ({ item, count }) => (
          <CostCodeContainer>
            <CircleCounter count={count} />
            {formatCostCode(item.costCode)}
          </CostCodeContainer>
        ),
        header: <FormattedMessage id="COST_CODE" />,
      },
      {
        wrapper: MdFixedContainer,
        position: "center",
        item: ({ item }) => (
          <NotNullableRenderer value={item.costType}>
            {formatCostType(item.costType)}
          </NotNullableRenderer>
        ),
        header: <FormattedMessage id="COST_TYPE" />,
      },
      ...(includeZones
        ? [
            {
              wrapper: LgContainer,
              position: "center",
              item: ({ item }) => (
                <NotNullableRenderer value={item.zone?.name} />
              ),
              header: <FormattedMessage id="ZONE" />,
            } as GridCol<MissingBudgetCombinationType>,
          ]
        : []),
    ],
    [formatCostCode, formatCostType, includeZones],
  );

  return configuration;
};
