import { routes } from "@/config/routes";
import { Quote, Rfq } from "@/generated/graphql";
import { useCallback } from "react";
import { MdOutlineImage } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid grid-flow-col justify-start gap-2 items-center text-blue-500 hover:text-blue-300 cursor-pointer text-sm
`;

const AddQuoteDocumentButton = tw.div`
  grid place-items-center text-gray-500 border-gray-500 w-17 h-17 border rounded-md  text-3xl
`;

type QuoteFromRfqTileProps = {
  quote: Pick<Quote, "id"> & {
    rfq: Pick<Rfq, "id" | "clientIdentifier">;
  };
  vendorRedirect?: () => void;
};

export const QuoteFromRfqTile = ({
  quote,
  vendorRedirect,
}: QuoteFromRfqTileProps) => {
  const { clientIdentifier } = quote.rfq;
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (vendorRedirect) {
      vendorRedirect();
    } else {
      navigate(generatePath(routes.bidsWithId, { id: quote.rfq.id }));
    }
  }, [navigate, quote.rfq.id, vendorRedirect]);

  return (
    <Container onClick={onClick}>
      <AddQuoteDocumentButton>
        <MdOutlineImage />
      </AddQuoteDocumentButton>
      <FormattedMessage
        id="RFQ_NUMBER_WITH_SYMBOL"
        values={{ number: clientIdentifier }}
      />
    </Container>
  );
};
