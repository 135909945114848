import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterItemInputContainer,
  FooterPropsWithTaxes,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useBuyoutGrouped } from "../../document/providers/BuyoutGroupedProvider";

const marginRightClass = "pr-4";
const Container = tw.div`text-sm whitespace-nowrap`;

export const BuyoutOrderedTotalFooter: FC<FooterPropsWithTaxes> = ({
  Wrapper,
  includeAdditionalCharges,
}) => {
  const { orderedSalesTax, orderedSubtotal, orderedTotal } = useBuyoutGrouped();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  if (!canViewPrices) {
    return null;
  }

  return (
    <FooterCol>
      <Wrapper className="flex-col items-end">
        <If isTrue={includeAdditionalCharges}>
          <FooterItemInputContainer>
            <FormattedMessage
              id="ADDITIONAL_CHARGES_ALLOWANCE"
              tagName={Container}
            />
          </FooterItemInputContainer>
        </If>
        <FooterHeader className={marginRightClass}>
          <FormattedMessage id="CLIENT_ORDERED" tagName={FooterHeaderText} />
        </FooterHeader>
        <FooterCell className={marginRightClass}>
          <Price
            price={orderedSubtotal}
            className="font-light"
            testId="orderedSubtotal"
          />
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={orderedSalesTax}>
            <Price
              price={orderedSalesTax}
              className="font-light"
              testId="orderedSalesTax"
            />
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal className="pr-0">
          <FooterCellCol className={marginRightClass}>
            <Price
              price={orderedTotal}
              maximumFractionDigits={2}
              testId="orderedTotal"
            />
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
