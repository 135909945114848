import { useMemo } from "react";
import { useRelease } from "../../../providers/ReleaseProvider";

export const useReleaseVendorActiveConacts = () => {
  const { release } = useRelease();

  const vendorActiveContacts = useMemo(
    () =>
      release?.preferredVendor?.contacts.filter(
        (contact) => contact.receivesOrderNotifications,
      ) || [],
    [release?.preferredVendor?.contacts],
  );

  return { vendorActiveContacts };
};
