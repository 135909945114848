import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { TextField } from "@/common/components/textfield/TextField";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center justify-center gap-1`;

export const ReleaseVendorName = () => {
  const { release } = useRelease();
  const org = release?.sellerOrgLocation?.org;
  if (!release || !org) {
    return null;
  }
  return (
    <Container>
      <TextField
        staticText
        label="Vendor"
        value={vendorLabelFormatter(release?.sellerOrgLocation)}
        multiline
        InputProps={{
          startAdornment: (
            <OrgLogo
              className="mr-2"
              logoImageUrl={org.photoUrl}
              name={org.name}
              width={32}
            />
          ),
        }}
      />
    </Container>
  );
};
