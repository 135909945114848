import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { Check, Close, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  CostType,
  useCostTypesList,
} from "../../cost-types/providers/CostTypesListProvider";
import { useCostTypes } from "../hooks/useCostTypes";

type Props = {
  costType: CostType | CostType;
  readonly?: boolean;
  setEditMode: (value: boolean) => void;
};
const IconButtonContainer = tw.div`grid gap-2 grid-cols-[repeat(3,_19px)] px-1`;

export const CostTypeActions: FC<Props> = ({
  costType,
  readonly = false,
  setEditMode,
}: Props) => {
  const {
    initialTypes,
    costTypes,
    setCostTypes,
    saveCostType,
    deleteCostType,
  } = useCostTypesList();
  const { loading } = useCostTypes();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();

  const onEdit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const onCancel = useCallback(() => {
    if (costType.id === "") {
      setCostTypes(costTypes.filter((c) => c.id !== costType.id));
    } else {
      setCostTypes(
        costTypes.map((c) =>
          c.id === costType.id
            ? {
                ...c,
                ...initialTypes.find((i) => i.id === c.id),
              }
            : c,
        ),
      );
    }
    setEditMode(false);
  }, [costType.id, costTypes, initialTypes, setCostTypes, setEditMode]);

  const onSave = useCallback(async () => {
    setCostTypes(
      costTypes.map((c) =>
        c.id === costType.id ? { ...c, ...costType, isSubmitted: true } : c,
      ),
    );
    if (costType.code && costType.description) {
      await saveCostType(costType.id);
      setEditMode(false);
    }
  }, [setCostTypes, costTypes, costType, saveCostType, setEditMode]);

  const onRemove = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "DELETE" }),
      includeWarningIcon: true,
      title: intl.$t({ id: "DELETE_COST_TYPE" }),
      text: intl.$t({ id: "DELETE_COST_TYPE_CONFIRMATION" }),
      handleConfirm: async () => {
        await deleteCostType(costType.id);
        setSuccessAlert(intl.$t({ id: "COST_TYPE_DELETED_SUCCESS" }));
      },
    });
  }, [costType, deleteCostType, intl, openDialog, setSuccessAlert]);

  const icons = useMemo(() => {
    if (!readonly) {
      return [
        <IconButtonBorderless
          onClick={onSave}
          key={1}
          className="col-start-2"
          loading={loading}
        >
          <Check />
        </IconButtonBorderless>,
        <IconButtonBorderless
          key={2}
          onClick={onCancel}
          className="col-start-3"
        >
          <Close />
        </IconButtonBorderless>,
      ];
    } else if (costType.mappings.length > 0) {
      return [
        <IconButtonBorderless
          key={2}
          onClick={onRemove}
          className="col-start-3"
        >
          <DeleteOutline />
        </IconButtonBorderless>,
      ];
    } else {
      return [
        <IconButtonBorderless
          key={1}
          onClick={onEdit}
          loading={loading}
          className="col-start-2"
        >
          <EditOutlined />
        </IconButtonBorderless>,
        <IconButtonBorderless
          key={2}
          onClick={onRemove}
          className="col-start-3"
        >
          <DeleteOutline />
        </IconButtonBorderless>,
      ];
    }
  }, [
    readonly,
    onSave,
    loading,
    onCancel,
    costType.mappings.length,
    onEdit,
    onRemove,
  ]);

  return <IconButtonContainer>{icons.map((icon) => icon)}</IconButtonContainer>;
};
