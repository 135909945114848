import { routes } from "@/config/routes";
import { WithAdminNavigation } from "../Admin";
import { WithAdminCodeTabNavigation } from "./common/WithAdminCodeTabNavigation";
import { CostCodes } from "./pages/cost-codes/CostCodes";
import { CostTypes } from "./pages/cost-types/CostTypes";
import { Equipment } from "./pages/equipment/Equipment";
import { PaymentMethods } from "./pages/payment-methods/PaymentMethods";
import { ServiceCodes } from "./pages/service-codes/ServiceCodes";
import { TaxCodes } from "./pages/tax-codes/TaxCodes";

export const costStructureRoutes = [
  {
    path: routes.costStructure,
    element: (
      <WithAdminNavigation>
        <WithAdminCodeTabNavigation />
      </WithAdminNavigation>
    ),
    children: [
      {
        path: "",
        element: <CostCodes />,
      },
      {
        path: routes.costTypes,
        element: <CostTypes />,
      },
      {
        path: routes.equipment,
        element: <Equipment />,
      },
      {
        path: routes.serviceCodes,
        element: <ServiceCodes />,
      },
      {
        path: routes.taxCodes,
        element: <TaxCodes />,
      },
      {
        path: routes.paymentMethods,
        element: <PaymentMethods />,
      },
    ],
  },
];
