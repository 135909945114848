export enum COLUMN_TYPE {
  Material = "Material",
  MaterialName = "MaterialName",
  Additional = "Additional",
  Quantity = "Quantity",
  PositiveQuantity = "PositiveQuantity",
  ReceivedQuantity = "ReceivedQuantity",
  UnitPrice = "UnitPrice",
  ExtPrice = "ExtPrice",
  PrefilledPrice = "PrefilledPrice",
  UOM = "UOM",
  Manufacturer = "Manufacturer",
  CostCode = "CostCode",
  PhaseCode = "PhaseCode",
  CostType = "CostType",
  Notes = "Notes",
  Zone = "Zone",
  CustomPartNumber = "CustomPartNumber",
  ExternalCode = "ExternalCode",
  Vendor = "Vendor",
  ExpirationDate = "ExpirationDate",
  DeliveryDate = "DeliveryDate",
  EndDate = "EndDate",
  OrderIncrement = "OrderIncrement",
  MinimumOrder = "MinimumOrder",
  LeadTime = "LeadTime",
  Code = "Code",
  CodeDescription = "CodeDescription",
  Tag = "Tag",
}
