import { useCallback, useMemo } from "react";

export const useAllItemsCheckboxSelection = <
  T extends { id: string },
  TSelected extends { id: string },
>(
  items: T[],
  selectedItems: TSelected[],
  setSelectedItems: (items: TSelected[]) => void,
  filterItems: (item: T) => boolean = () => true,
) => {
  const checked = useMemo(
    () =>
      items.length > 0 &&
      items
        .filter(filterItems)
        .every((i) =>
          selectedItems.some((selectedItem) => selectedItem.id === i.id),
        ),
    [filterItems, items, selectedItems],
  );

  const handleChange = useCallback(() => {
    const newSelectableItems = items.filter(filterItems);
    const allSelectedItemsExceptCurrentPage = selectedItems.filter(
      (selectedItem) => !items.find((item) => item.id === selectedItem.id),
    );
    if (checked) {
      setSelectedItems(allSelectedItemsExceptCurrentPage);
      return;
    }
    setSelectedItems([
      ...allSelectedItemsExceptCurrentPage,
      ...newSelectableItems,
    ] as TSelected[]);
  }, [checked, filterItems, items, selectedItems, setSelectedItems]);

  return {
    checked,
    handleChange,
  };
};
