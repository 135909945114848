import { TaxCodeSummaryFieldsFragment } from "@/generated/graphql";

export const getTaxCodeLabel = (
  taxCode: Pick<TaxCodeSummaryFieldsFragment, "code" | "description">,
) => {
  if (!taxCode.code && !taxCode.description) {
    return "";
  }

  if (!taxCode.code && taxCode.description) {
    return taxCode.description;
  }

  if (!taxCode.description && taxCode.code) {
    return taxCode.code;
  }

  return `${taxCode.code} - ${taxCode.description}`;
};
