import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  ExternalLedgerAccountType,
  useExternalGeneralLedgerAccounts,
} from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/general-ledger-account/ExternalGeneralLedgerAccountsProvider";
import { useGeneralLedgerTableConfiguration } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/general-ledger-account/GeneralLedgerTable.configuration";
import {
  SourceSystem,
  useConnectCostCodeLedgerAccountMutation,
  useDisconnectCostCodeLedgerAccountMutation,
} from "@/generated/graphql";
import { useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { CostCode } from "../providers/CostCodesListProvider";

const Footer = tw(
  FloatingFooter,
)`absolute flex items-center justify-end gap-4 bg-gray-200 !px-4`;

interface LinkLedgerAccountWithProviderProps {
  costCode: CostCode;
  sourceSystem: SourceSystem;
  handleClose: () => void;
}

export const LinkLedgerAccountWithProvider = ({
  costCode,
  sourceSystem,
  handleClose,
}: LinkLedgerAccountWithProviderProps) => {
  const [isLinking, setIsLinking] = useState(false);
  const [isUnlinking, setIsUnlinking] = useState(false);
  const [selectedLedgerAccount, setSelectedLedgerAccount] = useState<
    string | null | undefined
  >(costCode.ledgerAccount?.externalId);
  const { externalLedgerAccounts, loadingExternalLedgerAccounts } =
    useExternalGeneralLedgerAccounts();
  const configuration = useGeneralLedgerTableConfiguration(
    selectedLedgerAccount ? [selectedLedgerAccount] : [],
  );
  const intl = useIntl();
  const { setError } = useGlobalError();
  const [connectCostCodeMutation] = useConnectCostCodeLedgerAccountMutation();
  const [disconnectCostCodeMutation] =
    useDisconnectCostCodeLedgerAccountMutation();
  const { updateStartupData, costCodes } = useStartupDataStore(
    useShallow((state) => ({
      updateStartupData: state.updateStartupData,
      costCodes: state.costCodes,
    })),
  );

  const save = async () => {
    if (!selectedLedgerAccount) {
      return;
    }
    try {
      setIsLinking(true);
      const { data, errors } = await connectCostCodeMutation({
        variables: {
          input: {
            nodeId: costCode.id,
            externalId: selectedLedgerAccount,
            sourceSystem: sourceSystem,
          },
        },
      });
      updateStartupData({
        costCodes: costCodes.map((code) =>
          code.id === costCode.id
            ? {
                ...code,
                ledgerAccount: data?.connectCostCodeLedgerAccount.ledgerAccount,
              }
            : code,
        ),
      });
      if (errors) {
        setError(errors);
        return;
      }
      handleClose();
    } catch (error) {
      setError(error);
    } finally {
      setIsLinking(false);
    }
  };

  const unlink = async () => {
    try {
      setIsUnlinking(true);
      const { errors } = await disconnectCostCodeMutation({
        variables: {
          input: {
            nodeId: costCode.id,
            sourceSystem: sourceSystem,
          },
        },
      });
      if (errors) {
        setError(errors);
        return;
      }
      handleClose();
    } catch (error) {
      setError(error);
    } finally {
      setIsUnlinking(false);
    }
  };

  return (
    <>
      <LocalSearchableList<ExternalLedgerAccountType>
        tableConfiguration={configuration}
        items={externalLedgerAccounts}
        loadingItems={loadingExternalLedgerAccounts}
        className="mb-15 flex-1 overflow-scroll"
        searchBarTitle={intl.$t(
          {
            id: "GL_ACCOUNT_LINK_DIALOG_HEADER",
          },
          { costCodeDescription: costCode.description },
        )}
        searchKeys={["number", "item"]}
        itemSelection={{
          type: "single",
          selectedItemId: selectedLedgerAccount ?? null,
          setSelectedItemId: setSelectedLedgerAccount,
        }}
      />
      <Footer>
        {costCode?.ledgerAccount ? (
          <LinkLike disabled={isLinking || isUnlinking} onClick={unlink}>
            {intl.$t({ id: "GL_ACCOUNT_LINK_DIALOG_UNLINK_BUTTON" })}
          </LinkLike>
        ) : null}
        <OutlinedButton
          wide
          onClick={handleClose}
          disabled={isLinking || isUnlinking}
        >
          {intl.$t({ id: "CLOSE" })}
        </OutlinedButton>
        <PrimaryButton
          wide
          disabled={
            !selectedLedgerAccount ||
            selectedLedgerAccount === costCode.ledgerAccount?.externalId ||
            isLinking ||
            isUnlinking
          }
          onClick={save}
          loading={isLinking}
        >
          {intl.$t({ id: "SAVE" })}
        </PrimaryButton>
      </Footer>
    </>
  );
};
