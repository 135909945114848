import { gql } from "@apollo/client";

export const ORDER_TYPE_SUMMARY_FIELDS = gql`
  fragment OrderTypeSummaryFields on ReleaseType {
    id
    name
    transactionKind
    requireDeliverySlip
    includeAdditionalCharges
    poFormat
    default
    taxMode
    defaultAutoSync
  }
`;
