import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { ProjectListOptionFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { getProjectSelectorLabel } from "../projects-filter-selector/getProjectSelectorLabel";
import { StyledSelect } from "../select/components/single/StyledSelect";

type Props = {
  projectId: string | null;
  setProjectId: (projectId: string | null) => void;
  placeholder?: string;
  disabledFn?: (
    project: Pick<ProjectListOptionFieldsFragment, "id" | "name">,
  ) => boolean;
  disabledTooltip?: string;
  staticText?: boolean;
  required?: boolean;
  includeAllOption?: boolean;
};

const ALL_PROJECTS = "-1";

export const ProjectPicker: FC<Props> = ({
  projectId,
  setProjectId,
  placeholder,
  disabledFn = () => false,
  disabledTooltip,
  staticText,
  required,
  includeAllOption = true,
}) => {
  const { projects } = useProjectListOptions();
  const intl = useIntl();
  const projectOptions = useMemo(() => {
    return [
      ...(includeAllOption
        ? [
            {
              id: ALL_PROJECTS,
              name: intl.$t({ id: "ALL_PROJECTS" }),
            },
          ]
        : []),
      ...projects.map((project) => ({
        id: project.id,
        name: getProjectSelectorLabel(project),
      })),
    ];
  }, [includeAllOption, intl, projects]);

  return (
    <StyledSelect
      className="w-full"
      testId="form-projects-selector"
      placeholder={placeholder || intl.$t({ id: "PROJECT_NAME" })}
      options={projectOptions}
      value={projectId || (includeAllOption ? ALL_PROJECTS : null)}
      required={required}
      onChange={(value) =>
        setProjectId(value !== ALL_PROJECTS ? (value as string) : null)
      }
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      getOptionDisabled={disabledFn}
      disabledTooltip={disabledTooltip}
      noOptionsText={intl.$t({ id: "NO_PROJECTS_FOUND" })}
      staticText={staticText}
      size="xs"
    />
  );
};
