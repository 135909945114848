import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import { ButtonBase } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import React, { FC, forwardRef, memo, useCallback } from "react";
import tw from "tailwind-styled-components";

type BaseButtonProps = React.ComponentProps<typeof ButtonBase> & {
  loading?: boolean;
  wide?: boolean;
  narrow?: boolean;
  short?: boolean;
  testId?: string;
  fullWidth?: boolean;
  className?: string;
};

type BaseButtonStyledProps = BaseButtonProps & {
  $loading?: boolean;
  $wide?: boolean;
  $narrow?: boolean;
  $short?: boolean;
  $fullWidth?: boolean;
};

const BaseButtonStyled = tw(ButtonBase)<BaseButtonStyledProps>`
  relative shadow-none disabled:bg-opacity-50 px-4 py-2 rounded-4xl min-h-8 transition text-sm font-normal my-auto
  ${({ $loading }: BaseButtonStyledProps) => $loading && "pointer-events-none"}
  ${({ $wide }: BaseButtonStyledProps) => $wide && "px-16"}
  ${({ $narrow }: BaseButtonStyledProps) => $narrow && "px-2 min-w-fit"}
  ${({ $short }: BaseButtonStyledProps) => $short && "py-0"}
  ${({ $fullWidth }: BaseButtonStyledProps) => $fullWidth && "w-full"}
`;

const ChildrenContainer = tw.div`
  grid grid-flow-col place-items-center gap-1
  ${({ $loading }: { $loading: boolean }) => $loading && "opacity-0"}
`;

const CircularProgressStyled = tw(CircularProgress)`
  absolute mx-auto text-blue-500
  ${({ $loading }: { $loading: boolean }) => $loading && "visible"}
`;

export const BaseButton: FC<BaseButtonProps> = memo(
  forwardRef(
    (
      {
        children,
        loading,
        onClick,
        wide = false,
        narrow = false,
        short = false,
        fullWidth = false,
        testId,
        ...props
      },
      ref,
    ) => {
      const handleClick = useCallback(
        (ev: React.MouseEvent<HTMLButtonElement>) => {
          if (onClick) {
            ev.stopPropagation();
            ev.preventDefault();
            onClick(ev);
          }
        },
        [onClick],
      );

      const handleEnterKeyPress = useCallback(
        (ev: React.KeyboardEvent<HTMLButtonElement>) => {
          if (ev.key === EventKeysConstants.Enter) {
            const syntheticMouseEvent = new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            ev.currentTarget.dispatchEvent(syntheticMouseEvent);
          }
        },
        [],
      );

      return (
        <BaseButtonStyled
          {...props}
          ref={ref}
          $loading={!!loading}
          $wide={wide}
          $narrow={narrow}
          $short={short}
          $fullWidth={fullWidth}
          onClick={handleClick}
          onKeyDown={handleEnterKeyPress}
          data-testid={testId}
        >
          <ChildrenContainer $loading={!!loading}>{children}</ChildrenContainer>
          {loading ? (
            <CircularProgressStyled size={20} $loading={loading} />
          ) : null}
        </BaseButtonStyled>
      );
    },
  ),
);
