import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { ProjectFormProjectZones } from "@/contractor/pages/home/projects/components/project-form/forms/ProjectFormProjectZones";
import { useMemo } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`my-6`;

export const ProjectZones = () => {
  const { project } = useProject();

  const readonly = useMemo(() => !!project?.deletedAt, [project]);

  if (!project) {
    return null;
  }

  return (
    <Container>
      <ProjectFormProjectZones project={project} readonly={readonly} />
    </Container>
  );
};
