import { ConnectionIcon } from "@/common/components/connection-icon/ConnectionIcon";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  Base,
  MdFixedContainer,
  SmFixedContainer,
  XsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { TaxCodeFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { TaxCodeActions } from "./TaxCodeActions";
import { TaxCodeInput } from "./TaxCodeInput";
import { TaxRateCodeInput } from "./TaxCodeRateInput";

const WideContainer = tw(Base)`
  lg:basis-auto lg:w-5/12 2xl:basis-auto 2xl:w-6/12
  `;

export const useTaxCodesConfiguration = () => {
  const configuration: Array<GridCol<TaxCodeFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: WideContainer,
        header: <FormattedMessage id="COUNTY_DESCRIPTION" />,
        item: ({ item, editMode, rowError }) => (
          <TaxCodeInput
            code={item}
            error={rowError}
            prop="description"
            placeholder="COUNTY_DESCRIPTION"
            readonly={!editMode && item.id !== ""}
          />
        ),
        sortItemFn: (item1, item2) =>
          (item1.description || "").localeCompare(item2.description || ""),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="CODE" />,
        item: ({ item, editMode, rowError }) => (
          <TaxCodeInput
            code={item}
            error={rowError}
            prop="code"
            placeholder="CODE"
            required
            readonly={!editMode && item.id !== ""}
            centered
          />
        ),
        sortItemFn: (item1, item2) => item1.code.localeCompare(item2.code),
        position: "center",
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="TAX_RATE" />,
        item: ({ item, editMode, rowError }) => (
          <TaxRateCodeInput
            code={item}
            error={rowError}
            prop="rate"
            placeholder="TAX_RATE"
            required
            readonly={!editMode && item.id !== ""}
          />
        ),
        sortItemFn: (item1, item2) => item1.code.localeCompare(item2.code),
        position: "center",
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="IMPORTED_FROM" />,
        item: ({ item }) => (
          <ConnectionIcon sourceSystem={item.externalTaxCode?.sourceSystem} />
        ),
        position: "center",
      },
      {
        wrapper: XsFixedContainer,
        item: ({ item }) => <TaxCodeActions item={item} />,
      },
    ],
    [],
  );

  return { configuration };
};
