import { Loader } from "@/common/components/loader/Loader";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import { SourceSystem } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div``;
const SourceSystemLogo = tw.img`mr-2 w-6 h-6`;

type Props = {
  connectedSourceSystem: SourceSystem | undefined;
  loading?: boolean;
  isActive: boolean;
  onClick: () => void;
  enabledTooltip: string;
  disabledTooltip: string;
};

export const ConnectionStatusAdornment: FC<Props> = ({
  connectedSourceSystem,
  loading,
  isActive,
  onClick,
  enabledTooltip,
  disabledTooltip,
}) => {
  const { sourceSystemLogo } = useSourceSystemLogo(connectedSourceSystem, true);
  if (loading) {
    return <Loader loading small className="mr-2 w-[30px]" />;
  }
  if (connectedSourceSystem) {
    return (
      <Container onClick={onClick}>
        <Tooltip
          id="external-tooltip"
          element={
            <SourceSystemLogo
              className={isActive ? "" : "opacity-30"}
              src={sourceSystemLogo}
            />
          }
          className="mr-2 cursor-pointer"
        >
          <FormattedMessage
            id={isActive ? enabledTooltip : disabledTooltip}
            values={{ sourceSystem: `SOURCE_SYSTEM_${connectedSourceSystem}` }}
          />
        </Tooltip>
      </Container>
    );
  }
  return null;
};
