import { Identity } from "@/types/Identity";
import { ItemSelectionType } from "./SearchableList";

export const defaultTableItemSelectionClassNames =
  "bg-blue-100 border-blue-500 hover:bg-blue-100 first:mt-0 transition-all";

export const greenTableItemSelectionClassNames =
  "bg-green-100 border-green-500 hover:bg-green-100 first:mt-0 transition-all";

export const itemFn = <T extends Identity>(
  item: T,
  itemSelection?: ItemSelectionType<T>,
) => {
  if (itemSelection) {
    if (itemSelection.type === "single") {
      if (itemSelection.selectedItemId === item.id) {
        return {
          className: defaultTableItemSelectionClassNames,
        };
      }
    } else if (itemSelection.selectedItemIds.includes(item.id)) {
      return {
        className: defaultTableItemSelectionClassNames,
      };
    }
  }
  return { className: "" };
};

export const onItemClick = <T extends Identity>(
  item: T,
  itemSelection?: ItemSelectionType<T>,
) => {
  if (itemSelection) {
    if (itemSelection.type === "single") {
      if (itemSelection.selectedItemId === item.id) {
        itemSelection.setSelectedItemId(null);
        itemSelection.setSelectedItem?.(null);
      } else {
        itemSelection.setSelectedItemId(item.id);
      }
    } else if (itemSelection.selectedItemIds.includes(item.id)) {
      itemSelection.setSelectedItemIds(
        itemSelection.selectedItemIds.filter((id) => id !== item.id),
      );
    } else {
      itemSelection.setSelectedItemIds([
        ...itemSelection.selectedItemIds,
        item.id,
      ]);
    }
  }
};
