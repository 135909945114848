import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { ListDetailsHeaderItem } from "@/common/components/info-header/components/ListDetailsHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import {
  OrgRolesWrapper,
  Permission,
} from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Price } from "@/common/components/price/Price";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { BuyoutReleasesFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import { VendorList } from "../../../../../../common/components/vendor-list/VendorList";
import { BuyoutReleaseInfo } from "./BuyoutReleaseInfo";

type ReleasesInfoBoxesProps = {
  buyout: BuyoutReleasesFieldsFragment;
};

export const ReleasesInfoBoxes: FC<ReleasesInfoBoxesProps> = ({ buyout }) => {
  const { viewer } = useUser();
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);

  const amount = useMemo(() => new Decimal(buyout.amount), [buyout.amount]);
  const completed = useMemo(
    () => new Decimal(buyout.completedAmount),
    [buyout.completedAmount],
  );
  const remaining = useMemo(() => amount.minus(completed), [amount, completed]);

  return (
    <InfoHeader>
      <InfoHeaderItem>
        <BuyoutReleaseInfo buyout={buyout} />
      </InfoHeaderItem>
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="VENDOR" />}
        name={
          <VendorList
            contacts={buyout.preferredVendor?.contacts.filter(
              (c) => c.receivesOrderNotifications,
            )}
          />
        }
        orgName={buyout.sellerOrgLocation.org.name}
        logoImageUrl={buyout.sellerOrgLocation.org.photoUrl}
        address={buyout.sellerOrgLocation.address}
      />
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="PROJECT_DELIVERY_SITE" />}
        name={buyout.project.name}
        orgName={viewer?.org.name}
        navTo={generatePath(routes.projectDetails, { id: buyout.project.id })}
        logoImageUrl={viewer?.org.photoUrl}
        address={buyout.project.address}
      />
      <ListDetailsHeaderItem
        title={
          <OrgRolesWrapper permissions={[Permission.canViewPrices]}>
            <FormattedMessage
              id="BUYOUT_TOTAL_PRICE"
              values={{ price: <Price price={amount} /> }}
            />
          </OrgRolesWrapper>
        }
        list={
          hasPermissions
            ? [
                {
                  label: <FormattedMessage id="BUYOUT_COMPLETED_PRICE" />,
                  value: <Price price={completed} />,
                },
                {
                  label: <FormattedMessage id="BUYOUT_SCHEDULED_PRICE" />,
                  value: <Price price={new Decimal(buyout.scheduledAmount)} />,
                },
                {
                  label: <FormattedMessage id="BUYOUT_REMAINING_PRICE" />,
                  value: <Price price={remaining} />,
                },
              ]
            : []
        }
      />
    </InfoHeader>
  );
};
