import { AutocompleteRenderInputParams } from "@mui/material";
import { TextFieldStyled } from "../../styles/Selector.styles";
import { SelectCommonProps } from "./SelectCommonProps";

type Props<T> = {
  params: AutocompleteRenderInputParams;
  props: Pick<
    SelectCommonProps<T>,
    | "InputLabelProps"
    | "InputProps"
    | "inputProps"
    | "sx"
    | "className"
    | "label"
    | "placeholder"
    | "required"
    | "staticText"
    | "error"
  > & { xs?: boolean; shrink?: boolean };
};

export const InputRenderer = <T,>({
  params,
  props: {
    inputProps,
    InputLabelProps,
    sx,
    InputProps,
    className,
    label,
    placeholder,
    required,
    staticText,
    error,
    xs,
    shrink,
  },
}: Props<T>) => (
  <TextFieldStyled
    {...params}
    inputProps={{ ...params.inputProps, ...inputProps }}
    InputLabelProps={{
      ...(shrink && { shrink: true }),
      ...InputLabelProps,
    }}
    InputProps={{ ...params.InputProps, ...InputProps }}
    className={className}
    label={label ?? placeholder}
    sx={sx}
    size="small"
    required={required}
    staticText={staticText}
    fullWidth
    error={error}
    xs={xs}
  />
);
