import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { InfoOutlined } from "@mui/icons-material";
import { DialogActions } from "@mui/material";
import { PickersActionBarProps } from "@mui/x-date-pickers";
import { useLocalizationContext } from "@mui/x-date-pickers/internals";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  flex bg-gray-100 rounded-4xl px-6 py-2 items-center justify-between w-full gap-2
`;

const InfoText = tw.div`
  text-gray-600 text-sm flex items-center gap-1
`;

const InfoIcon = tw(InfoOutlined)`
  text-black text-xl
`;

export const ReleaseBackorderDatePickerCustomActionBar = (
  props: PickersActionBarProps,
) => {
  const { onAccept, actions, className } = props;
  const { localeText } = useLocalizationContext();

  if (actions == null || actions.length === 0) {
    return null;
  }

  return (
    <DialogActions className={className}>
      <Container>
        <InfoText>
          <InfoIcon />
          <FormattedMessage id="ORDER_BACKDATED" />
        </InfoText>
        <PrimaryButton
          onClick={() => {
            onAccept();
          }}
        >
          {localeText.okButtonLabel}
        </PrimaryButton>
      </Container>
    </DialogActions>
  );
};
