import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import {
  CreateOrgMaterialInput,
  OrgMaterialFieldsFragment,
  OrgMaterialsDocument,
  QueryOrgMaterialsFilter,
  UomsDocument,
  UpdateOrgMaterialsInput,
  useCreateOrgMaterialMutation,
  useRemoveOrgMaterialMutation,
  useUpdateOrgMaterialsMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useCallback, useContext, useState } from "react";
import { useMaterialsWithPagination } from "../hooks/useMaterialsWithPagination";

type ProviderContextType = {
  materials: OrgMaterialFieldsFragment[];
  refetch: () => void;
  filter?: QueryOrgMaterialsFilter | undefined;
  setFilter: (filter: QueryOrgMaterialsFilter | undefined) => void;
  loading: boolean;
  totalCount: number;
  isFiltered: boolean;
  commitUpdate: (id: string) => Promise<boolean>;
  updateMaterial: (id: string, price: UpdateOrgMaterialsInput) => void;
  selectedMaterials: string[];
  setSelectedMaterials: (materials: string[]) => void;
  selectMaterial: (materials: string) => void;
  createMaterial: (material: CreateOrgMaterialInput) => void;
  removeMaterial: (id: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  materials: [],
  refetch: () => null,
  filter: undefined,
  setFilter: () => null,
  loading: false,
  totalCount: 0,
  isFiltered: false,
  commitUpdate: NoFunctionBooleanPromise,
  updateMaterial: NoFunction,
  selectedMaterials: [],
  selectMaterial: NoFunction,
  setSelectedMaterials: NoFunction,
  createMaterial: NoFunction,
  removeMaterial: NoFunction,
});

export const MaterialListProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();

  const [materialMap, setMaterialMap] = useState<
    Record<string, UpdateOrgMaterialsInput>
  >({});
  const [filter, setFilter] = useState<QueryOrgMaterialsFilter | undefined>(
    getFiltersQueryParam(),
  );
  const { paginationArgs } = usePagination();
  const [createOrgMaterialMutation] = useCreateOrgMaterialMutation();
  const [updateOrgMaterialsMutation] = useUpdateOrgMaterialsMutation();
  const [removeOrgMaterialsMutation] = useRemoveOrgMaterialMutation();
  const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]);

  const { materials, loading, error, totalCount, refetch } =
    useMaterialsWithPagination(filter);

  const { setError } = useGlobalError();

  const commitUpdate = async (id: string) => {
    if (!materialMap) {
      return false;
    }
    const input = materialMap[id];
    if (!input) {
      return false;
    }
    return await updateProjectMaterial(input);
  };

  const createMaterial = useCallback(
    async (input: CreateOrgMaterialInput) => {
      try {
        const { errors } = await createOrgMaterialMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [createOrgMaterialMutation, setError],
  );

  const updateProjectMaterial = useCallback(
    async (input: UpdateOrgMaterialsInput) => {
      try {
        const { errors } = await updateOrgMaterialsMutation({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [setError, updateOrgMaterialsMutation],
  );

  const updateMaterial = (id: string, update: UpdateOrgMaterialsInput) => {
    setMaterialMap((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        ...update,
      },
    }));
  };

  const removeMaterial = useCallback(
    async (id: string) => {
      try {
        const { errors } = await removeOrgMaterialsMutation({
          variables: {
            id,
          },
          refetchQueries: [
            {
              query: OrgMaterialsDocument,
              variables: { ...paginationArgs, filter },
            },
          ],
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [filter, paginationArgs, removeOrgMaterialsMutation, setError],
  );

  const selectMaterial = (id: string) => {
    if (selectedMaterials.includes(id)) {
      setSelectedMaterials(
        selectedMaterials.filter((materialId) => materialId !== id),
      );
    } else {
      setSelectedMaterials([...selectedMaterials, id]);
    }
  };

  const setFilterAndUpdateQueryString = (
    filter: QueryOrgMaterialsFilter | undefined,
  ) => {
    setFiltersQueryParams(filter);
    setFilter(filter);
  };
  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        materials,
        refetch,
        loading,
        totalCount,
        setFilter: setFilterAndUpdateQueryString,
        filter,
        commitUpdate,
        updateMaterial,
        isFiltered: !!filter?.search || !!filter?.costCodeIds?.length,
        selectedMaterials,
        selectMaterial,
        setSelectedMaterials,
        createMaterial,
        removeMaterial,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMaterialList = (): ProviderContextType =>
  useContext(ProviderContext);
