import { useGlobalError } from "@/common/hooks/useGlobalError";
import { mergeChanges } from "@/common/utils/mergeChanges";
import {
  AddToReleaseItemInput,
  ProjectFieldsFragment,
  ReleaseDocument,
  ReleaseFieldsFragment,
  ReleaseItemFieldsFragment,
  ReleaseQuery,
  ReleaseStatus,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  UpdateContractorReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBoolean,
  NoFunctionBooleanPromise,
} from "@/types/NoFunction";
import { FetchResult } from "@apollo/client";
import Decimal from "decimal.js";
import { FC, createContext, useContext, useRef, useState } from "react";

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useProjectTags } from "../../project/hooks/useProjectTags";
import { useReleaseMutations } from "../pages/specify-details/hooks/useReleaseMutations";
import { useReleaseStore } from "../store/useReleaseStore";
import { ReleaseUpdate } from "../types/ReleaseUpdate";
import { useRelease } from "./ReleaseProvider";
import { useReleaseCacheUpdate } from "./useReleaseCacheUpdate";

type Props = {
  children: React.ReactNode;
  items: ReleaseItemFieldsFragment[];
  release: ReleaseUpdate;
  project?: Pick<ProjectFieldsFragment, "id"> | undefined | null;
};

type SubmitUpdateInputCallbackParams = {
  version?: number;
};

type SubmitUpdateInput = {
  skipConfirmation?: boolean;
  receive?: boolean;
  forceVersion?: boolean;
  callback?: (result: boolean, params: SubmitUpdateInputCallbackParams) => void;
  input?: UpdateContractorReleaseInput | null | undefined;
  skipVendorNotification?: boolean;
  schedule?: boolean;
};

type ProviderContextType = {
  updateRelease: (
    input: UpdateContractorReleaseInput,
    options?:
      | { batch?: boolean; setLoading?: boolean; callback?: () => void }
      | undefined,
  ) => Promise<boolean>;
  removeReleaseItem: (ids: string) => void;
  hasChanges: () => boolean;
  submitUpdate: (
    releaseNumber?: number,
    input?: SubmitUpdateInput,
  ) => boolean | Promise<boolean>;
  updating: boolean;
  release: Pick<
    ReleaseFieldsFragment,
    "id" | "version" | "status" | "sellerOrgLocation" | "poLink"
  >;
  project?: Pick<ProjectFieldsFragment, "id"> | undefined | null;
  removing: boolean;
  addItems: (itemsToAdd: AddToReleaseItemInput[]) => Promise<boolean>;
  selectedReleaseItemIds: string[];
  setSelectedReleaseItemIds: (id: string[]) => void;
  updates: UpdateContractorReleaseItemInput[] | undefined | null;
  updatingChanges: boolean;
  changes?: UpdateContractorReleaseInput;
  requireDeliverySlip: boolean;
  setRequireDeliverySlip: (val: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  updateRelease: NoFunctionBooleanPromise,
  submitUpdate: NoFunctionBoolean,
  hasChanges: () => false,
  updating: false,
  release: {
    id: "",
    version: 0,
    status: ReleaseStatus.Draft,
  },
  project: {
    id: "",
  },
  removeReleaseItem: NoFunctionBooleanPromise,
  removing: false,
  addItems: NoFunctionBooleanPromise,
  selectedReleaseItemIds: [],
  setSelectedReleaseItemIds: NoFunction,
  updates: [],
  updatingChanges: false,
  requireDeliverySlip: false,
  setRequireDeliverySlip: NoFunction,
  changes: undefined,
});

export const ReleaseUpdateProvider: FC<Props> = ({
  children,
  release,
  project,
  items,
}) => {
  const { setError } = useGlobalError();
  const [removing, setRemoving] = useState(false);
  const changes = useRef<UpdateContractorReleaseInput>();
  const updateReleaseCache = useReleaseCacheUpdate();
  const { chainUpdateContractorReleaseMutation, executionCount, updating } =
    useReleaseMutations();
  const { addItemsToRelease, invoiceStatuses } = useRelease();
  const { updateStoreRelease } = useReleaseStore();
  const { manufacturers } = useManufacturers();
  const { taxCodes } = useTaxCodeSummaries();
  const { orderTypes } = useOrderTypeOptions();
  const { allTags } = useProjectTags();

  const [selectedReleaseItemIds, setSelectedReleaseItemIds] = useState<
    string[]
  >([]);
  const [requireDeliverySlip, setRequireDeliverySlip] = useState(
    release.type.requireDeliverySlip,
  );

  const [updatingChanges, setUpdatingChanges] = useState(false);
  const {
    setHasChanges,
    updateStoreReleaseVersion,
    release: releaseStore,
  } = useReleaseStore();

  const removeReleaseItem = async (id: string) => {
    return await updateRelease({ releaseId: release.id, removedItems: [id] });
  };

  const updateRelease = async (
    input: UpdateContractorReleaseInput,
    options?:
      | { batch?: boolean; setLoading?: boolean; callback?: () => void }
      | undefined,
  ) => {
    updateReleaseCache(input, changes, { invoiceStatuses });
    updateStoreRelease(input, { taxCodes, orderTypes });
    if (options?.setLoading) {
      setUpdatingChanges(true);
    }
    const result = await update(
      input,
      options?.batch,
      undefined,
      options?.callback,
    );
    if (options?.setLoading) {
      setTimeout(() => {
        setUpdatingChanges(false);
      }, 500);
    }
    return result;
  };

  const addItems = async (
    itemsToAdd: AddToReleaseItemInput[],
  ): Promise<boolean> => {
    if (
      release &&
      itemsToAdd.length > 0 &&
      (await addItemsToRelease(itemsToAdd))
    ) {
      if (!changes.current) {
        // We want to ensure that changes are non-empty so that the update
        // mutation can be triggered following item addition/removal, even if
        // its input is a no-op (to send a notification).
        changes.current = {
          releaseId: release.id,
          version: release.version,
        };
      }
      return true;
    }
    return false;
  };

  const update = async (
    input: UpdateContractorReleaseInput,
    batch = true,
    forceVersion = false,
    callback?: () => void,
  ) => {
    accumulateChanges(input);
    if (batch) {
      return false;
    }

    if (input.version) {
      const result = submitUpdate(input.version, {
        skipConfirmation: input.skipConfirmation || false,
        forceVersion,
        callback,
      });
      if (changes.current && result) {
        await update(
          {
            ...changes.current,
            releaseId: release.id,
            warehouseId: input.warehouseId,
            clearWarehouse: !input.warehouseId,
            clearSiteContact: !input.siteContactId,
            skipConfirmation: input.skipConfirmation,
            assignDefaultCostCodes: false,
            costCodeId: input.costCodeId,
          },
          batch,
          true,
          callback,
        );
      }
    }
    return true;
  };

  const accumulateChanges = (input: UpdateContractorReleaseInput) => {
    changes.current = {
      ...changes.current,
      ...input,
      updates: mergeChanges(
        changes.current?.updates,
        input.updates,
        "releaseItemId",
      ),
    };
    changes.current.updates = changes.current?.updates?.filter(
      (item, index, self) =>
        index ===
          self.findIndex((t) => t.releaseItemId === item.releaseItemId) &&
        !changes.current?.removedItems?.includes(item.releaseItemId),
    );
    setHasChanges(true);
  };

  const hasChanges = () => Boolean(changes.current); // || itemsToRemove.length > 0;

  const submitUpdate = (
    releaseVersion?: number,
    {
      skipConfirmation,
      receive,
      forceVersion,
      callback: customCallback,
      input,
      skipVendorNotification,
      schedule,
    }: SubmitUpdateInput = {
      skipConfirmation: false,
      forceVersion: false,
      skipVendorNotification: false,
      schedule: false,
    },
  ) => {
    if (input) {
      accumulateChanges(input);
    }
    const version = forceVersion
      ? (releaseVersion ?? releaseStore?.version)
      : (releaseStore?.version ?? releaseVersion);

    if (changes.current) {
      const allChanges = changes.current;

      chainUpdateContractorReleaseMutation({
        queueItem: {
          id: release.id,
          params: {
            ...allChanges,
            updates: receive
              ? items
                  .filter((item) =>
                    selectedReleaseItemIds.find((id) => id === item.id),
                  )
                  .filter(
                    (item) =>
                      !allChanges?.updates?.find(
                        (u) =>
                          u.releaseItemId === item.id &&
                          u.receivedQuantityDecimal === "0",
                      ),
                  )
                  .map((item) => {
                    const existingItem = items.find(
                      (releaseItem) => releaseItem.id === item.id,
                    );
                    const update = allChanges?.updates?.find(
                      (u) => u.releaseItemId === item.id,
                    );
                    return update
                      ? {
                          ...update,
                          receivedQuantityDecimal: new Decimal(
                            update.receivedQuantityDecimal || 0,
                          )
                            .plus(existingItem?.receivedQuantityDecimal || 0)
                            .toString(),
                        }
                      : {
                          releaseItemId: item.id,
                          receivedQuantityDecimal:
                            existingItem?.quantityDecimal,
                        };
                  })
              : allChanges.updates,
            releaseId: release.id,
            version,
            skipConfirmation,
            receive,
            issues: allChanges.issues,
            skipVendorNotification,
            schedule,
          },
          updateApolloCache: (cache, result) => {
            const { data } =
              result as FetchResult<UpdateContractorReleaseMutation>;
            const releaseQuery = cache.readQuery<ReleaseQuery>({
              query: ReleaseDocument,
              variables: {
                id: release.id || "",
              },
            });
            if (releaseQuery?.release && data?.updateContractorRelease) {
              cache.writeQuery({
                query: ReleaseDocument,
                variables: {
                  id: release.id || "",
                },
                data: {
                  release: {
                    ...releaseQuery.release,
                    instructions: allChanges.instructions
                      ? data?.updateContractorRelease?.instructions
                      : releaseQuery.release?.instructions,
                    version: data?.updateContractorRelease.version,
                    costCode: data?.updateContractorRelease.costCode,
                    items: releaseQuery.release.items.map((item) => {
                      const updatedItem = (
                        changes.current?.updates ?? allChanges.updates
                      )?.find((u) => u.releaseItemId === item.id);
                      if (updatedItem) {
                        return {
                          ...item,
                          quantityDecimal:
                            updatedItem?.quantityDecimal ||
                            item.quantityDecimal,
                          unitPrice: updatedItem?.unitPrice || item.unitPrice,
                          manufacturer: updatedItem?.clearManufacturer
                            ? null
                            : updatedItem?.manufacturerId
                              ? manufacturers.find(
                                  (manufacturer) =>
                                    manufacturer.id ===
                                    updatedItem?.manufacturerId,
                                )
                              : item.manufacturer,
                          tags: changes.current?.updates?.find(
                            (u) => u.releaseItemId === item.id,
                          )?.tags
                            ? (allTags || []).filter((t) =>
                                changes.current?.updates
                                  ?.find((u) => u.releaseItemId === item.id)
                                  ?.tags?.includes(t.id),
                              )
                            : item.tags,
                        };
                      }
                      return item;
                    }),
                  },
                },
              });
              changes.current = undefined;
            }
          },
          callback: (
            queueItems: UpdateContractorReleaseInput[],
            result?: FetchResult<UpdateContractorReleaseMutation>,
          ) => {
            if (result?.data?.updateContractorRelease) {
              updateStoreReleaseVersion(result?.data?.updateContractorRelease);
            }
            setHasChanges(false);
            setRemoving(false);
            const errors = result?.errors;
            setError(errors);
            customCallback?.(!!result?.data, {
              version: result?.data?.updateContractorRelease.version,
            });
            return queueItems;
          },
        },
      });
      setHasChanges(false);
      return false;
    } else {
      setHasChanges(false);
      customCallback?.(true, { version });
      return true;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        updateRelease,
        hasChanges,
        submitUpdate,
        updating: executionCount.current > 0 || updating,
        release,
        project,
        removeReleaseItem,
        removing,
        addItems,
        selectedReleaseItemIds,
        setSelectedReleaseItemIds,
        requireDeliverySlip,
        setRequireDeliverySlip,
        updates: changes.current?.updates,
        updatingChanges,
        changes: changes.current,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseUpdate = (): ProviderContextType =>
  useContext(ProviderContext);
