import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ImportTaxCodesInput,
  useImportTaxCodesMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useImportTaxCodes = () => {
  const [importTaxCodesMutation, { loading }] = useImportTaxCodesMutation();
  const { setError } = useGlobalError();

  const importTaxCodes = useCallback(
    async (input: ImportTaxCodesInput) => {
      try {
        const { data, errors } = await importTaxCodesMutation({
          variables: {
            input,
          },
        });
        if (data && data.importTaxCodes) {
          return data.importTaxCodes;
        }
        if (errors) {
          setError(errors);
        }
        return null;
      } catch (error) {
        setError(error);
      }
    },
    [importTaxCodesMutation, setError],
  );

  return {
    importTaxCodes,
    loading,
  };
};
