import tw from "tailwind-styled-components";

export const AdditionalChargesContainer = tw.div`flex flex-col justify-end text-sm md:text-base font-medium`;
export const AdditionalChargesItemContainer = tw.div`grid grid-cols-[auto_164px] gap-4 text-right text-sm items-center h-10`;
export const TextFieldContainer = tw.div`flex flex-row justify-end`;
export const ItemContainer = tw.div`grid grid-cols-[auto_164px_24px] gap-4 text-right text-base items-center h-10`;
export const TaxesGroup = tw.div`grid grid-cols-[auto_164px] mr-10 gap-4 text-right items-center justify-end h-10`;
export const TotalItemOuter = tw.div`flex justify-end`;
export const TotalItemContainer = tw(AdditionalChargesItemContainer)<{
  $highlightTotal?: boolean;
  $hasAdditionalTaxes?: boolean;
}>`
  text-lg font-medium grid-cols-[auto_164px] w-fit mr-10 p-1 pt-2 pl-10 h-auto mb-1
  ${({ $highlightTotal }) =>
    $highlightTotal && "border-t-1 border-t border-dashed border-black"}
  ${({ $hasAdditionalTaxes }) => $hasAdditionalTaxes && "text-sm font-medium mt-0"}
`;
