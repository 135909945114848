import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { AddressFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
type Props = {
  logoImageUrl?: string | null;
  orgName?: string;
  contactName?: string;
  address?: AddressFieldsFragment | null;
};

const VendorWrapper = tw.div`flex items-center gap-1`;
const VendorDetailsWrapper = tw.div`grid ml-1`;
const SellerOrgName = tw.div`text-sm text-black`;
const SellerName = tw.div`text-xs text-gray-600`;

export const AssignBuyoutVendorInfo: FC<Props> = ({
  logoImageUrl,
  orgName,
  contactName,
  address,
}) => {
  return (
    <VendorWrapper>
      <VendorLogo
        logoImageUrl={logoImageUrl}
        orgName={orgName}
        contactName={contactName}
        address={address}
      />
      <VendorDetailsWrapper>
        <SellerOrgName>{`${orgName} (${address?.city})`}</SellerOrgName>
        <SellerName>{contactName}</SellerName>
      </VendorDetailsWrapper>
    </VendorWrapper>
  );
};
