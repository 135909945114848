import { ReleaseItem } from "@/generated/graphql";

export const filterReleaseItemsByDates = (
  item: Pick<ReleaseItem, "deliveryDate">,
  timeTbdFilter: boolean | undefined,
  filterDates?: {
    minDate?: number;
    maxDate?: number;
  } | null,
) =>
  timeTbdFilter
    ? item.deliveryDate === null || item.deliveryDate === undefined
    : filterDates && filterDates.minDate && filterDates.maxDate
      ? item.deliveryDate &&
        item.deliveryDate >= filterDates.minDate &&
        item.deliveryDate <= filterDates.maxDate
      : true;
