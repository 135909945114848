import { DeleteButton } from "@/common/components/button/DeleteButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceEmails } from "../../hooks/useInvoiceEmails";

const Container = tw.div`flex flex-row gap-2`;

type Props = {
  invoiceEmail: InvoiceEmailFieldsFragment;
  buttons?: React.ReactNode[];
};

export const InvoiceEmailActions: FC<Props> = ({
  invoiceEmail,
  buttons = [],
}) => {
  const { archiveInvoiceEmail } = useInvoiceEmails();
  const intl = useIntl();
  const { openDialog } = useDialog();

  const handleDelete = useCallback(() => {
    return archiveInvoiceEmail(invoiceEmail.id);
  }, [archiveInvoiceEmail, invoiceEmail.id]);

  return (
    <Container>
      <DeleteButton
        onClick={() =>
          openDialog({
            cancelButtonText: intl.$t({ id: "CANCEL" }),
            confirmButtonText: intl.$t({ id: "PROCEED" }),
            includeWarningIcon: true,
            title: intl.$t({ id: "DELETE_INVOICE_EMAIL_DETAILS" }),
            text: intl.$t({ id: "DELETE_INVOICE_EMAIL" }),
            handleConfirm: handleDelete,
          })
        }
      />
      {buttons}
    </Container>
  );
};
