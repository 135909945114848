import { useDialog } from "@/common/components/dialog/DialogProvider";
import { LinkChunks } from "@/common/components/link-chunks/LinkChunks";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { routes } from "@/config/routes";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { PrimaryButtonStyled } from "@/contractor/pages/home/release/components/connections/Connections.styles";
import { AdminUsersInfo } from "@/contractor/pages/home/release/components/connections/components/common/AdminUsersInfo";
import { ValidationType } from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { useMissingBudgetCombinations } from "@/contractor/pages/home/release/components/connections/providers/MissingBudgetCombinationsProvider";
import {
  InvoiceFieldsFragment,
  InvoiceToExportFieldsFragment,
  InvoiceValidationError,
  InvoiceValidationWarning,
  SourceSystem,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { ReactNode, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useClearPoNumber } from "../../scanned-invoices/hooks/useClearPoNumber";
import { useInvoiceValidation } from "../../scanned-invoices/providers/InvoiceValidationProvider";

export const useInvoiceReleaseValidation = (
  invoice: InvoiceFieldsFragment | null | InvoiceToExportFieldsFragment,
  poNo: string,
) => {
  const intl = useIntl();
  const { invoiceValidation, refetchInvoiceValidation } =
    useInvoiceValidation();
  const { connectedSourceSystem } = useOrgSettings();
  const integration = useMemo(
    () =>
      connectedSourceSystem
        ? intl.$t({ id: `INTEGRATION_${connectedSourceSystem}` })
        : "",
    [connectedSourceSystem, intl],
  );
  const { openDialog } = useDialog();
  const { clearPoNumber } = useClearPoNumber();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { formatCostCode } = useCostCodes();
  const { openModal: openMissingBudgetCombinationsModal } =
    useMissingBudgetCombinations();

  const clearPo = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      title: intl.$t({ id: "CLEAR_PO_NUMBER" }),
      text: intl.$t(
        { id: "CLEAR_PO_NUMBER_CONFIRMATION" },
        {
          poNumber:
            invoice?.poNumber || invoice?.release?.poNumber?.toString() || "",
          invoiceNumber: invoice?.number?.toString() || "",
          releaseNumber: invoice?.release?.sequenceNumber || "",
          br: <br />,
          integration,
        },
      ),
      includeWarningIcon: true,
      handleConfirm: async () => {
        if (!invoice?.id) {
          return;
        }
        await clearPoNumber(invoice?.id);
        refetchInvoiceValidation();
      },
    });
  }, [
    openDialog,
    intl,
    invoice?.poNumber,
    invoice?.release?.poNumber,
    invoice?.release?.sequenceNumber,
    invoice?.number,
    invoice?.id,
    integration,
    clearPoNumber,
    refetchInvoiceValidation,
  ]);

  const exportValidations = useMemo(() => {
    if (!invoiceValidation) {
      return [];
    }
    return [
      {
        id: "INTEGRATION_NOT_CONFIGURED",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.IntegrationNotConfigured,
        ),
        key: "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED",
        toolTipKey: "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED_TOOLTIP",
        adminToolTipKey:
          "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED_TOOLTIP_ADMIN",
        values: {
          admin: () => <AdminUsersInfo className="text-white underline" />,
          sub1: (chunks: ReactNode) => (
            <LinkChunks chunks={chunks} route={routes.connections} />
          ),
          integration,
        },
      },
      {
        id: "VENDOR_NOT_CONNECTED",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.VendorNotConnected,
        ),
        key: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED",
        toolTipKey: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED_TOOLTIP",
        adminToolTipKey: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED_ADMIN_TOOLTIP",
        values: {
          admin: () => <AdminUsersInfo className="text-white underline" />,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.vendorsWithId}
              routeParams={{
                vendorId: invoice?.release?.preferredVendor?.id || "",
              }}
            />
          ),
          vendor: invoice?.release?.sellerOrgLocation?.org.name,
          integration,
        },
      },
      {
        id: "PROJECT_NOT_CONNECTED",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.ProjectNotConnected,
        ),
        key: "EXPORT_PO_HOSTED_PROJECT_NOT_CONNECTED",
        toolTipKey: "EXPORT_PO_HOSTED_PROJECT_NOT_CONNECTED_TOOLTIP",
        values: {
          projectName: invoice?.release?.project?.name,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.projectDetails}
              routeParams={{ id: invoice?.release?.project?.id || "" }}
            />
          ),
          integration,
        },
      },
      {
        id: "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.AmountMismatch,
        ),
        validationType:
          connectedSourceSystem === SourceSystem.Foundation
            ? ValidationType.Warning
            : ValidationType.Error,
        key: new Decimal(invoiceValidation?.po?.amount || 0).greaterThan(
          new Decimal(invoice?.total || 0),
        )
          ? "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_GREATER_THAN_INVOICE"
          : "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_LOWER_THAN_INVOICE",
        toolTipKey: new Decimal(invoiceValidation?.po?.amount || 0).greaterThan(
          new Decimal(invoice?.total || 0),
        )
          ? "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_GREATER_THAN_INVOICE_TOOLTIP"
          : "EXPORT_INVOICE_HOSTED_AMOUNT_MISMATCH_LOWER_THAN_INVOICE_TOOLTIP",
        values: {
          integration,
          value: formatCurrency(Number(invoiceValidation?.po?.amount || 0)),
        },
      },
      {
        id: "EXPORT_INVOICE_HOSTED_MISSING_COST_CODES",
        condition: invoiceValidation.itemsMissingCostCodes.length > 0,
        key: "EXPORT_INVOICE_HOSTED_MISSING_COST_CODES",
        toolTipKey: "EXPORT_INVOICE_HOSTED_MISSING_COST_CODES_TOOLTIP",
        values: {
          releaseNumber: invoice?.release?.sequenceNumber,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.delivery}
              routeParams={{ deliveryId: invoice?.release?.id || "" }}
            />
          ),
        },
      },
      {
        id: "EXPORT_PO_COST_CODES_NOT_CONNECTED",
        condition: invoiceValidation.costCodesNotConnected.length > 0,
        key: "EXPORT_PO_COST_CODES_NOT_CONNECTED",
        adminToolTipKey: "EXPORT_PO_COST_CODES_NOT_CONNECTED_ADMIN_TOOLTIP",
        toolTipKey: "EXPORT_PO_COST_CODES_NOT_CONNECTED_TOOLTIP",
        values: {
          integration,
          sub: (chunks: ReactNode) => (
            <LinkChunks chunks={chunks} route={routes.costStructure} />
          ),
        },
      },
      {
        id: "EXPORT_PO_ITEMS_EXCEEDING_REMAINING_AMOUNT",
        condition: invoiceValidation.itemsExceedingRemainingAmount.length > 0,
        key: "EXPORT_PO_ITEMS_EXCEEDING_REMAINING_AMOUNT",
        toolTipKey: "EXPORT_PO_ITEMS_EXCEEDING_REMAINING_AMOUNT_TOOLTIP",
      },
      {
        id: "EXPORT_PO_ITEMS_EXCEEDING_REMAINING_UNEXPORTED_AMOUNT",
        condition:
          invoiceValidation?.itemsExceedingRemainingUnexportedAmount.length > 0,
        key: "EXPORT_PO_ITEMS_EXCEEDING_REMAINING_UNEXPORTED_AMOUNT",
        toolTipKey:
          "EXPORT_PO_ITEMS_EXCEEDING_REMAINING_UNEXPORTED_AMOUNT_TOOLTIP",
        values: {
          integration,
        },
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_PO_NOT_LINKED",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.PoNotLinked,
        ),
        key: "EXPORT_PO_NOT_LINKED",
        toolTipKey: "EXPORT_PO_NOT_LINKED_TOOLTIP",
        validationType: ValidationType.Error,
        values: {
          integration,
          br: <br />,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.delivery}
              routeParams={{ deliveryId: invoice?.release?.id || "" }}
            />
          ),
        },
      },
      {
        id: "TAX_ITEM_EXCEEDS_REMAINING_AMOUNT",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.TaxItemExceedsRemainingAmount,
        ),
        key: "TAX_ITEM_EXCEEDS_REMAINING_AMOUNT",
        toolTipKey: "TAX_ITEM_EXCEEDS_REMAINING_AMOUNT_TOOLTIP",
        values: {
          invoiceAmount: formatCurrency(
            Number((invoice as InvoiceFieldsFragment)?.taxAmount),
          ),
          poAmount: formatCurrency(
            Number(invoiceValidation?.salesTaxRemainingAmount),
          ),
        },
      },
      {
        id: "ADDITIONAL_CHARGES_ITEM_EXCEEDS_REMAINING_AMOUNT",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.AdditionalChargesItemExceedsRemainingAmount,
        ),
        key: "ADDITIONAL_CHARGES_ITEM_EXCEEDS_REMAINING_AMOUNT",
        toolTipKey: "ADDITIONAL_CHARGES_ITEM_EXCEEDS_REMAINING_AMOUNT_TOOLTIP",
        values: {
          invoiceAmount: formatCurrency(
            Number((invoice as InvoiceFieldsFragment)?.chargesAmount),
          ),
          poAmount: formatCurrency(
            Number(invoiceValidation?.additionalChargesRemainingAmount),
          ),
        },
      },
      {
        id: "EXPORT_PO_NOT_POSTED",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.PoNotPosted,
        ),
        key: "EXPORT_PO_NOT_POSTED",
        toolTipKey: "EXPORT_PO_NOT_POSTED_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "PO_OUTDATED",
        condition: invoiceValidation?.warnings.includes(
          InvoiceValidationWarning.PoOutdated,
        ),
        key: "PO_OUTDATED",
        toolTipKey: "PO_OUTDATED_TOOLTIP",
        values: {
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.delivery}
              routeParams={{ deliveryId: invoice?.release?.id || "" }}
            />
          ),
        },
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_INVOICE_PO_LISTS_MATERIALS_AND_NOT_LUMP_SUM_COST_CODES",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.PoNotBasic,
        ),
        key: "EXPORT_INVOICE_PO_LISTS_MATERIALS_AND_NOT_LUMP_SUM_COST_CODES",
        toolTipKey:
          "EXPORT_INVOICE_PO_LISTS_MATERIALS_AND_NOT_LUMP_SUM_COST_CODES_TOOLTIP",
        values: {
          integration,
          poNumber: poNo,
        },
      },
      {
        id: "EXPORT_INVOICE_COST_CODE_SINGULAR_PLURAL_MISSING_FROM_PO",
        condition: invoiceValidation?.costCodesMissingInPO.length > 0,
        key: "EXPORT_INVOICE_COST_CODE_SINGULAR_PLURAL_MISSING_FROM_PO",
        toolTipKey:
          "EXPORT_INVOICE_COST_CODE_SINGULAR_PLURAL_MISSING_FROM_PO_TOOLTIP",
        values: {
          count: invoiceValidation.costCodesMissingInPO.length,
          codes: invoiceValidation.costCodesMissingInPO
            .map((c) => `"${formatCostCode(c.id)}"`)
            .join(", "),
          costType: invoice?.release?.type?.costType?.description,
        },
      },
      {
        id: "EXPORT_INVOICE_COST_CODES_EXCEED_PO_AMOUNT",
        condition: invoiceValidation?.costCodesExceedingPOAmounts.length > 0,
        key: "EXPORT_INVOICE_COST_CODES_EXCEED_PO_AMOUNT",
        toolTipKey: "EXPORT_INVOICE_COST_CODES_EXCEED_PO_AMOUNT_TOOLTIP",
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_INVOICE_INVOICE_PO_DIFFERENT_ORDER_PO",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.PoNumberMismatch,
        ),
        key: "EXPORT_INVOICE_INVOICE_PO_DIFFERENT_ORDER_PO",
        toolTipKey: "EXPORT_INVOICE_INVOICE_PO_DIFFERENT_ORDER_PO_TOOLTIP",
      },
      {
        id: "EXPORT_INVOICE_LINE_ITEM_SALES_TAX_MISSING_IN_PO",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.SalesTaxItemMissing,
        ),
        key: "EXPORT_INVOICE_LINE_ITEM_SALES_TAX_MISSING_IN_PO",
        toolTipKey: "EXPORT_INVOICE_LINE_ITEM_SALES_TAX_MISSING_IN_PO_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "EXPORT_INVOICE_LINE_ITEM_ADDITIONAL_CHARGES_MISSING_IN_PO",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.AdditionalChargesItemMissing,
        ),
        key: "EXPORT_INVOICE_LINE_ITEM_ADDITIONAL_CHARGES_MISSING_IN_PO",
        toolTipKey:
          "EXPORT_INVOICE_LINE_ITEM_ADDITIONAL_CHARGES_MISSING_IN_PO_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "EXPORT_INVOICE_PO_NUMBER_IMMUTABLE",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.PoAlreadyLinked,
        ),
        key: "EXPORT_INVOICE_PO_NUMBER_IMMUTABLE",
        toolTipKey: "EXPORT_INVOICE_PO_NUMBER_IMMUTABLE_TOOLTIP",
        values: {
          integration,
          poNumber: poNo,
          releaseNumber: invoice?.release?.sequenceNumber || "",
        },
      },
      {
        id: "ORDER_HAS_NOT_BEEN_EXPORTED_AS_A_PO",
        condition: !invoiceValidation.warnings.includes(
          InvoiceValidationWarning.Standalone,
        ),
        key: "ORDER_HAS_NOT_BEEN_EXPORTED_AS_A_PO",
        values: {
          integration,
          invoiceNumber: invoice?.number,
          poNumber: poNo,
        },
        validationType: ValidationType.Warning,
      },
      {
        id: "INVOICE_WILL_BE_POSTED_AS_DIRECT_JOB_COST",
        condition: invoiceValidation.warnings.includes(
          InvoiceValidationWarning.Standalone,
        ),
        key: "INVOICE_WILL_BE_POSTED_AS_DIRECT_JOB_COST",
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_INVOICE_PO_NUMBER_NOT_FOUND",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.PoNotFound,
        ),
        key: "EXPORT_INVOICE_PO_NUMBER_NOT_FOUND",
        button: (
          <PrimaryButtonStyled onClick={clearPo}>
            <FormattedMessage id="CLEAR_PO_NUMBER" />
          </PrimaryButtonStyled>
        ),
        values: {
          poNumber: poNo,
          integration,
        },
      },
      {
        id: "EXPORT_PHASE_CODES_EXCEED_AMOUNTS",
        condition: invoiceValidation?.wbsExceedingPOAmounts.length > 0,
        key: "EXPORT_PHASE_CODES_EXCEED_AMOUNTS",
        toolTipKey: "EXPORT_PHASE_CODES_EXCEED_AMOUNTS_TOOLTIP",
        values: {
          releaseNumber: invoice?.release?.sequenceNumber,
        },
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_PHASE_CODES_MISSING_IN_PO",
        condition: invoiceValidation?.wbsMissingInPO.length > 0,
        key: "EXPORT_PHASE_CODES_MISSING_IN_PO",
        toolTipKey: "EXPORT_PHASE_CODES_MISSING_IN_PO_TOOLTIP",
        values: {
          releaseNumber: invoice?.release?.sequenceNumber,
        },
      },
      {
        id: "EXPORT_ITEMS_MISSING_TAGS",
        condition: invoiceValidation?.itemsMissingTags.length > 0,
        key: "EXPORT_ITEMS_MISSING_TAGS",
        toolTipKey: "EXPORT_ITEMS_MISSING_TAGS_TOOLTIP",
        values: {
          values: invoiceValidation?.itemsMissingTags
            .map((item) => item.orgMaterial?.material.name)
            .join(", "),
          releaseNumber: invoice?.release?.sequenceNumber,
        },
      },
      {
        id: "INVOICE_NUMBER_MISSING",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.InvoiceNumberMissing,
        ),
        key: "INVOICE_NUMBER_MISSING",
        toolTipKey: "INVOICE_NUMBER_MISSING_TOOLTIP",
      },
      {
        id: "INVOICE_NUMBER_TOO_LONG",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.InvoiceNumberTooLong,
        ),
        key: "INVOICE_NUMBER_TOO_LONG",
        toolTipKey: "INVOICE_NUMBER_TOO_LONG_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "INVOICE_ISSUE_DATE_MISSING",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.InvoiceIssueDateMissing,
        ),
        key: "INVOICE_ISSUE_DATE_MISSING",
        toolTipKey: "INVOICE_ISSUE_DATE_MISSING_TOOLTIP",
      },
      {
        id: "INVOICE_ISSUE_DATE_BEFORE_PO_DATE",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.InvoiceIssueDateBeforePoDate,
        ),
        key: "INVOICE_ISSUE_DATE_BEFORE_PO_DATE",
        toolTipKey: "INVOICE_ISSUE_DATE_BEFORE_PO_DATE_TOOLTIP",
        values: {
          poNumber: poNo,
        },
      },
      {
        id: "INVOICE_UNPOSTED_MISMATCH",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.InvoiceUnpostedMismatch,
        ),
        key: "INVOICE_UNPOSTED_MISMATCH",
        toolTipKey: "INVOICE_UNPOSTED_MISMATCH_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "INVOICE_POSTED_MISMATCH",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.InvoicePostedMismatch,
        ),
        key: "INVOICE_POSTED_MISMATCH",
        toolTipKey: "INVOICE_POSTED_MISMATCH_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "COVERAGE_MISSING",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.CoverageMissing,
        ),
        key: "COVERAGE_MISSING",
        toolTipKey: "COVERAGE_MISSING_TOOLTIP",
      },
      {
        id: "INVOICE_UNPOSTED_DUPLICATE_REEXPORT",
        condition: invoiceValidation?.warnings.includes(
          InvoiceValidationWarning.InvoiceUnpostedDuplicateReexport,
        ),
        key: "INVOICE_UNPOSTED_DUPLICATE_REEXPORT",
        toolTipKey: "INVOICE_UNPOSTED_DUPLICATE_REEXPORT_TOOLTIP",
        values: {
          integration,
        },
        validationType: ValidationType.Warning,
        alwaysInclude: true,
      },
      {
        id: "INVOICE_UNPOSTED_DUPLICATE_LINK",
        condition: invoiceValidation?.warnings.includes(
          InvoiceValidationWarning.InvoiceUnpostedDuplicateLink,
        ),
        key: "INVOICE_UNPOSTED_DUPLICATE_LINK",
        toolTipKey: "INVOICE_UNPOSTED_DUPLICATE_LINK_TOOLTIP",
        values: {
          integration,
        },
        validationType: ValidationType.Warning,
        alwaysInclude: true,
      },
      {
        id: "INVOICE_POSTED_DUPLICATE_LINK",
        condition: invoiceValidation?.warnings.includes(
          InvoiceValidationWarning.InvoicePostedDuplicateLink,
        ),
        key: "INVOICE_POSTED_DUPLICATE_LINK",
        toolTipKey: "INVOICE_POSTED_DUPLICATE_LINK_TOOLTIP",
        values: {
          integration,
        },
        validationType: ValidationType.Warning,
        alwaysInclude: true,
      },
      {
        id: "INVOICE_DUE_DATE_MISSING",
        condition: invoiceValidation?.warnings.includes(
          InvoiceValidationWarning.InvoiceDueDateMissing,
        ),
        key: "INVOICE_DUE_DATE_MISSING",
        toolTipKey: "INVOICE_DUE_DATE_MISSING_TOOLTIP",
        validationType: ValidationType.Warning,
        alwaysInclude: true,
      },
      {
        id: "EXPORT_COST_CODE_MISMATCHES",
        condition: invoiceValidation?.costCodeMismatches.length > 0,
        key: "EXPORT_COST_CODE_MISMATCHES",
        toolTipKey: "EXPORT_COST_CODE_MISMATCHES_TOOLTIP",
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_DESCRIPTION_MISMATCHES",
        condition: invoiceValidation?.descriptionMismatches.length > 0,
        key: "EXPORT_DESCRIPTION_MISMATCHES",
        toolTipKey: "EXPORT_DESCRIPTION_MISMATCHES_TOOLTIP",
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_UOM_MISMATCHES",
        condition: invoiceValidation?.uomMismatches.length > 0,
        key: "EXPORT_UOM_MISMATCHES",
        toolTipKey: "EXPORT_UOM_MISMATCHES_TOOLTIP",
        validationType: ValidationType.Warning,
      },
      {
        id: "EXPORT_ITEMS_NOT_CONNECTED",
        condition: invoiceValidation?.itemsNotConnected.length > 0,
        key: "EXPORT_ITEMS_NOT_CONNECTED",
        toolTipKey: "EXPORT_ITEMS_NOT_CONNECTED_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "MISSING_BUDGET_COMBINATIONS",
        condition: invoiceValidation?.missingBudgetCombinations.length > 0,
        key: "MISSING_BUDGET_COMBINATIONS",
        values: {
          count: (invoiceValidation?.missingBudgetCombinations || []).length,
          integration,
        },
        hideInfoIcon: true,
        button: (
          <PrimaryButtonStyled
            onClick={() =>
              openMissingBudgetCombinationsModal({
                sourceSystem: connectedSourceSystem,
                missingBudgetCombinations:
                  invoiceValidation.missingBudgetCombinations.map(
                    (combination, key) => ({
                      ...combination,
                      id: (key + 1).toString(),
                    }),
                  ),
                projectId: invoice?.release?.project?.id,
              })
            }
          >
            <FormattedMessage id="VIEW" />
          </PrimaryButtonStyled>
        ),
      },
      {
        id: "TAX_CODE_NOT_CONNECTED",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.TaxCodeNotConnected,
        ),
        key: "TAX_CODE_NOT_CONNECTED",
        values: {
          integration,
          sub: (chunks: ReactNode) => (
            <LinkChunks chunks={chunks} route={routes.taxCodes} />
          ),
        },
        toolTipKey: "TAX_CODE_NOT_CONNECTED_TOOLTIP",
        validationType: ValidationType.Error,
      },
      {
        id: "TAX_CODE_NOT_ASSIGNED",
        condition: invoiceValidation?.errors.includes(
          InvoiceValidationError.TaxCodeNotAssigned,
        ),
        key: "TAX_CODE_NOT_ASSIGNED",
        toolTipKey: "TAX_CODE_NOT_ASSIGNED_TOOLTIP",
        validationType: ValidationType.Error,
      },
    ];
  }, [
    invoiceValidation,
    integration,
    invoice,
    connectedSourceSystem,
    formatCurrency,
    poNo,
    clearPo,
    formatCostCode,
    openMissingBudgetCombinationsModal,
  ]);

  const validations = useMemo(() => {
    if (!connectedSourceSystem) {
      return [];
    }
    let validations = exportValidations.filter(
      (v) => v.validationType !== ValidationType.Warning || v.alwaysInclude,
    );

    return validations.filter((v) => v.condition);
  }, [connectedSourceSystem, exportValidations]);

  return {
    validations,
    exportValidations,
  };
};
