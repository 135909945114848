import { DateView } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import {
  ReleaseApprovalFieldsFragment,
  UsersUserFieldsFragment,
} from "@/generated/graphql";
import { Avatar } from "@mui/material";
import React, { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";
import { Tooltip } from "../../tooltip/Tooltip";

const Container = tw.div`
  flex flex-col max-w-64 justify-start gap-1
`;

const Label = tw.div`
  text-xs font-light grid grid-flow-col gap-1 justify-start
`;

const Name = tw.div`flex text-sm`;

const AvatarStyled = tw(Avatar)`
  ml-2 bg-blue-500 text-white text-2xs font-normal w-5 h-5
`;

const UserContainer = tw.div`flex flex-col`;
const DateViewContainer = tw.div`text-xs`;
const Date = tw.div`text-xs text-gray-600`;
const UserName = tw.div``;
const UserNameAndDate = tw.div`flex flex-col`;

type User = Pick<UsersUserFieldsFragment, "id" | "firstName" | "lastName">;

type Props = {
  title: string | React.JSX.Element;
  users: User[];
  approvals?: ReleaseApprovalFieldsFragment[];
  displayDateFn: (user: User) => number | undefined | null;
};

export const UserInfoHeaderItem: FC<Props> = ({
  title,
  users,
  approvals,
  displayDateFn,
}) => {
  return (
    <Container>
      <Label>{title}</Label>
      <Name>
        <UserNameAndDate>
          <UserName>{getUserName(users?.[0])}</UserName>
          <DateViewContainer>
            <Date>
              <DateView date={displayDateFn(users?.[0])} />
            </Date>
          </DateViewContainer>
        </UserNameAndDate>
        <If isTrue={users.length > 1}>
          <Tooltip
            id="extra-items"
            position="top"
            element={<AvatarStyled>+{users.length - 1}</AvatarStyled>}
          >
            {users.slice(1, users.length).map((user, index) => {
              return (
                <UserContainer key={index}>
                  <Name>{getUserName(user)}</Name>
                  <DateViewContainer>
                    <DateView
                      date={
                        approvals?.find((a) => a.approver.id === user.id)
                          ?.createdAt
                      }
                    />
                  </DateViewContainer>
                </UserContainer>
              );
            })}
          </Tooltip>
        </If>
      </Name>
    </Container>
  );
};
