import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useCallback, useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import {
  BuyoutItemFilter,
  useBuyoutFiltersStore,
} from "../store/useBuyoutFiltersStore";

export const useBuyoutItemsFilters = () => {
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const {
    filter,
    selectedCostCodes,
    selectedTags,
    excludeFullyOrdered,
    resetFilters,
  } = useBuyoutFiltersStore(
    useShallow((state) => ({
      filter: state.filter,
      selectedCostCodes: state.selectedCostCodes,
      selectedTags: state.selectedTags,
      excludeFullyOrdered: state.excludeFullyOrdered,
      resetFilters: state.resetFilters,
    })),
  );

  useEffect(() => {
    resetFilters();
  }, [resetFilters]);

  const filterFullyReceived = useCallback(
    (i: BuyoutItemFieldsFragment) => {
      switch (filter[BuyoutItemFilter.FULLY_RECEIVED]) {
        case false:
          return new Decimal(i.completedAmount || 0).lessThan(
            i.quantityDecimal,
          );
        case true:
          return new Decimal(i.completedAmount || 0).greaterThanOrEqualTo(
            i.quantityDecimal,
          );
        default:
          return true;
      }
    },
    [filter],
  );

  const filterExcludedFullyOrdered = useCallback(
    (i: BuyoutItemFieldsFragment) => {
      if (!excludeFullyOrdered) {
        return true;
      }
      const releasedAmount = Number(i?.releasedAmount) || 0;
      const quantityDecimal = Number(i?.quantityDecimal) || 0;
      return releasedAmount < quantityDecimal;
    },
    [excludeFullyOrdered],
  );

  const filterSelectedCostCodes = useCallback(
    (i: BuyoutItemFieldsFragment) => {
      if (selectedCostCodes.length === 0) {
        return true;
      }
      return selectedCostCodes.includes(
        i.costCode?.id || unassignedCostCode.id,
      );
    },
    [selectedCostCodes, unassignedCostCode.id],
  );

  const filterSelectedTags = useCallback(
    (i: BuyoutItemFieldsFragment) => {
      if (selectedTags.length === 0) {
        return true;
      }
      return selectedTags.some((tag) => i.tags?.some((t) => t.id === tag));
    },
    [selectedTags],
  );

  return {
    filterFullyReceived,
    filterExcludedFullyOrdered,
    filterSelectedCostCodes,
    filterSelectedTags,
  };
};
