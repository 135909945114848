import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  SourceSystem,
  useExternalEquipmentItemsQuery,
} from "@/generated/graphql";

export const useExternalEquipmentItems = (
  defaultSourceSystem?: SourceSystem,
) => {
  const { connectedSourceSystem } = useOrgSettings();
  const {
    data,
    loading: loadingExternalEquipmentItems,
    error: externalEquipmentItemsError,
  } = useExternalEquipmentItemsQuery({
    variables: {
      sourceSystem:
        defaultSourceSystem ?? connectedSourceSystem ?? SourceSystem.Foundation,
    },
    skip: !defaultSourceSystem && !connectedSourceSystem,
  });

  useErrorEffect(externalEquipmentItemsError);

  return {
    externalEquipmentItems: data?.externalEquipmentItems ?? [],
    loadingExternalEquipmentItems,
    externalEquipmentItemsError,
  };
};
