import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import {
  TextField,
  TextFieldProps,
} from "@/common/components/textfield/TextField";
import { Check, Close } from "@mui/icons-material";
import { FC, useState } from "react";
import tw from "tailwind-styled-components";
import { Icons } from "./InvoiceHeaderDetails.styles";

const Container = tw.div`flex flex-row relative flex-1 ml-1`;

const DatePickerTextField: FC<TextFieldProps> = ({
  required,
  staticText,
  onBlur,
  ...props
}) => {
  return (
    <TextField
      {...props}
      size="small"
      required={required}
      staticText={staticText}
      onBlur={onBlur}
      error={false}
      sx={{
        " .MuiInputBase-root": {
          backgroundColor: "#fff",
          paddingRight: "10px",
        },
        " .MuiInputBase-input": {
          paddingTop: "8.5px",
          paddingBottom: "8.5px",
          paddingLeft: "8px",
          lineHeight: "1em",
          width: "100px",
          height: "15px",
          fontSize: "14px",
          letterSpacing: "-0.5px",
        },
        " .MuiIconButton-root": {
          color: "#0070C0",
        },
        " .MuiIconButton-root:hover": {
          color: "#23B1ED",
          border: 0,
          backgroundColor: "transparent",
        },
      }}
    />
  );
};

type Props = {
  date: Date | null;
  onSave: (date: Date | null) => void;
  onClose: () => void;
};

export const InvoiceDatePicker: FC<Props> = ({
  date: _date,
  onClose,
  onSave,
}) => {
  const [date, setDate] = useState(_date);

  return (
    <Container>
      <DatePicker
        date={date}
        onChange={setDate}
        textFieldComponent={DatePickerTextField}
        utc
      />
      <Icons>
        <LinkLike
          onClick={() => {
            onSave(date);
            onClose();
          }}
        >
          <Check />
        </LinkLike>
        <LinkLike onClick={onClose}>
          <Close />
        </LinkLike>
      </Icons>
    </Container>
  );
};
