import { AuthorizationStatus, UpdateInvoiceInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { InvoiceDetailsFooter } from "../../../../../../../../../common/components/invoices/invoice-details/footer/InvoiceFooterDetails";
import { useInvoiceValidation } from "../../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import {
  Column,
  ContentColumn,
  PanelTitle,
  QuarterPanel,
} from "./InvoiceExpandable.styled";

export const InvoicePanel: FC = () => {
  const { invoice, updateInvoice } = useInvoiceVerification();
  const { refetchInvoiceValidation } = useInvoiceValidation();

  const handleUpdateInvoice = useCallback(
    (changes: UpdateInvoiceInput) => {
      refetchInvoiceValidation();
      return updateInvoice(changes);
    },
    [refetchInvoiceValidation, updateInvoice],
  );

  const readonly = useMemo(() => {
    return (
      invoice?.permissions.edit !== AuthorizationStatus.Authorized ||
      !!invoice?.archivedAt
    );
  }, [invoice]);

  return (
    <QuarterPanel data-testid="invoiceFooter" className="bg-blue-250">
      <Column>
        <FormattedMessage id="INVOICE" tagName={PanelTitle} />
      </Column>
      <ContentColumn>
        <InvoiceDetailsFooter
          invoice={invoice}
          updateInvoice={handleUpdateInvoice}
          readonly={readonly}
        />
      </ContentColumn>
    </QuarterPanel>
  );
};
