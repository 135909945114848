import { GroupFilter } from "@/common/components/groups/components/group-filter/GroupFilter";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderGroups } from "../../hooks/useOrderGroups";

const Container = tw.div`grid grid-cols-[1fr_1.5fr] justify-between items-center gap-4 mt-2`;

type Props = {
  value: string[] | null | undefined;
  onChange: (folderId: string[] | undefined) => void;
  label?: string;
  withCustomView?: boolean;
  clear?: () => void;
};

export const OrderGroupFilter: FC<Props> = (props: Props) => {
  const { groups, createGroup, updateGroup, deleteGroup } = useOrderGroups();

  return (
    <Container>
      <FormattedMessage id="ORDER_GROUPS" />
      <GroupFilter
        createGroup={createGroup}
        folders={groups}
        translationKeys={{
          addNew: "ADD_NEW_RELEASE_FOLDER",
          groupName: "RELEASE_GROUP_NAME",
          all: "ALL_RELEASE_GROUPS",
          unassigned: "UNASSIGNED_RELEASE_GROUPS",
          archiveTitle: "DELETE_RELEASE_GROUP_WITH_NAME",
          archiveContent: "DELETE_RELEASE_GROUP_CONFIRMATION",
        }}
        updateGroup={updateGroup}
        deleteGroup={deleteGroup}
        classes={{
          paper: "2xl:-ml-[94px] xl:-ml-[20px] md:-ml-[4px]",
        }}
        {...props}
      />
    </Container>
  );
};
