import { Dialog } from "@/common/components/dialog/Dialog";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ProjectItems } from "../../components/import-project-items/project-items/ProjectItems";
import { useRfqProjectListItemConfiguration } from "../../components/import-project-items/project-items/ProjectItems.configuration";
import { useRfqProjectItemsWithUpdates } from "../../components/import-project-items/RfqProjectItemsProvider";
import { useRfq } from "../../hooks/useRfq";

interface AddEstimatedItemsDialogProps {
  setShowEstimatedItemsDialog: (value: boolean) => void;
}

const Container = tw.div`w-[calc(80vw)] h-[calc(80vh)] flex-1 overflow-scroll flex flex-col`;

export const AddEstimatedItemsDialog = ({
  setShowEstimatedItemsDialog,
}: AddEstimatedItemsDialogProps) => {
  const intl = useIntl();
  const { addToRfq, updates, isUpdating } = useRfqProjectItemsWithUpdates();
  const { rfq, loading } = useRfq();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const configuration = useRfqProjectListItemConfiguration();

  const handleCancel = useCallback(() => {
    setShowEstimatedItemsDialog(false);
  }, [setShowEstimatedItemsDialog]);

  const handleConfirm = async () => {
    if (updates.length === 0) {
      setWarningAlert(<FormattedMessage id={`NOTHING_TO_ADD`} />);
      return;
    }
    if (await addToRfq()) {
      setSuccessAlert(intl.$t({ id: "ITEMS_ADDED_TO_RFQ" }));
      setShowEstimatedItemsDialog(false);
    }
  };

  if (!rfq) {
    return null;
  }

  return (
    <Dialog
      title={<FormattedMessage id={"ADD_ITEMS_TO_RFQ_FROM_ESTIMATES"} />}
      confirmButtonText={intl.$t({
        id: "RFQ_ADD_ITEMS",
      })}
      handleConfirm={handleConfirm}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      extraButtonMargin={false}
      loading={isUpdating}
      handleCancel={handleCancel}
      maxWidth={false}
      show={true}
      content={
        <Container>
          <ProjectItems columns={configuration} rfq={rfq} loading={loading} />
        </Container>
      }
    />
  );
};
