import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { generateUUID } from "@/common/utils/uuidUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  AdditionalChargesFieldsFragment,
  UpdateChargeInput,
} from "@/generated/graphql";
import { AddCircleOutlineRounded, DeleteOutline } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { AdditionalCharge } from "./AdditionalCharge";

const Container = tw.div<{
  $readonly: boolean;
}>`flex flex-col justify-center text-sm md:text-base font-medium min-h-10
  ${({ $readonly }) => ($readonly ? "pr-10 gap-0" : "gap-3")}
`;
const LinkLikeStyled = tw(LinkLike)`justify-end`;

type Props = {
  additionalCharges?: AdditionalChargesFieldsFragment[];
  readonly: boolean;
  showError?: boolean;
  updateAdditionalCharges: (additionalCharges: UpdateChargeInput[]) => void;
};

export const AdditionalCharges: FC<Props> = ({
  additionalCharges = [],
  readonly,
  showError = false,
  updateAdditionalCharges,
}) => {
  const { hasAdditionalCharges } = useOrgSettings();

  const getDefaultCharge = useCallback(
    () => ({
      description: "",
      amount: "0",
      id: generateUUID(),
    }),
    [],
  );
  const [charges, setCharges] = useState<AdditionalChargesFieldsFragment[]>(
    additionalCharges.length > 0
      ? additionalCharges
      : readonly
        ? []
        : [getDefaultCharge()],
  );

  useEffect(() => {
    if ((!additionalCharges || additionalCharges.length === 0) && !readonly) {
      setCharges([
        {
          description: "",
          amount: "0",
          id: generateUUID(),
        },
      ]);
    } else if (additionalCharges && additionalCharges.length > 0) {
      setCharges(additionalCharges);
    }
  }, [readonly, additionalCharges]);

  const onAdd = useCallback(() => {
    if (!readonly) {
      setCharges([
        ...charges,
        {
          description: "",
          amount: "0",
          id: generateUUID(),
        },
      ]);
    }
  }, [readonly, charges]);

  const onRemove = useCallback(
    (key: number) => {
      const newCharges = [
        ...(charges || []).slice(0, key),
        ...(charges || []).slice(key + 1),
      ];

      if (!readonly) {
        setCharges(newCharges);
        updateAdditionalCharges?.(newCharges);
      }
    },
    [charges, readonly, updateAdditionalCharges],
  );

  const updateCharge = useCallback(
    (key: number, charge: AdditionalChargesFieldsFragment) => {
      const newCharges = [
        ...(charges || []).slice(0, key),
        charge,
        ...(charges || []).slice(key + 1),
      ];

      if (!readonly) {
        if (Number(charge.amount) > 0 && charge.description) {
          setCharges(newCharges);
          updateAdditionalCharges?.(newCharges);
        } else {
          const existingCharge = charges[key];
          if (
            (existingCharge.description === charge.description ||
              charge.description === "") &&
            Number(charge.amount) === 0
          ) {
            const filteredCharges = charges.filter((c) => c.id !== charge.id);
            setCharges(
              filteredCharges.length > 0
                ? filteredCharges
                : [getDefaultCharge()],
            );
            updateAdditionalCharges?.(filteredCharges);
          }
        }
      }
    },
    [charges, getDefaultCharge, readonly, updateAdditionalCharges],
  );

  const handleAdditionalChargeClick = useCallback(
    (key: number) => {
      if (key === 0) {
        if (hasAdditionalCharges) {
          onAdd();
        }
        return;
      }

      onRemove(key);
    },
    [hasAdditionalCharges, onAdd, onRemove],
  );

  const getActionIcon = useCallback(
    (key: number) => {
      if (key === 0) {
        if (hasAdditionalCharges) {
          return <AddCircleOutlineRounded />;
        }
        return null;
      }
      return <DeleteOutline />;
    },
    [hasAdditionalCharges],
  );

  if (charges.length === 0) {
    return null;
  }

  return (
    <Container $readonly={readonly}>
      {charges?.map((additionalCharge, key) => (
        <AdditionalCharge
          key={key}
          index={key}
          charge={additionalCharge}
          readonly={readonly}
          showError={showError}
          onChange={(charge) => updateCharge(key, charge)}
        >
          <If isTrue={!readonly}>
            <LinkLikeStyled onClick={() => handleAdditionalChargeClick(key)}>
              {getActionIcon(key)}
            </LinkLikeStyled>
          </If>
        </AdditionalCharge>
      ))}
    </Container>
  );
};
