import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Loader } from "@/common/components/loader/Loader";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { TextField } from "@/common/components/textfield/TextField";
import { RfqFieldsFragment } from "@/generated/graphql";
import { Check, Close, EditOutlined } from "@mui/icons-material";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfq } from "../../rfq/hooks/useRfq";

type Props = {
  rfq?: RfqFieldsFragment | null;
};

const Container = tw.div`grid grid-flow-col h-full gap-2 justify-start items-center`;
const Row = tw.div`flex items-center`;
const Icons = tw.div`flex items-center`;
const TextContainer = tw.div`inline-block`;

export const EditableRfqNumber: FC<Props> = ({ rfq }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(rfq?.clientIdentifier || "");
  const { updateRfq, isUpdating } = useRfq();

  const { setValue: setFormValue } = useFormContext();

  const onSave = async (value: string) => {
    if (rfq) {
      if (value) {
        setValue(value);
        await updateRfq({
          rfqId: rfq.id,
          clientCustomNumber: value,
        });
        setIsEditing(false);
      } else {
        setValue(rfq.clientIdentifier);
        setIsEditing(false);
      }
    } else {
      setFormValue("clientCustomNumber", value);
      setIsEditing(false);
    }
  };

  return (
    <Container>
      <If isTrue={!isEditing}>
        <NotNullableRenderer
          value={
            <TextContainer>
              <FormattedMessage
                id="RFQ_NUMBER_WITH_SYMBOL"
                values={{ number: value }}
              />
            </TextContainer>
          }
        />
        <LinkLike>
          <EditOutlined
            onClick={() => {
              setValue(rfq?.clientIdentifier || "");
              setIsEditing(true);
            }}
            fontSize="small"
          />
        </LinkLike>
      </If>
      <If isTrue={isEditing}>
        <Row>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onClickEnter={() => onSave(value)}
            InputProps={{
              className: "max-h-8 flex-1 mx-1 bg-white",
            }}
          />
          <Icons>
            <If isTrue={isUpdating}>
              <Loader loading small />
            </If>
            <If isTrue={!isUpdating}>
              <Check
                className="cursor-pointer text-blue-500"
                onClick={() => onSave(value)}
              />
              <Close
                className="cursor-pointer text-blue-500"
                onClick={() => {
                  setValue(rfq?.clientIdentifier || "");
                  setIsEditing(false);
                }}
              />
            </If>
          </Icons>
        </Row>
      </If>
    </Container>
  );
};
