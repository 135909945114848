import { HeaderContainerWithSecondaryNavigation } from "@/common/components/header-container/HeaderContainer";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

import { useLocalSearch } from "@/common/hooks/useLocalSearch";
import { ImportExternalPaymentMethodsModal } from "./components/import-external-payment-methods/ImportExternalPaymentMethodsModal";
import { ImportExternalPaymentMethodsButton } from "./components/ImportExternalPaymentMethodsButton";
import { PaymentMethodsList } from "./components/PaymentMethodsList";
import { usePaymentMethods } from "./hooks/usePaymentMethods";

const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start w-[260px]
`;

const PaymentMethodsWithProvider = () => {
  const intl = useIntl();
  const { paymentMethods, loading } = usePaymentMethods();
  const { setSearchText, filteredItems } = useLocalSearch(paymentMethods);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "PAYMENT_METHODS" })}</title>
      </Helmet>
      <HeaderContainerWithSecondaryNavigation>
        <Filters>
          <SearchInput
            placeHolder={intl.$t({ id: "SEARCH_PAYMENT_METHODS" })}
            onChange={setSearchText}
          />
        </Filters>
        <HeaderRightPanel>
          <ImportExternalPaymentMethodsButton />
        </HeaderRightPanel>
      </HeaderContainerWithSecondaryNavigation>
      <PaymentMethodsList items={filteredItems} loading={loading} />
      <ImportExternalPaymentMethodsModal />
    </>
  );
};

export const PaymentMethods = () => {
  return (
    <NestedStepperProvider>
      <PaymentMethodsWithProvider />
    </NestedStepperProvider>
  );
};
