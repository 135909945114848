import { If } from "@/common/components/if/If";
import React, { FC, ReactNode } from "react";
import tw from "tailwind-styled-components";

type InfoHeaderItemProps = {
  title?: string | React.JSX.Element;
  children?: ReactNode;
  hideInMobile?: boolean;
  classes?: {
    container?: string;
    content?: string;
  };
  testId?: string;
};

const Container = tw.div<{ $hideInMobile?: boolean }>`
  ${({ $hideInMobile }) => ($hideInMobile ? "hidden" : "flex")} 
  md:flex 
  flex-col w-full gap-1 border-dotted md:border-none
  border-b-2 border-gray-300 pb-3 pt-3
`;
const Content = tw.div`
  grid grid-flow-col gap-2 text-sm font-normal w-full
`;

export const InfoHeaderItemTitle = tw.div`text-xs font-light`;

const Title = tw(
  InfoHeaderItemTitle,
)`pb-0.5 items-center grid-flow-col gap-1 min-h-[18px] justify-start hidden md:grid`;

export const InfoHeaderItem: FC<InfoHeaderItemProps> = ({
  title,
  hideInMobile = false,
  children,
  classes,
  testId,
}) => {
  return (
    <Container
      className={classes?.container}
      $hideInMobile={hideInMobile}
      data-testid={testId || "info-header"}
    >
      <If isTrue={title}>
        <Title>{title}</Title>
      </If>
      <Content className={classes?.content}>{children}</Content>
    </Container>
  );
};
