import { MAX_VENDOR_NUMBER } from "@/common/const";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { useVendors } from "../vendors/hooks/useVendors";
import { useVendorOptions, VendorOption } from "./useVendorOptions";
import {
  vendorLabelFormatter,
  VendorPickerCustomRender,
} from "./VendorPickerCustomRender";

type Props = {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  staticText?: boolean;
  autoSelectDependencies?: boolean;
  disableClearable?: boolean;
  filterResults?: (vendor: OrgPreferredVendorsFieldsFragment) => boolean;
  contactsFilter?: (
    contact: OrgPreferredVendorsFieldsFragment["contacts"][0],
  ) => boolean;
  formatFullLabel?: boolean;
};

export const VendorPickerControlled: FC<Props> = ({
  required,
  contactsFilter = () => true,
  name = "vendorId",
  autoSelectDependencies = true,
  filterResults,
  formatFullLabel,
  ...props
}) => {
  const {
    loading,
    vendorOptions,
    findOrderTypeByLocationId,
    findPaymentTermByLocationId,
    findCostCodeIdByLocationId,
  } = useVendorOptions({ contactsFilter });
  const { getVendorCode, shouldShowVendorCode } = useVendors();
  const intl = useIntl();
  const { watch, setValue, getValues } = useFormContext();
  const options = useMemo(
    () => vendorOptions.filter(filterResults ?? (() => true)),
    [filterResults, vendorOptions],
  );
  const { costCodes } = useProjectCostCodes();

  const vendorId = watch(name);

  useEffect(() => {
    if (options.length === 1) {
      setValue(name, options[0].id);
    }
  }, [name, setValue, options]);

  useEffect(() => {
    const vendor = options.find((v) => v.value === vendorId);
    const contacts = vendor?.contacts?.filter(contactsFilter);
    if (autoSelectDependencies && vendor && contacts?.length === 1) {
      setValue(
        "vendorContactIds",
        getValues("vendorContactIds") || contacts.map((c) => c.id),
      );
    }
  }, [
    vendorId,
    options,
    setValue,
    contactsFilter,
    autoSelectDependencies,
    getValues,
  ]);

  useEffect(() => {
    const orderTypeId = findOrderTypeByLocationId(vendorId);
    if (autoSelectDependencies && orderTypeId) {
      setValue("orderTypeId", orderTypeId);
    }
  }, [autoSelectDependencies, findOrderTypeByLocationId, setValue, vendorId]);

  useEffect(() => {
    const costCodeId = findCostCodeIdByLocationId(vendorId);
    if (
      autoSelectDependencies &&
      costCodes.find(
        (projectScopedCostCode) => projectScopedCostCode.id === costCodeId,
      ) &&
      !getValues("costCodeId")
    ) {
      setValue("costCodeId", costCodeId);
    }
  }, [
    autoSelectDependencies,
    costCodes,
    findCostCodeIdByLocationId,
    getValues,
    setValue,
    vendorId,
  ]);

  useEffect(() => {
    const paymentTerm = findPaymentTermByLocationId(vendorId);
    if (autoSelectDependencies) {
      setValue("paymentTerm", paymentTerm ?? 30);
    }
  }, [autoSelectDependencies, findPaymentTermByLocationId, setValue, vendorId]);

  const getLabel = useCallback(
    (o: VendorOption) => {
      if (formatFullLabel) {
        return vendorLabelFormatter(
          o.sellerOrgLocation,
          o.contacts.filter(contactsFilter),
          {
            vendorCode: getVendorCode(o),
          },
        );
      }

      return o.name;
    },
    [contactsFilter, formatFullLabel, getVendorCode],
  );

  return (
    <SelectControlled
      name={name}
      options={options}
      getLabel={getLabel}
      getValue={(option) => option.value as string}
      placeholder={intl.$t({ id: "VENDOR" })}
      loading={loading}
      rules={{
        required,
      }}
      customRender={(item) =>
        VendorPickerCustomRender(item, {
          filter: contactsFilter,
          shouldShowVendorCode,
        })
      }
      limitResults={MAX_VENDOR_NUMBER}
      {...props}
    />
  );
};
