import { If } from "@/common/components/if/If";
import { FC } from "react";
import {
  FooterCell,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterPropsWithTaxes,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";

export const ReleaseEmptyFooter: FC<FooterPropsWithTaxes> = ({
  Wrapper,
  includeAdditionalCharges,
}) => (
  <FooterCol>
    <Wrapper className="flex-col">
      <FooterHeader />
      <If isTrue={includeAdditionalCharges}>
        <FooterCell />
      </If>
      <FooterCell />
      <FooterCell />
      <FooterDashedLine />
      <FooterTotal />
    </Wrapper>
  </FooterCol>
);
