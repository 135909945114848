import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  InvoiceFieldsFragment,
  InvoiceToExportFieldsFragment,
  InvoiceValidationFieldsFragment,
  SourceSystem,
  useInvoiceValidationQuery,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLinkInvoice } from "../hooks/useLinkInvoice";
import { useValidateInvoice } from "../hooks/useValidateInvoice";
import { useExportInvoice } from "./ExportInvoiceProvider";
import { useInvoicesByIds } from "./InvoicesByIdsProvider";
type ProviderContextType = {
  linkInvoice: () => Promise<boolean | undefined | string>;
  invoiceValidation?: InvoiceValidationFieldsFragment;
  refetchInvoiceValidation: () => void;
  loading: boolean;
  poNumber: string | null;
  setPoNumber: (poNumber: string) => void;
};

type Props = {
  children: React.ReactNode;
  invoice: InvoiceToExportFieldsFragment | InvoiceFieldsFragment;
  skip?: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  linkInvoice: NoFunctionBooleanPromise,
  invoiceValidation: undefined,
  refetchInvoiceValidation: NoFunctionBooleanPromise,
  loading: false,
  poNumber: "",
  setPoNumber: NoFunction,
});

export const InvoiceValidationProvider: FC<Props> = ({
  children,
  invoice,
  skip,
}) => {
  const { linkInvoice: linkInvoiceMutation } = useLinkInvoice();
  const { connectedSourceSystem } = useOrgSettings();
  const { setValidatedInvoice } = useInvoicesByIds();
  const { clearErrors } = useExportInvoice();
  const { isValidInvoice } = useValidateInvoice();

  const [invoiceValidation, setInvoiceValidation] =
    useState<InvoiceValidationFieldsFragment>();
  const [poNumber, setPoNumber] = useState<string | null>(null);

  useEffect(() => {
    if (poNumber) {
      clearErrors();
    }
  }, [poNumber, clearErrors]);

  const { data, error, refetch, loading } = useInvoiceValidationQuery({
    variables: {
      input: {
        sourceSystem: connectedSourceSystem || SourceSystem.Foundation,
        invoiceId: invoice.id,
        poNumber:
          poNumber === null
            ? invoice.release?.poNumber || invoice.poNumber
            : poNumber,
      },
    },
    skip: !invoice.release || !connectedSourceSystem || skip,
    fetchPolicy: "cache-and-network",
  });
  useErrorEffect(error);

  useEffect(() => {
    if (data?.invoiceValidation || !loading) {
      const validated = isValidInvoice(data?.invoiceValidation);
      setValidatedInvoice({
        id: invoice.id,
        loading: false,
        validated,
      });
      setInvoiceValidation(data?.invoiceValidation);
    }
  }, [
    data?.invoiceValidation,
    loading,
    invoice.id,
    setValidatedInvoice,
    isValidInvoice,
  ]);

  useEffect(() => {
    if (loading) {
      setValidatedInvoice({
        id: invoice.id,
        loading: true,
        validated: isValidInvoice(invoiceValidation),
      });
    }
  }, [
    loading,
    invoiceValidation,
    invoice.id,
    setValidatedInvoice,
    isValidInvoice,
  ]);

  const linkInvoice = async () => {
    if (invoice && connectedSourceSystem) {
      return await linkInvoiceMutation({
        invoiceId: invoice?.id,
        sourceSystem: connectedSourceSystem,
      });
    }
  };

  const refetchInvoiceValidation = useCallback(async () => {
    if (invoice.release && connectedSourceSystem && !skip) {
      return await refetch();
    }
  }, [connectedSourceSystem, invoice.release, refetch, skip]);

  return (
    <ProviderContext.Provider
      value={{
        linkInvoice,
        invoiceValidation,
        refetchInvoiceValidation,
        loading,
        poNumber,
        setPoNumber,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceValidation = (): ProviderContextType =>
  useContext(ProviderContext);
