import { LocalPaginationProvider } from "@/common/components/pagination-local/LocalPaginationProvider";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { Switch } from "@/common/components/switch/Switch";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalEquipmentItems } from "../../../hooks/useExternalEquipmentItems";
import { useImportEquipmentItemsStore } from "../../../stores/useImportEquipmentItemsStore";
import { useImportExternalEquipmentItemsTableConfiguration } from "./ImportExternalEquipmentItemsTable.configuration";

const SearchBarTitleComponent = tw.div`flex flex-col pr-2`;
const ToggleText = tw.div`flex flex-row font-normal text-sm items-center mt-1`;

const ImportExternalEquipmentItemsTableWithProviders = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const {
    sourceSystem,
    selectedExternalEquipmentItems,
    totalExternalEquipmentItemsCount,
    setSelectedExternalEquipmentItems,
    setTotalExternalEquipmentItemsCount,
    importAllExternalItems,
    setImportAllExternalItems,
  } = useImportEquipmentItemsStore();
  const { externalEquipmentItems, loadingExternalEquipmentItems } =
    useExternalEquipmentItems(connectedSourceSystem?.system);
  const configuration = useImportExternalEquipmentItemsTableConfiguration(
    selectedExternalEquipmentItems,
  );

  useEffect(() => {
    setTotalExternalEquipmentItemsCount(externalEquipmentItems.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalEquipmentItems.length]);

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      items={externalEquipmentItems.map((equipmentItem) => ({
        ...equipmentItem,
        id: equipmentItem.externalId,
      }))}
      loadingItems={loadingExternalEquipmentItems}
      searchBarTitle={
        <SearchBarTitleComponent>
          <FormattedMessage id="SELECT_EQUIPMENT_ITEMS_TO_IMPORT" />
          <ToggleText>
            <FormattedMessage
              id="IMPORT_ALL_EQUIPMENT_ITEMS_FROM_SYSTEM"
              values={{
                system: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
              }}
            />
            <Switch
              className="ml-2"
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              value={importAllExternalItems}
              onChange={(value) => {
                setImportAllExternalItems(value);
                setSelectedExternalEquipmentItems(
                  value ? externalEquipmentItems.map((i) => i.externalId) : [],
                );
              }}
            />
          </ToggleText>
        </SearchBarTitleComponent>
      }
      searchKeys={["code", "name"]}
      itemSelection={{
        type: "multiple",
        selectedItemIds: selectedExternalEquipmentItems,
        setSelectedItemIds: (ids: string[]) => {
          setImportAllExternalItems(
            ids.length === totalExternalEquipmentItemsCount,
          );
          setSelectedExternalEquipmentItems(ids);
        },
      }}
    />
  );
};

export const ImportExternalEquipmentItemsTable: FC = () => {
  return (
    <LocalPaginationProvider>
      <ImportExternalEquipmentItemsTableWithProviders />
    </LocalPaginationProvider>
  );
};
