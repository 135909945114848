import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { routes } from "@/config/routes";
import { useNoteDocument } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { ArchiveRfqButton } from "@/contractor/pages/home/rfq-master-catalog-import/components/buttons/ArchiveRfqButton";
import { useRfq } from "@/contractor/pages/home/rfq/hooks/useRfq";
import {
  RfqFieldsFragment,
  RfqStatus,
  RfqVendorsDocument,
  useSubmitRfqMutation,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useRfqFromNote } from "../../hooks/useRfqFromNote.js";
import { CreateRfqFromNoteFormValues } from "./CreateRfqFromNoteForm";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

export const CreateRfqFromNoteFooter: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setError } = useGlobalError();
  const { rfq } = useRfq();
  const {
    syncCreateRfqFromNote,
    syncUpdateRfqFromNote,
    validateSpreadsheet,
    loading,
  } = useRfqFromNote();
  const { noteDocument } = useNoteDocument();
  const { openDialog } = useDialog();
  const intl = useIntl();

  const { reset, handleSubmit } = useFormContext<CreateRfqFromNoteFormValues>();

  const [submitRfqMutation, { loading: isSubmitting }] = useSubmitRfqMutation({
    errorPolicy: "all",
  });
  const submitRfq = useCallback(
    async (rfqId: string) => {
      try {
        const { data, errors } = await submitRfqMutation({
          variables: {
            input: {
              rfqId,
            },
          },
          refetchQueries: [
            { query: RfqVendorsDocument, variables: { id: rfqId } },
          ],
        });
        if (!errors && data?.submitRfq?.id) {
          navigate(generatePath(routes.rfqById, { id: data.submitRfq.id }));
        }
        setError(errors);
        return !errors;
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [submitRfqMutation, navigate, setError],
  );

  const handleSaveRfqFromNote = useCallback(async () => {
    if (!(await validateSpreadsheet())) {
      return;
    }
    await handleSubmit(() => {
      let result: RfqFieldsFragment | null | undefined;
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "SEND" }),
        includeWarningIcon: true,
        title: intl.$t(
          { id: "RFQ_SEND_TO_VENDORS_TITLE" },
          { number: rfq?.clientIdentifier },
        ),
        text: intl.$t({ id: "RFQ_SEND_TO_VENDORS_TEXT" }),
        handleConfirm: async () => {
          if (id) {
            result = await syncUpdateRfqFromNote();
          } else {
            result = await syncCreateRfqFromNote();
          }
          if (!result) {
            return;
          }
          submitRfq(result.id);
        },
      });
    })();
  }, [
    validateSpreadsheet,
    handleSubmit,
    id,
    syncUpdateRfqFromNote,
    syncCreateRfqFromNote,
    openDialog,
    submitRfq,
    intl,
    rfq?.clientIdentifier,
  ]);

  const saveRfq = useCallback(async () => {
    if (id) {
      await syncUpdateRfqFromNote();
    } else {
      const result = await syncCreateRfqFromNote();
      if (result) {
        navigate(
          generatePath(routes.editRfqFromNote, {
            id: result.id,
            noteDocumentId: noteDocument?.id,
          }),
        );
      }
    }
  }, [
    syncUpdateRfqFromNote,
    syncCreateRfqFromNote,
    id,
    navigate,
    noteDocument?.id,
  ]);

  const onCancel = useCallback(() => {
    if (rfq) {
      navigate(
        generatePath(routes.rfqById, {
          id: rfq.id,
        }),
      );
    } else {
      reset();
      navigate(routes.quotes);
    }
  }, [navigate, rfq, reset]);

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <ArchiveRfqButton />
        <OutlinedButton className="py-0" onClick={onCancel}>
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <If isTrue={!rfq || rfq?.status === RfqStatus.Draft}>
          <OutlinedButton onClick={saveRfq} loading={loading}>
            <FormattedMessage id="SAVE" />
          </OutlinedButton>
        </If>
        <PrimaryButton
          onClick={handleSaveRfqFromNote}
          testId="rfq-send-to-vendors-button"
          loading={isSubmitting}
        >
          <FormattedMessage id="RFQ_SEND_TO_VENDORS" />
        </PrimaryButton>
      </ButtonContainer>
    </FloatingFooterStyled>
  );
};
