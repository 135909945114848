import Handsontable from "handsontable";
import { ColumnSettings } from "handsontable/settings";
import { COLUMN_TYPE } from "../enums/columnType";

export const getPhysicalColumnIndex = (
  hotInstance: Handsontable | null | undefined,
  column: COLUMN_TYPE,
): number => {
  const columns = hotInstance?.getSettings().columns;
  return (
    hotInstance?.toPhysicalColumn(
      (columns as ColumnSettings[]).findIndex((c) => {
        return c.columnType === column;
      }),
    ) ?? -1
  );
};
