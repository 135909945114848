import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateInvoiceInput,
  UpdateReceiptMutation,
  useUpdateReceiptMutation,
} from "@/generated/graphql";

export const useUpdateReceipt = () => {
  const [updateReceiptMutation, { loading }] = useUpdateReceiptMutation();
  const { setError } = useGlobalError();

  const updateReceipt = async (
    input: UpdateInvoiceInput,
    includeDocuments: boolean = false,
  ): Promise<UpdateReceiptMutation | null | undefined> => {
    try {
      const { data, errors } = await updateReceiptMutation({
        variables: {
          input,
          includeDocuments,
        },
      });
      setError(errors);
      return data;
    } catch (errors) {
      setError(errors);
      return null;
    }
  };

  return { updateReceipt, loading };
};
