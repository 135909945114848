import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { AnimatedLoading } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";

import {
  PaymentMethodsDocument,
  useImportPaymentMethodsMutation,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { ImportExternalPaymentMethodsImportResults } from "../components/import-external-payment-methods/steps/ImportExternalPaymentMethodsResults";
import { ImportExternalPaymentMethodsTable } from "../components/import-external-payment-methods/steps/ImportExternalPaymentMethodsTable";
import { useImportPaymentMethodsStore } from "../stores/useImportPaymentMethodsStore";

export const useImportExternalPaymentMethodsModal = (): WizardModalPage[] => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();
  const {
    modalOpened,
    sourceSystem,
    selectedExternalPaymentMethods,
    totalExternalPaymentMethodsCount,
    setImportedPaymentMethods,
    closeModal,
  } = useImportPaymentMethodsStore();

  useEffect(() => {
    if (!modalOpened) {
      setNestedStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpened]);

  const [importPaymentMethodsMutation] = useImportPaymentMethodsMutation();
  const importSelectedPaymentMethods = useCallback(async () => {
    try {
      if (sourceSystem) {
        moveToNextNestedStep();
        const { data, errors } = await importPaymentMethodsMutation({
          variables: {
            input: {
              externalBankAccountIds: selectedExternalPaymentMethods,
              sourceSystem,
            },
          },
          refetchQueries: [
            {
              query: PaymentMethodsDocument,
            },
          ],
        });
        if (errors) {
          moveToPreviousNestedStep();
          setError(errors);
        }
        if (data?.importPaymentMethods) {
          setImportedPaymentMethods(data.importPaymentMethods);
          moveToNextNestedStep();
        }
      }
    } catch (error) {
      moveToPreviousNestedStep();
      setError(error);
    }
  }, [
    sourceSystem,
    selectedExternalPaymentMethods,
    importPaymentMethodsMutation,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
    setError,
    setImportedPaymentMethods,
  ]);

  return [
    {
      title: null,
      pages: [
        {
          component: <ImportExternalPaymentMethodsTable />,
          footerButtonsConfig: [
            {
              type: "text",
              text: intl.$t(
                { id: "OUT_OF_SELECTED" },
                {
                  count: selectedExternalPaymentMethods.length,
                  total: totalExternalPaymentMethodsCount,
                },
              ),
              className: "flex mr-4 items-center",
              hidden:
                selectedExternalPaymentMethods.length === 0 &&
                totalExternalPaymentMethodsCount === 0,
            },
            {
              type: "outlined",
              onClick: closeModal,
              text: intl.$t({ id: "CLOSE" }),
              className: "flex-1 mr-2",
            },
            {
              type: "primary",
              onClick: importSelectedPaymentMethods,
              text: intl.$t({ id: "IMPORT" }),
              className: "flex-1",
              disabled: selectedExternalPaymentMethods.length === 0,
            },
          ],
        },
        {
          component: (
            <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
          ),
          hideFooter: true,
          classNames: {
            wrapper: "mb-0",
          },
        },
        {
          component: (
            <CompletedStep text={intl.$t({ id: "IMPORT_COMPLETED" })} />
          ),
          onModalClose: moveToNextNestedStep,
          hideFooter: true,
          classNames: {
            wrapper: "mb-0",
          },
        },
        {
          component: <ImportExternalPaymentMethodsImportResults />,
          footerButtonsConfig: [
            {
              type: "outlined",
              onClick: closeModal,
              text: intl.$t({ id: "CLOSE" }),
              className: "w-60",
            },
          ],
        },
      ],
    },
  ];
};
