import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useExpandableFooterRef } from "../../../../../../../../../common/components/footer/hooks/useExpandableFooterRef";
import { If } from "../../../../../../../../../common/components/if/If";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";
import { InvoiceItemsPanel } from "./InvoiceItemsPanel";
import { InvoicePanel } from "./InvoicePanel";
import { ReleasePanel } from "./ReleasePanel";
import { ReleaseUninvoicedPanel } from "./ReleaseUninvoicedPanel";

const Container = tw.div`w-full bg-white grid grid-cols-[calc(60%-6px)_calc(40%+6px)]`;

type Props = {
  form: UseFormReturn<InvoiceCreateReleaseFormValues>;
  displayExtended: boolean;
};

export const InvoiceFooterExpanded: FC<Props> = ({ form, displayExtended }) => {
  const ref = useExpandableFooterRef();

  return (
    <Container ref={ref}>
      <ReleasePanel form={form} />
      <If isTrue={displayExtended}>
        <InvoiceItemsPanel />
        <ReleaseUninvoicedPanel />
      </If>
      <InvoicePanel />
    </Container>
  );
};
