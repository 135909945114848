import { ChevronRight } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceFooterState } from "../../../../../../../../common/components/invoices/invoice-details/types/InvoiceFooterState";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { ORDER_TOTAL_PANEL_WIDTH } from "./InvoiceFooterTotals";

const Placeholder = tw.div<{
  $orderTotalPanelWidth: number;
}>`flex flex-1 ${({ $orderTotalPanelWidth }) => `max-w-[calc(60%-${$orderTotalPanelWidth}px)]`}`;
const FooterBreadcrumbs = tw(Placeholder)`gap-2 items-center`;
const FooterBreadcrumb = tw.div<{
  $selected?: boolean;
}>`flex font-medium text-xs items-center gap-2
  ${({ $selected }) => (!$selected ? "text-gray-600" : "")}
`;
const Count = tw.div<{ $selected?: boolean }>`
  rounded-full border border-gray-600 p-2 text-2xs font-medium h-6 flex items-center
  ${({ $selected }) => ($selected ? "border-black text-black" : "text-gray-600")}
`;

export const InvoiceFooterBreadcrumbs = () => {
  const { footerState } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  if (
    !(
      footerState === InvoiceFooterState.MATCH_ORDER ||
      footerState === InvoiceFooterState.CREATE_ORDER ||
      footerState === InvoiceFooterState.IMPORT_ORDER ||
      matchedOrderViewState === MatchedOrderViewState.FULFILLED
    )
  ) {
    return <Placeholder $orderTotalPanelWidth={ORDER_TOTAL_PANEL_WIDTH} />;
  }

  return (
    <FooterBreadcrumbs $orderTotalPanelWidth={ORDER_TOTAL_PANEL_WIDTH}>
      <FooterBreadcrumb
        $selected={
          footerState === InvoiceFooterState.MATCH_ORDER ||
          footerState === InvoiceFooterState.IMPORT_ORDER ||
          footerState === InvoiceFooterState.CREATE_ORDER
        }
      >
        <Count
          $selected={
            footerState === InvoiceFooterState.MATCH_ORDER ||
            footerState === InvoiceFooterState.IMPORT_ORDER ||
            footerState === InvoiceFooterState.CREATE_ORDER
          }
        >
          1
        </Count>
        <FormattedMessage
          id={
            footerState === InvoiceFooterState.MATCH_ORDER
              ? "SELECT_ORDER"
              : "CREATE_ORDER"
          }
        />
      </FooterBreadcrumb>
      <ChevronRight color="disabled" />
      <FooterBreadcrumb
        $selected={matchedOrderViewState === MatchedOrderViewState.FULFILLED}
      >
        <Count
          $selected={matchedOrderViewState === MatchedOrderViewState.FULFILLED}
        >
          2
        </Count>
        <FormattedMessage id="VERIFY_INVOICE" />
      </FooterBreadcrumb>
    </FooterBreadcrumbs>
  );
};
