import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Identity } from "@/types/Identity";
import { NoFunction } from "@/types/NoFunction";

import { Fragment, ReactNode, useMemo } from "react";
import { CountType } from "../../../circle-counter/CountType";
import { GridCol } from "../../types/GridCol";
import { GridSortDirection } from "../../types/GridSortDirection";
import { RenderType } from "../../types/RenderType";
import {
  Header,
  PingEffect,
  SearchContainer,
  SearchStyled,
} from "./GridItem.styles";
import { GridSortContainer } from "./components/GridSortContainer";

export type GroupFunctionType<T extends Identity> = (
  category: CategoryState<T>,
  readonly?: boolean,
) => ReactNode;

type Props<T extends Identity, TAdditional> = {
  columns: Array<GridCol<T, TAdditional>>;
  item?: T;
  index?: number;
  readonly?: boolean;
  readonlyFn?: (
    additionalItem: TAdditional | undefined,
    item?: T | undefined,
  ) => boolean;
  error?: boolean;
  hidden?: boolean;
  renderType?: RenderType;
  category?: CategoryState<T>;
  className?: string;
  count?: CountType;
  visible?: boolean;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
  activeSearchColumn?: GridCol<T, TAdditional>;
  setActiveSearchColumn?: (column: GridCol<T, TAdditional>) => void;
  setActiveSortColumn?: (column: GridCol<T, TAdditional>) => void;
  rowError?: boolean;
  sortDirection?: GridSortDirection | null;
  sortedColumn?: GridCol<T, TAdditional>;
  disableSorting?: boolean;
  editing?: boolean;
  setEditing?: (editing: boolean) => void;
};

export const GridItem = <
  T extends Identity,
  TCategory extends CategoryState<T>,
  TAdditional,
>({
  item = {} as T,
  index,
  readonly = false,
  error = false,
  hidden = false,
  readonlyFn,
  columns,
  className,
  category = {} as TCategory,
  renderType = RenderType.Item,
  count = "",
  visible,
  editMode = false,
  setEditMode = NoFunction,
  setActiveSearchColumn,
  setActiveSortColumn,
  rowError = false,
  sortDirection,
  sortedColumn,
  disableSorting,
}: Props<T, TAdditional>) => {
  const elements = useMemo(() => {
    if (!visible && renderType === RenderType.Item) {
      return <PingEffect />;
    }
    return columns
      .filter((c) => !c.hidden)
      .map((column, key) => {
        const Wrapper = column.wrapper;
        if (!Wrapper) {
          return <span key={key} />;
        }

        const commonProps = {
          className: `group/item ${!column.skipAlignment ? `justify-${column.position || "start"}` : ""} ${className}`,
          $hidden: hidden,
        };

        switch (renderType) {
          case RenderType.Header: {
            const headerContent =
              column.header ?? column.headerFn?.(category, readonly);
            return (
              <Wrapper {...commonProps} key={key} $header>
                <Header
                  onClick={() =>
                    !editMode &&
                    !disableSorting &&
                    (column.sortItemFn || column.sortKey) &&
                    setActiveSortColumn?.(column)
                  }
                  $sortable={!!column.sortItemFn || !!column.sortKey}
                >
                  {headerContent}
                  {(!!column.sortItemFn || column.sortKey) && (
                    <GridSortContainer
                      column={column}
                      sortedColumn={sortedColumn}
                      sortDirection={sortDirection}
                      sortBy={headerContent}
                      disableSorting={disableSorting}
                    />
                  )}
                </Header>
                {!!column.searchItemFn && (
                  <SearchContainer>
                    <SearchStyled
                      onClick={() => setActiveSearchColumn?.(column)}
                    />
                  </SearchContainer>
                )}
              </Wrapper>
            );
          }
          case RenderType.Group:
          case RenderType.Subgroup:
            const groupContent =
              renderType === RenderType.Group
                ? column.group?.(category, readonly)
                : column.subgroup?.(category, readonly);
            if (!groupContent && column.hideGroupIfEmpty) {
              return null;
            }
            return (
              <Wrapper
                {...commonProps}
                key={key}
                $group={renderType === RenderType.Group}
                $subgroup={renderType === RenderType.Subgroup}
              >
                {groupContent}
              </Wrapper>
            );
          case RenderType.Details:
          case RenderType.Subdetails:
            const detailContent =
              renderType === RenderType.Details
                ? column.details?.({
                    item,
                    index,
                    readonly,
                    error,
                    rowError,
                    readonlyFn,
                    count,
                    editMode,
                    setEditMode,
                  })
                : column.subdetails?.({
                    item,
                    index,
                    readonly,
                    error,
                    rowError,
                    readonlyFn,
                    count,
                    editMode,
                    setEditMode,
                  });
            return detailContent ? (
              <Wrapper
                {...commonProps}
                key={key}
                $details={renderType === RenderType.Details}
                $subdetails={renderType === RenderType.Subdetails}
              >
                {detailContent}
              </Wrapper>
            ) : null;
          case RenderType.Footer: {
            return (
              <Wrapper {...commonProps} key={key}>
                {column.footer ?? null}
              </Wrapper>
            );
          }
          default: {
            const itemContent = column.item?.({
              item,
              index,
              readonly,
              error,
              rowError,
              readonlyFn,
              count,
              editMode,
              setEditMode,
            });
            return (
              <Wrapper {...commonProps} key={key} $item>
                {itemContent}
              </Wrapper>
            );
          }
        }
      });
  }, [
    columns,
    className,
    hidden,
    renderType,
    category,
    readonly,
    item,
    index,
    error,
    rowError,
    readonlyFn,
    count,
    editMode,
    setEditMode,
    sortedColumn,
    sortDirection,
    disableSorting,
    setActiveSortColumn,
    setActiveSearchColumn,
    visible,
  ]);
  return <Fragment key={count}>{elements}</Fragment>;
};
