import pako from "pako";

const isBase64 = (str: string): boolean => {
  try {
    return btoa(atob(str)) === str;
  } catch {
    return false;
  }
};

export const decompressQueryParam = (compressedData: string) => {
  if (isBase64(compressedData)) {
    const binaryString = atob(compressedData);
    const charArray = binaryString.split("").map((char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(charArray);
    const decompressed = pako.inflate(byteArray, { to: "string" });
    return JSON.parse(decompressed);
  } else {
    return {};
  }
};
