import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { FC } from "react";
import { CostType } from "../providers/CostTypesListProvider";
import { useCostTypesConfiguration } from "./CostTypes.config";

type Props = {
  loading?: boolean;
  items: CostType[];
  totalCount: number;
};

export const CostTypeList: FC<Props> = ({ loading, items, totalCount }) => {
  const { configuration } = useCostTypesConfiguration();

  return (
    <ListRenderer totalCount={totalCount} count={items.length} localPaginated>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            header: "top-[134px] bg-transparent",
          },
        }}
        items={items}
        loading={loading}
        emptyList={
          <NoResults
            isFiltered={false}
            translationKey="COST_TYPE_LIST_NO_ITEMS"
          />
        }
      />
    </ListRenderer>
  );
};
