import { CostCodeFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

const UNSPECIFIED_PHASE_CODE_ID = "-1";

export const useUnspecifiedPhaseCode = () => {
  const intl = useIntl();
  const unassignedPhaseCode: CostCodeFieldsFragment = useMemo(() => {
    return {
      id: UNSPECIFIED_PHASE_CODE_ID,
      code: "",
      description: intl.$t({ id: "UNSPECIFIED" }),
      deletedAt: null,
      inUse: false,
      mappings: [],
    };
  }, [intl]);

  return {
    unassignedPhaseCode,
  };
};
