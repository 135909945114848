import {
  CheckOutlined,
  CloseOutlined,
  ModeEditOutlined,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import { TextField, TextFieldProps } from "../textfield/TextField";

type Props = TextFieldProps & {
  disableSave?: boolean;
  onClickSave?: () => void;
  disableClose?: boolean;
  onClickClose?: () => void;
  showActions?: boolean;
  disabled?: boolean;
  onClickEdit?: () => void;
  actionClassName?: string;
  additionalActions?: React.JSX.Element;
  readonly?: boolean;
  staticText?: boolean;
  loading?: boolean;
};

const TextFieldStyled = tw(TextField)<Props>`
  ${({ disabled, staticText }: Props) => !disabled && !staticText && "bg-white"}
`;

export const TextfieldWithActions: FC<Props> = ({
  label,
  value,
  onChange,
  disableSave,
  onClickSave,
  disableClose,
  onClickClose,
  showActions,
  disabled,
  onClickEdit,
  InputProps,
  inputProps,
  actionClassName,
  required,
  additionalActions,
  staticText,
  loading,
  ...props
}) => {
  return (
    <TextFieldStyled
      size="small"
      label={label}
      required={required}
      disabled={disabled}
      staticText={staticText}
      value={value}
      onChange={onChange}
      classes={{
        root: `${disabled ? "bg-gray-100" : !staticText ? "bg-white" : ""} ${
          staticText && "bg-transparent"
        }`,
      }}
      inputProps={{
        ...inputProps,
        className: `${inputProps?.className} text-fill-black`,
      }}
      onClickEnter={onClickSave}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <>
            <If isTrue={disabled}>
              <LinkLike onClick={onClickEdit}>
                <ModeEditOutlined className={actionClassName} />
              </LinkLike>
            </If>
            <If isTrue={showActions && !loading}>
              <LinkLike disabled={disableSave} onClick={onClickSave}>
                <CheckOutlined className={actionClassName} />
              </LinkLike>
              <LinkLike disabled={disableClose} onClick={onClickClose}>
                <CloseOutlined className={actionClassName} />
              </LinkLike>
            </If>
            <If isTrue={loading}>
              <CircularProgress size={20} />
            </If>
            {additionalActions}
          </>
        ),
      }}
      {...props}
    />
  );
};
