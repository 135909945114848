import { ClearFiltersButton } from "@/common/components/clean-filters-button/ClearFiltersButton";
import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { StatusesFilter } from "@/common/components/status-filter/StatusesFilter";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { DeliverySlipStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipSequence } from "../../providers/DeliverySlipSequenceProvider";
import {
  DEFAULT_DELIVERY_SLIPS_FILTER,
  useDeliverySlips,
} from "../../providers/DeliverySlipsProvider";
import { ExportDeliverySlipsButton } from "../delivery-slip-verification/components/export-delivery-slips-button/ExportDeliverySlipsButton";
import { NewDeliverySlipButton } from "../new-delivery-slip/NewDeliverySlipButton";
import { DeliverySlipsAdditionalFilters } from "./DeliverySlipsAdditionalFilters";

const HeaderContainerStyled = tw(HeaderContainer)`
  grid lg:grid-flow-col justify-between gap-3 flex-wrap bg-transparent lg:bg-gray-100 pt-4 pb-4 top-[77px] xl:top-[69px]
`;
const Filters = tw.div`grid grid-flow-col gap-2 items-center`;
const ButtonContainer = tw.div`flex gap-2`;

enum DatesKeys {
  ScannedDate = "scannedDate",
  OrderDate = "orderDate",
}

export const DeliverySlipsHeader = () => {
  const { filter, setFilter, exportEnabled } = useDeliverySlips();
  const { sequenceActive } = useDeliverySlipSequence();

  const inSequenceFlow = useMemo(
    () => exportEnabled || sequenceActive,
    [exportEnabled, sequenceActive],
  );

  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });
  const intl = useIntl();

  const statuses = useMemo(() => {
    return Object.entries(DeliverySlipStatus).map(([, value]) => {
      return {
        value,
        name: intl.$t({ id: `DELIVERY_SLIP_STATUS_${value}` }),
      };
    });
  }, [intl]);

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.archived !== false) +
      Number(filter?.closedProjects !== false)
    );
  }, [filter?.archived, filter?.closedProjects]);

  const appliedDateFilters = useMemo(() => {
    return (
      Number(filter?.minCreatedAt != null || filter?.maxCreatedAt != null) +
      Number(
        filter?.minFulfillmentDate != null ||
          filter?.maxFulfillmentDate != null,
      )
    );
  }, [
    filter?.minCreatedAt,
    filter?.maxCreatedAt,
    filter?.minFulfillmentDate,
    filter?.maxFulfillmentDate,
  ]);

  const countNonDefaultFilters = useMemo(() => {
    return (
      appliedFilters +
      appliedDateFilters +
      Number(filter?.search != null && filter?.search !== "") +
      Number((filter?.statuses || []).length !== 0) +
      Number((filter?.projectIDs || []).length !== 0) +
      Number((filter?.sellerOrgLocationIds || []).length !== 0)
    );
  }, [
    appliedDateFilters,
    appliedFilters,
    filter?.projectIDs,
    filter?.search,
    filter?.sellerOrgLocationIds,
    filter?.statuses,
  ]);

  const resetFilterToDefault = useCallback(() => {
    setFilter(DEFAULT_DELIVERY_SLIPS_FILTER);
  }, [setFilter]);

  return (
    <HeaderContainerStyled>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          tooltip="DELIVERY_SLIPS_SEARCH_INFO"
          applyHeaderAccent
          clear={() => setFilter({ ...filter, search: undefined })}
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIDs: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIDs?.[0]}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              projectIDs: undefined,
            })
          }
        />
        <VendorsFilterSelector
          value={filter?.sellerOrgLocationIds?.[0]}
          onChange={(value) => {
            setFilter({
              ...filter,
              sellerOrgLocationIds: value ? [value] : undefined,
            });
          }}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              sellerOrgLocationIds: undefined,
            })
          }
        />
        <StatusesFilter
          options={statuses}
          selectedStatuses={filter?.statuses}
          onChange={(values) => {
            setFilter({
              ...filter,
              statuses: values,
            });
          }}
          applyHeaderAccent
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<DeliverySlipsAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ListDatesButton
          isDefault={appliedDateFilters === 0}
          appliedFilters={appliedDateFilters}
          hideDates
          dates={[
            {
              startDate: filter?.minCreatedAt,
              endDate: filter?.maxCreatedAt,
              key: DatesKeys.ScannedDate,
              label: intl.$t({ id: "SCANNED_DATE" }),
            },
            {
              startDate: filter?.minFulfillmentDate,
              endDate: filter?.maxFulfillmentDate,
              key: DatesKeys.OrderDate,
              label: intl.$t({ id: "ORDERED_DATE" }),
            },
          ]}
          clearValues={(value) => {
            if (value === DatesKeys.ScannedDate) {
              setFilter({
                ...filter,
                minCreatedAt: undefined,
                maxCreatedAt: undefined,
              });
            } else {
              setFilter({
                ...filter,
                minFulfillmentDate: undefined,
                maxFulfillmentDate: undefined,
              });
            }
          }}
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "SCANNED_DATE" }),
                  content: (
                    <DateRangePicker
                      onClose={onClose}
                      staticView
                      selectedValue={
                        filter?.minCreatedAt || filter?.maxCreatedAt
                          ? {
                              startDate: filter?.minCreatedAt
                                ? new Date(filter.minCreatedAt)
                                : new Date(),
                              endDate: filter?.maxCreatedAt
                                ? new Date(filter.maxCreatedAt)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minCreatedAt: dateRange.startDate?.getTime(),
                          maxCreatedAt: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: DatesKeys.ScannedDate,
                },
                {
                  label: intl.$t({ id: "ORDERED_DATE" }),
                  content: (
                    <DateRangePicker
                      onClose={onClose}
                      staticView
                      selectedValue={
                        filter?.minFulfillmentDate || filter?.maxFulfillmentDate
                          ? {
                              startDate: filter?.minFulfillmentDate
                                ? new Date(filter.minFulfillmentDate)
                                : new Date(),
                              endDate: filter?.maxFulfillmentDate
                                ? new Date(filter.maxFulfillmentDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minFulfillmentDate: dateRange.startDate?.getTime(),
                          maxFulfillmentDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: DatesKeys.OrderDate,
                },
              ]}
              activeTab={DatesKeys.ScannedDate}
            />
          )}
        </ListDatesButton>
        <ClearFiltersButton
          count={countNonDefaultFilters}
          onClick={resetFilterToDefault}
        />
      </Filters>
      <ButtonContainer>
        <ExportDeliverySlipsButton />
        <If isTrue={!inSequenceFlow}>
          <NewDeliverySlipButton />
        </If>
      </ButtonContainer>
    </HeaderContainerStyled>
  );
};
