import { Dialog } from "@/common/components/dialog/Dialog";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { ExternalGeneralLedgerAccountsProvider } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/general-ledger-account/ExternalGeneralLedgerAccountsProvider";
import { SourceSystem } from "@/generated/graphql";
import { Link } from "@mui/icons-material";
import { useCallback, useState } from "react";
import tw from "tailwind-styled-components";
import { CostCode } from "../providers/CostCodesListProvider";
import { LinkLedgerAccountWithProvider } from "./LinkLedgerAccountWithProvider";

const LedgerName = tw.span`ml-1 overflow-hidden text-ellipsis text-nowrap`;

interface CostCodeLinkLedgerAccountProps {
  costCode: CostCode;
  sourceSystem: SourceSystem;
}

export const CostCodeLinkLedgerAccount = ({
  costCode,
  sourceSystem,
}: CostCodeLinkLedgerAccountProps) => {
  const [showLinkDialog, setShowLinkDialog] = useState(false);

  const handleCloseDialog = useCallback(() => setShowLinkDialog(false), []);

  const handleOpenDialog = useCallback(() => setShowLinkDialog(true), []);

  const { hasFeature } = useIntegrationFeatureRequirement();

  if (
    !costCode.mappings.length &&
    !hasFeature({
      sourceSystem,
      feature: IntegrationFeature.GLAccountForNonConnectedCostCodes,
    })
  ) {
    return null;
  }

  return (
    <>
      <LinkLike onClick={handleOpenDialog}>
        <Link />
      </LinkLike>
      {costCode?.ledgerAccount ? (
        <LedgerName title={costCode.ledgerAccount.name}>
          {costCode.ledgerAccount.name}
        </LedgerName>
      ) : null}
      <Dialog
        hideTitle
        show={showLinkDialog}
        classes={{
          paper: "max-w-[90vw] w-[90vw] rounded-3xl max-h-[90vh] h-[90vh]",
        }}
        content={
          <ExternalGeneralLedgerAccountsProvider>
            <LinkLedgerAccountWithProvider
              costCode={costCode}
              sourceSystem={sourceSystem}
              handleClose={handleCloseDialog}
            />
          </ExternalGeneralLedgerAccountsProvider>
        }
      />
    </>
  );
};
