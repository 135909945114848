import { Dialog } from "@/common/components/dialog/Dialog";
import { If } from "@/common/components/if/If";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { routes } from "@/config/routes";
import {
  AssetContext,
  CreateDeliverySlipInput,
  TransactionKind,
  UpdateContractorReleaseInput,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useCreateDeliverySlip } from "../../../delivery-slips/hooks/useCreateDeliverySlip";
import { useAddDeliverySlipStore } from "../../../delivery-slips/store/useAddDeliverySlipStore";

const DialogContent = tw.div`
  grid gap-7 place-items-center w-full h-fit
`;

const AssetUploadContainer = tw.div`
  grid w-full h-fit
`;

const ReceiveReleaseDialogWithProvider: FC = () => {
  const intl = useIntl();
  const { assets } = useUploadAssets();
  const navigate = useNavigate();
  const { setError } = useGlobalError();
  const { setWarningAlert, setSuccessAlert } = useSnackbar();
  const { createDeliverySlip, loading } = useCreateDeliverySlip();
  const { addSlipVisible, slipRelease, setAddSlipVisible } =
    useAddDeliverySlipStore();

  const closeDialog = () => {
    setAddSlipVisible?.(false);
  };

  const [updateContractorReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const confirmRelease = useCallback(async () => {
    const input = {
      releaseId: slipRelease?.id || "",
      version: slipRelease?.version || 0,
      receive: true,
    } as UpdateContractorReleaseInput;
    try {
      const { data, errors } = await updateContractorReleaseMutation({
        variables: {
          input,
        },
      });
      setAddSlipVisible(false);
      setError(errors);
      return data?.updateContractorRelease;
    } catch (error) {
      setError(error);
      return null;
    }
  }, [
    setAddSlipVisible,
    setError,
    slipRelease?.id,
    slipRelease?.version,
    updateContractorReleaseMutation,
  ]);

  const addNewSlip = useCallback(async () => {
    if (!assets?.length) {
      setWarningAlert(<FormattedMessage id={`NO_DELIVERY_SLIP_PROVIDED`} />);
      return false;
    }

    const newSlipProps: CreateDeliverySlipInput = {
      orgLocationId: slipRelease?.project?.location?.id || "",
      projectId: slipRelease?.project?.id || "",
      releaseID: slipRelease?.id || "",
      assetUrls: assets.map((asset) => asset.url),
      async: false,
    };

    const newSlip = await createDeliverySlip(newSlipProps);

    if (!newSlip) {
      return;
    }

    if (newSlip?.deliveredReleaseItems?.length === 0) {
      setWarningAlert(<FormattedMessage id="NO_ITEMS_ON_DELIVERY_SLIP" />);
    } else {
      setSuccessAlert(
        <FormattedMessage
          id="NUMBER_OF_ITEMS_FOUND_ON_DELIVERY_SLIP"
          values={{ items: newSlip?.deliveredReleaseItems?.length }}
        />,
      );
    }

    setAddSlipVisible(false);
    navigate(
      generatePath(routes.deliverySlipReceiveOrder, {
        deliverySlipId: newSlip?.id,
      }),
    );
  }, [
    assets,
    createDeliverySlip,
    navigate,
    setAddSlipVisible,
    setSuccessAlert,
    setWarningAlert,
    slipRelease?.id,
    slipRelease?.project?.id,
    slipRelease?.project?.location?.id,
  ]);

  const handleConfirm = useCallback(() => {
    if (slipRelease?.type.requireDeliverySlip) {
      addNewSlip();
    } else {
      confirmRelease();
    }
  }, [addNewSlip, confirmRelease, slipRelease?.type.requireDeliverySlip]);

  const handleCustomButtonAction = useCallback(async () => {
    const newSlipProps: CreateDeliverySlipInput = {
      orgLocationId: slipRelease?.project?.location?.id || "",
      projectId: slipRelease?.project?.id || "",
      releaseID: slipRelease?.id || "",
      assetUrls: [],
      async: false,
    };

    const newSlip = await createDeliverySlip(newSlipProps);

    setAddSlipVisible(false);
    navigate(
      generatePath(routes.deliverySlipReceiveOrder, {
        deliverySlipId: newSlip?.id,
      }),
    );
  }, [
    createDeliverySlip,
    navigate,
    setAddSlipVisible,
    slipRelease?.id,
    slipRelease?.project?.id,
    slipRelease?.project?.location?.id,
  ]);

  const title = useMemo(() => {
    if (slipRelease?.type.requireDeliverySlip) {
      return <FormattedMessage id="ADD_DELIVERY_SLIP" />;
    }
    switch (slipRelease?.type.transactionKind) {
      case TransactionKind.Purchase:
        return <FormattedMessage id="RECEIVE_ORDER" />;
      case TransactionKind.Rental:
        return <FormattedMessage id="COMPLETE_RENTAL" />;
      case TransactionKind.Services:
        return <FormattedMessage id="COMPLETE_SERVICE" />;
    }
  }, [
    slipRelease?.type.requireDeliverySlip,
    slipRelease?.type.transactionKind,
  ]);

  const confirmLabel = useMemo(() => {
    if (slipRelease?.type.requireDeliverySlip) {
      return intl.$t({ id: "PROCEED" });
    }
    switch (slipRelease?.type.transactionKind) {
      case TransactionKind.Purchase:
        return intl.$t({ id: "RECEIVE" });
      case TransactionKind.Rental:
        return intl.$t({ id: "COMPLETE" });
      case TransactionKind.Services:
        return intl.$t({ id: "COMPLETE" });
      default:
        return intl.$t({ id: "PROCEED" });
    }
  }, [
    intl,
    slipRelease?.type.requireDeliverySlip,
    slipRelease?.type.transactionKind,
  ]);

  return (
    <Dialog
      title={title}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={confirmLabel}
      loading={loading || updating}
      customButtonText={
        slipRelease?.type.requireDeliverySlip
          ? intl.$t({ id: "PROCEED_WITHOUT_SLIP" })
          : undefined
      }
      handleConfirm={handleConfirm}
      handleCustomButtonAction={
        slipRelease?.type.requireDeliverySlip
          ? handleCustomButtonAction
          : undefined
      }
      maxWidth="xl"
      includeWarningIcon={!slipRelease?.type.requireDeliverySlip}
      handleCancel={closeDialog}
      show={addSlipVisible}
      content={
        <DialogContent>
          <If isTrue={slipRelease?.type.requireDeliverySlip}>
            <AssetUploadContainer>
              <UploadAsset
                testId="upload-delivery-slip"
                className="h-1/2 w-full"
                accept={{
                  [PDF_MIME_TYPE]: PDF_EXTENSIONS,
                  [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
                }}
              >
                <FormattedMessage id="DELIVERY_SLIP_SUPPORTED_FORMATS" />
              </UploadAsset>
            </AssetUploadContainer>
          </If>
          <If isTrue={!slipRelease?.type.requireDeliverySlip}>
            <If
              isTrue={
                slipRelease?.type.transactionKind === TransactionKind.Purchase
              }
            >
              <FormattedMessage id="MARK_ORDER_RECEIVED" />
            </If>
            <If
              isTrue={
                slipRelease?.type.transactionKind === TransactionKind.Rental
              }
            >
              <FormattedMessage id="MARK_RENTAL_COMPLETED" />
            </If>
            <If
              isTrue={
                slipRelease?.type.transactionKind === TransactionKind.Services
              }
            >
              <FormattedMessage id="MARK_SERVICE_COMPLETED" />
            </If>
          </If>
        </DialogContent>
      }
    />
  );
};

export const ReceiveReleaseDialog: FC = () => {
  return (
    <UploadAssetProvider context={AssetContext.DeliverySlip}>
      <ReceiveReleaseDialogWithProvider />
    </UploadAssetProvider>
  );
};
