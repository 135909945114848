import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import {
  InstructionInput,
  TaxType,
  UpdateChargeInput,
} from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

export type DeliverySlipReleaseFormValues = {
  businessLocationId: string;
  projectId: string;
  fulfillmentLocationId: string;
  vendorId: string;
  poNumber: string;
  orderDate: Date;
  orderTypeId: string;
  netAmount: number;
  paymentTerm: number | undefined;
  additionalCharges: UpdateChargeInput[];
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  total: string;
  taxType: TaxType | undefined;
  taxCodeId: string | undefined;
  instructions: InstructionInput | undefined;
  description?: string;
  taxVariance: string | undefined | null;
} & ExternalPOsQueryInputType;

type DeliverySlipReleaseForm = UseFormReturn<
  DeliverySlipReleaseFormValues,
  unknown,
  DeliverySlipReleaseFormValues
>;

export const DeliverySlipVerificationForm: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const deliverySlipReleaseForm: DeliverySlipReleaseForm =
    useForm<DeliverySlipReleaseFormValues>({
      defaultValues: {
        projectId: "",
        vendorId: "",
        poNumber: "",
        orderDate: undefined,
        netAmount: 0,
        paymentTerm: undefined,
        customTaxAmount: "0",
        additionalCharges: [],
        total: "0",
        orderTypeId: "",
        instructions: {
          text: "",
          assetUrls: [],
        },
        description: "",
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  return <FormProvider {...deliverySlipReleaseForm}>{children}</FormProvider>;
};
