import { If } from "@/common/components/if/If";
import { ImportExternalResultTitle } from "@/common/components/import-external-result-title/ImportExternalResultTitle";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportExternalTaxCodes } from "../../../providers/ImportExternalTaxCodesProvider";
import { useImportExternalTaxCodesTableConfiguration } from "./ImportExternalTaxCodesTable.configuration";

const Container = tw.div`flex flex-1 self-stretch flex-col`;

export const ImportExternalTaxCodesImportResults = () => {
  const intl = useIntl();
  const configuration = useImportExternalTaxCodesTableConfiguration();
  const { importedTaxCodes } = useImportExternalTaxCodes();

  return (
    <Container>
      <If isTrue={(importedTaxCodes?.created ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedTaxCodes?.created ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "CREATED_COST_CODES_COUNT" },
                { count: (importedTaxCodes?.created ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16"
        />
      </If>
      <If isTrue={(importedTaxCodes?.mapped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedTaxCodes?.mapped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "MATCHED_COST_CODES_COUNT" },
                { count: (importedTaxCodes?.mapped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
      <If isTrue={(importedTaxCodes?.skipped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedTaxCodes?.skipped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "SKIPPED_COST_CODES_COUNT" },
                { count: (importedTaxCodes?.skipped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
    </Container>
  );
};
