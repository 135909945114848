import { useEffect, useState } from "react";

type Props = {
  skip?: boolean;
};

type Dimensions = {
  width: number;
  height: number;
};

export const useWindowSize = (
  { skip }: Props = { skip: false },
): Dimensions => {
  const [windowSize, setWindowSize] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!skip) {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [skip]);
  return windowSize;
};
