import Decimal from "decimal.js";
import { useMemo } from "react";
import { isLumpSumItem } from "../../../../../../../common/utils/lumpSumItemUtils";
import { useRelease } from "../../../../release/providers/ReleaseProvider";

export const useSelectedInvoiceItems = () => {
  const { release } = useRelease();

  const selectedInvoiceItems = useMemo(
    () =>
      (release?.items ?? []).filter(
        (releaseItem) => (releaseItem.invoiceItems || [])?.length > 0,
      ),
    [release?.items],
  );

  const selectedInvoiceItemsSubtotal = useMemo(
    () =>
      selectedInvoiceItems.reduce((acc, releaseItem) => {
        const invoiceItem = releaseItem.invoiceItems?.[0];
        acc += new Decimal(invoiceItem?.quantity ?? 0)
          .mul(isLumpSumItem(releaseItem) ? 1 : (invoiceItem?.unitPrice ?? 0))
          .toNumber();
        return acc;
      }, 0),
    [selectedInvoiceItems],
  );

  const selectedInvoiceItemsCharges = useMemo(() => {
    if (!selectedInvoiceItemsSubtotal || !release?.additionalCharges.length) {
      return 0;
    }
    return new Decimal(selectedInvoiceItemsSubtotal)
      .div(release?.netAmount || 1)
      .mul(
        (release?.additionalCharges ?? []).reduce(
          (acc, charge) => acc.plus(charge.amount),
          new Decimal(0),
        ),
      )
      .toNumber();
  }, [
    release?.netAmount,
    release?.additionalCharges,
    selectedInvoiceItemsSubtotal,
  ]);

  const selectedInvoiceItemsSalesTax = useMemo(() => {
    if (
      !selectedInvoiceItemsSubtotal ||
      release?.taxAmount === "0" ||
      !release?.taxAmount
    ) {
      return 0;
    }
    return new Decimal(selectedInvoiceItemsSubtotal)
      .div(release?.netAmount || 1)
      .mul(release?.taxAmount ?? 1)
      .toNumber();
  }, [release?.netAmount, release?.taxAmount, selectedInvoiceItemsSubtotal]);

  const selectedInvoiceItemsTotal = useMemo(
    () =>
      new Decimal(selectedInvoiceItemsSubtotal)
        .plus(selectedInvoiceItemsSalesTax)
        .plus(selectedInvoiceItemsCharges),
    [
      selectedInvoiceItemsSubtotal,
      selectedInvoiceItemsSalesTax,
      selectedInvoiceItemsCharges,
    ],
  );

  return {
    selectedInvoiceItems,
    selectedInvoiceItemsSubtotal,
    selectedInvoiceItemsCharges,
    selectedInvoiceItemsSalesTax,
    selectedInvoiceItemsTotal,
  };
};
