import { useState } from "react";

export const useLoadingAction = () => {
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState<string[]>([]);

  const asyncAction = async (
    action?: () => Promise<void> | void,
    ids?: string[],
  ) => {
    setLoading(true);
    if (ids) {
      setIds(ids);
    }
    if (action) {
      await action();
    }
    setLoading(false);
    setIds([]);
  };

  return {
    loading,
    asyncAction,
    ids,
  };
};
