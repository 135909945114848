import { usePagination } from "@/common/components/pagination/PaginationProvider";
import {
  QueryExternalProjectsFilter,
  SourceSystem,
  useExternalProjectsQuery,
} from "@/generated/graphql";
import { useEffect } from "react";

export const useAgaveExternalProjectsWithPagination = (
  sourceSystem: SourceSystem | null | undefined,
  filter?: QueryExternalProjectsFilter,
) => {
  const { paginationArgs, setPageInfo, setPage } = usePagination();
  const { data, loading, error, refetch } = useExternalProjectsQuery({
    variables: {
      ...paginationArgs,
      filter,
      sourceSystem: sourceSystem || SourceSystem.Foundation,
    },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first",
    skip: !sourceSystem,
  });

  useEffect(() => {
    if (data?.externalProjects?.pageInfo) {
      setPageInfo({ ...data.externalProjects.pageInfo });
    }
  }, [data, setPageInfo]);

  return {
    externalProjects:
      data?.externalProjects.edges.map((edge) => edge.node) || [],
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.externalProjects.totalCount || 0,
    setPage,
    refetch,
  };
};
