import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import { useTaxCodesSummaryQuery } from "@/generated/graphql";

export const useTaxCodeSummaries = () => {
  const { viewer } = useUser();
  const { data, loading, error } = useTaxCodesSummaryQuery({
    skip: !viewer?.authId,
  });
  useErrorEffect(error);

  return {
    taxCodes: data?.viewer?.org.taxCodes || [],
    loading,
  };
};
