import { Switch } from "@/common/components/switch/Switch";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceStatus,
  ReceiptFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  invoice:
    | DistributorInvoiceFieldsFragment
    | InvoiceFieldsFragment
    | ReceiptFieldsFragment
    | null;
  onChange: (value: boolean) => void;
};

const Container = tw.div`flex gap-2 pr-1.5 py-1.5 pl-4 rounded-4xl items-center font-normal bg-blue-800`;
const Text = tw.span`text-white font-normal`;

export const InvoicePaidToggle: FC<Props> = ({ invoice, onChange }) => {
  const intl = useIntl();

  const [isPaid, setIsPaid] = useState(invoice?.status === InvoiceStatus.Paid);

  const togglePaid = useCallback(
    (value: boolean) => {
      setIsPaid(value);
      onChange(value);
    },
    [onChange],
  );

  return (
    <Container>
      <FormattedMessage id={"INVOICE_PAID"} tagName={Text} />
      <Switch
        width={60}
        offLabel={intl.$t({ id: "NO" })}
        onLabel={intl.$t({ id: "YES" })}
        onChange={togglePaid}
        value={isPaid}
      />
    </Container>
  );
};
