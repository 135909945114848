import { StyledReadonly } from "@/common/components/styled-readonly/StyledReadonly";
import { StyledStaticText } from "@/common/components/styled-static-text/StyledStaticText";
import { useMemo } from "react";
import { Select, SelectProps } from "./Select";

type Size = "xs" | "md";

type Props<T> = SelectProps<T> & { centered?: boolean; size?: Size };

export const StyledSelect = <T,>({ size, centered, ...props }: Props<T>) => {
  const {
    disabled,
    options,
    value,
    getValue,
    getLabel,
    staticText,
    className,
    inputProps,
    error,
  } = props;
  const readonlyValue = useMemo(() => {
    if (disabled || staticText) {
      const val = options.find((opt) => getValue(opt) === value);
      if (val) {
        return getLabel(val);
      }
    }
  }, [disabled, getLabel, getValue, options, staticText, value]);

  if (staticText) {
    return (
      <StyledStaticText className={className} title={readonlyValue}>
        {readonlyValue}
      </StyledStaticText>
    );
  }

  if (disabled) {
    return (
      <StyledReadonly title={readonlyValue} $error={error}>
        {readonlyValue}
      </StyledReadonly>
    );
  }

  return (
    <Select<T>
      {...props}
      className={`text-center text-xs md:text-sm ${className}`}
      inputProps={{
        className: `text-sm px-2 truncate ${centered && "text-center"} ${
          size === "xs" ? "py-1.5" : "py-2"
        }`,
        ...inputProps,
      }}
      classes={{
        inputRoot: size === "md" ? "py-2" : "py-1",
      }}
      InputLabelProps={{
        className: size === "xs" ? `text-sm ${value ? "top-1" : ""}` : "",
      }}
    />
  );
};
