import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useExternalPoLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";
import { useInvoiceImportExternalPO } from "./useInvoiceImportExternalPO";

export const useLazyLoadExternalPo = () => {
  const { invoice } = useInvoiceVerification();
  const { importExternalPO } = useInvoiceImportExternalPO();
  const { connectedSourceSystem } = useOrgSettings();
  const [loadExternalPo, { loading }] = useExternalPoLazyQuery();
  const { setAutoMatching, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const { setError } = useGlobalError();

  const fetchExternalPo = useCallback(async () => {
    if (!connectedSourceSystem) {
      setAutoMatching(false);
      return;
    }
    try {
      const { data } = await loadExternalPo({
        variables: {
          input: {
            number: invoice?.poNumber,
            sourceSystem: connectedSourceSystem,
          },
        },
      });
      if (data?.externalPO) {
        importExternalPO(data?.externalPO);
      } else {
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        setAutoMatching(false);
      }
    } catch (errors) {
      setError(errors);
      setAutoMatching(false);
    }
  }, [
    connectedSourceSystem,
    setAutoMatching,
    loadExternalPo,
    invoice?.poNumber,
    importExternalPO,
    setMatchedOrderViewState,
    setError,
  ]);

  return {
    fetchExternalPo,
    loading,
  };
};
