import { downloadFile } from "@/common/utils/download-file/downloadFile";
import { ExportToCsvOptions } from "../types/ExportToCsvProps";

export const generateCsvData = ({
  data,
  headers = [],
}: Pick<ExportToCsvOptions, "data" | "headers">) => {
  return [
    headers.join(","),
    ...data.map((row) => {
      return row
        .map((cell: string | number | null | undefined) => {
          if (cell === "") {
            return "";
          }
          if (typeof cell === "string") {
            return `"${cell.replace(/"/g, '""')}"`;
          }
          if (cell === null || cell === undefined) {
            return "";
          }
          return cell;
        })
        .join(",");
    }),
  ].join("\n");
};

export const exportDataToCsv = ({
  data,
  fileName,
  headers = [],
}: ExportToCsvOptions) => {
  const csvData = generateCsvData({ data, headers });
  downloadFile({
    data: csvData,
    fileName,
    mimeType: "text/csv",
    extension: "csv",
  });
};
