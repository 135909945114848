import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Location,
  useBeforeUnload,
  useBlocker,
  useNavigate,
} from "react-router-dom";
import tw from "tailwind-styled-components";
import { useDialog } from "../components/dialog/DialogProvider";

type UsePreventUnsavedChanges = {
  hasChanges: boolean;
  onSave?: () => Promise<boolean>;
};

const Content = tw.div`flex flex-col items-center max-w-[800px]`;
const DialogTitle = tw.div`font-medium text-xl mb-4`;
const DialogText = tw.div`font-normal text-sm mb-6`;

const usePreventUnsavedChanges = ({
  hasChanges,
  onSave,
}: UsePreventUnsavedChanges) => {
  const { isVisible: dialogIsVisible, openDialog } = useDialog();
  const intl = useIntl();
  const navigate = useNavigate();

  const [nextLocation, setNextLocation] = useState<string | null>(null);

  const blockerFunction = useCallback(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: Location;
      nextLocation: Location;
    }) => {
      setNextLocation(nextLocation.pathname);

      return hasChanges && currentLocation.pathname !== nextLocation.pathname;
    },
    [hasChanges],
  );

  const blocker = useBlocker(blockerFunction);

  const beforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (hasChanges) {
        event.preventDefault();
      }

      return hasChanges;
    },
    [hasChanges],
  );

  useBeforeUnload(beforeUnload);

  const handleCancel = useCallback(() => {
    return blocker?.reset?.();
  }, [blocker]);

  const handleCustomButtonAction = useCallback(async () => {
    const successfulSave = await onSave?.();

    if (!successfulSave) {
      blocker?.reset?.();

      return false;
    }

    if (nextLocation) {
      blocker?.reset?.();

      return navigate(nextLocation);
    }
  }, [blocker, navigate, nextLocation, onSave]);

  const handleConfirm = useCallback(() => {
    return blocker?.proceed?.();
  }, [blocker]);

  const openModal = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      customButtonText: intl.$t({ id: "SAVE_CHANGES" }),
      confirmButtonText: intl.$t({ id: "LEAVE" }),
      includeWarningIcon: true,
      closeOnConfirm: true,
      closeOnCustom: true,
      handleCancel,
      handleCustomButtonAction,
      handleConfirm,
      content: (
        <Content>
          <DialogTitle>{intl.$t({ id: "UNSAVED_CHANGES_TITLE" })}</DialogTitle>
          <DialogText>
            {intl.$t({ id: "UNSAVED_CHANGES_DESCRIPTION" })}
          </DialogText>
        </Content>
      ),
      extraButtonMargin: false,
    });
  }, [openDialog, handleCancel, handleCustomButtonAction, handleConfirm, intl]);

  useEffect(() => {
    if (blocker?.state === "blocked" && !hasChanges) {
      blocker?.reset?.();
    }

    if (blocker.state === "blocked" && hasChanges && !dialogIsVisible) {
      openModal();
    }
  }, [blocker, hasChanges, dialogIsVisible, openModal]);
};

export default usePreventUnsavedChanges;
