import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

const Container = tw.div`
  grid grid-flow-col gap-1 pl-8 w-full justify-start
`;
const Label = tw.div`text-2xs`;
const Details = tw.div`text-2xs text-gray-600`;

type Props = {
  costType: string;
  costCode?: string;
  phaseCode?: string;
  zone?: string;
  className?: string;
};

export const LineItemCostInfo: FC<Props> = ({
  costType,
  costCode,
  phaseCode,
  zone,
  className,
}) => {
  const { settings } = useOrgSettings();

  return (
    <>
      <If isTrue={costCode}>
        <Container className={className}>
          <Label>
            <FormattedMessage id={"COST_CODE"} />:
          </Label>
          <Details>{costCode}</Details>
        </Container>
      </If>
      <If isTrue={phaseCode}>
        <Container className={className}>
          <Label>
            <FormattedMessage id={"PHASE_CODE"} />:
          </Label>
          <Details>{phaseCode}</Details>
        </Container>
      </If>
      <If isTrue={zone}>
        <Container className={className}>
          <Label>
            <FormattedMessage id={"ZONE"} />:
          </Label>
          <Details>{zone}</Details>
        </Container>
      </If>
      <If isTrue={costType && settings?.display?.itemCostTypes}>
        <Container className={className}>
          <Label>
            <FormattedMessage id={"COST_TYPE"} />:
          </Label>
          <Details>{costType}</Details>
        </Container>
      </If>
    </>
  );
};
