import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";

import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { Switch } from "@/common/components/switch/Switch";
import { useUser } from "@/common/providers/UserProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  Actions,
  HeaderContainerStyled,
  ProjectHeaderContent,
} from "@/contractor/pages/home/project/Project.styles";
import {
  ActionItemWrapper,
  ActionsContainer,
  ActionsWrapper,
} from "@/contractor/pages/home/project/components/project-items-filters/ProjectItemsFilters.styles";
import { useProjectZoneUtils } from "@/contractor/pages/home/project/hooks/useProjectZoneUtils";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useSpendingReport } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { SystemRole } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useProjectZones } from "../../../../hooks/useProjectZones";
import { SpendingReportCostCodesSelector } from "../spending-report-filters/SpendingReportCostCodesSelector";
import { SpendingReportGroupByZonesSwitch } from "../spending-report-filters/SpendingReportGroupByZonesSwitch";
import { SpendingReportTagsSelector } from "../spending-report-filters/SpendingReportTagsSelector";
import { SpendingReportVendorsSelector } from "../spending-report-filters/SpendingReportVendorsSelector";
import { SpendingReportZonesSelector } from "../spending-report-filters/SpendingReportZonesSelector";
import { BudgetExportButton } from "./BudgetExportButton";
import { BudgetPrintButton } from "./BudgetPrintButton";

export const SpendingReportHeader = () => {
  const {
    groupedByZones,
    showChart,
    setShowCart,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    costCodeOptions,
  } = useSpendingReport();
  const { project } = useProject();
  const { zoneOptions, tagOptions } = useProjectZoneUtils(project);
  const { hasPhaseCodes } = useOrgSettings();
  const viewState = useTableViewStore((state) => state.viewState);
  const intl = useIntl();
  const { viewer } = useUser();
  const isFMAdmin = useMemo(
    () => viewer?.systemRoles.includes(SystemRole.FmAdmin),
    [viewer],
  );
  const { zones } = useProjectZones();

  const includeZone = useMemo(() => {
    return zones?.some((zone) => zone.inUse);
  }, [zones]);

  return (
    <HeaderContainerStyled>
      <ProjectHeaderContent>
        <ActionsContainer className="ml-0">
          <ActionsWrapper>
            <If isTrue={viewState === TableViewState.materials}>
              <If isTrue={includeZone}>
                <ActionItemWrapper className="w-1/4">
                  <SpendingReportGroupByZonesSwitch />
                </ActionItemWrapper>
              </If>
              <If isTrue={includeZone}>
                <ActionItemWrapper>
                  <SpendingReportZonesSelector
                    options={zoneOptions}
                    disabled={!groupedByZones}
                  />
                </ActionItemWrapper>
              </If>
            </If>
            <If isTrue={!hasPhaseCodes}>
              <ActionItemWrapper>
                <SpendingReportCostCodesSelector options={costCodeOptions} />
              </ActionItemWrapper>
            </If>
            <ActionItemWrapper>
              <SpendingReportVendorsSelector />
            </ActionItemWrapper>
            <ActionItemWrapper>
              <SpendingReportTagsSelector options={tagOptions} />
            </ActionItemWrapper>
            <ActionItemWrapper className="justify-start">
              <ListDatesButton
                dates={[
                  {
                    startDate,
                    endDate,
                  },
                ]}
                clearValues={() => {
                  setStartDate(undefined);
                  setEndDate(undefined);
                }}
                className="min-w-44"
              >
                {(onClose) => (
                  <PopoverWithTabs
                    tabs={[
                      {
                        label: intl.$t({
                          id: "SPENDING_REPORT_DATES_INVOICE_DUE_DATE",
                        }),
                        content: (
                          <DateRangePicker
                            open
                            staticView
                            onClose={onClose}
                            selectedValue={
                              startDate || endDate
                                ? {
                                    startDate: startDate
                                      ? new Date(startDate)
                                      : new Date(),
                                    endDate: endDate
                                      ? new Date(endDate)
                                      : new Date(),
                                  }
                                : undefined
                            }
                            isUTC
                            onChange={(dateRange) => {
                              setStartDate(dateRange.startDate?.getTime());
                              setEndDate(dateRange.endDate?.getTime());
                            }}
                          />
                        ),
                        viewState: "startDate",
                      },
                    ]}
                    activeTab="startDate"
                  />
                )}
              </ListDatesButton>
            </ActionItemWrapper>
          </ActionsWrapper>
        </ActionsContainer>
        <Actions>
          <ButtonsContainer>
            <If isTrue={isFMAdmin}>
              <FormattedMessage id="DISPLAY_CHARTS" />
              <Switch
                value={showChart}
                onChange={setShowCart}
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
              />
            </If>
            <BudgetExportButton />
            <BudgetPrintButton />
          </ButtonsContainer>
        </Actions>
      </ProjectHeaderContent>
    </HeaderContainerStyled>
  );
};
