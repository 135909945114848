import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  BuyoutsDocument,
  OrgPreferredVendorsFieldsFragment,
  UomsDocument,
  UpdateContractorBuyoutInput,
  useCreateBuyoutMutation,
  useProjectPredictedPoNumberQuery,
} from "@/generated/graphql";
import { NoFunctionStringPromise } from "@/types/NoFunction";
import { FC, createContext, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSpreadsheetBuyoutItems } from "../../buyout/components/document/providers/hooks/useSpreadsheetBuyoutItems";
import { useBuyoutMutations } from "../../buyout/components/document/providers/useBuyoutMutations";
import { useContractorBuyout } from "../../buyout/providers/ContractorBuyoutProvider";
import { useQuoteDocument } from "../../common/quote-document/providers/QuoteDocumentProvider";
import { useSetCurrentProjectId } from "../../project/hooks/useSetCurrentProjectId";
import { CreateBuyoutFromQuoteFormValues } from "../components/buyout-from-quote/create-buyout-from-quote/components/CreateBuyoutFromQuoteForm";

type ProviderContextType = {
  syncCreateBuyoutFromQuote: (options?: {
    submit: boolean;
  }) => Promise<string | undefined>;
  syncUpdateBuyoutFromQuote: () => Promise<string | boolean>;
  creating: boolean;
  updating: boolean;
  vendors: OrgPreferredVendorsFieldsFragment[];
  loadingVendors: boolean;
  predictedPoNumber?: string | null;
  loadingPredictedPoNumber: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateBuyoutFromQuote: NoFunctionStringPromise,
  syncUpdateBuyoutFromQuote: NoFunctionStringPromise,
  creating: false,
  updating: false,
  vendors: [],
  loadingVendors: false,
  predictedPoNumber: "",
  loadingPredictedPoNumber: false,
});

export const BuyoutFromQuoteProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { quoteDocument } = useQuoteDocument();
  const { buyout } = useContractorBuyout();
  const { defaultOrderType } = useOrderTypeOptions();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const getSpreadsheetBuyoutItems = useSpreadsheetBuyoutItems();
  const { spreadsheetData, resetPreviousData, gotoInvalidRow } =
    useColumnMapper();
  const { setWarningAlert, setSuccessAlert } = useSnackbar();
  const { paginationArgs } = usePagination();
  const { vendors, loading: loadingVendors } = useVendors();
  const { hasPhaseCodes } = useOrgSettings();
  const { getValues, watch } =
    useFormContext<CreateBuyoutFromQuoteFormValues>();

  const projectId = watch("projectId");
  useSetCurrentProjectId(projectId);
  const poNumber = watch("poNumber");
  const { data: predictedPoNumberData, loading: loadingPredictedPoNumber } =
    useProjectPredictedPoNumberQuery({
      variables: {
        id: projectId,
      },
      skip: !projectId || !!poNumber,
      fetchPolicy: "no-cache",
    });

  const [createBuyout, { loading: creatingBuyout }] = useCreateBuyoutMutation();
  const syncCreateBuyoutFromQuote = async (options?: { submit: boolean }) => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return undefined;
    }

    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
      ])) ||
      !(await validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UnitPrice,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ]))
    ) {
      gotoInvalidRow();
      return undefined;
    }

    const { addedItems } = await getSpreadsheetBuyoutItems();

    if (addedItems.length > 0) {
      try {
        const values = getValues();
        const { data, errors } = await createBuyout({
          variables: {
            input: {
              projectId: values.projectId ?? "",
              vendorLocationId: values.vendorId,
              releaseTypeId: values.releaseTypeId ?? defaultOrderType?.id,
              poNumber: values.poNumber || undefined,
              description: values.description || "",
              items: addedItems,
              submit: options?.submit,
              taxRate: !values.taxCodeId
                ? values.taxRate ||
                  (values.clearCustomTaxAmount ||
                  values.customTaxAmount?.length === 0
                    ? "0"
                    : undefined)
                : undefined,
              customTaxAmount: values.taxCodeId
                ? undefined
                : values.clearCustomTaxAmount ||
                    values.customTaxAmount?.length === 0
                  ? undefined
                  : values.customTaxAmount,
              paymentTerm: values.paymentTerm,
              quoteDocumentId: quoteDocument?.id ?? "",
              mergeDuplicates: false,
              instructions: values.instructions,
              vendorContactIds: values.vendorContactIds,
              taxCodeId: values.taxCodeId,
              taxType: values.taxType,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
            {
              query: BuyoutsDocument,
              variables: {
                ...paginationArgs,
              },
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        return data?.createBuyout?.id;
      } catch (error) {
        setError(error);
        return undefined;
      }
    }

    return undefined;
  };

  const { updateContractorBuyout, loading: updating } = useBuyoutMutations();
  const updateBuyoutItems = async (input: UpdateContractorBuyoutInput) => {
    try {
      return await updateContractorBuyout(input);
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const syncUpdateBuyoutFromQuote = async () => {
    const values = getValues();
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
      ])) ||
      !(await validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UnitPrice,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ]))
    ) {
      gotoInvalidRow();
      return false;
    }

    const { addedItems, updates, removedItems } =
      await getSpreadsheetBuyoutItems();

    if (buyout) {
      const result = await updateBuyoutItems({
        buyoutId: buyout?.id || "",
        version: buyout?.version || 0,
        taxRate: !values.taxCodeId ? values.taxRate || undefined : undefined,
        customTaxAmount: !values.taxCodeId
          ? values.customTaxAmount || undefined
          : undefined,
        description: values.description,
        clearCustomTaxAmount: !values.customTaxAmount,
        poNumber: values.poNumber ?? undefined,
        vendorLocationId: values.vendorId,
        updates,
        addedItems,
        removedItems,
        instructions: values.instructions,
        additionalChargesAllowance: values.additionalChargesAllowance,
        taxCodeId: values.taxCodeId,
        taxType: values.taxType,
      });

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "BUYOUT_ITEMS_SAVED_SUCCESS" }));
      }
      return result;
    }
    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateBuyoutFromQuote,
        syncUpdateBuyoutFromQuote,
        creating: creatingBuyout,
        updating,
        vendors,
        loadingVendors,
        predictedPoNumber: predictedPoNumberData?.project?.predictedPoNumber,
        loadingPredictedPoNumber,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyoutFromQuote = (): ProviderContextType =>
  useContext(ProviderContext);
