import {
  ReleaseWithType,
  useOrderTypesConfig,
} from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { ReleaseBackorderDatePickerCustomActionBar } from "@/contractor/pages/home/release/pages/specify-details/components/date-picker-action-bars/ReleaseBackorderDatePickerCustomActionBar";
import { ReleaseDatePickerCustomActionBar } from "@/contractor/pages/home/release/pages/specify-details/components/date-picker-action-bars/ReleaseDatePickerCustomActionBar";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isRetroactiveRelease } from "../utils/isRetroactiveRelease";

const inputLabelProps = {
  classes: {
    shrink: "mt-1 text-base",
    root: "ml-1 truncate text-sm max-w-full",
  },
};

type Props = {
  date: number | undefined | Date | null;
  labels?: {
    customLabel?: string;
    defaultLabel?: string;
  };
  release:
    | (Pick<ReleaseFieldsFragment, "timeTBD"> & ReleaseWithType)
    | null
    | undefined;
};

export const useReleaseDatePickerConfig = ({
  date,
  labels,
  release,
}: Props) => {
  const retroactiveRelease = useMemo(() => isRetroactiveRelease(date), [date]);
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const intl = useIntl();

  const actionBar = useMemo(
    () =>
      retroactiveRelease
        ? ReleaseBackorderDatePickerCustomActionBar
        : ReleaseDatePickerCustomActionBar,
    [retroactiveRelease],
  );

  const labelProps = useMemo(() => {
    if (release?.timeTBD) {
      return {
        styling: {
          classes: {
            ...inputLabelProps.classes,
            root: `${inputLabelProps.classes.root} text-black`,
          },
        },
        label: intl.$t({ id: "HOLD_FOR_RELEASE" }),
      };
    }

    return {
      label:
        labels?.customLabel ??
        orderTypeConfig?.labels.datePicker ??
        labels?.defaultLabel,
      styling: inputLabelProps,
    };
  }, [
    release?.timeTBD,
    labels?.customLabel,
    labels?.defaultLabel,
    orderTypeConfig?.labels.datePicker,
    intl,
  ]);

  const value = useMemo(
    () => (release?.timeTBD ? null : date ? new Date(date) : null),
    [release?.timeTBD, date],
  );

  const disabled = useMemo(() => !!release?.timeTBD, [release?.timeTBD]);

  return { actionBar, labelProps, value, disabled };
};
