import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { QUERYSTRING } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useVendorPricesStore } from "@/contractor/pages/admin/org-items/pages/materials-prices/stores/useVendorPricesStore";
import {
  UomsDocument,
  useCreateRfqMutation,
  useUpdateRfqMutation,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useNoteDocument } from "../../../../common/note-document/providers/NoteDocumentProvider";
import { useRfq } from "../../../../rfq/hooks/useRfq";
import { useSyncRfqItems } from "../../../../rfq/hooks/useSyncRfqItems";
import { CreateRfqFromNoteFormValues } from "../create-rfq-from-note/components/CreateRfqFromNoteForm";
import { useRfqFromNoteStore } from "../store/useRfqFromNoteStore";

export const useRfqFromNote = () => {
  const { queryParams } = useQueryParams();
  const { noteDocument } = useNoteDocument();
  const { setError } = useGlobalError();
  const { rfq } = useRfq();
  const { spreadsheetData, resetPreviousData } = useColumnMapper();
  const { getSyncedRfq, validateItems } = useSyncRfqItems();
  const { vendors, loading: loadingVendors } = useVendors();
  const { setWarningAlert } = useSnackbar();
  const { loading, setLoading } = useRfqFromNoteStore(
    useShallow((state) => ({
      loading: state.loading,
      setLoading: state.setLoading,
    })),
  );
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );
  const { fetchVendorPrices } = useVendorPricesStore(
    useShallow((state) => ({
      fetchVendorPrices: state.fetchVendorPrices,
    })),
  );

  const { getValues } = useFormContext<CreateRfqFromNoteFormValues>();

  const [createRfq, { loading: creating }] = useCreateRfqMutation();
  const [updateRfq, { loading: updating }] = useUpdateRfqMutation();

  useEffect(() => {
    setLoading(creating || updating);
  }, [creating, updating, setLoading]);

  const wasDocumentReplaced = useMemo(
    () => queryParams.get(QUERYSTRING.REPLACE_DOCUMENT) === "true",
    [queryParams],
  );

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  const validateSpreadsheet = useCallback(async () => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    return await validateItems();
  }, [spreadsheetData, setWarningAlert, validateItems]);

  const syncCreateRfqFromNote = useCallback(async () => {
    const values = getValues();
    const isValid = await validateSpreadsheet();
    if (!isValid) {
      return null;
    }

    const { addedItems } = await getSyncedRfq();
    if (
      addedItems &&
      addedItems.length > 0 &&
      values.projectId &&
      values.vendorIds.length > 0
    ) {
      try {
        const { data, errors } = await createRfq({
          variables: {
            input: {
              projectId: values.projectId,
              clientCustomNumber: values.clientCustomNumber || undefined,
              vendorLocationIds: values.vendorIds,
              noteDocumentId: noteDocument?.id,
              instructions: values.instructions,
              items: addedItems,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: UomsDocument }],
        });
        setError(errors);
        if (!errors) {
          fetchVendorPrices(true);
          resetPreviousData();
        }
        return data?.createRfq ?? null;
      } catch (error) {
        setError(error);
        return null;
      }
    }

    return rfq;
  }, [
    getValues,
    getSyncedRfq,
    createRfq,
    noteDocument?.id,
    setError,
    fetchVendorPrices,
    resetPreviousData,
    rfq,
    validateSpreadsheet,
  ]);

  const syncUpdateRfqFromNote = useCallback(async () => {
    const values = getValues();
    const isValid = await validateSpreadsheet();
    if (!isValid) {
      return null;
    }
    const { addedItems, updates, removedItems } = await getSyncedRfq();

    if (rfq) {
      try {
        const { data, errors } = await updateRfq({
          variables: {
            input: {
              rfqId: rfq.id,
              vendorLocationIds: values.vendorIds,
              instructions: values.instructions,
              addedItems,
              updates,
              removedItems,
            },
          },
          awaitRefetchQueries: true,
        });
        setError(errors);
        if (!errors) {
          fetchVendorPrices(true);
          resetPreviousData();
        }
        return data?.updateRfq ?? null;
      } catch (error) {
        setError(error);
        return null;
      }
    }

    return rfq;
  }, [
    getValues,
    validateSpreadsheet,
    getSyncedRfq,
    rfq,
    updateRfq,
    setError,
    fetchVendorPrices,
    resetPreviousData,
  ]);

  return {
    syncCreateRfqFromNote,
    syncUpdateRfqFromNote,
    validateSpreadsheet,
    loading,
    vendors,
    loadingVendors,
    wasDocumentReplaced,
  };
};
