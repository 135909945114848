import { BaseButton } from "@/common/components/button/BaseButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Select } from "@/common/components/select/components/single/Select";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { Popover } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Group } from "../../common/types/types";
import { GroupTag } from "./GroupTag";

const Container = tw.div`
  relative grid grid-flow-col gap-1 justify-self-start h-fit self-center
`;
const More = tw.span`
  grid place-items-center bg-blue-500 text-white text-2xs p-1 w-6 h-6 rounded-3xl group-hover:hidden
`;
const MoreTagsContainer = tw.div`
  grid grid-flow-col gap-1 h-fit
`;
const MdOutlineAddToPhotosStyled = tw(MdOutlineAddToPhotos)`text-white text-sm`;
const IconButton = tw(BaseButton)`
  text-gray-600 border-none w-6 h-6 px-1 py-1 min-h-6 bg-blue-400 hover:bg-blue-500
`;
const IconContainer = tw.div`w-6`;

type Props = {
  groups: Group[];
  existingGroups: Group[];
  moreTagCount: number;
  onRemove: (group: Group) => void;
  onAdd: (value: string | null) => void;
  loading: boolean;
  hideAddActionIfNoGroups?: boolean;
  placeholders: {
    add: string;
  };
};

export const GroupTagPicker: FC<Props> = ({
  groups,
  moreTagCount,
  onRemove,
  existingGroups,
  loading,
  onAdd,
  placeholders,
  hideAddActionIfNoGroups = true,
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const remainingGroups = useMemo(() => {
    return (
      groups?.filter(
        (folder) => !existingGroups.some((group) => group.id === folder.id),
      ) ?? []
    );
  }, [groups, existingGroups]);

  const handleChange = useCallback(
    (value: string | null) => {
      onAdd(value);
      setAnchorEl(null);
    },
    [onAdd],
  );

  return (
    <Container>
      {existingGroups.slice(0, moreTagCount).map((group) => (
        <GroupTag key={group.id} group={group} onRemove={onRemove} />
      ))}
      {existingGroups && existingGroups.length > moreTagCount && (
        <Tooltip
          id="invoice-groups-tooltip"
          element={<More>+{(existingGroups?.length || 0) - moreTagCount}</More>}
          position="bottom"
        >
          <MoreTagsContainer>
            {existingGroups.slice(moreTagCount).map((group) => (
              <GroupTag key={group.id} group={group} onRemove={onRemove} />
            ))}
          </MoreTagsContainer>
        </Tooltip>
      )}
      <IconContainer>
        <If
          isTrue={
            !loading && (!hideAddActionIfNoGroups || remainingGroups.length > 0)
          }
        >
          <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
            <MdOutlineAddToPhotosStyled />
          </IconButton>
        </If>
        <Loader loading={loading} small />
      </IconContainer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          " .MuiPaper-root": {
            borderRadius: "0.5rem",
            boxShadow: "none",
            padding: "0.5rem",
          },
        }}
      >
        <Select
          placeholder={intl.formatMessage({ id: placeholders.add })}
          options={remainingGroups}
          getLabel={(option) => option.name}
          getValue={(option) => option.id}
          onChange={handleChange}
        />
      </Popover>
    </Container>
  );
};
