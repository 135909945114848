import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue, setValue } from "@/common/utils/localStorage";
import { useCallback, useState } from "react";

interface UseResizeColumnsProps {
  minimizeRightSide?: boolean;
  minimizeThreshold?: number;
  minLeftWidth?: number;
  minRightWidth?: number;
}

export const useResizeColumns = ({
  minimizeRightSide = true,
  minimizeThreshold = 85,
  minLeftWidth = 800,
  minRightWidth = 550,
}: UseResizeColumnsProps = {}) => {
  const lastSaved = readValue(`${LOCAL_STORAGE_KEYS.RESIZABLE_COLUMNS}`);
  const [leftWidth, setLeftWidth] = useState(
    lastSaved && typeof lastSaved === "number" ? lastSaved : 60,
  );
  const [isDragging, setIsDragging] = useState(false);
  const [isRightMinimized, setIsRightMinimized] = useState(false);
  const [isInvalidLeft, setIsInvalidLeft] = useState(false);
  const [isInvalidRight, setIsInvalidRight] = useState(false);

  const handleResize = useCallback(
    (newWidthPx: number, containerWidth: number) => {
      const rightWidthPx = containerWidth - newWidthPx;
      const newWidthPercent = (newWidthPx / containerWidth) * 100;

      const isLeftInvalid = newWidthPx < minLeftWidth;
      const isRightInvalid = rightWidthPx < minRightWidth;

      setIsInvalidLeft(isLeftInvalid);
      setIsInvalidRight(isRightInvalid);

      if (minimizeRightSide && newWidthPercent >= minimizeThreshold) {
        setIsRightMinimized(true);
        setLeftWidth(100);
        setIsDragging(false);
      } else if (!isLeftInvalid && !isRightInvalid) {
        setIsRightMinimized(false);
        setLeftWidth(newWidthPercent);
        setValue(`${LOCAL_STORAGE_KEYS.RESIZABLE_COLUMNS}`, newWidthPercent);
      }
    },
    [minimizeRightSide, minLeftWidth, minRightWidth, minimizeThreshold],
  );

  const toggleMinimize = useCallback(() => {
    if (!minimizeRightSide) {
      return;
    }

    setIsRightMinimized((prev) => !prev);
    const lastSaved = readValue(`${LOCAL_STORAGE_KEYS.RESIZABLE_COLUMNS}`);
    if (lastSaved && typeof lastSaved === "number") {
      setLeftWidth((prev) => (prev === 100 ? lastSaved : 100));
    }
  }, [minimizeRightSide]);

  return {
    leftWidth,
    isDragging,
    isRightMinimized,
    isInvalidLeft,
    isInvalidRight,
    setIsDragging,
    handleResize,
    toggleMinimize,
    resetInvalidStates: () => {
      setIsInvalidLeft(false);
      setIsInvalidRight(false);
    },
  };
};
