import jsPDF from "jspdf";

export const LOGO_SIZE = 30;

export const drawLogo = (
  doc: jsPDF,
  logo: string,
  logoProportion: number,
  x: number,
  y: number,
) => {
  let scaleX = 1;
  let scaleY = 1;
  if (logoProportion >= 1) {
    scaleY = 1 / logoProportion;
  } else {
    scaleX = logoProportion;
  }
  doc.addImage(
    logo,
    x + LOGO_SIZE / 2 - (LOGO_SIZE * scaleX) / 2,
    y - 3 + LOGO_SIZE / 2 - (LOGO_SIZE * scaleY) / 2,
    LOGO_SIZE * scaleX,
    LOGO_SIZE * scaleY,
  );
  return LOGO_SIZE + 2;
};
