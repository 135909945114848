import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useGetProjectItemFromSku } from "@/common/hooks/useGetProjectItem";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { useDeliverySlipVerification } from "@/contractor/pages/home/releases/pages/delivery-slips/providers/DeliverySlipVerificationProvider";
import {
  AddToReleaseItemInput,
  ManufacturerFieldsFragment,
  UpdateContractorReleaseItemInput,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import { useState } from "react";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";

type GetSyncedReleaseProps = {
  importedItems?: { id: string }[];
  addItemsProps?: Partial<AddToReleaseItemInput>;
  projectId?: string;
};

export type SyncedRelease = {
  releaseId: string;
  version: number;
  updates: UpdateContractorReleaseItemInput[];
  addedItems: AddToReleaseItemInput[];
  removedItems: string[];
  buyoutId?: string;
};

export const useSyncReleaseItems = () => {
  const { manufacturers } = useManufacturers();
  const { spreadsheetData, getRemovedRowIds, rowHasChanges, gotoInvalidRow } =
    useColumnMapper();
  const {
    getCostCodeId,
    getRowTagIds,
    getCostTypeId,
    addMissingZones,
    addMissingTags,
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    findMaterialByName,
    getPrefilledValue,
    isBuyoutItem,
    tableHasColumn,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { release } = useRelease();
  const { deliverySlip } = useDeliverySlipVerification();
  const { zones } = useProjectZones();
  const { vendors, getVendorCode } = useVendors();
  const { materials } = useMaterials();
  const [saving, setSaving] = useState(false);
  const { setInputError } = useReleaseActions();
  const { release: releaseStore, hasChanges } = useReleaseStore();
  const { updateRelease, updating } = useReleaseUpdate();
  const { hasPhaseCodes } = useOrgSettings();
  const getProjectItem = useGetProjectItemFromSku();

  const addUpdatedItemOptionalFields = (
    item: UpdateContractorReleaseItemInput,
    row: Record<string, string>,
    newZones: ZoneFieldsFragment[],
    newManufacturers: ManufacturerFieldsFragment[],
  ): UpdateContractorReleaseItemInput => {
    const vendorText = getCellValue(row, COLUMN_TYPE.Vendor);
    const vendor = vendors.find(
      (vendor) =>
        vendorLabelFormatter(vendor.sellerOrgLocation, [], {
          vendorCode: getVendorCode(vendor),
        }) === vendorText,
    );
    const receivedQuantityDecimal = getCellValue(
      row,
      COLUMN_TYPE.ReceivedQuantity,
    );
    const zone = [...(zones || []), ...newZones].find(
      (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
    );
    const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
      (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
    );
    const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
    const existingItem = release?.items.find((item) => item.id === row.id);

    if (tableHasColumn(COLUMN_TYPE.Notes)) {
      item.instructions = {
        text: notes,
      };
    }
    if (tableHasColumn(COLUMN_TYPE.Manufacturer) && !existingItem?.buyoutItem) {
      item.manufacturerId = manufacturer?.id;
      item.clearManufacturer = !manufacturer;
    }
    if (
      tableHasColumn(COLUMN_TYPE.Tag) ||
      tableHasColumn(COLUMN_TYPE.PhaseCode)
    ) {
      item.tags = getRowTagIds(row);
    }
    if (tableHasColumn(COLUMN_TYPE.Vendor)) {
      if (vendor) {
        item.sellerOrgLocationId = vendor.sellerOrgLocation.id;
      } else {
        item.clearSellerOrgLocation = true;
      }
    }
    if (tableHasColumn(COLUMN_TYPE.Zone)) {
      item.zoneId = zone?.id;
    }
    if (tableHasColumn(COLUMN_TYPE.CostCode)) {
      item.costCodeId = getCostCodeId(row);
      item.clearCostCode = !getCostCodeId(row);
    }
    if (tableHasColumn(COLUMN_TYPE.CostType)) {
      item.costTypeId = getCostTypeId(row);
      item.clearCostType = !getCostTypeId(row);
    }
    if (tableHasColumn(COLUMN_TYPE.ReceivedQuantity)) {
      item.receivedQuantityDecimal =
        receivedQuantityDecimal === "" ? undefined : receivedQuantityDecimal;
    }
    if (tableHasColumn(COLUMN_TYPE.DeliveryDate)) {
      const date = getCellValue(row, COLUMN_TYPE.DeliveryDate);
      if (date) {
        item.deliveryDate = defaultReleaseDate(new Date(date)).getTime();
      }
      item.clearDeliveryDate = !date;
    }
    if (tableHasColumn(COLUMN_TYPE.EndDate)) {
      const date = getCellValue(row, COLUMN_TYPE.EndDate);
      if (date) {
        item.deliveryDate = defaultReleaseDate(new Date(date)).getTime();
      }
      item.clearDeliveryDate = !date;
    }
    return item;
  };

  const addNewItemOptionalFields = (
    newItem: AddToReleaseItemInput,
    row: Record<string, string>,
    newZones: ZoneFieldsFragment[],
    newManufacturers: ManufacturerFieldsFragment[],
  ): AddToReleaseItemInput => {
    const vendorText = getCellValue(row, COLUMN_TYPE.Vendor);
    const vendor = vendors.find(
      (v) =>
        vendorLabelFormatter(v.sellerOrgLocation, [], {
          vendorCode: getVendorCode(v),
        }) === vendorText,
    );
    const receivedQuantityDecimal = getCellValue(
      row,
      COLUMN_TYPE.ReceivedQuantity,
    );

    const zone = [...(zones || []), ...newZones].find(
      (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
    );
    const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
      (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
    );
    const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
    const existingItem = release?.items.find((item) => item.id === row.id);
    if (tableHasColumn(COLUMN_TYPE.Notes)) {
      newItem.instructions = {
        text: notes,
      };
    }

    if (tableHasColumn(COLUMN_TYPE.Manufacturer) && !existingItem?.buyoutItem) {
      newItem.manufacturerId = manufacturer?.id;
    }
    if (
      tableHasColumn(COLUMN_TYPE.Tag) ||
      tableHasColumn(COLUMN_TYPE.PhaseCode)
    ) {
      newItem.tags = getRowTagIds(row);
    }
    if (tableHasColumn(COLUMN_TYPE.Vendor)) {
      if (vendor) {
        newItem.sellerOrgLocationId = vendor.sellerOrgLocation.id;
      }
    }
    if (tableHasColumn(COLUMN_TYPE.Zone)) {
      newItem.zoneId = zone?.id;
    }
    if (tableHasColumn(COLUMN_TYPE.CostCode)) {
      newItem.costCodeId = getCostCodeId(row);
    }
    if (tableHasColumn(COLUMN_TYPE.CostType)) {
      newItem.costTypeId = getCostTypeId(row);
    }
    if (tableHasColumn(COLUMN_TYPE.ReceivedQuantity)) {
      newItem.receivedQuantityDecimal =
        receivedQuantityDecimal === "" ? undefined : receivedQuantityDecimal;
    }
    if (tableHasColumn(COLUMN_TYPE.DeliveryDate)) {
      const date = getCellValue(row, COLUMN_TYPE.DeliveryDate);
      if (date) {
        newItem.deliveryDate = defaultReleaseDate(new Date(date)).getTime();
      }
    }
    if (tableHasColumn(COLUMN_TYPE.EndDate)) {
      const date = getCellValue(row, COLUMN_TYPE.EndDate);
      if (date) {
        newItem.deliveryDate = defaultReleaseDate(new Date(date)).getTime();
      }
    }

    return newItem;
  };

  const getSyncedRelease = async (
    { importedItems, addItemsProps, projectId }: GetSyncedReleaseProps = {
      importedItems: [],
    },
  ) => {
    const newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    let itemsToRemove = [] as string[];

    setSaving(true);
    itemsToRemove = getRemovedRowIds(release?.items ?? []);
    const newMaterials = (await addMissingMaterials()) || [];
    const newManufacturers = (await addMissingManufacturers()) || [];
    const newZones =
      (await addMissingZones(release?.project?.id || projectId || null)) || [];
    await addMissingTags(release?.project?.id || projectId || null);
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getCellValue(row, COLUMN_TYPE.UOM);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }

      let quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity) || "0";
      const vendorText = getCellValue(row, COLUMN_TYPE.Vendor);
      let unitPrice = tableHasColumn(COLUMN_TYPE.PrefilledPrice)
        ? getCellValue(row, COLUMN_TYPE.PrefilledPrice)
        : getCellValue(row, COLUMN_TYPE.UnitPrice);

      if (isLumpSum && Number(unitPrice) > 1 && Number(quantityDecimal) === 1) {
        quantityDecimal = unitPrice;
        unitPrice = "1";
      }

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;
      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );
      const buyoutItem = isBuyoutItem(
        rowMaterialText,
        vendorText,
        manufacturer?.name ?? "",
        unitPrice,
        getCellValue(row, COLUMN_TYPE.CostCode),
      );

      const prefilledPrice = getPrefilledValue({
        material: rowMaterialText,
        vendor: vendorText,
        manufacturer: manufacturer?.name ?? "",
        uom,
      });

      const importedItem = importedItems?.find((_, i) => i === index);

      const existingItem = release?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          const item = {
            releaseItemId: row.id,
            position: index,
            uom,
            buyoutItemId: buyoutItem ? buyoutItem?.id : undefined,
            unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
            ...(isLumpSum && { name: rowMaterialText }),
            clearUnitPrice: !hasUnitPrice,
            quantityDecimal,
            poItemExternalId: importedItem?.id,
          } as UpdateContractorReleaseItemInput;
          itemsToUpdate.push(
            addUpdatedItemOptionalFields(item, row, newZones, newManufacturers),
          );
        }
      } else {
        const newItem = {
          ...(!buyoutItem && {
            projectItem: getProjectItem(material, uom),
          }),
          position: index,
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          poItemExternalId: importedItem?.id,
          buyoutItemId: buyoutItem ? buyoutItem?.id : undefined,
          deliverySlipId: deliverySlip ? deliverySlip?.id : undefined,
          quantityDecimal,
          pricePrenegotiated:
            prefilledPrice.isVendorPrice &&
            prefilledPrice.value === String(unitPrice),
          ...addItemsProps,
        } as AddToReleaseItemInput;
        newItems.push(
          addNewItemOptionalFields(newItem, row, newZones, newManufacturers),
        );
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    return {
      releaseId: release?.id ?? "",
      version: releaseStore?.version ?? 0,
      updates: itemsToUpdate,
      addedItems: newItems,
      removedItems: itemsToRemove,
      assignDefaultCostCodes: false,
      prefillPrices: false,
    };
  };

  type ValidateItemsProps = {
    type?: SpreadsheetSaveType;
    requirePrice?: boolean;
  };

  const validateItems = async (props?: ValidateItemsProps) => {
    const { type, requirePrice } = props ?? {};
    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.Material,
        ...(type !== SpreadsheetSaveType.Toggle
          ? tableHasColumn(COLUMN_TYPE.Quantity)
            ? [COLUMN_TYPE.Quantity]
            : []
          : []),
        ...(requirePrice
          ? tableHasColumn(COLUMN_TYPE.PrefilledPrice)
            ? [COLUMN_TYPE.PrefilledPrice]
            : []
          : tableHasColumn(COLUMN_TYPE.UnitPrice)
            ? [COLUMN_TYPE.UnitPrice]
            : []),
        COLUMN_TYPE.UOM,
      ])) ||
      !(await validateRowValues(
        [
          ...(type !== SpreadsheetSaveType.Toggle
            ? tableHasColumn(COLUMN_TYPE.Quantity)
              ? [COLUMN_TYPE.Quantity]
              : []
            : []),
          ...(tableHasColumn(COLUMN_TYPE.Vendor) ? [COLUMN_TYPE.Vendor] : []),
          ...(tableHasColumn(COLUMN_TYPE.PrefilledPrice)
            ? [COLUMN_TYPE.PrefilledPrice]
            : []),
          ...(tableHasColumn(COLUMN_TYPE.UnitPrice)
            ? [COLUMN_TYPE.UnitPrice]
            : []),
          COLUMN_TYPE.UOM,
          ...(hasPhaseCodes
            ? tableHasColumn(COLUMN_TYPE.PhaseCode)
              ? [COLUMN_TYPE.PhaseCode]
              : []
            : tableHasColumn(COLUMN_TYPE.CostCode)
              ? [COLUMN_TYPE.CostCode]
              : []),
          ...(tableHasColumn(COLUMN_TYPE.CostType)
            ? [COLUMN_TYPE.CostType]
            : []),
        ],
        undefined,
        { minPrice: undefined },
      ))
    ) {
      setInputError(ReleaseErrorType.SPREADSHEET_ERROR);
      gotoInvalidRow();
      return false;
    }
    return true;
  };

  const syncReleaseItems = async (
    type?: SpreadsheetSaveType,
    syncProps?: GetSyncedReleaseProps,
  ) => {
    if (!(await validateItems({ type }))) {
      return undefined;
    }

    if (hasChanges) {
      const syncedRelease = await getSyncedRelease(syncProps);
      await updateRelease(syncedRelease);
    }

    return release;
  };

  return {
    getSyncedRelease,
    validateItems,
    syncReleaseItems,
    saving: updating || saving,
  };
};
