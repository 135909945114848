import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Price } from "@/common/components/price/Price";
import { useTaxType } from "@/common/components/sales-tax-input/hooks/useTaxType";
import { DateView } from "@/common/utils/dates/DateView";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  TaxType,
  UpdateInvoiceInput,
} from "@/generated/graphql";
import { EditOutlined, InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BsExclamation } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { dateDiffInDays } from "../../../../../contractor/pages/home/invoices/pages/scanned-invoices/utils/dateDiff";
import { Tooltip } from "../../../tooltip/Tooltip";
import { InvoiceFooterPriceEdit } from "./InvoiceFooterPriceEdit";

const Row = tw.div`flex flex-row h-10`;
const RowTitle = tw.div`flex w-40 items-center justify-end font-medium text-sm`;
const RowIcon = tw.div`flex w-20 items-center justify-center`;
const RowValue = tw.div`flex w-40 items-center justify-end pr-7 text-sm`;
const RowEdit = tw.div`flex items-center justify-center text-3xl font-normal w-8 pr-8`;
const RowBorder = tw.div`flex flex-1 flex-row border-1 border-t border-dashed border-black justify-between pr-4`;
const DiscountInfo = tw.span``;
const ExclamationCircleRed = tw(
  BsExclamation,
)`bg-red-500 fill-red-500 text-xl rounded-full m-0 fill-white`;
const ExclamationCircleOrange = tw(InfoOutlined)`text-2xl text-orange-500`;

enum FooterDetailType {
  SUBTOTAL,
  ADDITIONAL_CHARGES,
  SALES_TAX,
}

type Props = {
  invoice: InvoiceFieldsFragment | DistributorInvoiceFieldsFragment | null;
  updateInvoice?: (input: UpdateInvoiceInput) => void;
  readonly: boolean;
  sameSalesTax?: boolean;
  sameAdditionalCharges?: boolean;
  sameSubtotal?: boolean;
  sameTotal?: boolean;
  isSalesTaxDiscrepancyMinor?: boolean;
  isAdditionalChargesDiscrepancyMinor?: boolean;
  isSubtotalDiscrepancyMinor?: boolean;
  isTotalDiscrepancyMinor?: boolean;
};

export const InvoiceDetailsFooter: FC<Props> = ({
  invoice,
  readonly,
  updateInvoice,
  sameSalesTax,
  sameAdditionalCharges,
  sameSubtotal,
  sameTotal,
  isSalesTaxDiscrepancyMinor,
  isAdditionalChargesDiscrepancyMinor,
  isSubtotalDiscrepancyMinor,
  isTotalDiscrepancyMinor,
}) => {
  const invoicePaymentTerm = useMemo(() => {
    return invoice?.dueDate && invoice.issueDate
      ? dateDiffInDays(invoice.dueDate, invoice.issueDate) || 0
      : 0;
  }, [invoice?.dueDate, invoice?.issueDate]);
  const [editingInputs, setEditingInputs] = useState<FooterDetailType[]>([]);
  const [subtotal, setSubtotal] = useState(invoice?.subtotal ?? "0");
  const [additionalCharges, setAdditionalCharges] = useState(
    invoice?.chargesAmount ?? "0",
  );
  const [salesTax, setSalesTax] = useState(invoice?.taxAmount ?? "0");
  const { useTaxKey } = useTaxType(
    invoice?.release?.type?.id,
    undefined,
    invoice?.release?.taxType,
  );
  const useTax = useMemo(
    () => invoice?.release?.taxType === TaxType.Use,
    [invoice],
  );
  const { hasAdditionalCharges } = useOrgSettings();

  useEffect(() => {
    setSubtotal(invoice?.subtotal ?? "0");
    setAdditionalCharges(invoice?.chargesAmount ?? "0");
    setSalesTax(invoice?.taxAmount ?? "0");
  }, [invoice, invoicePaymentTerm]);

  const handleEditToggleClick = useCallback(
    (detailType: FooterDetailType) => {
      if (editingInputs.includes(detailType)) {
        switch (detailType) {
          case FooterDetailType.SUBTOTAL:
            setSubtotal(subtotal ?? "0");
            break;
          case FooterDetailType.ADDITIONAL_CHARGES:
            setAdditionalCharges(additionalCharges ?? "0");
            break;
          case FooterDetailType.SALES_TAX:
            setSalesTax(salesTax ?? "0");
            break;
        }
        setEditingInputs((e) => e.filter((r) => r !== detailType));
      } else {
        setEditingInputs((e) => [...e, detailType]);
      }
    },
    [editingInputs, subtotal, additionalCharges, salesTax],
  );

  const handleSaveInvoiceDetail = useCallback(
    (detailType: FooterDetailType, value: string) => {
      switch (detailType) {
        case FooterDetailType.SUBTOTAL:
          if (invoice) {
            updateInvoice?.({ id: invoice.id, subtotal: value || "0" });
          }
          setSubtotal(value ?? "");
          break;
        case FooterDetailType.ADDITIONAL_CHARGES:
          if (invoice) {
            updateInvoice?.({ chargesAmount: value || "0", id: invoice.id });
          }
          setAdditionalCharges(value ?? "");
          break;
        case FooterDetailType.SALES_TAX:
          if (invoice) {
            updateInvoice?.({ id: invoice.id, taxAmount: value || "0" });
          }
          setSalesTax(value ?? "");
          break;
      }
      setEditingInputs((e) => e.filter((r) => r !== detailType));
    },
    [
      invoice,
      setSubtotal,
      setAdditionalCharges,
      setSalesTax,
      setEditingInputs,
      updateInvoice,
    ],
  );

  const discountDate = useMemo(
    () => (invoice?.discountDate ? new Date(invoice?.discountDate) : undefined),
    [invoice?.discountDate],
  );

  return (
    <>
      <Row>
        <FormattedMessage id="SUBTOTAL" tagName={RowTitle} />
        <RowIcon>
          <If isTrue={sameSubtotal === false}>
            {!isSubtotalDiscrepancyMinor ? (
              <ExclamationCircleRed />
            ) : (
              <Tooltip
                element={<ExclamationCircleOrange />}
                id="total-discrepancy"
              >
                <FormattedMessage id="MINOR_DISCREPANCY_WITH_ORDER" />
              </Tooltip>
            )}
          </If>
        </RowIcon>
        <RowValue data-testid="invoiceFooter-subtotal">
          <InvoiceFooterPriceEdit
            price={subtotal}
            isEditing={editingInputs.includes(FooterDetailType.SUBTOTAL)}
            toggleEditing={() =>
              handleEditToggleClick(FooterDetailType.SUBTOTAL)
            }
            onSave={(value) =>
              handleSaveInvoiceDetail(FooterDetailType.SUBTOTAL, value)
            }
          />
        </RowValue>
        <RowEdit>
          <If
            isTrue={
              !editingInputs.includes(FooterDetailType.SUBTOTAL) && !readonly
            }
          >
            <LinkLike>
              <EditOutlined
                fontSize="small"
                onClick={() => handleEditToggleClick(FooterDetailType.SUBTOTAL)}
                className="cursor-pointer"
                data-testid="invoiceFooter-subtotalEditBtn"
              />
            </LinkLike>
          </If>
        </RowEdit>
      </Row>
      <If
        isTrue={
          hasAdditionalCharges ||
          (invoice?.chargesAmount && Number(invoice.chargesAmount))
        }
      >
        <Row>
          <FormattedMessage id="ADDITIONAL_CHARGES" tagName={RowTitle} />
          <RowIcon>
            <If isTrue={sameAdditionalCharges === false}>
              {!isAdditionalChargesDiscrepancyMinor ? (
                <ExclamationCircleRed />
              ) : (
                <Tooltip
                  element={<ExclamationCircleOrange />}
                  id="total-discrepancy"
                >
                  <FormattedMessage id="MINOR_DISCREPANCY_WITH_ORDER" />
                </Tooltip>
              )}
            </If>
          </RowIcon>
          <RowValue data-testid="invoiceFooter-additionalCharges">
            <InvoiceFooterPriceEdit
              price={additionalCharges}
              isEditing={editingInputs.includes(
                FooterDetailType.ADDITIONAL_CHARGES,
              )}
              toggleEditing={() =>
                handleEditToggleClick(FooterDetailType.ADDITIONAL_CHARGES)
              }
              onSave={(value) =>
                handleSaveInvoiceDetail(
                  FooterDetailType.ADDITIONAL_CHARGES,
                  value,
                )
              }
            />
          </RowValue>
          <RowEdit>
            <If
              isTrue={
                !editingInputs.includes(FooterDetailType.ADDITIONAL_CHARGES) &&
                !readonly
              }
            >
              <LinkLike>
                <EditOutlined
                  fontSize="small"
                  onClick={() =>
                    handleEditToggleClick(FooterDetailType.ADDITIONAL_CHARGES)
                  }
                  className="cursor-pointer"
                  data-testid="invoiceFooter-additionalChargesEditBtn"
                />
              </LinkLike>
            </If>
          </RowEdit>
        </Row>
      </If>
      <If isTrue={!useTax}>
        <Row>
          <FormattedMessage id={useTaxKey} tagName={RowTitle} />
          <RowIcon>
            <If isTrue={sameSalesTax === false}>
              {!isSalesTaxDiscrepancyMinor ? (
                <ExclamationCircleRed />
              ) : (
                <Tooltip
                  element={<ExclamationCircleOrange />}
                  id="total-discrepancy"
                >
                  <FormattedMessage id="MINOR_DISCREPANCY_WITH_ORDER" />
                </Tooltip>
              )}
            </If>
          </RowIcon>
          <RowValue data-testid="invoiceFooter-salesTax">
            <InvoiceFooterPriceEdit
              price={salesTax}
              isEditing={editingInputs.includes(FooterDetailType.SALES_TAX)}
              toggleEditing={() =>
                handleEditToggleClick(FooterDetailType.SALES_TAX)
              }
              onSave={(value) =>
                handleSaveInvoiceDetail(FooterDetailType.SALES_TAX, value)
              }
            />
          </RowValue>
          <RowEdit>
            <If
              isTrue={
                !editingInputs.includes(FooterDetailType.SALES_TAX) && !readonly
              }
            >
              <LinkLike>
                <EditOutlined
                  fontSize="small"
                  onClick={() =>
                    handleEditToggleClick(FooterDetailType.SALES_TAX)
                  }
                  className="cursor-pointer"
                  data-testid="invoiceFooter-salesTaxEditBtn"
                />
              </LinkLike>
            </If>
          </RowEdit>
        </Row>
      </If>
      <Row className="pr-5">
        <RowBorder>
          <RowTitle className="rounded-l-4xl text-lg">
            <FormattedMessage id="TOTAL" />
          </RowTitle>
          <RowIcon className="py-3">
            <If isTrue={sameTotal === false}>
              {!isTotalDiscrepancyMinor ? (
                <ExclamationCircleRed />
              ) : (
                <Tooltip
                  element={<ExclamationCircleOrange />}
                  id="total-discrepancy"
                >
                  <FormattedMessage id="MINOR_DISCREPANCY_WITH_ORDER" />
                </Tooltip>
              )}
            </If>
          </RowIcon>
          <RowValue
            className="-mr-1 items-center"
            data-testid="invoiceFooter-invoiceTotal"
          >
            <Price
              price={invoice?.total}
              maximumFractionDigits={2}
              className="text-lg"
            />
            <If isTrue={invoice?.discountedAmount}>
              <Row className="text-sm text-gray-500">
                <DiscountInfo>
                  <Price
                    price={invoice?.discountedAmount}
                    maximumFractionDigits={2}
                  />
                  <If isTrue={discountDate}>
                    <DiscountInfo className="px-1">
                      <FormattedMessage id="INVOICE_IF_PAID_BEFORE" />
                    </DiscountInfo>
                    <DateView date={discountDate} />
                  </If>
                </DiscountInfo>
              </Row>
            </If>
          </RowValue>
        </RowBorder>
      </Row>
    </>
  );
};
