import { OverlayPanelProps } from "@/common/components/panel/OverlayPanel";
import { OverlayPanelWithStepper } from "@/common/components/panel/OverlayPanelWithStepper";
import { DEFAULT_ADDRESS_COUNTRY, DEFAULT_ADDRESS_STATE } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { routes } from "@/config/routes";
import { useProjects } from "@/contractor/pages/home/projects/providers/ProjectsProvider";
import { CreateProjectInput, ProjectStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { ProjectFormProjectInformation } from "../project-form/forms/ProjectFormProjectInformation";

type Props = {
  onClose: () => void;
  id?: string;
  calendarOpened: boolean;
  setCalendarOpened: (opened: boolean) => void;
};

export const ProjectFormWithStepper: FC<Props> = ({
  onClose,
  calendarOpened,
  setCalendarOpened,
}) => {
  const { createProject } = useProjects();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      projectName: "",
      address: "",
      address2: "",
      jobNumber: "",
      city: "",
      state: DEFAULT_ADDRESS_STATE,
      postalCode: "",
      country: DEFAULT_ADDRESS_COUNTRY,
      budget: "",
      status: ProjectStatus.Awarded,
      locationId: "",
      jobStartDate: null,
      projectUsers: [],
      taxExempt: false,
    } as FieldValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSave = useCallback(async () => {
    const input = methods.getValues();

    if (!input.status) {
      return;
    }

    let result = null;
    setSaving(true);
    const address = {
      addressLine1: input.address,
      addressLine2: input.address2,
      city: input.city,
      country: input.country,
      postalCode: input.postalCode,
      state: input.state || "",
    };
    const newProject: CreateProjectInput = {
      name: input.projectName,
      jobNumber: input.jobNumber || null,
      locationId: input.locationId || "",
      address,
      startDate: input.jobStartDate?.valueOf() || new Date().valueOf(),
      status: input.status,
      budget: input.budget?.toString() || null,
      team: input.projectUsers || [],
      taxExempt: input.taxExempt,
    };

    result = await createProject(newProject);

    setSaving(false);
    if (!result) {
      return;
    }
    setSuccessAlert(
      intl.$t(
        {
          id: "PROJECT_CREATE_SUCCESS",
        },
        { name: strongify(input.projectName) },
      ),
    );
    navigate(generatePath(routes.projectDetails, { id: result.id }));
    onClose();
  }, [methods, createProject, setSuccessAlert, intl, onClose, navigate]);

  const handleCloseCalendar = useCallback(() => {
    if (calendarOpened) {
      setCalendarOpened(false);
    }
  }, [calendarOpened, setCalendarOpened]);

  const steps = useMemo((): OverlayPanelProps[] => {
    return [
      {
        title: intl.$t({ id: "ADD_PROJECT" }),
        children: (
          <ProjectFormProjectInformation
            calendarOpened={calendarOpened}
            setCalendarOpened={setCalendarOpened}
          />
        ),
        onCancel: onClose,
        saving,
        onSave: handleSubmit(onSave),
        onClick: handleCloseCalendar,
      },
    ];
  }, [
    intl,
    calendarOpened,
    setCalendarOpened,
    onClose,
    saving,
    handleSubmit,
    onSave,
    handleCloseCalendar,
  ]);

  return (
    <FormProvider {...methods} handleSubmit={handleSubmit}>
      <OverlayPanelWithStepper steps={steps} />
    </FormProvider>
  );
};
