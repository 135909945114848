import { CountType } from "@/common/components/circle-counter/CountType";
import { InvoiceReleaseItemDetails } from "@/common/components/invoices/invoice-release-item-details/InvoiceReleaseItemDetails";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { useReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`grid w-full gap-0.5`;

type Props = {
  item: ExpandedReleaseItem;
  count: CountType;
};

export const DeliverySlipReleaseItemMaterial: FC<Props> = ({ item, count }) => {
  const { groupedByCostCode } = useReleaseItemsZoneProvider();
  const { release } = useRelease();

  const itemPhaseCode = useMemo(() => {
    return item.tags.find((tag) => tag.hasMapping)?.name;
  }, [item.tags]);

  return (
    <Container>
      <ProjectItemMaterialView
        item={item}
        count={count}
        includeCounter={!item.redelivery?.id && !item.backorderFromRelease}
        manufacturer={item.manufacturer}
        expandable={false}
        extraDetailsPosition="bottom"
      />
      <InvoiceReleaseItemDetails
        costTypeId={item.costType?.id ?? release?.type.costType?.id}
        costCodeId={groupedByCostCode ? "" : item.costCode?.id}
        zone={groupedByCostCode ? "" : item.zone?.name}
        phaseCode={groupedByCostCode ? "" : itemPhaseCode}
        item={item}
      />
    </Container>
  );
};
