import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
 border border-gray-500 p-2 w-40 rounded-lg cursor-pointer text-gray-500 text-center relative flex justify-center
`;

const Image = tw.img`
  min-h-36 h-fit w-fit object-contain
`;

type Props = {
  logoImageUrl?: string | null;
  removeImage: () => void;
};

export const LogoImage: FC<Props> = ({ logoImageUrl }) => {
  return (
    <Container>
      {logoImageUrl ? (
        <Image loading="lazy" src={logoImageUrl} alt={"org-logo"} />
      ) : (
        <FormattedMessage id="PHOTO_UPLOAD_PLACEHOLDER" />
      )}
    </Container>
  );
};
