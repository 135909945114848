import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { WrapperProps } from "@/common/components/grid-table/types/WrapperProps";
import { MaterialView } from "@/common/components/material/MaterialView";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  Base,
  LgFixedContainer,
  SmWideContainer,
} from "@/common/layout/ResponsiveClasses";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import {
  OrgMaterialFieldsFragment,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { MaterialDatePicker } from "./components/MaterialDatePicker";
import { MaterialPriceExtraDetails } from "./components/MaterialPriceExtraDetails";
import { MaterialPriceItemActions } from "./components/MaterialPriceItemActions";
import { MaterialPriceUom } from "./components/MaterialPriceUom";
import { MaterialUnitPriceInput } from "./components/MaterialUnitPriceInput";
import { VendorPriceManufacturerSelector } from "./components/VendorPriceManufacturerSelector";
import { VendorSelector } from "./components/VendorSelector";

const Container = tw(Base)`
  basis-full lg:basis-1/12 lg:w-1/12
  3xl:basis-2/12 3xl:w-2/12 flex-1 col-span-full
  ${({ $group }: WrapperProps) => ($group ? "lg:w-1/4" : "")}
`;

const FullRowContainer = tw(Base)`w-full flex-col col-span-full
  ${({ $header }: WrapperProps) => ($header ? "hidden" : "")}
`;

const ActionsContainer = tw(Base)`
  2xl:w-28 lg:w-24 md:w-20 w-20
  2xl:basis-28 lg:basis-24 md:basis-20 basis-20
`;

const DateContainer = tw(Base)`
  lg:basis-[143px]
`;

const VendorContainer = tw(LgFixedContainer)`
  ${({ $header }: WrapperProps) => ($header ? "justify-center" : "")}
`;

const Empty = tw.div`w-full h-12`;

export const useMaterialPricesConfiguration = () => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const {
    requiredValidator,
    combineValidators,
    positiveNumberValidator,
    vendorValidator,
  } = useTableValidators();
  const intl = useIntl();
  const { hasManufacturersSetting } = useManufacturersSettings();

  const configuration: Array<
    GridCol<OrgMaterialFieldsFragment, VendorPriceFieldsFragment>
  > = useMemo(
    () => [
      {
        wrapper: Container,
        item: ({ item, count }) => (
          <MaterialView
            material={item.material}
            description={item.material.name}
            count={count}
            includeCounter
            readonly
          />
        ),
        position: "none",
        header: <FormattedMessage id="ITEM_DESCRIPTION" />,
        details: () => <Empty />,
      },
      {
        wrapper: VendorContainer,
        header: <FormattedMessage id="VENDOR" />,
        item: ({ item, rowError, editMode }) => (
          <VendorSelector
            item={item}
            readonly={!editMode}
            error={rowError}
            price={item.vendorPrices[0]}
          />
        ),
        details: ({ item, index, editMode, rowError }) => (
          <VendorSelector
            item={item}
            readonly={!editMode}
            error={rowError}
            price={item.vendorPrices[index || 0]}
          />
        ),
        position: "none",
      },
      {
        wrapper: LgFixedContainer,
        position: "center",
        item: ({ item, editMode, index, rowError }) => (
          <VendorPriceManufacturerSelector
            item={item}
            price={item.vendorPrices[0]}
            readonly={!editMode}
            index={index}
            error={rowError}
          />
        ),
        details: ({ item, index, editMode, rowError }) => {
          return (
            <VendorPriceManufacturerSelector
              item={item}
              price={item.vendorPrices[index || 0]}
              readonly={!editMode}
              index={index}
              error={rowError}
            />
          );
        },
        header: <FormattedMessage id="MANUFACTURER" />,
        hidden: !hasManufacturersSetting,
      },
      {
        wrapper: SmWideContainer,
        hidden: !hasPermissions,
        item: ({ item, editMode, rowError }) => (
          <MaterialUnitPriceInput
            item={item}
            price={item.vendorPrices[0]}
            readonly={!editMode}
            error={rowError}
          />
        ),
        details: ({ item, index, editMode, rowError }) => (
          <MaterialUnitPriceInput
            item={item}
            price={item.vendorPrices[index || 0]}
            readonly={!editMode}
            error={rowError}
          />
        ),
        position: "center",
        header: <FormattedMessage id="PRICE" />,
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item, editMode, rowError }) => (
          <MaterialPriceUom
            item={item}
            price={item.vendorPrices[0]}
            readonly={!editMode}
            error={rowError}
          />
        ),
        position: "center",
        header: <FormattedMessage id="PER_UOM_HEADER" />,
        details: ({ item, index, editMode, rowError }) => (
          <MaterialPriceUom
            item={item}
            price={item.vendorPrices[index || 0]}
            readonly={!editMode}
            error={rowError}
          />
        ),
      },
      {
        wrapper: DateContainer,
        item: ({ item, editMode }) => (
          <MaterialDatePicker
            item={item}
            price={item.vendorPrices[0]}
            readonly={!editMode}
          />
        ),
        position: "center",
        header: <FormattedMessage id="PRICE_EXPIRATION" />,
        details: ({ item, index, editMode }) => (
          <MaterialDatePicker
            item={item}
            price={item.vendorPrices[index || 0]}
            readonly={!editMode}
          />
        ),
      },
      {
        wrapper: ActionsContainer,
        item: ({ item, editMode, setEditMode }) => {
          return (
            <MaterialPriceItemActions
              item={item}
              price={item.vendorPrices[0]}
              readonly={!editMode}
              setEditMode={setEditMode}
            />
          );
        },
        details: ({ item, index, editMode, setEditMode }) => {
          return (
            <MaterialPriceItemActions
              item={item}
              key={index}
              index={index}
              price={item.vendorPrices[index || 0]}
              readonly={!editMode}
              setEditMode={setEditMode}
            />
          );
        },
      },
      {
        wrapper: FullRowContainer,
        item: ({ item, editMode }) => (
          <MaterialPriceExtraDetails
            item={item}
            price={item.vendorPrices[0]}
            readonly={!editMode}
          />
        ),
        details: ({ item, index, editMode }) => {
          return (
            <MaterialPriceExtraDetails
              item={item}
              price={item.vendorPrices[index || 0]}
              readonly={!editMode}
            />
          );
        },
      },
    ],
    [hasManufacturersSetting, hasPermissions],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "MATERIAL_NAME" }),
        columnId: "material",
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "VENDOR" }),
        columnId: "vendorName",
        columnType: COLUMN_TYPE.Vendor,
        validator: combineValidators([requiredValidator, vendorValidator]),
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
        validator: requiredValidator,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "uom.pluralDescription",
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "price",
        columnType: COLUMN_TYPE.UnitPrice,
        validator: combineValidators([
          requiredValidator,
          positiveNumberValidator,
        ]),
      },
      {
        header: intl.$t({ id: "PRICE_EXPIRATION" }),
        columnId: "expirationDate",
        columnType: COLUMN_TYPE.ExpirationDate,
      },
      {
        header: intl.$t({ id: "ORDER_INCREMENT" }),
        columnId: "orderIncrement",
        columnType: COLUMN_TYPE.OrderIncrement,
      },
      {
        header: intl.$t({ id: "MINIMUM_ORDER" }),
        columnId: "minimumOrder",
        columnType: COLUMN_TYPE.MinimumOrder,
      },
      {
        header: intl.$t({ id: "LEAD_TIME_IN_DAYS" }),
        columnId: "leadTime",
        columnType: COLUMN_TYPE.LeadTime,
      },
    ],
    [
      intl,
      requiredValidator,
      positiveNumberValidator,
      combineValidators,
      vendorValidator,
    ],
  );

  return { configuration, spreadsheetConfig };
};
