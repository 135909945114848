import { Checkbox } from "@/common/components/checkbox/Checkbox";
import { Popover } from "@/common/components/popover/Popover";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Chip,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material";
import React, { ReactNode, Ref, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../../../if/If";
import { Loader } from "../../../loader/Loader";
import { Tooltip } from "../../../tooltip/Tooltip";
import { AvatarStyled } from "../../styles/Selector.styles";
import { getMultipleValueMaxWidth } from "../../utils/getMultipleValueMaxWidth";
import { InputRenderer } from "../common/InputRenderer";
import { PopupIcon } from "../common/PopupIcon";
import { SelectCommonProps } from "../common/SelectCommonProps";
import { CustomPopper } from "../single/components/CustomPoper";

const CheckboxPlaceholder = tw.div`w-[30px]`;

export type MultiselectProps<T> = SelectCommonProps<T> & {
  chipSize?: "small" | "medium";
  disableCloseOnSelect?: boolean;
  filterSelectedOptions?: boolean;
  includeCheckbox?: boolean;
  includeCheckboxFn?: (option: T) => boolean;
  optionDisabledFn?: (option: T) => boolean;
  optionTooltipRenderFn?: (option: T) => ReactNode;
  limitTags?: number;
  moreItems?: ReactNode;
  onMultipleChange?: (value: string[] | null) => void;
  onInputChange?: (value: string | undefined) => void;
  renderTag?: (option: T) => ReactNode;
  values?: string[] | null;
  noWrap?: boolean;
  xs?: boolean;
  disabledFn?: (option: T) => boolean;
  applyHeaderAccent?: boolean;
  groupBy?: (option: T) => string;
  slotProps?: AutocompleteProps<T, true, boolean, boolean>["slotProps"];
};

const CREATABLE_KEYS = ["[", "]"];

const MultiselectWithoutRef = <T,>(
  {
    chipSize,
    className,
    creatable = false,
    creatableFn,
    creatableTextKey,
    customRender,
    disableClearable = true,
    disabled,
    optionDisabledFn,
    optionTooltipRenderFn,
    getLabel,
    getOptionLabel,
    getValue,
    includeCheckbox = false,
    includeCheckboxFn,
    inputValue,
    limitTags,
    loading = false,
    moreItems,
    onMultipleChange,
    onInputChange,
    options,
    popup,
    renderTag,
    staticText = false,
    testId,
    values,
    noWrap,
    sx,
    xs,
    error,
    creatableUseLabel,
    creatableFirstOption,
    applyHeaderAccent = false,
    slotProps,
    ...props
  }: MultiselectProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const intl = useIntl();

  const filter = useMemo(
    () => creatableFn && createFilterOptions<T>(),
    [creatableFn],
  );

  const selectedOption = useMemo(() => {
    const option = creatable
      ? values?.map((v) => options.find((o) => getValue(o) === v) ?? v)
      : options.filter((o) => values?.includes(getValue(o)));
    return option || undefined;
  }, [creatable, values, options, getValue]);

  const getValueWithDefaultValue = useCallback(
    (option: T | null) => (option ? getValue(option) || "" : ""),
    [getValue],
  );

  const onChange = useCallback(
    (_: React.SyntheticEvent, value: (string | T)[]) => {
      const values = value.map((v, index) => {
        let val = getValueWithDefaultValue(v as T);
        if (index === value.length - 1 && !val) {
          const label = getLabel(v as T);

          if (label) {
            val = label.slice(
              label.indexOf(CREATABLE_KEYS[0]) + 1,
              label.lastIndexOf(CREATABLE_KEYS[1]),
            );
          } else {
            val = value[value.length - 1] as unknown as string;
          }
        }

        return val;
      });

      onMultipleChange?.(values);
    },
    [onMultipleChange, getValueWithDefaultValue, getLabel],
  );

  const filterOptions = useMemo(
    () =>
      creatable && creatableFn
        ? (options: T[], params: FilterOptionsState<T>) => {
            if (creatable) {
              const filtered = filter
                ? filter(options, {
                    ...params,
                    getOptionLabel: creatableUseLabel
                      ? getLabel
                      : getOptionLabel || getLabel,
                  })
                : options;
              const { inputValue } = params;
              if (
                inputValue !== "" &&
                filtered &&
                !filtered.find((o) => getLabel(o) === inputValue) &&
                typeof values === "object" &&
                !values?.find((o) => o === inputValue)
              ) {
                const creatableResults = creatableFn(
                  intl.$t(
                    { id: creatableTextKey || "CREATABLE_ADD_NEW" },
                    { inputValue },
                  ),
                  inputValue,
                );
                if (creatableResults) {
                  const mappedCreatableResults = (
                    (creatableResults as Array<T>)?.length > 0
                      ? creatableResults
                      : [creatableResults]
                  ) as Array<T>;

                  return creatableFirstOption
                    ? mappedCreatableResults.concat(filtered)
                    : filtered.concat(mappedCreatableResults);
                }
                return filtered;
              }
              return filtered;
            }

            return options;
          }
        : undefined,
    [
      creatable,
      creatableFirstOption,
      creatableFn,
      creatableTextKey,
      creatableUseLabel,
      filter,
      getLabel,
      getOptionLabel,
      intl,
      values,
    ],
  );

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: T,
      { selected }: AutocompleteRenderOptionState,
    ) => {
      const value = getLabel(option);
      const key = getValue(option);

      const optionDisabled =
        !!optionDisabledFn && optionDisabledFn(option) === true;
      const optionTooltip = optionTooltipRenderFn?.(option);

      const element = (
        <li
          {...props}
          key={key}
          className={`${props.className} ${customRender ? "m-0 py-0" : ""} ${optionDisabled ? "cursor-default opacity-30" : ""}`}
          data-testid={`${testId}-option-${key}`}
          onClick={(e) => {
            if (optionDisabled) {
              e.preventDefault();
              e.stopPropagation();
            } else {
              props.onClick?.(e);
            }
          }}
        >
          <If
            isTrue={
              !!includeCheckboxFn ? includeCheckboxFn(option) : includeCheckbox
            }
          >
            <Checkbox checked={selected} className="pr-4" />
          </If>
          <If
            isTrue={!!includeCheckboxFn && includeCheckboxFn(option) === false}
          >
            <CheckboxPlaceholder />
          </If>
          <If isTrue={customRender}>{customRender && customRender(option)}</If>
          <If isTrue={!customRender}>{value}</If>
        </li>
      );

      if (!!optionTooltip) {
        return (
          <Popover $arrow id={JSON.stringify(option)} element={element}>
            {optionTooltip}
          </Popover>
        );
      }

      return element;
    },
    [
      customRender,
      getLabel,
      getValue,
      includeCheckbox,
      includeCheckboxFn,
      optionDisabledFn,
      optionTooltipRenderFn,
      testId,
    ],
  );

  const sxClasses = useMemo(
    () => ({
      ...sx,
      ...(noWrap && {
        ".MuiInputBase-formControl.MuiAutocomplete-inputRoot": {
          flexWrap: "nowrap",
        },
      }),
    }),
    [noWrap, sx],
  );

  const renderTags = useCallback(
    (value: T[], getTagProps: AutocompleteRenderGetTagProps) => {
      const tags = value
        .slice(limitTags ? 0 : undefined, limitTags)
        .map((option, index) =>
          renderTag ? (
            renderTag(option)
          ) : (
            <Chip
              label={getOptionLabel ? getOptionLabel(option) : getLabel(option)}
              title={getLabel(option)}
              size={chipSize}
              {...getTagProps({ index })}
              className={`float-left -my-0.5 mx-0.5 ${applyHeaderAccent ? "bg-blue-400 text-white" : ""} ${getMultipleValueMaxWidth(
                limitTags,
                values?.length,
              )}`}
              key={index}
              sx={
                applyHeaderAccent
                  ? {
                      "& .MuiChip-deleteIcon": {
                        color: "white",
                      },
                    }
                  : {}
              }
            />
          ),
        );
      const additionalItems =
        limitTags && limitTags < value.length
          ? value.length - (limitTags || 0)
          : undefined;
      return (
        <>
          {tags}
          <If isTrue={additionalItems}>
            <Tooltip
              id="limit"
              color="secondary"
              element={<AvatarStyled>+{additionalItems}</AvatarStyled>}
            >
              {moreItems}
            </Tooltip>
          </If>
        </>
      );
    },
    [
      chipSize,
      getLabel,
      getOptionLabel,
      limitTags,
      moreItems,
      renderTag,
      values,
      applyHeaderAccent,
    ],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <InputRenderer
        props={{ ...props, className, staticText, error, xs }}
        params={params}
      />
    ),
    [className, error, props, staticText, xs],
  );

  return (
    <Autocomplete
      ref={ref}
      {...props}
      options={options}
      multiple
      className={className}
      data-testid={testId}
      inputValue={inputValue}
      disableClearable={disableClearable}
      PopperComponent={CustomPopper}
      onChange={onChange}
      isOptionEqualToValue={(option, value) =>
        getValue(option) === getValue(value)
      }
      value={selectedOption}
      getOptionLabel={(option) =>
        getOptionLabel
          ? getOptionLabel(option as T) || ""
          : getLabel(option as T) || ""
      }
      onInputChange={(event, value) => {
        onInputChange?.(value);
      }}
      freeSolo={creatable}
      filterOptions={filterOptions}
      disabled={disabled}
      readOnly={staticText || disabled}
      popupIcon={
        <PopupIcon
          disabled={disabled || staticText}
          loading={loading}
          popup={popup}
        />
      }
      renderTags={renderTags}
      ChipProps={{ size: chipSize }}
      loading={loading}
      loadingText={<Loader loading small />}
      limitTags={limitTags}
      renderInput={renderInput}
      sx={sxClasses}
      renderOption={renderOption}
      slotProps={slotProps}
    />
  );
};

export const Multiselect = React.forwardRef(MultiselectWithoutRef) as <T>(
  props: MultiselectProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof MultiselectWithoutRef>;
