import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import tw from "tailwind-styled-components";
import { ORDER_TOTAL_PANEL_WIDTH } from "../../../../../../../contractor/pages/home/invoices/pages/invoice-verification/components/footer/InvoiceFooterTotals";
import { InvoiceFooterExpanded } from "./expandable/InvoiceFooterExpanded";
import { InvoiceFooterButtons } from "./InvoiceFooterButtons";
import { InvoiceFooterTotals } from "./InvoiceFooterTotals";

const FloatingFooterStyled = tw(FloatingFooter)``;
const InvoiceFooterContainer = tw.div`flex h-10`;
const Placeholder = tw.div<{
  $orderTotalPanelWidth: number;
}>`flex flex-1 ${({ $orderTotalPanelWidth }) => `max-w-[calc(60%-${$orderTotalPanelWidth}px)]`}`;

export const InvoiceFooter = () => {
  return (
    <FloatingFooterStyled expandedChildren={<InvoiceFooterExpanded />}>
      <InvoiceFooterContainer>
        <Placeholder $orderTotalPanelWidth={ORDER_TOTAL_PANEL_WIDTH} />
        <InvoiceFooterTotals />
        <InvoiceFooterButtons />
      </InvoiceFooterContainer>
    </FloatingFooterStyled>
  );
};
