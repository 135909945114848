import { If } from "@/common/components/if/If";
import { ImportExternalResultTitle } from "@/common/components/import-external-result-title/ImportExternalResultTitle";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportPaymentMethodsStore } from "../../../stores/useImportPaymentMethodsStore";
import { useImportExternalPaymentMethodsResultConfiguration } from "./ImportExternalPaymenMethodsResults.configuration";

const Container = tw.div`flex flex-1 self-stretch flex-col`;

export const ImportExternalPaymentMethodsImportResults = () => {
  const intl = useIntl();
  const configuration = useImportExternalPaymentMethodsResultConfiguration();
  const { importedPaymentMethods } = useImportPaymentMethodsStore();

  return (
    <Container>
      <If isTrue={(importedPaymentMethods?.created ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedPaymentMethods?.created ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "CREATED_PAYMENT_METHODS_COUNT" },
                { count: (importedPaymentMethods?.created ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16"
        />
      </If>
      <If isTrue={(importedPaymentMethods?.mapped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedPaymentMethods?.mapped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "MATCHED_PAYMENT_METHODS_COUNT" },
                { count: (importedPaymentMethods?.mapped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
      <If isTrue={(importedPaymentMethods?.skipped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedPaymentMethods?.skipped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "SKIPPED_PAYMENT_METHODS_COUNT" },
                { count: (importedPaymentMethods?.skipped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
    </Container>
  );
};
