import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { routes } from "@/config/routes";
import { OrgRole, SystemRole } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { AdminNavigation } from "./AdminNavigation";

export const WithAdminNavigation: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { viewer, loading } = useStartupDataStore(
    useShallow((state) => ({
      viewer: state.viewer,
      loading: state.loading,
    })),
  );

  const isAdmin = useMemo(() => {
    return (
      viewer?.systemRoles.includes(SystemRole.FmAdmin) ||
      viewer?.orgRoles.includes(OrgRole.OrgAdmin) ||
      viewer?.orgRoles.includes(OrgRole.OrgRestrictedAdmin)
    );
  }, [viewer]);

  return loading ? null : isAdmin ? (
    <>
      <AdminNavigation />
      {children}
    </>
  ) : (
    <Navigate to={routes.home} replace />
  );
};
