import { GridTable } from "@/common/components/grid-table/GridTable";
import { AdminHeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import {
  AdminOrgPreferredVendorsFieldsFragment,
  OrgType,
} from "@/generated/graphql";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { LocationsProvider } from "../locations/providers/LocationsProvider";
import { PageContainer } from "./Vendors.styles";
import {
  EditVendorPanelId,
  ToggleEditVendor,
} from "./components/ToggleEditVendor";
import { useVendorsTableConfiguration } from "./components/Vendors.configuration";
import { NewVendorButton } from "./components/vendor-form/NewVendorButton";
import { ExternalVendorMappingModal } from "./components/vendor-mapping/ExternalVendorMappingModal";
import { useAdminVendors } from "./hooks/useAdminVendors";
import { AgaveExternalVendorProvider } from "./providers/AgaveExternalVendorProvider";
import { ExternalVendorMappingProvider } from "./providers/ExternalVendorMappingProvider";
import { OrgsProvider } from "./providers/OrgsProvider";

const VENDOR_PAGE_SIZE = 200;

const HeaderGroup = tw.div`
  grid grid-flow-col justify-between w-full
`;

export type GroupedVendor = {
  id: string;
  org: AdminOrgPreferredVendorsFieldsFragment["sellerOrgLocation"]["org"];
  orgPreferredVendors: AdminOrgPreferredVendorsFieldsFragment[];
};

const VendorsWithProvider = () => {
  const intl = useIntl();
  const { vendorId } = useParams();
  const { toggle } = useGlobalDrawer();
  const { vendors, loading, searchQuery, setSearchQuery, totalCount } =
    useAdminVendors();
  const vendorsTableConfiguration = useVendorsTableConfiguration();
  const [initialToggle, setInitialToggle] = useState(false);

  const groupedVendors = useMemo(() => {
    const list: GroupedVendor[] = [];
    vendors.forEach((vendor) => {
      if (
        list.find((item) => item.org.id === vendor.sellerOrgLocation.org.id)
      ) {
        list
          .find((item) => item.org.id === vendor.sellerOrgLocation.org.id)
          ?.orgPreferredVendors.push(vendor);
      } else {
        list.push({
          id: vendor.id,
          org: vendor.sellerOrgLocation.org,
          orgPreferredVendors: [vendor],
        });
      }
    });
    return list;
  }, [vendors]);

  useEffect(() => {
    if (!initialToggle && vendorId && vendors.length > 0) {
      toggle(EditVendorPanelId, true, vendorId);
      setInitialToggle(true);
    }
  }, [initialToggle, toggle, vendorId, vendors.length]);

  return (
    <PageContainer>
      <Helmet>
        <title>{intl.$t({ id: "VENDORS" })}</title>
      </Helmet>
      <AdminHeaderContainer>
        <HeaderGroup>
          <SearchInput
            placeHolder={intl.$t({ id: "SEARCH_VENDOR_AND_CONTACTS" })}
            onChange={setSearchQuery}
            value={searchQuery}
            className="w-64"
          />
          <NewVendorButton />
        </HeaderGroup>
      </AdminHeaderContainer>
      <ListRenderer totalCount={totalCount} count={vendors.length} paginated>
        <GridTable
          configuration={{
            columns: vendorsTableConfiguration,
            toggle: {
              item: (vendor) => toggle(EditVendorPanelId, true, vendor.id),
            },
            classNames: {
              header: "top-21",
            },
          }}
          expandedItems={(item) =>
            item.orgPreferredVendors
              .slice(1, item.orgPreferredVendors.length + 1)
              .map((i) => ({
                ...item,
                orgPreferredVendors: item.orgPreferredVendors.slice(
                  1,
                  item.orgPreferredVendors.length + 1,
                ),
                id: i.id,
              }))
          }
          loading={loading}
          items={groupedVendors}
          emptyList={
            <NoResults
              filteredTranslationKey="NO_RESULTS_VENDORS_FILTERED"
              translationKey="NO_RESULTS_VENDORS"
              isFiltered={!!searchQuery}
            />
          }
        />
      </ListRenderer>
      <ToggleEditVendor vendors={vendors} />
      <ExternalVendorMappingModal />
    </PageContainer>
  );
};

export const Vendors = () => {
  return (
    <PaginationProvider customPageSize={VENDOR_PAGE_SIZE}>
      <NestedStepperProvider>
        <LocationsProvider>
          <OrgsProvider type={OrgType.Distributor}>
            <AgaveExternalVendorProvider>
              <ExternalVendorMappingProvider>
                <VendorsWithProvider />
              </ExternalVendorMappingProvider>
            </AgaveExternalVendorProvider>
          </OrgsProvider>
        </LocationsProvider>
      </NestedStepperProvider>
    </PaginationProvider>
  );
};
