import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUser } from "@/common/providers/UserProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  AddWarehouseInput,
  UpdateWarehouseInput,
  WarehouseFieldsFragment,
  WarehousesDocument,
  useAddWarehouseMutation,
  useArchiveWarehouseMutation,
  useUpdateWarehouseMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, PropsWithChildren, createContext, useContext } from "react";
import { useShallow } from "zustand/react/shallow";

type ContextProps = {
  warehouses: WarehouseFieldsFragment[];
  loading: boolean;
  createWarehouse: (warehouse: AddWarehouseInput) => Promise<boolean>;
  updateWarehouse: (warehouse: UpdateWarehouseInput) => Promise<boolean>;
  archiveWarehouse: (id: string) => Promise<boolean | undefined>;
};

const ProviderContext = createContext<ContextProps>({
  warehouses: [],
  loading: false,
  createWarehouse: NoFunctionBooleanPromise,
  updateWarehouse: NoFunctionBooleanPromise,
  archiveWarehouse: NoFunctionBooleanPromise,
});

export const WarehousesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { viewer } = useUser();
  const { warehouses, loading, updateStartupData } = useStartupDataStore(
    useShallow((state) => ({
      warehouses: state.warehouses,
      loading: state.loading,
      updateStartupData: state.updateStartupData,
    })),
  );
  const { setError } = useGlobalError();
  const [archiveWarehouseMutation] = useArchiveWarehouseMutation();
  const [updateWarehouseMutation] = useUpdateWarehouseMutation();
  const [addWarehouseMutation] = useAddWarehouseMutation();

  const createWarehouse = async (warehouse: AddWarehouseInput) => {
    try {
      const { data, errors } = await addWarehouseMutation({
        variables: { input: warehouse },
        refetchQueries: [
          {
            query: WarehousesDocument,
            variables: { id: viewer?.org.id || "" },
          },
        ],
      });
      setError(errors);
      if (data?.addWarehouse) {
        updateStartupData({
          warehouses: [...warehouses, data?.addWarehouse],
        });
      }
      return !!data?.addWarehouse;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const updateWarehouse = async (warehouse: UpdateWarehouseInput) => {
    try {
      const { data, errors } = await updateWarehouseMutation({
        variables: { input: warehouse },
        refetchQueries: [
          {
            query: WarehousesDocument,
            variables: { id: viewer?.org.id || "" },
          },
        ],
      });
      setError(errors);
      if (data?.updateWarehouse) {
        updateStartupData({
          warehouses: warehouses.map((w) =>
            w.id === data?.updateWarehouse.id ? data?.updateWarehouse : w,
          ),
        });
      }
      return !!data?.updateWarehouse;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const archiveWarehouse = async (id: string) => {
    try {
      const { data, errors } = await archiveWarehouseMutation({
        variables: { id },
        refetchQueries: [
          {
            query: WarehousesDocument,
            variables: { id: viewer?.org.id || "" },
          },
        ],
      });
      setError(errors);
      if (data?.archiveWarehouse) {
        updateStartupData({
          warehouses: warehouses.filter((w) => w.id !== id),
        });
      }
      return !!data?.archiveWarehouse;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        warehouses,
        loading,
        createWarehouse,
        updateWarehouse,
        archiveWarehouse,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useWarehouses = (): ContextProps => useContext(ProviderContext);
