import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import {
  Base,
  HiddenMobile,
  MdFixedContainer,
  NotesContainer,
  SmFixedContainer,
  ToggleContainer,
} from "@/common/layout/ResponsiveClasses";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { useItemsHavePhaseCodes } from "@/distributor/pages/buyout/common/hooks/useItemsHavePhaseCodes";
import {
  DistributorReleaseFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ReleaseStatus,
  TransactionKind,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { checkReleaseStatus } from "../../../../../common/utils/status-checks/checkReleaseStatus";
import { DistributorReleaseCategoryToggle } from "../DistributorReleaseCategoryToggle";
import { DistributorReleaseCostCodeManufacturerSelector } from "../DistributorReleaseCostCodeManufacturerSelector";
import { DistributorReleaseDeliveryDate } from "../DistributorReleaseDeliveryDate";
import { DistributorReleaseDeliveryUnits } from "../DistributorReleaseDeliveryUnits";
import { DistributorReleaseItemActions } from "../DistributorReleaseItemActions";
import { DistributorReleaseItemToggle } from "../DistributorReleaseItemToggle";
import { DistributorReleaseManufacturerSelector } from "../DistributorReleaseManufacturerSelector";
import { DistributorReleaseNotes } from "../DistributorReleaseNotes";
import { DistributorReleaseNotesDetails } from "../DistributorReleaseNotesDetails";
import { DistributorReleasePriceInput } from "../DistributorReleasePriceInput";
import { DistributorReleaseQuantityInput } from "../DistributorReleaseQuantityInput";
import { DistributorGroupByCostCodeToggle } from "../distributor-group-by-costcode/DistributorGroupByCostCodeToggle";
import { DistributorReleaseItem } from "./DistributorReleaseItem";
import { DistributorReleaseTags } from "./DistributorReleaseTags";

const ItemWrapper = tw(Base)`col-span-full flex-1 truncate`;

const ToggleContainerWrapper = tw(
  ToggleContainer,
)`col-span-3 justify-self-start absolute top-3 right-3 md:static`;
const DeliveryDateWrapper = tw(SmFixedContainer)`col-span-4 md:col-span-1 mr-4`;
const ManufacturerWrapper = tw(MdFixedContainer)`col-span-4 md:col-span-1`;
const UnitsWrapper = tw(MdFixedContainer)`col-span-4 md:col-span-1`;
const ReleaseQuantityWrapper = tw(MdFixedContainer)`col-span-2 md:col-span-1`;
const UnitPriceWrapper = tw(
  SmFixedContainer,
)`col-span-2 md:col-span-1 h-8 md:h-auto`;
const ActionsContainer = tw(Base)`w-6 basis-6`;

export const useDistributorReleaseConfiguration = (
  release: DistributorReleaseFieldsFragment | null | undefined,
): Array<GridCol<DistributorReleaseItemFieldsFragment>> => {
  const { getVendorHasManufacturerSetting } = useManufacturersSettings();
  const hasManufacturersSetting = getVendorHasManufacturerSetting(
    release?.orgLocation?.org.settings?.display,
  );
  const itemsHavePhaseCodes = useItemsHavePhaseCodes();
  const { hasPhaseCodes } = useOrgSettings();

  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  return useMemo(() => {
    return [
      {
        wrapper: ItemWrapper,
        item: ({ item, count }) => (
          <DistributorReleaseItem item={item} count={count} />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            itemsHavePhaseCodes={itemsHavePhaseCodes(category.items)}
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEMS_IN_DELIVERY" />
            <DistributorGroupByCostCodeToggle />
          </>
        ),
        includesCounter: true,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />,
        item: ({ item }) => {
          return <DistributorReleaseTags item={item} />;
        },
      },
      {
        wrapper: NotesContainer,
        item: ({ item }) => <DistributorReleaseNotes item={item} />,
        details: ({ item, readonly }) => {
          return (
            <DistributorReleaseNotesDetails item={item} readonly={readonly} />
          );
        },
        header: <FormattedMessage id="NOTES" />,
        hidden:
          release?.status === ReleaseStatus.Received ||
          release?.status === ReleaseStatus.PartiallyReceived ||
          release?.status === ReleaseStatus.Canceled,
      },
      {
        wrapper: ToggleContainerWrapper,
        item: ({ item, readonly }) => (
          <DistributorReleaseItemToggle item={item} readonly={readonly} />
        ),
        header: <FormattedMessage id="INCLUDE_ITEM" />,
        subgroup: (category, readonly) => (
          <HiddenMobile>
            <DistributorReleaseCategoryToggle
              items={category.items}
              readonly={readonly}
            />
          </HiddenMobile>
        ),
        position: "center",
      },
      {
        wrapper: ManufacturerWrapper,
        item: ({ item, readonly, index }) => (
          <NotNullableRenderer value={!isLumpSumItem(item)}>
            <DistributorReleaseManufacturerSelector
              item={item}
              readonly={readonly}
              index={index}
            />
          </NotNullableRenderer>
        ),
        position: "center",
        header: <FormattedMessage id="PROVIDED_MANUFACTURER" />,
        subgroup: (category, readonly) => (
          <HiddenMobile>
            <DistributorReleaseCostCodeManufacturerSelector
              category={category}
              readonly={readonly}
            />
          </HiddenMobile>
        ),
        hidden: !hasManufacturersSetting,
      },
      {
        wrapper: UnitsWrapper,
        item: ({ item, readonly }) => (
          <DistributorReleaseDeliveryUnits
            item={item}
            readonly={readonly || isLumpSumItem(item)}
          />
        ),
        position: "center",
        header: <FormattedMessage id="UOM" />,
        hidden: checkReleaseStatus(release, [
          ReleaseStatus.Received,
          ReleaseStatus.PartiallyReceived,
          ReleaseStatus.Canceled,
        ]),
      },
      {
        wrapper: ReleaseQuantityWrapper,
        item: ({ item, readonly }) => (
          <NotNullableRenderer value={!isLumpSumItem(item)}>
            <DistributorReleaseQuantityInput
              label={<FormattedMessage id="ITEMS_IN_DELIVERY" />}
              item={item}
              readonly={readonly}
            />
          </NotNullableRenderer>
        ),
        group: (category) => (
          <ProductCategoryCount
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            itemsLength={category.items.flatMap((i: any) => i.items).length}
            $bold
          />
        ),
        subgroup: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
        header: <FormattedMessage id="QUANTITY_TO_FULFILL" />,
        position: "center",
      },
      {
        wrapper: UnitPriceWrapper,
        item: ({ item, readonly, index }) => (
          <NotNullableRenderer value={!isLumpSumItem(item)}>
            <DistributorReleasePriceInput
              label={<FormattedMessage id="UNIT_PRICE" />}
              item={item}
              readonly={readonly}
              release={release}
              index={index}
            />
          </NotNullableRenderer>
        ),
        header: <FormattedMessage id="UNIT_COST" />,
        position: "center",
      },
      {
        wrapper: UnitPriceWrapper,
        item: ({ item, readonly, index }) => (
          <DistributorReleasePriceInput
            label={<FormattedMessage id="EXT_PRICE" />}
            item={item}
            readonly={readonly || !isLumpSumItem(item)}
            release={release}
            index={index}
            extPrice
          />
        ),
        header: <FormattedMessage id="EXT_PRICE" />,
        position: "center",
      },
      {
        wrapper: DeliveryDateWrapper,
        item: ({ item, readonly }) => (
          <DistributorReleaseDeliveryDate item={item} readonly={readonly} />
        ),
        header: (
          <FormattedMessage id={hasEndDate ? "END_DATE" : "DELIVERY_DATE"} />
        ),
        position: "center",
      },
      {
        wrapper: ActionsContainer,
        item: ({ item }) => <DistributorReleaseItemActions item={item} />,
      },
    ];
  }, [
    hasManufacturersSetting,
    release,
    itemsHavePhaseCodes,
    hasPhaseCodes,
    hasEndDate,
  ]);
};
