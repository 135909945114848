import {
  OrgPreferredVendorsFieldsFragment,
  VendorContact,
} from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { OrgLogo } from "../org-logo/OrgLogo";

const VendorItem = tw.div`
  w-full py-[6px] grid grid-flow-col gap-x-2 justify-start items-center
`;
const Separator = tw.span`mx-1`;
const VendorOrg = tw.span``;
const VendorContacts = tw.span`text-xs relative bottom-[1px]`;

export const VendorPickerCustomRender = (
  item:
    | OrgPreferredVendorsFieldsFragment
    | {
        value: string | undefined;
        name: string;
      },
  opts?: {
    filter?: (
      contact: OrgPreferredVendorsFieldsFragment["contacts"][0],
    ) => boolean;
    shouldShowVendorCode?: boolean;
  },
) => {
  const vendor = item as OrgPreferredVendorsFieldsFragment;
  const sellerOrgLocation = vendor?.sellerOrgLocation;
  const contacts = vendor?.contacts;
  const vendorCode = vendor?.externalCode;
  const vendorName = (item as { name: string })?.name;

  return (
    <VendorItem>
      {sellerOrgLocation ? (
        <>
          <OrgLogo
            logoImageUrl={sellerOrgLocation?.org?.photoUrl}
            name={sellerOrgLocation?.org.id ? sellerOrgLocation?.org?.name : ""}
            width={24}
          />
          <div>
            <VendorOrg>
              {vendorLabelFormatter(sellerOrgLocation, [], {
                vendorCode: opts?.shouldShowVendorCode ? vendorCode : null,
              })}
            </VendorOrg>
            {opts?.filter && contacts?.length > 0 ? (
              <>
                <Separator>-</Separator>
                <VendorContacts>
                  {contacts
                    .filter(opts.filter)
                    .map((c) => c.name)
                    .join(", ")}
                </VendorContacts>
              </>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <OrgLogo name={" "} width={24} />
          {vendorName ?? null}
        </>
      )}
    </VendorItem>
  );
};

type VendorLabelFormatter = (
  location?: {
    org: {
      name: string;
    };
    address?: {
      city: string;
      state: string;
    } | null;
  } | null,
  contacts?: VendorContact[],
  opts?: { vendorCode?: string | null },
) => string;

export const vendorLabelFormatter: VendorLabelFormatter = (
  location,
  contacts = [],
  opts = {},
) => {
  if (!location) {
    return "";
  }
  const locationName = location.address
    ? `(${location.address?.city}, ${location.address?.state})`
    : "";
  const name = `${location.org?.name} ${locationName}${opts?.vendorCode ? ` [${opts.vendorCode}]` : ""}`;
  return `${name}${
    contacts.length > 0 ? ` - ${contacts.map((c) => c.name).join(", ")}` : ""
  }`;
};
