import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  pagesWithParams: {
    [key: string]: string;
  };
  setParamsForPage: (page: string, params: string) => void;
};

export const usePageParamsStore = create<State>()(
  devtools((set, get) => ({
    pagesWithParams: {},
    setParamsForPage: (page: string, params: string) =>
      set({ pagesWithParams: { ...get().pagesWithParams, [page]: params } }),
  })),
);
