import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import {
  ItemContainer,
  SalesReadonlyTaxLabel,
  SalesTaxLabel,
  SalesTaxValue,
} from "@/common/components/sales-tax-input/styles/SalesTaxInput.styles";
import { UpdateSalesTaxFunction } from "@/common/components/sales-tax-input/types/SalesTaxInputProps";
import { useUser } from "@/common/providers/UserProvider";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const PricePickerStyled = tw(PricePicker)`w-full text-right text-sm
${(props) => props.readonly && "bg-transparent"}`;

type Props = {
  value: string | number | null;
  setValue: (value: string | null) => void;
  update?: UpdateSalesTaxFunction;
  readonly?: boolean;
  className?: string;
};

export const SalesTaxVarianceInput: React.FC<Props> = ({
  setValue,
  value,
  update,
  readonly,
  className,
}) => {
  const intl = useIntl();
  const { currencySymbol, isContractor } = useUser();
  const onBlur = useCallback(
    async (newValue: string | null) => {
      const value = isNaN(parseFloat(newValue || "")) ? null : newValue;
      setValue(newValue);
      const input = {
        taxVariance: value,
        ...(isContractor && { clearTaxVariance: !value }),
      };

      await update?.(input);
    },
    [setValue, isContractor, update],
  );
  return (
    <ItemContainer className={className}>
      <SalesTaxLabel>
        <FormattedMessage
          id="SALES_TAX_VARIANCE"
          tagName={SalesReadonlyTaxLabel}
        />
        <InfoTooltip message={intl.$t({ id: "SALES_TAX_VARIANCE_TOOLTIP" })} />
      </SalesTaxLabel>
      <SalesTaxValue>
        <PricePickerStyled
          testId="tax-variance"
          value={value}
          onBlur={onBlur}
          decimals={2}
          placeholder={intl.$t(
            { id: "SALES_TAX_VARIANCE_PLACEHOLDER" },
            { currency: currencySymbol },
          )}
          readonly={readonly}
        />
      </SalesTaxValue>
    </ItemContainer>
  );
};
