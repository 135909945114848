import { useReleasePredictedPoNumberQuery } from "@/generated/graphql";

export const useReleasePredictedPoNumber = (id: string | undefined | null) => {
  const { data, loading } = useReleasePredictedPoNumberQuery({
    variables: {
      id: id || "",
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  return {
    predictedPoNumber: data?.release?.predictedPoNumber,
    loading,
  };
};
