import React, { FC } from "react";
import tw from "tailwind-styled-components";
import { InfoHeaderItem } from "./InfoHeaderItem";

const Details = tw.div`
  grid grid-flow-col gap-2 justify-start
`;

const Label = tw.div`
  text-xs md:text-sm font-medium text-right md:text-left
`;

const Value = tw.div`
  text-xs md:text-sm font-light
`;

const ListContent = tw.div`
  grid grid-flow-row justify-start
`;

type Props = {
  title?: string | React.JSX.Element;
  list: {
    label: string | React.JSX.Element;
    value: string | React.JSX.Element;
    hide?: boolean;
  }[];
  classes?: {
    list?: string;
    details?: string;
    value?: string;
    label?: string;
  };
};

export const ListDetailsHeaderItem: FC<Props> = ({
  title = "",
  list,
  classes,
}) => {
  return (
    <InfoHeaderItem title={title}>
      <ListContent className={classes?.list}>
        {list
          .filter((item) => !item.hide)
          .map(({ label, value }, key) => {
            return (
              <Details key={key} className={classes?.details}>
                <Label className={classes?.label}>{label}</Label>
                <Value className={classes?.value}>{value}</Value>
              </Details>
            );
          })}
      </ListContent>
    </InfoHeaderItem>
  );
};
