import { GridTable } from "@/common/components/grid-table/GridTable";
import { AdminHeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { NewUserButton } from "./components/NewUserButton";
import { tableConfiguration } from "./components/Users.tableConfiguration";
import { useAdminUsers } from "./hooks/useAdminUsers";

const Container = tw.div``;

export const Users = () => {
  const { users, loading, error } = useAdminUsers();
  const intl = useIntl();

  return (
    <Container>
      <Helmet>
        <title>{intl.$t({ id: "USERS" })}</title>
      </Helmet>
      <FormattedMessage id="USERS">
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <AdminHeaderContainer>
        <NewUserButton />
      </AdminHeaderContainer>
      <GridTable
        items={users}
        configuration={{
          columns: tableConfiguration,
          classNames: { header: "top-17" },
        }}
        loading={loading}
        error={error}
      />
    </Container>
  );
};
