import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { Price } from "@/common/components/price/Price";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { TypeIndicator } from "@/common/components/type-indicator/TypeIndicator";
import { DateView } from "@/common/utils/dates/DateView";
import { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceSuggestedOrders } from "../../../../hooks/useInvoiceSuggestedOrders";
import { useInvoiceMatchedOrder } from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`flex flex-col w-full gap-1 rounded-md bg-gray-200 border-gray-600 my-2 border-dashed border-0.5 p-4`;
const Item = tw.div`grid grid-flow-row grid-cols-[1fr_20px_1fr_24px_2fr_1.5fr_1fr] items-center gap-4 justify-between rounded-lg bg-white p-2 hover:cursor-pointer hover:border-blue-300 border
${({ $selected }: { $selected: boolean }) => ($selected ? "border-blue-500 hover:border-blue-500" : "")}`;
const ReleaseNameWithCounter = tw.div`flex flex-row gap-2 items-center`;
const BuyoutName = tw.div`font-bold text-sm`;
const ReleaseWithNumber = tw.div`text-sm`;
const ReleasePoNumber = tw.div`text-sm text-gray-500`;
const SectionContainer = tw.div`flex flex-col text-sm`;
const Label = tw.div`text-[9px] text-gray-500`;
const Name = tw.div``;
const Header = tw.div``;

export const InvoiceSuggestedOrders: FC = () => {
  const { invoice } = useInvoiceVerification();
  const { setSelectedRelease, selectedRelease } = useInvoiceMatchedOrder();
  const { releases, loading, called } = useInvoiceSuggestedOrders();

  useEffect(() => {
    if (called && releases.length === 1 && !selectedRelease) {
      setSelectedRelease(releases[0]);
    }
  }, [releases, called, setSelectedRelease, selectedRelease]);

  return (
    <Loader loading={loading}>
      <If isTrue={releases?.length > 0 && invoice?.poNumber}>
        <Container>
          <Header>
            <FormattedMessage id="SUGGESTED_MATCH" />:
          </Header>
          {releases?.map((release, key) => (
            <Item
              key={release.id}
              onClick={() => release && setSelectedRelease(release)}
              $selected={release.id === selectedRelease?.id}
            >
              <ReleaseNameWithCounter>
                <CircleCounter count={key + 1} />
                <SectionContainer>
                  <If isTrue={release.buyout}>
                    <BuyoutName>{release.buyout?.clientIdentifier}</BuyoutName>
                  </If>
                  <ReleaseWithNumber>
                    <FormattedMessage
                      id="ORDER_WITH_NUMBER"
                      values={{ orderNumber: release.sequenceNumber }}
                    />
                  </ReleaseWithNumber>
                  <ReleasePoNumber>{release.poNumber}</ReleasePoNumber>
                </SectionContainer>
              </ReleaseNameWithCounter>
              <SectionContainer>
                <TypeIndicator orderType={release.type} />
              </SectionContainer>
              <SectionContainer>
                <FormattedMessage
                  id="RELEASE_FULFILLMENT_DATE"
                  tagName={Label}
                />
                <NotNullableRenderer value={release.time}>
                  <DateView
                    date={release.time ? new Date(release.time) : undefined}
                  />
                </NotNullableRenderer>
              </SectionContainer>
              <OrgLogo
                logoImageUrl={release.sellerOrgLocation?.org?.photoUrl}
                name={release.sellerOrgLocation?.org?.name || ""}
                width={24}
              />
              <SectionContainer>
                <FormattedMessage id="PROJECT" tagName={Label} />
                <Name>{release.project?.name}</Name>
                <Label>{release.project?.jobNumber}</Label>
              </SectionContainer>
              <SectionContainer>
                <FormattedMessage id="VALUE" tagName={Label} />
                <Price price={release.total} />
              </SectionContainer>
              <SectionContainer>
                <ReleaseStatusChip
                  releaseType={release.type}
                  status={release.status}
                  type="breadcrumb"
                  className="text-center"
                />
              </SectionContainer>
            </Item>
          ))}
        </Container>
      </If>
    </Loader>
  );
};
