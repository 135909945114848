import Decimal from "decimal.js";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AdditionalChargesItemContainer,
  TotalItemContainer,
  TotalItemOuter,
} from "../../../../../../../../../common/components/additional-charges/AdditionalCharges.styles";
import { DividerLine } from "../../../../../../../../../common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { Price } from "../../../../../../../../../common/components/price/Price";
import { TOTAL_PRICE_DECIMAL_POINTS } from "../../../../../../../../../common/const";
import { useRelease } from "../../../../../../release/providers/ReleaseProvider";
import {
  Column,
  ContentColumn,
  QuarterPanel,
} from "./InvoiceExpandable.styled";

const Container = tw.div`flex flex-1 items-end justify-end flex-col`;
const TotalItem = tw.div`-mr-1 flex flex-row gap-1 justify-end items-center`;
const Item = tw.div`flex flex-row gap-1 justify-end items-center font-medium text-sm`;
const DividerLineStyled = tw(DividerLine)`h-1 -mt-1 border-gray-400`;

export const ReleaseUninvoicedPanel = () => {
  const { release } = useRelease();

  const invoicedSoFar = useMemo(
    () =>
      (release?.invoices ?? []).reduce(
        (invoicesTotal, invoice) => invoicesTotal.plus(invoice.total ?? 0),
        new Decimal(0),
      ),
    [release?.invoices],
  );

  const uninvoicedBalance = useMemo(
    () => new Decimal(release?.total ?? 0).minus(invoicedSoFar),
    [release?.total, invoicedSoFar],
  );

  return (
    <Container>
      <DividerLineStyled />
      <QuarterPanel>
        <Column />
        <ContentColumn>
          <AdditionalChargesItemContainer className="pr-11">
            <Item>
              <FormattedMessage id="INVOICED_SO_FAR" />
            </Item>
            <Price
              price={invoicedSoFar}
              className="text-sm font-normal"
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
            />
          </AdditionalChargesItemContainer>
          <TotalItemOuter>
            <TotalItemContainer $highlightTotal>
              <TotalItem>
                <FormattedMessage id="REMAINING_BALANCE" />
              </TotalItem>
              <Price
                price={uninvoicedBalance}
                className="font-medium"
                maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
              />
            </TotalItemContainer>
          </TotalItemOuter>
        </ContentColumn>
      </QuarterPanel>
    </Container>
  );
};
