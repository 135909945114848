import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import { CostCodeFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

type Props = {
  costCodes: CostCodeFieldsFragment[];
  onChange: (value: string | null) => void;
  readonly?: boolean;
  costCode?: string | null | undefined;
  disableClearable?: boolean;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  inputProps?: string;
};

export const CostCodeSelector: FC<Props> = ({
  costCodes,
  onChange,
  costCode = null,
  readonly = false,
  disableClearable,
  placeholder,
  className,
  disabled,
  inputProps,
}) => {
  const intl = useIntl();
  const [selectedCostCode, setSelectedCostCode] = useState<string | null>(
    costCode,
  );

  useEffect(() => {
    if (readonly) {
      setSelectedCostCode(costCode);
    }
  }, [costCode, readonly]);

  const handleOnChange = useCallback(
    (value: string | null) => {
      setSelectedCostCode(value);
      onChange(value);
    },
    [onChange],
  );

  return (
    <StyledSelect
      placeholder={
        placeholder ||
        intl.$t({
          id: "COST_CODES",
        })
      }
      className={className}
      options={costCodes}
      value={selectedCostCode}
      staticText={readonly}
      onChange={handleOnChange}
      getLabel={(option: CostCodeFieldsFragment) => option.description}
      getValue={(option: CostCodeFieldsFragment) => option.id}
      inputProps={{ className: inputProps ?? "py-2" }}
      InputLabelProps={{ className: "text-sm" }}
      disableClearable={disableClearable}
      disabled={disabled}
      size="xs"
    />
  );
};
