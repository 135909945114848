import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { InfoOutlined } from "@mui/icons-material";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useAssignOrderToInvoice } from "../../../../../hooks/useAssignOrderToInvoice";

const Container = tw.div`mx-4 mt-4 flex flex-col items-center self-stretch rounded-lg border border-dashed border-gray-600 p-8`;
const TitleContainer = tw.div`flex items-center`;
const InfoIcon = tw(InfoOutlined)`mr-1 text-gray-500`;
const Title = tw.span`text-xl font-medium`;
const Info = tw.span`mt-4 text-center text-gray-600`;

export const BlankRelease = () => {
  const intl = useIntl();
  const { assignOrderToInvoice, isAssigningOrderToInvoice } =
    useAssignOrderToInvoice();

  return (
    <Container>
      <TitleContainer>
        <InfoIcon />
        <Title>{intl.$t({ id: "BLANK_RELEASE_TITLE" })}</Title>
      </TitleContainer>
      <Info>{intl.$t({ id: "BLANK_RELEASE_INFO" })}</Info>
      <PrimaryButton
        className="mt-4"
        onClick={assignOrderToInvoice}
        loading={isAssigningOrderToInvoice}
      >
        {intl.$t({ id: "ADD_ITEMS" })}
      </PrimaryButton>
    </Container>
  );
};
