import { Group } from "@/common/components/groups/common/types/types";
import { GroupSelector } from "@/common/components/groups/components/group-selector/GroupSelector";
import { useInvoiceFolders } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/invoice-folder-picker/hooks/useInvoiceFolders";
import { useReceipts } from "@/contractor/pages/home/receipts/pages/receipts/providers/ReceiptsProvider";
import { useReceiptSequence } from "@/contractor/pages/home/receipts/pages/receipts/providers/ReceiptsSequenceProvider";
import { InvoiceType, UpdateInvoicesInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";

type Props = {
  removing?: boolean;
  onClose: () => void;
  existingFolders?: Group[];
};

export const ReceiptFolderSelector: FC<Props> = ({
  removing,
  onClose,
  existingFolders = [],
}) => {
  const { folders, createFolder } = useInvoiceFolders(InvoiceType.Receipt);
  const { sequenceIds, selectedReceipts, setSelectedReceipts } =
    useReceiptSequence();
  const { updateReceipts } = useReceipts();
  const filteredFolders = useMemo(
    () => (removing ? existingFolders : folders),
    [folders, removing, existingFolders],
  );

  const updateEntity = useCallback(
    async (input: Omit<UpdateInvoicesInput, "invoiceIds">) => {
      const updateResult = await updateReceipts({
        invoiceIds: sequenceIds,
        ...input,
      });
      if (!updateResult) {
        return;
      }
      const selectedReceiptsCopy = selectedReceipts.map((receipt) => {
        const item = {
          ...receipt,
          folders: receipt.folders ? [...receipt.folders] : [],
        };
        input.assignedFolderIds?.forEach((id) => {
          const originalFolder = folders.find((folder) => folder.id === id);
          if (
            originalFolder &&
            !item.folders.find((folder) => folder.id === id)
          ) {
            item.folders.push({
              id: originalFolder.id,
              name: originalFolder.name,
            });
          }
        });
        input.unassignedFolderIds?.forEach((id) => {
          item.folders = item.folders.filter((folder) => folder.id !== id);
        });
        return item;
      });
      setSelectedReceipts(selectedReceiptsCopy);
    },
    [
      updateReceipts,
      sequenceIds,
      folders,
      selectedReceipts,
      setSelectedReceipts,
    ],
  );

  return (
    <GroupSelector
      createGroup={createFolder}
      groups={filteredFolders}
      onClose={onClose}
      removing={removing}
      updateEntity={updateEntity}
    />
  );
};
