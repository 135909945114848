import Decimal from "decimal.js";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { InvoiceDetailsFooter } from "../../../../../../../../common/components/invoices/invoice-details/footer/InvoiceFooterDetails";
import { areDecimalsEqual } from "../../../../../../../../common/utils/areDecimalsEqual";
import { useDistributorInvoice } from "../../../../../providers/DistributorInvoiceProvider";
import {
  Column,
  ContentColumn,
  PanelTitle,
  QuarterPanel,
} from "./InvoiceExpandable.styled";

export const InvoicePanel = () => {
  const { invoice } = useDistributorInvoice();

  const sameTotal = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return areDecimalsEqual(invoice.release.total, invoice?.total);
  }, [invoice?.release, invoice?.total]);

  const isTotalDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return new Decimal(invoice?.release.total ?? 0)
      .minus(invoice?.total ?? 0)
      .abs()
      .lessThan(1);
  }, [invoice?.release, invoice?.total]);

  const sameSubtotal = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return areDecimalsEqual(invoice.release?.netAmount, invoice?.subtotal);
  }, [invoice?.release, invoice?.subtotal]);

  const isSubtotalDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return new Decimal(invoice?.release.netAmount ?? 0)
      .minus(invoice?.subtotal ?? 0)
      .abs()
      .lessThan(1);
  }, [invoice?.release, invoice?.subtotal]);

  const sameAdditionalCharges = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return areDecimalsEqual(
      (invoice.release?.additionalCharges || [])
        .reduce((acc, charge) => {
          return Number(new Decimal(acc).add(new Decimal(charge.amount || 0)));
        }, 0)
        .toString(),
      invoice?.chargesAmount,
    );
  }, [invoice?.release, invoice?.chargesAmount]);

  const isAdditionalChargesDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return new Decimal(
      (invoice.release?.additionalCharges || []).reduce((acc, charge) => {
        return Number(new Decimal(acc).add(new Decimal(charge.amount || 0)));
      }, 0),
    )
      .minus(invoice?.chargesAmount ?? 0)
      .abs()
      .lessThan(1);
  }, [invoice?.release, invoice?.chargesAmount]);

  const sameSalesTax = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return areDecimalsEqual(invoice.release?.taxAmount, invoice?.taxAmount);
  }, [invoice?.release, invoice?.taxAmount]);

  const isSalesTaxDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return new Decimal(invoice?.release.taxAmount ?? 0)
      .minus(invoice?.taxAmount ?? 0)
      .abs()
      .lessThan(1);
  }, [invoice?.release, invoice?.taxAmount]);

  return (
    <QuarterPanel data-testid="invoiceFooter" className="bg-blue-250">
      <Column>
        <FormattedMessage id="INVOICE" tagName={PanelTitle} />
      </Column>
      <ContentColumn>
        <InvoiceDetailsFooter
          invoice={invoice}
          readonly
          sameTotal={sameTotal}
          isTotalDiscrepancyMinor={isTotalDiscrepancyMinor}
          sameAdditionalCharges={sameAdditionalCharges}
          isAdditionalChargesDiscrepancyMinor={
            isAdditionalChargesDiscrepancyMinor
          }
          sameSalesTax={sameSalesTax}
          isSalesTaxDiscrepancyMinor={isSalesTaxDiscrepancyMinor}
          sameSubtotal={sameSubtotal}
          isSubtotalDiscrepancyMinor={isSubtotalDiscrepancyMinor}
        />
      </ContentColumn>
    </QuarterPanel>
  );
};
