import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Multiselect } from "../select/components/multiple/Multiselect";

type Props<T> = {
  options: { id: string; name: string }[];
  values: string[] | null;
  filter: T | undefined;
  setFilter: (value: T) => void;
  applyHeaderAccent?: boolean;
};

export const ListStatusesFilter = <T,>({
  options,
  values,
  filter = {} as T,
  setFilter,
  applyHeaderAccent = false,
}: Props<T>) => {
  const intl = useIntl();

  const setFilterHandler = useCallback(
    (values: string[] | null) => {
      if (values?.length) {
        setFilter({
          ...filter,
          statuses: values.map((value) => value as T),
        });
      } else {
        setFilter({
          ...filter,
          statuses: undefined,
        });
      }
    },
    [filter, setFilter],
  );

  return (
    <Multiselect
      testId="statusFilter"
      options={options}
      getLabel={(o) => o.name}
      getValue={(o) => o.id?.toString()}
      values={values}
      chipSize="small"
      includeCheckbox
      limitTags={1}
      disableCloseOnSelect
      className="min-w-45"
      placeholder={values?.length ? "" : intl.$t({ id: "ALL_STATUSES" })}
      onMultipleChange={setFilterHandler}
      applyHeaderAccent={applyHeaderAccent}
    />
  );
};
