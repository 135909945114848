import { Chip } from "@mui/material";
import tw from "tailwind-styled-components";

const Container = tw.div`absolute inset-0 flex items-center pl-2 right-8 overflow-hidden`;

interface CustomSelectedItemViewProps {
  text: string;
  clear?: () => void;
}

export const SelectCustomItemView = ({
  text,
  clear,
}: CustomSelectedItemViewProps) => {
  return (
    <Container role="button" tabIndex={0}>
      <Chip
        label={text}
        className="bg-blue-400 text-white"
        onDelete={clear}
        size="small"
        sx={{
          "& .MuiChip-deleteIcon": {
            color: "white",
          },
        }}
      />
    </Container>
  );
};
