import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { BuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  BuyoutExportStatus,
  BuyoutExportStatusEnum,
} from "../common/BuyoutExportStatus";
import { ExportBuyoutPOPanel } from "../panels/ExportBuyoutPOPanel";
type Props = {
  buyout: BuyoutFieldsFragment;
};

const ExportedAt = tw.div`text-2xs text-gray-600`;

export const ExportBuyoutButton: FC<Props> = ({ buyout }) => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();

  if (checkBuyoutStatus(buyout, [BuyoutStatus.Cancelled])) {
    return null;
  }

  if (buyout.poLink || buyout.poExists) {
    return (
      <ExportedAt>
        <BuyoutExportStatus
          status={
            buyout.poExists && !buyout.poLink
              ? BuyoutExportStatusEnum.PoExists
              : BuyoutExportStatusEnum.OK
          }
          poNumber={buyout.poNumber}
          date={buyout.poLink?.syncedAt}
          projectId={buyout.project?.id}
          sourceSystem={connectedSourceSystem}
          poLinkId={buyout.poLink?.id}
          buyoutId={buyout.id}
          poExists={buyout.poExists}
        />
      </ExportedAt>
    );
  }

  if (checkBuyoutStatus(buyout, [BuyoutStatus.Draft])) {
    return null;
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <OutlinedButton $small onClick={() => togglePanel(true)}>
          {intl.$t(
            { id: "EXPORT_PO_TO_INTEGRATION_TYPE" },
            {
              integration: intl.$t({
                id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
              }),
            },
          )}
        </OutlinedButton>
      )}
      content={(togglePanel) => (
        <ExportBuyoutPOPanel
          buyout={buyout}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};
