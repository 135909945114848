import { If } from "@/common/components/if/If";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useSpendingReport } from "../../../../providers/ProjectSpendingReportProvider";
import { useProjectStore } from "../../../../store/useProjectStore";

const Container = tw.div`text-sm`;
const PhaseCodeLabel = tw.span`text-gray-500 text-sm`;

export const PhaseCodesHeader = () => {
  const { tags } = useSpendingReport();
  const { phaseCodes } = useProjectStore();

  const getPhaseCodeName = useCallback(
    (id: string) => {
      return phaseCodes.find((code) => code.id === id)?.name;
    },
    [phaseCodes],
  );

  const hasPhaseCodeSelected = useMemo(() => {
    return tags?.length && tags.some((tag) => getPhaseCodeName(tag));
  }, [tags, getPhaseCodeName]);

  return (
    <Container>
      {hasPhaseCodeSelected ? (
        tags?.map((tag) => getPhaseCodeName(tag)).join(", ")
      ) : (
        <FormattedMessage id="ALL_PHASE_CODES" />
      )}{" "}
      <If isTrue={hasPhaseCodeSelected}>
        <PhaseCodeLabel>
          (<FormattedMessage id="PHASE_CODE" />)
        </PhaseCodeLabel>
      </If>
    </Container>
  );
};
