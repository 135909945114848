import { ImportServiceCodesMutation, SourceSystem } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type ImportServiceCodesState = {
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) => void;
  closeModal: () => void;
  selectedExternalServiceCodes: string[];
  setSelectedExternalServiceCodes: (id: string[]) => void;
  importAllExternalItems: boolean;
  setImportAllExternalItems: (value: boolean) => void;
  totalExternalServiceCodesCount: number;
  setTotalExternalServiceCodesCount: (count: number) => void;
  importedServiceCodes: ImportServiceCodesMutation["importServiceCodes"] | null;
  setImportedServiceCodes: (
    importedServiceCodes:
      | ImportServiceCodesMutation["importServiceCodes"]
      | null,
  ) => void;
};

const initialState = {
  sourceSystem: null,
  modalOpened: false,
  selectedExternalServiceCodes: [],
  importAllExternalItems: false,
  totalExternalServiceCodesCount: 0,
  importedServiceCodes: null,
};

export const useImportServiceCodesStore = create<ImportServiceCodesState>()(
  devtools((set) => ({
    ...initialState,
    openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) =>
      set({ sourceSystem, modalOpened: true }),
    closeModal: () => set(initialState),
    setSelectedExternalServiceCodes: (selectedExternalServiceCodes: string[]) =>
      set({ selectedExternalServiceCodes }),
    setImportAllExternalItems: (importAllExternalItems: boolean) =>
      set({
        importAllExternalItems,
      }),
    setTotalExternalServiceCodesCount: (
      totalExternalServiceCodesCount: number,
    ) => set({ totalExternalServiceCodesCount }),
    setImportedServiceCodes: (
      importedServiceCodes:
        | ImportServiceCodesMutation["importServiceCodes"]
        | null,
    ) => set({ importedServiceCodes }),
  })),
);
