import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useUser } from "@/common/providers/UserProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { setValue } from "@/common/utils/localStorage";
import { EnterpriseRole } from "@/generated/graphql";
import { useAuth0 } from "@auth0/auth0-react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { If } from "../if/If";
import { NotificationCount } from "../notifications/components/NotificationCount";
import { OrgRolesWrapper } from "../org-roles-wrapper/OrgRolesWrapper";
import { useRoles } from "../org-roles-wrapper/hasRoles";
import { AddNewOrgButton } from "./components/AddNewOrgButton";
import useOrgNotificationCount from "./hooks/useOrgNotificationCount";

const Container = tw.div`
  flex items-center gap-1
`;

const SwitchContainer = tw.div<{ $disabled: boolean }>`
  relative grid grid-flow-col w-fit px-2 bg-blue-800 border border-blue-400 
  rounded-4xl place-items-center self-center justify-self-center mr-4
  ${({ $disabled }) => !$disabled && "cursor-pointer"}
`;

const KeyboardArrowDownStyled = tw(KeyboardArrowDown)`
  text-gray-400 w-4
`;

const Dropdown = tw.div`
  flex items-center gap-1 text-sm py-1 pl-1
`;

const Item = tw.div`
  grid place-items-center grid-flow-col gap-2 ml-1 relative
`;

const Label = tw.div`
  text-sm
`;

export const EnterpriseOrgSwitch: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { viewer } = useUser();
  const { loginWithRedirect } = useAuth0();
  const { hasEnterpriseRoles: isEnterpriseAdmin } = useRoles({
    enterpriseRoles: [EnterpriseRole.EnterpriseAdmin],
  });

  const { orgNotificationCount } = useOrgNotificationCount();
  const { enterprise } = useStartupDataStore(
    useShallow((state) => ({
      enterprise: state.enterprise,
    })),
  );
  const options = useMemo(() => {
    return (
      enterprise?.orgs.map((org) => {
        return {
          id: org.id,
          label: org.name,
          value: org.id,
          notificationCount:
            orgNotificationCount?.find((o) => o.id === org.id)
              ?.notificationCount || 0,
        };
      }) || []
    );
  }, [enterprise?.orgs, orgNotificationCount]);

  const totalNotificationCount = useMemo(
    () =>
      orgNotificationCount
        ?.filter((org) => org.id !== viewer?.org.id)
        ?.map((org) => org.notificationCount)
        ?.reduce<number>((prev, curr) => (prev || 0) + (curr || 0), 0) || 0,
    [orgNotificationCount, viewer],
  );

  const onChange = useCallback(
    async (orgId: string | undefined) => {
      setValue(LOCAL_STORAGE_KEYS.LOGIN_ORG_ID, orgId);
      await loginWithRedirect({ authorizationParams: { org_id: orgId } });
    },
    [loginWithRedirect],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const disabled = useMemo(() => {
    return options.length === 1;
  }, [options.length]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }
      setAnchorEl(event.currentTarget);
    },
    [disabled],
  );

  const selectableOptions = useMemo(
    () => options.filter((opt) => opt.id !== viewer?.org.id),
    [options, viewer],
  );

  return (
    <Container>
      <OrgRolesWrapper enterpriseRoles={[EnterpriseRole.EnterpriseAdmin]}>
        <AddNewOrgButton />
      </OrgRolesWrapper>
      <If isTrue={!disabled || isEnterpriseAdmin}>
        <SwitchContainer $disabled={disabled}>
          <Dropdown onClick={handleClick}>
            <Item>
              <NotificationCount
                unreadCount={totalNotificationCount}
                className={totalNotificationCount > 0 ? "h-5 w-5" : ""}
              />
              <span>
                {options.find((item) => item.value === viewer?.org.id)?.label}
              </span>
            </Item>
            <If isTrue={!disabled && selectableOptions?.length > 0}>
              <KeyboardArrowDownStyled />
            </If>
          </Dropdown>
          <If isTrue={!disabled && selectableOptions?.length > 0}>
            <Menu
              anchorEl={anchorEl}
              open={open}
              variant="selectedMenu"
              onClose={handleClose}
              keepMounted
              disableAutoFocusItem
            >
              {selectableOptions.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    onChange(item.value);
                    handleClose();
                  }}
                  selected={item.value === viewer?.org.id}
                >
                  <Item>
                    <NotificationCount
                      unreadCount={item.notificationCount}
                      className={totalNotificationCount > 0 ? "h-5 w-5" : ""}
                    />
                    <Label>{item.label}</Label>
                  </Item>
                </MenuItem>
              ))}
            </Menu>
          </If>
        </SwitchContainer>
      </If>
    </Container>
  );
};
