import { FC } from "react";
import { OrderSideBySideBreadcrumbs } from "../../../OrderSideBySideBreadcrumbs";

export const CreateOrderFromQuoteBreadcrumbs: FC = () => {
  return (
    <OrderSideBySideBreadcrumbs
      hasReleaseTextKey="ADD_ITEMS_AND_COPY_PRICES"
      noReleaseTextKey="CREATE_ORDER_FROM_QUOTE"
    />
  );
};
