import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { SourceSystemWrapper } from "../../../../../../../../common/components/source-system-wrapper/SourceSystemWrapper";
import { useReleaseImportExternalPO } from "./hooks/useReleaseImportExternalPO";
import { ReleaseImportExternalPOWizard } from "./ReleaseImportExternalPOWizard";

const ImportPoButtonWithProvider = () => {
  const { openModal, importModalOpened } = useReleaseImportExternalPO();
  const { reset } = useFormContext<ExternalPOsQueryInputType>();

  useEffect(() => {
    if (!importModalOpened) {
      reset();
    }
  }, [importModalOpened, reset]);

  return (
    <SourceSystemWrapper anySourceSystem>
      <OutlinedButton onClick={() => openModal()}>
        <FormattedMessage id="IMPORT_PO" />
      </OutlinedButton>
      <ReleaseImportExternalPOWizard />
    </SourceSystemWrapper>
  );
};

export const ImportPoButton = () => {
  const methods = useForm<ExternalPOsQueryInputType>({
    defaultValues: {
      sellerOrgLocationID: null,
      search: null,
      minDate: null,
      maxDate: null,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  return (
    <NestedStepperProvider>
      <FormProvider {...methods}>
        <ImportPoButtonWithProvider />
      </FormProvider>
    </NestedStepperProvider>
  );
};
