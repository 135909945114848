import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "../components/material/utils";

export const useGetProjectItemFromSku = () => {
  return useCallback(
    (material: OrgMaterialFieldsFragment, uom: string) => ({
      estimateUom: uom ?? "",
      ...(isOrgCatalogSku(material.material) && {
        orgCatalogSkuId: material.material.id,
      }),
      ...(isProductSku(material.material) && {
        masterProductId: material.material.id,
      }),
      ...(isMasterSku(material.material) && {
        masterSkuId: material.material.id,
      }),
    }),
    [],
  );
};
