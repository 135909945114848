import {
  OrgPreferredVendorsFieldsFragment,
  VendorContact,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useVendors } from "../vendors/hooks/useVendors";
import { vendorLabelFormatter } from "./VendorPickerCustomRender";

export const ALL_VENDORS_LIST_ID = "-1";

export type VendorOption = OrgPreferredVendorsFieldsFragment & {
  value: string | undefined;
  name: string;
};

type Props = {
  includeAllOption?: boolean;
  contactsFilter?: (contact: VendorContact) => boolean;
};

export const useVendorOptions = (
  { contactsFilter, includeAllOption }: Props = { contactsFilter: () => true },
) => {
  const { vendors, loading, shouldShowVendorCode, getVendorCode } =
    useVendors();
  const intl = useIntl();
  const vendorOptions: VendorOption[] = useMemo(() => {
    const allOption = {
      name: intl.$t({ id: "ALL_VENDORS" }),
      id: ALL_VENDORS_LIST_ID,
    };
    const opts = [
      ...(includeAllOption
        ? [
            {
              ...allOption,
              value: allOption.id,
              contacts: [],
              sellerOrgLocation: {
                id: ALL_VENDORS_LIST_ID,
                name: "",
                org: { id: "", name: allOption.name },
              },
              taxExempt: false,
              externalVendors: [],
            } as VendorOption,
          ]
        : []),
    ];

    vendors.forEach((vendor) => {
      opts.push({
        ...vendor,
        value: vendor.sellerOrgLocation.id,
        name: vendorLabelFormatter(
          vendor.sellerOrgLocation,
          vendor.contacts.filter(contactsFilter ?? (() => true)),
          {
            vendorCode: getVendorCode(vendor),
          },
        ),
      });
    });
    return opts;
  }, [contactsFilter, getVendorCode, includeAllOption, intl, vendors]);

  const findOrderTypeByLocationId = useCallback(
    (locationId: string) => {
      return vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === locationId,
      )?.defaultReleaseType?.id;
    },
    [vendors],
  );

  const findPaymentTermByLocationId = useCallback(
    (locationId: string | undefined) => {
      if (!locationId) {
        return undefined;
      }
      return (
        vendors.find((vendor) => vendor.sellerOrgLocation.id === locationId)
          ?.defaultPaymentTerm ?? undefined
      );
    },
    [vendors],
  );

  const findCostCodeIdByLocationId = useCallback(
    (locationId: string) => {
      return vendors.find(
        (vendor) => vendor.sellerOrgLocation.id === locationId,
      )?.costCode?.id;
    },
    [vendors],
  );

  return {
    vendorOptions,
    loading,
    shouldShowVendorCode,
    findOrderTypeByLocationId,
    findPaymentTermByLocationId,
    findCostCodeIdByLocationId,
  };
};
