import { Address } from "@/common/components/address/Address";
import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { PROJECT_STATUSES } from "@/common/components/project-statuses/ProjectStatuses";
import { DateView } from "@/common/utils/dates/DateView";
import { ProjectStatus, RfqFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

type RFQInfoProps = {
  rfq: RfqFieldsFragment;
  hideVendors?: boolean;
};

export const RFQInfo: FC<RFQInfoProps> = ({ rfq }) => {
  return (
    <InfoHeader>
      <InfoHeaderItem
        title={
          rfq.project.status === ProjectStatus.Active ||
          rfq.project.status === ProjectStatus.Awarded ? (
            <FormattedMessage id={PROJECT_STATUSES[rfq.project.status].label} />
          ) : (
            <FormattedMessage id="RFQ_INFO_PROJECT" />
          )
        }
      >
        {rfq.project.name}
        {rfq.project.jobNumber ? ` (${rfq.project.jobNumber})` : ""}
      </InfoHeaderItem>
      <InfoHeaderItem
        title={<FormattedMessage id="RFQ_INFO_DELIVERY_ADDRESS" />}
      >
        <Address address={rfq.project.address} />
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="RFQ_INFO_JOB_START_DATE" />}>
        <DateView date={rfq.project.startDate} />
      </InfoHeaderItem>
    </InfoHeader>
  );
};
