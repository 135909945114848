import { DiscardButton } from "@/common/components/button/DiscardButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { routes } from "@/config/routes";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useDeliveries } from "@/contractor/pages/home/releases/pages/deliveries/providers/DeliveriesProvider";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import {
  IntegrationType,
  OrgRole,
  ReleaseStatus,
  SourceSystem,
} from "@/generated/graphql";
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { AdminUsersInfo } from "../common/AdminUsersInfo";
import { ConnectReleasePOsPanel } from "../panels/ConnectReleasePOsPanel";
import { ExportMultipleReleasePOsPanel } from "../panels/ExportMultipleReleasePOsPanel";
import { FoundationExportTypeDialog } from "./components/FoundationExportTypeDialog";

const Container = tw.div`
  grid grid-cols-[75px_repeat(5,_auto)] gap-2 relative w-fit place-items-center
`;
const NumberContainer = tw.div`
  text-sm font-medium
  ${({ $hasItems }: { $hasItems: boolean }) =>
    $hasItems ? "text-green-800" : ""}
`;
const InfoText = tw.span`grid text-2xs max-w-20 font-normal`;
const Link = tw(LinkLike)`inline`;

export const ExportMultipleReleasePOButton: FC = () => {
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();
  const { hasRoles: isAdmin } = useRoles({ roles: [OrgRole.OrgAdmin] });
  const { exportEnabled, setExportEnabled } = useDeliveries();
  const {
    startSequence,
    sequenceIds,
    sequenceActive,
    selectedReleases,
    setSelectedReleases,
    stopSequence,
  } = useReleaseSequence();

  const [showDialog, setShowDialog] = useState(false);
  const [toJob, setToJob] = useState(true);

  useEffect(() => {
    if (sequenceActive) {
      setExportEnabled(sequenceActive);
    }
  }, [exportEnabled, sequenceActive, sequenceIds, setExportEnabled]);

  const hasBothFoundationConnections = useMemo(
    () =>
      connectedSourceSystem === SourceSystem.Foundation &&
      connectedIntegrationType === IntegrationType.Foundation,
    [connectedIntegrationType, connectedSourceSystem],
  );

  const onClosePanel = useCallback(
    (togglePanel?: (opened: boolean) => void) => {
      togglePanel?.(false);
      setSelectedReleases([]);
      setExportEnabled(false);
      stopSequence();
    },
    [setExportEnabled, setSelectedReleases, stopSequence],
  );

  const startExportFlow = useCallback(() => {
    if (connectedIntegrationType || connectedSourceSystem) {
      setExportEnabled(true);
    } else if (!isAdmin) {
      setWarningAlert(
        intl.$t(
          { id: "EXPORT_PO_VALIDATION_GL" },
          {
            sub: () => <AdminUsersInfo />,
          },
        ),
      );
    } else {
      setWarningAlert(
        intl.$t(
          {
            id: "EXPORT_PO_VALIDATION_GL_ADMIN",
          },
          {
            sub: (chunks: ReactNode) => (
              <Link to={generatePath(routes.connections)}>{chunks}</Link>
            ),
          },
        ),
      );
    }
  }, [
    connectedIntegrationType,
    connectedSourceSystem,
    intl,
    isAdmin,
    setExportEnabled,
    setWarningAlert,
  ]);

  const onStartSequenceClicked = useCallback(() => {
    startSequence();
  }, [startSequence]);

  if (!exportEnabled) {
    return (
      <If isTrue={!!connectedIntegrationType || !!connectedSourceSystem}>
        <OutlinedButton testId="export-invoices" onClick={startExportFlow}>
          <FormattedMessage id="CREATE_SEQUENCE" />
        </OutlinedButton>
      </If>
    );
  }
  return (
    <Container>
      <FormattedMessage
        id="USE_CHECKBOXES_TO_SELECT_ITEMS"
        tagName={InfoText}
      />
      <NumberContainer $hasItems={selectedReleases.length > 0}>
        <FormattedMessage
          id="NUMBER_OF_SELECTED"
          values={{ count: sequenceIds.length }}
        />
      </NumberContainer>
      <DrawerPanel
        anchor={(togglePanel) => (
          <>
            <PrimaryButton
              testId="start-sequence"
              onClick={onStartSequenceClicked}
              disabled={sequenceIds.length === 0}
            >
              <FormattedMessage id="START_SEQUENCE" />
            </PrimaryButton>

            <PoNumberingSettingsCheck>
              <Tooltip
                element={
                  <PrimaryButton
                    testId="export-multiple-pos"
                    onClick={() =>
                      hasBothFoundationConnections
                        ? setShowDialog(true)
                        : togglePanel(true)
                    }
                    disabled={
                      sequenceIds.length === 0 ||
                      selectedReleases.some((i) =>
                        checkReleaseStatus(i, [
                          ReleaseStatus.Canceled,
                          ReleaseStatus.AwaitingApproval,
                          ReleaseStatus.Rejected,
                        ]),
                      )
                    }
                  >
                    <FormattedMessage
                      id={connectedIntegrationType ? "EXPORT" : "CONNECT"}
                    />
                  </PrimaryButton>
                }
                id="export-button"
              >
                {selectedReleases.some((i) =>
                  checkReleaseStatus(i, [
                    ReleaseStatus.Draft,
                    ReleaseStatus.Rejected,
                    ReleaseStatus.AwaitingApproval,
                  ]),
                ) ? (
                  <FormattedMessage id="DISABLE_EXPORT_RELEASE_STATUSES" />
                ) : undefined}
              </Tooltip>

              <FoundationExportTypeDialog
                toJob={toJob}
                showDialog={showDialog}
                setToJob={setToJob}
                setShowDialog={setShowDialog}
                togglePanel={togglePanel}
              />
            </PoNumberingSettingsCheck>
          </>
        )}
        content={(togglePanel) => {
          if (hasBothFoundationConnections) {
            return toJob ? (
              <ConnectReleasePOsPanel
                releaseIds={selectedReleases.map((r) => r.id)}
                onClose={() => onClosePanel(togglePanel)}
                sourceSystem={SourceSystem.Foundation}
                mode={ConnectionMode.Connect}
              />
            ) : (
              <ExportMultipleReleasePOsPanel
                releaseIds={selectedReleases.map((r) => r.id)}
                onClose={() => onClosePanel(togglePanel)}
              />
            );
          } else {
            return connectedSourceSystem ? (
              <ConnectReleasePOsPanel
                releaseIds={selectedReleases.map((r) => r.id)}
                onClose={() => onClosePanel(togglePanel)}
                sourceSystem={connectedSourceSystem}
                mode={ConnectionMode.Connect}
              />
            ) : (
              <ExportMultipleReleasePOsPanel
                releaseIds={selectedReleases.map((r) => r.id)}
                onClose={() => onClosePanel(togglePanel)}
              />
            );
          }
        }}
      />
      <DiscardButton onClick={() => onClosePanel()} />
    </Container>
  );
};
