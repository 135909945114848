import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ReceiptFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ConnectReceiptsPanel } from "../../../receipts/components/export/ConnectReceiptsPanel";
import { ReceiptExportStatus } from "./ReceiptExportStatus";
const OutlinedButtonStyled = tw(
  OutlinedButton,
)`text-xs mb-2 my-0 mx-0 px-2 sm:min-h-2`;

type Props = {
  receipt: ReceiptFieldsFragment;
};

export const ExportReceiptButton: FC<Props> = ({ receipt }) => (
  <DrawerPanel
    anchor={(togglePanel) =>
      !receipt.link ? (
        <OutlinedButtonStyled
          testId="export-receipt"
          onClick={() => togglePanel(true)}
        >
          <FormattedMessage id="EXPORT_RECEIPT" />
        </OutlinedButtonStyled>
      ) : (
        <ReceiptExportStatus
          receipt={receipt}
          toggleExportPanel={() => togglePanel(true)}
        />
      )
    }
    content={(togglePanel) => (
      <ConnectReceiptsPanel
        receiptIds={[receipt.id]}
        onClose={() => togglePanel(false)}
      />
    )}
  />
);
