import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { MAX_ITEMS_PER_PAGE } from "@/common/const";
import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  Edge,
  ManufacturerFieldsFragment,
  ManufacturersDocument,
  OrgMaterialFieldsFragment,
  OrgMaterialsSummaryDocument,
  OrgPreferredVendorsDocument,
  OrgPreferredVendorsFieldsFragment,
  StartupDataDocument,
  UomFieldsFragment,
  VendorPriceFieldsFragment,
  VendorPricesDocument,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type ExpandedMaterialPrice = VendorPriceFieldsFragment & {
  material?: OrgMaterialFieldsFragment;
  vendorName: string;
};

type VendorPricesState = {
  prices: ExpandedMaterialPrice[];
  pricesMap: Map<string, ExpandedMaterialPrice>;
  loading: boolean;
  error: Error | null;
  setVendorPrices: (prices: ExpandedMaterialPrice[]) => void;
  updateVendorPricesMap: (prices: ExpandedMaterialPrice[]) => void;
  fetchVendorPrices: (force?: boolean) => Promise<void>;
  globalVendorId?: string;
  setGlobalVendorId: (id: string) => void;
};

const createVendorPricesMap = (
  prices: ExpandedMaterialPrice[] | undefined,
): Map<string, ExpandedMaterialPrice> => {
  return (
    prices?.reduce((acc, material) => {
      return acc.set(material.id, material);
    }, new Map()) ?? new Map()
  );
};

const hydratePrices = (
  prices: ExpandedMaterialPrice[],
  materials: OrgMaterialFieldsFragment[],
  uoms: UomFieldsFragment[],
  vendors: OrgPreferredVendorsFieldsFragment[],
  manufacturers: ManufacturerFieldsFragment[],
) => {
  const uomMap = new Map<string, UomFieldsFragment>();
  uoms.forEach((uom) => uomMap.set(uom.id, uom));
  const materialsMap = new Map<string, OrgMaterialFieldsFragment>();
  materials.forEach((material) => materialsMap.set(material.id, material));
  const vendorsMap = new Map<string, OrgPreferredVendorsFieldsFragment>();
  vendors.forEach((vendor) =>
    vendorsMap.set(vendor.sellerOrgLocation.id, vendor),
  );
  const manufacturerMap = new Map<string, ManufacturerFieldsFragment>();
  manufacturers.forEach((manuf) => manufacturerMap.set(manuf.id, manuf));

  return prices.map((price) => {
    return {
      ...price,
      material: materialsMap.get(price.orgMaterialId),
      uom: uomMap.get(price.uom.id),
      sellerOrgLocation: vendorsMap.get(price.sellerOrgLocation.id)
        ?.sellerOrgLocation,
      vendorName: vendorLabelFormatter(
        vendorsMap.get(price.sellerOrgLocation.id)?.sellerOrgLocation,
      ),
      manufacturer: manufacturerMap.get(price.manufacturer?.id || ""),
    } as ExpandedMaterialPrice;
  });
};

export const useVendorPricesStore = create<VendorPricesState>()(
  devtools((set, get) => ({
    prices: [],
    pricesMap: new Map(),
    loading: false,
    error: null,
    globalVendorId: undefined,
    setGlobalVendorId: (id) => set({ globalVendorId: id }),
    setVendorPrices: (prices: ExpandedMaterialPrice[]) =>
      set(() => ({ prices })),
    updateVendorPricesMap(prices) {
      set({ pricesMap: createVendorPricesMap(prices) });
    },
    fetchVendorPrices: async (force = false) => {
      if ((get().prices.length > 0 && !force) || get().loading) {
        return;
      }
      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }

      set({ loading: true, error: null });
      try {
        await Promise.all([
          client.query({ query: StartupDataDocument }),
          client.query({
            query: OrgMaterialsSummaryDocument,
            fetchPolicy: "cache-first",
          }),
          client.query({
            query: VendorPricesDocument,
            fetchPolicy: force ? "network-only" : "cache-first",
          }),
          client.query({
            query: OrgPreferredVendorsDocument,
            variables: {
              first: MAX_ITEMS_PER_PAGE,
            },
          }),
          client.query({
            query: ManufacturersDocument,
            variables: {
              searchPrefix: "",
            },
          }),
        ]).then(
          ([
            startupResult,
            materialsResult,
            pricesResult,
            vendorsResult,
            manufacturersResult,
          ]) => {
            const hydratedPrices = hydratePrices(
              pricesResult.data.viewer.org.vendorPrices,
              materialsResult.data.viewer.org.materials,
              startupResult.data.viewer.org.uoms,
              vendorsResult.data.orgPreferredVendorsConnection.edges.map(
                (e: Edge) => e.node,
              ),
              manufacturersResult.data.manufacturers,
            );
            set({
              prices: hydratedPrices,
              pricesMap: createVendorPricesMap(hydratedPrices),
              loading: false,
            });
          },
        );
      } catch (error) {
        set({ error: error as Error, loading: false });
      }
    },
  })),
);
