import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { useUsers } from "@/common/hooks/useUsers";
import { getUserName } from "@/common/utils/users/getUserName";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

type Props = {
  className?: string;
};

const LinkLikeTextStyled = tw(LinkLikeText)`inline`;
const VendorListStyled = tw(VendorList)`inline`;

export const AdminUsersInfo: FC<Props> = ({ className }) => {
  const { users } = useUsers();

  const adminUsers = useMemo(
    () => users?.filter((u) => u.isOrgAdmin) || [],
    [users],
  );

  const userOptions = useMemo(
    () =>
      adminUsers.map((u) => ({
        id: u.id,
        name: getUserName(u),
        email: u.email,
        cellPhone: u.cellPhone,
      })),
    [adminUsers],
  );

  return (
    <LinkLikeTextStyled>
      <VendorListStyled
        contacts={userOptions}
        limit={adminUsers.length}
        classes={{
          popover: `inline ${className}`,
        }}
        separator=","
      />
    </LinkLikeTextStyled>
  );
};
