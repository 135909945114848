import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useProjectPredictedPoNumber } from "@/common/components/po-number/hooks/useProjectPredictedPoNumber";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import {
  PROJECT_ADDRESS_ID,
  useWarehouseOptions,
} from "@/common/components/warehouse-selector/useWarehouseOptions";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { useNoteDocument } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import {
  PoNumberingMode,
  ProjectExtendedFieldsFragment,
  ReleaseNotificationsMode,
  ReleaseStatus,
  ServiceType,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useBuyoutsByProject } from "../components/new-delivery/BuyoutsByProjectProvider";
import { NewDeliveryFormType } from "../components/new-delivery/NewDeliveryFormType";
import { useDeliveries } from "../providers/DeliveriesProvider";

const ConfirmationContainer = tw.div`max-w-38`;

enum NewOrderType {
  CreateNew = "createNew",
  ImportQuote = "quote",
  ImportNote = "note",
}

export const useNewDeliveryForm = ({
  onClose,
  project,
}: {
  onClose: () => void;
  project?: ProjectExtendedFieldsFragment | null;
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { buyouts, setProjectIdFilter } = useBuyoutsByProject();
  const { findOrderTypeByLocationId } = useVendors();
  const { locations } = useUserLocations();
  const { projects } = useProjectListOptions();
  const { createReservedRelease, createStandaloneRelease, creating } =
    useCreateRelease();
  const { costCodes } = useProjectCostCodes();
  const { viewer, poNumbering } = useUser();
  const { settings } = useOrgSettings();
  const { warehouses } = useWarehouses();
  const [view, setView] = useState(NewOrderType.CreateNew);
  const { createQuoteDocumentWithAsset } = useQuoteDocument();
  const { createNoteDocumentWithAsset } = useNoteDocument();
  const { getOrderType, defaultOrderType } = useOrderTypeOptions();
  const { openDialog } = useDialog();
  const { refetch } = useDeliveries();

  const formMethods = useForm<NewDeliveryFormType>({
    defaultValues: {
      locationId: "",
      projectId: project?.id || "",
      buyoutId: "",
      orderTypeId: "",
      warehouseId: "",
      vendorId: "",
      willCall: false,
      vendorStocking: false,
      poNumber: "",
      reservePoNumber: false,
      reserveSequenceNumber: false,
      costCodeId: undefined,
      watcherIds: [],
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const { watch, setValue, getValues } = formMethods;

  const locationId = watch("locationId");
  const projectId = watch("projectId");
  const orderTypeId = watch("orderTypeId");
  const vendorId = watch("vendorId");
  const buyoutId = watch("buyoutId");
  const reservePoNumber = watch("reservePoNumber");
  const reserveSequenceNumber = watch("reserveSequenceNumber");

  const selectedProject = useMemo(
    () => projects.find((project) => project.id === projectId),
    [projectId, projects],
  );

  const { users } = useUsers({
    requireLocation: true,
    locationId: selectedProject?.location.id,
  });

  useSetCurrentProjectId(projectId);

  const costCodeOptions = useMemo(
    () =>
      costCodes.map((costCode) => ({
        value: costCode.id,
        label: costCode.formatted,
      })),
    [costCodes],
  );

  const { predictedPoNumber, loading: loadingPredictedPoNumber } =
    useProjectPredictedPoNumber(projectId);

  const transactionKind = useMemo(
    () => getOrderType(orderTypeId)?.transactionKind,
    [getOrderType, orderTypeId],
  );
  const { orderTypeConfig } = useOrderTypesConfig({
    release:
      orderTypeId && transactionKind
        ? {
            type: {
              transactionKind,
            },
          }
        : undefined,
  });

  const { warehouseOptions } = useWarehouseOptions(
    warehouses,
    project?.address ||
      projects.find((project) => project.id === projectId)?.address,
  );

  useEffect(() => {
    setProjectIdFilter(projectId);
  }, [projectId, setProjectIdFilter]);

  useEffect(() => {
    if (projectId) {
      setValue("warehouseId", PROJECT_ADDRESS_ID);
    }
  }, [projectId, setValue]);

  useEffect(() => {
    if (!project && (locations || [])?.length !== 0) {
      setValue("locationId", locations[0].id);
    }
  }, [locations, setValue, project]);

  useEffect(() => {
    if (!project && projects?.length === 1 && !getValues("projectId")) {
      setValue("projectId", projects[0].id);
    }
  }, [project, projects, setValue, getValues]);

  useEffect(() => {
    if (defaultOrderType) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, setValue]);

  useEffect(() => {
    if (buyoutId) {
      const buyout = buyouts.find((b) => b.id === buyoutId);
      setValue("orderTypeId", buyout?.releaseType?.id || "");
    }
  }, [buyoutId, buyouts, setValue]);

  useEffect(() => {
    if (vendorId) {
      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [findOrderTypeByLocationId, setValue, vendorId]);

  const hasBuyout = useMemo(
    () =>
      !!vendorId && buyouts.some((b) => b.sellerOrgLocation?.id === vendorId),
    [vendorId, buyouts],
  );

  useEffect(() => {
    if (projectId && users) {
      if (
        settings?.releases?.notifications ===
        ReleaseNotificationsMode.SubmitterOnly
      ) {
        if (viewer?.id) {
          setValue("watcherIds", [viewer?.id]);
        }
        return;
      }
      const project = projects.find((project) => project.id === projectId);
      if (project) {
        setValue(
          "watcherIds",
          project.team
            .map((teamMember) => teamMember.id)
            .filter((teamMember) =>
              users.some((user) => user.id === teamMember),
            ),
        );
      }
    }
  }, [
    projectId,
    projects,
    setValue,
    settings?.releases?.notifications,
    viewer?.id,
    users,
  ]);

  const onSubmit = useCallback(
    async (values: NewDeliveryFormType) => {
      const {
        projectId,
        buyoutId,
        vendorId,
        warehouseId,
        orderTypeId,
        poNumber,
        reservePoNumber,
        reserveSequenceNumber,
        costCodeId,
        watcherIds,
      } = values;
      const newPoNumber = reservePoNumber
        ? poNumbering === PoNumberingMode.Always
          ? poNumber || predictedPoNumber
          : poNumber
        : undefined;
      if (hasBuyout && buyoutId) {
        const result = await createReservedRelease({
          buyoutId,
          typeId: orderTypeId || undefined,
          poNumber: newPoNumber,
          costCodeId,
          watcherIds,
        });
        if (result) {
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result.id,
            }),
          );
        }
      } else {
        const includeServices = [];
        if (!values.willCall) {
          includeServices.push({ type: ServiceType.Delivery });
        }
        if (!values.willCall && values.vendorStocking) {
          includeServices.push({ type: ServiceType.Stocking });
        }

        const result = await createStandaloneRelease({
          projectId,
          sellerOrgLocationId: vendorId || undefined,
          warehouseId:
            warehouseId === PROJECT_ADDRESS_ID ? undefined : warehouseId,
          includeServices,
          typeId: orderTypeId || undefined,
          poNumber: newPoNumber,
          costCodeId,
          status:
            reserveSequenceNumber || reservePoNumber
              ? ReleaseStatus.Reserved
              : undefined,
          watcherIds,
        });
        if (result) {
          if (reservePoNumber && result.poNumber) {
            onClose();
            refetch();
            openDialog({
              content: (
                <SuccessModal
                  message={
                    <ConfirmationContainer>
                      {intl.$t(
                        { id: "RESERVE_PO_NUMBER_CONFIRMATION" },
                        { poNumber: result.poNumber },
                      )}
                    </ConfirmationContainer>
                  }
                />
              ),
              closingTimer: DIALOG_AUTO_CLOSE_TIMER,
            });
            return;
          }
          if (reserveSequenceNumber && result.sequenceNumber) {
            onClose();
            refetch();
            openDialog({
              content: (
                <SuccessModal
                  message={
                    <ConfirmationContainer>
                      {intl.$t(
                        { id: "ORDER_NUMBER_WAS_RESERVED" },
                        { sequenceNumber: result.sequenceNumber },
                      )}
                    </ConfirmationContainer>
                  }
                />
              ),
              closingTimer: DIALOG_AUTO_CLOSE_TIMER,
            });
            return;
          }
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result.id,
            }),
          );
        }
      }
    },
    [
      createReservedRelease,
      createStandaloneRelease,
      hasBuyout,
      onClose,
      refetch,
      openDialog,
      navigate,
      predictedPoNumber,
      poNumbering,
      intl,
    ],
  );

  const requiresApproval = useMemo(() => {
    return (
      settings?.releaseApproval.enabled &&
      !settings?.releaseApproval.exemptRoles.some((role) =>
        viewer?.locationRoles.find(
          (lRole) => lRole.role === role && lRole.orgLocID === locationId,
        ),
      )
    );
  }, [settings, locationId, viewer?.locationRoles]);

  const onTabChange = useCallback(
    (viewState: string) => {
      setView(viewState as NewOrderType);
    },
    [setView],
  );

  const createFromQuote = useCallback(
    async (values: NewDeliveryFormType) => {
      const result = await createQuoteDocumentWithAsset(
        values.locationId || project?.location.id || "",
      );
      if (result) {
        navigate({
          pathname: generatePath(routes.deliveryFromQuote, {
            quoteDocumentId: result?.id,
          }),
          search: `?projectId=${projectId}`,
        });
      }
    },
    [createQuoteDocumentWithAsset, navigate, project?.location.id, projectId],
  );

  const createFromNote = useCallback(
    async (values: NewDeliveryFormType) => {
      const result = await createNoteDocumentWithAsset(
        values.locationId || project?.location.id || "",
      );

      if (result) {
        navigate({
          pathname: generatePath(routes.deliveryFromNote, {
            noteDocumentId: result?.id,
          }),
          search: `?projectId=${projectId}`,
        });
      }
    },
    [createNoteDocumentWithAsset, navigate, project?.location.id, projectId],
  );

  const saveLabel = useMemo(() => {
    const id =
      reservePoNumber || reserveSequenceNumber
        ? poNumbering !== PoNumberingMode.Never
          ? "RESERVE_PO_NUMBER"
          : "RESERVE_ORDER"
        : view === NewOrderType.CreateNew
          ? "CREATE_DELIVERY"
          : "IMPORT";
    return intl.$t({ id });
  }, [intl, poNumbering, reservePoNumber, reserveSequenceNumber, view]);

  const selectedLocation = useMemo(
    () => locations.find((location) => location.id === locationId),
    [locationId, locations],
  );

  return {
    selectedLocation,
    saveLabel,
    createFromNote,
    createFromQuote,
    onTabChange,
    requiresApproval,
    creating,
    onSubmit,
    costCodeOptions,
    orderTypeConfig,
    loadingPredictedPoNumber,
    warehouseOptions,
    formMethods,
    predictedPoNumber,
    hasBuyout,
    view,
    users,
  };
};
