import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  Base,
  MdFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { ServiceCodeFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ServiceCodeActions } from "./ServiceCodeActions";

const WideContainer = tw(Base)`
  lg:basis-auto lg:w-5/12 2xl:basis-auto 2xl:w-6/12
`;

export const useServiceCodesConfiguration = () => {
  const configuration: Array<GridCol<ServiceCodeFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: WideContainer,
        header: <FormattedMessage id="DESCRIPTION" />,
        item: ({ item }) => item.description,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="EQUIPMENT_NUMBER" />,
        item: ({ item }) => item.code,
        position: "center",
      },
      {
        wrapper: XxsFixedContainer,
        header: "",
        position: "end",
        item: ({ item }) => <ServiceCodeActions item={item} />,
      },
    ],
    [],
  );

  return { configuration };
};
