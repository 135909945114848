import { CategoryProps } from "@/config/deliveryConfiguration";
import { Identity } from "@/types/Identity";

export type CategoryState<T extends Identity> = {
  id: string;
  name: string;
  isOpened: boolean;
  items: T[];
  description?: string;
  parentId?: string;
  weightedAmount?: number;
};

export type CategoryLike = Pick<
  CategoryState<Identity>,
  "id" | "name" | "isOpened" | "parentId"
>;

export const useToggleCategory = <SubType, Type extends CategoryProps<SubType>>(
  categories: Type[],
  setCategories: (categories: Type[]) => void,
) => {
  const toggleCategory = (id: SubType) => {
    const categoryIndex = categories.findIndex(
      (category) => category.id === id,
    );
    if (categoryIndex !== -1) {
      const category = categories[categoryIndex];
      setCategories([
        ...categories.slice(0, categoryIndex),
        { ...category, isOpened: !category.isOpened },
        ...categories.slice(categoryIndex + 1),
      ]);
    }
  };

  return {
    toggleCategory,
  };
};
