import {
  ImportPaymentMethodsMutation,
  SourceSystem,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type ImportPaymentMethodsState = {
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) => void;
  closeModal: () => void;
  selectedExternalPaymentMethods: string[];
  setSelectedExternalPaymentMethods: (id: string[]) => void;
  importAllExternalItems: boolean;
  setImportAllExternalItems: (value: boolean) => void;
  totalExternalPaymentMethodsCount: number;
  setTotalExternalPaymentMethodsCount: (count: number) => void;
  importedPaymentMethods:
    | ImportPaymentMethodsMutation["importPaymentMethods"]
    | null;
  setImportedPaymentMethods: (
    importedEquipmentItems:
      | ImportPaymentMethodsMutation["importPaymentMethods"]
      | null,
  ) => void;
};

const initialState = {
  sourceSystem: null,
  modalOpened: false,
  selectedExternalPaymentMethods: [],
  importAllExternalItems: false,
  totalExternalPaymentMethodsCount: 0,
  importedPaymentMethods: null,
};

export const useImportPaymentMethodsStore = create<ImportPaymentMethodsState>()(
  devtools((set) => ({
    ...initialState,
    openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) =>
      set({ sourceSystem, modalOpened: true }),
    closeModal: () => set(initialState),
    setSelectedExternalPaymentMethods: (
      selectedExternalPaymentMethods: string[],
    ) => set({ selectedExternalPaymentMethods }),
    setImportAllExternalItems: (importAllExternalItems: boolean) =>
      set({
        importAllExternalItems,
      }),
    setTotalExternalPaymentMethodsCount: (
      totalExternalPaymentMethodsCount: number,
    ) => set({ totalExternalPaymentMethodsCount }),
    setImportedPaymentMethods: (
      importedPaymentMethods:
        | ImportPaymentMethodsMutation["importPaymentMethods"]
        | null,
    ) => set({ importedPaymentMethods }),
  })),
);
