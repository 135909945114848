import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { SourceSystem } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { AnimatedLoading } from "../../../invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import { useImportZones } from "../../hooks/useImportZones";
import { useProject } from "../../providers/ProjectProvider";

const Row = tw.div`flex gap-x-1`;
const AnimationContainer = tw.div`w-96 h-96`;

export const ImportZonesButton = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { importZones } = useImportZones();
  const { openDialog } = useDialog();
  const { project } = useProject();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const text = useMemo(
    () =>
      intl.$t(
        {
          id:
            connectedSourceSystem &&
            connectedSourceSystem === SourceSystem.Sage300
              ? "IMPORT_ZONES_TEXT_SAGE300"
              : "IMPORT_ZONES_TEXT",
        },
        {
          sourceSystem: intl.$t({
            id: `INTEGRATION_${connectedSourceSystem}`,
          }),
        },
      ),
    [intl, connectedSourceSystem],
  );

  if (
    !project ||
    !connectedSourceSystem ||
    !hasFeatureInConnectedSourceSystem(
      IntegrationFeature.SupportPhasesOrExtrasAsZones,
    ) ||
    !project.mappings.find((m) => m.sourceSystem === connectedSourceSystem)
  ) {
    return null;
  }

  return (
    <Row>
      <OutlinedButton
        onClick={() => {
          openDialog({
            cancelButtonText: intl.$t({ id: "CANCEL" }),
            confirmButtonText: intl.$t({ id: "IMPORT" }),
            title: intl.$t({ id: "IMPORT_ZONES" }),
            text,
            includeWarningIcon: true,
            handleConfirm: async () => {
              openDialog({
                content: (
                  <AnimationContainer>
                    <AnimatedLoading
                      loading
                      text={intl.$t({ id: "IMPORTING" })}
                      className="mt-20 h-5/6"
                    />
                  </AnimationContainer>
                ),
              });
              await importZones({
                projectId: project?.id,
                sourceSystem: connectedSourceSystem,
              });
            },
          });
        }}
        $small
      >
        <FormattedMessage id="IMPORT_ZONES" />
      </OutlinedButton>
      <Tooltip
        id="import-tags"
        element={<InfoOutlined className={"text-blue-500"} />}
        className="flex items-center"
      >
        {text}
      </Tooltip>
    </Row>
  );
};
