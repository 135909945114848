import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";

import {
  UpdateOrgMaterialsInput,
  useUpdateOrgMaterialsMutation,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useMaterialList } from "../providers/MaterialListProvider";
import { useMaterialsStore } from "../stores/useMaterialsStore";

export const useMaterials = () => {
  const { setError } = useGlobalError();
  const { refetch } = useMaterialList();
  const { setPage } = usePagination();
  const {
    materials,
    fetchMaterials,
    setMaterials,
    materialsMap,
    updateMaterialsMap,
    loading,
  } = useMaterialsStore(
    useShallow((state) => ({
      materials: state.materials,
      fetchMaterials: state.fetchMaterials,
      setMaterials: state.setMaterials,
      materialsMap: state.materialsMap,
      updateMaterialsMap: state.updateMaterialsMap,
      loading: state.loading,
    })),
  );

  useEffect(() => {
    fetchMaterials();
  }, [fetchMaterials]);

  const [updateOrgMaterialsMutation, { loading: updating }] =
    useUpdateOrgMaterialsMutation();

  const updateMaterials = useCallback(
    async (input: UpdateOrgMaterialsInput) => {
      try {
        const { data, errors } = await updateOrgMaterialsMutation({
          variables: {
            input,
          },
        });

        if (materials && data?.updateOrgMaterials) {
          const updatedItems =
            materials
              .filter(
                (item) => !input.removedMaterials?.find((id) => id === item.id),
              )
              .map((item) => {
                const updatedItem = data?.updateOrgMaterials.find(
                  (i) => i.id === item.id,
                );
                if (updatedItem) {
                  return updatedItem;
                }
                return item;
              }) ?? [];
          const newItems =
            data?.updateOrgMaterials.filter((item) =>
              materials?.every((i) => i.id !== item.id),
            ) ?? [];
          const updatedMaterial = [...updatedItems, ...newItems];
          updateMaterialsMap(updatedMaterial);
          setMaterials(updatedMaterial);
        }
        setError(errors);
        setPage({ page: 0 });
        refetch();
        return data?.updateOrgMaterials;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [
      updateOrgMaterialsMutation,
      materials,
      setError,
      setPage,
      refetch,
      updateMaterialsMap,
      setMaterials,
    ],
  );

  return {
    materials,
    updating,
    materialsMap,
    updateMaterials,
    loading,
  };
};
