import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceMatchedOrder } from "../../../../providers/InvoiceMatchedOrderProvider";

const Container = tw.div`
  grid w-full text-center text-2xs
`;

const ValueWithTax = tw.div`
  w-full text-center text-2xs text-gray-600
`;

type Props = {
  items: ExpandedReleaseItem[];
};

export const InvoicedItemExtPriceGroup: FC<Props> = ({ items }) => {
  const { release } = useRelease();
  const { calcExtPrice } = usePriceCalculation();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { useInvoiceItemPrice } = useInvoiceMatchedOrder();

  const [valueWithTax, setValueWithTax] = useState(0);

  const total = useMemo(() => {
    return items.reduce(
      (acc, item) =>
        acc.add(
          calcExtPrice(
            item.invoiceItems?.[0]?.quantity || "0",
            useInvoiceItemPrice && !isLumpSumItem(item)
              ? item.invoiceItems?.[0]?.unitPrice
              : item.unitPrice,
          ),
        ),
      new Decimal(0),
    );
  }, [calcExtPrice, items, useInvoiceItemPrice]);

  useEffect(() => {
    if (!total) {
      return;
    }
    if (release?.netAmount && release?.customTaxAmount) {
      const changes = release.additionalCharges.reduce(
        (acc, charge) => acc.add(charge.amount),
        new Decimal(0),
      );
      const calculatedTaxRate = new Decimal(release.customTaxAmount).div(
        new Decimal(total).add(changes),
      );
      setValueWithTax(total.add(total.mul(calculatedTaxRate)).toNumber());
    } else if (release?.taxRate) {
      setValueWithTax(total.add(total.mul(release.taxRate)).toNumber());
    }
  }, [total, release]);

  return (
    <If isTrue={items.some((i) => (i.invoiceItems || []).length > 0)}>
      <Container>
        <Price price={total} />
        <If isTrue={valueWithTax}>
          <FormattedMessage
            id="VALUE_WITH_TAX_AND_FEES"
            tagName={ValueWithTax}
            values={{ value: formatCurrency(valueWithTax) }}
          />
        </If>
      </Container>
    </If>
  );
};
