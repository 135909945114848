import { routes } from "@/config/routes";
import { WithAdminNavigation } from "@/contractor/pages/admin/Admin";
import { Integrations } from "@/contractor/pages/admin/integrations/Integrations";
import { Orgs } from "@/contractor/pages/admin/orgs/Orgs";
import { Users } from "@/contractor/pages/admin/users/Users";
import { Vendors } from "@/contractor/pages/admin/vendors/Vendors";
import { Warehouses } from "@/contractor/pages/admin/warehouse/Warehouses";
import { BuyoutReleases } from "@/contractor/pages/home/buyout-releases/BuyoutReleases";
import { Buyout } from "@/contractor/pages/home/buyout/Buyout";
import { SelectBuyoutProducts } from "@/contractor/pages/home/buyout/components/document/add-to-buyout/AddToBuyout";
import { Buyouts } from "@/contractor/pages/home/buyouts/Buyouts";
import { WithMainTabNavigation } from "@/contractor/pages/home/common/navigation/WithMainTabNavigation";
import { Project } from "@/contractor/pages/home/project/Project";
import { projectRoutesConfig } from "@/contractor/pages/home/project/Routes";
import { SelectProjectProducts } from "@/contractor/pages/home/project/components/project-add-catalog-materials/SelectProjectProducts";
import { Projects } from "@/contractor/pages/home/projects/Projects";
import { RfqCheckout } from "@/contractor/pages/home/rfq-master-catalog-import/RfqCheckout";
import { SelectRfqProducts } from "@/contractor/pages/home/rfq-master-catalog-import/SelectRfqProducts";
import { RfqQuotes } from "@/contractor/pages/home/rfq-quotes/RfqQuotes";
import { Rfq } from "@/contractor/pages/home/rfq/Rfq";
import { Rfqs } from "@/contractor/pages/home/rfqs/Rfqs";
import { costStructureRoutes } from "./pages/admin/cost-structure/Routes";
import { GMVReport } from "./pages/admin/gmv-report/GMVReport";
import { Locations } from "./pages/admin/locations/Locations";
import { adminOrderRoutes } from "./pages/admin/orders/Routes";
import { adminItemsRoutes } from "./pages/admin/org-items/Routes";
import { adminOrgRoutes } from "./pages/admin/organization/Routes";
import { BuyoutFromQuote } from "./pages/home/buyouts/components/buyout-from-quote/BuyoutFromQuote";
import { invoicesRoutes } from "./pages/home/invoices/Routes";
import { receiptRoutes } from "./pages/home/receipts/Routes";
import { releaseRoutes } from "./pages/home/release/Routes";
import { deliveriesRoutes } from "./pages/home/releases/Routes";
import { RfqFromNote } from "./pages/home/rfqs/components/rfq-from-note/RfqFromNote";
import { userAccountRoutes } from "./pages/home/user-profile/user-account/Routes";

const rfqRoutes = {
  children: [
    { path: routes.addToRfq, element: <SelectRfqProducts /> },
    { path: routes.rfqCheckout, element: <RfqCheckout /> },
    { path: routes.rfqById, element: <Rfq /> },
    { path: routes.rfqFromNote, element: <RfqFromNote /> },
    { path: routes.editRfqFromNote, element: <RfqFromNote /> },
    { path: routes.bidsWithId, element: <RfqQuotes /> },
    { path: routes.bidMessaging, element: <RfqQuotes /> },
  ],
};

const projectRoutes = {
  children: [
    {
      path: routes.project,
      element: <Project />,
      children: projectRoutesConfig.children,
    },
    { path: routes.addToProject, element: <SelectProjectProducts /> },
  ],
};

const homeRoutes = {
  children: [
    {
      path: routes.projects,
      element: (
        <WithMainTabNavigation>
          <Projects />
        </WithMainTabNavigation>
      ),
    },
    {
      path: routes.quotes,
      element: (
        <WithMainTabNavigation>
          <Rfqs />
        </WithMainTabNavigation>
      ),
    },
    {
      path: routes.buyout,
      element: <Buyout />,
    },
    {
      path: routes.addToBuyout,
      element: <SelectBuyoutProducts />,
    },
    {
      path: routes.buyoutMessages,
      element: <Buyout />,
    },
    {
      path: routes.buyoutDeliveries,
      element: <BuyoutReleases />,
    },
    {
      path: routes.buyoutFromQuote,
      element: <BuyoutFromQuote />,
    },
    {
      path: routes.editBuyoutFromQuote,
      element: <BuyoutFromQuote />,
    },
    {
      path: routes.buyouts,
      element: (
        <WithMainTabNavigation>
          <Buyouts />
        </WithMainTabNavigation>
      ),
    },
    ...deliveriesRoutes,
    ...userAccountRoutes,
    ...invoicesRoutes,
    ...receiptRoutes,
  ],
};

const adminRoutes = {
  children: [
    {
      path: routes.locations,
      element: (
        <WithAdminNavigation>
          <Locations />
        </WithAdminNavigation>
      ),
    },
    {
      path: routes.warehouses,
      element: (
        <WithAdminNavigation>
          <Warehouses />
        </WithAdminNavigation>
      ),
    },
    {
      path: routes.users,
      element: (
        <WithAdminNavigation>
          <Users />
        </WithAdminNavigation>
      ),
    },
    {
      path: routes.vendors,
      element: (
        <WithAdminNavigation>
          <Vendors />
        </WithAdminNavigation>
      ),
    },
    {
      path: routes.userRoles,
      element: (
        <WithAdminNavigation>
          <></>
        </WithAdminNavigation>
      ),
    },
    {
      path: routes.vendorsWithId,
      element: (
        <WithAdminNavigation>
          <Vendors />
        </WithAdminNavigation>
      ),
    },
    {
      path: routes.accounting,
      element: (
        <WithAdminNavigation>
          <></>
        </WithAdminNavigation>
      ),
    },
    ...adminOrgRoutes,
    ...adminItemsRoutes,
    ...adminOrderRoutes,
    ...costStructureRoutes,
    {
      path: routes.orgs,
      element: (
        <WithAdminNavigation>
          <Orgs />
        </WithAdminNavigation>
      ),
    },
    {
      path: routes.gmvReport,
      element: (
        <WithAdminNavigation>
          <GMVReport />
        </WithAdminNavigation>
      ),
    },
    {
      path: routes.connections,
      element: (
        <WithAdminNavigation>
          <Integrations />
        </WithAdminNavigation>
      ),
    },
  ],
};

export const contractorRoutes = [
  homeRoutes,
  adminRoutes,
  rfqRoutes,
  projectRoutes,
  releaseRoutes,
];
