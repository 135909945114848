import { If } from "@/common/components/if/If";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { KickbackIndicator } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/list/KickbackIndicator";
import { ReceiptSummaryFieldsFragment } from "@/generated/graphql";
import { CheckRounded } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-row gap-1`;
const CheckedGreen = tw(CheckRounded)`rounded-full p-0.5 bg-green-300`;
const CheckedGrayOutlined = tw(
  CheckRounded,
)`rounded-full p-0.5 bg-white text-gray-400 border border-gray-400`;
const CheckedGray = tw(
  CheckRounded,
)`rounded-full p-0.5 bg-gray-400 text-white border border-gray-400`;

export const ReceiptWarnings: FC<{ receipt: ReceiptSummaryFieldsFragment }> = ({
  receipt,
}) => {
  return (
    <>
      <KickbackIndicator kickbacks={receipt.kickbacks} />
      <SourceSystemWrapper anyIntegrationType anySourceSystem>
        <Container>
          <Tooltip
            id={`export-receipt-${receipt.id}`}
            element={
              <>
                <If isTrue={!receipt.prePaid}>
                  <CheckedGray />
                </If>
                <If
                  isTrue={
                    !!receipt.prePaid &&
                    !receipt.exportedAt &&
                    !receipt.link?.exportedAt
                  }
                >
                  <CheckedGrayOutlined />
                </If>
                <If
                  isTrue={
                    !!receipt.prePaid &&
                    (receipt.exportedAt || receipt.link?.exportedAt)
                  }
                >
                  <CheckedGreen />
                </If>
              </>
            }
          >
            <FormattedMessage
              id={
                !receipt.prePaid
                  ? "EXPORT_NOT_REQUIRED_FOR_PAID_RECEIPT"
                  : !!receipt.exportedAt || !!receipt.link?.exportedAt
                    ? "RECEIPT_EXPORTED_AT"
                    : "RECEIPT_NOT_EXPORTED"
              }
              values={{
                date: (
                  <DateView
                    date={receipt.exportedAt || receipt.link?.exportedAt}
                    options={SHORT_DATE_OPTION}
                  />
                ),
              }}
            />
          </Tooltip>
        </Container>
      </SourceSystemWrapper>
    </>
  );
};
