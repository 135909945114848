import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRfq } from "../../../rfq/hooks/useRfq";

export const ArchiveRfqButton = () => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const { rfq, removeRfq } = useRfq();

  const remove = useCallback(async () => {
    if (rfq) {
      const result = await removeRfq(rfq?.id);
      if (result) {
        setSuccessAlert(
          intl.$t(
            { id: "RFQ_DELETE_RFQ_QUESTION_SUCCESS" },
            {
              rfqNumber: strongify(rfq?.clientIdentifier),
            },
          ),
        );
      }
    }
  }, [intl, removeRfq, rfq, setSuccessAlert]);

  if (!rfq) {
    return null;
  }

  return (
    <OutlinedButton
      onClick={() =>
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "PROCEED" }),
          includeWarningIcon: true,
          title: intl.$t({ id: "RFQ_DELETE_RFQ_QUESTION" }),
          text: intl.$t({ id: "RFQ_DELETE_RFQ_QUESTION_TEXT" }),
          handleConfirm: remove,
        })
      }
    >
      <FormattedMessage id="RFQ_DELETE_RFQ" />
    </OutlinedButton>
  );
};
