import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { Base, MdFixedContainer } from "@/common/layout/ResponsiveClasses";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ProjectItemUnitsInput } from "@/contractor/pages/home/project/components/project-items/ProjectItemUnitsInput";
import {
  TagContainer,
  WideRfqItemContainer,
} from "@/contractor/pages/home/project/components/project-list/ProjectListItem.styles";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { RfqProjectItemTagPicker } from "../rfq-project-item-tag-picker/RfqProjectItemTagPicker";
import { RfqProjectItemCheckbox } from "./RfqProjectItemCheckbox";
import { RfqProjectItemGroupCheckbox } from "./RfqProjectItemGroupCheckbox";

export const useRfqProjectListItemConfiguration = () => {
  const { hasPhaseCodes } = useOrgSettings();

  const configuration: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  > = useMemo(() => {
    return [
      {
        wrapper: Base,
        item: ({ item }) => {
          return (
            <RfqProjectItemCheckbox
              item={item}
              estimatedItem={getEstimatedItem({ item })}
            />
          );
        },
        subgroup: (category) => {
          return <RfqProjectItemGroupCheckbox items={category.items} />;
        },
        position: "center",
      },
      {
        wrapper: WideRfqItemContainer,
        item: ({ item, count }) => {
          const estimatedItem = getEstimatedItem({ item });
          return (
            <ProjectItemMaterialView
              estimatedItem={estimatedItem}
              item={item}
              count={count}
              manufacturer={
                item.estimatedItems.length < 2
                  ? estimatedItem?.manufacturer
                  : undefined
              }
            />
          );
        },
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
          />
        ),
        details: ({ item, index }) => (
          <ProjectItemMaterialView
            item={item}
            includeCounter={false}
            className="pl-8"
            estimatedItem={getEstimatedItem({ item, index })}
            manufacturer={
              getEstimatedItem({ item, index })?.manufacturer ||
              item.manufacturer
            }
          />
        ),
        header: <FormattedMessage id="PROJECT_ITEM_ESTIMATED_ITEMS" />,
        includesCounter: true,
      },
      {
        wrapper: TagContainer,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />,
        item: ({ item }) => {
          return <RfqProjectItemTagPicker item={item} />;
        },
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => {
          const estimatedItem = getEstimatedItem({
            item,
            sumIfMultiple: true,
          });
          return (
            <ProjectItemUnitsInput
              item={item}
              estimatedItem={estimatedItem}
              staticText
            />
          );
        },
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} $bold />
        ),
        subgroup: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
        position: "center",
        header: <FormattedMessage id="ESTIMATED_QUANTITY" />,
      },
    ];
  }, [hasPhaseCodes]);

  return configuration;
};
