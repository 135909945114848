import { If } from "@/common/components/if/If";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useRelease } from "../../../providers/ReleaseProvider";

const PaymentTermsText = tw.div`font-light`;

export const ReleaseTaxFooter = ({
  includeAdditionalCharges,
}: {
  includeAdditionalCharges: boolean;
}) => {
  const { release } = useRelease();
  return (
    <FooterCol>
      <FooterHeader />
      <If isTrue={includeAdditionalCharges}>
        <FooterCell />
      </If>
      <FooterCell />
      <FooterCell />
      <FooterDashedLine />
      <FooterTotal className="justify-start">
        <FooterCellCol className="ml-5">
          <FormattedMessage id="PAYMENT_TERMS" tagName={FooterHeaderText} />
          <FormattedMessage
            id="PAYMENT_TERM_NET_WITH_DAYS"
            values={{ days: release?.paymentTerm }}
            tagName={PaymentTermsText}
          />
        </FooterCellCol>
      </FooterTotal>
    </FooterCol>
  );
};
