import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { Price } from "@/common/components/price/Price";
import { routes } from "@/config/routes";
import { BuyoutItemFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { AccessTime, Check } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, Fragment, useMemo } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";

const Container = tw.div`grid grid-cols-[40px_2fr_1fr] my-5`;

const StyledCheck = tw(Check)`w-5 mr-2`;
const StyledClock = tw(AccessTime)`w-5 mr-2`;
const DeliveryNumber = tw.div`font-medium text-sm`;
const DeliveryItemName = tw.div`grid grid-flow-col justify-start items-center flex-col ml-2`;
const VendorImage = tw.div`flex flex-row items-center`;
const OrderDetailsContainer = tw.div`flex flex-col gap-y-0.5`;
const IconContainer = tw.div`flex flex-row items-center`;
const DeliveryDate = tw.div`flex flex-row items-center font-normal text-xs`;
const DeliveryQuantity = tw.div`grid items-center justify-end content-center text-center`;
const OrderLinksContainer = tw.div`flex flex-row items-center gap-x-2`;
const EmptyIconView = tw.div`w-5 mr-2`;
const OrderedQuantity = tw.div`flex flex-row items-center font-medium text-sm justify-end`;
const Amount = tw.div`flex flex-row justify-end font-normal text-xs text-end`;

type ReleaseItem = BuyoutItemFieldsFragment["releaseItems"][0];

type Props = {
  releaseItems: Array<ReleaseItem>;
  releaseStatuses: ReleaseStatus[];
};

export const BuyoutReleaseItem: FC<Props> = ({
  releaseItems,
  releaseStatuses,
}) => {
  const time = useMemo(() => {
    switch (releaseItems[0].release.status) {
      case ReleaseStatus.Received:
      case ReleaseStatus.PartiallyReceived:
      case ReleaseStatus.Scheduled:
      case ReleaseStatus.Requested:
        return releaseItems[0].release.time;
      default:
        return null;
    }
  }, [releaseItems]);

  const icon = useMemo(() => {
    switch (releaseItems[0].release.status) {
      case ReleaseStatus.Scheduled:
      case ReleaseStatus.Requested:
        return <StyledClock />;
      case ReleaseStatus.Received:
      case ReleaseStatus.PartiallyReceived:
        return <StyledCheck />;
      default:
        return <EmptyIconView />;
    }
  }, [releaseItems]);

  const total = useMemo(
    () =>
      releaseItems.reduce((acc, item) => {
        const itemTotal = new Decimal(item.quantityDecimal).mul(
          item.unitPrice || 0,
        );
        return acc.add(
          releaseStatuses.includes(item.release.status) ? itemTotal : 0,
        );
      }, new Decimal(0)),
    [releaseItems, releaseStatuses],
  );

  return (
    <Container>
      <VendorImage>
        <OrgLogo
          logoImageUrl={releaseItems[0].release.sellerOrgLocation?.org.photoUrl}
          name={releaseItems[0].release.sellerOrgLocation?.org.name as string}
          width={32}
        />
      </VendorImage>
      <DeliveryItemName>
        <IconContainer>{icon}</IconContainer>
        <OrderDetailsContainer>
          <OrderLinksContainer>
            <LinkLike
              to={generatePath(routes.delivery, {
                deliveryId: releaseItems[0].release.id,
              })}
            >
              <FormattedMessage
                id="DELIVERY_WITH_NUMBER"
                values={{ number: releaseItems[0].release.sequenceNumber }}
                tagName={DeliveryNumber}
              />
            </LinkLike>
          </OrderLinksContainer>
          <If isTrue={time}>
            <DeliveryDate>
              <FormattedDate value={time ?? undefined} />
            </DeliveryDate>
          </If>
        </OrderDetailsContainer>
      </DeliveryItemName>
      <DeliveryQuantity>
        <OrderedQuantity>
          <Price price={total} />
        </OrderedQuantity>
        {releaseItems
          .filter((releaseItem) =>
            releaseStatuses.includes(releaseItem.release.status),
          )
          .map((releaseItem) => (
            <Fragment key={releaseItem.id}>
              <Amount>
                {releaseItem.quantityDecimal}{" "}
                {releaseItem.uom?.mnemonic ||
                  releaseItem.uom?.pluralDescription}
              </Amount>
            </Fragment>
          ))}
      </DeliveryQuantity>
    </Container>
  );
};
