import { useCallback } from "react";

type Item = {
  tags: Array<{
    hasMapping: boolean;
  }>;
};

export const useItemsHavePhaseCodes = () => {
  return useCallback(
    (items: Array<Item>) =>
      items.some((item) => item.tags.some((tag) => tag.hasMapping)),
    [],
  );
};
