import { useDialog } from "@/common/components/dialog/DialogProvider";
import { goToExternalUrl } from "@/common/utils/browserUtils";
import { AssetFieldsFragment } from "@/generated/graphql";
import { useIntl } from "react-intl";
import { AssetViewer } from "../asset-viewer/AssetViewer";

export const useOpenFile = () => {
  const { openDialog } = useDialog();
  const intl = useIntl();

  const openImageHandler = (asset: AssetFieldsFragment) => {
    openDialog({
      content: (
        <AssetViewer asset={asset} className="w-[900px] justify-items-center" />
      ),
      cancelButtonText: intl.$t({ id: "CLOSE" }),
      hasCloseIcon: true,
      maxWidth: "xl",
      classes: {
        root: "px-0 overflow-hidden w-full",
        scrollBody: "w-fit h-fit",
      },
    });
  };

  const open = (asset: AssetFieldsFragment) => {
    if (asset.thumbnailUrl) {
      openImageHandler(asset);
    } else {
      goToExternalUrl(asset.url, true);
    }
  };

  return { open };
};
