import { useDialog } from "@/common/components/dialog/DialogProvider";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useLinkPo } from "@/contractor/pages/home/release/components/connections/hooks/useLinkPo";
import {
  MissingBudgetCombinationsProvider,
  useMissingBudgetCombinations,
} from "@/contractor/pages/home/release/components/connections/providers/MissingBudgetCombinationsProvider";
import {
  MissingItemsFromInventoryProvider,
  useMissingItemsFromInventory,
} from "@/contractor/pages/home/release/components/connections/providers/MissingItemsFromInventoryProvider";
import {
  MissingMaterialConversionsProvider,
  useMissingMaterialConversions,
} from "@/contractor/pages/home/release/components/connections/providers/MissingMaterialConversionsProvider";
import {
  BuyoutFieldsFragment,
  PoFormat,
  PoNumberingMode,
  useProjectPredictedPoNumberQuery,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ExportCompleted,
  ExportType,
} from "../../../../../../../../common/components/export-completed/ExportCompleted";
import { useBuyoutSequence } from "../../../../hooks/useBuyoutSequence";
import { useBuyoutPoValidation } from "../../hooks/useBuyoutPoValidation";
import { HostedIntegrationBuyoutInformation } from "../connection-types/hosted/HostedIntegrationBuyoutInformation";

const CardList = tw.div`flex flex-col gap-3`;

type Props = {
  buyout: BuyoutFieldsFragment;
  titleId?: string;
  onClose: () => void;
};

const ExportBuyoutPOPanelWithProviders: FC<Props> = ({
  buyout,
  titleId = "CONNECT_BUYOUT",
  onClose,
}) => {
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const { poNumbering } = useUser();
  const { settings, connectedSourceSystem } = useOrgSettings();
  const { linkPo, linkingPo } = useLinkPo();
  const { updateBuyout } = useContractorBuyout();
  const { openModal: openMissingConversionsModal } =
    useMissingMaterialConversions();
  const { openModal: openMissingItemsModal } = useMissingItemsFromInventory();
  const { sequenceActive, navigateToNextSequence } = useBuyoutSequence();
  const { openModal: openMissingBudgetCombinationsModal } =
    useMissingBudgetCombinations();

  const [poNumber, setPoNumber] = useState(buyout?.poNumber ?? "");

  const { data: predictedPoNumberData } = useProjectPredictedPoNumberQuery({
    variables: {
      id: buyout.project.id,
    },
    skip:
      !!poNumber || !buyout.project.id || poNumbering === PoNumberingMode.Never,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!poNumber && predictedPoNumberData?.project?.predictedPoNumber) {
      setPoNumber(predictedPoNumberData?.project?.predictedPoNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictedPoNumberData]);

  const {
    isBuyoutPoValidated,
    buyoutPoValidation,
    loadingBuyoutPoValidation,
    validateBuyoutPo,
  } = useBuyoutPoValidation({
    poNumber,
    buyout,
    openMissingConversionsModal,
    openMissingItemsModal,
    openMissingBudgetCombinationsModal,
  });

  useEffect(() => {
    validateBuyoutPo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = useCallback(async () => {
    if (!connectedSourceSystem) {
      return;
    }
    const poLink = await linkPo({
      buyoutId: buyout.id,
      poNumber,
      autoSync: false,
      format: PoFormat.Basic,
      sourceSystem: connectedSourceSystem,
    });
    if (poLink === true) {
      updateBuyout({
        buyoutId: buyout.id,
        poNumber,
      });
      openDialog({
        content: <ExportCompleted count={1} type={ExportType.BUYOUT} />,
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        hideTitle: true,
      });
      onClose();
      if (sequenceActive) {
        navigateToNextSequence();
      }
    } else if (typeof poLink === "string") {
      setWarningAlert(poLink);
    }
  }, [
    connectedSourceSystem,
    linkPo,
    buyout.id,
    poNumber,
    updateBuyout,
    openDialog,
    onClose,
    sequenceActive,
    navigateToNextSequence,
    setWarningAlert,
  ]);

  return (
    <OverlayPanel
      title={`${intl.$t({ id: titleId })} (${
        settings
          ? intl.$t({
              id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
            })
          : ""
      })`}
      isLoading={linkingPo}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t({
        id: "EXPORT",
      })}
      disableSave={!poNumber || !isBuyoutPoValidated}
    >
      <CardList>
        <HostedIntegrationBuyoutInformation
          poNumber={poNumber}
          updatePoNumber={setPoNumber}
          buyout={buyout}
          poValidation={buyoutPoValidation}
          loadingPoValidation={loadingBuyoutPoValidation}
        />
      </CardList>
    </OverlayPanel>
  );
};

export const ExportBuyoutPOPanel = (props: Props) => (
  <MissingMaterialConversionsProvider>
    <MissingItemsFromInventoryProvider>
      <MissingBudgetCombinationsProvider>
        <ExportBuyoutPOPanelWithProviders {...props} />
      </MissingBudgetCombinationsProvider>
    </MissingItemsFromInventoryProvider>
  </MissingMaterialConversionsProvider>
);
