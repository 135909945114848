import Handsontable from "handsontable";

export const toggleTableReadOnly = (
  hotInstance: Handsontable | undefined | null,
  ignoreReadOnly: boolean,
): void => {
  hotInstance?.updateSettings({
    cells: function () {
      return { ...this, ignoreReadOnly };
    },
  });
};
