import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ImportAllowanceInput,
  ProjectAllowanceDocument,
  ProjectDocument,
  ProjectStoreDocument,
  UpdateProjectInput,
  useImportAllowanceMutation,
  useProjectAllowanceQuery,
  useUpdateProjectAllowanceMutation,
} from "@/generated/graphql";
import { useParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../../../store/useProjectStore";

type ProjectBudgetInput = Pick<
  UpdateProjectInput,
  "budgetAllowance" | "restrictCostCodes"
>;

export const useProjectBudget = () => {
  const { id } = useParams();
  const { data, error, loading } = useProjectAllowanceQuery({
    variables: { id: id ?? "" },
    skip: !id,
  });
  const [importAllowanceMutation, { loading: importing }] =
    useImportAllowanceMutation();
  const { setError } = useGlobalError();
  const [updateProjectMutation] = useUpdateProjectAllowanceMutation();
  const refetch = useProjectStore(useShallow((state) => state.refetch));

  useErrorEffect(error);

  const updateProjectBudget = async (input: ProjectBudgetInput) => {
    try {
      const { data: updatedProject, errors } = await updateProjectMutation({
        variables: {
          input: {
            id: id ?? "",
            ...input,
          },
        },
        refetchQueries: [
          {
            query: ProjectDocument,
            variables: { id: id ?? "" },
          },
        ],
      });
      setError(errors);
      if (updatedProject?.updateProject) {
        refetch();
        return true;
      }
    } catch (errors) {
      setError(errors);
    }
    return false;
  };

  const importAllowance = async (input: ImportAllowanceInput) => {
    try {
      const { data, errors } = await importAllowanceMutation({
        variables: {
          input,
        },
        refetchQueries: [
          { query: ProjectAllowanceDocument, variables: { id } },
          { query: ProjectStoreDocument, variables: { id } },
        ],
      });

      setError(errors);
      return !!data;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return {
    budget: data?.project?.allowance ?? null,
    loading,
    updateProjectBudget,
    importAllowance,
    importing,
  };
};
