import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useStore } from "zustand";
import {
  createGridTableStore,
  GridTableProps,
  GridTableState,
  GridTableStore,
} from "../store/useGridTableStore";

type Props = PropsWithChildren & Partial<GridTableProps>;

const GridTableContext = createContext<GridTableStore | null>(null);

export const GridTableProvider = ({ children, ...props }: Props) => {
  const store = useMemo(() => createGridTableStore(props), [props]);

  return (
    <GridTableContext.Provider value={store}>
      {children}
    </GridTableContext.Provider>
  );
};

export const useGridTable = <T,>(selector: (state: GridTableState) => T) => {
  const context = useContext(GridTableContext);

  if (!context) {
    throw new Error("useGridTable must be used within a GridTableProvider");
  }
  return useStore(context, selector);
};
