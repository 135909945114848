import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { Loader } from "@/common/components/loader/Loader";
import { NoResults } from "@/common/components/no-results/NoResults";
import { ZoneItemsContainer } from "@/contractor/pages/home/project/Project.styles";
import { useSpendingReportItems } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import {
  ProjectReportType,
  useSpendingReport,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { FC, useMemo } from "react";

type Props = {
  columns: Array<GridCol<ProjectReportType>>;
};

export const SpendingReportListMaterials: FC<Props> = ({ columns }) => {
  const { zones, toggleCostCode, toggleZone } = useSpendingReportItems();
  const { materialsLoading, zonesLoading, groupedByZones } =
    useSpendingReport();

  const hasItems = useMemo(() => {
    return zones.some((zone) => zone.items.length > 0);
  }, [zones]);

  return (
    <Loader loading={materialsLoading || zonesLoading}>
      <ListRenderer
        hasItemsCondition={hasItems}
        emptyList={<NoResults translationKey="NO_ITEMS" />}
      >
        <GridTable
          configuration={{
            container: ZoneItemsContainer,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columns: columns as any,
            classNames: {
              root: "pb-20",
              header: "top-[134px]",
              category: !groupedByZones ? "top-[170px] h-9" : "top-[185px] h-9",
              subCategory: !groupedByZones
                ? "top-[184px] h-12"
                : "top-[220px] h-12",
            },
            toggle: {
              subCategory: toggleCostCode,
              category: toggleZone,
            },
          }}
          items={zones}
          readonly={true}
          hideGroup={!groupedByZones}
        />
      </ListRenderer>
    </Loader>
  );
};
