import { hasImage } from "@/common/components/upload-asset/UploadAsset";
import { getFileName } from "@/common/components/upload-asset/getFileName";
import { useOpenFile } from "@/common/components/upload-asset/useOpenFile";
import {
  DateView,
  SHORT_DATE_OPTION,
  TIME_OPTION,
} from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { AssetContext, AssetFieldsFragment } from "@/generated/graphql";
import React, { FC, ReactNode, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { FileIcon } from "../file-icon/FileIcon";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import { Tooltip } from "../tooltip/Tooltip";

const ImageContainer = tw.div`grid items-center justify-items-center gap-1`;

type ImageStyledProps = {
  $includeNavigation: boolean;
  $small?: boolean;
  $lightBorder?: boolean;
};
const ImageStyled = tw.img<ImageStyledProps>`
  w-18 h-18 rounded object-cover border border-blue-800 cursor-pointer hover:border-blue-500 p-1
  ${({ $includeNavigation }) => $includeNavigation && "cursor-pointer"}
  ${({ $small }) => $small && "w-12 h-12"}
  ${({ $lightBorder }) => $lightBorder && "border-gray-300 hover:border-gray-300"}
`;
const File = tw.div<ImageStyledProps>`
border-blue-800 border p-1 rounded cursor-pointer
  w-18 h-18 grid grid-flow-col justify-center gap-1 items-center gray-blue-500 font-light text-sm
  ${({ $includeNavigation }) => $includeNavigation && "cursor-pointer"}
  ${({ $small }) => $small && "w-12 h-12"}
  ${({ $lightBorder }) => $lightBorder && "border-gray-300 hover:border-gray-300"}
`;
const TooltipContent = tw.div`flex flex-col`;
const TooltipContentLine = tw.span`text-center`;

type Props = {
  asset?:
    | (AssetFieldsFragment & {
        parentId?: string;
      })
    | null;
  remove?: (asset: AssetFieldsFragment) => void;
  getImageWrapper?: (
    url: string,
    children: ReactNode,
    index: number,
  ) => React.JSX.Element;
  includeNavigation?: boolean;
  customRedirect?: () => void;
  imageDetails?: (
    url: string,
    createdAt?: number,
    index?: number,
  ) => React.JSX.Element | null;
  includeTooltip?: boolean;
  readonly?: boolean;
  small?: boolean;
  index: number;
};

export const AssetItem: FC<Props> = ({
  asset,
  getImageWrapper = (url, children) => <>{children}</>,
  includeNavigation = true,
  customRedirect,
  imageDetails,
  includeTooltip = true,
  readonly = false,
  small = false,
  remove,
  index,
}) => {
  const { open } = useOpenFile();
  const navigate = useNavigate();

  const noAssetCallback = useCallback(() => {
    if (asset?.parentId && asset.context === AssetContext.DeliverySlip) {
      return navigate(
        generatePath(routes.deliverySlipReceiveOrder, {
          deliverySlipId: asset.parentId,
        }),
      );
    }
  }, [navigate, asset]);

  const onAssetClick = useCallback(() => {
    if (!includeNavigation) {
      return;
    }

    if (asset) {
      if (customRedirect) {
        customRedirect();
      } else {
        open(asset);
      }
    } else if (noAssetCallback) {
      noAssetCallback();
    }
  }, [includeNavigation, asset, noAssetCallback, customRedirect, open]);

  if (!asset) {
    return null;
  }

  return (
    <ImageContainer key={asset.url}>
      <Tooltip
        id={`asset`}
        element={getImageWrapper(
          asset.parentId || asset.url,
          <>
            {hasImage(asset) ? (
              <ImageStyled
                src={asset.thumbnailUrl || asset.url}
                alt="image"
                onClick={onAssetClick}
                $includeNavigation={includeNavigation}
                $small={small}
                $lightBorder={!!imageDetails}
              />
            ) : (
              <File
                $includeNavigation={includeNavigation}
                $small={small}
                $lightBorder={!!imageDetails}
                onClick={onAssetClick}
              >
                <FileIcon
                  fileName={getFileName(asset.url)}
                  iconClassName="text-5xl text-gray-400"
                />
              </File>
            )}
            {imageDetails?.(
              asset.parentId || asset.url,
              asset.createdAt,
              index,
            )}
          </>,
          index,
        )}
        hideTooltip={!includeTooltip}
      >
        <TooltipContent>
          <TooltipContentLine>{getFileName(asset.url)}</TooltipContentLine>
          <If isTrue={asset.createdAt}>
            <TooltipContentLine className="mt-1 text-xs">
              <DateView
                date={asset.createdAt}
                options={{ ...SHORT_DATE_OPTION, ...TIME_OPTION }}
              />
            </TooltipContentLine>
          </If>
        </TooltipContent>
      </Tooltip>
      <If isTrue={!readonly && remove}>
        <LinkLike onClick={() => remove?.(asset)}>
          <FormattedMessage id="DELETE" />
        </LinkLike>
      </If>
    </ImageContainer>
  );
};
