import { OrgCatalogSku, OrgMaterialFieldsFragment } from "@/generated/graphql";

export const composeMaterialName = (
  material: OrgMaterialFieldsFragment,
  materials: OrgMaterialFieldsFragment[],
) => {
  const hasDuplicates =
    materials.filter((mat) => mat.material.name === material.material.name)
      .length > 1;
  const name = material.material.name;
  const partNumber = (material.material as OrgCatalogSku).customPartNumber;
  return `${name}${hasDuplicates && partNumber ? `〈${partNumber}〉` : ""}`;
};
