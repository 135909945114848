import { MAX_VENDOR_NUMBER } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";

import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useIntegrationFeatureRequirement } from "../integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { SelectProps } from "../select/components/single/Select";
import { StyledSelect } from "../select/components/single/StyledSelect";
import { VendorPickerCustomRender } from "./VendorPickerCustomRender";
import { useVendorOptions, VendorOption } from "./useVendorOptions";

const ALL_VENDORS = "-1";
type Props<T> = {
  vendorId: string | null | undefined;
  setVendorId: (vendorId: string | null) => void;
  includeAllOption?: boolean;
  xs?: boolean;
  includePlaceholder?: boolean;
  inputPropsClassName?: string;
  contactsFilter?: (
    contact: OrgPreferredVendorsFieldsFragment["contacts"][0],
  ) => boolean;
  className?: string;
} & Pick<
  SelectProps<T>,
  | "getOptionDisabled"
  | "testId"
  | "staticText"
  | "disabledTooltip"
  | "placeholder"
  | "required"
  | "error"
  | "InputLabelProps"
  | "onBlur"
  | "inputProps"
>;

export const VendorPicker = ({
  vendorId,
  setVendorId,
  placeholder,
  includeAllOption = true,
  xs = true,
  includePlaceholder = true,
  inputPropsClassName = "",
  contactsFilter,
  className,
  testId,
  ...props
}: Props<VendorOption>) => {
  const intl = useIntl();
  const { vendorOptions, loading } = useVendorOptions({ includeAllOption });
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const shouldShowVendorCode = useMemo(() => {
    return hasFeatureInConnectedSourceSystem(
      IntegrationFeature.HumanReadableVendorCode,
    );
  }, [hasFeatureInConnectedSourceSystem]);

  return (
    <StyledSelect
      className={`w-full ${className}`}
      testId={testId || "form-vendors-selector"}
      placeholder={
        includePlaceholder
          ? placeholder || intl.$t({ id: "VENDOR" })
          : undefined
      }
      inputProps={{
        className: inputPropsClassName,
      }}
      options={vendorOptions || []}
      loading={loading}
      value={vendorId || ALL_VENDORS}
      onChange={(value) =>
        setVendorId(value !== ALL_VENDORS ? (value as string) : null)
      }
      customRender={(item) =>
        VendorPickerCustomRender(item, {
          filter: contactsFilter,
          shouldShowVendorCode,
        })
      }
      getLabel={(option) => option.name}
      getValue={(option) => option.value as string}
      noOptionsText={intl.$t({ id: "NO_VENDORS_FOUND" })}
      size={xs ? "xs" : undefined}
      limitResults={MAX_VENDOR_NUMBER}
      {...props}
    />
  );
};
