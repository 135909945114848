import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ReleaseDocument,
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
  useUpdateInvoiceMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useInvoiceUpdate = () => {
  const [updateInvoiceMutation, { loading }] = useUpdateInvoiceMutation();
  const { setError } = useGlobalError();

  const updateInvoice = useCallback(
    async (
      input: UpdateInvoiceInput,
      { includeDocuments = false, refetchQueries = false },
    ): Promise<UpdateInvoiceMutation | undefined | null> => {
      try {
        const { data, errors } = await updateInvoiceMutation({
          variables: {
            input,
            includeDocuments,
          },
          refetchQueries: refetchQueries
            ? [
                {
                  query: ReleaseDocument,
                  variables: {
                    id: input.releaseId || "",
                    invoiceId: input.id,
                  },
                },
              ]
            : undefined,
        });
        setError(errors);
        return data;
      } catch (errors) {
        setError(errors);
        return null;
      }
    },
    [setError, updateInvoiceMutation],
  );

  return { updateInvoice, loading };
};
