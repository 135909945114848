import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-row gap-1 items-center relative`;

type Props = {
  item?: ExpandedReleaseItem;
  index: number | undefined;
};

export const ReleaseItemUnitPrice: FC<Props> = ({ item, index }) => {
  const price = useMemo(() => {
    if (item?.unitPrice === null) {
      return null;
    }

    return Number(new Decimal(item?.unitPrice || 0));
  }, [item?.unitPrice]);

  return (
    <Container>
      <NotNullableRenderer value={price !== null}>
        <PricePicker
          value={price?.toString()}
          index={index}
          className="text-xs"
          readonly
        />
      </NotNullableRenderer>
    </Container>
  );
};
