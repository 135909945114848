import { gql } from "@apollo/client";
import { ADDRESS_FIELDS } from "./addressFragment";

export const ORG_LOCATION_FIELDS = gql`
  ${ADDRESS_FIELDS}
  fragment OrgLocationFields on OrgLocation {
    id
    name
    phone
    address {
      ...AddressFields
    }
    org {
      id
      name
      photoUrl
      contractorOrg {
        id
      }
    }
    contractorOrg {
      id
    }
  }
`;
