import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  InvoiceForm,
  InvoiceFormType,
} from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/invoice-list-actions/add-invoice/InvoiceForm";
import { useSplittingInvoicesWizard } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardProvider";
import { useReceiptUploadModal } from "@/contractor/pages/home/receipts/pages/receipt-record-order/providers/UploadReceiptProvider";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceType,
  ReceiptFieldsFragment,
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
  UpdateReceiptMutation,
} from "@/generated/graphql";
import { ExpandMore } from "@mui/icons-material";
import { Popover } from "@mui/material";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  DuplicatedInvoiceInfo,
  isDuplicatedInvoice,
} from "../../common/DuplicatedInvoiceInfo";
import {
  NotMatchingPoNumberInfo,
  isNotMatchingPoNumber,
} from "../../common/NotMatchingPoNumberInfo";
import { ReceiptMagicWand } from "../../common/ReceiptMagicWand";
import { InvoiceDocumentOptions } from "../InvoiceDocumentOptions";

import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useEquipmentItems } from "@/contractor/pages/admin/cost-structure/pages/equipment/hooks/useEquipmentItems";
import { useServiceCodes } from "@/contractor/pages/admin/cost-structure/pages/service-codes/hooks/useServiceCodes";
import { InfoIconContainer } from "@/contractor/pages/home/projects/components/project-form-with-stepper/ProjectFormWithStepper.styles";
import { ReceiptDocumentOptions } from "../ReceiptDocumentOptions";
import { InvoiceGroups } from "./InvoiceGroups";
import { InvoiceHeaderDateEdit } from "./InvoiceHeaderDateEdit";
import { InvoiceHeaderTextEdit } from "./InvoiceHeaderTextEdit";
import { InvoicePaidToggle } from "./InvoicePaidToggle";
import { ReceiptGroups } from "./ReceiptGroups";
import { ReceiptHeaderCostCodeSelect } from "./ReceiptHeaderCostCodeSelect";
import { ReceiptHeaderCreatedApproved } from "./ReceiptHeaderCreatedApproved";
import { ReceiptNote } from "./ReceiptNote";
import { ReceiptPaymentMethod } from "./ReceiptPaymentMethod";

const Container = tw.div`px-6`;
const Header = tw.div`font-medium grid grid-flow-col items-center justify-between`;
const HeaderGroup = tw.div`flex flex-row gap-2`;
const Details = tw.div`grid grid-cols-2 my-2 mr-2`;
const Detail = tw.div`text-sm items-center flex`;

const VendorInvoiceHeader = tw.div`flex-1`;
const ButtonsContainer = tw.div`flex gap-2 items-center`;
const OptionsButton = tw(
  PrimaryButton,
)`h-6 !min-w-52 bg-blue-450 pl-4 text-black hover:bg-blue-450`;

export const PAID_VIA_INVOICE_ID = "paid-via-invoice-id";

enum HeaderDetailType {
  NUMBER,
  PO_NUMBER,
  ISSUE_DATE,
  DUE_DATE,
  PAID,
  PRE_PAID,
  DESCRIPTION,
  PAYMENT_METHOD,
  NOTES,
  COST_CODE,
}

type Props = {
  invoice:
    | DistributorInvoiceFieldsFragment
    | InvoiceFieldsFragment
    | null
    | ReceiptFieldsFragment;
  updateInvoice?: (
    input: UpdateInvoiceInput,
  ) => Promise<
    UpdateInvoiceMutation | UpdateReceiptMutation | null | undefined
  >;
  displayHeaderDetails: boolean;
  readonly?: boolean;
  invoicePoNumberReadonly?: boolean;
  type?: InvoiceType;
  rescanInvoice?: () => void;
  readjust: () => void;
  skipAutoMatching?: boolean;
};

export const InvoiceDetailsHeader: FC<Props> = ({
  invoice,
  updateInvoice,
  displayHeaderDetails = true,
  readonly = false,
  invoicePoNumberReadonly = false,
  type = InvoiceType.Invoice,
  rescanInvoice,
  readjust,
  skipAutoMatching,
}) => {
  const intl = useIntl();
  const { wizardOpened } = useSplittingInvoicesWizard();
  const { uploadReceiptModalOpened } = useReceiptUploadModal();
  const { setSystemAlert } = useSnackbar();
  const { serviceCodes, loading: loadingServiceCodes } = useServiceCodes();
  const { equipmentItems, loading: loadingEquipmentItems } =
    useEquipmentItems();

  const [editingInputs, setEditingInputs] = useState<HeaderDetailType[]>([]);
  const [number, setNumber] = useState(invoice?.number ?? "");
  const [poNumber, setPoNumber] = useState(invoice?.poNumber ?? "");
  const [issueDate, setIssueDate] = useState(invoice?.issueDate);
  const [dueDate, setDueDate] = useState(invoice?.dueDate);
  const [description, setDescription] = useState(invoice?.description ?? "");
  const [paymentMethodId, setPaymentMethodId] = useState(
    (invoice as ReceiptFieldsFragment)?.paymentMethod?.id ??
      (!invoice?.prePaid ? PAID_VIA_INVOICE_ID : undefined),
  );
  const [optionsAnchorEl, setOptionsAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setNumber(invoice?.number ?? "");
    setPoNumber(invoice?.poNumber ?? "");
    setIssueDate(invoice?.issueDate);
    setDueDate(invoice?.dueDate);
    setPaymentMethodId(
      (invoice as ReceiptFieldsFragment)?.paymentMethod?.id ??
        (!invoice?.prePaid ? PAID_VIA_INVOICE_ID : undefined),
    );
  }, [invoice]);

  const handleEditToggleClick = useCallback(
    (detailType: HeaderDetailType) => {
      if (editingInputs.includes(detailType)) {
        switch (detailType) {
          case HeaderDetailType.NUMBER:
            setNumber(number ?? "");
            break;
          case HeaderDetailType.PO_NUMBER:
            setPoNumber(poNumber ?? "");
            break;
          case HeaderDetailType.ISSUE_DATE:
            setIssueDate(issueDate);
            break;
          case HeaderDetailType.DUE_DATE:
            setDueDate(dueDate);
            break;
          case HeaderDetailType.DESCRIPTION:
            setDescription(description);
            break;
        }
        setEditingInputs((e) => e.filter((r) => r !== detailType));
      } else {
        setEditingInputs((e) => [...e, detailType]);
      }
    },
    [
      number,
      poNumber,
      issueDate,
      dueDate,
      editingInputs,
      description,
      setNumber,
      setPoNumber,
      setIssueDate,
      setDueDate,
      setEditingInputs,
      setDescription,
    ],
  );

  const handleSaveInvoiceDetail = useCallback(
    async (detailType: HeaderDetailType, value?: string | number | boolean) => {
      switch (detailType) {
        case HeaderDetailType.NUMBER:
          if (invoice) {
            updateInvoice?.({ number: value as string, id: invoice.id });
          }
          setNumber((value as string) ?? "");

          break;
        case HeaderDetailType.PO_NUMBER:
          const previousReleaseId = invoice?.release?.id;
          if (invoice) {
            const result = await updateInvoice?.({
              poNumber: value as string,
              id: invoice.id,
              skipAutoMatching,
            });
            let release: UpdateInvoiceMutation["updateInvoice"]["release"] =
              null;
            if (result && type === InvoiceType.Invoice) {
              release = (result as UpdateInvoiceMutation).updateInvoice.release;
            }
            if (!previousReleaseId && release) {
              setSystemAlert([
                `${intl.$t(
                  { id: "INVOICE_AUTOLINK_INFO" },
                  {
                    releaseNumber: release.sequenceNumber,
                  },
                )}${release.itemCount ? "" : ` ${intl.$t({ id: "INVOICE_AUTOLINK_BLANK_RELEASE" })}`}`,
                intl.$t({ id: "INVOICE_UNLINK_MESSAGE" }),
              ]);
            }
          }
          setPoNumber((value as string) ?? "");
          break;

        case HeaderDetailType.DUE_DATE:
          if (invoice) {
            updateInvoice?.({ dueDate: value as number, id: invoice.id });
          }
          setDueDate(dueDate as number);
          break;

        case HeaderDetailType.ISSUE_DATE:
          if (invoice) {
            updateInvoice?.({ issueDate: value as number, id: invoice.id });
          }
          setIssueDate(issueDate as number);
          break;

        case HeaderDetailType.PAID:
          if (invoice) {
            updateInvoice?.({ paid: value as boolean, id: invoice.id });
          }
          setIssueDate(issueDate as number);
          break;

        case HeaderDetailType.PRE_PAID:
          if (invoice) {
            updateInvoice?.({ prePaid: !value as boolean, id: invoice.id });
          }
          setIssueDate(issueDate as number);
          break;

        case HeaderDetailType.DESCRIPTION:
          if (invoice) {
            updateInvoice?.({ description: value as string, id: invoice.id });
          }
          setDescription(description as string);
          break;

        case HeaderDetailType.PAYMENT_METHOD:
          if (invoice) {
            if (value === PAID_VIA_INVOICE_ID) {
              updateInvoice?.({
                id: invoice.id,
                clearPaymentMethod: true,
                prePaid: false,
              });
            } else {
              updateInvoice?.({
                id: invoice.id,
                paymentMethodId: value as string,
                prePaid: true,
              });
            }
          }
          setPaymentMethodId(value as string);
          break;

        case HeaderDetailType.NOTES:
          if (invoice) {
            updateInvoice?.({
              id: invoice.id,
              notes: value as string,
            });
          }
          break;

        case HeaderDetailType.COST_CODE:
          if (invoice) {
            if (!value && !!(invoice as ReceiptFieldsFragment).costCode) {
              updateInvoice?.({
                id: invoice.id,
                clearCostCode: true,
              });
            } else if (!!value) {
              updateInvoice?.({
                id: invoice.id,
                costCodeId: value as string,
              });
            }
          }
          break;
      }

      setEditingInputs((e) => e.filter((r) => r !== detailType));
    },
    [
      invoice,
      dueDate,
      issueDate,
      description,
      updateInvoice,
      skipAutoMatching,
      type,
      setSystemAlert,
      intl,
    ],
  );

  const handleOptionsClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setOptionsAnchorEl((el) => (el ? null : event.currentTarget));
    },
    [],
  );

  const handleOptionsClose = useCallback(() => setOptionsAnchorEl(null), []);

  const isInvoice = useMemo(() => type === InvoiceType.Invoice, [type]);

  return (
    <Container>
      <Header className={displayHeaderDetails ? "" : "mb-10"}>
        <HeaderGroup>
          <FormattedMessage
            id={isInvoice ? "VENDOR_INVOICE" : "RECEIPT"}
            tagName={VendorInvoiceHeader}
          />
          {type === InvoiceType.Invoice ? <InvoiceGroups /> : <ReceiptGroups />}
        </HeaderGroup>
        <ButtonsContainer>
          <If isTrue={!readonly}>
            <If isTrue={isInvoice}>
              <InvoicePaidToggle
                invoice={invoice}
                onChange={(value) =>
                  handleSaveInvoiceDetail(HeaderDetailType.PAID, value)
                }
              />
            </If>
            <If isTrue={!isInvoice}>
              <ReceiptPaymentMethod
                receipt={invoice as ReceiptFieldsFragment}
                selectedPaymentMethodId={paymentMethodId}
                setSelectedPaymentMethodId={(id) =>
                  handleSaveInvoiceDetail(
                    HeaderDetailType.PAYMENT_METHOD,
                    id as string,
                  )
                }
                onPaidToggle={(value) =>
                  handleSaveInvoiceDetail(HeaderDetailType.PRE_PAID, value)
                }
              />
            </If>
          </If>
        </ButtonsContainer>
      </Header>
      <If isTrue={!isInvoice}>
        <ReceiptNote
          receipt={invoice as ReceiptFieldsFragment}
          onSaveNotes={(notes) =>
            handleSaveInvoiceDetail(HeaderDetailType.NOTES, notes)
          }
        />
        <ReceiptHeaderCreatedApproved
          receipt={invoice as ReceiptFieldsFragment}
        />
      </If>
      <If isTrue={displayHeaderDetails}>
        <Details>
          <Detail>
            <InvoiceHeaderTextEdit
              title={intl.$t({
                id: isInvoice
                  ? "INVOICE_NUMBER_SYMBOL"
                  : "RECEIPT_NUMBER_SYMBOL",
              })}
              text={number}
              isEditing={editingInputs.includes(HeaderDetailType.NUMBER)}
              toggleEditing={() =>
                handleEditToggleClick(HeaderDetailType.NUMBER)
              }
              onSave={(value) =>
                handleSaveInvoiceDetail(HeaderDetailType.NUMBER, value)
              }
              readonly={readonly}
              error={
                isInvoice
                  ? isDuplicatedInvoice(invoice as InvoiceFieldsFragment)
                  : !number
              }
              info={
                isInvoice ? (
                  <DuplicatedInvoiceInfo
                    invoice={invoice as InvoiceFieldsFragment}
                  />
                ) : (
                  <ReceiptMagicWand
                    readonly={readonly}
                    receiptNumber={number}
                    receipt={invoice as ReceiptFieldsFragment}
                    onSave={(value) =>
                      handleSaveInvoiceDetail(HeaderDetailType.NUMBER, value)
                    }
                  />
                )
              }
              testIds={{
                edit: "invoice-number-edit",
                check: "invoice-number-check",
                input: "invoice-number-input",
              }}
            />
          </Detail>
          <Detail>
            <InvoiceHeaderDateEdit
              title={intl.$t({ id: "ISSUED" })}
              date={issueDate ? new Date(issueDate) : null}
              isEditing={editingInputs.includes(HeaderDetailType.ISSUE_DATE)}
              toggleEditing={() =>
                handleEditToggleClick(HeaderDetailType.ISSUE_DATE)
              }
              onChange={(date) => {
                setIssueDate(date?.getTime());
                handleSaveInvoiceDetail(
                  HeaderDetailType.ISSUE_DATE,
                  date?.getTime(),
                );
              }}
              readonly={readonly}
            />
          </Detail>
          <If isTrue={isInvoice}>
            <Detail>
              <PoNumberingSettingsCheck alternativeCondition={!!poNumber}>
                <InvoiceHeaderTextEdit
                  title={intl.$t({ id: "PO_HASH" })}
                  text={poNumber}
                  isEditing={editingInputs.includes(HeaderDetailType.PO_NUMBER)}
                  toggleEditing={() =>
                    handleEditToggleClick(HeaderDetailType.PO_NUMBER)
                  }
                  onSave={(value) =>
                    handleSaveInvoiceDetail(HeaderDetailType.PO_NUMBER, value)
                  }
                  error={isNotMatchingPoNumber(
                    invoice as InvoiceFieldsFragment,
                  )}
                  readonly={invoicePoNumberReadonly}
                  info={
                    <NotMatchingPoNumberInfo
                      invoice={invoice as InvoiceFieldsFragment}
                    />
                  }
                />
              </PoNumberingSettingsCheck>
            </Detail>
            <Detail>
              <InvoiceHeaderDateEdit
                title={intl.$t({ id: "DUE_DATE" })}
                date={dueDate ? new Date(dueDate) : null}
                isEditing={editingInputs.includes(HeaderDetailType.DUE_DATE)}
                toggleEditing={() =>
                  handleEditToggleClick(HeaderDetailType.DUE_DATE)
                }
                onChange={(date) => {
                  setDueDate(date?.getTime());
                  handleSaveInvoiceDetail(
                    HeaderDetailType.DUE_DATE,
                    date?.getTime(),
                  );
                }}
                readonly={readonly}
              />
            </Detail>
            <Detail />
            <Detail>
              <InvoiceHeaderTextEdit
                title={intl.$t({ id: "DESCRIPTION" })}
                text={description}
                truncateValue={18}
                isEditing={editingInputs.includes(HeaderDetailType.DESCRIPTION)}
                toggleEditing={() =>
                  handleEditToggleClick(HeaderDetailType.DESCRIPTION)
                }
                onSave={(value) =>
                  handleSaveInvoiceDetail(HeaderDetailType.DESCRIPTION, value)
                }
                info={
                  <InfoIconContainer>
                    <InfoTooltip
                      message={intl.$t({ id: "INVOICE_DESCRIPTION" })}
                    />
                  </InfoIconContainer>
                }
              />
            </Detail>
          </If>
          <Detail>
            <If
              isTrue={
                isInvoice || !!equipmentItems.length || !!serviceCodes.length
              }
            >
              <DrawerPanel
                anchor={(togglePanel) => (
                  <>
                    <OptionsButton
                      className={
                        optionsAnchorEl
                          ? `rounded-b-none rounded-t-2xl ${!wizardOpened && !uploadReceiptModalOpened ? "z-[1500]" : ""}`
                          : ""
                      }
                      onClick={handleOptionsClick}
                      disableRipple
                    >
                      <FormattedMessage
                        id={
                          type === InvoiceType.Invoice
                            ? "INVOICE_OPTIONS"
                            : "RECEIPT_OPTIONS"
                        }
                      />
                      <ExpandMore
                        className={`transition-all ${optionsAnchorEl ? "rotate-180" : ""}`}
                      />
                    </OptionsButton>
                    <Popover
                      open={Boolean(optionsAnchorEl)}
                      anchorEl={optionsAnchorEl}
                      onClose={handleOptionsClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      sx={{
                        " .MuiPaper-root": {
                          borderRadius: "1rem",
                          borderTopLeftRadius: 0,
                          boxShadow: "none",
                          marginLeft: "0.5px",
                        },
                      }}
                      className="bg-black/50"
                    >
                      {type === InvoiceType.Invoice ? (
                        <InvoiceDocumentOptions
                          invoice={invoice as InvoiceFieldsFragment}
                          readonly={readonly}
                          rescanInvoice={rescanInvoice}
                          togglePanel={togglePanel}
                          type={type}
                          closeOptions={handleOptionsClose}
                          readjust={readjust}
                        />
                      ) : (
                        <ReceiptDocumentOptions
                          receipt={invoice as ReceiptFieldsFragment}
                          readonly={readonly}
                          equipmentItems={equipmentItems}
                          loadingEquipmentItems={loadingEquipmentItems}
                          serviceCodes={serviceCodes}
                          loadingServiceCodes={loadingServiceCodes}
                        />
                      )}
                    </Popover>
                  </>
                )}
                content={(togglePanel) => (
                  <InvoiceForm
                    onClose={() => togglePanel(false)}
                    type={InvoiceFormType.CORRECTION}
                  />
                )}
              />
            </If>
          </Detail>
          <Detail>
            <If isTrue={!isInvoice}>
              <ReceiptHeaderCostCodeSelect
                receipt={invoice as ReceiptFieldsFragment}
                onChange={(id: string | null) =>
                  handleSaveInvoiceDetail(
                    HeaderDetailType.COST_CODE,
                    id ?? undefined,
                  )
                }
              />
            </If>
          </Detail>
        </Details>
      </If>
    </Container>
  );
};
