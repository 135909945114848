import {
  ImportEquipmentItemsMutation,
  SourceSystem,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type ImportEquipmentItemsState = {
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) => void;
  closeModal: () => void;
  selectedExternalEquipmentItems: string[];
  setSelectedExternalEquipmentItems: (id: string[]) => void;
  importAllExternalItems: boolean;
  setImportAllExternalItems: (value: boolean) => void;
  totalExternalEquipmentItemsCount: number;
  setTotalExternalEquipmentItemsCount: (count: number) => void;
  importedEquipmentItems:
    | ImportEquipmentItemsMutation["importEquipmentItems"]
    | null;
  setImportedEquipmentItems: (
    importedEquipmentItems:
      | ImportEquipmentItemsMutation["importEquipmentItems"]
      | null,
  ) => void;
};

const initialState = {
  sourceSystem: null,
  modalOpened: false,
  selectedExternalEquipmentItems: [],
  importAllExternalItems: false,
  totalExternalEquipmentItemsCount: 0,
  importedEquipmentItems: null,
};

export const useImportEquipmentItemsStore = create<ImportEquipmentItemsState>()(
  devtools((set) => ({
    ...initialState,
    openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) =>
      set({ sourceSystem, modalOpened: true }),
    closeModal: () => set(initialState),
    setSelectedExternalEquipmentItems: (
      selectedExternalEquipmentItems: string[],
    ) => set({ selectedExternalEquipmentItems }),
    setImportAllExternalItems: (importAllExternalItems: boolean) =>
      set({
        importAllExternalItems,
      }),
    setTotalExternalEquipmentItemsCount: (
      totalExternalEquipmentItemsCount: number,
    ) => set({ totalExternalEquipmentItemsCount }),
    setImportedEquipmentItems: (
      importedEquipmentItems:
        | ImportEquipmentItemsMutation["importEquipmentItems"]
        | null,
    ) => set({ importedEquipmentItems }),
  })),
);
