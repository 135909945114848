import { If } from "@/common/components/if/If";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useEnabledPoInSourceSystem } from "@/common/hooks/useEnabledPoInSourceSystem";
import {
  DateView,
  formattedDate,
  SHORT_DATE_OPTION,
} from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import {
  ArrowForward,
  CheckCircleRounded,
  Info,
  Link,
} from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { SourceSystemWrapper } from "../../../../../../../common/components/source-system-wrapper/SourceSystemWrapper";

const IconContainer = tw.div`h-fit flex flex-row gap-1 flex-1 justify-end`;
const Icon = tw(Info)`text-orange-500`;
const InfoIcon = tw(Info)`text-red-500`;
const SyncingIcon = tw(
  ArrowForward,
)`border border-dashed border-gray-500 rounded-full`;
const LinkStyled = tw(Link)`text-green-600`;
const LinkWarning = tw(Link)`text-orange-500`;
const CheckCircleOutlineRoundedStyled = tw(CheckCircleRounded)`text-green-800`;
const Rounded = tw.div<{
  $disabled: boolean;
  $notRequired?: boolean;
  $poExists?: boolean | null | undefined;
}>`
${({ $disabled, $poExists }) =>
  $disabled
    ? "bg-white text-gray-500"
    : $poExists
      ? "bg-blue-450 text-black"
      : "bg-green-300 text-black"}
${({ $notRequired }) => $notRequired && "bg-gray-200 text-black"}
rounded-full p-3 text-xs border border-gray-300 w-5 h-5 flex items-center justify-center`;
const ListItem = tw.li``;
const List = tw.ul`list-disc px-4`;

export const ReleaseWarnings: FC<{ release: ReleasePartialFieldsFragment }> = ({
  release,
}) => {
  const { settings, connectedSourceSystem } = useOrgSettings();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const intl = useIntl();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();

  const wasReleaseChangedAfterLinking = useMemo(
    () =>
      !!release.poLink?.syncedVersion &&
      release.poLink?.syncedVersion !== release.syncVersion,
    [release.poLink, release.syncVersion],
  );

  const wasReleaseChangedAfterExport = useMemo(
    () =>
      !!release.exportedVersion &&
      release.exportedVersion !== release.syncVersion,
    [release.exportedVersion, release.syncVersion],
  );

  const wasReleaseChanged = useMemo(
    () => wasReleaseChangedAfterLinking || wasReleaseChangedAfterExport,
    [wasReleaseChangedAfterExport, wasReleaseChangedAfterLinking],
  );

  const isRequiredReceipt = useMemo(() => {
    return (
      settings?.integrations.sourceSystems.find((system) => system.connected)
        ?.postInventoryReceipts ||
      settings?.integrations.accounting.find((accounting) => accounting.enabled)
        ?.postInventoryReceipts
    );
  }, [settings?.integrations.accounting, settings?.integrations.sourceSystems]);

  const poExportedTooltip = useMemo(() => {
    if (release?.exportedAt || release?.poLink?.syncedAt) {
      return release?.poLink?.createdBy
        ? "PO_EXPORTED_AT_BY"
        : "PO_EXPORTED_AT";
    }
    if (release?.poExists) {
      return "PO_EXISTS_IN_SOURCE_SYSTEM";
    }
    return "PO_NOT_EXPORTED";
  }, [
    release?.exportedAt,
    release?.poExists,
    release?.poLink?.createdBy,
    release?.poLink?.syncedAt,
  ]);

  return (
    <IconContainer>
      <If isTrue={!release.poLink?.syncedAt}>
        <SourceSystemWrapper anyIntegrationType>
          <If isTrue={release.exportedAt && !wasReleaseChanged}>
            <Tooltip
              id={`export-release-${release.id}`}
              element={<CheckCircleOutlineRoundedStyled />}
            >
              <FormattedMessage
                id="EXPORTED_AT"
                values={{
                  date: (
                    <DateView
                      date={release.exportedAt}
                      options={SHORT_DATE_OPTION}
                    />
                  ),
                }}
              />
            </Tooltip>
          </If>
        </SourceSystemWrapper>
      </If>
      <SourceSystemWrapper anySourceSystem anyIntegrationType>
        {release.poLink?.syncing ? (
          <Tooltip id={`syncing-${release.id}`} element={<SyncingIcon />}>
            <FormattedMessage id="SYNCING_RELEASE" />
          </Tooltip>
        ) : (
          <If
            isTrue={release.poLink?.syncedAt && !wasReleaseChangedAfterLinking}
          >
            <Tooltip id={`synced-${release.id}`} element={<LinkStyled />}>
              <FormattedMessage id="PO_SYNCHRONIZED" />
            </Tooltip>
          </If>
        )}
        <If isTrue={release.poLink?.autoSyncError}>
          <Tooltip id={`sync-error-${release.id}`} element={<InfoIcon />}>
            <FormattedMessage id="PO_SYNCHRONIZED_ERROR" />
          </Tooltip>
        </If>
        <If isTrue={wasReleaseChangedAfterLinking}>
          <Tooltip
            id={`export-release-${release.id}`}
            element={<LinkWarning />}
          >
            <FormattedMessage id="EXPORT_RELEASE_CHANGED" />
          </Tooltip>
        </If>
        <If isTrue={wasReleaseChangedAfterExport && !release.poLink}>
          <Tooltip id={`export-release-${release.id}`} element={<Icon />}>
            <FormattedMessage id="EXPORT_RELEASE_CHANGED" />
          </Tooltip>
        </If>
        <If
          isTrue={
            isPoEnabledInSourceSystem &&
            (includePoNumbering ||
              release?.exportedAt ||
              release?.poLink?.syncedAt)
          }
        >
          <Tooltip
            id={`po-export-${release.id}`}
            element={
              <Rounded
                $disabled={
                  !release?.exportedAt &&
                  !release?.poLink?.syncedAt &&
                  !release?.poExists
                }
                $poExists={
                  !release?.exportedAt &&
                  !release?.poLink?.syncedAt &&
                  release?.poExists
                }
              >
                P
              </Rounded>
            }
          >
            {connectedSourceSystem ? (
              <FormattedMessage
                id={poExportedTooltip}
                values={{
                  date: (
                    <DateView
                      date={release?.exportedAt || release?.poLink?.syncedAt}
                      options={SHORT_DATE_OPTION}
                    />
                  ),
                  username: getUserName(release.poLink?.createdBy),
                  sourceSystem: intl.$t({
                    id: `INTEGRATION_${connectedSourceSystem}`,
                  }),
                }}
              />
            ) : null}
          </Tooltip>
        </If>
        <If isTrue={isRequiredReceipt && isPoEnabledInSourceSystem}>
          <Tooltip
            id={`receipt-posted-${release.id}`}
            element={
              <Rounded
                $notRequired={!release?.requiresInventoryReceipt}
                $disabled={!release?.receiptPostedAt}
              >
                R
              </Rounded>
            }
          >
            {release?.requiresInventoryReceipt ? (
              <FormattedMessage
                id={
                  release?.receiptPostedAt
                    ? "RECEIPT_POSTED_AT"
                    : "RECEIPT_NOT_POSTED"
                }
                values={{
                  date: (
                    <DateView
                      date={release?.receiptPostedAt}
                      options={SHORT_DATE_OPTION}
                    />
                  ),
                }}
              />
            ) : (
              <FormattedMessage id="RECEIPT_NOT_REQUIRED" />
            )}
          </Tooltip>
        </If>
        <Tooltip
          id={`invoice-${release.id}`}
          element={<Rounded $disabled={!release.isInvoiced}>I</Rounded>}
        >
          {release.isInvoiced ? (
            <List>
              {release.invoices.map((invoice) => {
                return (
                  <ListItem key={invoice.id}>
                    {`${intl.$t({ id: "INVOICE" })}# ${invoice.number || "--"}
                    ${
                      invoice.issueDate
                        ? ` - ${formattedDate({ date: invoice.issueDate })}`
                        : ""
                    }
                    `}
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <FormattedMessage id="NO_INVOICE" />
          )}
        </Tooltip>
      </SourceSystemWrapper>
    </IconContainer>
  );
};
