import { SKIP_SPACE_COLUMNS } from "../constants/tableConstants";
import { COLUMN_TYPE } from "../enums/columnType";
import { sanitizeValue } from "./sanitizeValue";

export const getCellValue = (
  row: Record<string, string | number>,
  type: COLUMN_TYPE,
): string => {
  const value = (row && row[type]) ?? "";
  if (typeof value === "number") {
    return value.toString();
  }

  if (typeof value === "string") {
    return sanitizeValue(value, SKIP_SPACE_COLUMNS.includes(type));
  }
  return value;
};
