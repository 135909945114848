import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import {
  AssetFieldsFragment,
  NoteDocumentFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  NoteDocumentProvider,
  useNoteDocument,
} from "../../providers/NoteDocumentProvider";
import { NoteDocumentImport } from "../NoteDocumentImport";

const AddNoteDocument = tw.div`
  grid grid-flow-col justify-start gap-2 items-center text-sm
`;
const Container = tw.div`
  grid p-4 gap-4
`;

type Props = {
  replaceCallback?: (document: NoteDocumentFieldsFragment) => void;
};

const ReplaceNoteFileProviders: FC<Props> = ({ replaceCallback }) => {
  const intl = useIntl();
  const { createNoteDocumentWithAsset, setAsset, noteDocument } =
    useNoteDocument();

  const handleNoteDocumentChange = (asset: AssetFieldsFragment) => {
    setAsset(asset);
  };

  const onSave = async () => {
    if (!noteDocument?.orgLocation.id) {
      return;
    }
    const document = await createNoteDocumentWithAsset(
      noteDocument.orgLocation.id,
    );

    if (document) {
      replaceCallback?.(document);
    }
  };

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <AddNoteDocument onClick={() => togglePanel(true)}>
          <FormattedMessage id="REPLACE_THIS_NOTE" />
          <PrimaryButton $small>
            <FormattedMessage id="REPLACE" />
          </PrimaryButton>
        </AddNoteDocument>
      )}
      content={(togglePanel) => (
        <OverlayPanel
          onSave={onSave}
          saveLabel={intl.$t({ id: "REPLACE" })}
          title={intl.$t({ id: "REPLACE" })}
          onCancel={() => togglePanel(false)}
          className="px-0"
        >
          <Container>
            <NoteDocumentImport onChange={handleNoteDocumentChange} />
          </Container>
        </OverlayPanel>
      )}
    />
  );
};

export const ReplaceNoteFile: FC<Props> = ({ replaceCallback }) => {
  return (
    <NoteDocumentProvider>
      <ReplaceNoteFileProviders replaceCallback={replaceCallback} />
    </NoteDocumentProvider>
  );
};
