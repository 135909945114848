import { Chip } from "@mui/material";
import tw from "tailwind-styled-components";

const Container = tw.div`absolute inset-0 flex items-center pl-2 overflow-hidden right-10`;

interface CustomSelectedItemViewProps {
  text: string;
  clear?: () => void;
  onClick: () => void;
}

export const SelectCustomView = ({
  text,
  clear,
  onClick,
}: CustomSelectedItemViewProps) => {
  return (
    <Container onClick={onClick} role="button" tabIndex={0}>
      <Chip
        label={text}
        className="bg-blue-400 text-white"
        onDelete={clear}
        size="small"
        sx={{
          "& .MuiChip-deleteIcon": {
            color: "white",
          },
        }}
      />
    </Container>
  );
};
