import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Identity } from "@/types/Identity";
import { useShallow } from "zustand/react/shallow";
import { If } from "../../../if/If";
import { useCategoryCards } from "../../hooks/useCategoryCards";
import { useGridTable } from "../../providers/GridTableProvider";
import { GridTableCategoryProps } from "../../types/GridTableCategoryProps";
import { RenderType } from "../../types/RenderType";
import { GridItem } from "../grid-item/GridItem";

type Props<T extends Identity, TAdditional> = Pick<
  GridTableCategoryProps<T, TAdditional>,
  "index" | "category" | "configuration"
>;

export const GridTableCategoryHeader = <T extends Identity, TAdditional>({
  index,
  category,
  configuration,
}: Props<T, TAdditional>) => {
  const { categoryType, hideGroup, readonly } = useGridTable(
    useShallow((state) => ({
      categoryType: state.table.categoryType,
      hideGroup: state.table.hideGroup,
      readonly: state.table.readonly,
    })),
  );
  const { CategoryCard, CategoryCardContent } = useCategoryCards(categoryType);

  return (
    <If isTrue={!hideGroup}>
      <CategoryCard
        className={`${configuration.classNames?.category} print:top-0`}
        data-attr="zone"
        onClick={() =>
          configuration.toggle?.category &&
          configuration.toggle?.category((category as CategoryState<T>).id)
        }
      >
        <CategoryCardContent>
          <GridItem
            className="top-0 text-xs md:text-base"
            columns={configuration.columns}
            readonly={readonly}
            category={category as CategoryState<T>}
            index={index}
            renderType={RenderType.Group}
          />
        </CategoryCardContent>
      </CategoryCard>
    </If>
  );
};
