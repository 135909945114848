import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { defaultTableItemSelectionClassNames } from "@/common/components/searchable-list/utils";
import { ObjectType, useSortingStore } from "@/common/stores/useSortingStore";
import { routes } from "@/config/routes";
import {
  InvoiceSummaryFieldsFragment,
  QueryInvoicesSortBy,
} from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  InvoiceSequenceData,
  useInvoiceSequence,
} from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";
import { useScannedInvoicesConfiguration } from "./ScannedInvoices.configuration";

type Props = {
  invoices: InvoiceSummaryFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isFiltered: boolean;
};

export const ScannedInvoicesList: FC<Props> = ({
  invoices,
  loading,
  totalCount,
  isFiltered,
}) => {
  const { exportEnabled, filter } = useInvoices();
  const { sequenceIds, selectedInvoices, setSelectedInvoices } =
    useInvoiceSequence();
  const configuration = useScannedInvoicesConfiguration({
    includeTrash: !filter?.archived,
  });
  const navigate = useNavigate();
  const setSortEntity = useSortingStore((state) => state.setSortEntity);

  useEffect(() => {
    setSortEntity({
      objectType: ObjectType.Invoice,
      field: QueryInvoicesSortBy.CreatedAt,
      descending: true,
    });
  }, [setSortEntity]);

  const openInvoice = useCallback(
    (invoice: InvoiceSummaryFieldsFragment) => {
      if (exportEnabled) {
        if (invoice.permissions.export) {
          const isIncluded = sequenceIds.includes(invoice.id);
          setSelectedInvoices(
            !isIncluded
              ? ([...selectedInvoices, invoice] as InvoiceSequenceData[])
              : selectedInvoices.filter((i) => i.id !== invoice.id),
          );
        }
      } else {
        navigate(
          generatePath(routes.invoiceVerification, { invoiceId: invoice.id }),
        );
      }
    },
    [
      exportEnabled,
      navigate,
      selectedInvoices,
      sequenceIds,
      setSelectedInvoices,
    ],
  );

  const itemFn = useCallback(
    (item: InvoiceSummaryFieldsFragment) => {
      if (item.archivedAt) {
        return {
          className: "bg-gray-300 opacity-50",
        };
      }
      if (exportEnabled && sequenceIds.includes(item.id)) {
        return { className: defaultTableItemSelectionClassNames };
      }
      return { className: "" };
    },
    [exportEnabled, sequenceIds],
  );

  return (
    <ListRenderer totalCount={totalCount} count={invoices.length} paginated>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            itemContent: "grid-cols-[50px_repeat(5,_auto)]",
            header: "top-[150px] xl:top-[143px] bg-transparent",
            itemFn,
            container: "pt-6 xl:pt-2",
            root: "pt-2",
          },
          hasCardItems: true,
          toggle: {
            item: openInvoice,
          },
        }}
        items={invoices}
        loading={loading}
        sortObjectType={ObjectType.Invoice}
        emptyList={
          <NoResults
            isFiltered={isFiltered}
            filteredTranslationKey="NO_RESULTS_INVOICES_FILTERED"
            translationKey="NO_RESULTS_INVOICES"
          />
        }
      />
    </ListRenderer>
  );
};
