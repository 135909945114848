import tw from "tailwind-styled-components";
import { useExpandableFooterRef } from "../../../../../../../../common/components/footer/hooks/useExpandableFooterRef";
import { InvoicePanel } from "./InvoicePanel";
import { ReleasePanel } from "./ReleasePanel";

const Container = tw.div`w-full bg-white grid grid-cols-[calc(60%-6px)_calc(40%+6px)]`;

export const InvoiceFooterExpanded = () => {
  const ref = useExpandableFooterRef();

  return (
    <Container ref={ref}>
      <ReleasePanel />
      <InvoicePanel />
    </Container>
  );
};
