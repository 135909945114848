import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { GridTable } from "@/common/components/grid-table/GridTable";
import { AdminHeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { tableConfiguration } from "./components/Locations.configuration";
import { ToggleNewLocationForm } from "./components/ToggleNewLocationForm";
import { LocationsProvider, useLocations } from "./providers/LocationsProvider";

const LocationsWithProvider = () => {
  const { locations, loading } = useLocations();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "LOCATIONS" })}</title>
      </Helmet>
      <AdminHeaderContainer>
        <ToggleNewLocationForm label={intl.$t({ id: "LOCATION_ADD_LOCATION" })}>
          <PrimaryButton wide>
            <FormattedMessage id="LOCATION_ADD_LOCATION" />
          </PrimaryButton>
        </ToggleNewLocationForm>
      </AdminHeaderContainer>
      <GridTable
        items={locations}
        configuration={{
          classNames: {
            header: "top-21",
          },
          columns: tableConfiguration,
        }}
        loading={loading}
        emptyList={<NoResults translationKey="LOCATION_EMPTY_LIST" />}
      />
    </>
  );
};
export const Locations = () => (
  <LocationsProvider>
    <LocationsWithProvider />
  </LocationsProvider>
);
