import { DateTimePicker } from "@/common/components/picker/components/DateTimePicker";
import { useReleaseDatePickerConfig } from "@/contractor/pages/home/releases/pages/deliveries/hooks/useReleaseDatePickerConfig";
import { FC, useMemo } from "react";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../../providers/ReleaseProvider";

type Props = {
  value: Date | null;
  onChange: (date: Date | null | undefined) => void;
  label?: string;
  disabled?: boolean;
  includeCustomAction?: boolean;
};

const inputProps = {
  className: "text-sm",
};

export const ReleaseDateTimePicker: FC<Props> = ({
  value,
  onChange,
  label: customLabel,
  disabled: forceDisable,
  includeCustomAction = true,
}) => {
  const { release } = useRelease();
  const {
    actionBar,
    labelProps: { label, styling },
    value: computedValue,
    disabled,
  } = useReleaseDatePickerConfig({
    date: value,
    labels: { customLabel },
    release,
  });

  const isFieldDisabled = useMemo(
    () => forceDisable || disabled,
    [forceDisable, disabled],
  );

  const { inputErrors } = useReleaseActions();

  return (
    <DateTimePicker
      label={label}
      date={computedValue}
      onChange={onChange}
      onAccept={onChange}
      required={!isFieldDisabled}
      error={inputErrors.includes(ReleaseErrorType.REQUESTED_FULFILLMENT_DATE)}
      inputProps={inputProps}
      InputLabelProps={styling}
      actionBar={actionBar}
      disabled={isFieldDisabled}
      slotProps={{
        actionBar: {
          onClick: () => {
            onChange(undefined);
          },
          includeCustomAction,
        } as never,
      }}
    />
  );
};
