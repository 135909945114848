import { TextField } from "@/common/components/textfield/TextField";
import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";
import { Multiselect } from "../../select/components/multiple/Multiselect";
import { useMessages } from "../providers/MessagesProvider";

type SendMessageButtonProps = {
  message: string;
  setMessage: (message: string) => void;
};

export type MessageSenderComponents = {
  MessageSenderButton?: FC<SendMessageButtonProps>;
  MessageSenderTop?: React.ReactNode;
  MessageSenderBottom?: React.ReactElement;
};

type Props = {
  note?: React.ReactNode;
  sendMessagePlaceholder?: string;
  messageTabName: React.ReactNode;
  components?: MessageSenderComponents;
  projectId?: string;
};

const Container = tw.div`
  grid items-end bg-gray-100
`;
const TextFieldStyled = tw(TextField)`
  group w-full
`;

const InputContainer = tw.div`
  flex flex-col gap-2 relative w-full p-5
`;

export const MessagesSender: FC<Props> = ({
  sendMessagePlaceholder,
  components,
  projectId,
}) => {
  const { message, setMessage, setUserIds, userIds, isPrivate } = useMessages();
  const intl = useIntl();
  const { users, loading } = useUsers({ projectId });
  const { viewer } = useUser();
  const filteredUsers = useMemo(
    () => users?.filter((u) => u.id !== viewer?.id) || [],
    [users, viewer],
  );

  return (
    <Container>
      {components?.MessageSenderTop}
      <InputContainer>
        <If isTrue={isPrivate}>
          <Multiselect
            testId="form-team-selector"
            placeholder={intl.$t({ id: "NOTIFY_TEAM_MEMBER" })}
            options={filteredUsers}
            values={userIds}
            onMultipleChange={(values) => setUserIds(values || [])}
            loading={loading}
            getLabel={getUserName}
            getValue={(option) => option.id}
            noOptionsText={intl.$t({ id: "NO_USERS_FOUND" })}
            disableCloseOnSelect
            includeCheckbox
            className="min-w-60"
            chipSize="small"
            InputLabelProps={{
              className: "bg-white",
            }}
          />
        </If>
        <TextFieldStyled
          multiline
          label={intl.$t({
            id: sendMessagePlaceholder || "MESSAGE_PLACEHOLDER",
          })}
          minRows={2}
          maxRows={3}
          value={message}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            setMessage(ev.target.value)
          }
          className="bg-white"
          sx={{
            " fieldset": {
              border: 1,
            },
          }}
        />
        {components?.MessageSenderBottom &&
          React.cloneElement(components.MessageSenderBottom, {
            isPrivate,
          })}
      </InputContainer>
    </Container>
  );
};
