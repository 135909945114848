import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import {
  Base,
  MdFixedContainer,
  SmContainer,
  SmWideContainer,
} from "@/common/layout/ResponsiveClasses";

import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useItemsHavePhaseCodes } from "@/distributor/pages/buyout/common/hooks/useItemsHavePhaseCodes";
import { DistributorGroupByCostCodeToggle } from "@/distributor/pages/release/components/distributor-group-by-costcode/DistributorGroupByCostCodeToggle";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceReleaseItemMaterial } from "./InvoiceReleaseItemMaterial";

const RequestedItemsContainer = tw(
  Base,
)`col-span-full flex-1 2xl:basis-4/12 2xl:w-4/12 lg:basis-5/12 lg:w-5/12 xl:basis-4/12 xl:w-4/12`;
const FixedContainer = tw(MdFixedContainer)`line-clamp-2 xs:basis-1/2 xs:w-52`;
const ReadonlyField = tw.div`text-xs`;
const GrayReadonlyField = tw(ReadonlyField)`text-gray-500`;

export const useDistributorInvoiceReleaseConfiguration = (): Array<
  GridCol<DistributorReleaseItemFieldsFragment>
> => {
  const { calcExtPrice } = usePriceCalculation();
  const itemsHavePhaseCodes = useItemsHavePhaseCodes();
  return useMemo(
    () => [
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <InvoiceReleaseItemMaterial item={item} count={count} />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            itemsHavePhaseCodes={itemsHavePhaseCodes(category.items)}
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEMS_IN_DELIVERY" />
            <DistributorGroupByCostCodeToggle />
          </>
        ),
      },
      {
        wrapper: SmWideContainer,
        item: ({ item }) => (
          <ReadonlyField>
            <ValueUnit value={item.quantityDecimal} />
          </ReadonlyField>
        ),
        position: "center",
        header: <FormattedMessage id="RELEASE_QUANTITY" />,
      },
      {
        header: <FormattedMessage id="UOM" />,
        wrapper: FixedContainer,
        position: "center",
        item: ({ item }) => (
          <ReadonlyField>
            {item.uom?.mnemonic ?? item.uom?.pluralDescription}
          </ReadonlyField>
        ),
      },
      {
        header: <FormattedMessage id="PRICE" />,
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => (
          <ReadonlyField>
            <Price price={item.unitPrice} />
          </ReadonlyField>
        ),
      },
      {
        header: <FormattedMessage id="EXT_PRICE" />,
        wrapper: SmContainer,
        position: "center",
        item: ({ item }) => (
          <ReadonlyField>
            <Price price={calcExtPrice(item.quantityDecimal, item.unitPrice)} />
          </ReadonlyField>
        ),
        subgroup: (category) => (
          <GrayReadonlyField>
            <Price
              price={category.items.reduce(
                (acc, item) =>
                  acc.add(calcExtPrice(item.quantityDecimal, item.unitPrice)),
                new Decimal(0),
              )}
            />
          </GrayReadonlyField>
        ),
      },
    ],
    [calcExtPrice, itemsHavePhaseCodes],
  );
};
