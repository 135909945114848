import Fuse from "fuse.js";
import { useEffect, useState } from "react";

export const useLocalSearch = <T extends object>(
  items: T[],
  searchKeys?: string[],
) => {
  const [searchText, setSearchText] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    const fuse = new Fuse(items, {
      keys: (searchKeys ?? items?.[0]) ? Object.keys(items[0]) : [],
      threshold: 0.3,
    });
    const filteredItems = searchText
      ? fuse.search(searchText).map((result) => result.item)
      : items;
    setFilteredItems(filteredItems);
  }, [items, searchKeys, searchText]);

  return {
    searchText,
    setSearchText,
    filteredItems,
  };
};
