import { Label } from "@/common/components/release-terms-and-notes/ReleaseTermsAndNotes.styles";
import { NoFunction } from "@/types/NoFunction";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Select } from "../select/components/single/Select";
import { TextField } from "../textfield/TextField";

type Props = {
  paymentTerm?: string | null;
  updateItem?: (paymentTerm: string) => void;
  readonly?: boolean;
  hideLabel?: boolean;
  staticText?: boolean;
  labelId?: string;
  classes?: {
    container?: string;
    select?: string;
  };
};

const Container = tw.div`flex flex-row items-center`;
const selectInputPropsClassName = "-mr-1 pl-1.5";

export const CustomPaymentTerms: FC<Props> = ({
  paymentTerm,
  updateItem = NoFunction,
  readonly = false,
  hideLabel = false,
  staticText = false,
  labelId,
  classes,
}) => {
  const intl = useIntl();

  const [input, setInput] = useState(
    paymentTerm ? `${intl.$t({ id: "NET" })} ${paymentTerm}` : "",
  );

  useEffect(() => {
    setInput(paymentTerm ? `${intl.$t({ id: "NET" })} ${paymentTerm}` : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTerm]);

  const paymentTermOptions = useMemo(() => {
    const options = [
      { label: intl.$t({ id: "PAY_UPON_DELIVERY" }), id: "0" },
      { label: intl.$t({ id: "DAYS_COUNT" }, { count: 10 }), id: "10" },
      { label: intl.$t({ id: "DAYS_COUNT" }, { count: 15 }), id: "15" },
      { label: intl.$t({ id: "DAYS_COUNT" }, { count: 30 }), id: "30" },
      { label: intl.$t({ id: "DAYS_COUNT" }, { count: 40 }), id: "40" },
      { label: intl.$t({ id: "DAYS_COUNT" }, { count: 45 }), id: "45" },
    ];
    if (
      paymentTerm &&
      !options.some((option) => option.id === paymentTerm) &&
      !isNaN(parseInt(paymentTerm))
    ) {
      options.push({
        label: intl.$t({ id: "DAYS_COUNT" }, { count: parseInt(paymentTerm) }),
        id: paymentTerm,
      });
    }
    return options;
  }, [paymentTerm, intl]);

  const handlePaymentTermChange = useCallback(
    (value: string | null) => {
      const parsedValue = value ? parseInt(value) : 0;
      updateItem(parsedValue.toString());
    },
    [updateItem],
  );

  const onBlur = useCallback(() => {
    if (input !== `${intl.$t({ id: "NET" })} ${paymentTerm}`) {
      handlePaymentTermChange(input);
    }
  }, [paymentTerm, input, intl, handlePaymentTermChange]);

  return (
    <Container
      className={`${hideLabel ? "justify-end" : "justify-between"}${classes?.container ? ` ${classes.container}` : ""}`}
    >
      <If isTrue={!hideLabel}>
        <FormattedMessage id={labelId ?? "PAYMENT_TERMS"} tagName={Label} />
      </If>
      {!readonly && !staticText ? (
        <Select
          label={
            paymentTerm
              ? `${intl.$t({ id: "NET" })} ${paymentTerm}`
              : intl.$t({ id: "SELECT" })
          }
          options={paymentTermOptions}
          getValue={(o) => o.id}
          getLabel={(o) => o.label}
          onChange={handlePaymentTermChange}
          value={paymentTerm ? paymentTerm.toString() : ""}
          handleInputChange={setInput}
          onBlur={onBlur}
          disableClearable={false}
          creatable
          creatableFn={(_, text) => {
            const count = parseInt(text ?? "");
            if (text && !isNaN(count)) {
              return {
                id: text,
                label: intl.$t(
                  {
                    id: "SELECT_CUSTOM",
                  },
                  {
                    option: intl.$t({ id: "DAYS_COUNT" }, { count }),
                  },
                ),
              };
            }
            return undefined;
          }}
          inputProps={{
            className: classes?.select
              ? `${selectInputPropsClassName} ${classes.select}`
              : selectInputPropsClassName,
          }}
        />
      ) : (
        <TextField
          label={
            staticText
              ? ""
              : intl.$t({
                  id: "NET",
                })
          }
          value={
            paymentTerm
              ? staticText
                ? `${intl.formatMessage({ id: "NET" })} ${paymentTerm}`
                : intl.$t({ id: "DAYS_COUNT" }, { count: paymentTerm })
              : intl.$t({ id: "UNSPECIFIED" })
          }
          xs
          inputProps={{
            className: `text-right ${staticText ? "" : "bg-white"}${classes?.select ? ` ${classes.select}` : ""}`,
          }}
          disabled={!staticText}
          staticText={staticText}
        />
      )}
    </Container>
  );
};
