import { useAgaveExternalProjects } from "@/common/components/external-projects/hooks/useAgaveExternalProjects";
import { useAgaveExternalProjectsWithPagination } from "@/common/components/external-projects/hooks/useAgaveExternalProjectsWithPagination";
import {
  SourceSystem,
  useRefreshExternalProjectsMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useRefreshExternalProjects = (
  sourceSystem: SourceSystem | null,
) => {
  const { filter } = useAgaveExternalProjects(sourceSystem || null);
  const { refetch: refetchExternalProjects } =
    useAgaveExternalProjectsWithPagination(sourceSystem, filter);
  const [
    refreshExternalProjectsMutation,
    { loading: refreshingExternalProjects },
  ] = useRefreshExternalProjectsMutation();

  const handleRefreshExternalProjects = useCallback(async () => {
    if (sourceSystem) {
      const result = await refreshExternalProjectsMutation({
        variables: { sourceSystem },
      });
      if (
        result.data?.refreshExternalProjects &&
        result.data?.refreshExternalProjects > 0
      ) {
        await refetchExternalProjects();
      }
    }
  }, [refetchExternalProjects, refreshExternalProjectsMutation, sourceSystem]);

  return {
    handleRefreshExternalProjects,
    refreshingExternalProjects,
  };
};
