import { GridTable } from "@/common/components/grid-table/GridTable";
import { If } from "@/common/components/if/If";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { Switch } from "@/common/components/switch/Switch";
import { SwitchGroup } from "@/common/components/switch/SwitchGroup";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useOrderTypes } from "../hooks/useOrderTypes";
import { useOrderTypesConfiguration } from "./OrderTypes.config";
import { ToggleEditOrderType } from "./edit-order-type/ToggleEditOrderType";

export const OrderTypesList: FC = () => {
  const { configuration } = useOrderTypesConfiguration();
  const {
    orderTypes,
    loading,
    includeArchived,
    setIncludeArchived,
    hasArchived,
  } = useOrderTypes();
  const intl = useIntl();

  const sortedOrderTypes = useMemo(() => {
    return orderTypes
      .toSorted((a, b) => b.name.localeCompare(a.name))
      .toSorted((a) => (a.archivedAt ? 1 : -1));
  }, [orderTypes]);

  return (
    <ListRenderer>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            root: "pb-4",
            header: "top-[112px]",
            itemFn: (item) => {
              return {
                className: `
                    ${item.archivedAt ? "bg-opacity-30 shadow-xl" : ""} 
                  `,
              };
            },
          },
        }}
        items={sortedOrderTypes}
        loading={loading}
      />
      <If isTrue={hasArchived}>
        <SwitchGroup
          label={<FormattedMessage id="SHOW_DELETED_TYPES" />}
          className="justify-end"
        >
          <Switch
            value={includeArchived}
            onChange={setIncludeArchived}
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
            testId="form-include-additional-charges-switch"
          />
        </SwitchGroup>
      </If>
      <ToggleEditOrderType />
    </ListRenderer>
  );
};
