import { If } from "@/common/components/if/If";
import { LinkChunks } from "@/common/components/link-chunks/LinkChunks";
import { TextField } from "@/common/components/textfield/TextField";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { MissingMaterialConversionsModal } from "@/contractor/pages/home/release/components/connections/components/connection-types/hosted/steps/missing-material-conversion/MissingMaterialConversionsModal";
import { PoFormat, ReleaseSummaryFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButtonStyled } from "../../../Connections.styles";
import {
  MissingMaterialConversionsProvider,
  useMissingMaterialConversions,
} from "../../../providers/MissingMaterialConversionsProvider";
import { useReleaseConnectionOptions } from "../../../providers/ReleaseConnectionOptionsProvider";
import { AdminUsersInfo } from "../../common/AdminUsersInfo";
import { ConnectionError, ValidationType } from "../../common/ExportErrorList";
import { PoReleaseDetails } from "../../common/PoReleaseDetails";
type Props = {
  release: ReleaseSummaryFieldsFragment;
};

const OnPremiseReleaseInformationWithProvider: FC<Props> = ({ release }) => {
  const intl = useIntl();
  const { settings, connectedIntegrationType } = useOrgSettings();
  const { updateRelease, updatedReleases } = useReleaseConnectionOptions();

  const { openModal: openMissingConversionsModal } =
    useMissingMaterialConversions();
  const [poNumber, setPoNumber] = useState(release?.poNumber ?? "");
  const hasMissingItems = useMemo(() => release.hasMissingCostCodes, [release]);
  const updatedRelease = useMemo(
    () => updatedReleases.find((r) => r.releaseId === release.id),
    [release.id, updatedReleases],
  );
  const hasVendorId = useMemo(
    () =>
      !!release.preferredVendor?.externalCode ||
      updatedRelease?.externalVendorCode,
    [release.preferredVendor?.externalCode, updatedRelease?.externalVendorCode],
  );

  const hasTaxRate = useMemo(
    () =>
      settings?.integrations.accounting
        .find((a) => a.enabled)
        ?.taxAuthorities.some((t) => t.rate === release?.taxRate),
    [release?.taxRate, settings?.integrations.accounting],
  );
  const hasProjectJobNumber = useMemo(
    () => !!release?.project?.jobNumber,
    [release?.project?.jobNumber],
  );
  const allItemsHaveUnitPrice = useMemo(
    () => release?.total !== null,
    [release?.total],
  );
  const hasMissingExternalCodes = useMemo(
    () =>
      release.type.poFormat === PoFormat.Detail &&
      release.hasMissingExternalCodes,
    [release.type.poFormat, release.hasMissingExternalCodes],
  );
  const includedValidations: ConnectionError[] = useMemo(
    () =>
      [
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_ITEMS",
          condition: hasMissingItems,
          key: "EXPORT_RELEASE_ERROR_MISSING_ITEMS",
          toolTipKey: "EXPORT_RELEASE_ERROR_MISSING_ITEMS_TOOLTIP",
          values: {
            releaseNumber: release?.sequenceNumber,
            sub: (chunks: ReactNode) => (
              <LinkChunks
                chunks={chunks}
                route={routes.delivery}
                routeParams={{ deliveryId: release.id }}
              />
            ),
          },
        },
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
          condition: !hasVendorId,
          includeCondition:
            !release.preferredVendor?.externalCode &&
            !!release.preferredVendor?.deletedAt,
          key: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
          toolTipKey: !release.preferredVendor?.deletedAt
            ? "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID_TOOLTIP"
            : "EXPORT_RELEASE_ERROR_MISSING_DELETED_VENDOR_ID_TOOLTIP",
          adminToolTipKey: !release.preferredVendor?.deletedAt
            ? "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID_TOOLTIP_ADMIN"
            : "EXPORT_RELEASE_ERROR_MISSING_DELETED_VENDOR_ID_TOOLTIP",
          values: {
            sub: (chunks: ReactNode) => (
              <LinkChunks
                chunks={chunks}
                route={routes.vendorsWithId}
                routeParams={{ vendorId: release.preferredVendor?.id || "" }}
              />
            ),
            sub1: () => <AdminUsersInfo />,
            org: release.sellerOrgLocation?.org.name || "da",
          },
          child: (
            <If isTrue={release.preferredVendor?.deletedAt}>
              <TextField
                placeholder={intl.$t({
                  id: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
                })}
                className="mb-auto"
                value={updatedRelease?.externalVendorCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateRelease({
                    poNumber: updatedRelease?.poNumber,
                    releaseId: release.id,
                    externalVendorCode: event.target.value,
                  })
                }
                size="small"
                required
                shrink
              />
            </If>
          ),
        },
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_TAX_AUTHORITY_CODE",
          condition: !hasTaxRate && !release.customTaxAmount,
          key: "EXPORT_RELEASE_ERROR_MISSING_TAX_AUTHORITY_CODE",
          toolTipKey: "EXPORT_RELEASE_ERROR_MISSING_TAX_AUTHORITY_CODE_TOOLTIP",
          adminToolTipKey:
            "EXPORT_RELEASE_ERROR_MISSING_TAX_AUTHORITY_CODE_TOOLTIP_ADMIN",
          values: {
            rate: Number(new Decimal(release.taxRate || 0).mul(100)),
            sub: (chunks: ReactNode) => (
              <LinkChunks chunks={chunks} route={routes.connections} />
            ),
            sub1: () => <AdminUsersInfo className="text-white underline" />,
            integration: intl.$t({
              id: `INTEGRATION_${connectedIntegrationType}`,
            }),
          },
        },
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_PROJECT_JOB_NUMBER",
          condition: !hasProjectJobNumber,
          key: "EXPORT_RELEASE_ERROR_MISSING_PROJECT_JOB_NUMBER",
          toolTipKey: "EXPORT_RELEASE_ERROR_MISSING_PROJECT_JOB_NUMBER_TOOLTIP",
          values: {
            projectName: release.project?.name,
            sub: (chunks: ReactNode) => (
              <LinkChunks
                chunks={chunks}
                route={routes.projectDetails}
                routeParams={{ id: release.project?.id ?? "" }}
              />
            ),
          },
        },
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_PRICES",
          condition: !allItemsHaveUnitPrice,
          key: "EXPORT_RELEASE_ERROR_MISSING_PRICES",
          toolTipKey: "EXPORT_RELEASE_ERROR_MISSING_PRICES_TOOLTIP",
          values: {
            sub: (chunks: ReactNode) => (
              <LinkChunks
                chunks={chunks}
                route={routes.delivery}
                routeParams={{ deliveryId: release.id }}
              />
            ),
            releaseNumber: release?.sequenceNumber,
          },
        },
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_ACCOUNTING_SOFTWARE_ID",
          condition: !!hasMissingExternalCodes,
          key: "EXPORT_RELEASE_ERROR_MISSING_ACCOUNTING_SOFTWARE_ID",
          toolTipKey:
            "EXPORT_RELEASE_ERROR_MISSING_ACCOUNTING_SOFTWARE_ID_TOOLTIP",
          adminToolTipKey:
            "EXPORT_RELEASE_ERROR_MISSING_ACCOUNTING_SOFTWARE_ID_TOOLTIP_ADMIN",
          values: {
            sub: (chunks: ReactNode) => (
              <LinkChunks chunks={chunks} route={routes.itemDatabase} />
            ),
            releaseNumber: release?.sequenceNumber,
          },
        },
        {
          id: "EXPORT_RELEASE_MISSING_UOM_CONVERSIONS",
          condition:
            release.materialConversions.filter((c) => !c.quantityMultiplier)
              .length > 0,
          key: "EXPORT_RELEASE_MISSING_UOM_CONVERSIONS",
          toolTipKey: "EXPORT_RELEASE_MISSING_UOM_CONVERSIONS_TOOLTIP",
          values: {
            value: release.materialConversions.filter(
              (c) => !c.quantityMultiplier,
            ).length,
          },
          button: (
            <PrimaryButtonStyled
              onClick={() =>
                openMissingConversionsModal({
                  conversions: release.materialConversions,
                })
              }
            >
              <FormattedMessage id="SET_CONVERSION" />
            </PrimaryButtonStyled>
          ),
        },
        {
          id: "EXISTING_CONVERSIONS_WITH_NUMBER",
          condition:
            release.materialConversions.length > 0 &&
            (release?.materialConversions || []).filter(
              (c) => !c.quantityMultiplier,
            ).length === 0,
          key: "EXISTING_CONVERSIONS_WITH_NUMBER",
          values: {
            value: (release?.materialConversions || []).filter(
              (c) => c.quantityMultiplier,
            ).length,
          },
          hideInfoIcon: true,
          validationType: ValidationType.Warning,
          button: (
            <PrimaryButtonStyled
              onClick={() =>
                openMissingConversionsModal({
                  conversions: release.materialConversions.filter(
                    (c) => c.quantityMultiplier,
                  ),
                })
              }
            >
              <FormattedMessage id="EDIT" />
            </PrimaryButtonStyled>
          ),
        },
      ].filter((e) =>
        e.includeCondition !== undefined
          ? e.includeCondition || e.condition
          : e.condition,
      ),
    [
      allItemsHaveUnitPrice,
      connectedIntegrationType,
      hasMissingExternalCodes,
      hasMissingItems,
      hasProjectJobNumber,
      hasTaxRate,
      hasVendorId,
      intl,
      openMissingConversionsModal,
      release.customTaxAmount,
      release.id,
      release.materialConversions,
      release.preferredVendor?.deletedAt,
      release.preferredVendor?.externalCode,
      release.preferredVendor?.id,
      release.project?.id,
      release.project?.name,
      release.sellerOrgLocation?.org.name,
      release?.sequenceNumber,
      release.taxRate,
      updateRelease,
      updatedRelease?.externalVendorCode,
      updatedRelease?.poNumber,
    ],
  );

  const updatePoNumber = useCallback(
    (value: string) => {
      setPoNumber(value);
      updateRelease({
        poNumber: value,
        releaseId: release.id,
        externalVendorCode: updatedRelease?.externalVendorCode,
      });
    },
    [release.id, updateRelease, updatedRelease?.externalVendorCode],
  );

  return (
    <>
      <PoReleaseDetails
        release={release}
        poNumber={poNumber}
        updatePoNumber={updatePoNumber}
        includedValidations={includedValidations}
      />
      <MissingMaterialConversionsModal />
    </>
  );
};

export const OnPremiseReleaseInformation: FC<Props> = (props) => (
  <MissingMaterialConversionsProvider>
    <OnPremiseReleaseInformationWithProvider {...props} />
  </MissingMaterialConversionsProvider>
);
