import { CustomPaymentTerms } from "@/common/components/terms-and-discounts/CustomPaymentTerms";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { VendorFormType } from "./VendorForm";

export const VendorPaymentTerm = () => {
  const { watch, setValue } = useFormContext<VendorFormType>();
  const defaultPaymentTerm = watch("defaultPaymentTerm");

  const setPaymentTerm = useCallback(
    (value: string | null) => {
      setValue("defaultPaymentTerm", value ? Number(value) : undefined);
    },
    [setValue],
  );

  return (
    <CustomPaymentTerms
      paymentTerm={defaultPaymentTerm?.toString()}
      updateItem={setPaymentTerm}
      labelId="DEFAULT_PAYMENT_TERMS"
    />
  );
};
