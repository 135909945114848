import Handsontable from "handsontable";
import { CUSTOM_SOURCE } from "../constants/tableConstants";

export const setTableCells = (
  data: [number, number, string | number][],
  hotInstance: Handsontable | undefined | null,
  source: string = CUSTOM_SOURCE,
): void => {
  hotInstance?.setDataAtCell(
    data.filter((d) => d[0] !== -1 && d[1] !== -1),
    source,
  );
};
