import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { Switch } from "@/common/components/switch/Switch";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import {
  DefaultSettingsDetails,
  DefaultSettingsToggleText,
  SimpleStepContainer,
  SimpleStepContent,
  SimpleStepItemRow,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
} from "../../../Wizard.styles";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";

export const AgaveWizardInvoiceSettings: FC = () => {
  const intl = useIntl();
  const {
    autoExportApprovedInvoices,
    setAutoExportApprovedInvoices,
    autoPostInvoices,
    setAutoPostInvoices,
  } = useAgaveHostedWizard();

  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {intl.$t({ id: "INVOICE_EXPORT_SETTINGS" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <SimpleStepContent>
        <SimpleStepItemRow className="border-b-0">
          <DefaultSettingsToggleText>
            {intl.$t({ id: "AUTOMATED_SYNCHRONIZATION" })}
          </DefaultSettingsToggleText>
          <Switch
            width={100}
            offLabel={intl.$t({ id: "NO" })}
            onLabel={intl.$t({ id: "YES" })}
            onChange={setAutoExportApprovedInvoices}
            value={autoExportApprovedInvoices}
          />
          <DefaultSettingsDetails>
            {intl.$t({ id: "AUTOMATED_SYNCHRONIZATION_INVOICE" })}
          </DefaultSettingsDetails>
        </SimpleStepItemRow>
        <SourceSystemWrapper
          allowedFeatures={[IntegrationFeature.AutoPostInvoices]}
        >
          <SimpleStepItemRow className="border-b-0">
            <DefaultSettingsToggleText>
              {intl.$t({ id: "AUTO_POST_INVOICES" })}
            </DefaultSettingsToggleText>
            <Switch
              width={100}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              onChange={setAutoPostInvoices}
              value={autoPostInvoices}
            />
            <DefaultSettingsDetails>
              {intl.$t({ id: "AUTO_POST_INVOICES_DESCRIPTION" })}
            </DefaultSettingsDetails>
          </SimpleStepItemRow>
        </SourceSystemWrapper>
      </SimpleStepContent>
    </SimpleStepContainer>
  );
};
