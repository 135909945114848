import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useStartupDataUpdate } from "@/common/stores/hooks/useStartupDataUpdate";
import {
  UpdateCostCodesInput,
  useUpdateCostCodesMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useUpdateCostCodes = () => {
  const { setError } = useGlobalError();
  const [updateCostCodesMutation] = useUpdateCostCodesMutation();
  const { updateStartupData } = useStartupDataUpdate();

  const updateCostCodes = useCallback(
    async (input: UpdateCostCodesInput) => {
      try {
        const { data, errors } = await updateCostCodesMutation({
          variables: {
            input,
          },
        });
        if (data && data.updateCostCodes) {
          updateStartupData({
            costCodes: data.updateCostCodes,
          });
        }
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [updateCostCodesMutation, setError, updateStartupData],
  );

  return {
    updateCostCodes,
  };
};
