import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  ExternalPoBaseFieldsFragment,
  SourceSystem,
  useExternalPOsQuery,
} from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntegrationFeatureRequirement } from "../components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "./integrations/types/IntegrationFeature";

const DEFAULT_PAGE_SIZE_WITHOUT_PAGINATION = 100;

type ExternalPOsPaginatedInput = {
  sourceSystem: SourceSystem | undefined;
  search?: string;
  sellerOrgLocationID?: string;
  projectId?: string;
  minDate?: number;
  maxDate?: number;
  skip?: boolean;
};

type ExternalPOsPaginatedOutput = {
  externalPOs: (ExternalPoBaseFieldsFragment & Identity)[];
  loadingExternalPOs: boolean;
  count: number;
  page: number;
  setPageFn: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  itemsPerPage: number;
  setPageSizeFn: (perPage: number) => void;
  hasNext: boolean;
  hasPrevious: boolean;
  total?: number;
};

export const useExternalPOsWithPagination = ({
  sourceSystem,
  search,
  sellerOrgLocationID,
  projectId,
  minDate,
  maxDate,
  skip = false,
}: ExternalPOsPaginatedInput): ExternalPOsPaginatedOutput => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const { data, loading, error, refetch } = useExternalPOsQuery({
    variables: {
      input: {
        sourceSystem: sourceSystem ?? SourceSystem.Foundation,
        search,
        sellerOrgLocationID,
        projectId,
        perPage: hasFeatureInConnectedSourceSystem(
          IntegrationFeature.DisableExternalPoPagination,
        )
          ? DEFAULT_PAGE_SIZE_WITHOUT_PAGINATION
          : perPage,
        page: page + 1,
        minDate: minDate ?? undefined,
        maxDate: maxDate ?? undefined,
      },
    },
    nextFetchPolicy: "cache-and-network",
    skip:
      skip ||
      !sourceSystem ||
      (hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ExternalPoReadsProjectSpecific,
      )
        ? !projectId
        : false),
  });

  useEffect(() => {
    if (
      !skip &&
      !!sourceSystem &&
      (hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ExternalPoReadsProjectSpecific,
      )
        ? !!projectId
        : true)
    ) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, sellerOrgLocationID]);

  useErrorEffect(error);

  const previousPage = useCallback(() => {
    if (page > 0) {
      setPage(page - 1);
    }
  }, [page]);

  const nextPage = useCallback(() => {
    if (data?.externalPOs.hasNextPage) {
      setPage(page + 1);
    }
  }, [data?.externalPOs.hasNextPage, page]);

  const externalPOs = useMemo(
    () =>
      (data?.externalPOs.nodes ?? []).map((i) => ({
        ...i,
        id: i.externalId,
      })),
    [data?.externalPOs],
  );

  return {
    externalPOs,
    loadingExternalPOs: loading,
    count: externalPOs.length || perPage,
    page,
    setPageFn: setPage,
    previousPage,
    nextPage,
    itemsPerPage: perPage,
    setPageSizeFn: setPerPage,
    hasNext: !!data?.externalPOs.hasNextPage,
    hasPrevious: page > 0,
    total:
      externalPOs.length === 0 && !data?.externalPOs.hasNextPage
        ? 0
        : undefined,
  };
};
