import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { CurrencyPickerControlled } from "@/common/components/currency-picker/CurrencyPicker";
import { If } from "@/common/components/if/If";
import { PhoneInputControlled } from "@/common/components/phone-input/PhoneInputControlled";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { StateSelectorControlled } from "@/common/components/state-selector/StateSelectorControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { countries } from "@/common/utils/countries";
import { strongify } from "@/common/utils/htmlUtils";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import {
  isValidCellPhone,
  isValidUSPostalCode,
} from "@/common/utils/validationUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { OrgType, UpdateOrgInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  Container,
  FloatingFooterStyled,
  Item,
} from "../../common/Organization.styles";
import { useOrgProfile } from "../hooks/useOrgProfile";
import { LogoUploader } from "./logo-uploader/LogoUploader";

const InlineItems = tw.div<{ $fullWidth?: boolean }>`grid ${({
  $fullWidth,
}: {
  $fullWidth?: boolean;
}) => ($fullWidth ? "grid-cols-2" : "grid-cols-6 ")} gap-2 pb-3`;

const LogoContainer = tw.div`grid justify-end`;

const TextFieldControlledStyled = tw(TextFieldControlled)`
  w-full
`;

const Domain = tw.div`text-gray-500 text-sm`;

type Props = {
  withSave?: boolean;
  withLogoUpload?: boolean;
  fullWidth?: boolean;
  useViewerEnterprise?: boolean;
};

export const OrgProfileForm: FC<Props> = ({
  withSave = false,
  withLogoUpload = false,
  fullWidth = false,
  useViewerEnterprise = true,
}) => {
  const intl = useIntl();
  const { loading, domain, createOrg, updateOrg, viewerOrg } = useOrgProfile();
  const { setSuccessAlert } = useSnackbar();
  const { handleSubmit, formState, reset } = useFormContext<UpdateOrgInput>();
  const { watch } = useFormContext();
  const country = watch("hqAddress.country");
  const { settings } = useOrgSettings();

  const requiredStateAndCode = useMemo(
    () => isDefaultCountry(country),
    [country],
  );

  const onSaveHandler = useCallback(
    async (values: UpdateOrgInput) => {
      let result = null;
      if (values.id) {
        result = await updateOrg({
          ...values,
          settings: {
            ...values.settings,
            display: {
              ...values.settings?.display,
              enableManufacturers: settings?.display?.enableManufacturers,
              enableAdditionalCharges:
                settings?.display?.enableAdditionalCharges,
            },
          },
        });
      } else {
        const { settings, ...otherValues } = values;
        if (!otherValues.name) {
          return;
        }

        result = await createOrg({
          ...otherValues,
          name: otherValues.name,
          localPart: settings?.invoices?.localPart,
          type: OrgType.Contractor,
          enterpriseId: useViewerEnterprise
            ? viewerOrg?.viewer?.org?.enterprise?.id
            : undefined,
        });
      }

      if (!result?.id) {
        return;
      }
      setSuccessAlert(
        intl.$t({ id: "ORG_UPDATE_SUCCESS" }, { name: strongify(result.name) }),
      );
    },
    [
      setSuccessAlert,
      intl,
      updateOrg,
      settings?.display?.enableManufacturers,
      settings?.display?.enableAdditionalCharges,
      createOrg,
      useViewerEnterprise,
      viewerOrg?.viewer?.org?.enterprise?.id,
    ],
  );

  const isValidCode = useCallback(
    (value: string | null) => {
      return !isValidUSPostalCode(value) && requiredStateAndCode
        ? intl.$t({ id: "POSTAL_CODE_VALIDATION" })
        : true;
    },
    [intl, requiredStateAndCode],
  );

  const isValidContactCellPhone = useCallback(
    (value: string | null) => {
      return !isValidCellPhone(value) && requiredStateAndCode
        ? intl.$t({ id: "PHONE_NUMBER_VALIDATION" })
        : true;
    },
    [intl, requiredStateAndCode],
  );

  return (
    <Container>
      <Item $fullWidth={fullWidth}>
        <TextFieldControlledStyled
          size="small"
          label={intl.$t({ id: "ORG_NAME" })}
          rules={{ required: true }}
          name="name"
        />
      </Item>
      <Item $fullWidth={fullWidth}>
        <TextFieldControlledStyled
          size="small"
          label={intl.$t({ id: "CORPORATE_ADDRESS_LINE_1" })}
          rules={{ required: true }}
          name="hqAddress.addressLine1"
        />
      </Item>
      <Item $fullWidth={fullWidth}>
        <TextFieldControlledStyled
          size="small"
          label={intl.$t({ id: "CORPORATE_ADDRESS_LINE_2" })}
          name="hqAddress.addressLine2"
        />
      </Item>
      <Item $fullWidth={fullWidth}>
        <TextFieldControlledStyled
          size="small"
          label={intl.$t({ id: "ADDRESS_CITY" })}
          rules={{ required: true }}
          name="hqAddress.city"
        />
      </Item>
      <InlineItems $fullWidth={fullWidth}>
        <StateSelectorControlled
          name="hqAddress.state"
          countryInputName="hqAddress.country"
        />

        <TextFieldControlledStyled
          name="hqAddress.postalCode"
          label={intl.$t({ id: "ADDRESS_ZIP_CODE" })}
          rules={{
            required: requiredStateAndCode,
            validate: isValidCode,
          }}
        />
      </InlineItems>
      <Item $fullWidth={fullWidth}>
        <SelectControlled
          name="hqAddress.country"
          placeholder={intl.$t({ id: "ADDRESS_COUNTRY" })}
          options={countries}
          getLabel={(option) => option.name}
          getValue={(option) => option.abbreviation}
          rules={{ required: true }}
        />
      </Item>
      <Item $fullWidth={fullWidth}>
        <PhoneInputControlled
          size="small"
          label={intl.$t({ id: "LOCATION_CELL_PHONE" })}
          name="phone"
          rules={{ required: true, validate: isValidContactCellPhone }}
          className="w-full"
          countryCode={country}
        />
      </Item>
      <Item $fullWidth={fullWidth}>
        <CurrencyPickerControlled name="settings.display.currencyId" />
      </Item>
      <Item $fullWidth={fullWidth}>
        <TextFieldControlledStyled
          size="small"
          label={intl.$t({ id: "EMPLOYER_IDENTIFICATION_NUMBER" })}
          name="ein"
        />
      </Item>
      <Item $fullWidth={fullWidth}>
        <TextFieldControlledStyled
          size="small"
          label={intl.$t({ id: "EMAIL_FOR_INVOICES" })}
          name="settings.invoices.localPart"
          InputProps={{
            endAdornment: <Domain>@{domain}</Domain>,
          }}
        />
      </Item>
      <If isTrue={withLogoUpload}>
        <InlineItems>
          <FormattedMessage tagName="div" id="ORG_LOGO" />
          <LogoContainer>
            <LogoUploader />
          </LogoContainer>
        </InlineItems>
      </If>
      <If isTrue={withSave}>
        <Item $fullWidth={fullWidth}>
          <FloatingFooterStyled>
            <If isTrue={formState.isDirty}>
              <OutlinedButton wide onClick={() => reset()}>
                <FormattedMessage id="CANCEL" />
              </OutlinedButton>
            </If>
            <PrimaryButton
              onClick={handleSubmit(onSaveHandler)}
              loading={loading}
              wide
            >
              {intl.$t({ id: "SAVE" })}
            </PrimaryButton>
          </FloatingFooterStyled>
        </Item>
      </If>
    </Container>
  );
};
