import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useStartupDataUpdate } from "@/common/stores/hooks/useStartupDataUpdate";
import {
  CostTypesDocument,
  UpdateCostTypesInput,
  useUpdateCostTypesMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useUpdateCostTypes = () => {
  const { setError } = useGlobalError();
  const [updateCostTypesMutation] = useUpdateCostTypesMutation();
  const { updateStartupData } = useStartupDataUpdate();

  const updateCostTypes = useCallback(
    async (input: UpdateCostTypesInput) => {
      try {
        const { data, errors } = await updateCostTypesMutation({
          variables: {
            input,
          },
          refetchQueries: [{ query: CostTypesDocument }],
        });
        setError(errors);
        if (data?.updateCostTypes) {
          updateStartupData({
            costTypes: data.updateCostTypes,
          });
        }
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [updateCostTypesMutation, setError, updateStartupData],
  );

  return {
    updateCostTypes,
  };
};
