import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useIntl } from "react-intl";

export const useCreateBuyoutFromQuoteConfig = (): SpreadSheetConfig[] => {
  const intl = useIntl();
  const { requiredValidator } = useTableValidators();
  const { buyout } = useContractorBuyout();

  return [
    {
      header: intl.$t({ id: "ITEM_NAME" }),
      columnId: "description",
      columnType: COLUMN_TYPE.Material,
    },
    {
      header: intl.$t({ id: "TAG" }),
      columnId: "tags",
      columnType: COLUMN_TYPE.Tag,
    },
    {
      header: intl.$t({ id: "UOM" }),
      columnId: "UOM",
      columnType: COLUMN_TYPE.UOM,
    },
    {
      header: intl.$t({ id: "QUANTITY" }),
      columnId: "quantityDecimal",
      columnType: COLUMN_TYPE.Quantity,
    },
    {
      header: intl.$t({ id: "UNIT_PRICE" }),
      columnId: buyout ? "requestedUnitPrice" : "unitPrice",
      columnType: COLUMN_TYPE.UnitPrice,
      validator: requiredValidator,
      disabledForLumpSum: true,
    },
    {
      header: intl.$t({ id: "EXT_PRICE" }),
      columnId: "extPrice",
      columnType: COLUMN_TYPE.ExtPrice,
    },
    {
      header: intl.$t({ id: "COST_CODE" }),
      columnId: "costCode",
      columnType: COLUMN_TYPE.CostCode,
    },
    {
      header: intl.$t({ id: "PHASE_CODE" }),
      columnId: "tags",
      columnType: COLUMN_TYPE.PhaseCode,
    },
    {
      header: intl.$t({ id: "NOTES" }),
      columnId: "notes",
      columnType: COLUMN_TYPE.Notes,
    },
  ];
};
