import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { useMemo } from "react";

type Input = {
  release: ExpandedRelease | null | undefined;
  useInvoiceItemPrice?: boolean;
};

export const useInvoiceTotals = ({
  release,
  useInvoiceItemPrice = false,
}: Input) => {
  const { calcExtPrice } = usePriceCalculation();

  const invoiceSubtotal = useMemo(
    () =>
      release?.items.reduce(
        (acc, item) =>
          acc.plus(
            calcExtPrice(
              item.invoiceItems?.[0]?.quantity || 0,
              useInvoiceItemPrice
                ? item.invoiceItems?.[0]?.unitPrice || 0
                : item.unitPrice || 0,
            ),
          ),
        new Decimal(0),
      ) || new Decimal(0),
    [calcExtPrice, release?.items, useInvoiceItemPrice],
  );

  return {
    invoiceSubtotal,
  };
};
