import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import {
  MAX_PRICE_DECIMALS,
  MAX_QUANTITY_DECIMALS,
  PDF_FONT,
  PDF_LEFT_SPACING,
  TOTAL_PRICE_DECIMAL_POINTS,
} from "@/common/const";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { formatHeader } from "@/common/utils/pdf-print/formatHeader";
import { CostCodeType } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { ZoneCategory } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedRelease,
  ExpandedReleaseItem,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  ExternalCostCode,
  ExternalCostType,
  ReleaseStatus,
  TagExtendedFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const computeMappingLabel = (
  tagMapping:
    | {
        externalCostCode?:
          | Pick<ExternalCostCode, "code" | "name">
          | undefined
          | null;
        externalCostType?: Pick<ExternalCostType, "code"> | undefined | null;
      }
    | undefined
    | null,
) => {
  if (!tagMapping) {
    return "";
  }

  const { externalCostCode, externalCostType } = tagMapping;

  if (!externalCostCode || !externalCostType) {
    return "";
  }

  const phaseCodeNumberLabel = externalCostCode.code
    ? ` - ${externalCostCode.code}`
    : "";

  return `${externalCostCode.name} - ${externalCostType.code}${phaseCodeNumberLabel}`;
};

const computePhaseCodeLabel = ({
  tags,
  costCode,
  groupedByCostCode,
  hasPhaseCodes,
}: {
  tags: TagExtendedFieldsFragment[] | undefined;
  costCode: CategoryState<ExpandedReleaseItem>;
  groupedByCostCode: boolean;
  hasPhaseCodes: boolean;
}): string | null => {
  if (!groupedByCostCode || !hasPhaseCodes || !tags) {
    return "";
  }

  const tagMapping = tags?.find((tag) => tag.id === costCode.id)?.mapping;

  return computeMappingLabel(tagMapping);
};

const releaseItemQuantity = (
  releaseItem: ExpandedReleaseItem,
  intl: IntlShape,
) => {
  if (isLumpSumItem(releaseItem)) {
    return "--";
  }
  return `${intl.formatNumber(Number(releaseItem.quantityDecimal), {
    minimumFractionDigits: 0,
    maximumFractionDigits: MAX_QUANTITY_DECIMALS,
  })}\n${releaseItem.uom?.mnemonic || releaseItem.uom?.pluralDescription}`;
};

const releaseItemUnitPrice = ({
  releaseItem,
  formatCurrency,
  intl,
  checkMoreThenZero,
}: {
  releaseItem: ExpandedReleaseItem;
  formatCurrency: FormatCurrencyType;
  intl: IntlShape;
  checkMoreThenZero: boolean;
}) => {
  if (isLumpSumItem(releaseItem)) {
    return "--";
  }
  if (!checkMoreThenZero || Number(releaseItem.unitPrice) > 0) {
    return formatCurrency(releaseItem.unitPrice, {
      maximumFractionDigits: MAX_PRICE_DECIMALS,
    });
  }
  return intl.$t({ id: "TO_BE_SPECIFIED_BY_VENDOR" });
};

const itemsForPdf = (
  release: ExpandedRelease,
  intl: IntlShape,
  zones: ZoneCategory[],
  header: string[],
  hasPhaseCodes: boolean,
  hasManufacturersSetting: boolean,
  groupedByCostCode: boolean,
  fullyReceived: boolean | null | undefined,
  fullyInvoiced: boolean | null | undefined,
  filteredTags: string[],
  hasIssues: boolean,
  hasDifferentVendors: boolean,
  formatCostCode: (costCode: CostCodeType) => string,
  formatCurrency: FormatCurrencyType,
  tags?: TagExtendedFieldsFragment[],
  canViewPrices?: boolean,
): RowInput[] => {
  const result: RowInput[] = [];
  let count = 0;
  zones.forEach((zone) => {
    if (
      !(zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID) &&
      zone.items.some((costCode) =>
        costCode.items.some(
          (i) =>
            !i.alternativeFulfillmentRelease && !i.alternativeFulfillmentTime,
        ),
      )
    ) {
      result.push([
        {
          content: `${zone.name} (${intl.$t({ id: "ZONE" })})`,
          colSpan: header.length,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
      ]);
    }

    zone.items.forEach((costCode) => {
      const previousFormattedCode = groupedByCostCode
        ? `${hasPhaseCodes ? costCode.name : formatCostCode(costCode.id)} (${intl.$t({ id: hasPhaseCodes ? "PHASE_CODE" : "COST_CODE" })})`
        : "";

      let formattedCostCode =
        computePhaseCodeLabel({
          tags,
          costCode,
          groupedByCostCode,
          hasPhaseCodes,
        }) || previousFormattedCode;

      const items = costCode.items.filter(
        (i) =>
          !i.alternativeFulfillmentRelease && !i.alternativeFulfillmentTime,
      );

      if (
        items.length > 0 &&
        groupedByCostCode &&
        (!hasPhaseCodes || costCode.id !== UNSPECIFIED_COST_CODE_ID)
      ) {
        result.push([
          {
            content: formattedCostCode,
            colSpan: header.length,
            styles: {
              fontStyle: "bold",
              cellPadding: { left: 7, top: 3, bottom: 3 },
            },
          },
        ]);
      }
      items
        .filter((i) => {
          switch (fullyReceived) {
            case true:
              return new Decimal(
                i.receivedQuantityDecimal || 0,
              ).greaterThanOrEqualTo(i.quantityDecimal);
            case false:
              return new Decimal(i.receivedQuantityDecimal || 0).lessThan(
                i.quantityDecimal,
              );
            default:
              return true;
          }
        })
        .filter((i) => {
          switch (fullyInvoiced) {
            case true:
              return new Decimal(i.invoicedQuantity).greaterThanOrEqualTo(
                i.quantityDecimal,
              );
            case false:
              return new Decimal(i.invoicedQuantity).lessThan(
                i.quantityDecimal,
              );
            default:
              return true;
          }
        })
        .filter((i) =>
          filteredTags.length
            ? i.tags.some((tag) => filteredTags.includes(tag.id))
            : true,
        )
        .forEach((releaseItem) => {
          count++;
          const totalPriceForItem = new Decimal(releaseItem.unitPrice || 0).mul(
            releaseItem.quantityDecimal,
          );
          if (!groupedByCostCode) {
            if (hasPhaseCodes) {
              const phaseCodeLabel = computeMappingLabel(
                releaseItem.tags?.find((tag) => tag.mapping)?.mapping,
              );
              formattedCostCode = phaseCodeLabel
                ? phaseCodeLabel
                : intl.$t({ id: "UNSPECIFIED_PHASE_CODE" });
            } else {
              formattedCostCode =
                formatCostCode(releaseItem.costCode) ||
                intl.$t({ id: "UNSPECIFIED_COST_CODE" });
            }
          }
          const itemName =
            releaseItem.name ||
            releaseItem.projectItem?.material.material.name ||
            "";
          const costCode = groupedByCostCode ? "" : ` (${formattedCostCode})`;
          const instructions = releaseItem.instructions?.text
            ? ` [${releaseItem.instructions?.text}]`
            : "";
          let row: RowInput;

          if (
            release?.status === ReleaseStatus.Received ||
            release?.status === ReleaseStatus.PartiallyReceived
          ) {
            row = [
              count,
              `${itemName}${costCode}${instructions}`,
              ...(hasDifferentVendors
                ? [
                    vendorLabelFormatter(releaseItem.sellerOrgLocation) ||
                      vendorLabelFormatter(release.sellerOrgLocation) ||
                      "",
                  ]
                : []),
              ...(hasManufacturersSetting
                ? [
                    releaseItem.manufacturer?.name ||
                      intl.$t({ id: "ANY_MANUFACTURER" }),
                  ]
                : []),
              releaseItemQuantity(releaseItem, intl),
              ...(canViewPrices
                ? [
                    releaseItemUnitPrice({
                      releaseItem,
                      intl,
                      formatCurrency,
                      checkMoreThenZero: false,
                    }),
                    formatCurrency(totalPriceForItem, {
                      maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
                    }),
                  ]
                : []),
              ...(hasIssues
                ? [
                    releaseItem.issues?.length
                      ? `${releaseItem.issues[0].quantityDecimal} ${
                          releaseItem.uom?.mnemonic ||
                          releaseItem.uom?.pluralDescription
                        } ${intl.$t({
                          id: `RELEASE_ISSUE_${releaseItem.issues[0].issueType}`,
                        })}${
                          releaseItem.issues[0]?.description
                            ? `\n\n${releaseItem.issues[0]?.description}`
                            : ""
                        }`
                      : "",
                    releaseItem.issues[0]?.resolution
                      ? `${intl.$t({
                          id: releaseItem.issues[0].resolution.resolutionType,
                        })}\n\n${releaseItem.issues[0].resolution.comments}`
                      : "",
                  ]
                : []),
            ];
          } else {
            row = [
              count,
              `${itemName}${costCode}${instructions}`,
              ...(hasDifferentVendors
                ? [
                    vendorLabelFormatter(releaseItem.sellerOrgLocation) ||
                      vendorLabelFormatter(release?.sellerOrgLocation) ||
                      "",
                  ]
                : []),
              ...(hasManufacturersSetting
                ? [
                    releaseItem.manufacturer?.name ||
                      intl.$t({ id: "ANY_MANUFACTURER" }),
                  ]
                : []),
              releaseItemQuantity(releaseItem, intl),
              ...(canViewPrices
                ? [
                    releaseItemUnitPrice({
                      releaseItem,
                      intl,
                      formatCurrency,
                      checkMoreThenZero: true,
                    }),
                    Number(totalPriceForItem) > 0
                      ? formatCurrency(totalPriceForItem, {
                          maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
                        })
                      : intl.$t({ id: "TO_BE_SPECIFIED_BY_VENDOR" }),
                  ]
                : []),
            ];
          }

          result.push(row);
        });
    });
  });

  return result;
};

export const materials = (
  doc: jsPDF,
  release: ExpandedRelease,
  intl: IntlShape,
  zones: ZoneCategory[],
  topSpacing: number,
  hasPhaseCodes: boolean,
  hasManufacturersSetting: boolean,
  groupedByCostCode: boolean,
  fullyReceived: boolean | null | undefined,
  fullyInvoiced: boolean | null | undefined,
  filteredTags: string[],
  formatCostCode: (costCode: CostCodeType) => string,
  formatCurrency: FormatCurrencyType,
  tags?: TagExtendedFieldsFragment[],
  canViewPrices?: boolean,
) => {
  const hasIssues = (release?.issues || []).length > 0;
  const hasDifferentVendors = !!release?.items.some(
    (item) =>
      item.sellerOrgLocation &&
      item.sellerOrgLocation?.id !== release?.sellerOrgLocation?.id,
  );
  const header = [
    "",
    "ITEMS_IN_DELIVERY",
    ...(hasDifferentVendors ? ["VENDOR"] : []),
    "MANUFACTURER",
    "RELEASE_QUANTITY",
    ...(canViewPrices ? ["UNIT_COST", "EXT_PRICE"] : []),
    ...(hasIssues ? ["ISSUES", "SOLUTION"] : []),
  ];
  doc
    .setFont(PDF_FONT, "", "bold")
    .setFontSize(10)
    .text(intl.$t({ id: "ORDERED_ITEMS" }), PDF_LEFT_SPACING, topSpacing);
  topSpacing += 5;
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 8,
    },
    startY: topSpacing,
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
      cellPadding: { top: 2, bottom: 1, left: 2, right: 2 },
    },
    columnStyles: {
      0: { valign: "middle", halign: "center" },
      1: { valign: "middle" },
      2: { halign: "center", valign: "middle" },
      3: { halign: "center", valign: "middle" },
      4: { halign: "right", valign: "middle" },
      5: { halign: "right", valign: "middle" },
      6: { halign: "center", valign: "middle" },
      7: { halign: "center", valign: "middle" },
      8: { halign: "center", valign: "middle" },
    },
    head: [
      header
        .filter((h) => hasManufacturersSetting || h !== "MANUFACTURER")
        .map((name) => formatHeader(name, intl)),
    ],
    body: [
      ...itemsForPdf(
        release,
        intl,
        zones,
        header,
        hasPhaseCodes,
        hasManufacturersSetting,
        groupedByCostCode,
        fullyReceived,
        fullyInvoiced,
        filteredTags,
        hasIssues,
        hasDifferentVendors,
        formatCostCode,
        formatCurrency,
        tags,
        canViewPrices,
      ),
    ],
    didParseCell: function (data) {
      if (
        data.section === "head" &&
        data.column.index >= 4 &&
        data.column.index <= 5
      ) {
        data.cell.styles.halign = "right";
      }
    },
  });
};
