/* eslint-disable @cspell/spellchecker */
import Upload from "@/assets/icons/upload.svg?react";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { InfoText } from "@/common/components/info-text/InfoText";
import { FileType } from "@/generated/graphql";
import { Check, DeleteOutline } from "@mui/icons-material";
import { FC, useCallback, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

export type StylingClasses = {
  classes?: {
    container?: string;
    dndContainer?: string;
    uploadContainer?: {
      container?: string;
      image?: string;
      text?: string;
      button?: string;
    };
    fileContainer?: string;
  };
};

type FileUploadAreaProps = {
  onChange: (files: File[]) => Promise<void> | void;
  error: unknown;
  accept?: Accept;
  includeFileDetails?: boolean;
  className?: string;
  multiple?: boolean;
  mode?: "horizontal" | "vertical";
  loading?: boolean;
  disabled?: boolean;
  testId?: string;
} & StylingClasses;

type ContainerMode = {
  $mode: "horizontal" | "vertical";
};

const FileUploadContainer = tw.div`
  grid gap-2 overflow-auto
`;

const DNDContainer = tw.div`
  grid items-center justify-items-center justify-center border border-dashed border-gray-500 rounded-lg
  ${(props: ContainerMode) =>
    props.$mode === "horizontal" ? "h-full" : "h-64"}
`;

const StyledText = tw.div`
  text-black
`;

const UploadIcon = tw(Upload)`
  text-center text-blue-500 w-20 h-20
`;

const CheckIcon = tw(Check)`
  text-center text-blue-500 w-20 h-20
`;

const FileName = tw.div`
  truncate mr-4 w-1/2
`;

const FileInfo = tw.div`
  text-blue-500 flex items-center
`;

const DeleteFile = tw.div`col-start-3 col-end-3 row-start-1 row-end-3 hover: cursor-pointer`;

const TextContainer = tw.div`text-center ${(props: ContainerMode) =>
  props.$mode === "horizontal"
    ? "flex justify-center items-center gap-1"
    : ""}`;

const UploadAreaContainer = tw.div`flex items-center align-middle gap-2
${(props: ContainerMode) =>
  props.$mode === "horizontal" ? "flex-row" : "flex-col"}
`;

export const CSV_MIME_TYPE = "text/csv";
export const CSV_EXTENSIONS = [".csv"];
/**
 * @public
 */
export const XML_MIME_TYPE = "text/xml";
/**
 * @public
 */
export const XML_EXTENSIONS = [".xml"];
export const XLS_MIME_TYPE = "application/vnd.ms-excel";
export const XLS_EXTENSIONS = [".xls", ".xlsx", ".xlsm", ".xlsb"];
export const IMAGE_MIME_TYPE = "image/*";
export const IMAGE_EXTENSIONS = [
  ".jpg" as const,
  ".jpeg" as const,
  ".png" as const,
  ".gif" as const,
  ".heic" as const,
  ".heif" as const,
  ".svg" as const,
];
export const PDF_MIME_TYPE = "application/pdf";
export const PDF_EXTENSIONS = [".pdf"];
const DOC_MIME_TYPE = "application/msword";
const DOC_EXTENSIONS = [".doc", ".docx"];
const ODT_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const ODT_EXTENSIONS = [".odt"];
const RTF_MIME_TYPE = "application/rtf";
const RTF_EXTENSIONS = [".rtf"];
const AUTOCAD_MIME_TYPE = "application/acad";
const AUTOCAD_EXTENSIONS = [".dwg", ".dwt", ".dxf"];
const MSG_MIME_TYPE = "application/vnd.ms-outlook";
const MSG_EXTENSION = [".msg"];

export const ATTACHMENTS_MIME_TYPES = {
  [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
  [CSV_MIME_TYPE]: CSV_EXTENSIONS,
  [XLS_MIME_TYPE]: XLS_EXTENSIONS,
  [DOC_MIME_TYPE]: DOC_EXTENSIONS,
  [PDF_MIME_TYPE]: PDF_EXTENSIONS,
  [ODT_MIME_TYPE]: ODT_EXTENSIONS,
  [RTF_MIME_TYPE]: RTF_EXTENSIONS,
  [AUTOCAD_MIME_TYPE]: AUTOCAD_EXTENSIONS,
  [MSG_MIME_TYPE]: MSG_EXTENSION,
};

export const getFileType = (file?: File) => {
  return XLS_EXTENSIONS.includes(`.${file?.name.split(".").pop()}`)
    ? FileType.Excel
    : FileType.Csv;
};

export const FileUploadArea: FC<FileUploadAreaProps> = ({
  onChange,
  error,
  accept = {
    [CSV_MIME_TYPE]: CSV_EXTENSIONS,
    [XLS_MIME_TYPE]: XLS_EXTENSIONS,
  },
  includeFileDetails = true,
  className,
  multiple = false,
  mode = "vertical",
  classes,
  loading,
  disabled,
  testId = "material-dnd",
}) => {
  const intl = useIntl();
  const [file, setFile] = useState<File>();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (disabled) {
        return;
      }
      await onChange(acceptedFiles);
      if (!multiple) {
        setFile(acceptedFiles[0]);
      }
    },
    [disabled, multiple, onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    onDrop,
    maxFiles: multiple ? 10 : 1,
    multiple,
    accept,
  });

  const clearFileSelection = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      setFile(undefined);
    },
    [setFile],
  );

  return (
    <FileUploadContainer className={`${className} ${classes?.container}`}>
      <DNDContainer
        {...getRootProps()}
        data-testid={testId}
        $mode={mode}
        className={classes?.dndContainer}
      >
        <input {...getInputProps()} />
        <>
          {file && includeFileDetails ? (
            <>
              <CheckIcon />
              <StyledText>
                {isDragActive ? (
                  <StyledText>{intl.$t({ id: "DROP_FILES_HERE" })}</StyledText>
                ) : (
                  <FormattedMessage id="FILE_UPLOADED" />
                )}
              </StyledText>
              <FileInfo>
                <FileName>{file.name}</FileName>
                <DeleteFile onClick={clearFileSelection}>
                  <DeleteOutline />
                </DeleteFile>
              </FileInfo>
            </>
          ) : (
            <UploadAreaContainer
              $mode={mode}
              className={classes?.uploadContainer?.container}
            >
              <UploadIcon className={classes?.uploadContainer?.image} />
              <TextContainer
                className={classes?.uploadContainer?.text}
                $mode={mode}
              >
                {isDragActive ? (
                  <StyledText>{intl.$t({ id: "DROP_FILES_HERE" })}</StyledText>
                ) : (
                  <>
                    <StyledText>
                      <FormattedMessage id="DRAG_AND_DROP_FILE" />
                    </StyledText>
                    <StyledText>
                      <FormattedMessage id="OR" />
                    </StyledText>
                  </>
                )}
              </TextContainer>
              <PrimaryButton
                loading={loading}
                className={classes?.uploadContainer?.button}
                disabled={disabled}
              >
                <FormattedMessage id="BROWSE_FILES" />
              </PrimaryButton>
            </UploadAreaContainer>
          )}
        </>
      </DNDContainer>
      <If isTrue={error}>
        <InfoText type="error" title="UPLOAD_ERROR" body="UPLOAD_ERROR_TEXT" />
      </If>
    </FileUploadContainer>
  );
};
