import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import {
  CustomFileFormat,
  IntegrationType,
  SourceSystem,
} from "@/generated/graphql";
import { DeleteOutline } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../button/IconButton";
import { If } from "../if/If";
import { Select } from "../select/components/single/Select";

export type FileSourceType = IntegrationType | string;

export const PASTE_TABLE = "PASTE_TABLE";

const Option = tw.div`
  grid w-full grid-flow-col items-center justify-between h-10 pl-2
`;

type FileSourceOption = {
  label: string;
  value: FileSourceType;
  isCustom?: boolean;
};

type FileSourceSelectorProps = {
  onChange: (value: FileSourceType) => void;
  selectedFiles?: string[];
  value: string;
  disabled?: boolean;
  customFileFormats?: CustomFileFormat[];
  onRemoveCallback: (option: FileSourceOption) => void;
};

export const FileSourceSelector: FC<FileSourceSelectorProps> = ({
  onChange,
  value,
  selectedFiles,
  disabled,
  customFileFormats,
  onRemoveCallback,
}) => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { project } = useProject();

  const shouldAllowFoundationImport = useMemo(
    () =>
      connectedSourceSystem === SourceSystem.Foundation &&
      project?.externalProjects.some(
        (ep) => ep.sourceSystem === SourceSystem.Foundation,
      ),
    [connectedSourceSystem, project?.externalProjects],
  );

  const fileSources = useMemo<FileSourceOption[]>(() => {
    const integrationTypes = [];
    if (shouldAllowFoundationImport) {
      integrationTypes.push({
        value: IntegrationType.Foundation,
        label: intl.$t({ id: "INTEGRATION_FOUNDATION" }),
      });
    }
    integrationTypes.push(
      ...[
        {
          value: IntegrationType.Oncenter,
          label: intl.$t({ id: "INTEGRATION_TYPE_ON_CENTER" }),
        },
        {
          value: IntegrationType.Edge,
          label: intl.$t({ id: "INTEGRATION_TYPE_EDGE" }),
        },
      ],
    );

    const customFileFormat = {
      value: IntegrationType.Custom,
      label: intl.$t({ id: "CUSTOM_FILE" }),
    };

    const myCustomFormats =
      customFileFormats?.map((format) => ({
        value: format.id,
        label: format.name,
        isCustom: true,
      })) || [];

    let files: FileSourceOption[] = [];
    if (selectedFiles?.length) {
      files = selectedFiles.map((selectedFile) => ({
        value: selectedFile,
        label: selectedFile,
      }));
    }

    const pasteTable = {
      value: PASTE_TABLE,
      label: intl.$t({ id: "COPY_PASTE_TABLE" }),
    };

    return [
      ...integrationTypes,
      ...myCustomFormats,
      customFileFormat,
      pasteTable,
      ...files,
    ];
  }, [intl, selectedFiles, customFileFormats, shouldAllowFoundationImport]);

  const isCustomOption = useCallback(
    (option: FileSourceOption) => {
      return fileSources.find((f) => f.value === option.value)?.isCustom;
    },
    [fileSources],
  );

  return (
    <Select
      value={value}
      testId="source-selector"
      className="w-full"
      placeholder={intl.$t({ id: "SELECT_MATERIALS_SOURCE" })}
      onChange={(value) => onChange(value as FileSourceType)}
      options={fileSources}
      getLabel={(option) => option.label}
      getValue={(option) => option.value}
      customRender={(option) => (
        <Option>
          <If isTrue={isCustomOption(option)}>
            {option.label}
            <IconButtonBorderless onClick={() => onRemoveCallback(option)}>
              <DeleteOutline />
            </IconButtonBorderless>
          </If>
          <If isTrue={!isCustomOption(option)}>{option.label}</If>
        </Option>
      )}
      disabled={disabled}
    />
  );
};
