import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import {
  defaultTableItemSelectionClassNames,
  greenTableItemSelectionClassNames,
} from "@/common/components/searchable-list/utils";
import { ReleaseReceipts } from "@/contractor/pages/home/release/pages/release-details/ReleaseReceipts";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemList } from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { AssetDate } from "../../../../../../../../../../common/components/asset-date/AssetDate";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { InvoiceReleaseEditInfo } from "../invoice-edit-info/InvoiceReleaseEditInfo";
import { useInvoiceReleaseConfiguration } from "./InvoiceReleaseConfiguration";
import { InvoiceReleaseItemsFilterHeader } from "./InvoiceReleaseItemsFilterHeader";
import { InvoicesCard } from "./InvoicesCard";

const NotesCard = tw(AssetsCard)`my-[1px] lg:border-0 mt-3 overflow-visible`;
const ReceiptsCard = tw(ReleaseReceipts)`my-[1px] lg:border-0 overflow-visible`;

type Props = {
  readonly?: boolean;
};

export const InvoiceReleaseItems: FC<Props> = () => {
  const { release } = useRelease();
  const { showOnlyInvoicedItems } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const intl = useIntl();

  const getImageDetails = useCallback((url: string, createDate?: number) => {
    return <AssetDate date={createDate} className="mt-1" />;
  }, []);

  const items = useMemo(
    () =>
      release?.items
        .filter((i) => !showOnlyInvoicedItems || !!i.invoiceItems?.[0])
        .map((item) => {
          const estimatedItems = item.projectItem?.estimatedItems.filter(
            (ei) => ei.zone?.id === item.zone?.id,
          );
          return {
            ...{
              ...item.projectItem,
              material: {
                ...item.projectItem?.material,
                material: {
                  ...item.projectItem?.material.material,
                  name: item.name,
                },
              },
              estimatedItems,
            },
            ...item,
          } as ExpandedReleaseItem;
        }) ?? [],
    [release?.items, showOnlyInvoicedItems],
  );
  const configuration = useInvoiceReleaseConfiguration(
    release,
    matchedOrderViewState !== MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
    items,
  );
  const itemFn = useCallback(
    (item: ExpandedReleaseItem) => ({
      className: item.invoiceItems?.[0]?.id
        ? matchedOrderViewState === MatchedOrderViewState.EDIT_INVOICE_COVERAGES
          ? defaultTableItemSelectionClassNames
          : greenTableItemSelectionClassNames
        : "bg-gray-100 first:mt-0",
    }),
    [matchedOrderViewState],
  );

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseItemsZoneProvider items={items}>
      <InvoiceReleaseItemsFilterHeader />
      <ReleaseItemList
        columns={configuration}
        classNames={{
          header: "-top-6 bg-gray-100 -ml-1 z-10",
          category: "top-3",
          subCategory: (groupedByZones: boolean) =>
            groupedByZones ? "top-11" : "top-3",
        }}
        itemFn={itemFn}
      />
      <InvoiceReleaseEditInfo />
      <NotesCard
        headerClassName="bg-blue-100"
        title={intl.$t({ id: "NOTES_AND_ATTACHMENTS" })}
        assets={release.instructions?.assets || []}
        expanded={false}
        imageDetails={getImageDetails}
        description={release.description || undefined}
      />
      <QuoteDocumentPanel
        readonly
        quoteDocument={release.quoteDocument}
        quote={release.quote}
      />
      <NoteDocumentPanel readonly noteDocument={release.noteDocument} />
      <ReceiptsCard release={release} prePaid={false} />
      <ReleaseDeliverySlips
        release={release}
        deliverySlips={release.deliverySlips}
      />
      <InvoicesCard expanded />
    </ReleaseItemsZoneProvider>
  );
};
