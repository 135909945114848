import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useAddNoteItems } from "@/common/hooks/add-missing-items-to-order/useAddNoteItems";
import { useInstruction } from "@/common/hooks/useInsruction";
import { routes } from "@/config/routes";
import { useNoteDocumentItems } from "@/contractor/pages/home/common/note-document/hooks/useNoteDocumentItems";
import { useNoteDocument } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useRfq } from "@/contractor/pages/home/rfq/hooks/useRfq";
import { ChevronLeft } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useRfqFromNote } from "../../hooks/useRfqFromNote";
import { useCreateRfqFromNoteConfig } from "./CreateRfqFromNote.config";
import { CreateRfqFromNoteFormValues } from "./CreateRfqFromNoteForm";
import { CreateRfqFromNoteHeader } from "./CreateRfqFromNoteHeader";

const Container = tw.div`bg-gray-100 rounded-3xl pt-4 px-4 h-fit overflow-y-scroll`;
const Header = tw.div`grid pl-1 text-base font-medium items-center mb-1`;
const InnerContainer = tw.div`flex flex-col h-full`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const HeaderGroup = tw.div`grid grid-cols-[auto_auto_1fr] w-full place-content-center place-items-center gap-1 mb-4`;
const BackButton = tw.button`grid place-items-center bg-blue-800 rounded-md text-white`;
const ButtonGroup = tw.div`grid grid-flow-col w-fit gap-2 justify-self-end pr-2`;
const NewRfqHeaderGroup = tw(HeaderGroup)`grid-cols-[auto_1fr]`;

export const CreateRfqFromNoteDetails: FC = () => {
  const { noteDocument, loading } = useNoteDocument();
  const { findMaterialByName } = useTableHelpers();
  const spreadsheetViewColumns = useCreateRfqFromNoteConfig();
  const { rfq, loading: loadingRfq } = useRfq();
  const { watch, setValue, getValues } =
    useFormContext<CreateRfqFromNoteFormValues>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { wasDocumentReplaced, loading: updating } = useRfqFromNote();
  const { addNoteItems } = useAddNoteItems();
  const { saveInstruction } = useInstruction();

  const projectId = watch("projectId");

  useSetCurrentProjectId(projectId);

  useEffect(() => {
    setValue("instructions", {
      text: rfq?.instructions?.text ?? "",
      assetUrls: rfq?.instructions?.assets.map((asset) => asset.url) ?? [],
    });
  }, [
    rfq?.id,
    rfq?.includeServices,
    rfq?.instructions?.assets,
    rfq?.instructions?.text,
    setValue,
  ]);

  useEffect(() => {
    setValue("businessLocationId", noteDocument?.orgLocation?.id ?? "");
    setValue(
      "projectId",
      getValues("projectId") ||
        rfq?.project?.id ||
        searchParams.get("projectId") ||
        "",
    );
    setValue(
      "vendorIds",
      rfq?.targetSellersOrgLocations.map((v) => v.id) ?? [],
    );
  }, [
    noteDocument,
    setValue,
    searchParams,
    rfq,
    loadingRfq,
    loading,
    getValues,
  ]);
  const { noteDocumentItems } = useNoteDocumentItems(noteDocument);

  const tableItems = useMemo(() => {
    if (rfq?.items && rfq.items.length && !wasDocumentReplaced) {
      return rfq.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => {
          const matchingOrgMaterial = findMaterialByName(
            item.projectItem?.material.material.name ?? item.description ?? "",
          );

          return {
            id: item.id,
            name: item.description ?? "",
            material: matchingOrgMaterial,
            costCode:
              item?.costCode?.code ??
              matchingOrgMaterial?.costCode?.code ??
              undefined,
            notes: item.instructions?.text ?? "",
            UOM:
              item?.projectItem.estimateUom?.pluralDescription ??
              item?.projectItem.estimateUom.mnemonic,
            quantityDecimal: item.quantityDecimal ?? "",
          };
        });
    }

    return noteDocumentItems.map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.name ?? "");

      return {
        id: item.id,
        name: item.name ?? "",
        material: matchingOrgMaterial,
        costCode: item?.costCode ?? matchingOrgMaterial?.costCode ?? undefined,
        UOM: item?.UOM ?? undefined,
        quantityDecimal: item.quantityDecimal ?? "",
      };
    });
  }, [rfq?.items, wasDocumentReplaced, noteDocumentItems, findMaterialByName]);

  const handleClick = useCallback(() => {
    if (rfq) {
      navigate(generatePath(routes.rfqById, { id: rfq.id }));
    } else {
      navigate(generatePath(routes.quotes));
    }
  }, [navigate, rfq]);

  return (
    <Container>
      <If isTrue={rfq}>
        <HeaderGroup>
          <BackButton onClick={handleClick}>
            <ChevronLeft />
          </BackButton>
          <FormattedMessage id="EDIT_RFQ" tagName={Header} />
          <ButtonGroup>
            <OutlinedButton $small onClick={() => addNoteItems()}>
              <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_NOTE" />
            </OutlinedButton>
          </ButtonGroup>
        </HeaderGroup>
      </If>
      <If isTrue={!rfq}>
        <NewRfqHeaderGroup>
          <FormattedMessage id="NEW_RFQ" tagName={Header} />
          <ButtonGroup>
            <OutlinedButton $small onClick={() => addNoteItems()}>
              <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_NOTE" />
            </OutlinedButton>
          </ButtonGroup>
        </NewRfqHeaderGroup>
      </If>
      <CreateRfqFromNoteHeader />
      <InnerContainer>
        <SpreadSheetView>
          <SpreadSheetTable
            items={tableItems}
            columns={spreadsheetViewColumns}
            saving={loading || updating}
            height="550px"
          />
        </SpreadSheetView>
        <If isTrue={projectId}>
          <Instructions
            instruction={rfq?.instructions}
            projectId={projectId}
            saveInstruction={saveInstruction}
          />
        </If>
      </InnerContainer>
    </Container>
  );
};
