import Decimal from "decimal.js";
import { FC } from "react";
import { BsCheck, BsExclamation } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useExpandableFooterStore } from "../../../../../../../../common/components/footer/stores/useExpandableFooterStore";
import { If } from "../../../../../../../../common/components/if/If";
import { Price } from "../../../../../../../../common/components/price/Price";
import { TOTAL_PRICE_DECIMAL_POINTS } from "../../../../../../../../common/const";
import { useSelectedInvoiceItems } from "../../hooks/useSelectedInvoiceItems";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";

export const ORDER_TOTAL_PANEL_WIDTH = 86;

const Container = tw.div`flex flex-row mt-2 justify-start items-center h-6 mr-2`;
const TotalPanel = tw.div`flex flex-col text-center text-2xs min-w-20 items-center`;
const HorizontalDivider = tw.div`border-l-0 border-r-[1px] h-[70%] border-dashed border-black mx-3`;
const Placeholder = tw.div``;
const TotalExtendedPanel = tw.div`flex bg-blue-250 h-12 rounded-xl ml-1 px-2 items-center`;
const CheckmarkCircleGreen = tw(
  BsCheck,
)`bg-green-600 fill-green-600 text-xl rounded-full m-0 fill-white ml-1.5`;
const ExclamationCircleRed = tw(
  BsExclamation,
)`bg-red-500 fill-red-500 text-xl rounded-full m-0 fill-white ml-1.5`;

type Props = {
  displayExtended: boolean;
};

export const InvoiceFooterTotals: FC<Props> = ({ displayExtended }) => {
  const { invoice } = useInvoiceVerification();
  const { expanded } = useExpandableFooterStore();
  const { selectedInvoiceItemsTotal } = useSelectedInvoiceItems();
  const { selectedRelease, matchedOrderViewState } = useInvoiceMatchedOrder();

  if (expanded) {
    return <Placeholder />;
  }

  return (
    <Container>
      <TotalPanel
        className={displayExtended ? "mr-2.5" : ""}
        style={{ width: ORDER_TOTAL_PANEL_WIDTH }}
      >
        <If
          isTrue={
            matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER
              ? !!selectedRelease
              : !!invoice?.release
          }
        >
          <FormattedMessage id="ORDER_TOTAL" />
          <Price
            price={
              matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER
                ? (selectedRelease?.total ?? 0)
                : (invoice?.release?.total ?? 0)
            }
            className="text-base font-medium"
            maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
          />
          <FormattedMessage id="WITH_TAX_AND_FEES" />
        </If>
      </TotalPanel>
      <If isTrue={displayExtended}>
        <TotalExtendedPanel>
          <TotalPanel>
            <FormattedMessage id="INVOICED_ITEMS" />
            <Price
              price={selectedInvoiceItemsTotal}
              className="text-base font-medium"
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
            />
            <FormattedMessage id="WITH_TAX_AND_FEES" />
          </TotalPanel>
          <If
            isTrue={new Decimal(invoice?.total ?? 0).equals(
              selectedInvoiceItemsTotal,
            )}
          >
            <CheckmarkCircleGreen />
          </If>
          <If
            isTrue={
              !new Decimal(invoice?.total ?? 0).equals(
                selectedInvoiceItemsTotal,
              )
            }
          >
            <ExclamationCircleRed />
          </If>
          <HorizontalDivider />
          <TotalPanel>
            <FormattedMessage id="INVOICE_TOTAL" />
            <Price
              price={invoice?.total ?? 0}
              className="text-base font-medium"
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
            />
            <FormattedMessage id="WITH_TAX_AND_FEES" />
          </TotalPanel>
        </TotalExtendedPanel>
      </If>
      <If isTrue={!displayExtended}>
        <HorizontalDivider className="ml-0" />
        <TotalPanel>
          <FormattedMessage id="INVOICE_TOTAL" />
          <Price
            price={invoice?.total ?? 0}
            className="text-base font-medium"
            maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
          />
          <FormattedMessage id="WITH_TAX_AND_FEES" />
        </TotalPanel>
      </If>
    </Container>
  );
};
