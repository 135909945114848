import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Loader } from "@/common/components/loader/Loader";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { MAX_QUANTITY_DECIMALS, MIN_QUANTITY_DECIMALS } from "@/common/const";
import { DateView } from "@/common/utils/dates/DateView";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { routes } from "@/config/routes";
import { AssetContext, AssetType } from "@/generated/graphql";
import { CheckCircleOutline } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { usePriceCalculation } from "../../hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import { useReleaseItemInvoiceItems } from "./hooks/useReleaseItemInvoiceItems";

const InvoicedSoFar = tw.span`grid grid-flow-col items-center mr-2`;
const CheckIcon = tw(
  CheckCircleOutline,
)`absolute left-5 top-1/4 items-center text-xl text-green-600 mr-1.5`;
const ItemNameTitle = tw.div`text-xs font-thin mx-6`;
const ItemName = tw.div`text-xl font-medium mx-6`;
const Total = tw.div`font-medium text-lg`;
const Row = tw.div`flex h-20 items-center pl-3 pr-6 self-stretch`;
const Header = tw(Row)`bg-blue-100 mt-6 pl-6`;
const TitleCol = tw.div`flex-1 flex flex-col text-xs overflow-hidden text-ellipsis`;
const QuantityCol = tw.div`flex flex-col text-sm font-medium border-r-1 mr-4 border-r border-r-gray-300 pr-5 text-right w-24`;
const PriceCol = tw.div`flex flex-col text-sm font-medium text-right w-25`;
const SmallText = tw.div`text-2xs font-light my-0.5`;
const Items = tw.div`flex flex-1 flex-col pt-3 overflow-y-scroll gap-2`;

type Props = {
  releaseId: string | undefined;
  item: ExpandedReleaseItem;
};

export const ReleaseItemInvoicedSoFarSidePanel: FC<Props> = ({
  item,
  releaseId,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { calcExtPrice } = usePriceCalculation();

  const invoicedQuantity = useMemo(
    () => new Decimal(item.invoicedQuantity),
    [item.invoicedQuantity],
  );

  const { fetchInvoiceItems, invoiceItems, loadingInvoiceItems } =
    useReleaseItemInvoiceItems({
      releaseId,
      itemId: item.id,
    });

  if (invoicedQuantity.equals(0)) {
    return (
      <InvoicedSoFar>
        <If
          isTrue={invoicedQuantity.greaterThanOrEqualTo(item.quantityDecimal)}
        >
          <Tooltip id="invoiced-quantity-tooltip" element={<CheckIcon />}>
            <FormattedMessage id="ORDER_INVOICED_COMPLETELY" />
          </Tooltip>
        </If>
        <ValueUnit
          value={invoicedQuantity.toNumber()}
          uom={item.uom}
          renderAsPrice={isLumpSumItem(item)}
        />
      </InvoicedSoFar>
    );
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <InvoicedSoFar
          onClick={() => {
            fetchInvoiceItems();
            togglePanel(true);
          }}
          className="cursor-pointer"
        >
          <If
            isTrue={invoicedQuantity.greaterThanOrEqualTo(item.quantityDecimal)}
          >
            <Tooltip id="invoiced-quantity-tooltip" element={<CheckIcon />}>
              <FormattedMessage id="ORDER_INVOICED_COMPLETELY" />
            </Tooltip>
          </If>
          <ValueUnit
            value={invoicedQuantity.toNumber()}
            uom={item.uom}
            classNames={{ container: "text-blue-500", footer: "text-blue-500" }}
            renderAsPrice={isLumpSumItem(item)}
          />
        </InvoicedSoFar>
      )}
      content={(togglePanel) => (
        <OverlayPanel
          title={intl.$t({ id: "ORDER_ITEM_INVOICED" })}
          onCancel={() => togglePanel(false)}
          className="px-0"
        >
          <FormattedMessage id="ITEM_NAME" tagName={ItemNameTitle} />
          <ItemName>{item.name}</ItemName>
          <Header>
            <TitleCol>
              <FormattedMessage id="TOTAL" tagName={Total} />
            </TitleCol>
            <QuantityCol>
              <FormattedNumber
                value={invoicedQuantity.toNumber()}
                maximumFractionDigits={MAX_QUANTITY_DECIMALS}
                minimumFractionDigits={MIN_QUANTITY_DECIMALS}
              />
              <SmallText>
                {item.uom?.mnemonic ?? item.uom?.pluralDescription}
              </SmallText>
            </QuantityCol>
            <PriceCol>
              <NotNullableRenderer value={item.unitPrice}>
                <Price
                  price={calcExtPrice(
                    invoicedQuantity.toNumber(),
                    item.unitPrice,
                  )}
                  className="mb-0.5"
                />
              </NotNullableRenderer>
              <FormattedMessage id="EXT_PRICE" tagName={SmallText} />
            </PriceCol>
          </Header>
          <Loader loading={loadingInvoiceItems}>
            <Items>
              {invoiceItems.map((invoiceItem, index) => (
                <Row key={invoiceItem.id}>
                  <AssetItem
                    key={invoiceItem.id}
                    asset={
                      invoiceItem.invoice.documents[
                        invoiceItem.invoice.documents.length - 1
                      ].asset ?? {
                        url: "",
                        type: AssetType.Image,
                        context: AssetContext.Invoice,
                      }
                    }
                    index={index}
                    readonly
                  />
                  <TitleCol className="ml-3">
                    <LinkLike
                      onClick={() => {
                        navigate(
                          generatePath(routes.invoiceVerification, {
                            invoiceId: invoiceItem.invoice.id,
                          }),
                        );
                      }}
                    >
                      {intl.$t({ id: "INVOICE" })} #{invoiceItem.invoice.number}
                    </LinkLike>
                    <DateView date={invoiceItem.invoice.createdAt} />
                  </TitleCol>
                  <QuantityCol>
                    <FormattedNumber
                      value={Number(invoiceItem.quantity)}
                      maximumFractionDigits={MAX_QUANTITY_DECIMALS}
                      minimumFractionDigits={MIN_QUANTITY_DECIMALS}
                    />
                    <SmallText>
                      {item.uom?.mnemonic ?? item.uom?.pluralDescription}
                    </SmallText>
                  </QuantityCol>
                  <PriceCol>
                    <NotNullableRenderer value={item.unitPrice}>
                      <Price
                        price={calcExtPrice(
                          invoiceItem.quantity,
                          item.unitPrice,
                        )}
                        className="mb-0.5"
                      />
                    </NotNullableRenderer>
                    <FormattedMessage id="EXT_PRICE" tagName={SmallText} />
                  </PriceCol>
                </Row>
              ))}
            </Items>
          </Loader>
        </OverlayPanel>
      )}
    />
  );
};
