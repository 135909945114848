import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useSelectableCostTypesConfiguration } from "./SelectableCostTypes.configuration";

type Props = {
  selectedCostTypeId: string | null;
  setSelectedCostTypeId: (costCodeId: string | null) => void;
};

export const SelectableCostTypeList: FC<Props> = ({
  selectedCostTypeId,
  setSelectedCostTypeId,
}) => {
  const intl = useIntl();
  const { costTypes, loading } = useCostTypes();
  const { configuration } = useSelectableCostTypesConfiguration({
    selectedItem: selectedCostTypeId,
  });

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      itemSelection={{
        selectedItemId: selectedCostTypeId,
        setSelectedItemId: setSelectedCostTypeId,
        type: "single",
      }}
      searchBarTitle={intl.$t({ id: "SELECT_COST_TYPES_TO_IMPORT" })}
      searchKeys={["code", "description"]}
      items={costTypes}
      loadingItems={loading}
    />
  );
};
