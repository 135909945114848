import { LinkLike } from "@/common/components/link-like/LinkLike";
import { LocationSelector } from "@/common/components/location-selector/LocationSelector";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  SupportedFormats,
  UploadAsset,
} from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { routes } from "@/config/routes";
import { AssetContext, AuthorizationStatus } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../invoice-verification/providers/InvoiceVerificationProvider";
import { useSplitInvoiceDocument } from "../../../hooks/useSplitInvoiceDocument";
import { useInvoiceCreation } from "../../../providers/InvoiceCreationProvider";
import { useInvoices } from "../../../providers/InvoicesProvider";
import { useSplittingInvoicesWizard } from "../../splitting-invoices/SplittingInvoicesWizardProvider";

export enum InvoiceFormType {
  ADD,
  CORRECTION,
}

type Props = {
  onClose: () => void;
  type: InvoiceFormType;
  releaseId?: string;
};

const Label = tw.div`flex flex-row justify-between font-medium text-base mb-2`;
const Container = tw.div`flex flex-col gap-4`;

const InvoiceFormWithProvider: FC<Props> = ({ onClose, type, releaseId }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { splitInvoiceDocument } = useSplitInvoiceDocument();
  const { createInvoices } = useInvoices();
  const { assets } = useUploadAssets();
  const { openWizard } = useSplittingInvoicesWizard();
  const { updateInvoice, invoice } = useInvoiceVerification();
  const { locationId, setLocationId } = useInvoiceCreation();

  const save = useCallback(async () => {
    if (!assets || !locationId) {
      return;
    }
    const pageRanges = await splitInvoiceDocument({
      assetUrl: assets[0].url,
    });
    if (!pageRanges) {
      return;
    }
    // automatically create invoices if there is only one page range
    if (pageRanges.length === 1 && pageRanges[0].start === 1) {
      const invoices = await createInvoices({
        assetUrl: assets[0].url,
        pageRanges,
        orgLocationId: locationId,
      });

      if (releaseId && invoices) {
        await Promise.all(
          invoices?.map((invoice) =>
            updateInvoice({
              id: invoice.id,
              releaseId: releaseId,
            }),
          ),
        );
      }

      if (invoices && invoices.length > 0) {
        const firstInvoice = invoices[0];
        navigate(
          generatePath(routes.invoiceVerification, {
            invoiceId: firstInvoice.id,
          }),
        );
      }
    } else {
      openWizard({
        initialPage: 0,
        urls: assets.map((asset) => asset.url),
        pageRanges,
        locationId,
      });
    }
    onClose();
  }, [
    assets,
    createInvoices,
    locationId,
    navigate,
    onClose,
    openWizard,
    releaseId,
    splitInvoiceDocument,
    updateInvoice,
  ]);

  const saveCorrection = useCallback(async () => {
    if (!!invoice && !!assets[0]) {
      await updateInvoice(
        {
          id: invoice?.id,
          assetUrl: assets[0].url,
        },
        { includeDocuments: true },
      );
      onClose();
    }
  }, [invoice, assets, updateInvoice, onClose]);

  return (
    <OverlayPanel
      title={intl.$t({ id: "ADD_INVOICE" })}
      onSave={type === InvoiceFormType.ADD ? save : saveCorrection}
      onCancel={onClose}
      saveLabel={intl.$t({ id: "PROCEED" })}
      disableSave={!assets?.length || !locationId}
    >
      <Container>
        <Label>
          <FormattedMessage
            id={
              type === InvoiceFormType.ADD
                ? "NEW_INVOICE_DESCRIPTION"
                : "INVOICE_CORRECTION_DESCRIPTION"
            }
          />
          <Tooltip
            id="new-invoice-description"
            element={
              <LinkLike>
                <InfoOutlined />
              </LinkLike>
            }
          >
            <FormattedMessage id="NEW_INVOICE_DESCRIPTION_TOOLTIP" />
          </Tooltip>
        </Label>
        <LocationSelector
          placeholder={intl.$t({ id: "INVOICE_LOCATION" })}
          locationId={locationId}
          setLocationId={setLocationId}
          disabledFn={(location) =>
            location.permissions?.createInvoice !==
            AuthorizationStatus.Authorized
          }
          disabledTooltip={intl.$t({ id: "ORG_LOCATION_INVOICE_PERMISSION" })}
        />
        <UploadAsset
          accept={{
            [PDF_MIME_TYPE]: PDF_EXTENSIONS,
          }}
        >
          <SupportedFormats>
            <FormattedMessage id="INVOICE_SUPPORTED_FORMATS" />
          </SupportedFormats>
        </UploadAsset>
      </Container>
    </OverlayPanel>
  );
};

export const InvoiceForm: FC<Props> = ({ onClose, type, releaseId }) => {
  return (
    <UploadAssetProvider context={AssetContext.Invoice}>
      <InvoiceFormWithProvider
        onClose={onClose}
        type={type}
        releaseId={releaseId}
      />
    </UploadAssetProvider>
  );
};
