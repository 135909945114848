import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AdminOrgPreferredVendorsDocument,
  AdminOrgPreferredVendorsQuery,
  UpdateVendorOrgInput,
  useUpdateVendorOrgMutation,
} from "@/generated/graphql";

export const useUpdateVendorOrg = () => {
  const { setError } = useGlobalError();

  const [updateVendorOrgMutation, { loading: updating }] =
    useUpdateVendorOrgMutation();

  const updateVendorOrg = async (input: UpdateVendorOrgInput) => {
    try {
      const { errors } = await updateVendorOrgMutation({
        variables: { input },
        update: (cache, { data }) => {
          const queryVendorOrgs =
            cache.readQuery<AdminOrgPreferredVendorsQuery>({
              query: AdminOrgPreferredVendorsDocument,
            });
          if (queryVendorOrgs && data?.updateVendorOrg.id) {
            cache.writeQuery({
              query: AdminOrgPreferredVendorsDocument,
              data: {
                orgPreferredVendors:
                  queryVendorOrgs.orgPreferredVendorsConnection.edges.map(
                    (edge) => {
                      if (edge.node.sellerOrgLocation.org.id === input.id) {
                        return {
                          ...edge,
                          sellerOrgLocation: {
                            ...edge.node.sellerOrgLocation,
                            org: {
                              ...edge.node.sellerOrgLocation.org,
                              logoImageUrl: input.photoUrl,
                            },
                          },
                        };
                      }
                      return edge;
                    },
                  ),
              },
            });
          }
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    updateVendorOrg,
    updating,
  };
};
