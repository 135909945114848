import { InlineNotesAndTerms } from "@/common/components/inline-notes-and-terms/InlineNotesAndTerms";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import { DISTRIBUTOR_RELEASE_READ_ONLY_STATUSES } from "@/distributor/common/constants";
import {
  AssetContext,
  AssetFieldsFragment,
  DistributorReleaseFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useDistributorRelease } from "../../providers/DistributorReleaseProvider";

type Props = {
  release: DistributorReleaseFieldsFragment;
};

const DistributorReleaseInlineNotesAndTermsWithProvider: FC<Props> = ({
  release,
}) => {
  const { updateVendorRelease } = useDistributorRelease();
  const { assets } = useUploadAssets();

  const save = async ({
    newAssets,
    notes,
  }: { newAssets?: AssetFieldsFragment[]; notes?: string } = {}) => {
    await updateVendorRelease?.({
      notes: notes || undefined,
      assetUrls: (newAssets || assets).map((asset) => asset.url),
    });
  };

  const readonly = useMemo(
    () => DISTRIBUTOR_RELEASE_READ_ONLY_STATUSES.includes(release.status),
    [release.status],
  );

  return (
    <InlineNotesAndTerms
      save={save}
      notes={release.notes}
      readonly={readonly}
    />
  );
};

export const DistributorReleaseInlineNotesAndTerms: FC<Props> = (props) => {
  const { release } = props;
  return (
    <UploadAssetProvider
      initialAssets={release?.assets}
      context={AssetContext.Instruction}
      projectId={release?.project?.id}
    >
      <DistributorReleaseInlineNotesAndTermsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
