import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import {
  Container,
  Content,
  Row,
  Title,
} from "@/common/components/integration-batch-options/IntegrationBatchOptions.styles";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { IntegrationType } from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useExportReceipt } from "../../providers/ExportReceiptProvider";

type Props = {
  defaultClosed?: boolean;
};

export const ReceiptConnectionOptions: FC<Props> = ({
  defaultClosed = false,
}) => {
  const [collapsed, setCollapsed] = useState(defaultClosed);
  const { postingDate, setPostingDate } = useExportReceipt();

  return (
    <SourceSystemWrapper
      allowedIntegrationTypes={[IntegrationType.Foundation]}
      allowedFeatures={[IntegrationFeature.ReceiptsFileBased]}
    >
      <Container>
        <Title>
          <FormattedMessage id="OPTIONS" />
          <GroupToggle
            isOpened={!collapsed}
            onClick={() => setCollapsed((v) => !v)}
          />
        </Title>
        <Content $opened={!collapsed}>
          <Row>
            <FormattedMessage id="POSTING_DATE" />
            <DatePicker
              date={postingDate}
              onChange={(newValue) => {
                setPostingDate(newValue);
              }}
              className="bg-white"
              required
            />
          </Row>
        </Content>
      </Container>
    </SourceSystemWrapper>
  );
};
