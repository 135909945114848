import { MAX_ITEMS_PER_PAGE } from "@/common/const";
import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  ProjectListOptionFieldsFragment,
  ProjectListOptionsDocument,
  ProjectListOptionsQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const sortByName = (a: { name: string }, b: { name: string }) => {
  return a.name.localeCompare(b.name);
};

interface ProjectListOptionsStore {
  projects: ProjectListOptionFieldsFragment[];
  setProjects: (projects: ProjectListOptionFieldsFragment[]) => void;
  fetchProjects: () => Promise<void>;
  loading: boolean;
  error: Error | undefined;
}

export const useProjectListOptionsStore = create<ProjectListOptionsStore>()(
  devtools((set, get) => ({
    projects: [],
    setProjects: (projects) =>
      set({
        projects: projects.sort(sortByName),
      }),
    loading: false,
    error: undefined,
    fetchProjects: async () => {
      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }
      if (get().projects.length > 0) {
        return;
      }
      set({ loading: true });
      try {
        const { data } = await client.query<ProjectListOptionsQuery>({
          query: ProjectListOptionsDocument,
          variables: {
            first: MAX_ITEMS_PER_PAGE,
            filter: {
              deleted: false,
            },
          },
        });
        set({
          projects:
            data?.projects.edges
              .flatMap((edge) => edge.node)
              .sort(sortByName) ?? [],
        });
      } catch (error) {
        set({ error: error as ApolloError });
      } finally {
        set({ loading: false });
      }
    },
  })),
);
