import {
  Release,
  ReleaseStatus,
  ServiceType,
  TransactionKind,
} from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

const MISSING_TRANSLATION = "MISSING_TRANSLATION";

export type ReleaseWithType = {
  includeServices?: ServiceType[];
  type: Pick<Release["type"], "transactionKind"> | undefined;
} | null;

type Props = {
  release?: ReleaseWithType;
};

type OrderTypeConfig = {
  labels: {
    buttons: {
      complete: string;
      completeShort: string;
      confirm: string;
      changeDateAndTime: string;
    };
    datePicker: string;
    datePickerReadonlyLabel: string;
    backorderDateTitleLabel: string;
    confirmTitle: string;
    status: {
      [key in ReleaseStatus]: string;
    };
    uoms: string;
    orderedSoFar: string;
    orderedQuantity: string;
  };
  features: {
    includeWillCall: boolean;
    includeVendorStocking: boolean;
  };
};

export const useOrderTypesConfig = ({ release }: Props) => {
  const intl = useIntl();

  const orderTypeConfig = useMemo(() => {
    switch (release?.type?.transactionKind) {
      case TransactionKind.Purchase: {
        const isDelivery = release.includeServices?.includes(
          ServiceType.Delivery,
        );
        return {
          labels: {
            buttons: {
              complete: intl.$t({ id: "DELIVERY_CONFIRM" }),
              completeShort: intl.$t({ id: "RECEIVE" }),
              confirm: intl.$t({ id: "CONFIRM_VENDOR_DELIVERY" }),
              changeDateAndTime: intl.$t({ id: "CHANGE_DELIVERY_DATE" }),
            },
            datePicker: intl.$t({ id: "REQUESTED_FULFILLMENT_DATE" }),
            datePickerReadonlyLabel: intl.$t({
              id: isDelivery ? "DELIVERY" : "PICKUP",
            }),
            backorderDateTitleLabel: intl.$t({
              id: "SET_NEW_DELIVERY_DATE_FOR_ORDER",
            }),
            confirmTitle: intl.$t({ id: "DELIVERY_CONFIRM_TITLE" }),
            uoms: intl.$t({ id: "DELIVERY_UNITS" }),
            status: {
              [ReleaseStatus.Received]: intl.$t({
                id: "RELEASE_STATUS_RECEIVED",
              }),
            },
            orderedSoFar: intl.$t({ id: "ORDER_RECEIVED_SO_FAR" }),
            orderedQuantity: intl.$t({ id: "ORDER_RECEIVED_QUANTITY" }),
          },
          features: {
            includeWillCall: true,
            includeVendorStocking: true,
          },
        } as OrderTypeConfig;
      }
      case TransactionKind.Rental: {
        return {
          labels: {
            buttons: {
              complete: intl.$t({ id: "COMPLETE_RENTAL" }),
              completeShort: intl.$t({ id: "COMPLETE" }),
              confirm: intl.$t({ id: "CONFIRM_VENDOR_RENTAL" }),
              changeDateAndTime: intl.$t({ id: "RENTAL_CHANGE_DELIVERY_DATE" }),
            },
            datePicker: intl.$t({ id: "RENTAL_START_DATE" }),
            datePickerReadonlyLabel: intl.$t({ id: "RENTAL_START_DATE" }),
            confirmTitle: intl.$t({ id: "DELIVERY_RENTAL_CONFIRM_TITLE" }),
            backorderDateTitleLabel: intl.$t({
              id: "SET_NEW_RENTAL_DATE_FOR_ORDER",
            }),
            uoms: intl.$t({ id: "UOM" }),
            status: {
              [ReleaseStatus.Received]: intl.$t({
                id: "RELEASE_STATUS_COMPLETED",
              }),
            },
            orderedSoFar: intl.$t({ id: "ORDER_COMPLETED_SO_FAR" }),
            orderedQuantity: intl.$t({ id: "ORDER_COMPLETED_QUANTITY" }),
          },
          features: {
            includeWillCall: true,
            includeVendorStocking: false,
          },
        };
      }
      case TransactionKind.Services: {
        return {
          labels: {
            buttons: {
              complete: intl.$t({ id: "COMPLETE_SERVICE" }),
              completeShort: intl.$t({ id: "COMPLETE" }),
              confirm: intl.$t({ id: "CONFIRM_VENDOR_SERVICE" }),
              changeDateAndTime: intl.$t({
                id: "SERVICE_CHANGE_DELIVERY_DATE",
              }),
            },
            datePicker: intl.$t({ id: "SERVICE_START_DATE" }),
            datePickerReadonlyLabel: intl.$t({ id: "SERVICE_START_DATE" }),
            confirmTitle: intl.$t({ id: "DELIVERY_SERVICE_CONFIRM_TITLE" }),
            backorderDateTitleLabel: intl.$t({
              id: "SET_NEW_SERVICE_DATE_FOR_ORDER",
            }),
            uoms: intl.$t({ id: "UOM" }),
            status: {
              [ReleaseStatus.Received]: intl.$t({
                id: "RELEASE_STATUS_COMPLETED",
              }),
            },
            orderedSoFar: intl.$t({ id: "ORDER_COMPLETED_SO_FAR" }),
            orderedQuantity: intl.$t({ id: "ORDER_COMPLETED_QUANTITY" }),
          },
          features: {
            includeWillCall: false,
            includeVendorStocking: false,
          },
        };
      }
      default: {
        return {
          labels: {
            buttons: {
              complete: MISSING_TRANSLATION,
              completeShort: MISSING_TRANSLATION,
              changeDateAndTime: MISSING_TRANSLATION,
              confirm: MISSING_TRANSLATION,
            },
            datePicker: undefined,
            datePickerReadonlyLabel: MISSING_TRANSLATION,
            backorderDateTitleLabel: MISSING_TRANSLATION,
            confirmTitle: MISSING_TRANSLATION,
            uoms: intl.$t({ id: "UOM" }),
            status: {
              [ReleaseStatus.Received]: MISSING_TRANSLATION,
            },
            orderedSoFar: MISSING_TRANSLATION,
            orderedQuantity: MISSING_TRANSLATION,
          },
          features: {
            includeWillCall: false,
            includeVendorStocking: false,
          },
        };
      }
    }
  }, [intl, release?.includeServices, release?.type?.transactionKind]);

  return { orderTypeConfig };
};
