import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { format } from "date-fns";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { rowIsEmpty } from "../utils/rowIsEmpty";
import { useRenderHelpers } from "./useRenderHelpers";

export const useDeliveryDateRenderer = () => {
  const { checkReadOnly, applyClasses } = useRenderHelpers();
  const { release } = useRelease();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const rows = instance?.getData();

      if (!value && !rowIsEmpty(rows[row])) {
        textRenderer(
          instance,
          td,
          row,
          col,
          prop,
          release?.time ? format(new Date(release?.time), "MM/dd/yyyy") : "",
          cellProperties,
        );
        applyClasses(td, "htCenter htMiddle text-gray-500");
      } else {
        textRenderer(instance, td, row, col, prop, value, cellProperties);
      }

      checkReadOnly(td, row, instance, cellProperties);
    },
    [checkReadOnly, release?.time, applyClasses],
  );

  return renderer;
};
