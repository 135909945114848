import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useMemo } from "react";

export const useReleasePositions = () => {
  const { release } = useRelease();

  const allItemsHavePosition = useMemo(
    () =>
      !release?.items?.length ||
      release.items.every(
        (item) =>
          item.position !== undefined &&
          item.position !== null &&
          item.position < release.items.length,
      ),
    [release?.items],
  );

  return {
    allItemsHavePosition,
  };
};
