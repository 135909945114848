import { Loader } from "@/common/components/loader/Loader";
import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import {
  OrgMaterialsFilteredQuery,
  PartialOrgMaterialFieldsFragment,
  QueryOrgMaterialsFilter,
} from "@/generated/graphql";
import { FormControl } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { useOrgMaterials } from "./hooks/useOrgMaterials";

export type MaterialSummaryFields =
  OrgMaterialsFilteredQuery["orgMaterials"]["edges"][0]["node"];

type Props = {
  selectedOrgMaterial?: MaterialSummaryFields | null;
  setSelectedOrgMaterial: (
    material: MaterialSummaryFields | null | undefined,
  ) => void;
  includeSearchInfo?: boolean;
  label?: string;
  staticText?: boolean;
  classes?: {
    root?: string;
  };
  error?: boolean;
  filter?: QueryOrgMaterialsFilter;
};

const FieldsContainer = tw.div`
  grid ml-1 w-full py-1
`;

export const AddOrgMaterial: FC<Props> = ({
  selectedOrgMaterial,
  setSelectedOrgMaterial,
  staticText,
  classes,
  filter,
  error = false,
}) => {
  const [searchMode, setSearchMode] = useState(true);
  const [inputValue, setInputValue] = useState(
    selectedOrgMaterial?.material.name || "",
  );
  const { orgMaterials, setSearchQuery, loading } = useOrgMaterials(filter);
  useEffect(() => {
    setInputValue(selectedOrgMaterial?.material.name || "");
  }, [selectedOrgMaterial?.material.name]);

  const search = useCallback(
    (query: string) => {
      const materialInResults = orgMaterials.find(
        (material) => material.material.name === query,
      );

      if (materialInResults) {
        setSearchMode(false);
        setSelectedOrgMaterial(materialInResults);
      } else if (!searchMode) {
        setSearchMode(true);
        setSearchQuery(query);
        setSelectedOrgMaterial({
          material: {
            name: query,
          },
        } as MaterialSummaryFields);
      } else {
        setSearchQuery(query);
        setSelectedOrgMaterial({
          material: {
            name: query,
          },
        } as MaterialSummaryFields);
      }
    },
    [orgMaterials, searchMode, setSearchQuery, setSelectedOrgMaterial],
  );

  return (
    <FieldsContainer className={classes?.root}>
      <FormControl fullWidth>
        <StyledSelect
          options={orgMaterials}
          testId="material-name"
          value={selectedOrgMaterial?.id}
          serverSideFiltering={searchMode}
          getValue={(opt: PartialOrgMaterialFieldsFragment | string) =>
            typeof opt === "string" ? opt : opt.id
          }
          getLabel={(opt: PartialOrgMaterialFieldsFragment | string) =>
            typeof opt === "string" ? opt : opt.material.name
          }
          InputProps={{
            autoFocus: true,
            endAdornment: loading ? (
              <Loader loading small className="mr-2 justify-end" />
            ) : null,
          }}
          creatable
          inputValue={inputValue}
          handleInputChange={(value) => {
            search(String(value));
            setInputValue(value);
          }}
          onChange={(value) => {
            const material = orgMaterials.find(
              (material) => material.id === String(value),
            );
            setSelectedOrgMaterial(
              material ||
                ({
                  material: { name: value },
                } as MaterialSummaryFields),
            );
            if (material) {
              setInputValue(material.material.name);
            }
          }}
          staticText={staticText}
          error={error}
          size="xs"
          loading={loading}
        />
      </FormControl>
    </FieldsContainer>
  );
};
