import { InvoiceType } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { AnimatedLoading } from "../splitting-invoices/steps/AnimatedLoading";

const AnimationContainer = tw.div`w-96 h-56`;
const AnimationTextView = tw.div`flex flex-col items-center justify-center`;
const AnimationTitle = tw.div`font-medium text-xl`;
const AnimationText = tw.div``;

type Props = { type: InvoiceType; itemsCount: number };

export const InvoicesDownloadDialog = ({ type, itemsCount }: Props) => {
  return (
    <AnimationContainer>
      <AnimatedLoading
        loading
        text={
          <AnimationTextView>
            <FormattedMessage id="DOWNLOADING" tagName={AnimationTitle} />
            <FormattedMessage
              id={
                type === InvoiceType.Invoice
                  ? "INVOICES_COUNT"
                  : "RECEIPTS_COUNT"
              }
              values={{ count: itemsCount }}
              tagName={AnimationText}
            />
          </AnimationTextView>
        }
        className="mt-25 h-5/6"
      />
    </AnimationContainer>
  );
};
