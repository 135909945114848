import { Address } from "@/common/components/address/Address";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  LgFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { OrgLocationExtendedFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import { DeleteLocation } from "./DeleteLocation";
import { DisplayLocationName } from "./DisplayLocationName";

export const tableConfiguration: Array<
  GridCol<OrgLocationExtendedFieldsFragment>
> = [
  {
    wrapper: LgFixedContainer,
    header: <FormattedMessage id="LOCATION_NAME" />,
    item: ({ item }) => <DisplayLocationName location={item} />,
  },
  {
    wrapper: LgFixedContainer,
    header: <FormattedMessage id="LOCATION_ADDRESS" />,
    item: ({ item }) => <Address className="text-sm" address={item.address} />,
  },
  {
    wrapper: LgFixedContainer,
    header: <FormattedMessage id="LOCATION_STATE" />,
    position: "center",
    item: ({ item }) => item.address?.state,
  },
  {
    wrapper: LgFixedContainer,
    header: <FormattedMessage id="LOCATION_ASSIGNED_USERS" />,
    position: "center",
    item: ({ item }) => item.assignedUserCount,
  },
  {
    wrapper: SmFixedContainer,
    header: "",
    item: () => <></>,
  },
  {
    wrapper: SmFixedContainer,
    header: "",
    position: "center",
    item: ({ item }) => <DeleteLocation location={item} />,
  },
];
