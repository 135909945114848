import { NoteDocumentProvider } from "../../../common/note-document/providers/NoteDocumentProvider";
import { CreateRfqFromNoteForm } from "./create-rfq-from-note/components/CreateRfqFromNoteForm";
import { CreateRfqFromNote } from "./create-rfq-from-note/CreateRfqFromNote";

export const RfqFromNote = () => {
  return (
    <CreateRfqFromNoteForm>
      <NoteDocumentProvider>
        <CreateRfqFromNote />
      </NoteDocumentProvider>
    </CreateRfqFromNoteForm>
  );
};
