import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { AnimatedLoading } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";

import {
  EquipmentItemsDocument,
  useImportEquipmentItemsMutation,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { ImportExternalEquipmentItemsImportResults } from "../components/import-external-equipment-items/steps/ImportExternalEquipmentItemsResults";
import { ImportExternalEquipmentItemsTable } from "../components/import-external-equipment-items/steps/ImportExternalEquipmentItemsTable";
import { useImportEquipmentItemsStore } from "../stores/useImportEquipmentItemsStore";

export const useImportExternalEquipmentItemsModal = (): WizardModalPage[] => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();
  const {
    modalOpened,
    sourceSystem,
    selectedExternalEquipmentItems,
    totalExternalEquipmentItemsCount,
    setImportedEquipmentItems,
    closeModal,
  } = useImportEquipmentItemsStore();

  useEffect(() => {
    if (!modalOpened) {
      setNestedStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpened]);

  const [importEquipmentItemsMutation] = useImportEquipmentItemsMutation();
  const importSelectedEquipmentItems = useCallback(async () => {
    try {
      if (sourceSystem) {
        moveToNextNestedStep();
        const { data, errors } = await importEquipmentItemsMutation({
          variables: {
            input: {
              externalEquipmentItemIds: selectedExternalEquipmentItems,
              sourceSystem,
            },
          },
          refetchQueries: [
            {
              query: EquipmentItemsDocument,
            },
          ],
        });
        if (errors) {
          moveToPreviousNestedStep();
          setError(errors);
        }
        if (data?.importEquipmentItems) {
          setImportedEquipmentItems(data.importEquipmentItems);
          moveToNextNestedStep();
        }
      }
    } catch (error) {
      moveToPreviousNestedStep();
      setError(error);
    }
  }, [
    sourceSystem,
    selectedExternalEquipmentItems,
    importEquipmentItemsMutation,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
    setError,
    setImportedEquipmentItems,
  ]);

  return [
    {
      title: null,
      pages: [
        {
          component: <ImportExternalEquipmentItemsTable />,
          footerButtonsConfig: [
            {
              type: "text",
              text: intl.$t(
                { id: "OUT_OF_SELECTED" },
                {
                  count: selectedExternalEquipmentItems.length,
                  total: totalExternalEquipmentItemsCount,
                },
              ),
              className: "flex mr-4 items-center",
              hidden:
                selectedExternalEquipmentItems.length === 0 &&
                totalExternalEquipmentItemsCount === 0,
            },
            {
              type: "outlined",
              onClick: closeModal,
              text: intl.$t({ id: "CLOSE" }),
              className: "flex-1 mr-2",
            },
            {
              type: "primary",
              onClick: importSelectedEquipmentItems,
              text: intl.$t({ id: "IMPORT" }),
              className: "flex-1",
              disabled: selectedExternalEquipmentItems.length === 0,
            },
          ],
        },
        {
          component: (
            <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
          ),
          hideFooter: true,
          classNames: {
            wrapper: "mb-0",
          },
        },
        {
          component: (
            <CompletedStep text={intl.$t({ id: "IMPORT_COMPLETED" })} />
          ),
          onModalClose: moveToNextNestedStep,
          hideFooter: true,
          classNames: {
            wrapper: "mb-0",
          },
        },
        {
          component: <ImportExternalEquipmentItemsImportResults />,
          footerButtonsConfig: [
            {
              type: "outlined",
              onClick: closeModal,
              text: intl.$t({ id: "CLOSE" }),
              className: "w-60",
            },
          ],
        },
      ],
    },
  ];
};
