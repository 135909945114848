import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import { FC, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ConnectionError, ValidationType } from "./ExportErrorList";

type ErrorItemProps = {
  $isError?: boolean;
  $isFirstWarning: boolean;
  $grayed?: boolean;
};

const ErrorText = tw.div`text-xs`;
const ErrorItem = tw.div<ErrorItemProps>`
  flex flex-row gap-2 items-center
  ${({ $isFirstWarning }) =>
    $isFirstWarning ? "border-t border-dashed border-gray-400 pt-2" : ""}
  ${({ $isError, $grayed }) => ($isError ? "text-red-500" : $grayed && "text-gray-600")}
`;

type ValidationItemProps = {
  validation: ConnectionError;
  includeDivider?: boolean;
  classes?: {
    item?: string;
  };
  isAdmin?: boolean;
  index?: number;
  iterationKey?: number;
  grayed?: boolean;
};

export const ValidationItem: FC<ValidationItemProps> = ({
  validation,
  iterationKey = 0,
  includeDivider = false,
  classes,
  isAdmin = false,
  index = 0,
  grayed = true,
}) => (
  <Fragment key={iterationKey}>
    <ErrorItem
      $isError={validation.validationType !== ValidationType.Warning}
      $isFirstWarning={
        includeDivider &&
        validation.validationType === ValidationType.Warning &&
        iterationKey !== 0 &&
        index === iterationKey
      }
      key={iterationKey}
      className={classes?.item}
      $grayed={grayed}
    >
      <If isTrue={!validation.hideInfoIcon}>
        <Tooltip
          id={validation.toolTipKey || ""}
          element={
            <LinkLike
              disabled={validation.validationType === ValidationType.Warning}
            >
              <InfoOutlined
                className={
                  validation.validationType !== ValidationType.Warning
                    ? "text-red-500"
                    : ""
                }
              />
            </LinkLike>
          }
        >
          {(
            isAdmin
              ? validation.adminToolTipKey || validation.toolTipKey
              : validation.toolTipKey
          ) ? (
            <FormattedMessage
              id={
                isAdmin
                  ? validation.adminToolTipKey || validation.toolTipKey
                  : validation.toolTipKey
              }
              values={validation.values}
            />
          ) : null}
        </Tooltip>
      </If>
      <FormattedMessage
        id={validation.key}
        values={validation.values}
        tagName={ErrorText}
      />
      <If isTrue={validation.button}>{validation.button}</If>
    </ErrorItem>
    {validation.child}
  </Fragment>
);
