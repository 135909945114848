import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ExpandableFooterStore {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  height: number;
  setHeight: (height: number) => void;
}

export const useExpandableFooterStore = create<ExpandableFooterStore>()(
  devtools((set) => ({
    expanded: false,
    height: 0,
    setExpanded: (expanded: boolean) => {
      set(() => ({
        expanded,
      }));
    },
    setHeight: (height: number) => {
      set(() => ({
        height,
      }));
    },
  })),
);
