import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { CostType } from "@/generated/graphql";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

type CostTypeType =
  | Pick<CostType, "code" | "description">
  | string
  | null
  | undefined;

export const useCostTypes = () => {
  const { costTypes, loading } = useStartupDataStore(
    useShallow((state) => ({
      costTypes: state.costTypes,
      loading: state.loading,
    })),
  );

  const trimDescription = useCallback((description: string) => {
    return description.replace(/\s+/g, " ").trim();
  }, []);

  const formatCostType = useCallback(
    (costType: CostTypeType) => {
      if (!costType) {
        return "";
      }
      if (typeof costType === "string") {
        const code = costTypes?.find(
          (ct) =>
            ct.code.toLowerCase() === costType.toLowerCase() ||
            trimDescription(ct.description).toLowerCase() ===
              costType.toLowerCase() ||
            costType === ct.id,
        );

        return code
          ? `${trimDescription(code?.description)} (${code?.code})`
          : costType;
      }

      return `${trimDescription(costType.description)} (${costType.code})`;
    },
    [costTypes, trimDescription],
  );

  return {
    costTypes,
    loading,
    formatCostType,
  };
};
