import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Card } from "@/common/layout/ResponsiveClasses";
import { useLocale } from "@/common/providers/LocaleProvider";
import { Identity } from "@/types/Identity";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useSorting } from "../../stores/hooks/useSorting";
import { ObjectType } from "../../stores/useSortingStore";
import { If } from "../if/If";
import { SearchInput } from "../search-input/SearchInput";
import { GridItem } from "./components/grid-item/GridItem";
import { GridTableCategory } from "./components/grid-table-category/GridTableCategory";
import { CategoryType } from "./enums/CategoryType";
import {
  Category,
  CategoryCardContent,
  CloseStyled,
  DefaultContainer,
  HeaderCard,
  HeaderCardContent,
  HeaderCardWrapper,
  HeaderSearch,
  LoaderStyled,
  TableContent,
} from "./GridTable.styles";
import { getCountKey } from "./hooks/useCountGeneration";
import { GridTableProvider, useGridTable } from "./providers/GridTableProvider";
import { CategoryTypeRenderItemsMap } from "./types/CategoryTypeRenderItemsMap";
import { GridCol } from "./types/GridCol";
import { GridSortDirection } from "./types/GridSortDirection";
import { GridTableConfiguration } from "./types/GridTableConfiguration";
import { RenderType } from "./types/RenderType";
import { getCategoryType } from "./utils/getCategoryType";

type GridTableProviderProps = {
  virtualized?: boolean;
  hideGroup?: boolean;
  readonly?: boolean;
};

type Props<T extends Identity, TAdditional> = {
  items?: CategoryState<T>[] | CategoryState<CategoryState<T>>[] | Array<T>;
  configuration: GridTableConfiguration<T, TAdditional>;
  readonlyFn?: (
    additionalItem: TAdditional | undefined,
    item?: T | undefined,
  ) => boolean;
  hideFirstDetail?: boolean;
  expandedItems?: (item: T) => T[];
  expandedDetailItems?: (item: T) => T[];
  expandedItemComponent?: (item: T) => JSX.Element;
  preRowItemComponent?: (item: T) => JSX.Element;
  showSubdetailsIfNoDetailItems?: (item: T) => boolean;
  error?: boolean;
  loading?: boolean;
  emptyList?: string | ReactNode;
  hideHeader?: boolean;
  virtualizedItemsCount?: number;
  footer?: ReactNode;
  onSort?: (
    column: GridCol<T, TAdditional>,
    direction: GridSortDirection,
  ) => void;
  useLocalPagination?: boolean;
  disableSorting?: boolean;
  sortObjectType?: ObjectType;
};

const GridTableWithProvider = <T extends Identity, TAdditional = undefined>({
  items,
  configuration,
  readonlyFn,
  hideFirstDetail = false,
  expandedItems = () => [],
  expandedDetailItems = () => [],
  expandedItemComponent,
  preRowItemComponent,
  showSubdetailsIfNoDetailItems = () => false,
  error = false,
  loading = false,
  emptyList,
  hideHeader = false,
  footer,
  onSort,
  sortObjectType,
  useLocalPagination,
  disableSorting,
}: Props<T, TAdditional>) => {
  const { selectedLocale } = useLocale();
  const { setSortEntity, sortEntity, removeSortEntity } =
    useSorting(sortObjectType);
  const { categoryType, resetCountMap } = useGridTable(
    useShallow((state) => ({
      categoryType: state.table.categoryType,
      resetCountMap: state.resetCountMap,
    })),
  );
  const intl = useIntl();
  const [searchText, setSearchText] = useState("");

  const setSearchTextAndResetMap = useCallback(
    (text: string) => {
      setSearchText(text);
      resetCountMap();
    },
    [resetCountMap],
  );

  const [activeSearchColumn, setActiveSearchColumn] = useState<
    GridCol<T, TAdditional> | undefined
  >();

  const [activeSortColumn, setActiveSortColumn] =
    useState<GridCol<T, TAdditional>>();

  const [sortDirection, setSortDirection] = useState<GridSortDirection | null>(
    null,
  );

  const [editing, setEditing] = useState(false);

  const Container = useMemo(
    () => configuration.container || DefaultContainer,
    [configuration.container],
  );

  useEffect(() => {
    if (sortEntity) {
      setSortDirection(
        sortEntity.descending
          ? GridSortDirection.Descending
          : GridSortDirection.Ascending,
      );
    }
  }, [sortEntity]);

  useEffect(() => {
    setActiveSortColumn(
      sortEntity
        ? configuration.columns.find((c) => c.sortKey === sortEntity.field)
        : undefined,
    );
  }, [sortEntity, configuration]);

  const filteredItems = useMemo(() => {
    if (categoryType === undefined) {
      return [];
    }
    if (!activeSearchColumn?.searchItemFn && !activeSortColumn?.sortItemFn) {
      return items || [];
    }
    if (!items || items.length === 0) {
      return [];
    }
    const sortFunction =
      !disableSorting && !useLocalPagination && activeSortColumn?.sortItemFn
        ? sortDirection === GridSortDirection.Ascending
          ? activeSortColumn?.sortItemFn
          : (a: T, b: T) => -(activeSortColumn?.sortItemFn?.(a, b) || 0)
        : undefined;
    if (categoryType === CategoryType.ONE_LEVEL) {
      const result = (items as T[]).filter((item) =>
        activeSearchColumn?.searchItemFn
          ? activeSearchColumn?.searchItemFn(
              item as T,
              searchText,
              selectedLocale?.toString(),
            )
          : true,
      );
      if (sortFunction) {
        return result.toSorted(sortFunction);
      }

      return result;
    }
    if (categoryType === CategoryType.TWO_LEVELS) {
      return (items as CategoryState<T>[])
        .map((category) => {
          const categoryItems = category.items.filter((i) =>
            activeSearchColumn?.searchItemFn
              ? activeSearchColumn?.searchItemFn(
                  i as T,
                  searchText,
                  selectedLocale?.toString(),
                )
              : true,
          );
          return {
            ...category,
            items: sortFunction
              ? categoryItems.toSorted(sortFunction)
              : categoryItems,
          };
        })
        .filter((category) => (category as CategoryState<T>).items.length > 0);
    }
    if (categoryType === CategoryType.THREE_LEVELS) {
      return (items as CategoryState<CategoryState<T>>[])
        .map((category) => ({
          ...category,
          items: category.items
            .map((subCategory) => {
              const subCategoryItems = subCategory.items.filter((item) =>
                activeSearchColumn?.searchItemFn
                  ? activeSearchColumn?.searchItemFn(
                      item as T,
                      searchText,
                      selectedLocale?.toString(),
                    )
                  : true,
              );
              return {
                ...subCategory,
                items: sortFunction
                  ? subCategoryItems.toSorted(sortFunction)
                  : subCategoryItems,
              };
            })
            .filter((subCategory) => subCategory.items.length > 0),
        }))
        .filter((category) => category.items.length > 0);
    }
    return items;
  }, [
    activeSearchColumn,
    activeSortColumn,
    disableSorting,
    items,
    categoryType,
    searchText,
    selectedLocale,
    sortDirection,
    useLocalPagination,
  ]);

  const setSortDirectionFn = useCallback(
    (column: GridCol<T, TAdditional>) => {
      const columnDefaultSort =
        column.defaultSort || GridSortDirection.Ascending;
      if (
        (activeSortColumn !== column && !column.sortKey) ||
        (column.sortKey !== sortEntity?.field && column.sortKey)
      ) {
        if (sortObjectType && column.sortKey) {
          setSortEntity({
            objectType: sortObjectType,
            field: column.sortKey,
            descending: columnDefaultSort === GridSortDirection.Descending,
          });
        } else {
          setActiveSortColumn(column);
          setSortDirection(columnDefaultSort);
        }
        onSort?.(column, columnDefaultSort);
        return;
      }
      let newDirection: GridSortDirection | undefined;
      if (sortDirection === columnDefaultSort) {
        if (sortDirection === GridSortDirection.Ascending) {
          newDirection = GridSortDirection.Descending;
        } else {
          newDirection = GridSortDirection.Ascending;
        }
      }
      if (newDirection) {
        if (sortObjectType && column.sortKey) {
          setSortEntity({
            objectType: sortObjectType,
            field: column.sortKey,
            descending: newDirection === GridSortDirection.Descending,
          });
        } else {
          setActiveSortColumn(column);
          setSortDirection(newDirection);
        }
        onSort?.(column, newDirection);
        return;
      }
      setActiveSortColumn(undefined);
      setSortDirection(null);
      removeSortEntity();
    },
    [
      activeSortColumn,
      onSort,
      removeSortEntity,
      setSortEntity,
      sortDirection,
      sortObjectType,
      sortEntity,
    ],
  );

  return (
    <Container className={configuration.classNames?.container}>
      <If isTrue={!hideHeader}>
        <HeaderCardWrapper className={configuration.classNames?.header}>
          <HeaderCard>
            <If isTrue={activeSearchColumn}>
              <HeaderSearch>
                <If isTrue={typeof activeSearchColumn?.header === "string"}>
                  <FormattedMessage id="SEARCH" /> {activeSearchColumn?.header}
                </If>
                <SearchInput
                  placeHolder={intl.$t({ id: "SEARCH" })}
                  value={searchText}
                  onChange={setSearchTextAndResetMap}
                  autoFocus
                />
                <CloseStyled
                  onClick={() => {
                    setActiveSearchColumn(undefined);
                    setSearchText("");
                  }}
                />
              </HeaderSearch>
            </If>
            <HeaderCardContent data-testid="tableHeader">
              <GridItem
                className="text-center text-sm font-medium"
                columns={configuration.columns}
                index={0}
                renderType={RenderType.Header}
                setActiveSearchColumn={setActiveSearchColumn}
                setActiveSortColumn={setSortDirectionFn}
                sortedColumn={activeSortColumn}
                sortDirection={sortDirection}
                disableSorting={disableSorting || editing}
                category={{
                  items: filteredItems as Array<T>,
                  id: "header",
                  name: "header",
                  isOpened: true,
                }}
              />
            </HeaderCardContent>
          </HeaderCard>
        </HeaderCardWrapper>
      </If>
      <LoaderStyled loading={loading}>
        <TableContent className={configuration.classNames?.root}>
          {filteredItems?.map((category, index) => (
            <GridTableCategory
              key={index}
              index={index}
              category={category}
              configuration={configuration}
              readonlyFn={readonlyFn}
              expandedItems={expandedItems}
              expandedDetailItems={expandedDetailItems}
              expandedItemComponent={expandedItemComponent}
              preRowItemComponent={preRowItemComponent}
              showSubdetailsIfNoDetailItems={showSubdetailsIfNoDetailItems}
              hideFirstDetail={hideFirstDetail}
              error={error}
              setEditing={setEditing}
            />
          ))}
        </TableContent>
        <If isTrue={items?.length === 0}>{emptyList}</If>
        <If isTrue={configuration.columns.some((c) => c.footer)}>
          <Category>
            <Card className="rounded-b-2xl rounded-t-none bg-gray-100 pt-5">
              <CategoryCardContent className="gap-0 px-0">
                <GridItem
                  key="footer"
                  renderType={RenderType.Footer}
                  columns={configuration.columns}
                  className="gap-0"
                />
              </CategoryCardContent>
              {footer}
            </Card>
          </Category>
        </If>
        <If isTrue={!configuration.columns.some((c) => c.footer) && !!footer}>
          {footer}
        </If>
      </LoaderStyled>
    </Container>
  );
};

export const GridTable = <T extends Identity, TAdditional = undefined>(
  props: Props<T, TAdditional> & GridTableProviderProps,
) => {
  const { hideGroup, virtualized, readonly } = props;
  const itemCount = useMemo(() => props.items?.length || 0, [props.items]);
  const defaultProviderProps = useMemo(() => {
    const categoryType =
      props.items && props.items.length > 0
        ? getCategoryType(props.items?.[0])
        : undefined;

    const itemsCount =
      virtualized && categoryType
        ? itemCount > CategoryTypeRenderItemsMap[categoryType]
          ? CategoryTypeRenderItemsMap[categoryType]
          : itemCount
        : 0;
    const defaultVisibleCategories = Array.from(
      { length: itemsCount },
      (_, i) => getCountKey(i),
    );
    return {
      virtualized: virtualized ?? false,
      categoryType:
        props.items && props.items.length > 0
          ? getCategoryType(props.items?.[0])
          : undefined,
      visibleCategories: defaultVisibleCategories,
      hideGroup: hideGroup ?? false,
      readonly: readonly ?? false,
    };
  }, [hideGroup, itemCount, props.items, readonly, virtualized]);

  return (
    <GridTableProvider {...defaultProviderProps}>
      <GridTableWithProvider {...props} />
    </GridTableProvider>
  );
};
