import { LocalPaginationProvider } from "@/common/components/pagination-local/LocalPaginationProvider";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { Switch } from "@/common/components/switch/Switch";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalServiceCodes } from "../../../hooks/useExternalServiceCodes";
import { useImportServiceCodesStore } from "../../../stores/useImportServiceCodesStore";
import { useImportExternalServiceCodesTableConfiguration } from "./ImportExternalServiceCodesTable.config";

const SearchBarTitleComponent = tw.div`flex flex-col pr-2`;
const ToggleText = tw.div`flex flex-row font-normal text-sm items-center mt-1`;

const ImportExternalServiceCodesTableWithProviders = () => {
  const intl = useIntl();
  const {
    sourceSystem,
    selectedExternalServiceCodes,
    totalExternalServiceCodesCount,
    setSelectedExternalServiceCodes,
    setTotalExternalServiceCodesCount,
    importAllExternalItems,
    setImportAllExternalItems,
  } = useImportServiceCodesStore();
  const { externalServiceCodes, loadingExternalServiceCodes } =
    useExternalServiceCodes();
  const configuration = useImportExternalServiceCodesTableConfiguration(
    selectedExternalServiceCodes,
  );

  useEffect(() => {
    setTotalExternalServiceCodesCount(externalServiceCodes.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalServiceCodes.length]);

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      items={externalServiceCodes.map((serviceCode) => ({
        ...serviceCode,
        id: serviceCode.externalId,
      }))}
      loadingItems={loadingExternalServiceCodes}
      searchBarTitle={
        <SearchBarTitleComponent>
          <FormattedMessage id="SELECT_SERVICE_CODES_TO_IMPORT" />
          <ToggleText>
            <FormattedMessage
              id="IMPORT_ALL_SERVICE_CODES_FROM_SYSTEM"
              values={{
                system: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
              }}
            />
            <Switch
              className="ml-2"
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              value={importAllExternalItems}
              onChange={(value) => {
                setImportAllExternalItems(value);
                setSelectedExternalServiceCodes(
                  value ? externalServiceCodes.map((i) => i.externalId) : [],
                );
              }}
            />
          </ToggleText>
        </SearchBarTitleComponent>
      }
      searchKeys={["code", "name"]}
      itemSelection={{
        type: "multiple",
        selectedItemIds: selectedExternalServiceCodes,
        setSelectedItemIds: (ids: string[]) => {
          setImportAllExternalItems(
            ids.length === totalExternalServiceCodesCount,
          );
          setSelectedExternalServiceCodes(ids);
        },
      }}
    />
  );
};

export const ImportExternalServiceCodesTable = () => {
  return (
    <LocalPaginationProvider>
      <ImportExternalServiceCodesTableWithProviders />
    </LocalPaginationProvider>
  );
};
