import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import {
  Base,
  SmFixedContainer,
  SmdFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import {
  BUDGET_VIEW_TYPE,
  ProjectReportType,
  useSpendingReport,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import {
  ProjectReportCostCodeFieldsFragment,
  ProjectReportCostCodeVendorFieldsFragment,
  ProjectReportMaterialFieldsFragment,
  ProjectReportVendorFieldsFragment,
  ProjectReportZoneFieldsFragment,
} from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { CheckCircleOutline } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CurrencyDisplay } from "./components/CurrencyDisplay";
import { PhaseCodesHeader } from "./components/PhaseCodeHeader";
import { ReportItemDisplay } from "./components/ReportItemDisplay";
import { ReportMaterialItem } from "./components/ReportMaterialItem";
import { ReportVendorItem } from "./components/ReportVendorItem";
import { SetCostCodeAllowance } from "./components/SetCostCodeAllowance";
import { SpendingReportValue } from "./components/SpendingReportValue";
import { SpendingReportVendorValue } from "./components/SpendingReportVendorValue";
import { CostCodeOrderedInvoiced } from "./components/cost-codes-totals/CostCodeOrderedInvoiced";
import { CostCodePaid } from "./components/cost-codes-totals/CostCodePaid";
import { CostCodeReceived } from "./components/cost-codes-totals/CostCodeReceived";
import {
  TotalContainer,
  ValueWithTax,
} from "./components/cost-codes-totals/CostCodeTotal.Styles";
import { ReportType } from "./components/report-item-orders-modal/ReportItemOrderedInvoicedModal";
import { ZoneOrderedInvoiced } from "./components/zone-totals/ZoneOrderedInvoiced";
import { filterProjectReportItem } from "./utils/filterProjectReportItem";

const ItemContainer = tw(Base)`
  col-span-full
  basis-full lg:basis-1/12 lg:w-1/12
  2xl:basis-2/12 2xl:w-2/12 flex-1
`;

const AllowanceContainer = tw(
  SmFixedContainer,
)`2xl:basis-24 2xl:w-24 xl:w-24 xl:basis-24`;

type CustomHeaderProps = {
  $disabled: boolean;
};

const CustomHeader = tw.div<CustomHeaderProps>`
  flex items-center
  ${(props) => props.$disabled && "opacity-50"}
`;

const CheckStyled = tw(CheckCircleOutline)`
  text-green-800
`;

export enum SpendingReportValueType {
  ORDERED,
  INVOICED,
}

export const useSpendingReportListItemConfiguration = () => {
  const { viewType } = useSpendingReport();
  const { project } = useProject();
  const { materialsMap } = useProjectMaps(project);
  const { hasPhaseCodes } = useOrgSettings();

  const getDetailsForZone = useCallback(
    (category: CategoryState<ProjectReportType>) => {
      return category as unknown as ProjectReportZoneFieldsFragment & {
        id: string;
      };
    },
    [],
  );

  const getDetailsForCostCode = useCallback(
    (category: CategoryState<ProjectReportType>) => {
      return category as unknown as ProjectReportCostCodeFieldsFragment & {
        id: string;
      };
    },
    [],
  );

  const getMaterial = useCallback((item: ProjectReportType) => {
    return item as ProjectReportMaterialFieldsFragment & Identity;
  }, []);

  const getVendorCostCode = useCallback(
    (category: CategoryState<ProjectReportType>) => {
      return category as unknown as ProjectReportCostCodeVendorFieldsFragment & {
        id: string;
      };
    },
    [],
  );

  const getVendor = useCallback((item: ProjectReportType) => {
    return item as ProjectReportVendorFieldsFragment & Identity;
  }, []);

  const materialsConfig: Array<GridCol<ProjectReportType>> = useMemo(
    () => [
      {
        wrapper: ItemContainer,
        item: ({ item, count }) => (
          <ReportMaterialItem
            count={count}
            material={materialsMap.get(item.id)}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) =>
          hasPhaseCodes ? (
            <PhaseCodesHeader />
          ) : (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
              projectId={project?.id}
            />
          ),
        header: <FormattedMessage id="ITEMS" />,
        includesCounter: true,
        searchItemFn: filterProjectReportItem,
      },
      {
        wrapper: AllowanceContainer,
        subgroup: (category) => (
          <SetCostCodeAllowance item={getDetailsForCostCode(category)} />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_HEADER" />,
        item: ({ item }) => (
          <CurrencyDisplay value={getMaterial(item)?.estimated} />
        ),
        group: (category) => (
          <ReportItemDisplay item={getDetailsForZone(category)} />
        ),
        subgroup: (category) => (
          <ReportItemDisplay item={getDetailsForCostCode(category)} />
        ),
        position: "end",
      },
      {
        wrapper: SmdFixedContainer,
        header: (
          <CustomHeader $disabled={viewType !== BUDGET_VIEW_TYPE.MATERIALS}>
            <FormattedMessage id="PROJECT_BUDGET_QUOTED_HEADER" />
          </CustomHeader>
        ),
        item: ({ item }) => (
          <NotNullableRenderer
            value={
              viewType === BUDGET_VIEW_TYPE.MATERIALS &&
              getMaterial(item)?.quoted
            }
          >
            <CheckStyled />
          </NotNullableRenderer>
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_ORDERED_HEADER" />,
        item: ({ item }) => (
          <SpendingReportValue
            item={getMaterial(item)}
            valueType={SpendingReportValueType.ORDERED}
          />
        ),
        group: (category) => (
          <ZoneOrderedInvoiced
            item={getDetailsForZone(category)}
            valueType={SpendingReportValueType.ORDERED}
          />
        ),
        subgroup: (category) => (
          <CostCodeOrderedInvoiced
            item={getDetailsForCostCode(category)}
            zoneId={category.parentId}
            reportType={ReportType.Material}
            valueType={SpendingReportValueType.ORDERED}
          />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: (
          <CustomHeader $disabled={viewType !== BUDGET_VIEW_TYPE.MATERIALS}>
            <FormattedMessage id="PROJECT_BUDGET_RECEIVED_HEADER" />
          </CustomHeader>
        ),
        item: ({ item }) => (
          <NotNullableRenderer value={viewType === BUDGET_VIEW_TYPE.MATERIALS}>
            <CurrencyDisplay value={getMaterial(item)?.received} />
          </NotNullableRenderer>
        ),
        subgroup: (category) => (
          <NotNullableRenderer value={viewType === BUDGET_VIEW_TYPE.MATERIALS}>
            <CostCodeReceived item={getDetailsForCostCode(category)} />
          </NotNullableRenderer>
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_INVOICED_HEADER" />,
        item: ({ item }) => (
          <SpendingReportValue
            item={getMaterial(item)}
            valueType={SpendingReportValueType.INVOICED}
          />
        ),
        group: (category) => (
          <ZoneOrderedInvoiced
            item={getDetailsForZone(category)}
            valueType={SpendingReportValueType.INVOICED}
          />
        ),
        subgroup: (category) => (
          <CostCodeOrderedInvoiced
            item={getDetailsForCostCode(category)}
            zoneId={category.parentId}
            reportType={ReportType.Material}
            valueType={SpendingReportValueType.INVOICED}
          />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_PAID_HEADER" />,
        item: ({ item }) => <CurrencyDisplay value={getMaterial(item)?.paid} />,
        group: (category) => (
          <CurrencyDisplay value={getDetailsForZone(category)?.paid} />
        ),
        subgroup: (category) => (
          <CostCodePaid item={getDetailsForCostCode(category)} />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: (
          <FormattedMessage id="PROJECT_BUDGET_ORDERED_VS_BUDGET_HEADER" />
        ),
        group: (category) => (
          <TotalContainer>
            <CurrencyDisplay
              value={getDetailsForZone(category)?.overUnder}
              showColor
              rightAlign
            />
            <If isTrue={getDetailsForZone(category)?.overUnder}>
              <ValueWithTax className="text-right">
                <FormattedMessage id="WITH_TAX_AND_FEES" />
              </ValueWithTax>
            </If>
          </TotalContainer>
        ),
        subgroup: (category) => (
          <TotalContainer>
            <CurrencyDisplay
              value={getDetailsForZone(category)?.overUnder}
              showColor
              rightAlign
            />
            <If isTrue={getDetailsForZone(category)?.overUnder}>
              <ValueWithTax className="text-right">
                <FormattedMessage id="WITH_TAX_AND_FEES" />
              </ValueWithTax>
            </If>
          </TotalContainer>
        ),
        position: "end",
      },
    ],
    [
      viewType,
      materialsMap,
      project?.id,
      getDetailsForCostCode,
      getMaterial,
      getDetailsForZone,
      hasPhaseCodes,
    ],
  );

  const vendorsConfig: GridCol<ProjectReportType>[] = useMemo(
    () => [
      {
        wrapper: ItemContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_VENDOR_HEADER" />,
        item: ({ item, count }) => (
          <ReportVendorItem vendor={getVendor(item).vendor} count={count} />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) =>
          hasPhaseCodes ? (
            <PhaseCodesHeader />
          ) : (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
              projectId={project?.id}
            />
          ),
        includesCounter: true,
      },
      {
        wrapper: AllowanceContainer,
        subgroup: (category) => (
          <SetCostCodeAllowance item={getVendorCostCode(category)} />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_HEADER" />,
        item: () => "--",
        subgroup: (category) => (
          <ReportItemDisplay item={getVendorCostCode(category)} />
        ),
        position: "end",
      },
      {
        wrapper: SmdFixedContainer,
        header: (
          <CustomHeader $disabled>
            <FormattedMessage id="PROJECT_BUDGET_QUOTED_HEADER" />
          </CustomHeader>
        ),
        item: () => "--",
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_ORDERED_HEADER" />,
        item: ({ item }) => (
          <SpendingReportVendorValue
            item={item as ProjectReportVendorFieldsFragment}
            costCodeId={item.costCodeId}
            valueType={SpendingReportValueType.ORDERED}
          />
        ),
        subgroup: (category) => (
          <CostCodeOrderedInvoiced
            item={getVendorCostCode(category)}
            reportType={ReportType.Vendor}
            valueType={SpendingReportValueType.ORDERED}
          />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_RECEIVED_HEADER" />,
        item: ({ item }) => <CurrencyDisplay value={item.received} />,
        subgroup: (category) => (
          <CostCodeReceived item={getDetailsForCostCode(category)} />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_INVOICED_HEADER" />,
        item: ({ item }) => (
          <SpendingReportVendorValue
            item={item as ProjectReportVendorFieldsFragment}
            costCodeId={item.costCodeId}
            valueType={SpendingReportValueType.INVOICED}
          />
        ),
        subgroup: (category) => (
          <CostCodeOrderedInvoiced
            item={getDetailsForCostCode(category)}
            zoneId={category.parentId}
            reportType={ReportType.Material}
            valueType={SpendingReportValueType.INVOICED}
          />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: <FormattedMessage id="PROJECT_BUDGET_PAID_HEADER" />,
        item: ({ item }) => <CurrencyDisplay value={item.paid} />,
        subgroup: (category) => (
          <CostCodePaid item={getDetailsForCostCode(category)} />
        ),
        position: "center",
      },
      {
        wrapper: SmdFixedContainer,
        header: (
          <FormattedMessage id="PROJECT_BUDGET_ORDERED_VS_BUDGET_HEADER" />
        ),
        item: () => "--",
        subgroup: (category) => (
          <TotalContainer>
            <CurrencyDisplay
              value={getVendorCostCode(category)?.overUnder}
              showColor
              rightAlign
            />
            <If isTrue={getVendorCostCode(category)?.overUnder}>
              <ValueWithTax className="text-right">
                <FormattedMessage id="WITH_TAX_AND_FEES" />
              </ValueWithTax>
            </If>
          </TotalContainer>
        ),
        position: "end",
      },
    ],
    [
      getDetailsForCostCode,
      getVendor,
      getVendorCostCode,
      project?.id,
      hasPhaseCodes,
    ],
  );

  return { materialsConfig, vendorsConfig };
};
