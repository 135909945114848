import { If } from "@/common/components/if/If";
import { InlineNotesAndAttachments } from "@/common/components/inline-notes-and-attachments/InlineNotesAndAttachments";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import { AssetContext } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  readonly: boolean;
};

const DistributorQuoteNotesWithProvider: FC<Props> = ({ item, readonly }) => {
  const { updateQuote, setExpandedItems, expandedItems } =
    useDistributorQuote();
  const { assets } = useUploadAssets();

  const closeItem = useCallback(
    () =>
      setExpandedItems(
        expandedItems.filter((id) => id !== item.quoteItems[0].id),
      ),
    [expandedItems, item.quoteItems, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (notes: string) => {
      const updates = [
        {
          quoteItemId: item.quoteItems[0].id,
          notes,
          assetUrls: assets.map((a) => a.url),
        },
      ];
      await updateQuote({
        updates,
      });

      closeItem();
    },
    [assets, closeItem, item.quoteItems, updateQuote],
  );

  return (
    <If isTrue={expandedItems.includes(item.quoteItems[0].id)}>
      <InlineNotesAndAttachments
        closeItem={closeItem}
        item={item.quoteItems[0]}
        readonly={readonly}
        saveItem={handleOnSave}
      />
    </If>
  );
};

export const DistributorQuoteNotes: FC<Props> = (props) => {
  const { item } = props;
  const { quote } = useDistributorQuote();

  return (
    <UploadAssetProvider
      initialAssets={item.quoteItems[0].assets}
      context={AssetContext.Note}
      projectId={quote?.rfq.project?.id}
    >
      <DistributorQuoteNotesWithProvider {...props} />
    </UploadAssetProvider>
  );
};
