import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ViewToggle } from "../../../../view-toggle/ViewToggle";

export enum SalesTaxType {
  Percent = "percent",
  Amount = "amount",
}

const toggleOptions = [
  {
    label: <FormattedMessage id="AMOUNT" />,
    viewState: SalesTaxType.Amount,
  },
  {
    label: <FormattedMessage id="PERCENTAGE" />,
    viewState: SalesTaxType.Percent,
  },
];

type Props = {
  type: SalesTaxType;
  changeType: (type: SalesTaxType) => void;
  readonly?: boolean;
};

export const AmountPercentageSwitch: FC<Props> = ({
  type,
  changeType,
  readonly,
}) => (
  <ViewToggle
    options={toggleOptions}
    setView={changeType}
    tableView={type}
    readonly={readonly}
  />
);
