import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { LOCAL_STORAGE_KEYS } from "../const";
import { readValue, setValue } from "../utils/localStorage";

export enum TableViewState {
  normal = "normal",
  spreadsheet = "spreadsheet",
  materials = "materials",
  vendors = "vendors",
}

interface SaveSelection {
  id: string;
}

interface TableViewStore {
  viewState?: TableViewState;
  setViewState: (view?: TableViewState) => void;
  saveSelection?: SaveSelection;
  initViewStore: (
    defaultTableView?: TableViewState,
    saveSelection?: SaveSelection,
  ) => void;
  resetViewStore: () => void;
}

export const useTableViewStore = create<TableViewStore>()(
  devtools((set, get) => ({
    viewState: undefined,
    saveSelection: undefined,
    setViewState: (view?: TableViewState) => {
      const saveSelection = get().saveSelection;
      if (saveSelection) {
        setValue(`${LOCAL_STORAGE_KEYS.LIST_VIEW}_${saveSelection.id}`, view);
      }
      set(() => ({
        viewState: view,
      }));
    },
    initViewStore: (
      defaultTableView?: TableViewState,
      saveSelection?: SaveSelection,
    ) => {
      let calculatedTableView = defaultTableView;
      if (saveSelection) {
        const value = readValue(
          `${LOCAL_STORAGE_KEYS.LIST_VIEW}_${saveSelection.id}`,
          defaultTableView,
        );
        if (value) {
          const key = value as keyof typeof TableViewState;
          if (TableViewState[key]) {
            calculatedTableView = TableViewState[key];
          }
        }
      }
      set(() => ({
        viewState: calculatedTableView,
        saveSelection: saveSelection,
      }));
    },
    resetViewStore: () =>
      set(() => ({
        saveSelection: undefined,
        viewState: undefined,
      })),
  })),
);
