import { formattedDate } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import { useReleaseNavigation } from "@/contractor/pages/home/releases/pages/deliveries/hooks/useReleaseNavigation";
import {
  Notification,
  NotificationReleaseChangeContextFieldsFragment,
  ReleaseEvent,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { DetailsContainer, MessageBody } from "../common/Notification.styles";
import { BaseNotification, Sender } from "./BaseNotification";

type ReleaseChangeChangeNotificationProps = {
  notification: Notification;
};

export const ReleaseChangeNotification: FC<
  ReleaseChangeChangeNotificationProps
> = ({ notification }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { getReleaseEditPath } = useReleaseNavigation();
  const context =
    notification.context as NotificationReleaseChangeContextFieldsFragment;

  const onCallback = () => {
    switch (context.event) {
      case ReleaseEvent.ApprovalRequested:
      case ReleaseEvent.Rejected:
        navigate(getReleaseEditPath(context.release));
        break;
      default:
        navigate(
          generatePath(routes.delivery, {
            deliveryId: context.release.id,
          }),
        );
        break;
    }
  };

  const sender = useMemo(() => {
    if (
      context.event === ReleaseEvent.NeedsToBeReceived ||
      context.event === ReleaseEvent.RequestedInternal
    ) {
      return "";
    }
    if (
      context.oldStatus === ReleaseStatus.Draft &&
      context.newStatus === ReleaseStatus.Requested
    ) {
      return (
        (context?.release.buyout?.createdBy.firstName ?? "") +
        (!!context?.release.buyout?.createdBy.firstName &&
        !!context?.release.buyout.createdBy.lastName
          ? " "
          : "") +
        (context?.release.buyout?.createdBy.lastName ?? "")
      );
    } else {
      return getUserName(context?.initiatedBy) ?? "";
    }
  }, [context]);

  const notificationMessage = useMemo(() => {
    switch (context.event) {
      case ReleaseEvent.ApprovalRequested:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_APPROVAL_REQUESTED" },
          {
            submittedBy: getUserName(context.release.submittedBy),
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
          },
        );
      case ReleaseEvent.Approved:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_APPROVED" },
          {
            approvedBy: context.release.approvals
              .map((a) => getUserName(a.approver))
              .join(", "),
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
          },
        );
      case ReleaseEvent.Rejected:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_REJECTED" },
          {
            rejectedBy: getUserName(context.release.rejectedBy),
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
          },
        );
      case ReleaseEvent.Confirmed:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_CONFIRMED" },
          {
            vendorOrgName: context.release.sellerOrgLocation?.org.name,
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
          },
        );
      case ReleaseEvent.ChangedByVendor:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_MODIFIED" },
          {
            vendorOrgName: context.release.sellerOrgLocation?.org.name,
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
          },
        );
      case ReleaseEvent.IssuesResolved:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_ISSUES_RESOLVED" },
          {
            vendorOrgName: context.release.sellerOrgLocation?.org.name,
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
          },
        );
      case ReleaseEvent.RequestedInternal:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_CONTRACTOR_REQUESTED" },
          {
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
            submittedBy: getUserName(context.release.submittedBy),
            vendorOrgName: context.release.sellerOrgLocation?.org.name,
          },
        );
      case ReleaseEvent.NeedsToBeReceived:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_NEEDS_TO_BE_RECEIVED" },
          {
            projectName: context.release.project?.name,
            vendorOrgName: context.release.sellerOrgLocation?.org.name,
            deliveryNumber: context.release.sequenceNumber,
            deliveryDate: context.release.time
              ? formattedDate({ date: context.release.time })
              : "",
          },
        );
      case ReleaseEvent.ChangedByContractorInternal:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_CHANGED_INTERNAL" },
          {
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
            changedBy: getUserName(context.initiatedBy),
          },
        );
      case ReleaseEvent.ReceivedInternal:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_COMPLETED_INTERNAL" },
          {
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
            changedBy: getUserName(context.initiatedBy),
          },
        );
      case ReleaseEvent.PartiallyReceivedInternal:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_PARTIALLY_COMPLETED_INTERNAL" },
          {
            projectName: context.release.project?.name,
            deliveryNumber: context.release.sequenceNumber,
            changedBy: getUserName(context.initiatedBy),
          },
        );
      case ReleaseEvent.ItemDue:
        return intl.$t(
          { id: "NOTIFICATION_DELIVERY_ITEM_DUE" },
          {
            deliveryNumber: context.release.sequenceNumber,
            vendorOrgName: context.release.sellerOrgLocation?.org.name,
            projectName: context.release.project?.name,
          },
        );
      default:
        return "";
    }
  }, [context, intl]);

  return (
    <BaseNotification notification={notification} onCallback={onCallback}>
      <DetailsContainer>
        <Sender>{sender}</Sender>
        <MessageBody>{notificationMessage}</MessageBody>
      </DetailsContainer>
    </BaseNotification>
  );
};
