import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { getPhysicalColumnIndex } from "../utils/getPhysicalColumnIndex";
import { useRenderHelpers } from "./useRenderHelpers";

export const useCostCodeRenderer = () => {
  const { costCodeIsNotSupported } = useProjectCostCodes();
  const { checkReadOnly, applyTooltip, applyClasses } = useRenderHelpers();
  const { zones } = useProjectZones();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const rows = instance?.getData();
      const zone = zones?.find(
        (z) =>
          z.name ===
          rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Zone)],
      );
      if (value && costCodeIsNotSupported(value, zone?.id)) {
        applyTooltip(td, costCodeIsNotSupported(value, zone?.id) || "");
        applyClasses(
          td,
          "htCenter htMiddle text-red-500 bg-red-100 border-gray-200",
        );
      } else {
        applyClasses(td, "");
        applyTooltip(td, "");
      }
      textRenderer(instance, td, row, col, prop, value, cellProperties);
      checkReadOnly(td, row, instance, cellProperties);
    },
    [checkReadOnly, applyTooltip, applyClasses, costCodeIsNotSupported, zones],
  );

  return renderer;
};
