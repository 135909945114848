import { Select } from "@/common/components/select/components/single/Select";
import { Switch } from "@/common/components/switch/Switch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { usePaymentMethods } from "@/contractor/pages/admin/cost-structure/pages/payment-methods/hooks/usePaymentMethods";
import { InvoiceType, ReceiptFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { PAID_VIA_INVOICE_ID } from "./InvoiceDetailsHeader";

const Container = tw.div`flex gap-2 py-1.5 rounded-4xl items-center font-normal`;
const InfoIcon = tw(InfoOutlined)`text-blue-500`;

type Props = {
  receipt: ReceiptFieldsFragment | null;
  selectedPaymentMethodId: string | undefined;
  setSelectedPaymentMethodId: (id: string | null) => void;
  onPaidToggle: (value: boolean) => void;
};

export const ReceiptPaymentMethod: FC<Props> = ({
  receipt,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
  onPaidToggle,
}) => {
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();
  const { paymentMethods, loading: loadingPaymentMethods } =
    usePaymentMethods();

  const [isPaid, setIsPaid] = useState(!receipt?.prePaid);

  const togglePaid = useCallback(
    (value: boolean) => {
      if (
        value === false &&
        receipt?.release &&
        receipt.release.invoices.filter(
          (invoice) => invoice.type !== InvoiceType.Receipt,
        ).length
      ) {
        setWarningAlert(intl.$t({ id: "RECEIPT_PAID_TOGGLE_WARNING" }));
        return;
      }
      setIsPaid(value);
      onPaidToggle(value);
    },
    [receipt?.release, intl, setWarningAlert, onPaidToggle],
  );

  const paymentMethodsOptions = useMemo(() => {
    return [
      {
        id: PAID_VIA_INVOICE_ID,
        label: intl.$t({ id: "WILL_BE_PAID_VIA_INVOICE" }),
      },
      ...paymentMethods.map((paymentMethod) => ({
        id: paymentMethod.id,
        label: paymentMethod.description,
      })),
    ];
  }, [paymentMethods, intl]);

  if (loadingPaymentMethods) {
    return null;
  }
  return (
    <Container
      className={paymentMethods.length === 0 ? "" : "bg-blue-800 px-4"}
    >
      {paymentMethods.length === 0 ? (
        <>
          <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE" />
          <Tooltip id="receipt-info" element={<InfoIcon />}>
            <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE_INFO" />
          </Tooltip>
          <Switch
            width={60}
            offLabel={intl.$t({ id: "NO" })}
            onLabel={intl.$t({ id: "YES" })}
            onChange={togglePaid}
            value={isPaid}
          />
        </>
      ) : (
        <Select
          label={intl.$t({ id: "PAYMENT_METHOD" })}
          InputLabelProps={{
            color: "error",
          }}
          placeholder={intl.$t({ id: "PAYMENT_METHOD" })}
          options={paymentMethodsOptions}
          loading={loadingPaymentMethods}
          getValue={(paymentMethod) => paymentMethod.id}
          getLabel={(paymentMethod) => paymentMethod.label}
          value={selectedPaymentMethodId}
          onChange={setSelectedPaymentMethodId}
          sx={{
            ".MuiFormLabel-root": {
              backgroundColor: "white !important",
            },
          }}
        />
      )}
    </Container>
  );
};
