import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import { IntlShape } from "react-intl";
import { releaseHeaderLowerPart } from "./releaseHeaderLowerPart";
import { releaseHeaderUpperPart } from "./releaseHeaderUpperPart";

export const releaseHeader = (
  doc: jsPDF,
  release: ExpandedRelease | DistributorReleaseFieldsFragment,
  intl: IntlShape,
  topSpacing: number,
  contractorLogo: string,
  contractorLogoProportion: number,
) => {
  let topOffset = topSpacing;

  topOffset = releaseHeaderUpperPart(
    doc,
    release,
    intl,
    topOffset,
    contractorLogo,
    contractorLogoProportion,
  );

  topOffset = releaseHeaderLowerPart(doc, release, intl, topOffset + 8);

  return topOffset;
};
