import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { AddEstimatedItemInput } from "@/generated/graphql";
import {
  ControlPoint,
  DeleteOutline,
  DragHandle,
  Place,
} from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import { useProjectMaps } from "../../hooks/useProjectMaps";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";
import { ProjectBulkCostCodeSelector } from "./ProjectBulkCostCodeSelector";
import { ProjectBulkPhaseCodeSelector } from "./ProjectBulkPhaseCodeSelector";
import { ProjectBulkZoneSelector } from "./ProjectBulkZoneSelector";

export const ProjectItemsSideActions = () => {
  const {
    selectedEstimatedItemIds,
    setSelectedEstimatedItemIds,
    updateEstimatedItems,
  } = useEstimatedItems();
  const { project } = useProject();
  const [loading, setLoading] = useState(false);
  const viewState = useTableViewStore((state) => state.viewState);
  const { estimatedItemsMap } = useProjectMaps(project);
  const { hasPhaseCodes } = useOrgSettings();

  const canModifyItems = useMemo(
    () => selectedEstimatedItemIds.length > 0,
    [selectedEstimatedItemIds],
  );

  const deleteItems = useCallback(async () => {
    if (project && selectedEstimatedItemIds) {
      setLoading(true);
      await updateEstimatedItems({
        projectId: project.id,
        removedItems: selectedEstimatedItemIds,
        updates: [],
      });
      setLoading(false);
      setSelectedEstimatedItemIds([]);
    }
  }, [
    project,
    selectedEstimatedItemIds,
    setSelectedEstimatedItemIds,
    updateEstimatedItems,
  ]);

  const duplicate = useCallback(async () => {
    if (project && selectedEstimatedItemIds) {
      setLoading(true);
      await updateEstimatedItems({
        projectId: project.id,
        addedItems: selectedEstimatedItemIds
          .map((id) => {
            const item = project.items.find((i) =>
              i.estimatedItems.some((e) => e.id === id),
            );
            const estimatedItem = estimatedItemsMap.get(id);
            if (item) {
              return {
                orgMaterialId: item.material?.id,
                quantityDecimal: estimatedItem?.quantityDecimal,
                manufacturerId: item.manufacturer?.id,
                uom: item.estimateUom.pluralDescription,
                zoneId: estimatedItem?.zone?.id,
                costCodeId: estimatedItem?.costCode?.id,
                unitPrice: estimatedItem?.unitPrice,
              } as AddEstimatedItemInput;
            }
            return null;
          })
          .filter((i) => i !== null) as AddEstimatedItemInput[],
        updates: [],
      });
      setLoading(false);
      setSelectedEstimatedItemIds([]);
    }
  }, [
    project,
    selectedEstimatedItemIds,
    updateEstimatedItems,
    setSelectedEstimatedItemIds,
    estimatedItemsMap,
  ]);

  const actions = useMemo(() => {
    return [
      {
        icon: <ControlPoint />,
        tooltipKey: "DUPLICATE_PROJECT_ITEMS",
        callback: duplicate,
      },
      ...(canModifyItems
        ? [
            {
              icon: <Place />,
              tooltipKey: "ASSIGN_ZONE",
              component: <ProjectBulkZoneSelector setLoading={setLoading} />,
            },
            {
              icon: <DragHandle />,
              tooltipKey: hasPhaseCodes
                ? "ASSIGN_PHASE_CODE"
                : "ASSIGN_COST_CODE",
              component: hasPhaseCodes ? (
                <ProjectBulkPhaseCodeSelector setLoading={setLoading} />
              ) : (
                <ProjectBulkCostCodeSelector setLoading={setLoading} />
              ),
            },
            {
              icon: <DeleteOutline />,
              tooltipKey: "DELETE_ITEMS",
              callback: deleteItems,
            },
          ]
        : []),
    ];
  }, [duplicate, canModifyItems, deleteItems, hasPhaseCodes]);

  return (
    <SideActionBar
      loading={loading}
      visible={
        selectedEstimatedItemIds.length > 0 &&
        viewState === TableViewState.normal
      }
      actions={actions}
    ></SideActionBar>
  );
};
