import { InstructionInput } from "@/generated/graphql";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

export const useInstruction = () => {
  const { setValue } = useFormContext();

  const saveInstruction = useCallback(
    (instruction: InstructionInput) => {
      setValue("instructions", instruction);
    },
    [setValue],
  );

  const saveDescription = useCallback(
    (description: string) => {
      setValue("description", description);
    },
    [setValue],
  );

  return { saveDescription, saveInstruction };
};
