import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DateView } from "@/common/utils/dates/DateView";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const DateItem = tw.div`text-sm font-medium text-center`;
const Type = tw.div`text-xs text-center text-gray-600`;
const WarehouseName = tw.div`overflow-hidden text-ellipsis text-nowrap`;

type Props = {
  release: Pick<
    ReleasePartialFieldsFragment,
    "time" | "project" | "warehouse" | "timeTBD"
  >;
  isDelivery: boolean;
};

export const ReleaseTimeAndType: FC<Props> = ({ release, isDelivery }) => {
  const intl = useIntl();
  return (
    <Tooltip
      className="col-span-2 overflow-hidden"
      element={
        <>
          <DateItem>
            <DateView
              date={release.time}
              noDateLabel={
                release.timeTBD
                  ? intl.$t({ id: "HOLD_FOR_RELEASE" })
                  : undefined
              }
            />
          </DateItem>
          <Type>
            {isDelivery ? (
              <>
                {release.warehouse ? (
                  <WarehouseName>
                    <FormattedMessage
                      id="FORMATTED_WAREHOUSE_NAME"
                      values={{ name: release.warehouse?.name }}
                    />
                  </WarehouseName>
                ) : (
                  <FormattedMessage id={"PROJECT_LOCATION_WITH_ADDRESS"} />
                )}
              </>
            ) : (
              <FormattedMessage id={"CLIENT_DELIVERY"} />
            )}
          </Type>
        </>
      }
      position="top"
      id="delivery-tooltip"
    >
      {isDelivery ? (
        release.warehouse ? (
          <FormattedMessage
            id="FORMATTED_WAREHOUSE_NAME"
            values={{ name: release.warehouse?.name }}
          />
        ) : (
          <FormattedMessage id={"PROJECT_LOCATION_WITH_ADDRESS"} />
        )
      ) : (
        <FormattedMessage id={"CLIENT_DELIVERY"} />
      )}
    </Tooltip>
  );
};
