import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import { SourceSystem } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  sourceSystem?: SourceSystem;
  onClick?: () => void;
  disableClick?: boolean;
};

const Image = tw.img<{
  onClick: (() => void) | undefined;
}>`w-6 h-6 rounded-sm ${({ onClick }) => (onClick ? "cursor-pointer" : "")}`;

export const ConnectionIcon: FC<Props> = ({
  sourceSystem,
  disableClick,
  onClick,
}) => {
  const { sourceSystemLogo } = useSourceSystemLogo(sourceSystem, true);

  if (!sourceSystem) {
    return null;
  }

  return (
    <Image
      src={sourceSystemLogo}
      alt={sourceSystem}
      onClick={disableClick ? undefined : onClick}
    />
  );
};
