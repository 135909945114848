import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { routes } from "@/config/routes";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { RfqQuotesFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { useGetOrderFromQuote } from "./useGetOrderFromQuote";

type Props = {
  rfq: RfqQuotesFieldsFragment | undefined | null;
  isDisabled: boolean;
};

export const ProceedButton: FC<Props> = ({ isDisabled }: Props) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const {
    acceptQuote,
    rfq,
    selectedQuotes,
    allQuoteItems,
    selectedAuxiliaryQuoteItems,
    auxiliaryItems,
  } = useRfqQuotes();
  const getNewOrderFromRfqQuote = useGetOrderFromQuote();
  const { createStandaloneRelease } = useCreateRelease();
  const navigate = useNavigate();

  const createBuyout = useCallback(async () => {
    const firstBuyoutId = await acceptQuote();
    if (firstBuyoutId) {
      navigate(generatePath(routes.buyout, { id: firstBuyoutId }));
    }
  }, [acceptQuote, navigate]);

  const createOrder = useCallback(async () => {
    const input = await getNewOrderFromRfqQuote({
      rfq,
      selectedQuotes,
      allQuoteItems,
      selectedAuxiliaryQuoteItems,
      auxiliaryItems,
    });
    const release = await createStandaloneRelease(input);
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, { deliveryId: release.id }),
      );
    }
  }, [
    createStandaloneRelease,
    getNewOrderFromRfqQuote,
    navigate,
    rfq,
    selectedQuotes,
    allQuoteItems,
    selectedAuxiliaryQuoteItems,
    auxiliaryItems,
  ]);

  const proceedWithSelection = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "CREATE_BUYOUT" }),
      customButtonText: intl.$t({ id: "CREATE_ORDER" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "PROCEED_WITH_SELECTED_ITEMS" }),
      text: intl.$t({ id: "PROCEED_WITH_SELECTED_ITEMS_DETAILS" }),
      handleConfirm: createBuyout,
      handleCustomButtonAction: createOrder,
      buttonsConfiguration: {
        custom: {
          type: "primary",
        },
      },
    });
  }, [createBuyout, createOrder, intl, openDialog]);

  return (
    <PrimaryButton
      onClick={proceedWithSelection}
      disabled={isDisabled}
      testId="accept-quotes-button"
      autoFocus
    >
      <FormattedMessage id="PROCEED_WITH_SELECTION" />
    </PrimaryButton>
  );
};
