import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { getDiscountByAmount } from "@/common/components/price/utils/getDiscountByAmount";
import { getDiscountByPercentage } from "@/common/components/price/utils/getDiscountByPercentage";
import { sumQuoteGroupFullPrices } from "@/common/components/price/utils/sumQuoteGroupFullPrices";
import { TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  QuoteStatus,
  RfqQuotesQuoteFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useBidsPrices } from "../../../providers/BidsPricesProvider";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";

type TotalQuotePriceProps = {
  quote: RfqQuotesQuoteFieldsFragment;
};

type TotalQuotePriceContainerProps = {
  $deactivated?: boolean;
  $highlighted?: boolean;
};

const TotalQuotePriceContainer = tw.div<TotalQuotePriceContainerProps>`
  relative w-full
  ${({ $deactivated }: TotalQuotePriceContainerProps) =>
    $deactivated && "text-gray-500"}  
`;

const PriceContainer = tw.div<TotalQuotePriceContainerProps>`
  relative font-medium text-base py-3 px-4 w-full
  ${({ $highlighted }: TotalQuotePriceContainerProps) =>
    $highlighted &&
    "bg-blue-500 bg-opacity-10 border-2 border-blue-500 rounded-3xl"}
`;

const MultiSelection = tw.div``;

const SelectedProductsCount = tw.div`
  font-medium text-xs text-gray-500 mt-1 mr-9
`;

export const TotalQuotePrice: FC<TotalQuotePriceProps> = ({ quote }) => {
  const {
    rfq,
    multiQuote,
    selectedQuotes,
    allQuoteItems,
    selectedAuxiliaryQuoteItems,
  } = useRfqQuotes();

  const { selectEntireQuoteByBestPrice } = useBidsPrices();
  const { calcExtPrice, calcAdditionalChargesPrice } = usePriceCalculation();

  const price = useMemo(() => {
    let quoteItems = allQuoteItems.filter(
      (i) => i.status !== QuoteStatus.Withdrawn && i.quoteId === quote.id,
    );

    if (selectedQuotes.length === 0) {
      const entireQuote = selectEntireQuoteByBestPrice(quote?.id, rfq?.id);

      quoteItems = quoteItems.filter((i) =>
        entireQuote.quoteItems.find((q) => q.quoteItemId === i.id),
      );
    }

    const sumMainItems = quoteItems.reduce((prevValue, item) => {
      let currentPrice;

      if (multiQuote) {
        const isItemSelected = selectedQuotes.find(
          (quote) =>
            quote.quoteItemId === item.id && quote.rfqItemId === item.rfqItemId,
        );
        currentPrice = isItemSelected
          ? new Decimal(
              calcExtPrice(item.quantityDecimal ?? 0, item.unitPrice ?? 0),
            )
          : new Decimal(0);
      } else {
        currentPrice = new Decimal(item.unitPrice ?? 0).mul(
          item.quantityDecimal ?? 0,
        );
      }

      return Number(currentPrice.add(prevValue));
    }, 0);

    const sumAuxiliaryItems = quote.auxiliaryItems.reduce((prevValue, item) => {
      const price = selectedAuxiliaryQuoteItems.find(
        (aux) => aux.itemId === item.id,
      )
        ? new Decimal(item.unitPrice ?? 0).mul(item.quantityDecimal ?? 0)
        : new Decimal(0);

      return Number(price.add(new Decimal(prevValue)));
    }, 0);

    const chargesAmount = calcAdditionalChargesPrice(quote.additionalCharges);
    const totalSum = Number(
      new Decimal(sumMainItems).add(sumAuxiliaryItems).add(chargesAmount),
    );

    if (quote.discount) {
      let amount;
      if (quote.discount.percentage) {
        amount = getDiscountByPercentage(totalSum, quote.discount.percentage);
      }
      if (quote.discount.amount) {
        amount = getDiscountByAmount(
          quote.discount.amount ?? "0",
          quote.itemGroups.reduce(sumQuoteGroupFullPrices, 0),
        ).mul(totalSum);
      }
      return amount ? Number(new Decimal(totalSum).sub(amount)) : totalSum;
    }

    return totalSum;
  }, [
    allQuoteItems,
    selectedQuotes,
    quote.auxiliaryItems,
    quote.additionalCharges,
    quote.discount,
    quote.id,
    quote.itemGroups,
    calcAdditionalChargesPrice,
    selectEntireQuoteByBestPrice,
    rfq?.id,
    multiQuote,
    calcExtPrice,
    selectedAuxiliaryQuoteItems,
  ]);

  const selectedQuoteItems = useMemo(() => {
    return selectedQuotes.filter((qi) => qi.quoteId === quote.id);
  }, [quote, selectedQuotes]);

  const hasSelection = useMemo(() => {
    return selectedQuoteItems.length > 0 && price > 0;
  }, [selectedQuoteItems, price]);

  const isDeactivated = useMemo(() => {
    return (
      (rfq?.status === RfqStatus.Awarded ||
        rfq?.status === RfqStatus.Cancelled) &&
      quote.status !== QuoteStatus.Accepted
    );
  }, [rfq, quote]);

  return (
    <TotalQuotePriceContainer $deactivated={isDeactivated}>
      <PriceContainer $highlighted={hasSelection}>
        <Price
          price={price}
          maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
        />
      </PriceContainer>
      <If isTrue={hasSelection}>
        <MultiSelection>
          <SelectedProductsCount>
            <FormattedMessage
              id="SELECTED_MULTI_BID_PRODUCTS_COUNT"
              values={{
                selected:
                  selectedQuoteItems.length +
                  selectedAuxiliaryQuoteItems.length,
              }}
            />
          </SelectedProductsCount>
        </MultiSelection>
      </If>
    </TotalQuotePriceContainer>
  );
};
