import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { useUser } from "@/common/providers/UserProvider";
import { EditIcon, EditIconFilled } from "@/distributor/common/Common.styles";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ReleaseAdditionalChargesAndTaxesProps } from "../release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { ReleaseTermsAndNotesPanel } from "./ReleaseTermsAndNotesPanel";

const LinkLikeStyled = tw(LinkLike)`justify-end mr-10`;

type Props = Pick<
  ReleaseAdditionalChargesAndTaxesProps,
  "release" | "updateRelease"
>;

export const ReleaseTermsAndNotes: FC<Props> = ({ release, updateRelease }) => {
  const { isContractor } = useUser();

  if (!release || (isContractor && !release.notes)) {
    return null;
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <LinkLikeStyled onClick={() => togglePanel(true)}>
          <If isTrue={release.notes || release.assets.length > 0}>
            <EditIconFilled />
          </If>
          <If isTrue={!release.notes && !release.assets.length}>
            <EditIcon />
          </If>
          <FormattedMessage id="NOTES_TERMS_DOCS" />
        </LinkLikeStyled>
      )}
      content={(togglePanel) => (
        <ReleaseTermsAndNotesPanel
          onClose={() => togglePanel(false)}
          release={release}
          updateRelease={updateRelease}
        />
      )}
    />
  );
};
