import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { DialogActions } from "@mui/material";
import { PickersActionBarProps } from "@mui/x-date-pickers";
import { useLocalizationContext } from "@mui/x-date-pickers/internals";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  flex rounded-4xl py-2 gap-2 items-center w-full
  ${({ $includeCustomAction }: { $includeCustomAction: boolean }) => ($includeCustomAction ? "justify-evenly" : "justify-around")}
`;

export const ReleaseDatePickerCustomActionBar = ({
  onAccept,
  actions,
  className,
  onClick,
  setIsOpened,
  includeCustomAction = true,
}: PickersActionBarProps & {
  setIsOpened?: (value: boolean) => void;
  includeCustomAction?: boolean;
}) => {
  const { localeText } = useLocalizationContext();
  const intl = useIntl();

  if (actions == null || actions.length === 0) {
    return null;
  }

  return (
    <DialogActions className={className}>
      <Container $includeCustomAction={includeCustomAction}>
        <OutlinedButton
          onClick={() => {
            onAccept();
          }}
        >
          {localeText.cancelButtonLabel}
        </OutlinedButton>
        <If isTrue={includeCustomAction}>
          <PrimaryButton
            onClick={(e) => {
              onClick?.(
                e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>,
              );
              setIsOpened?.(false);
            }}
          >
            {intl.$t({ id: "HOLD_FOR_RELEASE" })}
          </PrimaryButton>
        </If>
        <PrimaryButton
          onClick={() => {
            onAccept();
          }}
        >
          {localeText.okButtonLabel}
        </PrimaryButton>
      </Container>
    </DialogActions>
  );
};
