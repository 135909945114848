import { DeleteButton } from "@/common/components/button/DeleteButton";
import {
  ServiceCodeFieldsFragment,
  ServiceCodesDocument,
  ServiceCodesQuery,
  useUpdateServiceCodesMutation,
} from "@/generated/graphql";
import { FC, useCallback } from "react";

type Props = {
  item: ServiceCodeFieldsFragment;
};

export const ServiceCodeActions: FC<Props> = ({ item }) => {
  const [updateServiceCodesMutation] = useUpdateServiceCodesMutation();

  const deleteServiceCode = useCallback(async () => {
    await updateServiceCodesMutation({
      variables: {
        input: {
          deleted: [item.id],
        },
      },
      update: (cache) => {
        const queryServiceCodes = cache.readQuery<ServiceCodesQuery>({
          query: ServiceCodesDocument,
        });
        cache.writeQuery({
          query: ServiceCodesDocument,
          data: {
            viewer: {
              ...queryServiceCodes?.viewer,
              org: {
                ...queryServiceCodes?.viewer?.org,
                serviceCodes:
                  queryServiceCodes?.viewer?.org.serviceCodes.filter(
                    (code) => code.id !== item.id,
                  ),
              },
            },
          },
        });
      },
    });
  }, [item.id, updateServiceCodesMutation]);

  return <DeleteButton onClick={deleteServiceCode} />;
};
