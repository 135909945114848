import { If } from "@/common/components/if/If";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import {
  vendorLabelFormatter,
  VendorPickerCustomRender,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { EditableRfqNumber } from "@/contractor/pages/home/rfq-master-catalog-import/components/RfqNumberWithToggle";
import { useRfq } from "@/contractor/pages/home/rfq/hooks/useRfq";
import { FC, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { CreateRfqFromNoteFormValues } from "./CreateRfqFromNoteForm";

const Container = tw.div`
  w-full grid gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md
`;
const InputsContainer = tw.div`
  h-full grid gap-2 w-full justify-between
`;
const VendorTooltip = tw.div`max-w-[400px]`;

export const CreateRfqFromNoteHeader: FC = () => {
  const intl = useIntl();
  const { locations, loading: loadingLocations } = useUserLocations();
  const { rfq } = useRfq();
  const [preferredVendors, setPreferredVendors] =
    useState<Array<string> | null>(
      rfq?.targetSellersOrgLocations?.map((a) => a.id) || [],
    );
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { vendors, shouldShowVendorCode } = useVendors();
  const { watch, setValue } = useFormContext<CreateRfqFromNoteFormValues>();

  const businessLocationId = watch("businessLocationId");
  const projectOptions = useMemo(
    () =>
      projects.filter((project) => project.location.id === businessLocationId),
    [projects, businessLocationId],
  );

  useEffect(() => {
    if ((locations ?? []).length === 1) {
      setValue("businessLocationId", (locations ?? [])[0].id);
    }
  }, [locations, setValue]);

  useEffect(() => {
    if (projects.length === 1) {
      setValue("projectId", projects[0].id);
    }
  }, [projects, setValue]);

  return (
    <Container>
      <InputsContainer
        className={
          locations.length > 1
            ? "grid-cols-[1fr_2fr_2fr_2fr]"
            : "grid-cols-[1fr_2fr_2fr]"
        }
      >
        <EditableRfqNumber rfq={rfq} />
        <If isTrue={locations.length > 1}>
          <SelectControlled
            name="businessLocationId"
            options={locations ?? []}
            getValue={(o) => o.id}
            getLabel={(o) => o.name}
            placeholder={intl.$t({ id: "BUSINESS_LOCATION" })}
            loading={loadingLocations}
            disabled={!!rfq}
            required
            rules={{
              required: true,
            }}
          />
        </If>
        <SelectControlled
          name="projectId"
          options={projectOptions}
          getValue={(o) => o.id}
          getLabel={(o) => getProjectSelectorLabel(o)}
          placeholder={intl.$t({ id: "PROJECT_NAME" })}
          loading={loadingProjects}
          required
          rules={{
            required: true,
          }}
        />
        <MultiselectControlled
          name="vendorIds"
          placeholder={intl.$t({ id: "PREFERRED_VENDORS" })}
          options={vendors || []}
          customRender={(item) =>
            VendorPickerCustomRender(item, {
              filter: (c) => c.receivesQuoteNotifications,
              shouldShowVendorCode,
            })
          }
          limitTags={1}
          values={preferredVendors}
          onMultipleChange={setPreferredVendors}
          getLabel={(o) =>
            vendorLabelFormatter(
              o.sellerOrgLocation,
              o.contacts.filter((c) => c.receivesQuoteNotifications),
              {
                vendorCode: shouldShowVendorCode ? o.externalCode : null,
              },
            )
          }
          sx={{
            ".MuiInputBase-formControl.MuiAutocomplete-inputRoot": {
              flexWrap: "nowrap",
            },
          }}
          className="min-w-full"
          testId="rfq-form-vendor-selector"
          getValue={(option) => option.sellerOrgLocation.id}
          disableCloseOnSelect
          chipSize="small"
          includeCheckboxFn={(option) => option.contacts.length > 0}
          optionDisabledFn={(option) => option.contacts.length === 0}
          optionTooltipRenderFn={(option) =>
            option.contacts.length === 0 ? (
              <VendorTooltip>
                {intl.$t({ id: "VENDOR_DISABLED_TOOLTIP" })}
              </VendorTooltip>
            ) : undefined
          }
          required
          rules={{
            required: true,
          }}
        />
      </InputsContainer>
    </Container>
  );
};
