import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterProps,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useBuyoutGrouped } from "../../document/providers/BuyoutGroupedProvider";

const marginRightClass = "pr-4";

export const BuyoutStandaloneItemsOrderedTotalFooter: FC<FooterProps> = ({
  Wrapper,
}) => {
  const {
    orderedStandaloneSalesTax,
    orderedStandaloneSubtotal,
    orderedStandaloneTotal,
  } = useBuyoutGrouped();

  return (
    <FooterCol>
      <Wrapper className="flex-col items-end">
        <FooterHeader className={marginRightClass}>
          <FormattedMessage id="CLIENT_ORDERED" tagName={FooterHeaderText} />
        </FooterHeader>
        <FooterCell className={marginRightClass}>
          <Price price={orderedStandaloneSubtotal} className="font-light" />
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={orderedStandaloneSalesTax}>
            <Price price={orderedStandaloneSalesTax} className="font-light" />
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal className="pr-0">
          <FooterCellCol className={marginRightClass}>
            <Price price={orderedStandaloneTotal} />
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
