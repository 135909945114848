import { useUser } from "@/common/providers/UserProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  BuyoutFieldsFragment,
  DistributorBuyoutFieldsFragment,
  UpdateContractorBuyoutInput,
  UpdateVendorBuyoutInput,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AdditionalChargesContainer,
  AdditionalChargesItemContainer,
  ItemContainer,
  TotalItemContainer,
  TotalItemOuter,
} from "../additional-charges/AdditionalCharges.styles";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import {
  OrgRolesWrapper,
  Permission,
} from "../org-roles-wrapper/OrgRolesWrapper";
import { PaymentTerms } from "../payment-terms/PaymentTerms";
import { PricePicker } from "../price-picker/PricePicker";
import { Price } from "../price/Price";
import { SalesTax } from "../sales-tax-input/SalesTax";
import { SalesTaxInput } from "../sales-tax-input/types/SalesTaxInputType";
import { Tooltip } from "../tooltip/Tooltip";
import { vendorLabelFormatter } from "../vendor-picker/VendorPickerCustomRender";

const NoPriceContainer = tw.div`flex justify-end items-center`;
const AdditionalChargesContainerStyled = tw(AdditionalChargesContainer)`my-4`;
const TotalItem = tw.div`-mr-1`;
const Sub = tw.span`font-normal`;
const Item = tw.div`font-medium text-sm`;
const LinkLikeStyled = tw(LinkLike)`pl-1`;

const SubTotalItemContainer = tw(
  AdditionalChargesItemContainer,
)`text-base pr-10`;

type Props = {
  buyout?:
    | BuyoutFieldsFragment
    | DistributorBuyoutFieldsFragment
    | null
    | undefined;
  updateBuyout?: (
    input: UpdateContractorBuyoutInput | UpdateVendorBuyoutInput,
  ) => Promise<boolean>;
  totalDivider?: JSX.Element;
  total: string | null;
  includePaymentTerms?: boolean;
  editablePaymentTerms?: boolean;
  readonlySalesTax?: boolean;
  onTaxRateChange?: (value: string) => void;
  readonly?: boolean;
  buyoutInput?: SalesTaxInput;
  readonlyPaymentTerms?: boolean;
  includeAdditionalChargesAllowance?: boolean;
  readonlyAdditionalChargesAllowance?: boolean;

  //TODO: move to store
  netAmount?: string | null;
};

export const BuyoutAdditionalChargesAndTaxes: FC<Props> = ({
  buyout,
  updateBuyout,
  totalDivider,
  total,
  includePaymentTerms,
  buyoutInput,
  readonly = false,
  readonlyPaymentTerms = false,
  includeAdditionalChargesAllowance,
  readonlyAdditionalChargesAllowance,
  readonlySalesTax,
  netAmount,
}) => {
  const { isContractor } = useUser();
  const { hasAdditionalCharges } = useOrgSettings();

  const salesTaxInput: SalesTaxInput = useMemo(() => {
    const charges =
      buyoutInput && buyoutInput.chargesAmount
        ? buyoutInput.chargesAmount
        : (buyout?.additionalChargesAllowance ??
          (buyout?.additionalCharges?.reduce((acc, charge) => {
            return new Decimal(acc).add(charge.amount);
          }, new Decimal(0)) ||
            new Decimal(0)));
    const computedSubtotal =
      netAmount ??
      new Decimal(
        buyoutInput ? buyoutInput.netAmount || 0 : buyout?.netAmount || 0,
      ).toString();

    return {
      taxRate: buyoutInput ? buyoutInput.taxRate : buyout?.taxRate,
      customTaxAmount: buyoutInput
        ? buyoutInput.customTaxAmount
        : buyout?.customTaxAmount,
      orderTypeId: buyoutInput
        ? buyoutInput.orderTypeId
        : buyout?.releaseType?.id,
      taxAmount: buyoutInput ? buyoutInput.taxAmount : buyout?.taxAmount,
      netAmount: computedSubtotal,
      id: buyout?.id,
      version: buyout?.version,
      taxCodeId: buyoutInput ? buyoutInput.taxCodeId : buyout?.taxCode?.id,
      taxType: buyoutInput ? buyoutInput.taxType : buyout?.taxType,
      chargesAmount: charges.toString(),
    };
  }, [
    buyoutInput,
    buyout?.additionalChargesAllowance,
    buyout?.additionalCharges,
    buyout?.netAmount,
    buyout?.taxRate,
    buyout?.customTaxAmount,
    buyout?.releaseType?.id,
    buyout?.taxAmount,
    buyout?.id,
    buyout?.version,
    buyout?.taxCode?.id,
    buyout?.taxType,
    netAmount,
  ]);

  return (
    <OrgRolesWrapper
      permissions={isContractor ? [Permission.canViewPrices] : []}
    >
      <AdditionalChargesContainerStyled>
        <SubTotalItemContainer>
          <FormattedMessage id="SUBTOTAL" tagName={Item} />
          <Price
            price={salesTaxInput?.netAmount}
            className="text-sm font-normal"
            zeroValuePlaceholder={
              <NoPriceContainer>
                --{" "}
                <Tooltip
                  id="subtotal-price"
                  element={
                    <LinkLikeStyled onClick={() => null} forwardEvent={false}>
                      <InfoOutlined />
                    </LinkLikeStyled>
                  }
                >
                  <FormattedMessage id="NO_SUBTOTAL_TOOLTIP" />
                </Tooltip>
              </NoPriceContainer>
            }
          />
        </SubTotalItemContainer>
        <If
          isTrue={
            includeAdditionalChargesAllowance &&
            (hasAdditionalCharges ||
              (salesTaxInput.chargesAmount &&
                Number(salesTaxInput.chargesAmount) > 0))
          }
        >
          <ItemContainer>
            <FormattedMessage
              id="ADDITIONAL_CHARGES_ALLOWANCE"
              tagName={Item}
            />
            <PricePicker
              className="text-right"
              value={salesTaxInput.chargesAmount}
              readonly={readonly || readonlyAdditionalChargesAllowance}
              onBlur={(value) =>
                updateBuyout?.({
                  buyoutId: buyout?.id || "",
                  version: buyout?.version || 0,
                  additionalChargesAllowance: value,
                })
              }
            />
          </ItemContainer>
        </If>
        <PaymentTerms
          item={buyout}
          includePaymentTerms={includePaymentTerms}
          readonly={readonly || readonlyPaymentTerms}
          onChange={(paymentTerm) => {
            updateBuyout?.({
              buyoutId: buyout?.id || "",
              version: buyout?.version || 0,
              paymentTermDays: Number(paymentTerm),
            });
          }}
        />
        <SalesTax
          canEdit={!readonly && !readonlySalesTax}
          salesTaxInput={salesTaxInput}
          update={async ({ id, ...input }) => {
            await updateBuyout?.({
              ...(input as UpdateContractorBuyoutInput),
              ...(id && { buyoutId: id }),
            });
          }}
          fallbackOrderTypes={buyout?.releaseType ? [buyout?.releaseType] : []}
          fallbackTaxCodes={buyout?.taxCode ? [buyout.taxCode] : []}
          taxExempt={{
            isProjectTaxExempt: buyout?.project?.taxExempt,
            isVendorTaxExempt: buyout?.preferredVendor?.taxExempt,
            vendorName: vendorLabelFormatter(buyout?.sellerOrgLocation),
          }}
          includeTaxVariance={false}
        />
        {totalDivider}
        <TotalItemOuter>
          <TotalItemContainer $highlightTotal={true}>
            <FormattedMessage
              id="TOTAL_BUYOUT"
              values={{ sub: (...chunks) => <Sub>{chunks}</Sub> }}
              tagName={TotalItem}
            />
            <Price
              price={total}
              maximumFractionDigits={2}
              zeroValuePlaceholder={
                <NoPriceContainer>
                  --{" "}
                  <Tooltip
                    id="subtotal-price"
                    element={
                      <LinkLikeStyled forwardEvent={false} onClick={() => null}>
                        <InfoOutlined />
                      </LinkLikeStyled>
                    }
                  >
                    <FormattedMessage id="NO_TOTAL_TOOLTIP" />
                  </Tooltip>
                </NoPriceContainer>
              }
              className="font-bold"
              testId="total-price"
            />
          </TotalItemContainer>
        </TotalItemOuter>
      </AdditionalChargesContainerStyled>
    </OrgRolesWrapper>
  );
};
