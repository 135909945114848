import { ReleaseItem } from "@/generated/graphql";
import Decimal from "decimal.js";
import { filterReleaseItemsByDates } from "../../../../../../common/components/release-items-date-range-filter/utils/filterReleaseItemsByDates";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import {
  ReleaseItemFilter,
  ReleaseItemsFilters,
} from "../../types/ReleaseItemsFilters";

const filterFullyReceived = (
  item: Pick<ReleaseItem, "receivedQuantityDecimal" | "quantityDecimal">,
  filter: ReleaseItemsFilters,
) => {
  switch (filter[ReleaseItemFilter.FULLY_RECEIVED]) {
    case false:
      return new Decimal(item.receivedQuantityDecimal || 0).lessThan(
        item.quantityDecimal,
      );
    case true:
      return new Decimal(
        item.receivedQuantityDecimal || 0,
      ).greaterThanOrEqualTo(item.quantityDecimal);
    default:
      return true;
  }
};

const filterFullyInvoiced = (
  item: Pick<
    ReleaseItem,
    "receivedQuantityDecimal" | "quantityDecimal" | "invoicedQuantity"
  >,
  filter: ReleaseItemsFilters,
) => {
  switch (filter[ReleaseItemFilter.FULLY_INVOICED]) {
    case false:
      return new Decimal(item.invoicedQuantity).lessThan(item.quantityDecimal);
    case true:
      return new Decimal(item.invoicedQuantity).greaterThanOrEqualTo(
        item.quantityDecimal,
      );
    default:
      return true;
  }
};

const filterTags = (item: ExpandedReleaseItem, filteredTags: string[]) =>
  filteredTags.length
    ? item.tags.some((tag) => filteredTags.includes(tag.id))
    : true;

export const filterReleaseItems = (
  items: ExpandedReleaseItem[],
  filter: ReleaseItemsFilters,
  filteredTags: string[],
  timeTbdFilter: boolean | undefined,
  filterDates?: {
    minDate?: number;
    maxDate?: number;
  } | null,
) =>
  items
    .filter((item) => filterFullyInvoiced(item, filter))
    .filter((item) => filterFullyReceived(item, filter))
    .filter((item) => filterTags(item, filteredTags))
    .filter((item) =>
      filterReleaseItemsByDates(item, timeTbdFilter, filterDates),
    );
