import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { Loader } from "@/common/components/loader/Loader";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import {
  EmptyListContainer,
  ReleaseItemList,
} from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import { ReleaseStatus } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipOrderConfiguration } from "./DeliverySlipOrderConfiguration";

const Footer = tw.div`mr-7 mt-10 pb-5`;
const EmptyListContainerStyled = tw(EmptyListContainer)`h-40 flex-col gap-4`;

type Props = {
  readonly?: boolean;
  assignOrder: () => Promise<boolean>;
};

export const DeliverySlipOrderItems: FC<Props> = ({
  readonly,
  assignOrder,
}) => {
  const { release } = useRelease();
  const configuration = useDeliverySlipOrderConfiguration({
    projectId: release?.project?.id,
  });
  const { updateRelease } = useReleaseUpdate();

  const items = useMemo(
    () =>
      release?.items.map((item) => {
        const estimatedItems = item.projectItem?.estimatedItems.filter(
          (ei) => ei.zone?.id === item.zone?.id,
        );
        return {
          ...{
            ...item.projectItem,
            material: {
              ...item.projectItem?.material,
              material: {
                ...item.projectItem?.material.material,
                name: item.name,
              },
            },
            estimatedItems,
          },
          ...item,
        } as ExpandedReleaseItem;
      }) ?? [],
    [release?.items],
  );

  const itemsCount = useMemo(
    () => ({
      partial: release?.items.filter((i) => i.unitPrice !== null).length || 0,
      total: release?.items.length || 0,
    }),
    [release?.items],
  );

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseItemsZoneProvider items={items}>
      <ReleaseItemList
        columns={configuration}
        classNames={{
          header: "-top-4 bg-gray-100",
          category: "top-9",
          subCategory: (groupedByZones: boolean) =>
            groupedByZones ? "top-20" : "top-9",
        }}
        emptyList={
          release.status === ReleaseStatus.Draft ||
          release.status === ReleaseStatus.Reserved ? (
            <EmptyListContainerStyled>
              <FormattedMessage id="NO_RELEASE_ITEMS_ASSIGNED" />
              <PrimaryButton $small onClick={assignOrder}>
                <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_PACKING_SLIP" />
              </PrimaryButton>
            </EmptyListContainerStyled>
          ) : undefined
        }
      />
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          release={release}
          total={release.total}
          itemsCount={itemsCount}
          editableByContractor={!readonly}
          updateRelease={updateRelease}
          taxExempt={{
            isProjectTaxExempt: release.project?.taxExempt,
            isVendorTaxExempt: release.preferredVendor?.taxExempt,
            vendorName: vendorLabelFormatter(release.sellerOrgLocation),
          }}
          includeNotesPanel={false}
          includePaymentTerms={true}
          readonly
        />
      </Footer>
    </ReleaseItemsZoneProvider>
  );
};
