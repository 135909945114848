import {
  NumericalInput,
  ReadonlySx,
} from "@/common/components/numerical-input/NumericalInput";
import { MAX_PRICE_DECIMALS } from "@/common/const";
import { useUser } from "@/common/providers/UserProvider";
import { InputLabelProps, InputProps } from "@mui/material";
import React, { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

const UnitsInputContainer = tw.div`
  relative w-full
`;

const InputStyled = tw(NumericalInput)`
  w-full bg-white print:bg-transparent
`;

type Props = {
  value: string | number | null | undefined;
  readonly?: boolean;
  index?: number;
  onBlur?: (value: string | null) => void;
  error?: boolean;
  errorMessage?: string;
  label?: string | React.JSX.Element;
  className?: string;
  hideErrorIcon?: boolean;
  placeholder?: string;
  shrink?: boolean;
  labelProps?: InputLabelProps;
  InputProps?: InputProps;
  testId?: string;
  decimals?: number;
  helperText?: string | React.JSX.Element;
};

export const PricePicker: FC<Props> = ({
  value,
  readonly = false,
  index,
  onBlur,
  label,
  error,
  errorMessage,
  className,
  hideErrorIcon,
  placeholder,
  shrink = true,
  labelProps,
  InputProps,
  testId,
  decimals,
  helperText,
}) => {
  const { currencySymbol } = useUser();
  const unitPrice = useMemo(() => {
    if (value === null) {
      return null;
    }
    const price = Number(value || 0);
    if (Math.round(price) === price) {
      return price.toFixed(2);
    }
    return price;
  }, [value]);
  return (
    <UnitsInputContainer data-testid={`units-input-${index}`}>
      <InputStyled
        value={unitPrice}
        label={label}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          onBlur?.(
            event.target.value
              .replaceAll(",", "")
              .replaceAll(currencySymbol, "") !== ""
              ? Number(
                  event.target.value
                    .replaceAll(",", "")
                    .replaceAll(currencySymbol, ""),
                ).toString()
              : null,
          );
        }}
        readonly={readonly}
        decimals={decimals || MAX_PRICE_DECIMALS}
        dynamicDigits
        InputProps={InputProps}
        inputProps={{
          disabled: readonly,
          style: { marginTop: 0 },
          className: `text-sm px-4 py-1.5 md:px-2 text-center ${className}`,
        }}
        sx={ReadonlySx}
        classes={{
          root: "print:child-first:hidden border-0 w-32",
          container: className,
        }}
        InputLabelProps={labelProps}
        placeholder={placeholder}
        autoComplete="off"
        className={className}
        error={error}
        errorMessage={errorMessage}
        prefix={currencySymbol}
        testId={testId || `price-input-${index}`}
        hideErrorIcon={hideErrorIcon}
        shrink={shrink}
        helperText={helperText}
      />
    </UnitsInputContainer>
  );
};
