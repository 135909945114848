type StorageKey = string | string[];

const STORAGE_KEY_SEPARATOR = ":";

const normalizeStorageKey = (key: StorageKey): string => {
  return (Array.isArray(key) ? key : [key]).join(STORAGE_KEY_SEPARATOR);
};

export const readValue = <T = string>(
  key: StorageKey,
  defaultValue: T | null = null,
) => {
  const value = window.localStorage.getItem(normalizeStorageKey(key));
  if (value) {
    try {
      return JSON.parse(value) as T;
    } catch {
      return value;
    }
  }
  return defaultValue;
};

export const readValueFromKeys = <T>(
  keys: StorageKey[],
  defaultValue: T | null = null,
) => {
  let value = defaultValue as ReturnType<typeof readValue>;
  keys.forEach((key) => {
    const storedValue = readValue<T>(key);
    if (storedValue) {
      value = storedValue;
      return false;
    }
  });

  return value;
};

export const setValue = <T>(key: StorageKey, value: T) => {
  const normalizedKey = normalizeStorageKey(key);
  if (value !== null && value !== undefined) {
    window.localStorage.setItem(normalizedKey, JSON.stringify(value));
  } else {
    window.localStorage.removeItem(normalizedKey);
  }
};

export const removeValue = (key: StorageKey) => {
  window.localStorage.removeItem(normalizeStorageKey(key));
};
