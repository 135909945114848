import { If } from "@/common/components/if/If";
import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { OrgRole } from "@/generated/graphql";
import { FC, PropsWithChildren, ReactNode, useMemo } from "react";
import tw from "tailwind-styled-components";
import { ValidationItem } from "./ValidationItem";

const Container = tw.div`relative`;
const ErrorList = tw.div`flex flex-col gap-2`;

export enum ValidationType {
  Error = "error",
  Warning = "warning",
}

export type ConnectionError = {
  id: string;
  condition?: boolean;
  includeCondition?: boolean;
  key: string;
  toolTipKey?: string;
  adminToolTipKey?: string;
  values?: Record<
    string,
    | string
    | number
    | ((chunks: ReactNode) => JSX.Element)
    | JSX.Element
    | undefined
    | null
  >;
  child?: ReactNode;
  button?: ReactNode;
  validationType?: ValidationType | undefined;
  hideInfoIcon?: boolean;
};

type Props = PropsWithChildren<{
  includedValidations: ConnectionError[];
  classes?: {
    item?: string;
  };
  includeDivider?: boolean;
}>;

export const ExportErrorList: FC<Props> = ({
  includedValidations,
  classes,
  includeDivider = true,
}) => {
  const { hasRoles: isAdmin } = useRoles({ roles: [OrgRole.OrgAdmin] });

  const sortedValidations: ConnectionError[] = useMemo(
    () =>
      includedValidations.sort((i) => {
        if (!i.validationType) {
          return -1;
        }

        if (i.validationType === ValidationType.Error) {
          return -1;
        }

        return 1;
      }),
    [includedValidations],
  );

  return (
    <If isTrue={includedValidations.length}>
      <Container>
        <ErrorList>
          {sortedValidations.map((validation, key) => (
            <ValidationItem
              validation={validation}
              key={key}
              iterationKey={key}
              includeDivider={includeDivider}
              classes={classes}
              isAdmin={isAdmin}
              index={sortedValidations.findIndex((v) => v === validation)}
            />
          ))}
        </ErrorList>
      </Container>
    </If>
  );
};
