import { DeleteButton } from "@/common/components/button/DeleteButton";
import {
  PaymentMethodFieldsFragment,
  PaymentMethodsDocument,
  PaymentMethodsQuery,
  useUpdatePaymentMethodsMutation,
} from "@/generated/graphql";
import { FC, useCallback } from "react";

type Props = {
  item: PaymentMethodFieldsFragment;
};

export const PaymentMethodActions: FC<Props> = ({ item }) => {
  const [updatePaymentMethodsMutation] = useUpdatePaymentMethodsMutation();

  const deletePaymentMethod = useCallback(async () => {
    await updatePaymentMethodsMutation({
      variables: {
        input: {
          deleted: [item.id],
        },
      },
      update: (cache) => {
        const queryPaymentMethods = cache.readQuery<PaymentMethodsQuery>({
          query: PaymentMethodsDocument,
        });
        cache.writeQuery({
          query: PaymentMethodsDocument,
          data: {
            viewer: {
              ...queryPaymentMethods?.viewer,
              org: {
                ...queryPaymentMethods?.viewer?.org,
                paymentMethods:
                  queryPaymentMethods?.viewer?.org.paymentMethods.filter(
                    (paymentMethod) => paymentMethod.id !== item.id,
                  ),
              },
            },
          },
        });
      },
    });
  }, [item.id, updatePaymentMethodsMutation]);

  return <DeleteButton onClick={deletePaymentMethod} />;
};
