import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { If } from "@/common/components/if/If";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  VendorContact,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceCloseButton } from "../common/InvoiceCloseButton";
import { InvoiceIssueAssetUpload } from "../common/InvoiceIssueAssetUpload";

const Container = tw.div`flex flex-col mb-3`;
const TopContainer = tw.div`grid grid-cols-[auto_auto_1fr] gap-1 items-center justify-between mb-2 mt-2`;
const DescriptionContainer = tw.div`grid grid-cols-[3fr_1fr] gap-2 mb-2`;
const Label = tw.div`font-medium text-sm`;
const Assets = tw.div`grid col-span-full grid-flow-col justify-items-start gap-2 mb-2 justify-start`;

type Props = {
  onClose?: () => void;
  hideFooter?: boolean;
  contacts?: Pick<VendorContact, "name" | "receivesInvoiceNotifications">[];
};

const InvoiceVendorIssueFormWithProvider: FC<Props> = ({
  onClose,
  hideFooter,
  contacts,
}) => {
  const intl = useIntl();
  const { setValue } = useFormContext();
  const { assets, removeAsset } = useUploadAssets();

  const updateUrls = useCallback(
    (assets: AssetFieldsFragment[]) => {
      setValue(
        "assetUrls",
        assets.map((asset) => asset.url),
      );
    },
    [setValue],
  );

  const remove = useCallback(
    async (asset: AssetFieldsFragment) => {
      const files = await removeAsset(asset);
      updateUrls(files);
    },
    [removeAsset, updateUrls],
  );

  return (
    <Container>
      <If isTrue={!hideFooter}>
        <TopContainer>
          <FormattedMessage id="REPORT_ISSUE_TO" tagName={Label} />
          <Label className="font-normal">
            {contacts
              ?.filter((c) => c.receivesInvoiceNotifications)
              .map((c) => c.name)
              .join(", ")}
          </Label>
          <InvoiceCloseButton onClose={onClose} />
        </TopContainer>
      </If>
      <DescriptionContainer>
        <TextFieldControlled
          name="description"
          multiline
          label={intl.$t({
            id: "ISSUE_DESCRIPTION",
          })}
          required
          minRows={3}
          maxRows={10}
        />
        <InvoiceIssueAssetUpload updateUrls={updateUrls} />
      </DescriptionContainer>
      <Assets>
        {assets?.map((asset, index) => (
          <AssetItem key={index} asset={asset} remove={remove} index={index} />
        ))}
      </Assets>
    </Container>
  );
};

export const InvoiceVendorIssueForm: FC<Props> = (props) => (
  <UploadAssetProvider context={AssetContext.Note}>
    <InvoiceVendorIssueFormWithProvider {...props} />
  </UploadAssetProvider>
);
