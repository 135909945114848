import { useGlobalError } from "@/common/hooks/useGlobalError";
import { LinkInvoiceInput, useLinkInvoiceMutation } from "@/generated/graphql";

export const useLinkInvoice = () => {
  const [linkInvoiceMutation] = useLinkInvoiceMutation();
  const { setError } = useGlobalError();

  const linkInvoice = async (linkInvoiceInput: LinkInvoiceInput) => {
    try {
      const { data, errors } = await linkInvoiceMutation({
        variables: {
          input: linkInvoiceInput,
        },
      });

      setError(errors);
      return !!data?.linkInvoice;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return { linkInvoice };
};
