import { GridTable } from "@/common/components/grid-table/GridTable";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { MissingBudgetCombinationType } from "../../../../../providers/MissingBudgetCombinationsProvider";
import { useMissingBudgetCombinationsListConfiguration } from "./MissingBudgetCombinationsList.configuration";

const Container = tw.div``;

type Props = {
  missingBudgetCombinations: MissingBudgetCombinationType[];
  includeZones: boolean;
};

export const MissingBudgetCombinationsList: FC<Props> = ({
  missingBudgetCombinations,
  includeZones,
}) => {
  const configuration = useMissingBudgetCombinationsListConfiguration({
    includeZones,
  });
  return (
    <Container>
      <GridTable
        items={missingBudgetCombinations}
        configuration={{
          classNames: {
            container: "relative pt-20",
          },
          columns: configuration,
        }}
      />
    </Container>
  );
};
