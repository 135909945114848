import { useGetOrCreateLumpSumMaterial } from "@/common/components/import-external-po/hooks/useGetOrCreateLumpSumMaterial";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import {
  CreateOrgMaterialInput,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useUpdateMaterialsAndGetMap = () => {
  const { updateMaterials } = useMaterials();
  const { getOrCreateLumpSumMaterial, getLumpSumOrgSKU } =
    useGetOrCreateLumpSumMaterial();
  const lumpSumOrgSku = getLumpSumOrgSKU();

  const getMap = useCallback(
    async (
      input: CreateOrgMaterialInput[],
      result: OrgMaterialFieldsFragment[],
    ) => {
      const map = new Map<string, string>();
      let lumpSumMaterial: OrgMaterialFieldsFragment | null = null;

      if (
        input.some(
          (material) =>
            material.newOrgCatalogSKU?.defaultUom === lumpSumOrgSku.defaultUom,
        )
      ) {
        lumpSumMaterial = await getOrCreateLumpSumMaterial();
      }

      let index = 0;
      input.forEach((material) => {
        if (
          material.newOrgCatalogSKU?.defaultUom === lumpSumOrgSku.defaultUom &&
          lumpSumMaterial
        ) {
          map.set(lumpSumOrgSku.name, lumpSumMaterial.material.id);
        } else if (material.newOrgCatalogSKU?.name) {
          map.set(material.newOrgCatalogSKU?.name, result[index].material.id);
          index++;
        }
      });
      return map;
    },
    [getOrCreateLumpSumMaterial, lumpSumOrgSku.defaultUom, lumpSumOrgSku.name],
  );

  const updateMaterialsAndGetMap = useCallback(
    async (materialsToAdd: CreateOrgMaterialInput[]) => {
      const addedMaterials = materialsToAdd.filter(
        (material) =>
          material.newOrgCatalogSKU?.defaultUom !== lumpSumOrgSku.defaultUom,
      );

      const newMaterials = addedMaterials.length
        ? await updateMaterials({ addedMaterials })
        : [];

      return getMap(materialsToAdd, newMaterials || []);
    },
    [getMap, lumpSumOrgSku.defaultUom, updateMaterials],
  );

  return { updateMaterialsAndGetMap };
};
