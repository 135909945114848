import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useIntl } from "react-intl";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";
import {
  ExternalGeneralLedgerAccountsProvider,
  ExternalLedgerAccountType,
  useExternalGeneralLedgerAccounts,
} from "./ExternalGeneralLedgerAccountsProvider";
import { useGeneralLedgerTableConfiguration } from "./GeneralLedgerTable.configuration";

const GLAccountForInvoicesAgainstInventoryReceiptsWithProviders = () => {
  const intl = useIntl();
  const { externalLedgerAccounts, loadingExternalLedgerAccounts } =
    useExternalGeneralLedgerAccounts();
  const {
    inventoryReceiptLedgerAccountId,
    setInventoryReceiptLedgerAccountId,
  } = useAgaveHostedWizard();
  const configuration = useGeneralLedgerTableConfiguration(
    inventoryReceiptLedgerAccountId ? [inventoryReceiptLedgerAccountId] : [],
  );

  return (
    <LocalSearchableList<ExternalLedgerAccountType>
      tableConfiguration={configuration}
      items={externalLedgerAccounts}
      loadingItems={loadingExternalLedgerAccounts}
      searchBarTitle={intl.$t({
        id: "SELECT_GENERAL_LEDGER_ACCOUNT_NUMBER_FOR_INVOICES_AGAINST_INVENTORY_RECEIPTS",
      })}
      searchKeys={["number", "name"]}
      itemSelection={{
        type: "single",
        selectedItemId: inventoryReceiptLedgerAccountId,
        setSelectedItemId: setInventoryReceiptLedgerAccountId,
      }}
    />
  );
};

export const GLAccountForInvoicesAgainstInventoryReceipts = () => (
  <ExternalGeneralLedgerAccountsProvider>
    <GLAccountForInvoicesAgainstInventoryReceiptsWithProviders />
  </ExternalGeneralLedgerAccountsProvider>
);
