import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { PaymentTerm, usePaymentTerms } from "@/common/hooks/usePaymentTerms";
import { cleanQuery } from "@/common/utils/cacheUtils";
import { mergeChanges } from "@/common/utils/mergeChanges";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ActivateBuyoutInput,
  BuyoutDocument,
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
  BuyoutQuery,
  BuyoutsBuyoutFieldsFragment,
  BuyoutStatus,
  namedOperations,
  UpdateContractorBuyoutInput,
  useActivateBuyoutMutation,
  useBuyoutQuery,
  useCreateReservedReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import { useApolloClient } from "@apollo/client";
import Decimal from "decimal.js";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useProjectTags } from "../../project/hooks/useProjectTags";
import { useProjectStore } from "../../project/store/useProjectStore";
import { useBuyoutMutations } from "../components/document/providers/useBuyoutMutations";

export type QuotedBuyoutRelatedBuyout = Pick<
  BuyoutsBuyoutFieldsFragment,
  "id" | "clientIdentifier" | "status"
> & {
  sellerOrgLocation: {
    org: {
      name: string;
      photoUrl?: string | null | undefined;
    };
  };
};

type PartialUpdateContractorBuyoutInput = Partial<UpdateContractorBuyoutInput>;

type ProviderContextType = {
  buyout: BuyoutFieldsFragment | null;
  hasMultipleBuyouts: boolean;
  relatedBuyoutsQueue: QuotedBuyoutRelatedBuyout[];
  currentBuyoutQueueIndex: number;
  nextNonActivatedBuyout: QuotedBuyoutRelatedBuyout | undefined;
  activatedBuyoutClientIdentifiers: string[];
  updateBuyout: (
    updates?: PartialUpdateContractorBuyoutInput,
    trigger?: boolean,
  ) => Promise<boolean>;
  loading: boolean;
  createReservedRelease: (buyoutId: string) => Promise<string | undefined>;
  paymentTermOptions: PaymentTerm[];
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
  selectedBuyoutItems: string[];
  setSelectedBuyoutItems: (items: string[]) => void;
  updating?: boolean;
  isActive: boolean;
  activateBuyout: (input: ActivateBuyoutInput) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  buyout: null,
  hasMultipleBuyouts: false,
  relatedBuyoutsQueue: [],
  currentBuyoutQueueIndex: 0,
  nextNonActivatedBuyout: undefined,
  activatedBuyoutClientIdentifiers: [],
  loading: false,
  updateBuyout: NoFunctionBooleanPromise,
  createReservedRelease: NoFunctionUndefinedPromise,
  paymentTermOptions: [],
  expandedItems: [],
  setExpandedItems: NoFunction,
  selectedBuyoutItems: [],
  setSelectedBuyoutItems: NoFunction,
  updating: false,
  isActive: false,
  activateBuyout: NoFunctionBooleanPromise,
});

type Props = {
  children: React.ReactNode;
  id?: string;
};

export const ContractorBuyoutProvider: FC<Props> = ({ children, id }) => {
  const { id: bId, buyoutId } = useParams();
  const { data, loading, error } = useBuyoutQuery({
    variables: { id: buyoutId || bId || id || "" },
    skip: !buyoutId && !bId && !id,
    fetchPolicy: "cache-and-network",
  });
  const changes = useRef<UpdateContractorBuyoutInput>();

  const { updateContractorBuyout, loading: updating } = useBuyoutMutations();
  const client = useApolloClient();
  const { manufacturers } = useManufacturers();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [selectedBuyoutItems, setSelectedBuyoutItems] = useState<string[]>([]);
  const { costCodes } = useCostCodes();
  const { tags: projectTags } = useProjectTags();
  const { hasPhaseCodes } = useOrgSettings();
  const { phaseCodes } = useProjectStore(
    useShallow((state) => ({
      phaseCodes: state.phaseCodes,
    })),
  );
  const { taxCodes } = useTaxCodeSummaries();
  const { setError } = useGlobalError();
  const { orderTypes } = useOrderTypeOptions();
  const [
    activatedBuyoutClientIdentifiers,
    setActivatedBuyoutClientIdentifiers,
  ] = useState<Array<string>>([]);
  const [activateBuyoutMutation] = useActivateBuyoutMutation({
    update: (cache) => cleanQuery(cache, namedOperations.Query.Buyouts),
  });

  useErrorEffect(error);

  const { paymentTermsOptions } = usePaymentTerms();

  const relatedBuyoutsQueue = useMemo(() => {
    const relatedBuyouts = (data?.buyout?.quote?.rfq.quotes || []).reduce<
      Array<QuotedBuyoutRelatedBuyout>
    >((buyouts, quote) => {
      if (quote.buyout && quote.buyout.status === BuyoutStatus.Draft) {
        buyouts.push(quote.buyout);
      }
      return buyouts;
    }, []);
    return relatedBuyouts.sort(
      (b1, b2) => Number(b1.clientIdentifier) - Number(b2.clientIdentifier),
    );
  }, [data?.buyout?.quote?.rfq.quotes]);

  const currentBuyoutQueueIndex = useMemo(
    () => relatedBuyoutsQueue.findIndex((b) => b.id === data?.buyout?.id),
    [data?.buyout, relatedBuyoutsQueue],
  );

  const nextNonActivatedBuyout = useMemo<
    QuotedBuyoutRelatedBuyout | undefined
  >(() => {
    let nextNonActivatedBuyoutInQueue;
    nextNonActivatedBuyoutInQueue = relatedBuyoutsQueue.find(
      (buyout, index) => {
        if (
          index > currentBuyoutQueueIndex &&
          buyout.status === BuyoutStatus.Draft
        ) {
          return buyout;
        }
        return undefined;
      },
    );
    if (!nextNonActivatedBuyoutInQueue) {
      nextNonActivatedBuyoutInQueue = relatedBuyoutsQueue.find(
        (buyout, index) => {
          if (
            index < currentBuyoutQueueIndex &&
            buyout.status === BuyoutStatus.Draft
          ) {
            return buyout;
          }
          return undefined;
        },
      );
    }
    return nextNonActivatedBuyoutInQueue;
  }, [relatedBuyoutsQueue, currentBuyoutQueueIndex]);

  const isBulkUpdate = useMemo(() => {
    return data?.buyout?.status !== BuyoutStatus.Draft;
  }, [data?.buyout]);

  const updateQuery = useCallback(
    (bulkUpdates?: UpdateContractorBuyoutInput) => {
      client.cache.updateQuery(
        { query: BuyoutDocument, variables: { id: data?.buyout?.id } },
        (data: BuyoutQuery | null) => {
          if (data?.buyout) {
            const updatedOrderType = orderTypes.find(
              (t) => t.id === bulkUpdates?.releaseTypeId,
            );
            const updatedTaxCode = taxCodes.find(
              (t) => t.id === bulkUpdates?.taxCodeId,
            );

            const items = data?.buyout?.items.map(
              (item: BuyoutItemFieldsFragment) => {
                const updatedItem = bulkUpdates?.updates?.find(
                  (i) => i.buyoutItemId === item.id,
                );
                if (!updatedItem) {
                  return item;
                }
                const newItem: BuyoutItemFieldsFragment = {
                  ...item,
                  quantityDecimal:
                    updatedItem?.quantityDecimal || item.quantityDecimal,
                  unitPrice: updatedItem?.requestedUnitPrice || item.unitPrice,
                  description: updatedItem?.description || item.description,
                  manufacturer: updatedItem?.manufacturerId
                    ? manufacturers.find(
                        (m) => m.id === updatedItem?.manufacturerId,
                      ) || item.manufacturer
                    : item.manufacturer,
                  costCode: updatedItem?.costCodeId
                    ? costCodes.find((cc) => cc.id === updatedItem?.costCodeId)
                    : item.costCode,
                  tags: updatedItem?.tags
                    ? (hasPhaseCodes ? phaseCodes : projectTags).filter((t) =>
                        updatedItem.tags?.includes(t.id),
                      )
                    : item.tags,
                };
                return newItem;
              },
            );
            const updatedAdditionalChargesAllowance =
              bulkUpdates?.additionalChargesAllowance ??
              data.buyout.additionalChargesAllowance;
            const netAmount = items.reduce((prevValue, item) => {
              const price = new Decimal(item.unitPrice || 0).mul(
                item.quantityDecimal || 0,
              );
              return Number(price.add(new Decimal(prevValue)));
            }, 0);

            const taxRate = bulkUpdates?.clearTaxCode
              ? null
              : (updatedTaxCode?.rate ??
                bulkUpdates?.taxRate ??
                data.buyout.taxRate);
            const chargesAmount =
              updatedAdditionalChargesAllowance ??
              data.buyout.additionalCharges.reduce((prevValue, charge) => {
                const price = new Decimal(charge.amount || 0);
                return Number(price.add(new Decimal(prevValue)));
              }, 0);
            const customTaxAmount = bulkUpdates?.clearCustomTaxAmount
              ? null
              : (bulkUpdates?.customTaxAmount ?? data.buyout.customTaxAmount);
            const taxAmount =
              customTaxAmount ??
              new Decimal(netAmount)
                .add(chargesAmount)
                .mul(taxRate || 0)
                .toString();
            const amount = new Decimal(taxAmount || 0)
              .add(netAmount)
              .add(chargesAmount)
              .toString();

            return {
              ...data,
              buyout: {
                ...data?.buyout,
                taxCode: bulkUpdates?.clearTaxCode
                  ? null
                  : (updatedTaxCode ?? data?.buyout?.taxCode),
                taxType: bulkUpdates?.taxType ?? data?.buyout?.taxType,
                releaseType: updatedOrderType ?? data?.buyout?.releaseType,
                additionalChargesAllowance: updatedAdditionalChargesAllowance,
                taxRate,
                items,
                customTaxAmount,
                taxAmount,
                netAmount: netAmount.toString(),
                amount,
              },
            };
          }
          return data;
        },
      );
    },
    [
      client.cache,
      data?.buyout?.id,
      taxCodes,
      orderTypes,
      manufacturers,
      costCodes,
      hasPhaseCodes,
      phaseCodes,
      projectTags,
    ],
  );

  const updateItems = async (input?: PartialUpdateContractorBuyoutInput) => {
    return await updateContractorBuyout(
      {
        ...input,
        buyoutId: data?.buyout?.id || "",
        version: data?.buyout?.version || 0,
      },
      // data?.buyout,
    );
  };

  const accumulateChanges = (input: UpdateContractorBuyoutInput) => {
    changes.current = {
      ...changes.current,
      ...input,
      updates: mergeChanges(
        changes.current?.updates,
        input.updates,
        "buyoutItemId",
      ),
    };
    changes.current.updates = changes.current?.updates?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.buyoutItemId === item.buyoutItemId),
    );
  };

  const [createReservedReleaseMutation] = useCreateReservedReleaseMutation();
  const createReservedRelease = async (buyoutId: string) => {
    try {
      const { data, errors } = await createReservedReleaseMutation({
        variables: {
          input: {
            buyoutId,
          },
        },
      });
      if (data?.createReservedRelease) {
        return data?.createReservedRelease.id;
      }
      setError(errors);
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  const updateBuyout = async (
    input?: PartialUpdateContractorBuyoutInput,
    trigger?: boolean,
  ) => {
    accumulateChanges({
      ...input,
      buyoutId: data?.buyout?.id || "",
      version: data?.buyout?.version || 0,
    });
    updateQuery(changes.current);
    if (isBulkUpdate && !trigger) {
      return true;
    } else {
      return await updateItems(input || changes.current);
    }
  };

  const isActive = useMemo(
    () => data?.buyout?.status === BuyoutStatus.Active,
    [data?.buyout?.status],
  );

  const activateBuyout = useCallback(
    async (input: ActivateBuyoutInput): Promise<boolean> => {
      try {
        const { errors } = await activateBuyoutMutation({
          variables: { input },
        });
        setError(errors);
        const clientIdentifier = data?.buyout?.clientIdentifier;
        if (!errors && clientIdentifier) {
          setActivatedBuyoutClientIdentifiers((ids) => [
            ...ids,
            clientIdentifier,
          ]);
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [activateBuyoutMutation, data?.buyout?.clientIdentifier, setError],
  );

  return (
    <ProviderContext.Provider
      value={{
        buyout: data?.buyout || null,
        hasMultipleBuyouts: relatedBuyoutsQueue.length > 1,
        relatedBuyoutsQueue,
        currentBuyoutQueueIndex,
        nextNonActivatedBuyout,
        activatedBuyoutClientIdentifiers,
        paymentTermOptions: paymentTermsOptions,
        loading,
        createReservedRelease,
        updateBuyout,
        expandedItems,
        setExpandedItems,
        selectedBuyoutItems,
        setSelectedBuyoutItems,
        updating,
        isActive,
        activateBuyout,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useContractorBuyout = () => useContext(ProviderContext);
