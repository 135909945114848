import { AdditionalFiltersOverlay } from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { If } from "@/common/components/if/If";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import { BATCH_MONTH_DATE_FORMAT } from "@/common/const";
import { StyledTextField } from "@/contractor/pages/admin/integrations/components/wizard/Wizard.styles";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CustomFilterContainer } from "./InvoicesAdditionalFilters";

type ExportedWithBatchFilters = Pick<
  QueryInvoicesFilter,
  "exported" | "batchMonth" | "batchNumber"
>;

export const InvoiceExportedWithFiltering = ({
  filter,
  setFilter,
}: {
  filter: ExportedWithBatchFilters | undefined;
  setFilter: (filter: ExportedWithBatchFilters) => void;
}) => {
  const [batchNumber, setBatchNumber] = useState(filter?.batchNumber || "");

  const applyBatchNumber = useCallback(() => {
    if (filter?.batchNumber !== batchNumber) {
      setFilter({ ...filter, batchNumber: batchNumber || undefined });
    }
  }, [batchNumber, filter, setFilter]);

  return (
    <>
      <CustomFilterContainer>
        <AdditionalFiltersOverlay.Name>
          <FormattedMessage id="INVOICE_EXPORTED_FILTER" />
        </AdditionalFiltersOverlay.Name>
        <AdditionalFiltersOverlay.Configurations>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(filter?.exported === true)}
            onClick={() =>
              setFilter({
                ...filter,
                exported: true,
              })
            }
          >
            <FormattedMessage id="YES" />
          </AdditionalFiltersOverlay.Configuration>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(filter?.exported === false)}
            onClick={() =>
              setFilter({
                ...filter,
                exported: false,
                batchMonth: undefined,
                batchNumber: undefined,
              })
            }
          >
            <FormattedMessage id="NO" />
          </AdditionalFiltersOverlay.Configuration>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(filter?.exported == null)}
            onClick={() =>
              setFilter({
                ...filter,
                exported: undefined,
                batchMonth: undefined,
                batchNumber: undefined,
              })
            }
            $isDefault
          >
            <FormattedMessage id="NO_FILTER" />
          </AdditionalFiltersOverlay.Configuration>
        </AdditionalFiltersOverlay.Configurations>
      </CustomFilterContainer>
      <If isTrue={filter?.exported}>
        <CustomFilterContainer>
          <FormattedMessage id="BATCH_MONTH" />
          <DatePicker
            views={["month", "year"]}
            date={filter?.batchMonth ? new Date(filter.batchMonth) : null}
            onChange={(newValue) => {
              setFilter({
                ...filter,
                batchMonth: newValue
                  ? format(newValue, BATCH_MONTH_DATE_FORMAT)
                  : undefined,
              });
            }}
            className="bg-white"
          />
        </CustomFilterContainer>
        <CustomFilterContainer>
          <FormattedMessage id="BATCH_NUMBER" />
          <StyledTextField
            value={batchNumber}
            size="small"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setBatchNumber(ev.target.value)
            }
            onBlur={applyBatchNumber}
            onClickEnter={applyBatchNumber}
            type="number"
          />
        </CustomFilterContainer>
      </If>
    </>
  );
};
