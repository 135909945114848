import { useUsersQuery } from "@/generated/graphql";
import { useMemo } from "react";
import { useUser } from "../providers/UserProvider";
import { useErrorEffect } from "./useErrorEffect";

type Props = {
  requireLocation?: boolean;
  locationId?: string;
  projectId?: string;
};

export const useUsers = ({
  requireLocation = false,
  locationId,
  projectId,
}: Props = {}) => {
  const { isContractor } = useUser();
  const { data, error, loading, refetch } = useUsersQuery({
    variables: {
      orgLocationID: locationId,
      projectID: projectId,
    },
    skip: (requireLocation && !locationId) || !isContractor,
  });
  useErrorEffect(error);

  const users = useMemo(
    () =>
      data?.users?.toSorted((a, b) => a.firstName.localeCompare(b.firstName)),
    [data],
  );

  return { users, loading, refetch };
};
