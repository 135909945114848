import { Checkbox } from "@/common/components/checkbox/Checkbox";
import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { AddToBuyoutItem } from "@/contractor/pages/home/buyout/components/document/providers/BuyoutProjectItemsProvider";
import { AddToRfqItem } from "@/contractor/pages/home/rfq/components/import-project-items/RfqProjectItemsProvider";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useEffect, useState } from "react";

type Props = {
  item: ProjectItemFieldsFragment;
  estimatedItem?: EstimatedItemFieldsFragment | null;
  updates: AddToRfqItem[] | AddToBuyoutItem[];
  setUpdates: (updates: AddToRfqItem[] | AddToBuyoutItem[]) => void;
};

export const AddEstimatedItemCheckbox: FC<Props> = ({
  item,
  estimatedItem,
  updates,
  setUpdates,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(
      !!updates.find(
        (update) =>
          update.itemId === item.id &&
          (!estimatedItem?.id ||
            update.estimatedItemIds.includes(estimatedItem?.id)),
      ),
    );
  }, [updates, item, estimatedItem?.id]);

  const onToggle = (checked: boolean) => {
    const itemsToAdd = [
      {
        itemId: item.id,
        estimatedItemIds: estimatedItem?.id ? [estimatedItem?.id] : [],
        isIncluded: checked,
        description: item.material.material.name,
        manufacturerId: estimatedItem ? estimatedItem.manufacturer?.id : null,
        quantityDecimal: estimatedItem?.quantityDecimal || "1",
        costCodeId: estimatedItem?.costCode?.id,
        tags: estimatedItem?.tags?.map((tag) => tag.id) || [],
        projectItem: {
          estimateUom: item.estimateUom.pluralDescription,
          ...(isOrgCatalogSku(item.material.material) && {
            orgCatalogSkuId: item.material.material.id,
          }),
          ...(isProductSku(item.material.material) && {
            masterProductId: item.material.material.id,
          }),
          ...(isMasterSku(item.material.material) && {
            masterSkuId: item.material.material.id,
          }),
        },
      },
    ];

    setUpdates(itemsToAdd);
  };

  return (
    <Checkbox
      checked={checked}
      onClick={(event) => {
        event.stopPropagation();
        onToggle(!checked);
      }}
    />
  );
};
