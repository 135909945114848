import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { appendTableRows } from "@/common/components/spreadsheet-table/utils/appendTableRows";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { InvoiceItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddInvoiceItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { invoice } = useInvoiceVerification();
  const { release } = useRelease();
  const { findMaterialByName } = useTableHelpers();
  const { showModal } = useAddItemsModal();
  const { formatCostCode } = useCostCodes();
  const { calcExtPrice } = usePriceCalculation();
  const { setSystemAlert } = useSnackbar();
  const intl = useIntl();

  const missingItems = useMemo(() => {
    return (
      invoice?.items.filter(
        (item) =>
          !invoice?.releaseItemHints.some(
            (hint) => hint.invoiceItem?.id === item.id,
          ),
      ) || []
    );
  }, [invoice]);

  const addAllItems = useCallback(
    (items?: InvoiceItemFieldsFragment[]) => {
      const newItems =
        (items || invoice?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          return {
            [COLUMN_TYPE.Material]:
              material?.material.name ?? item.description ?? "",
            [COLUMN_TYPE.Manufacturer]: item.manufacturer ?? "",
            [COLUMN_TYPE.UOM]: String(item.UOM) || "UT",
            [COLUMN_TYPE.Quantity]: item.quantityDecimal,
            [COLUMN_TYPE.UnitPrice]: item.unitPrice,
            [COLUMN_TYPE.ExtPrice]: String(
              calcExtPrice(item.quantityDecimal, item.unitPrice),
            ),
            [COLUMN_TYPE.CostCode]: formatCostCode(
              release?.costCode ?? material?.costCode ?? "",
            ),
          };
        }) || [];
      appendTableRows(newItems, handsonInstance);
    },
    [
      calcExtPrice,
      findMaterialByName,
      formatCostCode,
      handsonInstance,
      invoice?.items,
      release?.costCode,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(missingItems);
  }, [addAllItems, missingItems]);

  const addInvoiceItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = invoice?.items.length === missingItems.length;
    setSystemAlert(
      intl.$t(
        { id: "INVOICE_OCR_RESULTS_NO_OF_ITEMS" },
        {
          count: invoice?.items.length,
          missing: missingItems.length,
        },
      ),
    );
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      invoice?.items.length || 0,
      missingItems.length,
    );
  }, [
    handsonInstance,
    invoice?.items.length,
    missingItems,
    setSystemAlert,
    intl,
    showModal,
    addAllItems,
    addMissingItems,
  ]);

  return {
    addInvoiceItems,
  };
};
