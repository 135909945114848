import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUpdateTaxCodesMutation } from "@/generated/graphql";
import { useCallback } from "react";

export const useArchiveTaxCodes = () => {
  const [updateTaxCodesMutation, { loading }] = useUpdateTaxCodesMutation();
  const { setError } = useGlobalError();

  const archiveTaxCodes = useCallback(
    async (ids: string[]) => {
      try {
        const { data, errors } = await updateTaxCodesMutation({
          variables: {
            input: {
              archived: ids,
            },
          },
        });
        if (data && data.updateTaxCodes) {
          return data.updateTaxCodes;
        }
        if (errors) {
          setError(errors);
        }
        return null;
      } catch (error) {
        setError(error);
      }
    },
    [updateTaxCodesMutation, setError],
  );

  const unarchiveTaxCodes = useCallback(
    async (ids: string[]) => {
      try {
        const { data, errors } = await updateTaxCodesMutation({
          variables: {
            input: {
              unarchived: ids,
            },
          },
        });
        if (data && data.updateTaxCodes) {
          return data.updateTaxCodes;
        }
        if (errors) {
          setError(errors);
        }
        return null;
      } catch (error) {
        setError(error);
      }
    },
    [updateTaxCodesMutation, setError],
  );

  return {
    archiveTaxCodes,
    unarchiveTaxCodes,
    loading,
  };
};
