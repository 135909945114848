import { If } from "@/common/components/if/If";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { DEFAULT_ADDRESS_COUNTRY } from "@/common/const";
import { isEmptyString } from "@/common/utils/validationUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  AdminOrgPreferredVendorsFieldsFragment,
  ExternalVendor,
  OrgFieldsFragment,
  VendorContact,
} from "@/generated/graphql";
import { Add, ModeEditOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  LineItemLabel,
  ListItem,
  PreferredVendorDetailsContainer,
} from "../../Vendors.styles";
import useEnterprisePreferredVendors from "../../hooks/useEnterprisePreferredVendors";
import { useExternalVendorMapping } from "../../providers/ExternalVendorMappingProvider";
import { useOrgs } from "../../providers/OrgsProvider";
import { NewOrgLogoDrawer } from "./NewOrgLogoDrawer";
import { NEW_BRANCH_ID, VendorBranchSection } from "./VendorBranchSection";
import { VendorCostCode } from "./VendorCostCode";
import { NEW_VENDOR_ID } from "./VendorForm";
import { VendorOrderType } from "./VendorOrderType";
import { VendorPaymentTerm } from "./VendorPaymentTerm";
import { NewVendorDetailsSection } from "./sections/NewVendorDetailsSection";
import { SalesTaxExemptSection } from "./sections/SalesTaxExemptSection";
import { VendorAccountingDetails } from "./sections/VendorAccountingDetails";
import { VendorContactSection } from "./sections/VendorContactSection";
import { VendorLocation } from "./sections/VendorLocation";
import { VendorFormVendorConnections } from "./vendor-connections/VendorFormVendorConnections";
const VendorContainer = tw.div`relative flex flex-col my-7 gap-3`;
const Sections = tw.div`flex flex-col gap-5 bg-gray-100 p-6 -mx-6`;
const ReadonlyAddressContainer = tw.div`flex flex-col gap-3`;
const OrgLogoWrapper = tw.div`absolute top-6 right-2 grid place-items-center h-15`;
const PencilIcon = tw(
  ModeEditOutlined,
)` absolute ml-2 -mt-1 text-xxl text-blue-500 hover:text-blue-300 bg-white rounded-lg p-1 cursor-pointer -top-1 -right-1`;

const HighlightedItemClassName = "bg-green-100 px-6 hover:bg-green-300";

export type ExtendedOrgFieldsFragment = OrgFieldsFragment & {
  __typename?: string;
  isFromEnterprise: boolean;
  contacts?: VendorContact[];
  externalCode?: string;
  externalVendors?: ExternalVendor[];
};

export const PreferredVendorDetailsSection = ({
  vendor,
  editVendorLocation,
}: {
  vendor?: AdminOrgPreferredVendorsFieldsFragment;
  editVendorLocation: {
    editing: boolean;
    setEditing: (editing: boolean) => void;
  };
}) => {
  const intl = useIntl();
  const { orgs } = useOrgs();
  const { settings } = useOrgSettings();
  const { selectedExternalVendorData } = useExternalVendorMapping();
  const { hasPhaseCodes, connectedSourceSystem } = useOrgSettings();

  const { vendors: enterpriseOrgPreferredVendors } =
    useEnterprisePreferredVendors();

  const { watch, setValue, trigger } = useFormContext();
  const preferredVendor = watch("preferredVendor");
  const location = watch("location");
  const mappings = watch("mappings");

  const [newVendorName, setNewVendorName] = useState("");
  const [isOrgLogoDrawerOpen, setIsLogoDrawerOpen] = useState(false);

  const readonly = useMemo(() => {
    return !!vendor;
  }, [vendor]);

  const customRender = useCallback(
    (item: { id: string; name: string; isFromEnterprise: boolean }) => (
      <ListItem
        $isAdd={item.id === NEW_VENDOR_ID}
        className={item.isFromEnterprise ? HighlightedItemClassName : "px-6"}
      >
        <If isTrue={item.id === NEW_VENDOR_ID}>
          <Add />
        </If>
        {item.name}
      </ListItem>
    ),
    [],
  );

  const formattedEnterpriseOrgPreferredVendors = useMemo(
    (): ExtendedOrgFieldsFragment[] | undefined =>
      (enterpriseOrgPreferredVendors || []).map((vendor) => ({
        id: vendor.id,
        name: vendor.sellerOrgLocation.name,
        locations: [vendor.sellerOrgLocation],
        contacts: vendor.contacts,
        isFromEnterprise: true,
        externalCode: vendor.externalCode || "",
        externalVendors: vendor.externalVendors?.filter(
          (externalVendor) =>
            externalVendor.sourceSystem === connectedSourceSystem,
        ),
      })),
    [enterpriseOrgPreferredVendors, connectedSourceSystem],
  );

  const vendorOptions = useMemo(() => {
    return [
      {
        id: NEW_VENDOR_ID,
        name: intl.$t({ id: "ADD_NEW_VENDOR" }),
        isFromEnterprise: false,
      },
    ]
      .concat(formattedEnterpriseOrgPreferredVendors || [])
      .concat(orgs as ExtendedOrgFieldsFragment[]);
  }, [orgs, intl, formattedEnterpriseOrgPreferredVendors]);

  const prefillVendorInformation = useCallback(
    (vendor: Partial<ExtendedOrgFieldsFragment>) => {
      if (vendor.id) {
        setValue("vendorId", vendor.id);
      }

      if (vendor.isFromEnterprise) {
        const externalVendor = vendor.externalVendors?.[0];

        if (externalVendor?.externalId) {
          setValue("mappings", [
            {
              externalId: externalVendor?.externalId,
              sourceSystem: connectedSourceSystem,
            },
          ]);
        }
      }

      if (vendor.externalCode) {
        setValue("externalCode", vendor.externalCode);
      }

      if (vendor?.locations?.[0]) {
        const location = vendor.locations[0];
        const state = location.address?.state;

        setValue("location", location.id);
        setValue("state", state);
      }

      if (vendor?.contacts?.[0]) {
        const contact = vendor.contacts[0];

        setValue("contacts.0.name", contact.name);
        setValue("contacts.0.email", contact.email);
        setValue("contacts.0.cellPhone", contact.cellPhone);
      }
    },
    [connectedSourceSystem, setValue],
  );

  useEffect(() => {
    if (!preferredVendor) {
      return;
    }

    const selectedVendor = vendorOptions.find((v) => v.id === preferredVendor);

    if (!selectedVendor) {
      return;
    }

    prefillVendorInformation(selectedVendor);
  }, [vendorOptions, preferredVendor, prefillVendorInformation]);

  useEffect(() => {
    if (selectedExternalVendorData) {
      const matchedVendor = orgs.find(
        (o) =>
          o.name.toLowerCase() ===
          selectedExternalVendorData.name.toLowerCase(),
      );
      if (matchedVendor) {
        setValue("preferredVendor", matchedVendor.id);
        const matchedBranch = matchedVendor.locations.find(
          (l) =>
            l.address?.postalCode ===
            selectedExternalVendorData.address?.postalCode,
        );
        if (matchedBranch) {
          setValue("location", matchedBranch.id);
        }
      } else {
        setValue("preferredVendor", NEW_VENDOR_ID);
        setNewVendorName(selectedExternalVendorData.name ?? "");
        setValue("newVendor", {
          name: selectedExternalVendorData?.name ?? "",
          location: {
            name: "",
            addressLine1:
              selectedExternalVendorData.address?.addressLine1 ?? "",
            city: selectedExternalVendorData.address?.city ?? "",
            state: selectedExternalVendorData.address?.state ?? "",
            country:
              selectedExternalVendorData.address?.country ??
              DEFAULT_ADDRESS_COUNTRY,
            postalCode: selectedExternalVendorData.address?.postalCode ?? "",
            phone: selectedExternalVendorData.phone ?? "",
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExternalVendorData]);

  const onVendorSelect = (value: string | null) => {
    if (value === NEW_VENDOR_ID) {
      setValue("newVendor.name", newVendorName?.split(" (A")[0]);
      setValue("preferredVendor", NEW_VENDOR_ID);
    } else {
      setValue("preferredVendor", value);
    }
    trigger("preferredVendor");
  };

  return (
    <>
      <If
        isTrue={(settings?.integrations.sourceSystems ?? []).some(
          (system) => system.connected,
        )}
      >
        <VendorFormVendorConnections
          mappings={(vendor?.externalVendors || mappings) ?? []}
          vendorId={vendor?.id}
        />
      </If>
      <PreferredVendorDetailsContainer>
        <VendorContainer>
          <FormattedMessage id="VENDOR_ORGANIZATION" tagName={LineItemLabel} />
          <SelectControlled
            options={vendorOptions}
            name="preferredVendor"
            className="min-w-32 py-0"
            classes={{
              listItem: "px-0",
            }}
            creatable
            creatableFn={(value) => {
              setNewVendorName(value);
              return {
                id: NEW_VENDOR_ID,
                name: value,
                isFromEnterprise: false,
              };
            }}
            onChange={onVendorSelect}
            creatableTextKey="ADD_NEW_VENDOR_CREATABLE"
            customRender={customRender}
            getLabel={(option) => option.name}
            getOptionLabel={(option) =>
              option.id === NEW_VENDOR_ID ? "" : option.name
            }
            getValue={(option) => option.id}
            placeholder={intl.$t({ id: readonly ? "VENDOR" : "SEARCH_VENDOR" })}
            rules={{ required: preferredVendor !== NEW_VENDOR_ID }}
            staticText={readonly}
          />
          <If
            isTrue={
              !isEmptyString(preferredVendor) &&
              preferredVendor !== NEW_VENDOR_ID
            }
          >
            <If isTrue={readonly}>
              <ReadonlyAddressContainer>
                <OrgLogoWrapper>
                  <If isTrue={!!vendor?.sellerOrgLocation.org.contractorOrg}>
                    <PencilIcon onClick={() => setIsLogoDrawerOpen(true)} />
                  </If>
                  <OrgLogo
                    name={vendor?.sellerOrgLocation?.org.name ?? ""}
                    logoImageUrl={vendor?.sellerOrgLocation?.org.photoUrl}
                    width={60}
                  />
                </OrgLogoWrapper>
                <VendorLocation
                  edit={
                    vendor?.sellerOrgLocation.contractorOrg
                      ? editVendorLocation
                      : undefined
                  }
                />
              </ReadonlyAddressContainer>
            </If>
            <If isTrue={!readonly}>
              <VendorBranchSection
                vendorId={preferredVendor}
                extraOrgs={formattedEnterpriseOrgPreferredVendors}
              />
            </If>
          </If>
        </VendorContainer>
        <If isTrue={!isEmptyString(preferredVendor)}>
          <Sections>
            <If isTrue={preferredVendor === NEW_VENDOR_ID}>
              <NewVendorDetailsSection />
            </If>
            <If
              isTrue={
                preferredVendor === NEW_VENDOR_ID || location === NEW_BRANCH_ID
              }
            >
              <VendorLocation isNewVendor />
            </If>
            <SalesTaxExemptSection />
            <VendorAccountingDetails />
            <VendorOrderType />
            <If isTrue={!hasPhaseCodes}>
              <VendorCostCode />
            </If>
            <VendorPaymentTerm />
            <VendorContactSection />
          </Sections>
        </If>
        <NewOrgLogoDrawer
          orgId={preferredVendor}
          isOpen={isOrgLogoDrawerOpen}
          setIsOpen={setIsLogoDrawerOpen}
        />
      </PreferredVendorDetailsContainer>
    </>
  );
};
