import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useExpandableFooterStore } from "../../../../../../../common/components/footer/stores/useExpandableFooterStore";
import { If } from "../../../../../../../common/components/if/If";
import { Price } from "../../../../../../../common/components/price/Price";
import { TOTAL_PRICE_DECIMAL_POINTS } from "../../../../../../../common/const";
import { ORDER_TOTAL_PANEL_WIDTH } from "../../../../../../../contractor/pages/home/invoices/pages/invoice-verification/components/footer/InvoiceFooterTotals";
import { useDistributorInvoice } from "../../../../providers/DistributorInvoiceProvider";

const Container = tw.div`flex flex-row mt-1 justify-start items-center h-8`;
const TotalPanel = tw.div`flex flex-col text-center text-2xs min-w-20 items-center`;
const HorizontalDivider = tw.div`border-l-0 border-r-[1px] h-[70%] border-dashed border-black mr-3 ml-2`;
const Placeholder = tw.div``;

export const InvoiceFooterTotals: FC = () => {
  const { invoice } = useDistributorInvoice();
  const { expanded } = useExpandableFooterStore();

  if (expanded) {
    return <Placeholder />;
  }

  return (
    <Container>
      <TotalPanel style={{ width: ORDER_TOTAL_PANEL_WIDTH }}>
        <If isTrue={!!invoice?.release}>
          <FormattedMessage id="ORDER_TOTAL" />
          <Price
            price={invoice?.release?.total ?? 0}
            className="text-base font-medium"
            maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
          />
          <FormattedMessage id="WITH_TAX_AND_FEES" />
        </If>
      </TotalPanel>
      <HorizontalDivider />
      <TotalPanel>
        <FormattedMessage id="INVOICE_TOTAL" />
        <Price
          price={invoice?.total ?? 0}
          className="text-base font-medium"
          maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
        />
        <FormattedMessage id="WITH_TAX_AND_FEES" />
      </TotalPanel>
    </Container>
  );
};
