import { If } from "@/common/components/if/If";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { FC, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useProject } from "../../providers/ProjectProvider";
import { ProjectSpendingReportItemsProvider } from "../../providers/ProjectSpendingReportItemsProvider";
import {
  ProjectSpendingReportProvider,
  useSpendingReport,
} from "../../providers/ProjectSpendingReportProvider";
import { SpendingReportChart } from "./components/spending-report-chart/SpendingReportChart";
import { SpendingReportFooter } from "./components/spending-report-footer/SpendingReportFooter";
import { SpendingReportHeader } from "./components/spending-report-header/SpendingReportHeader";
import { useSpendingReportListItemConfiguration } from "./spending-report-list/SpendingReportListItem.configuration";
import { SpendingReportListMaterials } from "./spending-report-list/SpendingReportListMaterials";
import { SpendingReportListVendors } from "./spending-report-list/SpendingReportListVendors";

const Container = tw.div`relative`;

const SpendingReportReportWithProvider = () => {
  const { materialsConfig, vendorsConfig } =
    useSpendingReportListItemConfiguration();
  const viewState = useTableViewStore((state) => state.viewState);
  const { showChart } = useSpendingReport();

  return (
    <ProjectSpendingReportItemsProvider>
      <Container>
        <SpendingReportHeader />
        <If isTrue={showChart}>
          <SpendingReportChart />
        </If>
        <If isTrue={viewState === TableViewState.materials}>
          <SpendingReportListMaterials columns={materialsConfig} />
        </If>
        <If isTrue={viewState === TableViewState.vendors}>
          <SpendingReportListVendors columns={vendorsConfig} />
        </If>
        <SpendingReportFooter />
      </Container>
    </ProjectSpendingReportItemsProvider>
  );
};

export const SpendingReportReport: FC = () => {
  const { project } = useProject();
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.materials);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  if (!project) {
    return null;
  }

  return (
    <ProjectSpendingReportProvider>
      <ProjectSpendingReportItemsProvider>
        <SpendingReportReportWithProvider />
      </ProjectSpendingReportItemsProvider>
    </ProjectSpendingReportProvider>
  );
};
