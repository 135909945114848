import { If } from "@/common/components/if/If";
import { useUser } from "@/common/providers/UserProvider";
import {
  InvoiceIssueFieldsFragment,
  InvoiceKickbackFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Header = tw.div`flex flex-row items-baseline gap-2`;
const IssueHeader = tw.div`font-medium`;
const IssueDetails = tw.div`font-normal text-2xs text-gray-600 group-hover:text-blue-300 group-hover:cursor-pointer`;

type Props = {
  kickbacks?: InvoiceKickbackFieldsFragment[];
  issues?: InvoiceIssueFieldsFragment[];
};

export const InvoiceIssueHeader: FC<Props> = ({
  kickbacks = [],
  issues = [],
}) => {
  const { isContractor } = useUser();

  if (!isContractor) {
    return (
      <FormattedMessage id="CONTRACTOR_REPORTED_ISSUE" tagName={IssueHeader} />
    );
  }

  if (kickbacks.length > 0 && issues.length === 0) {
    return (
      <Header>
        <FormattedMessage id="INTERNAL_KICKBACKS" tagName={IssueHeader} />
        <If isTrue={kickbacks.some((k) => !k.resolvedAt)}>
          <FormattedMessage
            id="UNRESOLVED"
            values={{ number: kickbacks.filter((k) => !k.resolvedAt).length }}
            tagName={IssueDetails}
          />
        </If>
      </Header>
    );
  }

  if (kickbacks.length === 0 && issues.length > 0) {
    return (
      <Header>
        <FormattedMessage id="VENDOR_ISSUES" tagName={IssueHeader} />
        <If isTrue={kickbacks.some((k) => !k.resolvedAt)}>
          <FormattedMessage
            id="UNRESOLVED"
            values={{ number: kickbacks.filter((k) => !k.resolvedAt).length }}
            tagName={IssueDetails}
          />
        </If>
      </Header>
    );
  }

  return null;
};
