import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { FulfillmentHeaderItem } from "@/common/components/info-header/components/FulfillmentHeaderItem";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { ListDetailsHeaderItem } from "@/common/components/info-header/components/ListDetailsHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { getUserName } from "@/common/utils/users/getUserName";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorReleaseAddress } from "./DistributorReleaseAddress";
import { DistributorSiteContact } from "./DistributorSiteContact";
import { ReleaseDeliveryDate } from "./ReleaseDeliveryDate";

type Props = {
  release: DistributorReleaseFieldsFragment;
  vertical?: boolean;
};

const DeliveryInfo = tw.div`[&>div]:border-none`;

export const ReleaseInfoHeader: FC<Props> = ({ release }) => {
  return (
    <InfoHeader hideActionInMobile hideInMobile={false}>
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="CONTRACTOR" />}
        name={getUserName(release.createdBy)}
        orgName={release.project?.location.org.name}
        logoImageUrl={release.project?.location.org.photoUrl}
        address={release.project?.location.address}
      />
      <InfoHeaderItem title={<FormattedMessage id="ORDER_TYPE" />}>
        {release.type?.name}
      </InfoHeaderItem>
      <ListDetailsHeaderItem
        classes={{
          details: "grid-cols-[1fr_2fr] mb-1",
          list: "grid-cols-1fr",
          value: "font-normal",
        }}
        list={[
          {
            label: <FormattedMessage id="PROJECT_TITLE" />,
            value: release.project?.name ?? "",
          },
          {
            label: (
              <>
                <FormattedMessage id="PO_HASH" />:
              </>
            ),
            value: release.poNumber || release.sequenceNumber?.toString() || "",
          },
          {
            label: (
              <>
                <FormattedMessage id="DELIVERY_SITE" />:
              </>
            ),
            value: <DistributorReleaseAddress release={release} />,
          },
          {
            label: (
              <>
                <FormattedMessage id="PROJECT_SITE_CONTACT" />:
              </>
            ),
            value: <DistributorSiteContact siteContact={release.siteContact} />,
            hide: !release.siteContact,
          },
        ]}
      />
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="VENDOR" />}
        name={<VendorList contacts={release.vendorContacts} />}
        orgName={release.sellerOrgLocation?.org.name}
        logoImageUrl={release.sellerOrgLocation?.org.photoUrl}
        address={release.sellerOrgLocation?.address}
        hideInMobile
      />
      <DeliveryInfo>
        <ReleaseDeliveryDate
          release={release}
          classes={{ container: "mb-0 md:mb-2 mt-2 md:mt-0" }}
        />
        <FulfillmentHeaderItem
          includeServices={release.includeServices}
          release={release}
          hideHeader
        />
      </DeliveryInfo>
    </InfoHeader>
  );
};
