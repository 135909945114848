import { ApolloCache } from "@apollo/client";

const ROOT_QUERY = "ROOT_QUERY";

export const cleanQuery = <T>(cache: ApolloCache<T>, fieldName: string) => {
  cache.evict({ id: ROOT_QUERY, fieldName: fieldName });
  cache.gc();
};

export const evictCacheById = <T>(
  cache: ApolloCache<T>,
  fieldName: string,
  id: string | null | undefined,
) => {
  cache.evict({ id: `${fieldName}:${id}` });
  cache.gc();
};
