import {
  DEFAULT_CURRENCY,
  PDF_FONT,
  PDF_LEFT_SPACING,
  TOTAL_PRICE_DECIMAL_POINTS,
} from "@/common/const";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { CostCodeType } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { ZoneCategory } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import { ProjectReportType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import {
  ProjectMaterialFieldsFragment,
  ProjectReportCostCodeFieldsFragment,
  ProjectReportZoneFieldsFragment,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const EMPTY_STRING = "-";

const formatCurrency = (
  value: string | null | undefined,
  intl: IntlShape,
  currencySymbol: string,
) => {
  return (
    intl.formatNumber(Number(value), {
      minimumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      currency: currencySymbol || DEFAULT_CURRENCY,
      style: "currency",
    }) || EMPTY_STRING
  );
};

const getDetailsForZone = (zone: ZoneCategory) => {
  return zone as unknown as ProjectReportZoneFieldsFragment & {
    id: string;
  };
};

const getDetailsForCostCode = (category: CategoryState<ProjectReportType>) => {
  return category as unknown as ProjectReportCostCodeFieldsFragment & {
    id: string;
  };
};

const itemsForPdf = (
  intl: IntlShape,
  zones: ZoneCategory[],
  materialsMap: Map<string, ProjectMaterialFieldsFragment>,
  formatCostCode: (costCode: CostCodeType) => string,
  currencySymbol: string,
): RowInput[] => {
  const result: RowInput[] = [];

  zones.forEach((zone) => {
    if (!(zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID)) {
      const budget = formatCurrency(
        getDetailsForZone(zone)?.allowance ||
          getDetailsForZone(zone)?.estimated,
        intl,
        currencySymbol,
      );
      const ordered = formatCurrency(
        getDetailsForZone(zone)?.ordered,
        intl,
        currencySymbol,
      );
      const invoiced = formatCurrency(
        getDetailsForZone(zone)?.invoiced,
        intl,
        currencySymbol,
      );
      const paid = formatCurrency(
        getDetailsForZone(zone)?.paid,
        intl,
        currencySymbol,
      );
      const overUnder = formatCurrency(
        getDetailsForZone(zone)?.overUnder,
        intl,
        currencySymbol,
      );
      result.push([
        {
          content: `${zone.name} (${intl.$t({ id: "ZONE" })})`,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
        budget,
        EMPTY_STRING,
        EMPTY_STRING,
        ordered,
        invoiced,
        paid,
        overUnder,
      ]);
    }
    zone.items.forEach((costCode) => {
      const budget = formatCurrency(
        getDetailsForCostCode(costCode)?.allowance ||
          getDetailsForCostCode(costCode)?.estimated,
        intl,
        currencySymbol,
      );
      const received = formatCurrency(
        getDetailsForCostCode(costCode)?.received,
        intl,
        currencySymbol,
      );
      const ordered = formatCurrency(
        getDetailsForCostCode(costCode)?.ordered,
        intl,
        currencySymbol,
      );
      const invoiced = formatCurrency(
        getDetailsForCostCode(costCode)?.invoiced,
        intl,
        currencySymbol,
      );
      const paid = formatCurrency(
        getDetailsForCostCode(costCode)?.paid,
        intl,
        currencySymbol,
      );
      const overUnder = formatCurrency(
        getDetailsForCostCode(costCode)?.overUnder,
        intl,
        currencySymbol,
      );
      result.push([
        {
          content: `${formatCostCode(costCode.id)} - (${intl.$t({ id: "COST_CODE" })})`,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
        budget,
        EMPTY_STRING,
        ordered,
        received,
        invoiced,
        paid,
        overUnder,
      ]);
      const items = costCode.items;
      items.forEach((item) => {
        const material = item as ProjectReportType;
        const materialName =
          materialsMap.get(material.id)?.material.name || EMPTY_STRING;
        const received = formatCurrency(
          material.received,
          intl,
          currencySymbol,
        );
        const ordered = formatCurrency(material.ordered, intl, currencySymbol);
        const invoiced = formatCurrency(
          material.invoiced,
          intl,
          currencySymbol,
        );
        const paid = formatCurrency(material.paid, intl, currencySymbol);

        result.push([
          materialName,
          EMPTY_STRING,
          EMPTY_STRING,
          ordered,
          received,
          invoiced,
          paid,
          EMPTY_STRING,
        ]);
      });
    });
  });

  return result;
};

const HEADER = [
  "ITEMS",
  "PROJECT_BUDGET_HEADER",
  "PROJECT_BUDGET_QUOTED_HEADER",
  "PROJECT_BUDGET_ORDERED_HEADER",
  "PROJECT_BUDGET_RECEIVED_HEADER",
  "PROJECT_BUDGET_INVOICED_HEADER",
  "PROJECT_BUDGET_PAID_HEADER",
  "PROJECT_BUDGET_ORDERED_VS_BUDGET_HEADER",
];

export const materialsBudgetReport = (
  doc: jsPDF,
  intl: IntlShape,
  zones: ZoneCategory[],
  topSpacing: number,
  materialsMap: Map<string, ProjectMaterialFieldsFragment>,
  formatCostCode: (costCode: CostCodeType) => string,
  currencySymbol: string,
) => {
  const header = HEADER;

  doc
    .setFont(PDF_FONT, "", "bold")
    .setFontSize(10)
    .text(intl.$t({ id: "PROJECT_JOB_COSTS" }), PDF_LEFT_SPACING, topSpacing);
  topSpacing += 40;
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 8,
    },
    startY: topSpacing,
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
    },
    columnStyles: {
      0: { valign: "middle" },
      1: { halign: "center", valign: "middle" },
      2: { halign: "center", valign: "middle" },
      3: { halign: "center", valign: "middle" },
      4: { halign: "center", valign: "middle" },
      5: { halign: "center", valign: "middle" },
      6: { halign: "center", valign: "middle" },
      7: { halign: "center", valign: "middle" },
    },
    head: [header.map((header) => intl.$t({ id: header }))],
    body: [
      ...itemsForPdf(intl, zones, materialsMap, formatCostCode, currencySymbol),
    ],
  });
};
