import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { useBuyoutGrouped } from "@/contractor/pages/home/buyout/components/document/providers/BuyoutGroupedProvider";
import { useBuyoutItemsFilters } from "@/contractor/pages/home/buyout/components/document/providers/hooks/useBuyoutItemsFilters";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { QuoteDocumentDetails } from "@/contractor/pages/home/common/quote-document/components/quote-document-details/QuoteDocumentDetails";
import { ZoneItemsContainer } from "@/contractor/pages/home/project/Project.styles";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useAddItemsToRelease } from "../AddItemsToReleaseProvider";
import { BuyoutFilters } from "./components/BuyoutFilters";

const Content = tw.div<{ $hasQuote: boolean }>`
  ${({ $hasQuote }: { $hasQuote: boolean }) =>
    $hasQuote && "grid-cols-[5fr_4fr]"} grid gap-2`;
const ListContainer = tw.div`max-h-[calc(100vh-300px)] overflow-y-auto pr-4`;

type Props = {
  columns: Array<GridCol<BuyoutItemFieldsFragment>>;
  buyoutId?: string;
};

export const AddFromBuyoutToReleaseList: FC<Props> = ({ columns }) => {
  const { buyout } = useContractorBuyout();
  const { selectedItems } = useAddItemsToRelease();
  const { costCodes, toggleCategory, groupedByCostCode } = useBuyoutGrouped();

  const {
    filterExcludedFullyOrdered,
    filterSelectedCostCodes,
    filterSelectedTags,
  } = useBuyoutItemsFilters();

  const itemFn = useCallback(
    (item: BuyoutItemFieldsFragment) => {
      const isIncluded = selectedItems?.some((i) => i.itemId === item.id);
      return {
        className: `${
          isIncluded
            ? "border-2 border-blue-500 bg-blue-100 transition-all"
            : "opacity-90 transition-all bg-gray-100"
        }`,
      };
    },
    [selectedItems],
  );

  const items = useMemo(() => {
    return (
      buyout?.items
        .filter(filterExcludedFullyOrdered)
        .filter(filterSelectedCostCodes)
        .filter(filterSelectedTags)
        .toSorted((a, b) => (a.position || 0) - (b.position || 0)) ?? []
    );
  }, [
    buyout?.items,
    filterExcludedFullyOrdered,
    filterSelectedCostCodes,
    filterSelectedTags,
  ]);

  return (
    <Content $hasQuote={!!buyout?.quoteDocument}>
      <ListContainer>
        <BuyoutFilters />
        <ListRenderer>
          <GridTable
            configuration={{
              container: ZoneItemsContainer,
              columns,
              classNames: {
                header: "top-14",
                category: "top-[107px]",
                itemFn,
              },
              toggle: {
                category: toggleCategory,
              },
            }}
            items={groupedByCostCode ? costCodes : items}
            virtualized
          />
        </ListRenderer>
      </ListContainer>
      <If isTrue={buyout?.quoteDocument}>
        <ListContainer>
          <QuoteDocumentDetails document={buyout?.quoteDocument} />
        </ListContainer>
      </If>
    </Content>
  );
};
