import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  CreateRfqInput,
  RfqDocument,
  RfqsDocument,
  ViewerDocument,
  namedOperations,
  useCreateRfqMutation,
  useUpdateRfqMutation,
} from "@/generated/graphql";
import { FC, createContext, useContext } from "react";

type ProviderContextType = {
  createRfq: (
    input: CreateRfqInput,
  ) => Promise<{ id: string; clientIdentifier: string } | null>;
  updateRfqDetails: (
    rfqId: string,
    clientIdentifier?: string,
    vendorLocationIds?: string[],
  ) => Promise<string | null>;
};

const ProviderContext = createContext<ProviderContextType>({
  createRfq: () => Promise.resolve(null),
  updateRfqDetails: () => Promise.resolve(null),
});

export const RfqCreationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [createRfqMutation] = useCreateRfqMutation();
  const [updateRfqDetailsMutation] = useUpdateRfqMutation();
  const { setError } = useGlobalError();
  const { paginationArgs } = usePagination();

  const createRfq = async (input: CreateRfqInput) => {
    try {
      const { data, errors } = await createRfqMutation({
        variables: {
          input,
        },
        refetchQueries: [
          { query: RfqsDocument, variables: { ...paginationArgs, filter: {} } },
          { query: ViewerDocument },
        ],
        update: (cache) => cleanQuery(cache, namedOperations.Query.Rfqs),
      });
      setError(errors);

      return data?.createRfq || null;
    } catch (errors) {
      setError(errors);

      return null;
    }
  };

  const updateRfqDetails = async (
    rfqId: string,
    clientIdentifier?: string,
    vendorLocationIds?: string[],
  ) => {
    try {
      const { data, errors } = await updateRfqDetailsMutation({
        variables: {
          input: {
            rfqId,
            clientCustomNumber: clientIdentifier,
            vendorLocationIds,
          },
        },
        refetchQueries: [
          { query: RfqDocument, variables: { id: rfqId } },
          { query: RfqsDocument, variables: { ...paginationArgs, filter: {} } },
          { query: ViewerDocument },
        ],
      });
      setError(errors);

      return data?.updateRfq?.id || null;
    } catch (errors) {
      setError(errors);

      return null;
    }
  };

  return (
    <ProviderContext.Provider value={{ createRfq, updateRfqDetails }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useRfqCreation = (): ProviderContextType =>
  useContext(ProviderContext);
