import { OrgRole } from "@/generated/graphql";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useUser } from "../providers/UserProvider";
import { useStartupDataStore } from "../stores/useStartupDataStore";

export const useUserLocations = () => {
  const { locations, loading } = useStartupDataStore(
    useShallow((state) => ({
      locations: state.locations,
      loading: state.loading,
    })),
  );
  const { viewer } = useUser();

  const filteredLocations = useMemo(
    () =>
      locations.filter((loc) => {
        return (
          viewer?.locationRoles.some((role) => role.orgLocID === loc.id) ||
          viewer?.orgRoles.some((role) => role === OrgRole.OrgAdmin)
        );
      }),
    [viewer, locations],
  );

  return useMemo(() => {
    return { locations: filteredLocations || [], loading };
  }, [loading, filteredLocations]);
};
