import { DeleteButton } from "@/common/components/button/DeleteButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { NoFunction } from "@/types/NoFunction";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCancelBuyoutByRfqId } from "../../hooks/useCancelBuyoutByRfqId";

type Props = {
  rfqId?: string;
  rfqNumber?: string;
  isVisible: boolean;
  $singleBuyout?: boolean;
  isIcon?: boolean;
  buyoutNo?: string;
};

export const CancelBuyoutButton: FC<Props> = ({
  rfqId,
  rfqNumber,
  isVisible,
  isIcon = false,
  $singleBuyout = false,
  buyoutNo,
}) => {
  const intl = useIntl();
  const { cancelRfqBuyouts } = useCancelBuyoutByRfqId();

  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();

  const openCancelBuyoutsDialog = () => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      includeWarningIcon: true,
      title: intl.$t({
        id: "CANCEL_RFQ_BUYOUTS",
      }),
      text: intl.$t({ id: "CONFIRM_CANCEL_RFQ_BUYOUTS_DETAILS" }),
      handleConfirm: async () => {
        if (rfqId) {
          const result = await cancelRfqBuyouts(rfqId);
          if (result) {
            const message = $singleBuyout
              ? intl.$t(
                  { id: "BUYOUT_CANCELED_SUCCESS" },
                  { number: strongify(buyoutNo) },
                )
              : intl.$t(
                  { id: "BUYOUTS_CANCELED_SUCCESS" },
                  { rfqNumber: strongify(rfqNumber) },
                );
            setSuccessAlert(message);
          }
        }
      },
    });
  };

  return (
    <If isTrue={isVisible}>
      <If isTrue={!isIcon}>
        <OutlinedButton onClick={openCancelBuyoutsDialog}>
          <FormattedMessage
            id={$singleBuyout ? "CANCEL_RFQ_BUYOUT" : "CANCEL_RFQ_BUYOUTS"}
          />
        </OutlinedButton>
      </If>
      <If isTrue={isIcon}>
        <DeleteButton onClick={NoFunction} />
      </If>
    </If>
  );
};
