import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useUpdateOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useUpdateOrgSettings";
import { DisplaySettings } from "@/generated/graphql";

export const useManufacturersSettings = () => {
  const { updateOrgSettings } = useUpdateOrgSettings();
  const { settings } = useOrgSettings();

  const getVendorHasManufacturerSetting = (
    displaySettings:
      | Pick<DisplaySettings, "enableManufacturers">
      | undefined
      | null,
  ) => {
    return displaySettings?.enableManufacturers ?? false;
  };

  return {
    hasManufacturersSetting: settings?.display?.enableManufacturers ?? false,
    getVendorHasManufacturerSetting,
    updateOrgSettings,
  };
};
