import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  MdContainer,
  MdFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { QuoteInputContainerReadonly } from "@/distributor/common/Common.styles";
import { BidPriceTotalForItem } from "@/distributor/common/quote/BidPriceTotalForItem";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useItemsHavePhaseCodes } from "../../common/hooks/useItemsHavePhaseCodes";
import { BuyoutCategoryTotal } from "../BuyoutCategoryTotal";
import { DistributorBuyoutGroupByCostCodeToggle } from "../DistributorBuyoutGroupByCostCodeToggle";
import { DistributorBuyoutItemMaterialView } from "../DistributorBuyoutItemMaterialView";
import { DistributorBuyoutTagsPicker } from "../DistributorBuyoutTagsPicker";
import { DistributorProductView } from "../DistributorProductView";
import { BuyoutNotesDetails } from "../non-quoted/components/BuyoutNotesDetails";
import { WithdrawalWrapper } from "../non-quoted/components/WithdrawalWrapper";
import { SymbolContainer, TotalContainerStyled } from "./BuyoutItem.styles";

const ItemWrapper = tw(Base)`
  col-span-full
  lg:basis-3/12 lg:w-3/12
  xl:flex-1
`;

export const useDistributorReadonlyBuyoutConfiguration = () => {
  const itemsHavePhaseCodes = useItemsHavePhaseCodes();
  const { hasPhaseCodes } = useOrgSettings();

  const configuration: Array<GridCol<DistributorBuyoutItemFieldsFragment>> =
    useMemo(
      () => [
        {
          wrapper: ItemWrapper,
          item: ({ item, count }) =>
            item.rfqItem ? (
              <DistributorProductView item={item} count={count} />
            ) : (
              <DistributorBuyoutItemMaterialView
                item={item}
                readonly
                count={count}
              />
            ),
          group: (category) => (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
              itemsHavePhaseCodes={itemsHavePhaseCodes(category.items)}
            />
          ),
          header: (
            <>
              <FormattedMessage id="ORDERED_ITEMS" />
              <DistributorBuyoutGroupByCostCodeToggle />
            </>
          ),
          includesCounter: true,
        },
        {
          wrapper: MdFixedContainer,
          header: (
            <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />
          ),
          item: ({ item }) => {
            return <DistributorBuyoutTagsPicker item={item} readonly />;
          },
        },
        {
          wrapper: MdContainer,
          position: "center",
          item: ({ item }) => (
            <ValueUnit
              value={item.quantityDecimal}
              uom={item.projectItem.estimateUom}
            />
          ),
          header: <FormattedMessage id="UNITS_IN_BUYOUT" />,
          group: (category) => (
            <ProductCategoryCount itemsLength={category.items.length} />
          ),
        },
        {
          wrapper: SymbolContainer,
          item: () => <>x</>,
        },
        {
          wrapper: QuoteInputContainerReadonly,
          item: ({ item }) => (
            <ValueCurrency
              value={item.unitPrice || 0}
              perUom={item.projectItem.estimateUom}
              dynamicDigits
            />
          ),
          header: <FormattedMessage id="UNIT_PRICE" />,
        },
        {
          wrapper: SymbolContainer,
          item: () => <>=</>,
        },
        {
          wrapper: TotalContainerStyled,
          item: ({ item }) => <BidPriceTotalForItem item={item} />,
          position: "end",
          header: <FormattedMessage id="EXT_PRICE" />,
          group: (category) => <BuyoutCategoryTotal items={category.items} />,
          details: ({ item, readonly }) => {
            return (
              <WithdrawalWrapper item={item}>
                <BuyoutNotesDetails item={item} readonly={readonly} />
              </WithdrawalWrapper>
            );
          },
        },
      ],
      [itemsHavePhaseCodes, hasPhaseCodes],
    );
  return configuration;
};
