import { getManufacturerIdFromPicker } from "@/common/components/manufacturer-picker/getManufacturerIdFromPicker";
import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { hasProperty } from "@/common/utils/objectUtils";
import {
  EstimatedItemFieldsFragment,
  ProjectDocument,
  ProjectItemFieldsFragment,
  Scalars,
  UomFieldsFragment,
  UomsDocument,
  UpdateEstimatedItemInput,
  UpdateEstimatedItemsInput,
  useAddEstimatedItemMutation,
  useCreateOrgCatalogSkuMutation,
  useRemoveEstimatedItemMutation,
  useUpdateEstimatedItemsTagsMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBoolean,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
} from "@/types/NoFunction";
import { gql } from "@apollo/client";
import { FC, createContext, useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { UNSPECIFIED_COST_CODE_ID } from "../../../../../common/hooks/useUnspecifiedCostCode";
import { UNSPECIFIED_ZONE_ID } from "../../../../../common/hooks/useUnspecifiedZone";
import { useProjectEstimatedItems } from "../hooks/useProjectEstimatedItems";

type EditProjectEstimatedItem = UpdateEstimatedItemInput & {
  costTypeId?: string;
  name?: string;
  orgCatalogSkuId?: Scalars["ID"]["input"];
  orgCatalogSkuName?: string;
  masterProductId?: Scalars["ID"]["input"];
  masterSkuId?: Scalars["ID"]["input"];
  manufacturerId?: string;
  deliveryDate?: number;
  projectId?: string;
  isAddMode?: boolean;
  hasError?: boolean;
  unitPrice?: string;
  uomId?: string;
};

type ExpandedItem = { id: string; zoneId?: string };

type EstimatedItemUpdates = {
  key: keyof EditProjectEstimatedItem;
  value: string | null | number | boolean | undefined | UomFieldsFragment;
};

type ProviderContextType = {
  updateEstimatedItem: (props: {
    estimatedItemInput?: UpdateEstimatedItemInput;
  }) => Promise<boolean>;
  addEstimatedItem: () => Promise<boolean>;
  expandedItems: Array<ExpandedItem>;
  setExpandedItem: (id: string, zoneId?: string) => void;
  newProjectEstimatedItem: EditProjectEstimatedItem;
  removeEstimatedItem: (id: string) => Promise<boolean>;
  setNewProjectEstimatedItem: (
    input: Array<EstimatedItemUpdates>,
    reset?: boolean,
  ) => void;
  saveEstimatedItem: (item: ProjectItemFieldsFragment) => Promise<boolean>;
  editEstimatedItem: (
    item: ProjectItemFieldsFragment,
    estimatedItem?: EstimatedItemFieldsFragment | null,
  ) => boolean;
  cancelEstimatedItem: () => void;
  updateEstimatedItemTags: (id: string, tags: string[]) => Promise<boolean>;
  updating: boolean;
  selectedEstimatedItemIds: string[];
  setSelectedEstimatedItemIds: (id: string[]) => void;
  updateEstimatedItems: (input: UpdateEstimatedItemsInput) => Promise<boolean>;
};

type Props = {
  children: React.ReactNode;
  projectId?: string;
};

const ProviderContext = createContext<ProviderContextType>({
  updateEstimatedItem: NoFunctionBooleanPromise,
  addEstimatedItem: NoFunctionBooleanPromise,
  expandedItems: [],
  setExpandedItem: NoFunction,
  newProjectEstimatedItem: { id: "" },
  setNewProjectEstimatedItem: NoFunction,
  removeEstimatedItem: NoFunctionBooleanPromise,
  saveEstimatedItem: NoFunctionBooleanPromise,
  editEstimatedItem: NoFunctionBoolean,
  cancelEstimatedItem: NoFunctionPromise,
  updateEstimatedItemTags: NoFunctionBooleanPromise,
  updating: false,
  selectedEstimatedItemIds: [],
  setSelectedEstimatedItemIds: NoFunction,
  updateEstimatedItems: NoFunctionBooleanPromise,
});

export const EstimatedItemsProvider: FC<Props> = ({ children, projectId }) => {
  const [updateEstimatedItemTagsMutation] =
    useUpdateEstimatedItemsTagsMutation();
  const [addEstimatedItemMutation] = useAddEstimatedItemMutation();
  const [removeEstimatedItemMutation] = useRemoveEstimatedItemMutation();
  const [createOrgCatalogSkuMutation] = useCreateOrgCatalogSkuMutation();
  const { setError } = useGlobalError();
  const [expandedItems, setExpandedItems] = useState<Array<ExpandedItem>>([]);
  const [newProjectEstimatedItem, setNewProjectEstimatedItem] =
    useState<EditProjectEstimatedItem>({ id: "", isAddMode: false });
  const [selectedEstimatedItemIds, setSelectedEstimatedItemIds] = useState<
    string[]
  >([]);
  const { setWarningAlert } = useSnackbar();
  const { updateEstimatedItems, updating } = useProjectEstimatedItems();
  const intl = useIntl();

  const updateEstimatedItemTags = useCallback(
    async (id: string, tags: string[]) => {
      try {
        const { errors } = await updateEstimatedItemTagsMutation({
          variables: {
            input: { id, tags },
          },
          update: (cache, { data }) => {
            data?.updateEstimatedItem.forEach((item) => {
              cache.writeFragment({
                id: `EstimateItem:${item.id}`,
                fragment: gql`
                  fragment EstimatedItemTags on EstimatedItem {
                    id
                    tags {
                      id
                    }
                  }
                `,
                data: {
                  tags: item.tags,
                },
              });
            });
          },
        });
        setError(errors);
        return !errors;
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, updateEstimatedItemTagsMutation],
  );

  const updateEstimatedItem = useCallback(
    async ({
      estimatedItemInput,
    }: {
      estimatedItemInput?: UpdateEstimatedItemInput;
    } = {}) => {
      if (
        !estimatedItemInput &&
        newProjectEstimatedItem.id &&
        Object.keys(newProjectEstimatedItem).length === 1
      ) {
        setNewProjectEstimatedItem({ id: "", isAddMode: false });
        return true;
      }
      const updateEstimatedItemInput = estimatedItemInput || {
        costCodeId:
          newProjectEstimatedItem.costCodeId === UNSPECIFIED_COST_CODE_ID
            ? undefined
            : newProjectEstimatedItem.costCodeId,
        id: newProjectEstimatedItem.id,
        quantity: newProjectEstimatedItem.quantity,
        unitPrice: newProjectEstimatedItem.unitPrice
          ? newProjectEstimatedItem.unitPrice
          : undefined,
        clearUnitPrice:
          hasProperty(newProjectEstimatedItem, "unitPrice") &&
          !newProjectEstimatedItem.unitPrice,
        zoneId:
          newProjectEstimatedItem.zoneId === UNSPECIFIED_ZONE_ID
            ? undefined
            : newProjectEstimatedItem.zoneId,
        notes: newProjectEstimatedItem.notes,
        tags: newProjectEstimatedItem.tags,
        uom: newProjectEstimatedItem.uom,
        clearManufacturer: !getManufacturerIdFromPicker(
          newProjectEstimatedItem.manufacturerId ?? null,
        ),
        manufacturerId: getManufacturerIdFromPicker(
          newProjectEstimatedItem.manufacturerId ?? null,
        ),
        deliveryDate: newProjectEstimatedItem.deliveryDate,
        clearZone: newProjectEstimatedItem.zoneId === UNSPECIFIED_ZONE_ID,
        clearCostCode:
          (!newProjectEstimatedItem.costCodeId &&
            hasProperty(newProjectEstimatedItem, "costCodeId")) ||
          newProjectEstimatedItem.costCodeId === UNSPECIFIED_COST_CODE_ID,
      };
      setNewProjectEstimatedItem({ id: "", isAddMode: false });
      if (!projectId) {
        throw new Error("Project ID is required");
      }
      return await updateEstimatedItems({
        projectId,
        updates: [updateEstimatedItemInput],
      });
    },
    [newProjectEstimatedItem, projectId, updateEstimatedItems],
  );

  const removeEstimatedItem = async (id: string) => {
    try {
      const { errors } = await removeEstimatedItemMutation({
        variables: {
          id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ProjectDocument,
            variables: { id: projectId || "", excludePhantoms: true },
          },
        ],
      });
      setError(errors);
      if (!errors) {
        setNewProjectEstimatedItem({ id: "", isAddMode: false });
      }

      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const addEstimatedItem = useCallback(async () => {
    try {
      let orgCatalogSkuId = newProjectEstimatedItem.orgCatalogSkuId;
      if (newProjectEstimatedItem.orgCatalogSkuName) {
        const { data, errors: orgCatalogErrors } =
          await createOrgCatalogSkuMutation({
            variables: {
              input: {
                name: newProjectEstimatedItem.orgCatalogSkuName,
                defaultUom: newProjectEstimatedItem.uom || "",
              },
            },
          });

        setError(orgCatalogErrors);
        orgCatalogSkuId = data?.createOrgCatalogSku.id;
        setNewProjectEstimatedItem({
          ...newProjectEstimatedItem,
          orgCatalogSkuId,
        });
      }
      const { errors } = await addEstimatedItemMutation({
        variables: {
          input: {
            projectId: projectId || "",
            manufacturerId: getManufacturerIdFromPicker(
              newProjectEstimatedItem.manufacturerId ?? null,
            ),
            costCodeId: newProjectEstimatedItem.costCodeId,
            quantity: newProjectEstimatedItem.quantity || 0,
            unitPrice: newProjectEstimatedItem.unitPrice || undefined,
            zoneId: newProjectEstimatedItem.zoneId,
            notes: newProjectEstimatedItem.notes,
            tags: newProjectEstimatedItem.tags,
            item: {
              estimateUom: newProjectEstimatedItem.uom || "",
              orgCatalogSkuId,
              masterProductId: newProjectEstimatedItem.masterProductId,
              masterSkuId: newProjectEstimatedItem.masterSkuId,
            },
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ProjectDocument,
            variables: { id: projectId || "", excludePhantoms: true },
          },
          {
            query: UomsDocument,
          },
        ],
      });
      setError(errors);
      if (!errors) {
        setNewProjectEstimatedItem({ id: "", isAddMode: false });
      }

      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  }, [
    addEstimatedItemMutation,
    createOrgCatalogSkuMutation,
    projectId,
    setError,
    newProjectEstimatedItem,
    setNewProjectEstimatedItem,
  ]);

  const setExpandedItem = useCallback(
    (item: string, zoneId?: string) => {
      if (expandedItems.some((i) => item === i.id && i.zoneId === zoneId)) {
        setExpandedItems(
          expandedItems.filter(
            (i) => i.id !== item && (!zoneId || i.zoneId !== zoneId),
          ),
        );
      } else {
        setExpandedItems([...expandedItems, { id: item, zoneId }]);
      }
    },
    [expandedItems],
  );

  const setNewProjectEstimatedItemProp = useCallback(
    (input: Array<EstimatedItemUpdates>, reset?: boolean) => {
      let props = {} as EditProjectEstimatedItem;
      input.forEach(({ key, value }) => {
        props = { ...props, [key]: value };
      });
      setNewProjectEstimatedItem({
        ...(!reset && newProjectEstimatedItem),
        ...props,
      });
    },
    [newProjectEstimatedItem, setNewProjectEstimatedItem],
  );

  const cancelEstimatedItem = useCallback(() => {
    setNewProjectEstimatedItemProp(
      [
        { key: "id", value: "" },
        { key: "isAddMode", value: false },
      ],
      true,
    );
  }, [setNewProjectEstimatedItemProp]);

  const saveEstimatedItem = useCallback(
    async (item: ProjectItemFieldsFragment) => {
      if (newProjectEstimatedItem?.id && item?.estimatedItems.length) {
        if (newProjectEstimatedItem?.quantity === 0) {
          setWarningAlert(intl.$t({ id: "PROJECT_ITEMS_VALIDATION_QUANTITY" }));
          setNewProjectEstimatedItemProp([{ key: "hasError", value: true }]);
          return false;
        }
        if (
          !newProjectEstimatedItem.isAddMode &&
          Object.keys(newProjectEstimatedItem).length === 2
        ) {
          setNewProjectEstimatedItemProp(
            [
              { key: "id", value: "" },
              { key: "isAddMode", value: false },
            ],
            true,
          );
          return true;
        }
        await updateEstimatedItem();
      } else {
        if (
          !newProjectEstimatedItem?.quantity ||
          !newProjectEstimatedItem?.uom ||
          (!newProjectEstimatedItem?.orgCatalogSkuId &&
            !newProjectEstimatedItem?.orgCatalogSkuName &&
            !newProjectEstimatedItem?.masterSkuId &&
            !newProjectEstimatedItem?.masterProductId)
        ) {
          setWarningAlert(intl.$t({ id: "PROJECT_ITEMS_VALIDATION" }));
          setNewProjectEstimatedItemProp([{ key: "hasError", value: true }]);
          return false;
        }
        await addEstimatedItem();
      }
      return true;
    },
    [
      addEstimatedItem,
      intl,
      newProjectEstimatedItem,
      setNewProjectEstimatedItemProp,
      setWarningAlert,
      updateEstimatedItem,
    ],
  );

  const editEstimatedItem = useCallback(
    (
      item: ProjectItemFieldsFragment,
      estimatedItem?: EstimatedItemFieldsFragment | null,
    ) => {
      if (newProjectEstimatedItem?.id || newProjectEstimatedItem.isAddMode) {
        setWarningAlert(
          intl.$t({ id: "PROJECT_ITEMS_EDIT_IN_PROGRESS_VALIDATION" }),
        );
        setNewProjectEstimatedItemProp([{ key: "hasError", value: true }]);
        return false;
      }
      const hasEstimatedItems = item?.estimatedItems
        ? item?.estimatedItems.length === 0
        : false;
      let estimatedItemInput: Array<EstimatedItemUpdates> = [
        { key: "id", value: estimatedItem?.id || item?.id || "" },
      ];
      if (hasEstimatedItems) {
        estimatedItemInput = estimatedItemInput.concat([
          { key: "isAddMode", value: false },
          { key: "quantity", value: estimatedItem?.quantityDecimal || "0" },
          { key: "costCodeId", value: estimatedItem?.id || "" },
          { key: "uom", value: item?.estimateUom?.pluralDescription || "" },
          {
            key: "orgCatalogSkuId",
            value: isOrgCatalogSku(item?.material.material)
              ? item?.material.material.id
              : undefined,
          },
          {
            key: "masterProductId",
            value: isProductSku(item?.material.material)
              ? item?.material.material.id
              : undefined,
          },
          {
            key: "masterSkuId",
            value: isMasterSku(item?.material.material)
              ? item?.material.material.id
              : undefined,
          },
          {
            key: "unitPrice",
            value: estimatedItem?.unitPrice,
          },
          {
            key: "deliveryDate",
            value: undefined,
          },
        ]);
      }
      setNewProjectEstimatedItemProp(estimatedItemInput);
      return true;
    },
    [
      intl,
      newProjectEstimatedItem?.id,
      newProjectEstimatedItem.isAddMode,
      setNewProjectEstimatedItemProp,
      setWarningAlert,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        addEstimatedItem,
        updateEstimatedItem,
        expandedItems,
        setExpandedItem,
        newProjectEstimatedItem,
        setNewProjectEstimatedItem: setNewProjectEstimatedItemProp,
        removeEstimatedItem,
        saveEstimatedItem,
        editEstimatedItem,
        cancelEstimatedItem,
        updateEstimatedItemTags,
        selectedEstimatedItemIds,
        setSelectedEstimatedItemIds,
        updating,
        updateEstimatedItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useEstimatedItems = (): ProviderContextType =>
  useContext(ProviderContext);
