import { TaxCodeSummaryFieldsFragment, TaxType } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { UpdateSalesTaxFunction } from "../../types/SalesTaxInputProps";
import { NativeSalesTaxPicker } from "./input/NativeSalesTaxPicker";

type Props = {
  taxCode: string | null;
  taxType: TaxType | undefined;
  setTaxCode: (value: string | null) => void;
  update?: UpdateSalesTaxFunction;
  fallbackTaxCodes?: TaxCodeSummaryFieldsFragment[];
};

export const NativeSalesTaxInput: FC<Props> = ({
  taxCode,
  taxType,
  setTaxCode,
  update,
  fallbackTaxCodes,
}) => {
  const onChange = useCallback(
    async (taxCodeId: string | null) => {
      if (update) {
        await update({
          taxCodeId: taxCodeId ? taxCodeId : null,
          taxType,
          clearTaxCode: !taxCodeId,
        });
      } else {
        setTaxCode(taxCode ? taxCode : null);
      }
    },
    [setTaxCode, taxCode, taxType, update],
  );

  return (
    <NativeSalesTaxPicker
      onChange={onChange}
      value={taxCode}
      fallbackTaxCodes={fallbackTaxCodes}
    />
  );
};
