import { Checkbox } from "@/common/components/checkbox/Checkbox";
import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { AddToBuyoutItem } from "@/contractor/pages/home/buyout/components/document/providers/BuyoutProjectItemsProvider";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";
import { AddToRfqItem } from "@/contractor/pages/home/rfq/components/import-project-items/RfqProjectItemsProvider";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";

type Props = {
  items: ProjectItemFieldsFragment[];
  updates: AddToRfqItem[] | AddToBuyoutItem[];
  setUpdates: (updates: AddToRfqItem[] | AddToBuyoutItem[]) => void;
};
export const AddEstimatedItemGroupCheckbox: FC<Props> = ({
  items,
  updates,
  setUpdates,
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(
      updates.length > 0 &&
        items.every((item) =>
          updates.some(
            (update) =>
              item.id === update.itemId &&
              (!item.estimatedItems.length ||
                !update.estimatedItemIds.length ||
                item.estimatedItems.some((i) =>
                  update.estimatedItemIds.includes(i.id),
                )),
          ),
        ),
    );
  }, [updates, items]);

  const onToggle = useCallback(
    (value: boolean) => {
      const itemsToAdd = items.map((item) => {
        const estimatedItem = getEstimatedItem({
          item,
          sumIfMultiple: true,
        });
        return {
          itemId: item.id,
          isIncluded: value,
          description: item.material.material.name,
          quantityDecimal: estimatedItem?.quantityDecimal || "1",
          manufacturerId: estimatedItem?.manufacturer?.id,
          estimatedItemIds: item.estimatedItems[0]?.id
            ? [item.estimatedItems[0]?.id]
            : [],
          costCodeId: item.estimatedItems[0].costCode?.id || undefined,
          tags: item.estimatedItems[0].tags?.map((tag) => tag.id) || [],
          projectItem: {
            estimateUom: item.estimateUom.pluralDescription,
            ...(isOrgCatalogSku(item.material.material) && {
              orgCatalogSkuId: item.material.material.id,
            }),
            ...(isProductSku(item.material.material) && {
              masterProductId: item.material.material.id,
            }),
            ...(isMasterSku(item.material.material) && {
              masterSkuId: item.material.material.id,
            }),
          },
        };
      });

      setUpdates(itemsToAdd);
    },
    [items, setUpdates],
  );

  return (
    <Checkbox
      onClick={(event) => {
        event.stopPropagation();
        onToggle(!checked);
      }}
      className="print:hidden"
      checked={checked}
    />
  );
};
