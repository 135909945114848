import { useLumpSumReleaseItems } from "@/contractor/pages/home/release/hooks/useLumpSumReleaseItems";
import { AddToReleaseItemInput } from "@/generated/graphql";
import { useCallback } from "react";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useLumpSumInvoiceReleaseItems = () => {
  const { getLumpSumReleaseItem } = useLumpSumReleaseItems();
  const { invoice } = useInvoiceVerification();

  const getInvoiceLumpSumReleaseItem = useCallback(
    async (
      values: InvoiceCreateReleaseFormValues,
    ): Promise<AddToReleaseItemInput[]> =>
      await getLumpSumReleaseItem(values, {
        invoiceId: invoice?.id,
      }),
    [getLumpSumReleaseItem, invoice],
  );

  return { getInvoiceLumpSumReleaseItem };
};
