import {
  TagExtendedFieldsFragment,
  TagFieldsFragment,
} from "@/generated/graphql";
import { Close } from "@mui/icons-material";
import { darken, getLuminance, lighten } from "@mui/material";
import { memo, useMemo } from "react";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../button/IconButton";
import { If } from "../if/If";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  tag?: TagExtendedFieldsFragment;
  className?: string;
  onRemove?: (value: TagFieldsFragment) => void;
  readonly?: boolean;
};

const Item = tw.div`
  relative inline-flex py-1 pl-2 pr-3 min-w-12 rounded-l rounded-r-4xl text-white 
  font-normal items-center justify-between whitespace-nowrap w-fit
`;

const Name = tw.span`
  truncate text-xs font-medium
`;

const ActionContainer = tw.div`
  absolute grid grid-flow-col place-items-center right-0 top-0 pr-0.5 rounded-r-4xl cursor-pointer 
  opacity-0 group-hover/tag:opacity-100
`;

const Dots = tw.span`
  text-xs py-1 font-medium pl-1
`;

const CloseIcon = tw(Close)`
  text-sm pb-0.5 text-blue-800 hover:text-black
`;

const CloseButton = tw(IconButtonBorderless)`
  w-5 h-5 min-h-5 place-items-center
`;

export const TagItem = memo(({ tag, className, onRemove, readonly }: Props) => {
  const isImported = useMemo(() => tag?.hasMapping, [tag]);
  const backgroundColor = useMemo(() => {
    if (isImported) {
      return "white";
    }
    if (tag && tag.color) {
      return lighten(tag.color, 0.8 - getLuminance(tag.color));
    } else {
      return "gray";
    }
  }, [isImported, tag]);

  const color = useMemo(() => {
    if (tag && tag.color) {
      return darken(tag.color, 0.65);
    } else {
      return "darkgray";
    }
  }, [tag]);

  const border = useMemo(() => {
    if (!isImported) {
      return {
        border: "1px solid transparent",
      };
    }
    if (tag && tag.color) {
      const color = lighten(tag.color, 0.8 - getLuminance(tag.color));
      return {
        border: `1px solid ${color}`,
        borderLeft: `4px solid ${color}`,
      };
    } else {
      return {
        border: "1px solid darkgray",
        borderLeft: "4px solid darkgray",
      };
    }
  }, [isImported, tag]);

  if (!tag) {
    return null;
  }

  return (
    <Item
      style={{
        backgroundColor,
        color,
        ...border,
      }}
      className={`${className} group/tag`}
    >
      <Tooltip
        id="tag-name"
        className="truncate text-xs"
        element={<Name title={tag.name}>{tag.name}</Name>}
      >
        {tag.name}
      </Tooltip>
      <If isTrue={!readonly && onRemove}>
        <ActionContainer style={{ backgroundColor }}>
          <Dots style={{ color }}>...</Dots>
          <CloseButton onClick={() => onRemove && onRemove(tag)}>
            <CloseIcon />
          </CloseButton>
        </ActionContainer>
      </If>
    </Item>
  );
});
