import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useGridTable } from "../providers/GridTableProvider";

export const useGridTableCategoryVisibility = (category: string) => {
  const { visibleCategories, setTableCategoryVisibilities, virtualized } =
    useGridTable(
      useShallow((state) => ({
        visibleCategories: state.table.visibleCategories,
        categoryType: state.table.categoryType,
        setTableCategoryVisibilities: state.setTableCategoriesVisibility,
        virtualized: state.table.virtualized,
      })),
    );
  const visible = useMemo(
    () =>
      !virtualized ||
      visibleCategories.includes(category) ||
      visibleCategories.some((visibleCategory) =>
        category.startsWith(visibleCategory),
      ),
    [category, virtualized, visibleCategories],
  );

  const setVisible = useCallback(
    (visible: boolean) => setTableCategoryVisibilities([category], visible),
    [category, setTableCategoryVisibilities],
  );

  return { visible, setVisible };
};
