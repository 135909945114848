import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

interface PoPredictedNumberProps {
  predictedNumber?: number | null;
}

const Container = tw.div`flex flex-col`;
const Title = tw.span`text-sm text-gray-600`;
const PoSequenceNumber = tw.span``;

export const PoPredictedNumber = ({
  predictedNumber,
}: PoPredictedNumberProps) => {
  const intl = useIntl();

  if (!predictedNumber) {
    return null;
  }

  return (
    <Container>
      <Title>{intl.$t({ id: "ORDER_SEQUENCE_NUMBER" })}</Title>
      <PoSequenceNumber>{predictedNumber}</PoSequenceNumber>
    </Container>
  );
};
