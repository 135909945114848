import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { EstimatedItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useProjectTags } from "../../hooks/useProjectTags";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";

type Props = {
  estimatedItem?: EstimatedItemFieldsFragment | null;
  readonly?: boolean;
};

export const ProjectItemTagsPicker: FC<Props> = ({
  estimatedItem,
  readonly,
}) => {
  const { project } = useProject();
  const { updateEstimatedItemTags } = useEstimatedItems();
  const { tags: allTags } = useProjectTags();

  const handleChange = useCallback(
    async (tags: string[]) => {
      if (estimatedItem?.id) {
        await updateEstimatedItemTags(estimatedItem?.id, tags.slice(-1));
      }
    },
    [estimatedItem?.id, updateEstimatedItemTags],
  );

  if (!project || !estimatedItem || !estimatedItem.id) {
    return null;
  }

  return (
    <TagPicker
      options={allTags}
      values={estimatedItem.tags || []}
      handleChange={handleChange}
      project={project}
      readonly={readonly}
      visibleTags={1}
    />
  );
};
