import { CountType } from "@/common/components/circle-counter/CountType";
import { If } from "@/common/components/if/If";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { ReleaseItemPadding } from "@/common/components/release-item-padding/ReleaseItemPadding";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useMaterialCostCodeAndZone } from "@/contractor/pages/home/common/hooks/useMaterialCostCodeAndZone";
import { useReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { LockOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-1 items-center justify-between`;
const InnerContainer = tw.div`flex items-center`;

type Props = {
  item: ExpandedReleaseItem;
  count: CountType;
  projectId?: string;
};

export const ReleaseItemMaterial: FC<Props> = ({ item, count, projectId }) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { groupedByCostCode } = useReleaseItemsZoneProvider();
  const { itemDetails } = useMaterialCostCodeAndZone(
    item,
    groupedByCostCode,
    projectId,
  );

  return (
    <Container>
      <InnerContainer>
        <ReleaseItemPadding item={item} count={count} />
        <ProjectItemMaterialView
          item={item}
          count={count}
          includeCounter={!item.redelivery?.id && !item.backorderFromRelease}
          manufacturer={item.manufacturer}
          expandable={false}
          contractorNotes={{
            notes: item.instructions?.text,
            assets: item.instructions?.assets,
          }}
          vendorNotes={{
            notes: item.notes,
            assets: item.assets,
          }}
          showOutsideOfEstimatesInfo={item.estimatedItems.length === 0}
          extraDetails={itemDetails}
        />
      </InnerContainer>
      <If isTrue={release?.poLink?.immutable}>
        <Tooltip id="release-item-immutable-tooltip" element={<LockOutlined />}>
          <FormattedMessage
            id="ORDER_ITEM_IMMUTABLE_DESCRIPTION"
            values={{
              sourceSystem:
                release?.poLink?.sourceSystem ??
                intl.$t({ id: "SOURCE_SYSTEM" }),
            }}
          />
        </Tooltip>
      </If>
    </Container>
  );
};
