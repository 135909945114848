import { DateView } from "@/common/utils/dates/DateView";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`col-span-2`;
const DateItem = tw.div`flex items-center justify-center text-sm font-medium text-center`;
const Type = tw.div`text-xs text-gray-600 flex items-center justify-center text-center`;

type Props = {
  release: Pick<ReleasePartialFieldsFragment, "createdAt" | "createdBy">;
};

export const ReleaseCreateDate: FC<Props> = ({ release }) => {
  return (
    <Container>
      <DateItem>
        <DateView date={release.createdAt} />
      </DateItem>
      <Type>
        <DateView date={release.createdAt} onlyTime />
      </Type>
    </Container>
  );
};
