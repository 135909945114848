import { FC } from "react";
import { useIntl } from "react-intl";
import { If } from "../../../if/If";
import { InfoTooltip } from "../../../info-tooltip/InfoTooltip";
import { SalesTaxInputProps } from "../../types/SalesTaxInputProps";

type Props = Pick<SalesTaxInputProps, "taxExempt">;

export const TaxExemptInfoTooltip: FC<Props> = ({ taxExempt }) => {
  const intl = useIntl();

  return (
    <If isTrue={taxExempt?.isProjectTaxExempt || taxExempt?.isVendorTaxExempt}>
      <InfoTooltip
        message={intl.$t(
          {
            id: taxExempt?.isProjectTaxExempt
              ? "TAX_EXEMPT_PROJECT_TOOLTIP"
              : "TAX_EXEMPT_VENDOR_TOOLTIP",
          },
          {
            vendor: taxExempt?.vendorName,
          },
        )}
      />
    </If>
  );
};
