export enum ReleaseItemFilter {
  FULLY_RECEIVED = "FULLY_RECEIVED",
  FULLY_INVOICED = "FULLY_INVOICED",
  BACKORDERED = "BACKORDERED",
}

export type ReleaseItemsFilters = {
  [ReleaseItemFilter.FULLY_RECEIVED]?: boolean | null | undefined;
  [ReleaseItemFilter.FULLY_INVOICED]?: boolean | null | undefined;
  [ReleaseItemFilter.BACKORDERED]?: boolean | null | undefined;
};
