import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { ResizableColumns } from "@/common/components/resizable-columns/ResizableColumns";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { routes } from "@/config/routes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { ContractorBuyoutProvider } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { ReleaseActionsProvider } from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import {
  ReleaseProvider,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { ReleaseSequenceProvider } from "../../../../deliveries/providers/ReleaseSequenceProvider";
import { useDeliverySlipImportExternalPO } from "../../../hooks/useDeliverySlipImportExternalPO";
import { DeliverySlipReleaseProvider } from "../../../providers/DeliverySlipReleaseProvider";
import { DeliverySlipSequenceProvider } from "../../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlipVerification } from "../../../providers/DeliverySlipVerificationProvider";
import {
  DeliverySlipsProvider,
  useDeliverySlips,
} from "../../../providers/DeliverySlipsProvider";
import { DeliverySlipBreadcrumbs } from "../../breadcrumbs/DeliverySlipBreadcrumbs";
import { DeliverySlipDetails } from "../components/delivery-slip-details/DeliverySlipDetails";
import { useDeliverySlipReleaseSpreadsheetConfig } from "../components/delivery-slip-edit-release/DeliverySlipRelease.config";
import { DeliverySlipReleaseFormValues } from "../components/delivery-slip-form/DeliverySlipVerificationForm";
import { DeliverySlipImportExternalPOWizard } from "../delivery-slip-import-external-po/DeliverySlipImportExternalPOWizard";
import { DeliverySlipReceiveOrderDetails } from "./components/DeliverySlipReceiveOrderDetails";

const Container = tw.div`
  relative mt-5 xl:-mx-10
`;

const DeliverySlipReceiveOrderWithProvider: FC = () => {
  const { deliverySlip } = useDeliverySlipVerification();
  const { setGlobalVendorId } = useVendorPrices();
  const { importModalOpened } = useDeliverySlipImportExternalPO();
  const { watch, setValue } = useFormContext<DeliverySlipReleaseFormValues>();
  const vendorId = watch("vendorId");

  useEffect(() => {
    if (deliverySlip) {
      setValue("projectId", deliverySlip.project?.id || "");
      setValue("vendorId", deliverySlip.predictedSellerOrgLocation?.id || "");
      setValue("poNumber", deliverySlip.predictedRelease?.poNumber || "");
      if (deliverySlip.fulfillmentDate) {
        setValue(
          "orderDate",
          defaultReleaseDate(getUTCDate(deliverySlip.fulfillmentDate)),
        );
      }
    }
  }, [deliverySlip, setValue]);

  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  return (
    <>
      <Container>
        <DeliverySlipBreadcrumbs receive />
        <ResizableColumns hasFooter>
          <DeliverySlipReceiveOrderDetails />
          <DeliverySlipDetails />
        </ResizableColumns>
      </Container>
      <If isTrue={importModalOpened}>
        <DeliverySlipImportExternalPOWizard />
      </If>
    </>
  );
};

const DeliverySlipUpdateWrapper: FC = () => {
  const { release } = useRelease();
  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseUpdateProvider
      release={release}
      project={release?.project}
      items={release.items}
    >
      <ReleaseActionsProvider>
        <ContractorBuyoutProvider id={release.buyout?.id}>
          <DeliverySlipReleaseProvider>
            <DeliverySlipReceiveOrderWithProvider />
          </DeliverySlipReleaseProvider>
        </ContractorBuyoutProvider>
      </ReleaseActionsProvider>
    </ReleaseUpdateProvider>
  );
};

export const DeliverySlipReceiveOrder: FC = () => {
  const { deliverySlipId, deliveryId } = useParams();
  const { loading } = useDeliverySlips();

  const { deliverySlip } = useDeliverySlipVerification();
  const deliverySlipReleaseSpreadsheetConfig =
    useDeliverySlipReleaseSpreadsheetConfig();
  const navigate = useNavigate();

  if (deliverySlipId && !deliverySlip && !loading) {
    return <Loader loading />;
  }

  if (!deliveryId && !deliverySlip?.release?.id) {
    const path = generatePath(routes.deliverySlipMatchOrder, {
      deliverySlipId: deliverySlip?.id || deliverySlipId,
    });
    navigate(path);
  }

  return (
    <ReleaseSequenceProvider>
      <DeliverySlipSequenceProvider>
        <DeliverySlipsProvider>
          <ColumnMapperProvider config={deliverySlipReleaseSpreadsheetConfig}>
            <ReleaseProvider id={deliverySlip?.release?.id || deliveryId || ""}>
              <DeliverySlipUpdateWrapper />
            </ReleaseProvider>
          </ColumnMapperProvider>
        </DeliverySlipsProvider>
      </DeliverySlipSequenceProvider>
    </ReleaseSequenceProvider>
  );
};
