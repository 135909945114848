import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import { usePaymentMethodsQuery } from "@/generated/graphql";

export const usePaymentMethods = () => {
  const { isContractor } = useUser();
  const { data, error, loading } = usePaymentMethodsQuery({
    skip: !isContractor,
  });
  useErrorEffect(error);

  return {
    paymentMethods: data?.viewer?.org?.paymentMethods || [],
    loading,
  };
};
