import {
  ExternalTaxCodeFieldsFragment,
  SourceSystem,
  useExternalTaxCodesLazyQuery,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export type ExternalTaxCodeType = Omit<
  ExternalTaxCodeFieldsFragment,
  "__typename"
> & {
  id: string;
};

export const useExternalTaxCodes = () => {
  const [loadExternalTaxCodes, { loading, data }] =
    useExternalTaxCodesLazyQuery();

  const fetchExternalTaxCodes = useCallback(
    async (sourceSystem: SourceSystem) => {
      const { data } = await loadExternalTaxCodes({
        variables: { sourceSystem },
      });

      return (
        data?.externalTaxCodes
          .map((externalTaxCode) => ({
            ...externalTaxCode,
            id: externalTaxCode.externalId,
          }))
          .toSorted((a, b) => a.description.localeCompare(b.description)) || []
      );
    },
    [loadExternalTaxCodes],
  );

  const externalTaxCodes = useMemo(
    () =>
      data?.externalTaxCodes
        .toSorted((a, b) => a.description.localeCompare(b.description))
        .map((externalTaxCode) => ({
          ...externalTaxCode,
          id: externalTaxCode.externalId,
        })) || [],
    [data],
  );

  return {
    fetchExternalTaxCodes,
    externalTaxCodes,
    loading,
  };
};
