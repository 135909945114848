import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { If } from "../../../../../../../../common/components/if/If";
import { ReleaseAdditionalChargesAndTaxes } from "../../../../../../../../common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { vendorLabelFormatter } from "../../../../../../../../common/components/vendor-picker/VendorPickerCustomRender";
import { useDistributorInvoice } from "../../../../../providers/DistributorInvoiceProvider";
import {
  Column,
  ContentColumn,
  PanelTitle,
  QuarterPanel,
  TitleRow,
} from "./InvoiceExpandable.styled";

export const ReleasePanel: FC = () => {
  const { invoice } = useDistributorInvoice();

  return (
    <QuarterPanel>
      <Column>
        <TitleRow>
          <FormattedMessage id="ORDER" tagName={PanelTitle} />
        </TitleRow>
      </Column>
      <ContentColumn>
        <If isTrue={invoice}>
          <ReleaseAdditionalChargesAndTaxes
            release={invoice?.release}
            total={invoice?.release?.total}
            taxExempt={{
              isProjectTaxExempt: invoice?.release?.project?.taxExempt,
              isVendorTaxExempt: invoice?.release?.preferredVendor?.taxExempt,
              vendorName: vendorLabelFormatter(
                invoice?.release?.sellerOrgLocation,
              ),
            }}
            includeNotesPanel={false}
            includePaymentTerms={true}
            readonly
          />
        </If>
      </ContentColumn>
    </QuarterPanel>
  );
};
