import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { AnimatedLoading } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import {
  ServiceCodesDocument,
  useImportServiceCodesMutation,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { ImportExternalServiceCodesImportResults } from "../components/import-external-service-codes/steps/ImportExternalServiceCodesResults";
import { ImportExternalServiceCodesTable } from "../components/import-external-service-codes/steps/ImportExternalServiceCodesTable";
import { useImportServiceCodesStore } from "../stores/useImportServiceCodesStore";

export const useImportExternalServiceCodesModal = (): WizardModalPage[] => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();
  const {
    modalOpened,
    sourceSystem,
    selectedExternalServiceCodes,
    totalExternalServiceCodesCount,
    setImportedServiceCodes,
    closeModal,
  } = useImportServiceCodesStore();

  useEffect(() => {
    if (!modalOpened) {
      setNestedStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpened]);

  const [importServiceCodesMutation] = useImportServiceCodesMutation();
  const importSelectedServiceCodes = useCallback(async () => {
    try {
      if (sourceSystem) {
        moveToNextNestedStep();
        const { data, errors } = await importServiceCodesMutation({
          variables: {
            input: {
              externalServiceCodeIds: selectedExternalServiceCodes,
              sourceSystem,
            },
          },
          refetchQueries: [
            {
              query: ServiceCodesDocument,
            },
          ],
        });
        if (errors) {
          moveToPreviousNestedStep();
          setError(errors);
        }
        if (data?.importServiceCodes) {
          setImportedServiceCodes(data.importServiceCodes);
          moveToNextNestedStep();
        }
      }
    } catch (error) {
      moveToPreviousNestedStep();
      setError(error);
    }
  }, [
    sourceSystem,
    selectedExternalServiceCodes,
    importServiceCodesMutation,
    setImportedServiceCodes,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
    setError,
  ]);

  return [
    {
      title: null,
      pages: [
        {
          component: <ImportExternalServiceCodesTable />,
          footerButtonsConfig: [
            {
              type: "text",
              text: intl.$t(
                { id: "OUT_OF_SELECTED" },
                {
                  count: selectedExternalServiceCodes.length,
                  total: totalExternalServiceCodesCount,
                },
              ),
              className: "flex mr-4 items-center",
              hidden:
                selectedExternalServiceCodes.length === 0 &&
                totalExternalServiceCodesCount === 0,
            },
            {
              type: "outlined",
              onClick: closeModal,
              text: intl.$t({ id: "CLOSE" }),
              className: "flex-1 mr-2",
            },
            {
              type: "primary",
              onClick: importSelectedServiceCodes,
              text: intl.$t({ id: "IMPORT" }),
              className: "flex-1",
              disabled: selectedExternalServiceCodes.length === 0,
            },
          ],
        },
        {
          component: (
            <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
          ),
          hideFooter: true,
          classNames: {
            wrapper: "mb-0",
          },
        },
        {
          component: (
            <CompletedStep text={intl.$t({ id: "IMPORT_COMPLETED" })} />
          ),
          onModalClose: moveToNextNestedStep,
          hideFooter: true,
          classNames: {
            wrapper: "mb-0",
          },
        },
        {
          component: <ImportExternalServiceCodesImportResults />,
          footerButtonsConfig: [
            {
              type: "outlined",
              onClick: closeModal,
              text: intl.$t({ id: "CLOSE" }),
              className: "w-60",
            },
          ],
        },
      ],
    },
  ];
};
