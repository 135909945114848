import { InvoiceStatus } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { BaseStatusChip, BaseStatusChipProps } from "./BaseStatusChip";

const BaseStatusChipStyled = tw(BaseStatusChip)`text-center text-xs flex-col`;

type Props = BaseStatusChipProps & {
  status: InvoiceStatus | undefined;
};

const INVOICE_STATUS = {
  [InvoiceStatus.Approved]: "bg-green-300",
  [InvoiceStatus.AwaitingApproval]: "bg-yellow-600",
  [InvoiceStatus.IssuesReported]: "bg-red-200",
  [InvoiceStatus.Paid]: "bg-green-800 text-white",
  [InvoiceStatus.InternalKickback]:
    "bg-blue-100 text-blue-800 border border-blue-200",
};

export const InvoiceStatusChip: FC<Props> = ({ status, type = "normal" }) => {
  if (!status) {
    return null;
  }

  return (
    <BaseStatusChipStyled
      type={type}
      classes={{ text: INVOICE_STATUS[status] }}
      data-testid="invoice-status-chip"
    >
      <FormattedMessage
        id={`INVOICE_STATUS_${status}`}
        values={{ sub: (...chunks) => <span>{chunks}</span> }}
      />
    </BaseStatusChipStyled>
  );
};
