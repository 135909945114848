import { useSpendingReportItems } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import {
  BUDGET_VIEW_TYPE,
  useSpendingReport,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ReportChart } from "./ReportChart";
import { ReportChartBreadcrumbs } from "./ReportChartBreadcrumbs";

const Container = tw.div`grid grid-flow-col gap-3 w-full justify-items-center overflow-auto`;

type ChartDataType = {
  id?: string;
  name?: string;
  type?: string;
  allowance?: string | null | undefined;
  estimated?: string | null | undefined;
  ordered?: string | null | undefined;
  invoiced?: string | null | undefined;
  paid?: string | null | undefined;
  items?: ChartDataType[];
};

export const SpendingReportChart: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { zones } = useSpendingReportItems();
  const { viewType, materialsReport, zonesReport, vendorsReport } =
    useSpendingReport();
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [currentSelection, setCurrentSelection] = useState<ChartDataType[]>([]);
  const intl = useIntl();
  const [breadcrumbs, setBreadcrumbs] = useState<ChartDataType[]>([]);
  const [hasDragged, setHasDragged] = useState(false);

  useEffect(() => {
    const items = zones.length > 1 ? zones : (zones[0]?.items ?? []);
    const firstItemType = items && (items[0] as ChartDataType)?.type;
    const currentReport =
      viewType === BUDGET_VIEW_TYPE.MATERIALS
        ? materialsReport
        : viewType === BUDGET_VIEW_TYPE.ZONES
          ? zonesReport
          : vendorsReport;
    if (currentReport) {
      const root = [
        {
          id: "root",
          name: intl.$t({
            id:
              firstItemType === "COST_CODE"
                ? "COST_CODES"
                : firstItemType === "ZONE"
                  ? "PROJECT_ZONES"
                  : firstItemType === "VENDOR"
                    ? "VENDORS"
                    : "MATERIALS",
          }),
          type: "",
          estimated: currentReport.allowance || currentReport.estimated,
          ordered: currentReport.ordered,
          invoiced: currentReport.invoiced,
          paid: currentReport.paid,
          items,
        },
      ];
      setCurrentSelection(root);
      setBreadcrumbs([
        {
          id: "root",
          name: intl.$t({ id: "PROJECT_JOB_COSTS" }),
          items: root,
        },
      ]);
    }
  }, [zones, intl, materialsReport, zonesReport, vendorsReport, viewType]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current!.offsetLeft);
    setScrollLeft(containerRef.current!.scrollLeft);
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) {
        return;
      }
      e.preventDefault();
      const x = e.pageX - containerRef.current!.offsetLeft;
      const walk = x - startX;
      containerRef.current!.scrollLeft = scrollLeft - walk;
      setHasDragged(true);
    },
    [isDragging, startX, scrollLeft],
  );

  const onDragEnd = useCallback(() => {
    setIsDragging(false);
    setTimeout(() => {
      setHasDragged(false);
    });
  }, []);

  return (
    <>
      <ReportChartBreadcrumbs
        breadcrumbs={breadcrumbs}
        setBreadcrumbs={setBreadcrumbs}
        setCurrentSelection={setCurrentSelection}
      />
      <Container
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
      >
        {currentSelection?.map((item, index) => {
          return (
            <ReportChart
              key={index}
              title={item.id !== "root" ? item.name : ""}
              type={item.type}
              onClick={
                item.items?.length
                  ? () => {
                      if (hasDragged) {
                        return;
                      }
                      if (item.items?.length) {
                        setBreadcrumbs([...breadcrumbs, item]);
                        setCurrentSelection(item.items);
                      }
                    }
                  : undefined
              }
              data={[
                Number(item.allowance || item.estimated || 0) ?? 0,
                Number(item.ordered || 0) ?? 0,
                Number(item.invoiced || 0) ?? 0,
                Number(item.paid || 0) ?? 0,
              ]}
            />
          );
        })}
      </Container>
    </>
  );
};
