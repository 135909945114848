import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import {
  Base,
  MdFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { PaymentMethodFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { PaymentMethodActions } from "./PaymentMethodActions";
import { PaymentMethodDefaultSwitch } from "./PaymentMethodDefaultSwitch";

const WideContainer = tw(Base)`
  lg:basis-auto lg:w-5/12 2xl:basis-auto 2xl:w-6/12
`;

export const usePaymentMethodsConfiguration = () => {
  const intl = useIntl();

  const configuration: Array<GridCol<PaymentMethodFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: WideContainer,
        header: <FormattedMessage id="DESCRIPTION" />,
        item: ({ item }) => item.externalBankAccount?.name,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="TYPE" />,
        item: ({ item }) => intl.$t({ id: `PAYMENT_METHOD_TYPE_${item.type}` }),
        position: "center",
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="NUMBER" />,
        item: ({ item }) => <NotNullableRenderer value={item.number} />,
        position: "center",
      },
      {
        wrapper: XxsFixedContainer,
        header: <FormattedMessage id="DEFAULT" />,
        position: "end",
        item: ({ item }) => <PaymentMethodDefaultSwitch item={item} />,
      },
      {
        wrapper: XxsFixedContainer,
        header: "",
        position: "end",
        item: ({ item }) => <PaymentMethodActions item={item} />,
      },
    ],
    [intl],
  );

  return { configuration };
};
