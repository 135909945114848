import {
  AdditionalFiltersOverlay,
  DividerLine,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { KickbackFilters } from "@/common/components/kickback-filters/KickbackFilters";
import { OrderTypesFilter } from "@/common/components/order-types-filter/OrderTypesFilter";
import { CustomFilterContainer } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/list/InvoicesAdditionalFilters";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { MultiSelectUserFilter } from "../../../../../../../../common/components/multiselect-user-filter/MultiSelectUserFilter";
import { useReceipts } from "../../providers/ReceiptsProvider";

enum AdditionalFilter {
  ClosedProjects = "CLOSED_PROJECTS",
  Exported = "EXPORTED",
  Deleted = "DELETED",
  PaidViaInvoice = "PAID_VIA_INVOICE",
  ReceiptDownloaded = "RECEIPT_DOWNLOADED",
}

export const ReceiptsAdditionalFilters = () => {
  const intl = useIntl();
  const { filter, setFilter } = useReceipts();

  const options = useMemo<Option<QueryInvoicesFilter>[]>(() => {
    return [
      {
        id: AdditionalFilter.Exported,
        title: intl.$t({ id: "RECEIPT_EXPORTED" }),
        filter: (value) => ({
          exported: value,
        }),
        value: (currentFilter) => currentFilter?.exported,
      },
      {
        id: AdditionalFilter.PaidViaInvoice,
        title: intl.$t({ id: "RECEIPT_PAID_VIA_INVOICE" }),
        filter: (value) => ({
          prePaid: value,
        }),
        value: (currentFilter) => currentFilter?.prePaid,
      },
      {
        id: AdditionalFilter.ReceiptDownloaded,
        title: intl.$t({ id: "RECEIPT_DOWNLOADED" }),
        filter: (value) => ({
          downloaded: value,
        }),
        value: (currentFilter) => currentFilter?.downloaded,
      },
      {
        id: AdditionalFilter.ClosedProjects,
        title: intl.$t({ id: "RECEIPTS_FROM_CLOSED_PROJECTS" }),
        filter: (value) => ({
          closedProjects: value,
        }),
        value: (currentFilter) => currentFilter?.closedProjects,
      },
      {
        id: AdditionalFilter.Deleted,
        title: intl.$t({ id: "DELETED" }),
        filter: (value) => ({
          archived: value,
        }),
        value: (currentFilter) => currentFilter?.archived,
      },
    ];
  }, [intl]);

  const setFilterHandler = useCallback(
    (values: string[] | undefined) => {
      setFilter({
        ...filter,
        releaseTypeIds: values,
      });
    },
    [filter, setFilter],
  );

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      options={options}
      setFilter={setFilter}
      customBottomFilters={
        <>
          <KickbackFilters filter={filter} setFilter={setFilter} />
          <DividerLine className="mb-4" />
          <CustomFilterContainer className="grid-cols-1">
            <OrderTypesFilter
              selectedOrderTypes={filter?.releaseTypeIds}
              onChange={setFilterHandler}
            />
          </CustomFilterContainer>
          <MultiSelectUserFilter
            setFilter={(createdByIds) => setFilter({ ...filter, createdByIds })}
            values={filter?.createdByIds || null}
            applyHeaderAccent
            titleId="SUBMITTED_BY"
          />
        </>
      }
    />
  );
};
