import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../store/useProjectStore";

export const useSetCurrentProjectId = (id: string | undefined) => {
  const { setCurrentProjectId } = useProjectStore(
    useShallow((state) => ({
      setCurrentProjectId: state.setCurrentProjectId,
    })),
  );

  useEffect(() => {
    if (id) {
      setCurrentProjectId(id);
    }
  }, [id, setCurrentProjectId]);
};
