import { DeleteButton } from "@/common/components/button/DeleteButton";
import {
  EquipmentItemFieldsFragment,
  EquipmentItemsDocument,
  EquipmentItemsQuery,
  useUpdateEquipmentItemsMutation,
} from "@/generated/graphql";
import { FC, useCallback } from "react";

type Props = {
  item: EquipmentItemFieldsFragment;
};

export const EquipmentItemActions: FC<Props> = ({ item }) => {
  const [updateEquipmentItemMutation] = useUpdateEquipmentItemsMutation();

  const deleteEquipmentItem = useCallback(async () => {
    await updateEquipmentItemMutation({
      variables: {
        input: {
          deleted: [item.id],
        },
      },
      update: (cache) => {
        const queryEquipmentItems = cache.readQuery<EquipmentItemsQuery>({
          query: EquipmentItemsDocument,
        });
        cache.writeQuery({
          query: EquipmentItemsDocument,
          data: {
            viewer: {
              ...queryEquipmentItems?.viewer,
              org: {
                ...queryEquipmentItems?.viewer?.org,
                equipmentItems:
                  queryEquipmentItems?.viewer?.org.equipmentItems.filter(
                    (equipmentItem) => equipmentItem.id !== item.id,
                  ),
              },
            },
          },
        });
      },
    });
  }, [item.id, updateEquipmentItemMutation]);

  return <DeleteButton onClick={deleteEquipmentItem} />;
};
