import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { routes } from "@/config/routes";
import { ReleaseMessagesButton } from "@/contractor/pages/home/release/components/ReleaseMessagesButton";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { MessageContextKind } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ReleaseBreadcrumbs,
  useReleaseBreadcrumbsActions,
} from "../hooks/useReleaseBreadcrumbsActions";
import { ReleaseSequenceStepper } from "./ReleaseSequenceStepper";

export const OrderSideBySideBreadcrumbs = ({
  hasReleaseTextKey,
  noReleaseTextKey,
}: {
  hasReleaseTextKey: string;
  noReleaseTextKey: string;
}) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { actions } = useReleaseBreadcrumbsActions();

  const breadcrumbs = useMemo(() => {
    return [
      {
        text: intl.$t({ id: "ORDERS" }),
        link: routes.deliveries,
      },
      {
        text: release
          ? intl.$t({ id: hasReleaseTextKey })
          : intl.$t({ id: noReleaseTextKey }),
      },
    ] as BreadcrumbItem[];
  }, [hasReleaseTextKey, intl, noReleaseTextKey, release]);

  const breadcrumbsActions = useMemo(() => {
    return actions.flatMap((action) => {
      if (action === ReleaseBreadcrumbs.SequenceStepper) {
        return <ReleaseSequenceStepper />;
      } else if (action === ReleaseBreadcrumbs.Messages && release) {
        return (
          <ReleaseMessagesButton
            key="messages"
            release={release}
            text={
              <QuestionBox>
                <Tooltip
                  id="release-messages"
                  element={
                    <MessageButton
                      id={release.id}
                      kind={MessageContextKind.Release}
                    />
                  }
                >
                  <FormattedMessage id="MESSENGER" />
                </Tooltip>
              </QuestionBox>
            }
          />
        );
      }
      return [];
    });
  }, [actions, release]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      actions={breadcrumbsActions}
      appendItems={
        release
          ? [
              <ReleaseStatusChip
                key="status"
                status={release.status}
                type="breadcrumb"
                releaseType={release.type}
              />,
            ]
          : []
      }
    />
  );
};
