import {
  DEFAULT_ITEMS_PER_PAGE,
  LOCAL_STORAGE_KEYS,
  PAGE_OPTIONS,
} from "@/common/const";
import { readValue, setValue } from "@/common/utils/localStorage";
import { PageInfoFieldsFragment } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { PAGINATION } from "../../const";
import { useQueryParams } from "../../hooks/useQueryParams";

export type PaginationArgs = Partial<{
  first: number;
  last: number;
  after: string | null;
  before: string | null;
}>;

type UpdatePageProps = {
  skipQueryUpdate?: boolean;
};

type PaginationContextProps = {
  paginationArgs: PaginationArgs;
  nextPage: () => void;
  previousPage: () => void;
  setPageInfo: (pageInfo: PageInfoFieldsFragment) => void;
  page: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageInfo: PageInfoFieldsFragment | null;
  setPage: ({
    page,
    pagination,
    queryParams,
  }: {
    page: number;
    pagination?: PaginationArgs;
    queryParams?: Record<
      string,
      string | null | boolean | Array<unknown> | undefined | number
    >;
  }) => void;
  firstPage: (options?: UpdatePageProps) => void;
  customPageSize?: number;
};

const PaginationProviderContext = createContext<PaginationContextProps>({
  paginationArgs: { first: DEFAULT_ITEMS_PER_PAGE },
  nextPage: NoFunction,
  previousPage: NoFunction,
  setPageInfo: NoFunction,
  page: 0,
  pageSize: 0,
  setPageSize: NoFunction,
  pageInfo: null,
  setPage: NoFunction,
  firstPage: NoFunction,
  customPageSize: undefined,
});

const ITEMS_PER_PAGE = 20;

type PaginationProviderProps = {
  children: React.ReactNode;
  itemsPerPage?: number;
  useQueryString?: boolean;
  customPageSize?: number;
};

export const PaginationProvider: FC<PaginationProviderProps> = ({
  children,
  itemsPerPage = ITEMS_PER_PAGE,
  useQueryString = true,
  customPageSize,
}) => {
  const { queryParams, setQueryParams } = useQueryParams();
  const [pageSize, setPageSize] = useState(
    queryParams.get(PAGINATION.FIRST)
      ? Number(queryParams.get(PAGINATION.FIRST))
      : queryParams.get(PAGINATION.LAST)
        ? Number(queryParams.get(PAGINATION.LAST))
        : Number(
            customPageSize ||
              readValue(LOCAL_STORAGE_KEYS.PAGE_SIZE_PREFERENCE, itemsPerPage),
          ),
  );
  const [pageInfo, setPageInfo] = useState<PageInfoFieldsFragment | null>(null);
  const [paginationArgs, setPaginationArgs] = useState<PaginationArgs>({
    first: queryParams.get(PAGINATION.FIRST)
      ? Number(queryParams.get(PAGINATION.FIRST))
      : !queryParams.get(PAGINATION.LAST)
        ? pageSize
        : undefined,
    after: queryParams.get(PAGINATION.AFTER) || undefined,
    before: queryParams.get(PAGINATION.BEFORE) || undefined,
    last: queryParams.get(PAGINATION.LAST)
      ? Number(queryParams.get(PAGINATION.LAST))
      : undefined,
  });
  const [page, setPageState] = useState(
    useQueryString && queryParams.get(PAGINATION.PAGE)
      ? Number(queryParams.get(PAGINATION.PAGE))
      : 0,
  );

  const nextPage = () => {
    if (pageInfo?.hasNextPage) {
      const pagination = {
        first: pageSize,
        last: undefined,
        after: pageInfo?.endCursor,
        before: undefined,
      };

      setPaginationArgs(pagination);
      if (useQueryString) {
        setPaginationQueryString(page + 1, pagination);
      }
      setPageState((oldPage) => oldPage + 1);
    }
  };

  const previousPage = () => {
    if (pageInfo?.hasPreviousPage) {
      const pagination = {
        first: undefined,
        last: pageSize,
        after: undefined,
        before: pageInfo?.startCursor,
      };
      setPaginationArgs(pagination);
      setPaginationQueryString(page - 1, pagination);
      setPageState((oldPage) => oldPage - 1);
    }
  };

  const setPage = (
    {
      page,
      pagination,
      queryParams,
    }: {
      page: number;
      pagination?: PaginationArgs;
      queryParams?: Record<
        string,
        string | null | boolean | Array<unknown> | undefined | number
      >;
    },
    options?: UpdatePageProps,
  ) => {
    const newPagination = pagination || {
      first: pageSize,
      after: undefined,
      before: undefined,
    };

    setPageState(page);
    setPaginationArgs(newPagination);
    if (useQueryString && !options?.skipQueryUpdate) {
      setPaginationQueryString(page, newPagination, queryParams);
    }
  };

  const setPaginationQueryString = (
    page: number,
    pagination?: PaginationArgs,
    queryParams?: Record<
      string,
      string | null | boolean | Array<unknown> | undefined | number
    >,
  ) => {
    setQueryParams({
      [PAGINATION.FIRST]: pagination?.first?.toString(),
      [PAGINATION.AFTER]: pagination?.after?.toString(),
      [PAGINATION.BEFORE]: pagination?.before?.toString(),
      [PAGINATION.LAST]: pagination?.last?.toString(),
      [PAGINATION.PAGE]: page.toString(),
      ...queryParams,
    });
  };

  const changePageSize = useCallback((value: number) => {
    if (PAGE_OPTIONS.includes(value)) {
      setValue(LOCAL_STORAGE_KEYS.PAGE_SIZE_PREFERENCE, value);
    }
    setPageSize(value);
  }, []);

  const firstPage = (options?: UpdatePageProps) => {
    setPage(
      {
        page: 0,
        pagination: {
          first: pageSize,
          after: undefined,
          before: undefined,
        },
      },
      options,
    );
  };

  return (
    <PaginationProviderContext.Provider
      value={{
        paginationArgs,
        nextPage,
        previousPage,
        setPageInfo,
        page,
        pageSize,
        setPageSize: changePageSize,
        pageInfo,
        setPage,
        firstPage,
        customPageSize,
      }}
    >
      {children}
    </PaginationProviderContext.Provider>
  );
};

export const usePagination = (): PaginationContextProps =>
  useContext(PaginationProviderContext);
