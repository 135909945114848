import { FC, useCallback } from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { OutlinedButton } from "../../../../../../../../../common/components/button/OutlinedButton";
import { PrimaryButton } from "../../../../../../../../../common/components/button/PrimaryButton";
import { InvoiceFooterState } from "../../../../../../../../../common/components/invoices/invoice-details/types/InvoiceFooterState";
import { INVOICE_READONLY_STATUSES } from "../../../../../../../../../distributor/pages/invoices/providers/DistributorInvoiceProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceUpdateRelease } from "../../../providers/InvoiceUpdateReleaseProvider";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";
import { ButtonContainer } from "../InvoiceFooterButtons";

type Props = {
  createReleaseFormHandleSubmit: UseFormHandleSubmit<
    InvoiceCreateReleaseFormValues,
    undefined
  >;
};

export const EditOrderButtons: FC<Props> = ({
  createReleaseFormHandleSubmit,
}) => {
  const { invoice, footerState, setFooterState } = useInvoiceVerification();
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const { syncUpdateReleaseFromInvoice, saving } = useInvoiceUpdateRelease();

  const handleUpdateReleaseFromInvoice = useCallback(async () => {
    await createReleaseFormHandleSubmit(
      async (values: InvoiceCreateReleaseFormValues) => {
        const sync = await syncUpdateReleaseFromInvoice(values);
        if (!sync) {
          return null;
        }
        if (invoice && !INVOICE_READONLY_STATUSES.includes(invoice.status)) {
          setMatchedOrderViewState(
            MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
          );
        } else {
          setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        }
        setFooterState(InvoiceFooterState.DEFAULT);
      },
    )();
  }, [
    createReleaseFormHandleSubmit,
    syncUpdateReleaseFromInvoice,
    invoice,
    setFooterState,
    setMatchedOrderViewState,
  ]);

  if (footerState !== InvoiceFooterState.EDIT_ORDER) {
    return null;
  }

  return (
    <ButtonContainer>
      <OutlinedButton
        onClick={() => {
          if (invoice && !INVOICE_READONLY_STATUSES.includes(invoice?.status)) {
            setMatchedOrderViewState(
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
            );
          } else {
            setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
          }
          setFooterState(InvoiceFooterState.DEFAULT);
        }}
      >
        <FormattedMessage id="CLOSE" />
      </OutlinedButton>
      <PrimaryButton
        className="py-0"
        onClick={handleUpdateReleaseFromInvoice}
        loading={saving}
      >
        <FormattedMessage id="SAVE_ORDER" />
      </PrimaryButton>
    </ButtonContainer>
  );
};
