import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { OutlinedButton } from "../button/OutlinedButton";

type Props = {
  outlinedButtonClassName?: string;
};

export const PageBackButton: FC<Props> = ({ outlinedButtonClassName }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (window.history.length <= 1) {
    return null;
  }

  return (
    <OutlinedButton onClick={handleClick} className={outlinedButtonClassName}>
      <FormattedMessage id="BACK" />
    </OutlinedButton>
  );
};
