import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateReceiptKickbackInput,
  ResolveReceiptKickbackInput,
  UpdateReceiptKickbackInput,
  useCreateReceiptKickbackMutation,
  useDeleteReceiptKickbackMutation,
  useResolveReceiptKickbackMutation,
  useUpdateReceiptKickbackMutation,
} from "@/generated/graphql";

export const useReceiptKickbacks = () => {
  const [createReceiptKickbackMutation, { loading: creating }] =
    useCreateReceiptKickbackMutation();
  const [updateReceiptKickbackMutation, { loading: updating }] =
    useUpdateReceiptKickbackMutation();
  const [resolveReceiptKickbackMutation, { loading: resolving }] =
    useResolveReceiptKickbackMutation();
  const [deleteReceiptKickbackMutation, { loading: deleting }] =
    useDeleteReceiptKickbackMutation();
  const { setError } = useGlobalError();

  const createReceiptKickback = async (input: CreateReceiptKickbackInput) => {
    try {
      const { data } = await createReceiptKickbackMutation({
        variables: {
          input,
        },
      });
      return data?.createReceiptKickback;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const updateReceiptKickback = async (input: UpdateReceiptKickbackInput) => {
    try {
      const { data } = await updateReceiptKickbackMutation({
        variables: {
          input,
        },
      });
      return !!data?.updateReceiptKickback;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const resolveReceiptKickback = async (input: ResolveReceiptKickbackInput) => {
    try {
      const { data } = await resolveReceiptKickbackMutation({
        variables: {
          input,
        },
      });
      return !!data?.resolveReceiptKickback;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const deleteReceiptKickback = async (id: string) => {
    try {
      const { data } = await deleteReceiptKickbackMutation({
        variables: {
          id,
        },
      });
      return !!data?.deleteReceiptKickback;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    createReceiptKickback,
    updateReceiptKickback,
    resolveReceiptKickback,
    deleteReceiptKickback,
    loading: creating || updating || resolving || deleting,
  };
};
