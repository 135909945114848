import { gql } from "@apollo/client";

export const RELEASE_ISSUE_RESOLUTION_FIELDS = gql`
  fragment ReleaseIssueResolutionFields on ReleaseIssueResolution {
    id
    resolutionType
    comments
    redeliveryReleaseItem {
      id
      alternativeFulfillmentTime
      alternativeFulfillmentRelease {
        id
        sequenceNumber
      }
      deliveryDate
      release {
        id
        time
        sequenceNumber
      }
    }
  }
`;
