import { InstructionInput } from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

export type CreateRfqFromNoteFormValues = {
  clientCustomNumber: string;
  businessLocationId: string;
  projectId: string;
  vendorIds: string[];
  instructions: InstructionInput | undefined;
};

type CreateRfqForm = UseFormReturn<
  CreateRfqFromNoteFormValues,
  unknown,
  CreateRfqFromNoteFormValues
>;

export const CreateRfqFromNoteForm: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const createRfqFromNoteForm: CreateRfqForm =
    useForm<CreateRfqFromNoteFormValues>({
      defaultValues: {
        clientCustomNumber: "",
        businessLocationId: "",
        projectId: "",
        vendorIds: [],
        instructions: {
          text: "",
          assetUrls: [],
        },
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  return <FormProvider {...createRfqFromNoteForm}>{children}</FormProvider>;
};
