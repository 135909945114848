import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useMemo } from "react";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";

export enum ReleaseBreadcrumbs {
  SequenceStepper,
  Messages,
}

export const useReleaseBreadcrumbsActions = () => {
  const { sequenceActive } = useReleaseSequence();
  const { release } = useRelease();

  const actions = useMemo<ReleaseBreadcrumbs[]>(() => {
    const items = [];

    if (release) {
      items.push(ReleaseBreadcrumbs.Messages);
    }

    if (sequenceActive) {
      items.push(ReleaseBreadcrumbs.SequenceStepper);
    }

    return items;
  }, [release, sequenceActive]);

  return {
    actions,
  };
};
