import {
  StaticDatePicker as MuiStaticDatePicker,
  StaticDatePickerProps,
} from "@mui/x-date-pickers/StaticDatePicker";
import { FC } from "react";

type Props = StaticDatePickerProps<Date>;

export const StaticDatePicker: FC<Props> = (props) => {
  return (
    <MuiStaticDatePicker
      displayStaticWrapperAs="desktop"
      {...props}
      sx={{
        "& .MuiButtonBase-root": {
          fontSize: "14px",
          fontWeight: "300",
        },
        "& .MuiPickersDay-root": {
          width: "45px",
          height: "45px",
          margin: "0 auto",
        },
        "& .MuiDayCalendar-weekDayLabel": {
          fontSize: "14px",
          fontWeight: "300",
          width: "45px",
          height: "45px",
          margin: "0 auto",
        },
        "& .MuiDayCalendar-weekContainer": {
          padding: "0 4px",
        },
        "& .MuiDayCalendar-header": {
          padding: "0 4px",
        },
        "& .MuiDateCalendar-root": {
          width: "100%",
          margin: "0",
          height: "390px",
          maxHeight: "390px",
        },
        "& .MuiPickersSlideTransition-root": {
          minHeight: "360px",
        },
        "& .Mui-selected": {
          backgroundColor: "#293B59",
        },
      }}
    />
  );
};
