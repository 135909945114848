import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useMemo } from "react";

export const useInvoiceExtPrices = (item: ExpandedReleaseItem) => {
  const { calcExtPrice } = usePriceCalculation();
  const invoicedItem = useMemo(
    () => item.invoiceItems?.[0],
    [item.invoiceItems],
  );

  const extPrice = useMemo(
    () =>
      isLumpSumItem(item)
        ? (invoicedItem?.quantity ?? 0)
        : calcExtPrice(
            invoicedItem?.quantity ?? 0,
            invoicedItem?.unitPrice ?? item.unitPrice ?? 0,
          ).toString(),
    [item, invoicedItem, calcExtPrice],
  );

  return { extPrice, invoicedItem };
};
