export const getMaterialNameParts = (name: string) => {
  if (!name) {
    return { namePart: "", partNumber: "" };
  }
  const namePart = String(name).split("〈")?.[0].trim();
  const partNumber = String(name)
    .match(/〈(.*)〉/)?.[1]
    .trim();
  return { namePart, partNumber };
};
