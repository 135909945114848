import { useAllItemsCheckboxSelection } from "@/common/components/pagination/hooks/useAllItemsCheckboxSelection";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";

const Container = tw.div`
  flex -ml-2
`;

export const DeliveryAllItemsCheckbox: FC = () => {
  const { sequenceIds, selectedReleases, setSelectedReleases } =
    useReleaseSequence();
  const { deliveries } = useDeliveries();
  const { checked, handleChange } = useAllItemsCheckboxSelection(
    deliveries,
    selectedReleases,
    setSelectedReleases,
  );

  return (
    <Container>
      <SelectionCheckbox
        items={sequenceIds}
        setSelection={handleChange}
        checked={checked}
      />
    </Container>
  );
};
