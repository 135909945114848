import { If } from "@/common/components/if/If";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`
  w-full
`;
const PricePickerStyled = tw(PricePicker)`
${({ readonly }: { readonly: boolean }) =>
  readonly ? "bg-transparent" : "bg-white"}
`;
const OrderedUnitPrice = tw.div`
  text-red-500 w-22 text-center text-2xs
`;
const OrderedUnitPriceTextWrap = tw.div`text-wrap`;
const OrderedUnitPriceText = tw.div`text-red-500 absolute -right-1 -left-1 z-10 px-0.5`;
const Text = tw.span`bg-white`;

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const InvoiceReleaseItemUnitPriceInput: FC<Props> = ({ item }) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const { updateRelease, release } = useReleaseUpdate();
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const { invoice, updateInvoice } = useInvoiceVerification();
  const { calcExtPrice } = usePriceCalculation();
  const { useInvoiceItemPrice, matchedOrderViewState } =
    useInvoiceMatchedOrder();

  const readonly = useMemo(
    () =>
      !(
        (item.invoiceItems || [])?.length > 0 &&
        useInvoiceItemPrice &&
        matchedOrderViewState === MatchedOrderViewState.EDIT_INVOICE_COVERAGES
      ),
    [item.invoiceItems, useInvoiceItemPrice, matchedOrderViewState],
  );

  const updatePrice = useCallback(
    async (price: string | null) => {
      if (item.id) {
        const invoicedItem = item.invoiceItems?.[0];
        if (useInvoiceItemPrice && !!invoicedItem) {
          await updateInvoice(
            {
              id: invoice?.id || "",
              updatedInvoicedReleaseItems: [
                {
                  id: invoicedItem.id,
                  releaseItemId: item.id,
                  unitPrice: price,
                  quantity: invoicedItem?.quantity || undefined,
                },
              ],
              releaseId: invoice?.release?.id || "",
            },
            { bulkUpdate: true },
          );
        }
        if (release) {
          const input: UpdateContractorReleaseInput = {
            releaseId: release?.id,
            version: release?.version,
            updates: [
              {
                releaseItemId: item.id,
                unitPrice: price,
              },
            ],
          };
          await updateRelease(input);
        }
      } else {
        setNewProjectEstimatedItem([
          {
            key: "quantity",
            value: price,
          },
        ]);
      }
    },
    [
      release,
      item.id,
      item.invoiceItems,
      invoice?.id,
      invoice?.release?.id,
      useInvoiceItemPrice,
      updateRelease,
      updateInvoice,
      setNewProjectEstimatedItem,
    ],
  );

  const differentPrices = useMemo(() => {
    const hint = invoice?.releaseItemHints?.find(
      (hint) => hint.releaseItem.id === item.id,
    );
    const extPrice = calcExtPrice(item.quantityDecimal, item.unitPrice);
    const invoiceExtPrice = calcExtPrice(
      hint?.invoiceItem?.quantityDecimal,
      hint?.invoiceItem?.unitPrice,
    );

    return (
      hint &&
      hint?.invoiceItem?.unitPrice &&
      hint?.invoiceItem?.unitPrice != "0" &&
      item?.unitPrice &&
      hint?.invoiceItem?.unitPrice !== item?.unitPrice &&
      extPrice !== invoiceExtPrice
    );
  }, [
    calcExtPrice,
    invoice?.releaseItemHints,
    item.id,
    item.quantityDecimal,
    item.unitPrice,
  ]);

  const invoicedPriceDifferentThanOrdered = useMemo(
    () =>
      item.invoiceItems?.[0]?.unitPrice !== null &&
      item.invoiceItems?.[0]?.unitPrice !== undefined &&
      item.invoiceItems[0].unitPrice !== item.unitPrice,
    [item.invoiceItems, item.unitPrice],
  );

  if (useInvoiceItemPrice) {
    return (
      <Container>
        <PricePickerStyled
          value={item.invoiceItems?.[0]?.unitPrice}
          readonly={readonly}
          onBlur={updatePrice}
          className="text-center text-sm"
          hideErrorIcon
          helperText={
            invoicedPriceDifferentThanOrdered ? (
              <OrderedUnitPriceTextWrap>
                <OrderedUnitPriceText>
                  <FormattedMessage
                    id="ORDERED_UNIT_PRICE"
                    values={{ unitPrice: formatCurrency(item.unitPrice) }}
                    tagName={Text}
                  />
                </OrderedUnitPriceText>
              </OrderedUnitPriceTextWrap>
            ) : undefined
          }
        />
        <If
          isTrue={
            matchedOrderViewState !==
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
            invoicedPriceDifferentThanOrdered
          }
        >
          <FormattedMessage
            id="ORDERED_UNIT_PRICE"
            values={{ unitPrice: formatCurrency(item.unitPrice) }}
            tagName={OrderedUnitPrice}
          />
        </If>
      </Container>
    );
  }

  return (
    <Container>
      <If isTrue={!differentPrices}>
        <PricePickerStyled
          value={item.unitPrice}
          readonly={readonly}
          onBlur={updatePrice}
          className="text-center text-sm"
          hideErrorIcon
        />
      </If>
      <If isTrue={differentPrices}>
        <Tooltip
          id="unit-price"
          element={
            <PricePickerStyled
              value={item.unitPrice}
              readonly={readonly}
              onBlur={updatePrice}
              className="text-center text-sm text-red-500"
              hideErrorIcon
            />
          }
        >
          <FormattedMessage id="UNIT_PRICE_DOES_NOT_MATCH" />
        </Tooltip>
      </If>
    </Container>
  );
};
