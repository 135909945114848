import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { ExtraOption } from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useMemo } from "react";
import { useRelease } from "../../../providers/ReleaseProvider";

export const useReleaseItemsDecorator = () => {
  const { buyout } = useContractorBuyout();
  const { formatCostCode } = useCostCodes();
  const { materials } = useMaterials();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { getFormattedMaterialName } = useTableHelpers();
  const { hasPhaseCodes } = useOrgSettings();
  const { release } = useRelease();

  return useMemo(() => {
    const lumpSumItems =
      release?.items
        .filter((i) => i.uom && isLumpSumUomText(i.uom.pluralDescription))
        .map((lumpSumItem) => {
          const material = materials.find(
            (m) => m.id === lumpSumItem.projectItem?.material.id,
          );

          return {
            name: lumpSumItem.name
              ? lumpSumItem.name
              : material
                ? getFormattedMaterialName(material)
                : "",
            value: lumpSumItem.id,
          } as ExtraOption;
        }) || [];
    const buyoutItems =
      buyout?.items
        .filter((b) =>
          materials.some(
            (i) => i.material.name === b.projectItem.material.material.name,
          ),
        )
        .map((buyoutItem) => {
          const material = materials.find(
            (m) =>
              m.material.name === buyoutItem.projectItem.material.material.name,
          );

          const buyoutItemContent = document.createElement("span");
          buyoutItemContent.className =
            "text-gray-600 font-light text-xs select-none mr-10";
          buyoutItemContent.innerHTML = `⟮${hasPhaseCodes ? buyoutItem?.tags[0]?.name : buyoutItem?.costCode?.description || unassignedCostCode.description}, ${buyoutItem?.quantityDecimal} ${material?.defaultEstimateUom.pluralDescription}⟯`;
          const bubble = document.createElement("span");
          bubble.className = "absolute top-1 right-1 text-green-800";
          bubble.innerHTML = "●";
          buyoutItemContent.appendChild(bubble);

          return {
            name: material ? getFormattedMaterialName(material) : "",
            content: buyoutItemContent,
            value: buyoutItem.id,
            prefilling: [
              [COLUMN_TYPE.Quantity, buyoutItem?.quantityDecimal],
              ...(hasPhaseCodes
                ? [[COLUMN_TYPE.PhaseCode, buyoutItem?.tags[0]?.name]]
                : [
                    [
                      COLUMN_TYPE.CostCode,
                      formatCostCode(buyoutItem?.costCode),
                    ],
                  ]),
              [COLUMN_TYPE.UOM, material?.defaultEstimateUom.pluralDescription],
              [COLUMN_TYPE.Manufacturer, buyoutItem?.manufacturer?.name],
              [COLUMN_TYPE.PrefilledPrice, buyoutItem?.unitPrice],
            ],
          } as ExtraOption;
        }) || [];

    return lumpSumItems.concat(buyoutItems);
  }, [
    release?.items,
    buyout?.items,
    materials,
    unassignedCostCode.description,
    getFormattedMaterialName,
    formatCostCode,
    hasPhaseCodes,
  ]);
};
