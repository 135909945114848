import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { SCREEN_BREAKPOINTS } from "@/common/const";
import { useWindowSize } from "@/common/hooks/useWindowResize";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  DistributorReleaseFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  DistributorReleaseErrorType,
  useDistributorRelease,
} from "../providers/DistributorReleaseProvider";

enum PriceType {
  Reserved,
  PreviousOrder,
  NotFromBuyout,
  Prenegotiated,
}

const Container = tw.div`relative`;
const InfoContainer = tw.div`flex items-center
${(props: { $isReserved: boolean }) =>
  props.$isReserved && "bg-green-800 rounded-full"}
`;
const InfoIcon = tw(InfoOutlined)`text-lg text-white
${(props: { $isReserved: boolean }) =>
  props.$isReserved ? "text-white" : "text-blue-500 bg-white"}`;
const InfoLabel = tw.div`right-3 -top-2 absolute`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly: boolean;
  index?: number;
  release: DistributorReleaseFieldsFragment | null | undefined;
  label?: string | React.JSX.Element;
  extPrice?: boolean;
};

export const DistributorReleasePriceInput: FC<Props> = ({
  item,
  readonly,
  index,
  release,
  label,
  extPrice = false,
}) => {
  const { updateVendorReleaseItem, inputErrors, updatedItem } =
    useDistributorRelease();
  const intl = useIntl();
  const { calcExtPrice } = usePriceCalculation();
  const { formatCurrency } = useFormatNumberToCurrency();
  const windowSize = useWindowSize();
  const hideLabel = windowSize.width > SCREEN_BREAKPOINTS.xs;

  const unitPrice = useMemo(() => {
    const lumpSumItem = isLumpSumItem(item);
    if (extPrice && !lumpSumItem) {
      const unitPrice = updatedItem(item)?.unitPrice ?? item.unitPrice;
      if (!unitPrice) {
        return undefined;
      }
      return calcExtPrice(
        item.quantityDecimal,
        updatedItem(item)?.unitPrice ?? item.unitPrice ?? 0,
      ).toString();
    }
    return lumpSumItem
      ? item.quantityDecimal
      : (updatedItem(item)?.unitPrice ?? item.unitPrice);
  }, [item, extPrice, updatedItem, calcExtPrice]);

  const savePrice = useCallback(
    async (value: string | null) => {
      if (isLumpSumItem(item)) {
        await updateVendorReleaseItem({
          unitPrice: "1",
          quantityDecimal: value || "0",
          releaseItemId: item.id,
        });
      } else {
        await updateVendorReleaseItem({
          unitPrice: value || "0",
          releaseItemId: item.id,
        });
      }
    },
    [updateVendorReleaseItem, item],
  );

  const error = useMemo(
    () =>
      !!(
        inputErrors.includes(DistributorReleaseErrorType.PRICE) &&
        !item.unitPrice
      ),
    [inputErrors, item.unitPrice],
  );

  const priceType = useMemo(() => {
    if (
      item.buyoutItem?.unitPrice &&
      item.projectItem?.estimateUom.id === item.uom?.id
    ) {
      return PriceType.Reserved;
    }
    if (item.pricePrefilled) {
      return PriceType.PreviousOrder;
    }
    if (item.pricePrenegotiated) {
      return PriceType.Prenegotiated;
    }

    return PriceType.NotFromBuyout;
  }, [
    item.buyoutItem?.unitPrice,
    item.pricePrefilled,
    item.pricePrenegotiated,
    item.projectItem?.estimateUom.id,
    item.uom?.id,
  ]);

  const infoLabel = useMemo(() => {
    if (
      checkReleaseStatus(release, [
        ReleaseStatus.Received,
        ReleaseStatus.PartiallyReceived,
        ReleaseStatus.Canceled,
      ])
    ) {
      return;
    }
    const unitPrice = Number(item.buyoutItem?.unitPrice || 0);
    if (release?.buyout) {
      switch (priceType) {
        case PriceType.Reserved:
          return intl.$t(
            { id: "DISTRIBUTOR_RELEASE_ITEM_RESERVED_INFO" },
            { price: formatCurrency(unitPrice, { maximumFractionDigits: 3 }) },
          );
        case PriceType.PreviousOrder:
          return intl.$t(
            { id: "DISTRIBUTOR_RELEASE_ITEM_PRICE_FROM_PREVIOUS_ORDER" },
            { price: formatCurrency(unitPrice, { maximumFractionDigits: 3 }) },
          );
        case PriceType.NotFromBuyout:
          return intl.$t({ id: "DISTRIBUTOR_RELEASE_ITEM_NOT_PART_OF_BUYOUT" });
        default:
          return "";
      }
    }

    if (priceType === PriceType.PreviousOrder) {
      return intl.$t(
        { id: "DISTRIBUTOR_RELEASE_ITEM_PRICE_FROM_PREVIOUS_ORDER" },
        { price: formatCurrency(item.unitPrice, { maximumFractionDigits: 3 }) },
      );
    }
  }, [
    formatCurrency,
    intl,
    item.buyoutItem?.unitPrice,
    item.unitPrice,
    priceType,
    release,
  ]);

  if (!(updatedItem(item)?.isIncluded ?? item.isIncluded)) {
    return null;
  }

  return (
    <NotNullableRenderer value={unitPrice || !extPrice || isLumpSumItem(item)}>
      <Container>
        <PricePicker
          label={!hideLabel ? label : undefined}
          value={unitPrice}
          index={index}
          onBlur={async (unitPrice) => {
            const mapValue = isNaN(Number(unitPrice)) ? "0" : unitPrice;
            if (mapValue === item.unitPrice) {
              return;
            }
            await savePrice(unitPrice);
          }}
          readonly={readonly}
          error={error}
        />
        <InfoLabel>
          <Tooltip
            element={
              <If isTrue={infoLabel && !extPrice}>
                <InfoContainer $isReserved={priceType === PriceType.Reserved}>
                  <InfoIcon $isReserved={priceType === PriceType.Reserved} />
                </InfoContainer>
              </If>
            }
            id={`uom-description-${index}`}
          >
            {infoLabel}
          </Tooltip>
        </InfoLabel>
      </Container>
    </NotNullableRenderer>
  );
};
