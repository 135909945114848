import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import usePreventUnsavedChanges from "@/common/hooks/usePreventUnsavedChanges";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { INVOICE_READONLY_STATUSES } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, FormProvider, useForm, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceKickbackForm } from "../../../../../../../../common/components/invoices/invoice-issue/kickback/InvoiceKickbackForm";
import { useRelease } from "../../../../../release/providers/ReleaseProvider";
import { useInvoiceValidation } from "../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoicePermissions } from "../../hooks/useInvoicePermissions";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../matched-order/components/InvoiceVerificationForm";
import { InvoiceFooterExpanded } from "./expanable/InvoiceFooterExpanded";
import { InvoiceFooterBreadcrumbs } from "./InvoiceFooterBreadcrumbs";
import { InvoiceFooterButtons } from "./InvoiceFooterButtons";
import { InvoiceFooterTotals } from "./InvoiceFooterTotals";

export interface InvoiceKickbackFormValues {
  description: string;
  assigneeIds: string[];
  assetUrls: string[];
  issue: string;
}

const kickbackFormValues: InvoiceKickbackFormValues = {
  description: "",
  assigneeIds: [],
  assetUrls: [],
  issue: "",
};

const InvoiceFooterContainer = tw.div`flex`;

export const InvoiceFooter: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const { invoice, footerState, setFooterState, submitUpdates, hasChanges } =
    useInvoiceVerification();
  const { matchedOrderViewState, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const { setSuccessAlert } = useSnackbar();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { fetchInvoicePermissions, permissions } = useInvoicePermissions();

  const createReleaseForm = useFormContext<InvoiceCreateReleaseFormValues>();
  const { getValues } = createReleaseForm;

  const kickbackForm = useForm({
    defaultValues: kickbackFormValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (invoice?.release?.project?.id) {
      fetchInvoicePermissions(
        invoice?.id,
        invoice?.release?.project?.id ?? getValues().projectId,
      );
    }
  }, [
    fetchInvoicePermissions,
    getValues,
    invoice?.id,
    invoice?.release?.project?.id,
  ]);

  const isApprovedOrPaid = useMemo(() => {
    if (!invoice) {
      return false;
    }
    return INVOICE_READONLY_STATUSES.includes(invoice.status);
  }, [invoice]);

  const displayExtendedFooterContent = useMemo(
    () =>
      !!matchedOrderViewState &&
      ![
        MatchedOrderViewState.CREATE_ORDER,
        MatchedOrderViewState.EDIT_ORDER,
      ].includes(matchedOrderViewState) &&
      !!invoice?.release &&
      !release?.poLink,
    [matchedOrderViewState, invoice?.release, release?.poLink],
  );

  const saveChanges = useCallback(async () => {
    if (await submitUpdates({ refetchQueries: !!invoice?.release?.id })) {
      setSuccessAlert(intl.$t({ id: "CHANGES_SAVED_SUCCESSFULLY" }));
      refetchInvoiceValidation();
      if (isApprovedOrPaid) {
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        setFooterState(InvoiceFooterState.DEFAULT);
      }
    }
  }, [
    submitUpdates,
    invoice?.release?.id,
    setSuccessAlert,
    intl,
    isApprovedOrPaid,
    setMatchedOrderViewState,
    setFooterState,
    refetchInvoiceValidation,
  ]);

  usePreventUnsavedChanges({
    hasChanges,
    onSave: async () => {
      await saveChanges();
      return true;
    },
  });

  // TODO remove archivedAt when we add unarchive feature - right now we hide the footer entirely if archived
  if (invoice?.archivedAt || (invoice?.hasRelease && !invoice.release)) {
    return null;
  }

  return (
    <FormProvider {...kickbackForm}>
      <Form>
        <FloatingFooter
          expandedChildren={
            <InvoiceFooterExpanded
              form={createReleaseForm}
              displayExtended={displayExtendedFooterContent}
            />
          }
        >
          <If isTrue={footerState === InvoiceFooterState.KICKBACK}>
            <InvoiceKickbackForm
              onClose={() => setFooterState(InvoiceFooterState.DEFAULT)}
              invoiceId={invoice?.id}
              releaseCreatedBy={invoice?.release?.createdBy?.id}
              hideDetails
            />
          </If>
          <InvoiceFooterContainer>
            <InvoiceFooterBreadcrumbs />
            <InvoiceFooterTotals
              displayExtended={displayExtendedFooterContent}
            />
            <InvoiceFooterButtons
              isApprovedOrPaid={isApprovedOrPaid}
              createReleaseForm={createReleaseForm}
              kickbackForm={kickbackForm}
              saveChanges={saveChanges}
              fetchInvoicePermissions={fetchInvoicePermissions}
              permissions={permissions}
            />
          </InvoiceFooterContainer>
        </FloatingFooter>
      </Form>
    </FormProvider>
  );
};
