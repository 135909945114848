import { InputBaseComponentProps } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { If } from "../if/If";
import { Select } from "../select/components/single/Select";
import { useOrderTypeOptions } from "./hooks/useOrderTypeOptions";

type Props = {
  orderTypeId: string | null | undefined;
  setOrderTypeId?: (orderTypeId: string | null) => void;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps | undefined;
  disableClearable?: boolean;
};

export const OrderTypePicker: FC<Props> = ({
  orderTypeId,
  setOrderTypeId,
  disabled,
  inputProps,
  disableClearable,
}) => {
  const intl = useIntl();
  const { orderTypes, loading, includeOrderTypes } = useOrderTypeOptions();

  return (
    <If isTrue={includeOrderTypes}>
      <Select
        className="w-full"
        testId="form-order-type-selector"
        label={intl.$t({ id: "ORDER_TYPE" })}
        options={orderTypes}
        inputProps={inputProps}
        value={orderTypeId}
        onChange={setOrderTypeId}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        loading={loading}
        disabled={disabled}
        disableClearable={disableClearable}
      />
    </If>
  );
};
