import * as Sentry from "@sentry/react";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import { registerAllModules } from "handsontable/registry";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_URL,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.breadcrumbsIntegration({
      dom: {
        serializeAttribute: ["data-sentry"],
      },
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,

  enabled: process.env.NODE_ENV !== "development",
  // We need to support long URLs and not truncate them, default value 250 is too short for distributor URLs with signature
  maxValueLength: 1000,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeBreadcrumb: excludeGraphQLFetch,
});

registerAllModules();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
