import { ReleaseSelectorFieldsFragment } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useExpandableFooterStore } from "../../../../../../../common/components/footer/stores/useExpandableFooterStore";

export enum MatchedOrderViewState {
  DEFAULT = "DEFAULT",
  MATCH_ORDER = "MATCH_ORDER",
  CREATE_ORDER = "CREATE_ORDER",
  EDIT_INVOICE_COVERAGES = "EDIT_INVOICE_COVERAGES",
  EDIT_ORDER = "EDIT_ORDER",
  FULFILLED = "FULFILLED",
  IMPORT_ORDER = "IMPORT_ORDER",
}

type ProviderContextType = {
  matchedOrderViewState: MatchedOrderViewState | null;
  setMatchedOrderViewState: (status: MatchedOrderViewState | null) => void;
  selectedRelease: ReleaseSelectorFieldsFragment | null;
  setSelectedRelease: (release: ReleaseSelectorFieldsFragment | null) => void;
  preselectedPoNumber: string;
  setPreselectedPoNumber: (poNumber: string) => void;
  addMissingInvoiceItems: boolean;
  setAddMissingInvoiceItems: (add: boolean) => void;
  autoMatching: boolean;
  setAutoMatching: (autoMatching: boolean) => void;
  useInvoiceItemPrice: boolean;
  setUseInvoiceItemPrice: (specify: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  matchedOrderViewState: null,
  setMatchedOrderViewState: NoFunction,
  selectedRelease: null,
  setSelectedRelease: NoFunction,
  preselectedPoNumber: "",
  setPreselectedPoNumber: NoFunction,
  addMissingInvoiceItems: false,
  setAddMissingInvoiceItems: NoFunction,
  autoMatching: false,
  setAutoMatching: NoFunction,
  useInvoiceItemPrice: false,
  setUseInvoiceItemPrice: NoFunction,
});

export const InvoiceMatchedOrderProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [matchedOrderViewState, setMatchedOrderViewState] =
    useState<MatchedOrderViewState | null>(null);
  const [selectedRelease, setSelectedRelease] =
    useState<ReleaseSelectorFieldsFragment | null>(null);
  const [preselectedPoNumber, setPreselectedPoNumber] = useState("");
  const [addMissingInvoiceItems, setAddMissingInvoiceItems] = useState(false);
  const [autoMatching, setAutoMatching] = useState(false);
  const [useInvoiceItemPrice, setUseInvoiceItemPrice] = useState(false);

  const { expanded, setExpanded } = useExpandableFooterStore();

  useEffect(() => {
    if (!expanded) {
      setExpanded(matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchedOrderViewState]);

  return (
    <ProviderContext.Provider
      value={{
        matchedOrderViewState,
        setMatchedOrderViewState,
        selectedRelease,
        setSelectedRelease,
        preselectedPoNumber,
        setPreselectedPoNumber,
        addMissingInvoiceItems,
        setAddMissingInvoiceItems,
        autoMatching,
        setAutoMatching,
        useInvoiceItemPrice,
        setUseInvoiceItemPrice,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceMatchedOrder = (): ProviderContextType =>
  useContext(ProviderContext);
