import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { WizardModalFooterButton } from "@/common/components/wizard-modal/components/WizardModalFooterButton";
import { useAgaveExternalVendorsWithPagination } from "@/contractor/pages/home/project/hooks/useAgaveExternalVendorsWithPagination";
import {
  ExternalVendorFieldsFragment,
  SourceSystem,
  useExternalVendorQuery,
  useRefreshExternalVendorsMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { ExternalVendorsTable } from "../components/vendor-mapping/steps/ExternalVendorsTable";
import { useAgaveExternalVendorProvider } from "./AgaveExternalVendorProvider";

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null;
  setSourceSystem: (system: SourceSystem | null) => void;
  modalOpened: boolean;
  openModal: ({
    vendor,
    sourceSystem,
    selectedExternalVendor,
    isConnected,
  }: {
    vendor: string | undefined;
    sourceSystem: SourceSystem;
    selectedExternalVendor: string | undefined;
    isConnected: boolean;
  }) => void;
  closeModal: () => void;

  selectedExternalVendor: string | null;
  setSelectedExternalVendor: (id: string | null) => void;

  selectedExternalVendorData: ExternalVendorFieldsFragment | null;
  setSelectedExternalVendorData: (
    vendorData: ExternalVendorFieldsFragment | null,
  ) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  setSourceSystem: NoFunction,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  selectedExternalVendor: null,
  setSelectedExternalVendor: NoFunction,

  selectedExternalVendorData: null,
  setSelectedExternalVendorData: NoFunction,
});

export const ExternalVendorMappingProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();

  const { connectVendor, disconnectVendor, connecting } =
    useAgaveExternalVendorProvider();

  const [internalVendor, setInternalVendor] = useState<string | null>(null);
  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);

  const [selectedExternalVendor, setSelectedExternalVendor] = useState<
    string | null
  >(null);
  const [selectedExternalVendorData, setSelectedExternalVendorData] =
    useState<ExternalVendorFieldsFragment | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const { refetchExternalVendors } =
    useAgaveExternalVendorsWithPagination(sourceSystem);

  const { data, loading: loadingExternalVendor } = useExternalVendorQuery({
    variables: {
      input: {
        externalId: selectedExternalVendor ?? "",
        sourceSystem: sourceSystem ?? SourceSystem.Foundation,
      },
    },
    skip: !sourceSystem || !selectedExternalVendor,
  });

  const [
    refreshExternalVendorsMutation,
    { loading: refreshingExternalVendors },
  ] = useRefreshExternalVendorsMutation();

  const openModal = useCallback(
    ({
      sourceSystem,
      selectedExternalVendor,
      vendor,
      isConnected,
    }: {
      sourceSystem: SourceSystem;
      selectedExternalVendor: string | undefined;
      vendor: string | undefined;
      isConnected: boolean;
    }) => {
      setSourceSystem(sourceSystem);
      setIsConnected(isConnected);
      if (vendor) {
        setInternalVendor(vendor);
      }
      if (selectedExternalVendor) {
        setSelectedExternalVendor(selectedExternalVendor);
      }
      setModalOpened(true);
    },
    [],
  );

  const closeModal = useCallback(() => {
    setModalOpened(false);
    setSelectedExternalVendor(null);
    setIsConnected(false);
  }, []);

  const handleRefreshExternalVendors = useCallback(async () => {
    if (sourceSystem) {
      const result = await refreshExternalVendorsMutation({
        variables: { sourceSystem },
      });
      if (
        result.data?.refreshExternalVendors &&
        result.data?.refreshExternalVendors > 0
      ) {
        refetchExternalVendors();
      }
    }
  }, [refetchExternalVendors, refreshExternalVendorsMutation, sourceSystem]);

  const handleConnectVendor = useCallback(async () => {
    if (!!internalVendor && !!selectedExternalVendor && !!sourceSystem) {
      const success = await connectVendor({
        externalId: selectedExternalVendor,
        nodeId: internalVendor,
        sourceSystem,
      });
      if (success) {
        closeModal();
      }
    } else if (!internalVendor) {
      setSelectedExternalVendorData(data?.externalVendor ?? null);
      closeModal();
    }
  }, [
    internalVendor,
    selectedExternalVendor,
    sourceSystem,
    data?.externalVendor,
    setSelectedExternalVendorData,
    connectVendor,
    closeModal,
  ]);

  const handleDisconnectVendor = useCallback(async () => {
    if (!!internalVendor && !!sourceSystem) {
      const success = await disconnectVendor({
        nodeId: internalVendor,
        sourceSystem,
      });
      if (success) {
        setSelectedExternalVendorData(null);
        setIsConnected(false);
        closeModal();
      }
    }
  }, [internalVendor, sourceSystem, disconnectVendor, closeModal]);

  const pages: WizardModalPage[] = useMemo(() => {
    const footerButtonsConfig: WizardModalFooterButton[] = [];
    if (isConnected) {
      footerButtonsConfig.push({
        type: "link",
        onClick: handleDisconnectVendor,
        text: intl.$t({ id: "DISCONNECT_VENDOR" }),
        className: "mr-4 justify-center",
      });
    }
    footerButtonsConfig.push(
      ...([
        {
          type: "outlined",
          onClick: closeModal,
          text: intl.$t({ id: "CLOSE" }),
          className: "flex-1 mr-2",
        },
        {
          type: "outlined",
          onClick: handleRefreshExternalVendors,
          loading: refreshingExternalVendors,
          text: intl.$t(
            { id: "SYNC_FROM_SOURCE_SYSTEM" },
            { sourceSystem: intl.$t({ id: `SOURCE_SYSTEM_${sourceSystem}` }) },
          ),
          className: "flex-1 mr-2",
        },
        {
          type: "primary",
          onClick: handleConnectVendor,
          text: intl.$t({ id: "CONNECT" }),
          className: "flex-1",
          loading: connecting,
          disabled: (!data && loadingExternalVendor) || !selectedExternalVendor,
        },
      ] as WizardModalFooterButton[]),
    );
    return [
      {
        title: null,
        pages: [
          {
            component: <ExternalVendorsTable />,
            footerButtonsConfig,
          },
        ],
      },
    ];
  }, [
    isConnected,
    closeModal,
    intl,
    handleRefreshExternalVendors,
    refreshingExternalVendors,
    sourceSystem,
    handleConnectVendor,
    connecting,
    data,
    loadingExternalVendor,
    selectedExternalVendor,
    handleDisconnectVendor,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        setSourceSystem,
        pages,
        openModal,
        closeModal,

        selectedExternalVendor,
        setSelectedExternalVendor,

        selectedExternalVendorData,
        setSelectedExternalVendorData,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalVendorMapping = () => useContext(ProviderContext);
