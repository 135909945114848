import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { useTaxType } from "@/common/components/sales-tax-input/hooks/useTaxType";
import { DateView } from "@/common/utils/dates/DateView";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InvoiceStatus, TaxType } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { BsExclamation } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InfoTooltip } from "../../../../../../../../../common/components/info-tooltip/InfoTooltip";
import { Tooltip } from "../../../../../../../../../common/components/tooltip/Tooltip";
import { useSelectedInvoiceItems } from "../../../hooks/useSelectedInvoiceItems";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import {
  Column,
  ContentColumn,
  PanelDescription,
  PanelTitle,
  QuarterPanel,
} from "./InvoiceExpandable.styled";

const Row = tw.div`flex flex-row h-10 pr-6`;
const RowTitle = tw.div`flex w-40 items-center justify-end font-medium text-sm`;
const RowIcon = tw.div`flex w-20 items-center justify-start pl-4`;
const RowValue = tw.div`flex w-40 items-center h-8 justify-end pr-8 text-sm`;
const RowBorder = tw.div`flex flex-1 flex-row border-1 border-t border-dashed border-black justify-between`;
const DiscountInfo = tw.span``;
const RedText = tw.div`text-red-500`;
const ExclamationCircleRed = tw(
  BsExclamation,
)`bg-red-500 fill-red-500 text-xl rounded-full m-0 fill-white`;
const ExclamationCircleOrange = tw(InfoOutlined)`text-2xl text-orange-500`;

export const InvoiceItemsPanel: FC = () => {
  const intl = useIntl();
  const { hasAdditionalCharges } = useOrgSettings();
  const { invoice } = useInvoiceVerification();
  const {
    selectedInvoiceItems,
    selectedInvoiceItemsCharges,
    selectedInvoiceItemsSalesTax,
    selectedInvoiceItemsSubtotal,
    selectedInvoiceItemsTotal,
  } = useSelectedInvoiceItems();

  const { useTaxKey } = useTaxType(
    invoice?.release?.type?.id,
    undefined,
    invoice?.release?.taxType,
  );

  const useTax = useMemo(
    () => invoice?.release?.taxType === TaxType.Use,
    [invoice],
  );

  const discountDate = useMemo(
    () => (invoice?.discountDate ? new Date(invoice?.discountDate) : undefined),
    [invoice?.discountDate],
  );

  const containerClassName = useMemo(() => {
    let className = "border-2 ";
    if (invoice?.status === InvoiceStatus.Approved) {
      className += "border-green-800 bg-green-150";
    } else if (selectedInvoiceItems.length) {
      className += "border-blue-300 bg-blue-100";
    } else {
      className +=
        "border-gray-400 bg-white border-dashed border-b-0 border-t-0 border-r-0 border-l-[1px]";
    }

    return className;
  }, [selectedInvoiceItems.length, invoice?.status]);

  const totalsAreNotEqual = useMemo(
    () =>
      !new Decimal(selectedInvoiceItemsTotal)
        .toDP(2)
        .equals(new Decimal(invoice?.total || 0).toDP(2)),
    [selectedInvoiceItemsTotal, invoice?.total],
  );

  const subTotalsAreNotEqual = useMemo(
    () =>
      !new Decimal(selectedInvoiceItemsSubtotal)
        .toDP(2)
        .equals(new Decimal(invoice?.subtotal || 0).toDP(2)),
    [selectedInvoiceItemsSubtotal, invoice?.subtotal],
  );

  const invoicedSalesTaxGreaterThanOrderSalesTax = useMemo(
    () =>
      new Decimal(invoice?.taxAmount || 0)
        .toDP(2)
        .greaterThan(new Decimal(invoice?.release?.taxAmount || 0).toDP(2)),
    [invoice?.taxAmount, invoice?.release?.taxAmount],
  );

  const invoicedAdditionalChargesExceeds = useMemo(
    () =>
      new Decimal(invoice?.chargesAmount || 0).greaterThan(
        new Decimal(
          invoice?.release?.additionalCharges.reduce(
            (acc, charge) => acc.plus(charge.amount),
            new Decimal(0),
          ) || 0,
        ),
      ),
    [invoice?.chargesAmount, invoice?.release?.additionalCharges],
  );

  return (
    <QuarterPanel data-testid="invoiceFooter" className={containerClassName}>
      <Column>
        <PanelTitle>
          <FormattedMessage id="INVOICED_ITEMS" />
          <FormattedMessage
            id="INVOICED_ITEMS_DESCRIPTION"
            tagName={PanelDescription}
          />
        </PanelTitle>
      </Column>
      <ContentColumn>
        <Row>
          <FormattedMessage id="SUBTOTAL" tagName={RowTitle} />
          <RowIcon>
            <InfoTooltip
              message={intl.formatMessage({ id: "INVOICED_SUBTOTAL_TOOLTIP" })}
            />
            <If isTrue={subTotalsAreNotEqual}>
              <Tooltip
                id="INVOICED_TOTAL_WARNING_TOOLTIP"
                element={<ExclamationCircleRed />}
              >
                <FormattedMessage id="INVOICED_TOTAL_WARNING_TOOLTIP" />
              </Tooltip>
            </If>
          </RowIcon>
          <RowValue data-testid="invoiceFooter-subtotal">
            <If isTrue={selectedInvoiceItems.length}>
              <Price
                price={selectedInvoiceItemsSubtotal ?? "0"}
                maximumFractionDigits={2}
              />
            </If>
            <If isTrue={!selectedInvoiceItems.length}>
              <FormattedMessage id="SELECT_ITEMS" tagName={RedText} />
            </If>
          </RowValue>
        </Row>
        <If isTrue={hasAdditionalCharges && selectedInvoiceItemsCharges}>
          <Row>
            <FormattedMessage id="ADDITIONAL_CHARGES" tagName={RowTitle} />
            <RowIcon>
              <If isTrue={!invoicedAdditionalChargesExceeds}>
                <InfoTooltip
                  message={intl.formatMessage({
                    id: "INVOICED_ADDITIONAL_CHARGES_TOOLTIP",
                  })}
                />
              </If>
              <If isTrue={invoicedAdditionalChargesExceeds}>
                <Tooltip
                  id="INVOICED_ADDITIONAL_CHARGES_WARNING_TOOLTIP"
                  element={<ExclamationCircleOrange />}
                >
                  <FormattedMessage id="INVOICED_ADDITIONAL_CHARGES_TOOLTIP" />
                  {". "}
                  <FormattedMessage id="INVOICED_ADDITIONAL_CHARGES_WARNING_TOOLTIP" />
                </Tooltip>
              </If>
            </RowIcon>
            <RowValue data-testid="invoiceFooter-additionalCharges">
              <If isTrue={selectedInvoiceItems.length}>
                <Price
                  price={selectedInvoiceItemsCharges ?? "0"}
                  maximumFractionDigits={2}
                />
              </If>
              <If isTrue={!selectedInvoiceItems.length}>
                <FormattedMessage id="SELECT_ITEMS" tagName={RedText} />
              </If>
            </RowValue>
          </Row>
        </If>
        <If isTrue={!useTax}>
          <Row>
            <FormattedMessage id={useTaxKey} tagName={RowTitle} />
            <RowIcon>
              <If isTrue={invoicedSalesTaxGreaterThanOrderSalesTax}>
                <Tooltip
                  id="INVOICED_SALES_TAX_WARNING_TOOLTIP"
                  element={<ExclamationCircleOrange />}
                >
                  <FormattedMessage id="INVOICED_SALES_TAX_WARNING_TOOLTIP" />
                </Tooltip>
              </If>
            </RowIcon>
            <RowValue data-testid="invoiceFooter-salesTax">
              <If isTrue={selectedInvoiceItems.length}>
                <Price
                  price={selectedInvoiceItemsSalesTax ?? "0"}
                  maximumFractionDigits={2}
                />
              </If>
              <If isTrue={!selectedInvoiceItems.length}>
                <FormattedMessage id="SELECT_ITEMS" tagName={RedText} />
              </If>
            </RowValue>
          </Row>
        </If>
        <Row>
          <RowBorder>
            <RowTitle className="rounded-l-4xl text-lg">
              <FormattedMessage id="TOTAL" />
            </RowTitle>
            <RowIcon>
              <InfoTooltip
                message={intl.formatMessage({
                  id: "INVOICED_TOTAL_TOOLTIP",
                })}
              />
              <If isTrue={totalsAreNotEqual}>
                <Tooltip
                  id="INVOICED_TOTAL_WARNING_TOOLTIP"
                  element={<ExclamationCircleRed />}
                >
                  <FormattedMessage id="INVOICED_TOTAL_WARNING_TOOLTIP" />
                </Tooltip>
              </If>
            </RowIcon>
            <RowValue
              className="h-auto items-end py-2"
              data-testid="invoiceFooter-invoiceTotal"
            >
              <If isTrue={selectedInvoiceItems.length}>
                <Price
                  price={selectedInvoiceItemsTotal}
                  maximumFractionDigits={2}
                  className="text-lg"
                />
                <If isTrue={invoice?.discountedAmount}>
                  <Row className="text-sm text-gray-500">
                    <DiscountInfo>
                      <Price
                        price={invoice?.discountedAmount}
                        maximumFractionDigits={2}
                      />
                      <If isTrue={discountDate}>
                        <DiscountInfo className="px-1">
                          <FormattedMessage id="INVOICE_IF_PAID_BEFORE" />
                        </DiscountInfo>
                        <DateView date={discountDate} />
                      </If>
                    </DiscountInfo>
                  </Row>
                </If>
              </If>
              <If isTrue={!selectedInvoiceItems.length}>
                <FormattedMessage id="SELECT_ITEMS" tagName={RedText} />
              </If>
            </RowValue>
          </RowBorder>
        </Row>
      </ContentColumn>
    </QuarterPanel>
  );
};
