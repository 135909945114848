import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { RfqStatusChip } from "@/common/components/statuses/RfqStatusChip";
import { routes } from "@/config/routes";
import { useRfq } from "@/contractor/pages/home/rfq/hooks/useRfq";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
export const CreateRfqFromNoteBreadcrumbs: FC = () => {
  const intl = useIntl();
  const { rfq } = useRfq();

  const breadcrumbs = useMemo(() => {
    return [
      {
        text: intl.$t({ id: "QUOTES" }),
        link: routes.quotes,
      },
      {
        text: rfq
          ? intl.$t(
              { id: "RFQ_NUMBER_WITH_SYMBOL" },
              { number: rfq.clientIdentifier },
            )
          : intl.$t({ id: "CREATE_RFQ_FROM_NOTE" }),
      },
    ] as BreadcrumbItem[];
  }, [intl, rfq]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={
        rfq
          ? [
              <RfqStatusChip
                key="status"
                status={rfq.status}
                type="breadcrumb"
              />,
            ]
          : []
      }
    />
  );
};
