import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue, setValue } from "@/common/utils/localStorage";
import { useCallback, useEffect, useState } from "react";

const DEFAULT_EXCLUDED_ORGS = [
  "0fd145e5-ace9-46e3-aab0-68f72cd4b8ff",
  "48007d6d-4958-4bb1-8c60-16b030ddbc9d",
  "5e513dd8-2ff5-48c6-9e44-7570f6b22a1f",
  "7d69fb6e-8f8d-42e5-87e4-d144bdbd586d",
  "7620454e-47d4-472b-bd1b-d6a88979d6b0",
  "1a02ba7d-0730-4a01-b9c8-09b75e07e854",
];

const EXCLUDED_ORGS_BEFORE_DATE = [
  {
    id: "65ddf8cc-796d-43ba-a5bc-6b8b34d303e3",
    name: "SVM",
    date: "2024-10-01T00:00:00",
  },
];

export const useExcludedOrgs = () => {
  const [excludedOrgs, setExcludedOrgs] = useState<string[] | null>(null);

  useEffect(() => {
    const localStorageExcludedOrgs = readValue<string[]>(
      LOCAL_STORAGE_KEYS.GMV_REPORT_EXCLUDED_ORGS,
    );
    setExcludedOrgs(
      localStorageExcludedOrgs === null
        ? DEFAULT_EXCLUDED_ORGS
        : ((localStorageExcludedOrgs ?? []) as string[]),
    );
  }, []);

  const getExcludedOrgsByDate = useCallback(
    (excludedOrgs: string[], date: Date | undefined) => {
      let orgsToExclude = [...excludedOrgs];
      EXCLUDED_ORGS_BEFORE_DATE.forEach((org) => {
        if (date && date < new Date(org.date)) {
          orgsToExclude.push(org.id);
        }
      });
      return orgsToExclude;
    },
    [],
  );

  const updateExcludedOrgs = useCallback((orgs: string[]) => {
    setExcludedOrgs(orgs);
    setValue(LOCAL_STORAGE_KEYS.GMV_REPORT_EXCLUDED_ORGS, orgs);
  }, []);

  return {
    getExcludedOrgsByDate,
    excludedOrgs,
    updateExcludedOrgs,
  };
};
