import { DeleteButton } from "@/common/components/button/DeleteButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { SourceSystemFeatureRequirement } from "@/common/components/integration-feature-requirement/SourceSystemFeatureRequirement";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { WizardModalMode } from "@/common/components/wizard-modal/WizardModal";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useEnabledPoInSourceSystem } from "@/common/hooks/useEnabledPoInSourceSystem";
import { threeStateResult } from "@/common/utils/threeStateResult";
import { routes } from "@/config/routes";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import {
  IntegrationType,
  SourceSystem,
  SourceSystemSettingExtendedFieldsFragment,
} from "@/generated/graphql";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useAgaveConnectionProvider } from "../../../providers/AgaveConnectionProvider";
import {
  Actions,
  BaseContent,
  BottomBar,
  Group,
  Integration,
  IntegrationInner,
  IntegrationTypeContainer,
  Logo,
} from "../../Integrations.styles";
import { ItemGroup } from "../../common/ItemGroup";
import { DefaultLinkChunks } from "../../wizard/Wizard.styles";
import { useAgaveHostedWizard } from "../../wizard/agave-wizard/AgaveHostedWizardProvider";
import { ConnectionStatus } from "../components/ConnectionStatus";

const Content = tw(BaseContent)``;
const Image = tw.img`mt-5 ml-5`;

type Props = {
  sourceSystem: SourceSystemSettingExtendedFieldsFragment;
};

export const SourceSystemIntegration: FC<Props> = ({ sourceSystem }) => {
  const intl = useIntl();
  const { disconnect } = useAgaveConnectionProvider();
  const { openWizard } = useAgaveHostedWizard();
  const { connectedAccountingSystem } = useOrgSettingsExtended();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { getSourceSystemConfig } = useSourceSystemConfig();
  const { openDialog } = useDialog();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();

  const handleOpenIntegrationStep = useCallback(
    (step: IntegrationFeature) => {
      openWizard({
        sourceSystem: sourceSystem.system,
        mode: WizardModalMode.SINGLE_PAGE,
        step,
      });
    },
    [openWizard, sourceSystem.system],
  );

  const confirmAndDisconnect = (sourceSystem: SourceSystem) => {
    openDialog({
      cancelButtonText: intl.$t({ id: "NO" }),
      confirmButtonText: intl.$t({ id: "YES" }),
      includeWarningIcon: true,
      title: intl.$t(
        { id: "DISCONNECT_CONFIRMATION" },
        { sourceSystem: intl.$t({ id: `SOURCE_SYSTEM_${sourceSystem}` }) },
      ),
      handleConfirm: async () => {
        await disconnect({ system: sourceSystem });
      },
    });
  };

  const poSettingsValues = useMemo(() => {
    const values = [];

    // TODO: Uncomment when the feature is implemented
    // values.push({
    //   label: intl.$t({ id: "AUTOMATED_SYNCHRONIZATION" }),
    //   value:
    //     typeof sourceSystem.defaultAutoSync === "boolean"
    //       ? intl.$t({
    //           id: sourceSystem.defaultAutoSync ? "ON" : "OFF",
    //         })
    //       : null,
    // });
    if (
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.PostInventoryReceipts,
      ) &&
      isPoEnabledInSourceSystem
    ) {
      values.push({
        label: intl.$t({ id: "POST_INVENTORY_RECEIPTS" }),
        value:
          typeof sourceSystem.postInventoryReceipts === "boolean"
            ? intl.$t({
                id: sourceSystem.postInventoryReceipts ? "YES" : "NO",
              })
            : null,
      });
      if (sourceSystem.postInventoryReceipts) {
        values.push({
          label: intl.$t({ id: "GL_ACCOUNT_NUMBER_FOR_UNINVOICED_RECEIPTS" }),
          value: sourceSystem.inventoryReceiptLedgerAccount?.name ?? null,
        });
      }
    }

    if (hasFeatureInConnectedSourceSystem(IntegrationFeature.AutoPostPOs)) {
      values.push({
        label: intl.$t({ id: "AUTO_POST_POS" }),
        value: threeStateResult(sourceSystem.autoPostPOs, {
          yes: intl.$t({ id: "YES" }),
          no: intl.$t({ id: "NO" }),
          notDefined: "--",
        }),
      });
    }

    return values;
  }, [
    hasFeatureInConnectedSourceSystem,
    isPoEnabledInSourceSystem,
    intl,
    sourceSystem.postInventoryReceipts,
    sourceSystem.inventoryReceiptLedgerAccount?.name,
    sourceSystem.autoPostPOs,
  ]);

  const invoiceSettingsValues = useMemo(() => {
    const values = [];

    values.push({
      label: intl.$t({ id: "AUTOMATED_SYNCHRONIZATION" }),
      value:
        typeof sourceSystem.autoExportApprovedInvoices === "boolean"
          ? intl.$t({
              id: sourceSystem.autoExportApprovedInvoices ? "ON" : "OFF",
            })
          : null,
    });
    if (
      hasFeatureInConnectedSourceSystem(IntegrationFeature.AutoPostInvoices)
    ) {
      values.push({
        label: intl.$t({ id: "AUTO_POST_INVOICES" }),
        value: threeStateResult(sourceSystem.autoPostInvoices, {
          yes: intl.$t({ id: "YES" }),
          no: intl.$t({ id: "NO" }),
          notDefined: "--",
        }),
      });
    }

    return values;
  }, [
    intl,
    sourceSystem.autoExportApprovedInvoices,
    sourceSystem.autoPostInvoices,
    hasFeatureInConnectedSourceSystem,
  ]);

  const connectionActive: boolean = useMemo(() => {
    if (
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.GlobalPhaseCodesStep,
      ) &&
      sourceSystem.wbsTags === true
    ) {
      return true;
    }
    return (
      (!hasFeatureInConnectedSourceSystem(
        IntegrationFeature.GeneralLedgerAccount,
      )
        ? true
        : hasFeatureInConnectedSourceSystem(IntegrationFeature.IncludeCostTypes)
          ? !!sourceSystem.toJobLedgerAccount
          : !!sourceSystem.accountsPayableLedgerAccount) &&
      (!hasFeatureInConnectedSourceSystem(
        IntegrationFeature.IncludeCostTypes,
      ) ||
        !!sourceSystem.materialCostType)
    );
  }, [hasFeatureInConnectedSourceSystem, sourceSystem]);

  const hasFoundationOnPremise = useMemo(
    () => connectedAccountingSystem?.integration === IntegrationType.Foundation,
    [connectedAccountingSystem?.integration],
  );

  const { sourceSystemLogo } = useSourceSystemLogo(sourceSystem.system);

  const activeVariation = useMemo(() => {
    return getSourceSystemConfig(sourceSystem.system).variations?.find(
      (variation) => variation.system === sourceSystem.system,
    );
  }, [getSourceSystemConfig, sourceSystem.system]);

  return (
    <Integration>
      <IntegrationInner>
        <Logo>
          <Image loading="lazy" src={sourceSystemLogo} alt="logo" />
          <If isTrue={sourceSystem.system === SourceSystem.Foundation}>
            <IntegrationTypeContainer>
              <FormattedMessage
                id={
                  hasFoundationOnPremise
                    ? "INTEGRATION_TYPE_HOSTED_JOB_PO_EXPORT"
                    : "INTEGRATION_TYPE_HOSTED"
                }
              />
            </IntegrationTypeContainer>
          </If>
        </Logo>
        <Content>
          <Group className="pr-[5%]">
            <SourceSystemFeatureRequirement
              feature={IntegrationFeature.GlobalPhaseCodesStep}
            >
              <ItemGroup
                values={[
                  {
                    value:
                      sourceSystem.wbsTags === null
                        ? undefined
                        : intl.$t({ id: sourceSystem.wbsTags ? "NO" : "YES" }),
                  },
                ]}
                label={intl.$t({ id: "USE_GLOBAL_PHASE_CODES" })}
                fulfilled={sourceSystem.wbsTags !== null}
                onEdit={() =>
                  handleOpenIntegrationStep(
                    IntegrationFeature.GlobalPhaseCodesStep,
                  )
                }
              />
            </SourceSystemFeatureRequirement>
            <If isTrue={activeVariation && activeVariation.listInfo}>
              {activeVariation?.listInfo}
            </If>
            <If isTrue={poSettingsValues.length}>
              <ItemGroup
                values={poSettingsValues}
                label={intl.$t({ id: "PO_EXPORT_SETTINGS" })}
                fulfilled
                onEdit={() =>
                  handleOpenIntegrationStep(
                    IntegrationFeature.PoExportSettingsStep,
                  )
                }
              />
            </If>
            <SourceSystemFeatureRequirement
              feature={IntegrationFeature.InvoiceWrites}
            >
              <ItemGroup
                values={invoiceSettingsValues}
                label={intl.$t({ id: "INVOICE_EXPORT_SETTINGS" })}
                fulfilled
                onEdit={() =>
                  handleOpenIntegrationStep(IntegrationFeature.InvoiceWrites)
                }
              />
            </SourceSystemFeatureRequirement>
            <If
              isTrue={hasFeatureInConnectedSourceSystem(
                IntegrationFeature.ApLedgerAccountStep,
              )}
            >
              <ItemGroup
                values={[
                  {
                    value: sourceSystem.accountsPayableLedgerAccount
                      ? sourceSystem.accountsPayableLedgerAccount.name
                      : intl.$t({ id: "NOT_SPECIFIED" }),
                  },
                ]}
                label={intl.$t({ id: "ACCOUNTS_PAYABLE_LEDGER_ACCOUNT" })}
                fulfilled={!!sourceSystem.accountsPayableLedgerAccount}
                onEdit={() =>
                  handleOpenIntegrationStep(
                    IntegrationFeature.ApLedgerAccountStep,
                  )
                }
              />
            </If>
          </Group>
          <Group>
            <ItemGroup
              iconColorClassName={
                sourceSystem.progress.releaseTypes.mapped !==
                sourceSystem.progress.releaseTypes.total
                  ? "text-orange-500"
                  : undefined
              }
              label={intl.$t(
                { id: "ORDER_TYPES_COUNT" },
                {
                  count: sourceSystem.progress.releaseTypes.mapped,
                  total: sourceSystem.progress.releaseTypes.total,
                },
              )}
              values={[
                {
                  value: intl.$t(
                    {
                      id: "SETUP_ORDER_TYPES_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <DefaultLinkChunks
                          chunks={chunks}
                          route={routes.adminOrders}
                        />
                      ),
                    },
                  ),
                },
              ]}
              fulfilled={
                sourceSystem.progress.releaseTypes.total ===
                sourceSystem.progress.releaseTypes.mapped
              }
            />
            <SourceSystemFeatureRequirement
              feature={IntegrationFeature.IncludeCostTypes}
            >
              <ItemGroup
                iconColorClassName={
                  sourceSystem.progress.costTypes.mapped !==
                  sourceSystem.progress.costTypes.total
                    ? "text-orange-500"
                    : undefined
                }
                label={intl.$t(
                  { id: "COST_TYPES_COUNT" },
                  {
                    count: sourceSystem.progress.costTypes.mapped,
                    total: sourceSystem.progress.costTypes.total,
                  },
                )}
                values={[
                  {
                    value: intl.$t(
                      {
                        id: "IMPORT_COST_TYPES_LINK",
                      },
                      {
                        sub: (chunks: ReactNode) => (
                          <DefaultLinkChunks
                            chunks={chunks}
                            route={routes.costTypes}
                          />
                        ),
                      },
                    ),
                  },
                ]}
                fulfilled={
                  sourceSystem.progress.costTypes.total ===
                  sourceSystem.progress.costTypes.mapped
                }
              />
            </SourceSystemFeatureRequirement>
            <ItemGroup
              iconColorClassName={
                sourceSystem.progress.costCodes.mapped !==
                sourceSystem.progress.costCodes.total
                  ? "text-orange-500"
                  : undefined
              }
              label={intl.$t(
                { id: "COST_CODES_COUNT" },
                {
                  count: sourceSystem.progress.costCodes.mapped,
                  total: sourceSystem.progress.costCodes.total,
                },
              )}
              values={[
                {
                  value: intl.$t(
                    {
                      id: "IMPORT_COST_CODES_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <DefaultLinkChunks
                          chunks={chunks}
                          route={routes.costStructure}
                        />
                      ),
                    },
                  ),
                },
              ]}
              fulfilled={
                sourceSystem.progress.costCodes.total ===
                sourceSystem.progress.costCodes.mapped
              }
            />
            <ItemGroup
              iconColorClassName={
                sourceSystem.progress.vendors.mapped !==
                sourceSystem.progress.vendors.total
                  ? "text-orange-500"
                  : undefined
              }
              label={intl.$t(
                { id: "VENDORS_COUNT" },
                {
                  total: sourceSystem.progress.vendors.total,
                  count: sourceSystem.progress.vendors.mapped,
                },
              )}
              values={[
                {
                  value: intl.$t(
                    {
                      id: "CONNECT_VENDORS_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <DefaultLinkChunks
                          chunks={chunks}
                          route={routes.vendors}
                        />
                      ),
                    },
                  ),
                },
              ]}
              fulfilled={
                sourceSystem.progress.vendors.total ===
                sourceSystem.progress.vendors.mapped
              }
            />
            <ItemGroup
              iconColorClassName={
                sourceSystem.progress.projects.mapped !==
                sourceSystem.progress.projects.total
                  ? "text-orange-500"
                  : undefined
              }
              label={intl.$t(
                { id: "PROJECTS_COUNT" },
                {
                  count: sourceSystem.progress.projects.mapped,
                  total: sourceSystem.progress.projects.total,
                },
              )}
              values={[
                {
                  value: intl.$t(
                    {
                      id: "CONNECT_PROJECTS_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <DefaultLinkChunks
                          chunks={chunks}
                          route={routes.projects}
                        />
                      ),
                    },
                  ),
                },
              ]}
              fulfilled={
                sourceSystem.progress.projects.mapped ===
                sourceSystem.progress.projects.total
              }
            />
            <SourceSystemFeatureRequirement
              feature={IntegrationFeature.OrgMaterialsMapping}
            >
              <ItemGroup
                iconColorClassName="text-gray-600"
                label={intl.$t(
                  { id: "MATERIALS_COUNT" },
                  {
                    count: sourceSystem.progress.orgMaterials.mapped,
                    total: sourceSystem.progress.orgMaterials.total,
                  },
                )}
                values={[
                  {
                    value: intl.$t(
                      {
                        id: "CONNECT_MATERIALS_LINK",
                      },
                      {
                        sub: (chunks: ReactNode) => (
                          <DefaultLinkChunks
                            chunks={chunks}
                            route={routes.itemDatabase}
                          />
                        ),
                      },
                    ),
                    details: intl.$t({ id: "INTEGRATIONS_REQUIRED_MATERIALS" }),
                  },
                ]}
                fulfilled={
                  sourceSystem.progress.orgMaterials.total ===
                  sourceSystem.progress.orgMaterials.mapped
                }
              />
            </SourceSystemFeatureRequirement>
          </Group>
        </Content>
        <Actions>
          <DeleteButton
            onClick={() => confirmAndDisconnect(sourceSystem.system)}
            iconClassName="w-8 h-8"
          />
        </Actions>
      </IntegrationInner>
      <BottomBar>
        <ConnectionStatus isActive={connectionActive} />
        <OutlinedButton
          className="ml-6 h-[32px] min-h-[32px] bg-blue-100 text-sm"
          onClick={() => confirmAndDisconnect(sourceSystem.system)}
        >
          {intl.$t({ id: "DISCONNECT" })}
        </OutlinedButton>
      </BottomBar>
    </Integration>
  );
};
