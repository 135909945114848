import { useIntegrationTypeConfig } from "@/common/hooks/integrations/integration-type-config/useIntegrationConfig";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IntegrationType, SourceSystem } from "@/generated/graphql";
import { useCallback } from "react";

export const useIntegrationFeatureRequirement = () => {
  const { getIntegrationConfig } = useIntegrationTypeConfig();
  const { getSourceSystemConfig } = useSourceSystemConfig();
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();

  const hasFeature = useCallback(
    ({
      integrationType,
      sourceSystem,
      feature,
    }: {
      feature: IntegrationFeature;
      integrationType?: IntegrationType;
      sourceSystem?: SourceSystem;
    }) => {
      if (sourceSystem) {
        return getSourceSystemConfig(sourceSystem, true).features.includes(
          feature,
        );
      }
      if (integrationType) {
        return getIntegrationConfig(integrationType).features.includes(feature);
      }
      return false;
    },
    [getIntegrationConfig, getSourceSystemConfig],
  );

  const hasFeatureInConnectedSourceSystem = useCallback(
    (feature: IntegrationFeature) => {
      return hasFeature({
        feature,
        sourceSystem: connectedSourceSystem,
      });
    },
    [connectedSourceSystem, hasFeature],
  );

  const hasFeatureInConnectedIntegrationType = useCallback(
    (feature: IntegrationFeature) => {
      return hasFeature({
        feature,
        integrationType: connectedIntegrationType,
      });
    },
    [connectedIntegrationType, hasFeature],
  );

  return {
    hasFeature,
    hasFeatureInConnectedSourceSystem,
    hasFeatureInConnectedIntegrationType,
    connectedSourceSystem,
  };
};
