import { QueryExternalProjectsFilter } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  filter: QueryExternalProjectsFilter | undefined;
  setFilter: (filter: QueryExternalProjectsFilter) => void;
};

export const useExternalProjectsFilterStore = create<State>()(
  devtools((set) => ({
    filter: undefined,

    setFilter: (filter: QueryExternalProjectsFilter) => {
      set({ filter });
    },
  })),
);
