import { useUser } from "@/common/providers/UserProvider";
import { useOrderTypeSummariesQuery } from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useOrderTypeOptions = () => {
  const { viewer } = useUser();
  const { data, loading } = useOrderTypeSummariesQuery({
    variables: {
      id: viewer?.org?.id || "",
      archived: false,
    },
    skip: !viewer?.authId || !viewer?.org?.id,
  });

  const getOrderType = useCallback(
    (id: string | null | undefined) =>
      data?.org?.releaseTypes.find((rt) => rt.id === id),
    [data?.org?.releaseTypes],
  );

  return useMemo(
    () => ({
      orderTypes: data?.org?.releaseTypes ?? [],
      loading,
      getOrderType,
      includeOrderTypes: (data?.org?.releaseTypes || []).length > 1,
      defaultOrderType:
        data?.org?.releaseTypes?.find((rt) => rt.default) || null,
    }),
    [data?.org?.releaseTypes, getOrderType, loading],
  );
};
