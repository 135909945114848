import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useMemo } from "react";

export const useEnabledPoInSourceSystem = () => {
  const { settings } = useOrgSettings();

  const isPoEnabledInSourceSystem = useMemo(() => {
    return !!settings?.releases?.useSourceSystemPOs;
  }, [settings?.releases?.useSourceSystemPOs]);

  return {
    isPoEnabledInSourceSystem,
  };
};
