import FileAdd from "@/assets/icons/file-add.svg?react";
import File from "@/assets/icons/file.svg?react";
import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import {
  AddItemsFromDialog,
  AddItemsOptionType,
} from "@/common/components/add-items-from-dialog/AddItemsFromDialog";
import { If } from "@/common/components/if/If";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { BuyoutFieldsFragment } from "@/generated/graphql";
import { FC, useEffect, useMemo, useState } from "react";
import { ContractorBuyoutProvider } from "../../../providers/ContractorBuyoutProvider";
import { AddEstimatedItemsDialog } from "../import-to-buyout/AddEstimatedItemsDialog";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";
import { BuyoutProjectItemsProvider } from "../providers/BuyoutProjectItemsProvider";
import { useSyncBuyoutItems } from "../providers/SyncBuyoutItemsProvider";

type BuyoutAddItemsProps = {
  buyout: BuyoutFieldsFragment;
};

export const BuyoutAddItems: FC<BuyoutAddItemsProps> = ({ buyout }) => {
  const [showAddItemsDialog, setShowAddItemsDialog] = useState(false);
  const [showEstimatedItemsDialog, setShowEstimatedItemsDialog] =
    useState(false);
  const { setNewBuyoutItem } = useBuyoutGrouped();
  const viewState = useTableViewStore((state) => state.viewState);
  const { syncBuyoutItems } = useSyncBuyoutItems();

  useEffect(() => {
    setShowAddItemsDialog(
      buyout.items.length === 0 && viewState === TableViewState.normal,
    );
  }, [buyout, viewState]);

  const dialogOptions = useMemo<AddItemsOptionType[]>(
    () => [
      {
        label: "ADD_FROM_PROJECT_ESTIMATED_ITEMS",
        details: "ADD_FROM_PROJECT_ESTIMATED_ITEMS_INFO",
        callback: () => {
          setShowAddItemsDialog(false);
          setShowEstimatedItemsDialog(true);
        },
        icon: <File className="h-28 w-28" />,
        preferred: true,
      },
      ...(viewState === TableViewState.normal
        ? [
            {
              label: "ADD_CUSTOM_MATERIAL",
              details: "ADD_CUSTOM_MATERIAL_INFO",
              callback: () => {
                setNewBuyoutItem({
                  description: "",
                  projectItem: {
                    estimateUom: "",
                  },
                  quantity: 0,
                  isAddMode: true,
                  requestedUnitPrice: "0",
                });
                setShowAddItemsDialog(false);
              },
              icon: <FileAdd className="h-28 w-28" />,
            },
          ]
        : []),
    ],
    [viewState, setNewBuyoutItem],
  );

  const gotoItemSelection = async () => {
    const result = await syncBuyoutItems();
    if (result) {
      setShowAddItemsDialog(true);
    }
  };

  return (
    <>
      <AddItemsButton onClick={gotoItemSelection} />
      <AddItemsFromDialog
        title="ADD_ITEMS_TO_BUYOUT"
        options={dialogOptions}
        visible={showAddItemsDialog}
        setVisible={setShowAddItemsDialog}
      />
      <If isTrue={showEstimatedItemsDialog}>
        <ContractorBuyoutProvider>
          <BuyoutProjectItemsProvider>
            <AddEstimatedItemsDialog
              setShowEstimatedItemsDialog={setShowEstimatedItemsDialog}
            />
          </BuyoutProjectItemsProvider>
        </ContractorBuyoutProvider>
      </If>
    </>
  );
};
