import { useDialog } from "@/common/components/dialog/DialogProvider";
import { InfoText } from "@/common/components/info-text/InfoText";
import { OverlayPanelProps } from "@/common/components/panel/OverlayPanel";
import { OverlayPanelWithStepper } from "@/common/components/panel/OverlayPanelWithStepper";
import { useStepper } from "@/common/components/stepper/Stepper";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { DIALOG_AUTO_CLOSE_TIMER, QUERYSTRING } from "@/common/const";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import {
  AssetContext,
  AuthorizationStatus,
  CreateDeliverySlipInput,
  DeliverySlipPartialFieldsFragment,
  OrgLocationRole,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import tw from "tailwind-styled-components";
import { useDeliverySlips } from "../../providers/DeliverySlipsProvider";
import { NewDeliverySlipCreatedInfo } from "./NewDeliverySlipCreatedInfo";
import { NewDeliverySlipFormFields } from "./NewDeliverySlipFormFields";

export const LET_OFFICE_ASSIGN = "-1";

type Props = {
  onClose: () => void;
};

const Container = tw.div`
  grid gap-4 grid-rows-[auto_auto_1fr] h-full
`;

const DeliverySlipFormWithProvider: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { locations } = useUserLocations();
  const { assets } = useUploadAssets();
  const { viewer } = useUser();
  const { openDialog } = useDialog();
  const { moveToNextStep } = useStepper();
  const navigate = useNavigate();
  const { createDeliverySlip } = useDeliverySlips();
  const [newSlip, setNewSlip] =
    useState<DeliverySlipPartialFieldsFragment | null>(null);
  const { setWarningAlert } = useSnackbar();

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      locationId: locations.length === 1 ? locations[0].id : "",
      projectId: LET_OFFICE_ASSIGN,
      releaseId: LET_OFFICE_ASSIGN,
      notes: "",
    } as FieldValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (!methods.getValues("locationId")) {
      const authorizedLocations = locations.filter(
        (location) =>
          location.permissions?.createDeliverySlip ===
          AuthorizationStatus.Authorized,
      );
      methods.setValue(
        "locationId",
        authorizedLocations.length === 1 ? authorizedLocations[0].id : "",
      );
    }
  }, [locations, methods]);

  const [saving, setSaving] = useState(false);

  const onSave = useCallback(async () => {
    const input = methods.getValues();
    setSaving(true);
    const newSlip: CreateDeliverySlipInput = {
      orgLocationId: input.locationId || "",
      projectId:
        input.projectId === LET_OFFICE_ASSIGN ? null : input.projectId || "",
      notes: input.notes || "",
      releaseID:
        input.releaseId === LET_OFFICE_ASSIGN ? null : input.releaseId || "",
      assetUrls: assets.map((asset) => asset.url),
      async: false,
    };

    if (assets.filter((asset) => asset.url.includes(".pdf?"))?.length > 1) {
      setWarningAlert(<FormattedMessage id="ONLY_ONE_PDF_DOCUMENT_ALLOWED" />);
      setSaving(false);
      return;
    }

    const deliverySlip = await createDeliverySlip(newSlip);

    setSaving(false);
    if (!deliverySlip) {
      return;
    } else {
      setNewSlip(deliverySlip);
    }
    moveToNextStep();
  }, [methods, assets, createDeliverySlip, moveToNextStep, setWarningAlert]);

  const isForeman = useMemo(
    () =>
      viewer?.locationRoles.every(
        (s) => s.role === OrgLocationRole.LocationForeman,
      ),
    [viewer],
  );

  const gotoNext = useCallback(() => {
    if (isForeman) {
      navigate(routes.deliverySlips);
      setTimeout(
        () =>
          openDialog({
            content: (
              <SuccessModal
                message={intl.$t({ id: "DELIVERY_SLIP_CREATED" })}
              />
            ),
            closingTimer: DIALOG_AUTO_CLOSE_TIMER,
          }),
        200,
      );
    } else if (newSlip?.release) {
      navigate({
        pathname: generatePath(routes.deliverySlipReceiveOrder, {
          deliverySlipId: newSlip?.id,
        }),
        search: createSearchParams({
          [QUERYSTRING.SPREADSHEET_VIEW]:
            newSlip?.release?.poNumber &&
            (newSlip.release.status === ReleaseStatus.Draft ||
              newSlip.release.status === ReleaseStatus.Reserved)
              ? "true"
              : "false",
        }).toString(),
      });
    } else {
      navigate(
        generatePath(routes.deliverySlipMatchOrder, {
          deliverySlipId: newSlip?.id,
        }),
      );
    }
    onClose();
  }, [
    intl,
    isForeman,
    navigate,
    newSlip?.id,
    newSlip?.release,
    onClose,
    openDialog,
  ]);

  const steps = useMemo((): OverlayPanelProps[] => {
    return [
      {
        title: intl.$t({ id: "ADD_PACKING_SLIP" }),
        children: (
          <Container>
            <NewDeliverySlipFormFields />
            <UploadAsset
              accept={{
                [PDF_MIME_TYPE]: PDF_EXTENSIONS,
                [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
              }}
              limit={1}
            />
            <InfoText title="NOTE" body="NEW_SLIP_NOTE" />
          </Container>
        ),
        onCancel: onClose,
        saving,
        onSave: handleSubmit(onSave),
        disableSave: assets.length === 0,
      },
      {
        title: intl.$t({ id: "ADD_PACKING_SLIP" }),
        children: <NewDeliverySlipCreatedInfo />,
        onCancel: onClose,
        saving,
        cancelLabel: intl.$t({ id: "NO" }),
        saveLabel: intl.$t({ id: "PROCEED" }),
        disableSave: !newSlip,
        onSave: gotoNext,
      },
    ];
  }, [
    intl,
    onClose,
    saving,
    handleSubmit,
    onSave,
    assets.length,
    newSlip,
    gotoNext,
  ]);

  return (
    <FormProvider {...methods} handleSubmit={handleSubmit}>
      <OverlayPanelWithStepper steps={steps} />
    </FormProvider>
  );
};

export const NewDeliverySlipFormWithStepper: FC<Props> = ({ onClose }) => {
  return (
    <UploadAssetProvider context={AssetContext.DeliverySlip}>
      <DeliverySlipFormWithProvider onClose={onClose} />
    </UploadAssetProvider>
  );
};
