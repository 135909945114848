import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { ReleaseStatus, ReleaseType } from "@/generated/graphql";
import { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "../tooltip/Tooltip";
import { BaseStatusChip, BaseStatusChipProps } from "./BaseStatusChip";

type Props = BaseStatusChipProps & {
  status: ReleaseStatus | undefined;
  releaseType: Pick<ReleaseType, "transactionKind"> | undefined;
  hoverText?: ReactNode;
  className?: string;
};

const RELEASE_STATUS = {
  [ReleaseStatus.Draft]: "bg-gray-200",
  [ReleaseStatus.Canceled]: "bg-blue-400 text-white",
  [ReleaseStatus.Requested]: "bg-yellow-600",
  [ReleaseStatus.Scheduled]: "bg-green-300",
  [ReleaseStatus.Received]: "bg-green-600 text-white",
  [ReleaseStatus.PartiallyReceived]: "bg-orange-100 text-xs ",
  [ReleaseStatus.AwaitingApproval]: "bg-blue-500 text-white text-xs",
  [ReleaseStatus.Rejected]: "bg-red-200",
  [ReleaseStatus.Reserved]: "bg-yellow-400",
};

export const ReleaseStatusChip: FC<Props> = ({
  status,
  hoverText,
  type = "normal",
  releaseType,
  className = "",
}) => {
  const { orderTypeConfig } = useOrderTypesConfig({
    release: {
      type: releaseType,
    },
  });

  if (!status) {
    return null;
  }

  return (
    <Tooltip
      id="release-status"
      position="top"
      element={
        <BaseStatusChip
          type={type}
          classes={{ root: className, text: RELEASE_STATUS[status] }}
          data-testid="release-status-chip"
        >
          {status === ReleaseStatus.Received ? (
            orderTypeConfig.labels.status[ReleaseStatus.Received]
          ) : (
            <FormattedMessage id={`RELEASE_STATUS_${status}`} />
          )}
        </BaseStatusChip>
      }
    >
      {hoverText}
    </Tooltip>
  );
};
