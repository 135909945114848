import { ReleaseSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";

export const useHasMajorityDefaultSync = (
  releases: ReleaseSummaryFieldsFragment[],
) => {
  const syncCount = useMemo(() => {
    if (releases.length === 1 && releases[0].poLink) {
      return releases[0].poLink?.autoSync ? 1 : 0;
    }

    return releases.filter((release) =>
      release.poLink ? release.poLink.autoSync : release.type?.defaultAutoSync,
    ).length;
  }, [releases]);

  const hasMajorityDefaultSync = useMemo((): boolean => {
    return syncCount >= releases.length / 2;
  }, [releases.length, syncCount]);

  return { syncCount, hasMajorityDefaultSync };
};
