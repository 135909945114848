import { useViewerTaxCodesQuery } from "@/generated/graphql";
import { useMemo } from "react";

export const useTaxCodes = () => {
  const { data, loading } = useViewerTaxCodesQuery();

  const taxCodes = useMemo(() => {
    return data?.viewer?.org.taxCodes || [];
  }, [data]);

  return {
    taxCodes,
    loading,
  };
};
