import { ProjectStatus } from "@/generated/graphql";
import {
  CloseRounded,
  DoneAllRounded,
  DoneRounded,
  GavelRounded,
} from "@mui/icons-material";

export const PROJECT_STATUSES = {
  [ProjectStatus.Active]: {
    icon: GavelRounded,
    label: "PROJECT_STATUS_ACTIVE",
    tooltip: "PROJECT_STATUS_ACTIVE_BID_FOR_JOB",
    color: "bg-yellow-600",
    text: "",
  },
  [ProjectStatus.Awarded]: {
    icon: DoneRounded,
    label: "PROJECT_STATUS_AWARDED",
    tooltip: "PROJECT_STATUS_ACTIVE_AWARDED_JOB",
    color: "bg-green-300",
    text: "",
  },
  [ProjectStatus.Completed]: {
    icon: DoneAllRounded,
    label: "PROJECT_STATUS_COMPLETED",
    tooltip: "PROJECT_STATUS_COMPLETED_AWARDED_JOB",
    color: "bg-green-600",
    text: "text-white",
  },
  [ProjectStatus.Lost]: {
    icon: CloseRounded,
    label: "PROJECT_STATUS_LOST",
    tooltip: "PROJECT_STATUS_LOST_BID_FOR_JOB",
    color: "bg-red-200",
    text: "",
  },
};
