import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DeleteOutline } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useRfq } from "../hooks/useRfq";
import { useRfqMutations } from "../hooks/useRfqMutations";

type Props = {
  id: string;
  readonly: boolean;
};

export const DeleteItem: FC<Props> = ({ id, readonly }) => {
  const { rfq } = useRfq();
  const { openDialog } = useDialog();
  const { updateRfq } = useRfqMutations();
  const intl = useIntl();

  const handleDelete = useCallback(() => {
    if (!rfq) {
      return;
    }

    return updateRfq({ rfqId: rfq.id, removedItems: [id] });
  }, [rfq, updateRfq, id]);

  return (
    <If isTrue={!readonly}>
      <LinkLike
        onClick={() =>
          openDialog({
            cancelButtonText: intl.$t({ id: "CANCEL" }),
            confirmButtonText: intl.$t({ id: "DELETE" }),
            includeWarningIcon: true,
            title: intl.$t({ id: "RFQ_ITEM_REMOVE_RFQ_ITEM_QUESTION" }),
            text: intl.$t({ id: "RFQ_ITEM_REMOVE_RFQ_ITEM_TEXT" }),
            handleConfirm: handleDelete,
          })
        }
      >
        <DeleteOutline />
      </LinkLike>
    </If>
  );
};
