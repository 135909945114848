import {
  AdditionalFiltersOverlay,
  DividerLine,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { KickbackFilters } from "@/common/components/kickback-filters/KickbackFilters";
import { OrderTypesFilter } from "@/common/components/order-types-filter/OrderTypesFilter";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useEnabledPoInSourceSystem } from "@/common/hooks/useEnabledPoInSourceSystem";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoices } from "../../providers/InvoicesProvider";
import { InvoiceExportedWithFiltering } from "./InvoiceExportedWithFiltering";

export const CustomFilterContainer = tw.div`
  grid grid-cols-2 space-between items-center mb-2
`;

enum InvoicesAdditionalFilter {
  PoExported = "PO_EXPORTED",
  ReceiptPosted = "RECEIPT_POSTED",
  InvoiceExported = "INVOICE_EXPORTED",
  InvoiceDownloaded = "INVOICE_DOWNLOADED",
  ClosedProjects = "CLOSED_PROJECTS",
  Deleted = "DELETED",
}

export const InvoicesAdditionalFilters = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInvoices();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const isInvoiceBatchingSupported = useMemo(
    () => hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching),
    [hasFeatureInConnectedSourceSystem],
  );

  const options = useMemo<Option<QueryInvoicesFilter>[]>(() => {
    return [
      ...(includePoNumbering && isPoEnabledInSourceSystem
        ? [
            {
              id: InvoicesAdditionalFilter.PoExported,
              title: intl.$t({ id: "PO_EXPORTED" }),
              filter: (value: boolean | null) => ({
                poExported: value,
              }),
              value: (currentFilter: QueryInvoicesFilter | undefined) =>
                currentFilter?.poExported,
            },
          ]
        : []),
      ...(isPoEnabledInSourceSystem
        ? [
            {
              id: InvoicesAdditionalFilter.ReceiptPosted,
              title: intl.$t({ id: "RECEIPT_POSTED" }),
              filter: (value: boolean | null) => ({
                receiptPosted: value,
              }),
              value: (currentFilter: QueryInvoicesFilter | undefined) =>
                currentFilter?.receiptPosted,
            },
          ]
        : []),
      ...(isInvoiceBatchingSupported
        ? []
        : [
            {
              id: InvoicesAdditionalFilter.InvoiceExported,
              title: intl.$t({ id: "INVOICE_EXPORTED_FILTER" }),
              filter: (value: boolean | null) => ({
                exported: value,
              }),
              value: (currentFilter: QueryInvoicesFilter | undefined) =>
                currentFilter?.exported,
            },
          ]),
      {
        id: InvoicesAdditionalFilter.InvoiceDownloaded,
        title: intl.$t({ id: "INVOICE_DOWNLOADED" }),
        filter: (value) => ({
          downloaded: value,
        }),
        value: (currentFilter) => currentFilter?.downloaded,
      },
      {
        id: InvoicesAdditionalFilter.ClosedProjects,
        title: intl.$t({ id: "INVOICES_FROM_CLOSED_PROJECTS" }),
        filter: (value) => ({
          closedProjects: value,
        }),
        value: (currentFilter) => currentFilter?.closedProjects,
      },
      {
        id: InvoicesAdditionalFilter.Deleted,
        title: intl.$t({ id: "DELETED" }),
        filter: (value) => ({
          archived: value,
        }),
        value: (currentFilter) => currentFilter?.archived,
      },
    ];
  }, [
    includePoNumbering,
    intl,
    isInvoiceBatchingSupported,
    isPoEnabledInSourceSystem,
  ]);

  const setFilterHandler = useCallback(
    (values: string[] | undefined) => {
      setFilter({
        ...filter,
        releaseTypeIds: values,
      });
    },
    [filter, setFilter],
  );

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      options={options}
      setFilter={setFilter}
      customBottomFilters={
        <>
          <If isTrue={isInvoiceBatchingSupported}>
            <InvoiceExportedWithFiltering
              filter={filter}
              setFilter={setFilter}
            />
            <DividerLine className="mb-4" />
          </If>
          <KickbackFilters filter={filter} setFilter={setFilter} />
          <DividerLine className="mb-4" />
          <CustomFilterContainer>
            <FormattedMessage id="SELECT_VENDOR" />
            <VendorsFilterSelector
              value={filter?.sellerOrgLocationIds?.[0]}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  sellerOrgLocationIds: value ? [value] : undefined,
                });
              }}
            />
          </CustomFilterContainer>
          <OrderTypesFilter
            selectedOrderTypes={filter?.releaseTypeIds}
            onChange={setFilterHandler}
          />
        </>
      }
    />
  );
};
