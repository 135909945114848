import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useIntl } from "react-intl";

export const useReceiptUpdateReleaseSpreadsheetConfig =
  (): SpreadSheetConfig[] => {
    const { release } = useRelease();

    const intl = useIntl();
    return [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: (id) =>
          isLumpSumItem(release?.items.find((itm) => itm.id === id))
            ? "name"
            : "material",
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "UOM",
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "UNIT_PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.UnitPrice,
        disabledForLumpSum: true,
      },
      {
        header: intl.$t({ id: "EXT_PRICE" }),
        columnId: "extPrice",
        columnType: COLUMN_TYPE.ExtPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        columnType: COLUMN_TYPE.Zone,
      },
    ];
  };
