import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useInstruction } from "@/common/hooks/useInsruction";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectPhaseCodes } from "@/contractor/pages/home/project/hooks/useProjectPhaseCodes";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { ReleaseDescription } from "@/contractor/pages/home/release/components/ReleaseDescription";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ReleaseAdditionalCharges } from "../../../../../../../../../../common/components/release-additional-charges-and-taxes/ReleaseAdditionalCharges";
import { useTaxCalculation } from "../../../../../../../../../../common/components/sales-tax-input/hooks/useTaxCalculation";
import { SalesTax } from "../../../../../../../../../../common/components/sales-tax-input/SalesTax";
import { SalesTaxInput } from "../../../../../../../../../../common/components/sales-tax-input/types/SalesTaxInputType";
import { usePriceCalculation } from "../../../../../../../release/hooks/usePriceCalculation";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";

const Container = tw.div`flex flex-col h-full pb-7 self-stretch`;
const InnerContainer = tw.div`flex flex-row items-end justify-end`;
const Item = tw.div`flex flex-col items-center justify-end gap-4 mt-10`;
const InputContainer = tw.div`w-full pr-10`;
const ChargesInputContainer = tw.div`w-full`;
const TaxInputContainer = tw.div`w-full -mt-3`;

type Props = {
  createMode?: boolean;
};

export const InvoiceUpdateReleaseNonItemizedView: FC<Props> = ({
  createMode = false,
}) => {
  const intl = useIntl();
  const { hasPhaseCodes } = useOrgSettings();
  const { phaseCodes } = useProjectPhaseCodes();
  const { release } = useRelease();
  const { saveDescription, saveInstruction } = useInstruction();
  const { importedDescription } = useInvoiceImportExternalPO();
  const { costCodes, loading } = useProjectCostCodes();
  const { calcAdditionalChargesPrice } = usePriceCalculation();
  const { getTaxAmount } = useTaxCalculation();

  const { watch, setValue, getValues } =
    useFormContext<InvoiceCreateReleaseFormValues>();

  const netAmount = watch("netAmount");
  const projectId = watch("projectId");
  const taxRate = watch("taxRate");
  const customTaxAmount = watch("customTaxAmount");
  const orderTypeId = watch("orderTypeId");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const chargesAmount = watch("additionalCharges");
  const taxVariance = watch("taxVariance");

  const taxAmount = getTaxAmount(taxRate, customTaxAmount, netAmount);

  useSetCurrentProjectId(projectId);

  const salesTaxInput: SalesTaxInput = useMemo(() => {
    return {
      taxRate,
      customTaxAmount,
      orderTypeId,
      taxAmount,
      netAmount,
      id: undefined,
      version: undefined,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(chargesAmount),
      taxVariance,
    };
  }, [
    taxRate,
    taxAmount,
    netAmount,
    customTaxAmount,
    orderTypeId,
    taxCodeId,
    taxType,
    chargesAmount,
    taxVariance,
    calcAdditionalChargesPrice,
  ]);

  return (
    <Container>
      <InnerContainer>
        <Item>
          <InputContainer>
            <If isTrue={!hasPhaseCodes}>
              <SelectControlled
                name="costCodeId"
                options={costCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.formatted}
                placeholder={intl.$t({ id: "COST_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_COST_CODE" })}
                disableClearable={false}
              />
            </If>
            <If isTrue={hasPhaseCodes}>
              <SelectControlled
                name="phaseCodeId"
                options={phaseCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.name}
                placeholder={intl.$t({ id: "PHASE_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_PHASE_CODE" })}
                disableClearable={false}
              />
            </If>
          </InputContainer>
          <InputContainer>
            <NumericalInputControlled
              name="netAmount"
              label={intl.$t({ id: "SUBTOTAL" })}
              includeCurrency
              inputProps={{ className: "bg-white text-center" }}
            />
          </InputContainer>
          <ChargesInputContainer>
            <ReleaseAdditionalCharges
              readonly={false}
              showError={false}
              updateRelease={({ additionalCharges }) => {
                setValue("additionalCharges", additionalCharges ?? []);
                return true;
              }}
              additionalCharges={(watch("additionalCharges") ?? []).map(
                (charge, id) => ({
                  ...charge,
                  id: (charge.id ?? id).toString(),
                }),
              )}
            />
          </ChargesInputContainer>
          <TaxInputContainer>
            <SalesTax
              canEdit
              className="mt-2"
              salesTaxInput={salesTaxInput}
              update={({ taxCodeId, taxRate, taxType, customTaxAmount }) => {
                setValue("taxCodeId", taxCodeId ?? getValues("taxCodeId"));
                setValue("taxRate", taxRate ?? getValues("taxRate"));
                setValue("taxType", taxType ?? getValues("taxType"));
                setValue(
                  "customTaxAmount",
                  customTaxAmount ?? getValues("customTaxAmount"),
                );
              }}
            />
          </TaxInputContainer>
          <Instructions
            projectId={projectId}
            instruction={createMode ? undefined : release?.instructions}
            saveInstruction={saveInstruction}
          >
            <ReleaseDescription
              release={createMode ? undefined : release}
              description={
                createMode ? importedDescription || undefined : undefined
              }
              directlyEditable
              saveDescriptionCustomMethod={saveDescription}
            />
          </Instructions>
        </Item>
      </InnerContainer>
    </Container>
  );
};
