import { GridTable } from "@/common/components/grid-table/GridTable";
import { AdminHeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { NoFunction } from "@/types/NoFunction";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { NewOrgButton } from "./components/NewOrgButton";
import { tableConfiguration } from "./components/Orgs.tableConfiguration";
import { useEnterprises } from "./hooks/useEnteprises";

export const Orgs = () => {
  const { orgs, loading, error } = useEnterprises();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "ORGS" })}</title>
      </Helmet>
      <AdminHeaderContainer>
        <NewOrgButton />
      </AdminHeaderContainer>
      <GridTable
        items={orgs}
        configuration={{
          columns: tableConfiguration,
          toggle: {
            item: NoFunction,
          },
          classNames: {
            header: "top-21",
          },
        }}
        readonly
        loading={loading}
        error={error}
      />
    </>
  );
};
