import { QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { QueryExternalProjectsFilter, SourceSystem } from "@/generated/graphql";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useAgaveExternalProjectsWithPagination } from "./useAgaveExternalProjectsWithPagination";
import { useExternalProjectsFilterStore } from "./useExternalProjectsFilterStore";

export const useAgaveExternalProjects = (sourceSystem: SourceSystem | null) => {
  const { queryParams } = useQueryParams();
  const { filter, setFilter } = useExternalProjectsFilterStore(
    useShallow((state) => ({
      filter: state.filter,
      setFilter: state.setFilter,
    })),
  );

  const { externalProjects, loading, error, totalCount, setPage } =
    useAgaveExternalProjectsWithPagination(sourceSystem, filter);

  useErrorEffect(error);

  useEffect(() => {
    setFilter({
      search: queryParams.get(QUERYSTRING.SEARCH) || "",
      mapped:
        queryParams.get(QUERYSTRING.MAPPED) !== null
          ? Boolean(queryParams.get(QUERYSTRING.MAPPED))
          : undefined,
    });
  }, [queryParams, setFilter]);

  const setFilterUpdate = (filter: QueryExternalProjectsFilter) => {
    setPage({
      page: 0,
      queryParams: {
        ...filter,
      },
    });
    setFilter(filter);
  };

  return {
    externalProjects:
      externalProjects.map((i) => ({ ...i, id: i.externalId })) ?? [],
    loading,
    totalCount,
    filter,
    isFiltered: !!(filter?.search || filter?.mapped !== undefined),
    setFilter: setFilterUpdate,
  };
};
