import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { ImportTaxCodesMutation, SourceSystem } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { AnimatedLoading } from "../../../../../home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import { ImportExternalTaxCodesImportResults } from "../components/import/steps/ImportExternalTaxCodesImportResults";
import { ImportExternalTaxCodesTable } from "../components/import/steps/ImportExternalTaxCodesTable";
import { useImportTaxCodes } from "../hooks/useImportTaxCodes";

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) => void;
  closeModal: () => void;

  selectedExternalTaxCodes: string[];
  setSelectedExternalTaxCodes: (id: string[]) => void;

  importAllExternalItems: boolean;
  setImportAllExternalItems: (value: boolean) => void;

  totalExternalTaxCodesCount: number;
  setTotalExternalTaxCodesCount: (count: number) => void;

  importedTaxCodes: ImportTaxCodesMutation["importTaxCodes"] | null;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  selectedExternalTaxCodes: [],
  setSelectedExternalTaxCodes: NoFunction,

  importAllExternalItems: false,
  setImportAllExternalItems: NoFunction,

  totalExternalTaxCodesCount: 0,
  setTotalExternalTaxCodesCount: NoFunction,

  importedTaxCodes: null,
});

export const ImportExternalTaxCodesProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();

  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [importAllExternalItems, setImportAllExternalItems] = useState(false);

  const [totalExternalTaxCodesCount, setTotalExternalTaxCodesCount] =
    useState(0);
  const [selectedExternalTaxCodes, setSelectedExternalTaxCodes] = useState<
    string[]
  >([]);
  const [importedTaxCodes, setImportedTaxCodes] = useState<
    ImportTaxCodesMutation["importTaxCodes"] | null
  >(null);

  const openModal = useCallback(
    ({ sourceSystem }: { sourceSystem: SourceSystem }) => {
      setSourceSystem(sourceSystem);
      setModalOpened(true);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSourceSystem(null);
    setNestedStep(0);
  }, [setNestedStep]);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
  }, [resetModal]);

  const { importTaxCodes: importTaxCodesMutation } = useImportTaxCodes();
  const importSelectedTaxCodes = useCallback(async () => {
    if (sourceSystem) {
      moveToNextNestedStep();
      const result = await importTaxCodesMutation({
        externalTaxCodeIds: selectedExternalTaxCodes,
        sourceSystem,
      });
      if (!result) {
        moveToPreviousNestedStep();
      }
      if (result) {
        setImportedTaxCodes(result);
        moveToNextNestedStep();
      }
    }
  }, [
    sourceSystem,
    selectedExternalTaxCodes,
    importTaxCodesMutation,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
  ]);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            component: <ImportExternalTaxCodesTable />,
            footerButtonsConfig: [
              {
                type: "text",
                text: intl.$t(
                  { id: "OUT_OF_SELECTED" },
                  {
                    count: selectedExternalTaxCodes.length,
                    total: totalExternalTaxCodesCount,
                  },
                ),
                className: "flex mr-4 items-center",
                hidden:
                  selectedExternalTaxCodes.length === 0 &&
                  totalExternalTaxCodesCount === 0,
              },
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: importSelectedTaxCodes,
                text: intl.$t({ id: "IMPORT" }),
                className: "flex-1",
                disabled: selectedExternalTaxCodes.length === 0,
              },
            ],
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: (
              <CompletedStep text={intl.$t({ id: "IMPORT_COMPLETED" })} />
            ),
            onModalClose: moveToNextNestedStep,
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: <ImportExternalTaxCodesImportResults />,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "w-60",
              },
            ],
          },
        ],
      },
    ],
    [
      intl,
      selectedExternalTaxCodes.length,
      totalExternalTaxCodesCount,
      closeModal,
      importSelectedTaxCodes,
      moveToNextNestedStep,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,

        selectedExternalTaxCodes,
        setSelectedExternalTaxCodes,

        importAllExternalItems,
        setImportAllExternalItems,

        totalExternalTaxCodesCount,
        setTotalExternalTaxCodesCount,

        importedTaxCodes,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useImportExternalTaxCodes = () => useContext(ProviderContext);
