import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { COLUMN_TYPE } from "../enums/columnType";
import { getCellValue } from "./getCellValue";

export const sumTableUnitPrices = (data: Record<string, string>[]) => {
  return data.reduce((acc, row) => {
    const isLumpSum = isLumpSumUomText(getCellValue(row, COLUMN_TYPE.UOM));

    const unitPrice = isLumpSum
      ? 1
      : Number(
          getCellValue(row, COLUMN_TYPE.UnitPrice) ||
            getCellValue(row, COLUMN_TYPE.PrefilledPrice),
        );
    return acc + unitPrice;
  }, 0);
};
