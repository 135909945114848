import { AutoFixNormalOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

const MagicWandIcon = tw(AutoFixNormalOutlined)`text-blue-500 cursor-pointer
${({ $disabled }: { $disabled: boolean }) => $disabled && "text-gray-400"}
`;

export const MagicWand: FC<Props> = ({ disabled = false, onClick }) => {
  return (
    <Tooltip
      id="magic-wand-tooltip"
      element={<MagicWandIcon onClick={onClick} $disabled={disabled} />}
    >
      {disabled ? (
        <FormattedMessage id="PLEASE_SELECT_PROJECT_TO_AUTO_GENERATE_PO" />
      ) : undefined}
    </Tooltip>
  );
};
