import { If } from "@/common/components/if/If";
import { ImportExternalResultTitle } from "@/common/components/import-external-result-title/ImportExternalResultTitle";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportServiceCodesStore } from "../../../stores/useImportServiceCodesStore";
import { useImportExternalServiceCodesResultsConfiguration } from "./ImporExternalServiceCodesResults.config";

const Container = tw.div`flex flex-1 self-stretch flex-col`;

export const ImportExternalServiceCodesImportResults = () => {
  const intl = useIntl();
  const configuration = useImportExternalServiceCodesResultsConfiguration();
  const { importedServiceCodes } = useImportServiceCodesStore();

  return (
    <Container>
      <If isTrue={(importedServiceCodes?.created ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedServiceCodes?.created ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "CREATED_SERVICE_CODES_COUNT" },
                { count: (importedServiceCodes?.created ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16"
        />
      </If>
      <If isTrue={(importedServiceCodes?.mapped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedServiceCodes?.mapped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "MATCHED_SERVICE_CODES_COUNT" },
                { count: (importedServiceCodes?.mapped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
      <If isTrue={(importedServiceCodes?.skipped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedServiceCodes?.skipped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "SKIPPED_SERVICE_CODES_COUNT" },
                { count: (importedServiceCodes?.skipped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
    </Container>
  );
};
