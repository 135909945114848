import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import {
  MdFixedContainer,
  XlContainer,
} from "@/common/layout/ResponsiveClasses";
import { PaymentMethodFieldsFragment } from "@/generated/graphql";
import { FormattedMessage, useIntl } from "react-intl";

export const useImportExternalPaymentMethodsResultConfiguration = (): Array<
  GridCol<PaymentMethodFieldsFragment>
> => {
  const intl = useIntl();

  return [
    {
      wrapper: XlContainer,
      header: <FormattedMessage id="DESCRIPTION" />,
      item: ({ item }) => item.externalBankAccount?.name,
    },
    {
      wrapper: MdFixedContainer,
      header: <FormattedMessage id="TYPE" />,
      item: ({ item }) => intl.$t({ id: `PAYMENT_METHOD_TYPE_${item.type}` }),
      position: "center",
    },
    {
      wrapper: MdFixedContainer,
      header: <FormattedMessage id="NUMBER" />,
      item: ({ item }) => <NotNullableRenderer value={item.number} />,
      position: "center",
    },
  ];
};
