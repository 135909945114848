import { InlineNotesAndTerms } from "@/common/components/inline-notes-and-terms/InlineNotesAndTerms";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import {
  AssetContext,
  AssetFieldsFragment,
  BuyoutStatus,
  DistributorBuyoutFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useDistributorBuyout } from "../../providers/DistributorBuyoutProvider";

type Props = {
  buyout: DistributorBuyoutFieldsFragment;
};

const DistributorBuyoutInlineNotesAndTermsWithProvider: FC<Props> = ({
  buyout,
}) => {
  const { updateVendorBuyout } = useDistributorBuyout();
  const { assets } = useUploadAssets();

  const save = async ({
    newAssets,
    notes,
  }: {
    newAssets?: AssetFieldsFragment[];
    notes?: string | undefined;
  } = {}) => {
    await updateVendorBuyout?.({
      notes: notes || undefined,
      assetUrls: (newAssets || assets).map((asset) => asset.url),
    });
  };

  const readonly = useMemo(() => {
    return !checkBuyoutStatus(buyout, [BuyoutStatus.Requested]);
  }, [buyout]);

  if (readonly && !buyout.notes && !buyout.assets.length) {
    return null;
  }

  return (
    <InlineNotesAndTerms save={save} notes={buyout.notes} readonly={readonly} />
  );
};

export const DistributorBuyoutInlineNotesAndTerms: FC<Props> = (props) => {
  const { buyout } = props;
  return (
    <UploadAssetProvider
      initialAssets={buyout?.assets}
      context={AssetContext.Note}
      projectId={buyout?.project?.id}
    >
      <DistributorBuyoutInlineNotesAndTermsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
