import { useDialog } from "@/common/components/dialog/DialogProvider";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { isNullOrEmptyString } from "@/common/utils/isNullOrEmptyString";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { PoFormat, ReleaseStatus, SourceSystem } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useInvoiceSequence } from "../../scanned-invoices/providers/InvoiceSequenceProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useApproveInvoice = () => {
  const { invoice, approveInvoice, setHasError } = useInvoiceVerification();
  const { release } = useRelease();
  const { calcExtPrice } = usePriceCalculation();
  const { connectedSourceSystem, settings } = useOrgSettings();
  const { setWarningAlert, setSystemAlert } = useSnackbar();
  const intl = useIntl();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { selectedInvoices, navigateToNextSequence } = useInvoiceSequence();
  const { useInvoiceItemPrice, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();

  const processApproveInvoice = useCallback(async () => {
    if (
      await approveInvoice({
        invoiceId: invoice?.id ?? "",
      })
    ) {
      setHasError(false);
      setSystemAlert(
        intl.$t(
          {
            id:
              connectedSourceSystem &&
              settings?.integrations.sourceSystems.find(
                (sourceSystem) => sourceSystem.connected,
              )?.autoExportApprovedInvoices
                ? "INVOICE_WAS_APPROVED_AND_QUEUED"
                : "INVOICE_APPROVED",
          },
          {
            integration: connectedSourceSystem
              ? intl.$t({ id: `SOURCE_SYSTEM_${connectedSourceSystem}` })
              : "",
            invoiceNumber: invoice?.number,
          },
        ),
      );
      setTimeout(() => {
        if (selectedInvoices.length > 1) {
          const index = selectedInvoices.findIndex(
            (currentInvoice) => currentInvoice.id === invoice?.id,
          );
          const isLastIndex = index === selectedInvoices.length - 1;
          if (isLastIndex) {
            navigate(routes.invoices);
          } else {
            navigateToNextSequence({ navigateToInvoices: true });
            setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
          }
        } else {
          setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        }
      }, 1600);
    }
  }, [
    approveInvoice,
    connectedSourceSystem,
    intl,
    invoice?.id,
    invoice?.number,
    navigate,
    navigateToNextSequence,
    selectedInvoices,
    setHasError,
    setMatchedOrderViewState,
    setSystemAlert,
    settings?.integrations.sourceSystems,
  ]);

  const approve = useCallback(async () => {
    setHasError(false);
    if (!invoice?.number) {
      setWarningAlert(
        intl.$t({
          id: "INVOICE_NUMBER_MUST_BE_SET",
        }),
      );
      setHasError(true);
      return;
    }
    if (!invoice?.issueDate) {
      setWarningAlert(
        intl.$t({
          id: "INVOICE_ISSUE_DATE_MUST_BE_SET",
        }),
      );
      setHasError(true);
      return;
    }
    const totalInvoiced = (
      release?.items
        .filter((i) => !!i.invoiceItems?.[0]?.id)
        .reduce(
          (acc, item) =>
            acc.plus(
              calcExtPrice(
                item.invoiceItems?.[0]?.quantity,
                useInvoiceItemPrice
                  ? item.invoiceItems?.[0]?.unitPrice || 0
                  : item.unitPrice || 0,
              ),
            ),
          new Decimal(0),
        ) || new Decimal(0)
    )
      .plus(invoice?.chargesAmount || 0)
      .plus(invoice?.taxAmount || 0);
    if (
      !totalInvoiced.equals(invoice?.total || 0) &&
      release?.type.poFormat !== PoFormat.Basic &&
      connectedSourceSystem !== SourceSystem.Foundation
    ) {
      setWarningAlert(
        intl.$t({ id: "INVOICE_APPROVE_ERROR_DIFFERENT_TOTALS" }),
      );
      setHasError(true);
      return;
    }
    if (invoice?.orgDuplicate) {
      setWarningAlert(
        intl.$t({
          id: "INVOICE_WITH_THE_SAME_NUMBER_ALREADY_EXISTS_IN_SYSTEM",
        }),
      );
      setHasError(true);
      return;
    }
    if (invoice?.vendorDuplicate) {
      setWarningAlert(
        intl.$t({
          id: "INVOICE_WITH_THE_SAME_NUMBER_ALREADY_EXISTS_FOR_VENDOR",
        }),
      );
      setHasError(true);
      return;
    }
    if (
      includePoNumbering &&
      invoice?.poNumber !== invoice?.release?.poNumber &&
      !(
        isNullOrEmptyString(invoice?.poNumber) &&
        isNullOrEmptyString(invoice?.release?.poNumber)
      )
    ) {
      setWarningAlert(intl.$t({ id: "INVOICE_APPROVE_ERROR_SAME_PO" }));

      setHasError(true);
      return;
    }
    if (!totalInvoiced.equals(invoice?.total || 0)) {
      openDialog({
        includeWarningIcon: true,
        title: intl.$t({ id: "INVOICE_TOTAL_MISMATCH" }),
        text: intl.$t(
          { id: "INVOICE_TOTAL_MISMATCH_TEXT" },
          {
            additionalInfo:
              invoice?.release?.status === ReleaseStatus.Reserved
                ? `${intl.$t({ id: "RELEASE_TRANSFER_TO_RESERVED" }, { releaseNumber: invoice?.release?.sequenceNumber })}. `
                : "",
          },
        ),
        confirmButtonText: intl.$t({ id: "APPROVE" }),
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        handleConfirm: processApproveInvoice,
      });
      return;
    }
    if (invoice?.release?.status === ReleaseStatus.Reserved) {
      openDialog({
        includeWarningIcon: true,
        title: intl.$t({ id: "CONFIRM_INVOICE" }),
        text: intl.$t(
          { id: "RELEASE_TRANSFER_TO_RESERVED" },
          { releaseNumber: invoice?.release?.sequenceNumber },
        ),
        confirmButtonText: intl.$t({ id: "APPROVE" }),
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        handleConfirm: processApproveInvoice,
      });
      return;
    }
    await processApproveInvoice();
  }, [
    setHasError,
    invoice?.number,
    invoice?.issueDate,
    invoice?.chargesAmount,
    invoice?.taxAmount,
    invoice?.total,
    invoice?.orgDuplicate,
    invoice?.vendorDuplicate,
    invoice?.poNumber,
    invoice?.release?.poNumber,
    invoice?.release?.status,
    invoice?.release?.sequenceNumber,
    release?.items,
    release?.type.poFormat,
    connectedSourceSystem,
    includePoNumbering,
    useInvoiceItemPrice,
    processApproveInvoice,
    setWarningAlert,
    intl,
    calcExtPrice,
    openDialog,
  ]);

  return {
    approve,
  };
};
