import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { TagItem } from "@/common/components/tag-picker/TagItem";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { CategoryLike } from "@/common/hooks/useToggleCategory";
import {
  MdFixedContainer,
  SmContainer,
  SmFixedContainer,
  XlContainer,
  XsContainer,
} from "@/common/layout/ResponsiveClasses";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import {
  ExternalPoItemFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { Check, InfoOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-row items-center`;
const InfoOutlinedBlue = tw(InfoOutlined)`text-blue-500 mr-2`;
const InfoOutlinedRed = tw(InfoOutlined)`text-red-500 mr-2`;
const Category = tw.div`font-medium text-sm`;
const ReadonlyField = tw.div`text-xs flex flex-col`;
const CheckIcon = tw(Check)`w-6 h-6 text-center text-xl text-green-600 mr-3`;
const Notes = tw.span`text-xs italic mt-0.5 leading-tight`;

export enum ExternalPOTableCategoryId {
  MISSING_TAG = "MISSING_TAG",
  MISSING_COST_CODES = "MISSING_COST_CODES",
  UNSUPPORTED_COST_TYPES = "UNSUPPORTED_COST_TYPES",
  INCLUDED = "INCLUDED",
}
export enum ExportPOItemType {
  DEFAULT = "DEFAULT",
  PHASE_CODE = "PHASE_CODE",
}
export type ExternalPOItem = ExternalPoItemFieldsFragment & Identity;

type Input = {
  type: ExportPOItemType;
  onImportClick?: () => void;
  importing?: boolean;
  importLabelId?: string;
  sourceSystem?: SourceSystem;
  importableItems?: boolean;
};

export const useExternalItemsPOTableConfiguration = ({
  type,
  onImportClick,
  importing,
  importLabelId,
  sourceSystem,
  importableItems = false,
}: Input): Array<GridCol<ExternalPOItem, CategoryLike>> => {
  const { formatCostCode } = useCostCodes();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const hasRemainingAmount = useMemo(() => {
    return hasFeatureInConnectedSourceSystem(
      IntegrationFeature.RemainingCostAmount,
    );
  }, [hasFeatureInConnectedSourceSystem]);

  const columns: Array<GridCol<ExternalPOItem, CategoryLike>> = [
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      group: (category) => (
        <Tooltip
          id="category"
          element={
            <Row>
              <InfoOutlinedBlue />
              <Category>{category.name}</Category>
            </Row>
          }
        >
          {category.description}
        </Tooltip>
      ),
      item: ({ item }) => (
        <>
          <If isTrue={importableItems}>
            <CheckIcon />
          </If>
          <ReadonlyField>
            <NotNullableRenderer value={item.description}>
              {item.description}
            </NotNullableRenderer>
            <Notes>{item.notes}</Notes>
          </ReadonlyField>
        </>
      ),
    },
    {
      header: <FormattedMessage id="UOM" />,
      wrapper: XsContainer,
      position: "center",
      item: ({ item }) => <ReadonlyField>{item.uom}</ReadonlyField>,
    },
    {
      header: <FormattedMessage id="RELEASE_QUANTITY" />,
      wrapper: SmContainer,
      position: "center",
      item: ({ item }) => (
        <ReadonlyField>
          <NotNullableRenderer value={!isLumpSumUomText(item.uom)}>
            <ValueUnit value={item.quantity} />
          </NotNullableRenderer>
        </ReadonlyField>
      ),
    },
    {
      header: <FormattedMessage id="UNIT_PRICE" />,
      wrapper: SmContainer,
      position: "center",
      item: ({ item }) => (
        <ReadonlyField>
          <NotNullableRenderer value={!isLumpSumUomText(item.uom)}>
            <Price price={item.unitCost} />
          </NotNullableRenderer>
        </ReadonlyField>
      ),
    },
    {
      header: <FormattedMessage id="EXT_PRICE" />,
      wrapper: SmContainer,
      position: "center",
      item: ({ item }) => (
        <ReadonlyField>
          <Price price={item.amount} />
        </ReadonlyField>
      ),
    },
    {
      header: <FormattedMessage id="REMAINING_COST" />,
      wrapper: SmContainer,
      position: "center",
      hidden: !hasRemainingAmount,
      item: ({ item }) => (
        <ReadonlyField>
          <Price price={item.remainingAmount} />
        </ReadonlyField>
      ),
    },
  ];

  if (type === ExportPOItemType.DEFAULT) {
    const defaultColumns: Array<GridCol<ExternalPOItem, CategoryLike>> = [
      {
        header: <FormattedMessage id="COST_CODE" />,
        wrapper: MdFixedContainer,
        position: "center",
        group: (category) =>
          category.id === ExternalPOTableCategoryId.MISSING_COST_CODES &&
          onImportClick ? (
            <PrimaryButton $small onClick={onImportClick} loading={importing}>
              <FormattedMessage id={importLabelId} />
            </PrimaryButton>
          ) : null,
        item: ({ item }) => (
          <ReadonlyField>
            {formatCostCode(
              item.costCode ??
                item.externalCostCode?.costCode?.id ??
                item.externalCostCode?.name,
            )}
          </ReadonlyField>
        ),
      },
      {
        header: <FormattedMessage id="COST_TYPE" />,
        wrapper: SmFixedContainer,
        position: "center",
        item: ({ item }) => (
          <ReadonlyField>{item.costType?.name}</ReadonlyField>
        ),
      },
    ];
    columns.push(...defaultColumns);
  } else {
    columns.push({
      header: <FormattedMessage id="PHASE_CODE" />,
      wrapper: MdFixedContainer,
      position: "center",
      group: (category) =>
        category.id === ExternalPOTableCategoryId.MISSING_COST_CODES ||
        (category.id === ExternalPOTableCategoryId.MISSING_TAG &&
          !!sourceSystem &&
          onImportClick) ? (
          <PrimaryButton $small onClick={onImportClick} loading={importing}>
            <FormattedMessage id={importLabelId} />
          </PrimaryButton>
        ) : null,
      item: ({ item }) => (
        <>
          <TagItem
            tag={
              item.wbsTag ?? {
                name: `${formatCostCode(item.externalCostCode?.costCode?.id ?? item.externalCostCode?.name)} - ${
                  item.costType?.code ?? ""
                }`,
                id: item.externalId,
                color: "",
                mapping: sourceSystem ? { sourceSystem } : undefined,
                hasMapping: !!sourceSystem,
              }
            }
          />
        </>
      ),
    });
  }

  return columns;
};

export const useMergedExternalItemsPOTableConfiguration = ({
  type,
  sourceSystem,
}: Pick<Input, "type" | "sourceSystem">) => {
  const { formatCostCode } = useCostCodes();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const hasRemainingAmount = useMemo(() => {
    return hasFeatureInConnectedSourceSystem(
      IntegrationFeature.RemainingCostAmount,
    );
  }, [hasFeatureInConnectedSourceSystem]);

  const columns: Array<GridCol<ExternalPoItemFieldsFragment & Identity>> = [
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <ReadonlyField>
          <NotNullableRenderer value={item.description}>
            {item.description}
          </NotNullableRenderer>
        </ReadonlyField>
      ),
    },
    {
      header: <FormattedMessage id="UOM" />,
      wrapper: XsContainer,
      position: "center",
      item: ({ item }) => <ReadonlyField>{item.uom}</ReadonlyField>,
    },
    {
      header: <FormattedMessage id="RELEASE_QUANTITY" />,
      wrapper: SmContainer,
      position: "center",
      item: ({ item }) => (
        <ReadonlyField>
          <ValueUnit value={item.quantity} />
        </ReadonlyField>
      ),
    },
    {
      header: <FormattedMessage id="UNIT_PRICE" />,
      wrapper: SmContainer,
      position: "center",
      item: ({ item }) => (
        <ReadonlyField>
          <Price price={item.unitCost} />
        </ReadonlyField>
      ),
    },
    {
      header: <FormattedMessage id="EXT_PRICE" />,
      wrapper: SmContainer,
      position: "center",
      item: ({ item }) => (
        <ReadonlyField>
          <Price price={item.amount} />
        </ReadonlyField>
      ),
    },
    {
      header: <FormattedMessage id="REMAINING_COST" />,
      wrapper: SmContainer,
      position: "center",
      hidden: !hasRemainingAmount,
      item: ({ item }) => (
        <ReadonlyField>
          <Price price={item.remainingAmount} />
        </ReadonlyField>
      ),
    },
  ];

  if (type === ExportPOItemType.DEFAULT) {
    const defaultColumns: Array<
      GridCol<ExternalPoItemFieldsFragment & Identity>
    > = [
      {
        header: <FormattedMessage id="COST_CODE" />,
        wrapper: MdFixedContainer,
        position: "center",
        item: ({ item }) => (
          <ReadonlyField>
            {formatCostCode(
              item.costCode ??
                item.externalCostCode?.costCode?.id ??
                item.externalCostCode?.name,
            )}
          </ReadonlyField>
        ),
      },
      {
        header: <FormattedMessage id="COST_TYPE" />,
        wrapper: SmFixedContainer,
        position: "center",
        item: ({ item }) => (
          <ReadonlyField>{item.costType?.name}</ReadonlyField>
        ),
      },
    ];
    columns.push(...defaultColumns);
  } else {
    columns.push({
      header: <FormattedMessage id="PHASE_CODE" />,
      wrapper: MdFixedContainer,
      position: "center",
      item: ({ item }) => (
        <>
          <InfoOutlinedRed />
          <TagItem
            tag={
              item.wbsTag ?? {
                name: `${formatCostCode(item.externalCostCode?.costCode?.id ?? item.externalCostCode?.name)} - ${
                  item.costType?.code ?? ""
                }`,
                id: item.externalId,
                color: "",
                mapping: sourceSystem ? { sourceSystem } : undefined,
                hasMapping: !!sourceSystem,
              }
            }
          />
        </>
      ),
    });
  }

  return columns;
};
