import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { AssetFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`grid grid-flow-row gap-2 mb-2 mt-2`;
const IssueDescription = tw.div`grid text-sm`;
const Assets = tw.div`grid grid-flow-col justify-items-start gap-2 mb-2 justify-start`;

type IssueContentProps = {
  description?: string | null | undefined;
  assets?: AssetFieldsFragment[] | null;
};

export const IssueContent: FC<IssueContentProps> = ({
  description,
  assets,
}) => {
  return (
    <Container>
      <IssueDescription>{description}</IssueDescription>
      <Assets>
        {assets?.map((asset, index) => (
          <AssetItem key={index} asset={asset} index={index} />
        ))}
      </Assets>
    </Container>
  );
};
