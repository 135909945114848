import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { StaticDatePicker } from "@/common/components/picker/components/StaticDatePicker";
import { areDatesEqual } from "@/common/components/picker/utils/areDatesEqual";
import { Popover } from "@/common/components/popover/Popover";
import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { MAX_QUANTITY_DECIMALS } from "@/common/const";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import {
  AlternativeFulfillmentInput,
  DistributorReleaseFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { getReleaseTime } from "../utils/getReleaseTime";

const Container = tw.div`flex flex-col justify-between gap-4 w-96 ${({
  $className,
}: {
  $className?: string;
}) => $className}`;
const Content = tw.div`flex gap-4 flex-col`;
const CustomPickersDay = tw(
  PickersDay as React.ComponentType<PickersDayProps<Date>>,
)` border-solid border-4 
  ${({ $selected }: { $selected?: boolean }) =>
    $selected && "bg-blue-500 text-white rounded-full hover"}`;
const PickersDayStyled = tw(
  PickersDay as React.ComponentType<PickersDayProps<Date>>,
)`
  ${({ $selected }: { $selected?: boolean }) => $selected && "bg-blue-800"}
`;

const ButtonContainer = tw.div`flex flex-row justify-around w-full gap-2`;
const OutlinedButtonStyled = tw(OutlinedButton)`w-full`;
const PrimaryButtonStyled = tw(PrimaryButton)`w-full`;
const StaticDatePickerStyled = tw(
  StaticDatePicker,
)`border-dashed border-b -mx-6`;
const BackorderQuantityContainer = tw.div`flex flex-col bg-gray-100 rounded text-sm gap-4 p-5 text-blue-800`;
const BackorderQuantityItem = tw.div`flex flex-row w-full justify-between items-center `;
const SwitchContainer = tw.div`flex flex-row justify-evenly gap-3`;
const QuantityPickerStyled = tw(QuantityPicker)`bg-white`;

type Props = {
  item?: DistributorReleaseItemFieldsFragment;
  release: DistributorReleaseFieldsFragment | undefined | null;
  onSave: (time: AlternativeFulfillmentInput) => Promise<void>;
  onChange?: (time: AlternativeFulfillmentInput) => void;
  className?: string;
  withoutBottomButtons?: boolean;
  selectedFulfillmentAlternative?: AlternativeFulfillmentInput | null;
  includeReleaseOptions?: boolean;
  useReleaseTimeAsDefault?: boolean;
  useCurrentDayAsDefault?: boolean;
  includeBackorderQuantity?: boolean;
  labels?: {
    newInput?: string;
    timeHeader?: string;
  };
};

export const DistributorReleaseDatePicker: FC<Props> = ({
  item,
  release,
  onSave,
  onChange,
  className,
  withoutBottomButtons = false,
  selectedFulfillmentAlternative = null,
  includeReleaseOptions = true,
  useReleaseTimeAsDefault = true,
  useCurrentDayAsDefault = true,
  includeBackorderQuantity = true,
}) => {
  const initialDate = useMemo(() => {
    const alternativeRelease = release?.project?.releases.find(
      (r) => r.id === selectedFulfillmentAlternative?.releaseId,
    );
    if (selectedFulfillmentAlternative?.time) {
      return new Date(selectedFulfillmentAlternative.time);
    } else if (alternativeRelease) {
      return new Date(alternativeRelease?.time || 0);
    } else if (useReleaseTimeAsDefault && item?.deliveryDate) {
      return new Date(item.deliveryDate);
    } else if (useReleaseTimeAsDefault && release?.time) {
      return new Date(release.time);
    } else if (useCurrentDayAsDefault) {
      return defaultReleaseDate();
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [date, setDate] = useState<Date | null>(initialDate);
  const [alternativeFulfillmentQuantity, setAlternativeFulfillmentQuantity] =
    useState<string | null>(item?.quantityDecimal || null);
  const [backorderEntireQuantity, setBackorderEntireQuantity] =
    useState<boolean>(!item?.quantityDecimal);

  const intl = useIntl();
  const { cancelDialog } = useDialog();
  const handleChange = useCallback(
    ({ date = null }: { date?: Date | null }) => {
      setDate(date);
      onChange?.({ time: date?.getTime() });
    },
    [setDate, onChange],
  );

  const getReleases = useCallback(() => {
    return release?.buyout
      ? release.buyout.releases.map((rel) => ({
          ...rel,
          buyout: {
            id: release.buyout?.id,
            clientIdentifier: release.buyout?.clientIdentifier,
          },
        }))
      : release?.project?.releases.map((rel) => ({
          ...rel,
          buyout: {
            id: rel.buyout?.id,
            clientIdentifier: rel.buyout?.clientIdentifier,
          },
        }));
  }, [release?.buyout, release?.project?.releases]);

  const onConfirm = useCallback(async () => {
    const time = {
      time: date ? defaultReleaseDate(date).getTime() : undefined,
      quantityDecimal: alternativeFulfillmentQuantity,
    };

    await onSave(time);
    cancelDialog?.();
  }, [date, alternativeFulfillmentQuantity, onSave, cancelDialog]);

  const renderDay = useCallback(
    ({ day, ...other }: PickersDayProps<Date>) => {
      if (
        !includeReleaseOptions ||
        !getReleases()?.find(
          (r) =>
            r.id !== release?.id &&
            checkReleaseStatus(r, [
              ReleaseStatus.Scheduled,
              ReleaseStatus.Requested,
            ]) &&
            areDatesEqual(getReleaseTime(r), day) &&
            (!date || !areDatesEqual(getReleaseTime(r), date)),
        )
      ) {
        return <PickersDayStyled day={day} {...other} />;
      }
      const key = `day-${day.getDay()}`;
      return (
        <Popover
          id={key}
          key={key}
          element={<CustomPickersDay day={day} {...other} $selected={true} />}
        >
          <FormattedMessage id="RELEASE_ADD_TO_EXISTING_DATE" />
        </Popover>
      );
    },
    [date, getReleases, includeReleaseOptions, release?.id],
  );

  const setIsBackorderedEntireQuantity = useCallback(
    (value: boolean) => {
      setBackorderEntireQuantity(value);
      if (value) {
        setAlternativeFulfillmentQuantity(null);
      }
    },
    [setBackorderEntireQuantity, setAlternativeFulfillmentQuantity],
  );

  return (
    <Container $className={className}>
      <Content>
        <StaticDatePickerStyled
          value={date}
          onChange={(date?: Date | null) => handleChange({ date })}
          slots={{
            day: renderDay,
          }}
        />
        <If isTrue={includeBackorderQuantity}>
          <BackorderQuantityContainer>
            <BackorderQuantityItem>
              <FormattedMessage id="BACKORDER_ENTIRE_QUANTITY" />
              <SwitchContainer>
                <InnerLabeledSwitch
                  toggle={(value) => setIsBackorderedEntireQuantity(value)}
                  width={60}
                  initialValue={backorderEntireQuantity}
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                />
              </SwitchContainer>
            </BackorderQuantityItem>
            <If isTrue={!backorderEntireQuantity}>
              <BackorderQuantityItem>
                <FormattedMessage id="BACKORDERED_QUANTITY" />
                <QuantityPickerStyled
                  id="backorder-quantity-picker"
                  quantity={alternativeFulfillmentQuantity ?? "0"}
                  saveQuantity={setAlternativeFulfillmentQuantity}
                  label={
                    item?.quantityDecimal
                      ? intl.$t(
                          {
                            id: "ORDERED_QUANTITY_VALUE",
                          },
                          {
                            quantity: intl.formatNumber(
                              Number(item?.quantityDecimal) || 0,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: MAX_QUANTITY_DECIMALS,
                              },
                            ),
                          },
                        )
                      : undefined
                  }
                  hoverLabel={item?.uom?.pluralDescription}
                />
              </BackorderQuantityItem>
            </If>
          </BackorderQuantityContainer>
        </If>
      </Content>
      <If isTrue={!withoutBottomButtons}>
        <ButtonContainer>
          <OutlinedButtonStyled wide onClick={cancelDialog}>
            <FormattedMessage id="CANCEL" />
          </OutlinedButtonStyled>
          <LoadingButton
            button={PrimaryButtonStyled}
            wide
            onClick={onConfirm}
            disabled={new Decimal(item?.quantityDecimal || 0).lessThan(
              alternativeFulfillmentQuantity || 0,
            )}
          >
            <FormattedMessage id="CONFIRM" />
          </LoadingButton>
        </ButtonContainer>
      </If>
    </Container>
  );
};
