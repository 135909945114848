import { LocalPaginationProvider } from "@/common/components/pagination-local/LocalPaginationProvider";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { Switch } from "@/common/components/switch/Switch";
import {
  ExternalMaterialCostTypesProvider,
  useExternalMaterialCostTypes,
} from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/material-cost-type/ExternalMaterialCostTypesProvider";
import { SourceSystem } from "@/generated/graphql";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportExternalCostTypes } from "../../../providers/ImportExternalCostTypesProvider";
import { useImportExternalCostTypesTableConfiguration } from "./ImportExternalCostTypesTable.configuration";

const SearchBarTitleComponent = tw.div`flex flex-col pr-2`;
const ToggleText = tw.div`flex flex-row font-normal text-sm items-center mt-1`;

const ImportExternalCostTypesTableWithProviders = () => {
  const intl = useIntl();
  const {
    sourceSystem,
    selectedExternalCostTypes,
    totalExternalCostTypesCount,
    setSelectedExternalCostTypes,
    setTotalExternalCostTypesCount,
    importAllExternalItems,
    setImportAllExternalItems,
  } = useImportExternalCostTypes();
  const { externalCostTypes, loadingExternalCostTypes } =
    useExternalMaterialCostTypes();
  const configuration = useImportExternalCostTypesTableConfiguration(
    selectedExternalCostTypes,
  );

  useEffect(() => {
    setTotalExternalCostTypesCount(externalCostTypes.length);
  }, [externalCostTypes, setTotalExternalCostTypesCount]);

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      items={externalCostTypes}
      loadingItems={loadingExternalCostTypes}
      searchBarTitle={
        <SearchBarTitleComponent>
          <FormattedMessage id="SELECT_COST_TYPES_TO_IMPORT" />
          <ToggleText>
            <FormattedMessage
              id="IMPORT_ALL_COST_TYPES_FROM_SYSTEM"
              values={{
                system: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
              }}
            />
            <Switch
              className="ml-2"
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              value={importAllExternalItems}
              onChange={(value) => {
                setImportAllExternalItems(value);
                setSelectedExternalCostTypes(
                  value ? externalCostTypes.map((i) => i.id) : [],
                );
              }}
            />
          </ToggleText>
        </SearchBarTitleComponent>
      }
      searchKeys={["code", "name", "description"]}
      itemSelection={{
        type: "multiple",
        selectedItemIds: selectedExternalCostTypes,
        setSelectedItemIds: (ids: string[]) => {
          setImportAllExternalItems(ids.length === totalExternalCostTypesCount);
          setSelectedExternalCostTypes(ids);
        },
      }}
    />
  );
};

export const ImportExternalCostTypesTable: FC<{
  defaultSourceSystem?: SourceSystem;
}> = ({ defaultSourceSystem }) => {
  return (
    <LocalPaginationProvider>
      <ExternalMaterialCostTypesProvider
        defaultSourceSystem={defaultSourceSystem}
      >
        <ImportExternalCostTypesTableWithProviders />
      </ExternalMaterialCostTypesProvider>
    </LocalPaginationProvider>
  );
};
