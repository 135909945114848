import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue, removeValue, setValue } from "@/common/utils/localStorage";
import { routes } from "@/config/routes";
import {
  InvoiceStatus,
  ReceiptSummaryFieldsFragment,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

type ReceiptSummary = Pick<
  ReceiptSummaryFieldsFragment,
  | "number"
  | "createdAt"
  | "total"
  | "release"
  | "issueDate"
  | "project"
  | "prePaid"
  | "status"
> & {
  link: {
    failedAt?: number | null;
  };
  folders?: { name: string }[];
  createdBy: {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
  };
};

type ReceiptSequenceCsvData = {
  group: string;
  number: string | null | undefined;
  createdAt: number | null | undefined;
  createdBy: {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
  };
  issueDate?: number | null | undefined;
  total: string | null | undefined;
  release?: {
    sequenceNumber: number | null | undefined;
    poNumber: string | null | undefined;
    type: {
      name: string | null | undefined;
    };
    project?: {
      jobNumber: string | null | undefined;
    };
    sellerOrgLocation?: {
      org?: {
        name: string | null | undefined;
      };
    };
  };
  folders?: {
    id: string;
    name: string;
  }[];
};

export type ReceiptSequenceData = ReceiptSequenceCsvData & {
  id: string;
  version?: number;
  status: InvoiceStatus;
  link?: {
    failedAt?: number | null;
  };
  prePaid?: boolean;
};

type Params = {
  navigateToReceipts?: boolean;
  markSequenceIdAsApproved?: string;
};

type ProviderContextType = {
  step: number;
  setStep: (step: number) => void;
  sequenceIds: string[];
  selectedReceipts: ReceiptSequenceData[];
  setSelectedReceipts: (receipts: ReceiptSequenceData[]) => void;
  sequenceActive: boolean;
  startSequence: () => void;
  stopSequence: () => void;
  navigateToNextSequence: (params?: Params) => void;
  redirectToReceipts: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  step: 0,
  setStep: NoFunction,
  sequenceIds: [],
  selectedReceipts: [],
  setSelectedReceipts: NoFunction,
  sequenceActive: false,
  startSequence: NoFunction,
  stopSequence: NoFunction,
  navigateToNextSequence: NoFunction,
  redirectToReceipts: NoFunction,
});

export const ReceiptsSequenceProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const receiptCsvDataToSequence = useCallback(
    (receipt: ReceiptSummary): ReceiptSequenceCsvData => {
      return {
        group: (receipt.folders || []).map((f) => f.name).join(", "),
        createdBy: {
          firstName: receipt.createdBy?.firstName,
          lastName: receipt.createdBy?.lastName,
        },
        createdAt: receipt.createdAt,
        issueDate: receipt.issueDate,
        release: {
          sequenceNumber: receipt.release?.sequenceNumber,
          poNumber: receipt.release?.poNumber,
          type: {
            name: receipt.release?.type?.name,
          },
          project: {
            jobNumber:
              receipt.release?.project?.jobNumber ?? receipt.project?.jobNumber,
          },
          sellerOrgLocation: {
            org: {
              name: receipt.release?.sellerOrgLocation?.org?.name,
            },
          },
        },
        number: receipt.number,
        total: receipt.total,
      };
    },
    [],
  );

  const receiptToSequence = useCallback(
    (receipt: ReceiptSequenceData): ReceiptSequenceData => ({
      id: receipt.id,
      status: receipt.status,
      link: receipt.link ? { failedAt: receipt.link?.failedAt } : undefined,
      prePaid: receipt.prePaid,
      folders: receipt.folders,
      ...receiptCsvDataToSequence(receipt as ReceiptSummary),
    }),
    [receiptCsvDataToSequence],
  );

  const [selectedReceipts, setSelectedReceipts] = useState<
    ReceiptSequenceData[]
  >(
    readValue(
      LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SELECTED_INVOICES,
      [],
    ) as ReceiptSequenceData[],
  );
  const [step, setStep] = useState(
    readValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_STEP, 0) as number,
  );
  const [sequenceActive, setSequenceActive] = useState(
    readValue(
      LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_ACTIVE,
      false,
    ) as boolean,
  );

  const setSelectedReceiptsAndUpdateLocalStorage = useCallback(
    (receipts: ReceiptSequenceData[]) => {
      setSelectedReceipts(receipts.map(receiptToSequence));
      setValue(
        LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SELECTED_INVOICES,
        receipts,
      );
    },
    [receiptToSequence],
  );

  const setSequenceActiveAndUpdateLocalStorage = useCallback(
    (active: boolean) => {
      setSequenceActive(active);
      setValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_ACTIVE, active);
    },
    [],
  );

  const setStepAndUpdateLocalStorage = useCallback((step: number) => {
    setStep(step);
    setValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_STEP, step);
  }, []);

  const startSequence = useCallback(() => {
    setStepAndUpdateLocalStorage(0);
    setSelectedReceiptsAndUpdateLocalStorage(selectedReceipts);
    setSequenceActiveAndUpdateLocalStorage(true);
    setValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_ACTIVE, true);
    const sequenceStep = readValue(
      LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_STEP,
      0,
    ) as number;
    const firstReceipt = selectedReceipts[sequenceStep];
    if (firstReceipt) {
      setValue(
        LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.REDIRECT_ROUTE,
        location.search,
      );
      navigate(
        generatePath(routes.processReceipt, {
          receiptId: firstReceipt.id,
        }),
      );
    }
  }, [
    location.search,
    navigate,
    selectedReceipts,
    setSelectedReceiptsAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const stopSequence = useCallback(() => {
    setSelectedReceiptsAndUpdateLocalStorage([]);
    setSequenceActiveAndUpdateLocalStorage(false);
    setStepAndUpdateLocalStorage(0);
    removeValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.REDIRECT_ROUTE);
    removeValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_STEP);
  }, [
    setSelectedReceiptsAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const sequenceIds = useMemo(
    () => selectedReceipts.map((i) => i.id),
    [selectedReceipts],
  );

  const redirectToReceipts = useCallback(() => {
    const redirectParams = readValue(
      LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.REDIRECT_ROUTE,
      "",
    ) as string | undefined;
    navigate(generatePath(`${routes.receipts}${redirectParams}`));
  }, [navigate]);

  const navigateToNextSequence = useCallback(
    (
      { markSequenceIdAsApproved, navigateToReceipts }: Params = {
        navigateToReceipts: false,
        markSequenceIdAsApproved: undefined,
      },
    ) => {
      if (sequenceActive) {
        if (markSequenceIdAsApproved) {
          setSelectedReceiptsAndUpdateLocalStorage(
            selectedReceipts.map((i) => {
              if (i.id === markSequenceIdAsApproved) {
                return {
                  ...i,
                  status: InvoiceStatus.Approved,
                };
              }
              return i;
            }),
          );
        }
        if (sequenceIds.length === step + 1) {
          redirectToReceipts();
        } else {
          setStepAndUpdateLocalStorage(step + 1);
          navigate(
            generatePath(routes.processReceipt, {
              receiptId: sequenceIds[step + 1],
            }),
          );
        }
      } else if (navigateToReceipts) {
        redirectToReceipts();
      }
    },
    [
      sequenceActive,
      sequenceIds,
      step,
      setSelectedReceiptsAndUpdateLocalStorage,
      selectedReceipts,
      redirectToReceipts,
      setStepAndUpdateLocalStorage,
      navigate,
    ],
  );

  const setSelectedReceiptsAndSaveToLocalStorage = useCallback(
    (invoices: ReceiptSequenceData[], saveToLocalStorage = false) => {
      setSelectedReceipts(invoices.map(receiptToSequence));
      if (saveToLocalStorage) {
        setValue(
          LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SELECTED_INVOICES,
          invoices,
        );
        if (invoices.length > 1) {
          setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE, true);
          setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP, 0);
        }
      }
    },
    [receiptToSequence],
  );

  return (
    <ProviderContext.Provider
      value={{
        step,
        setStep: setStepAndUpdateLocalStorage,
        sequenceIds,
        selectedReceipts,
        setSelectedReceipts: setSelectedReceiptsAndSaveToLocalStorage,
        sequenceActive,
        startSequence,
        stopSequence,
        navigateToNextSequence,
        redirectToReceipts,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptSequence = (): ProviderContextType =>
  useContext(ProviderContext);
