import { BuyoutAdditionalChargesAndTaxes } from "@/common/components/buyout-additional-chatrges-and-taxes/BuyoutAdditionalChargesAndTaxes";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useInstruction } from "@/common/hooks/useInsruction";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { BuyoutDescription } from "@/contractor/pages/home/buyout/components/document/components/BuyoutDescription";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { UpdateContractorBuyoutInput } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useCreateBuyoutFromQuoteConfig } from "./CreateBuyoutFromQuote.config";
import { CreateBuyoutFromQuoteFormValues } from "./CreateBuyoutFromQuoteForm";
import { CreateBuyoutFromQuoteHeader } from "./CreateBuyoutFromQuoteHeader";

const Header = tw.div`grid pb-3 pl-1 text-base font-medium`;
const Container = tw.div`bg-gray-100 rounded-3xl pt-4 px-4 h-fit overflow-y-scroll`;
const InnerContainer = tw.div`flex flex-col h-full`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const Footer = tw.div`mt-10 w-full pb-5`;

export const CreateBuyoutFromQuoteDetails: FC = () => {
  const { quoteDocument, loading } = useQuoteDocument();
  const { findMaterialByName, calcTableTotal } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const { calcExtPrice } = usePriceCalculation();
  const { defaultOrderType } = useOrderTypeOptions();
  const { buyout } = useContractorBuyout();
  const spreadsheetViewColumns = useCreateBuyoutFromQuoteConfig();
  const { setValue, getValues, watch } =
    useFormContext<CreateBuyoutFromQuoteFormValues>();
  const [searchParams] = useSearchParams();
  const { saveDescription, saveInstruction } = useInstruction();

  useEffect(() => {
    if (buyout?.taxRate) {
      setValue("taxRate", buyout?.taxRate);
    }
  }, [buyout?.taxRate, setValue]);

  const projectId = watch("projectId");
  const customTaxAmount = watch("customTaxAmount");
  const netAmount = watch("netAmount");
  const orderTypeId = watch("releaseTypeId");
  const taxRate = watch("taxRate");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const additionalChargesAllowance = watch("additionalChargesAllowance");

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = `${calcTableTotal(data)}`;
      if (newTotal !== netAmount) {
        setValue("netAmount", newTotal);
      }
    },
    [calcTableTotal, setValue, netAmount],
  );

  useEffect(() => {
    setValue(
      "businessLocationId",
      buyout?.project?.location?.id ?? quoteDocument?.orgLocation?.id ?? "",
    );
    setValue(
      "projectId",
      buyout?.project?.id ??
        quoteDocument?.predictedProject?.id ??
        searchParams.get("projectId") ??
        "",
    );
    setValue(
      "releaseTypeId",
      buyout?.releaseType.id ?? defaultOrderType?.id ?? "",
    );
    setValue(
      "vendorId",
      buyout?.sellerOrgLocation?.id ??
        quoteDocument?.predictedSellerOrgLocation?.id ??
        "",
    );
    setValue("paymentTerm", quoteDocument?.paymentTerm ?? undefined);
    setValue(
      "customTaxAmount",
      buyout?.taxAmount || quoteDocument?.taxAmount || "",
    );
    setValue("poNumber", getValues("poNumber") || buyout?.poNumber || "");
    setValue("description", buyout?.description);
    setValue(
      "vendorContactIds",
      getValues("vendorContactIds") ||
        buyout?.vendorContacts?.map((vendorContact) => vendorContact.id) ||
        null,
    );
  }, [
    quoteDocument,
    setValue,
    searchParams,
    buyout,
    defaultOrderType?.id,
    getValues,
  ]);

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const { calcTotalPrice } = usePriceCalculation();
  const total = useMemo(
    () =>
      calcTotalPrice({
        netAmount,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
        additionalChargesAllowance,
      }),
    [
      calcTotalPrice,
      netAmount,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      additionalChargesAllowance,
    ],
  );

  const tableItems = useMemo(() => {
    if (buyout?.items) {
      return buyout.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => {
          const matchingOrgMaterial = findMaterialByName(item.description);
          return {
            id: item.id,
            description: item.description ?? "",
            quantityDecimal: item.quantityDecimal ?? "",
            material: matchingOrgMaterial,
            costCode: item?.costCode?.code ?? undefined,
            manufacturer:
              item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
            UOM:
              item.projectItem.estimateUom.mnemonic ??
              matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
              matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
            unitPrice: item.unitPrice ?? "",
            requestedUnitPrice: item.requestedUnitPrice ?? "",
            extPrice: calcExtPrice(item.quantityDecimal, item.unitPrice),
            tags: item.tags,
            notes: item.instructions?.text ?? "",
          };
        });
    }

    if (!quoteDocument?.items) {
      return [];
    }

    return quoteDocument.items.map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description ?? "");
      return {
        id: item.id,
        quantityDecimal: item.quantity ?? "",
        description: item.description ?? "",
        material: matchingOrgMaterial,
        costCode: matchingOrgMaterial?.costCode?.code ?? undefined,
        manufacturer:
          item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
        UOM:
          item.uom ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        unitPrice: item.unitPrice ?? "",
        requestedUnitPrice: "",
        extPrice: calcExtPrice(item.quantity, item.unitPrice),
        tags: [],
        notes: "",
      };
    });
  }, [findMaterialByName, quoteDocument?.items, calcExtPrice, buyout?.items]);

  const handleUpdateBuyout = (values: UpdateContractorBuyoutInput) => {
    if (values.customTaxAmount) {
      setValue("customTaxAmount", values.customTaxAmount || "0");
      setValue("taxRate", undefined);
    }
    if (values.clearCustomTaxAmount !== undefined) {
      setValue("clearCustomTaxAmount", !!values.clearCustomTaxAmount);
    }
    if (
      values.customTaxAmount ||
      (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
    ) {
      setValue("customTaxAmount", values.customTaxAmount || "");
      setValue("taxRate", undefined);
    }
    if (values.taxRate || values.clearCustomTaxAmount) {
      setValue("customTaxAmount", undefined);
      setValue("taxRate", values.taxRate || "");
    }
    if (values.taxCodeId) {
      setValue("taxCodeId", values.taxCodeId);
    }
    if (values.taxType) {
      setValue("taxType", values.taxType);
    }
    if (values.releaseTypeId) {
      setValue("releaseTypeId", values.releaseTypeId);
    }
    if (values.additionalChargesAllowance) {
      setValue("additionalChargesAllowance", values.additionalChargesAllowance);
    }

    return Promise.resolve(true);
  };

  return (
    <Container>
      <FormattedMessage id="NEW_BUYOUT" tagName={Header} />
      <CreateBuyoutFromQuoteHeader />
      <InnerContainer>
        <SpreadSheetView>
          <SpreadSheetTable
            items={tableItems}
            columns={spreadsheetViewColumns}
            saving={loading}
            height="550px"
            preventUpdatedOnChanges
            onChanges={updateSubtotal}
          />
        </SpreadSheetView>
        <If isTrue={projectId}>
          <Instructions
            instruction={buyout?.instructions}
            projectId={projectId}
            saveInstruction={saveInstruction}
          >
            <BuyoutDescription
              buyout={buyout}
              saveDescription={saveDescription}
              batchSave
            />
          </Instructions>
        </If>
      </InnerContainer>
      <Footer>
        <BuyoutAdditionalChargesAndTaxes
          buyoutInput={{
            orderTypeId,
            taxRate,
            customTaxAmount,
            netAmount,
            taxType,
            taxCodeId,
            chargesAmount: additionalChargesAllowance,
          }}
          total={`${total}`}
          buyout={buyout}
          updateBuyout={handleUpdateBuyout}
          includeAdditionalChargesAllowance={!!buyout}
        />
      </Footer>
    </Container>
  );
};
