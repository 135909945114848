import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../store/useProjectStore";

export const useProjectPhaseCodes = () => {
  const { phaseCodes } = useProjectStore(
    useShallow((state) => ({ phaseCodes: state.phaseCodes })),
  );

  return { phaseCodes };
};
