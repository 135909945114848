import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useGetProjectItemFromSku } from "@/common/hooks/useGetProjectItem";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { PROJECT } from "@/contractor/pages/home/project/queries/project";
import {
  AddEstimatedItemInput,
  UomsDocument,
  UpdateEstimatedItemInput,
  useUpdateEstimatedItemsMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useProjectMaps } from "../hooks/useProjectMaps";
import { useProjectZones } from "../hooks/useProjectZones";
import { useProject } from "./ProjectProvider";

type ProviderContextType = {
  syncEstimatedItems: (trigger?: SpreadsheetSaveType) => Promise<boolean>;
  saving: boolean;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncEstimatedItems: NoFunctionBooleanPromise,
  saving: false,
});

export const SyncEstimatedItemsProvider: FC<Props> = ({ children }) => {
  const { manufacturers } = useManufacturers();
  const {
    spreadsheetData,
    getRemovedRowIds,
    rowHasChanges,
    resetPreviousData,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    addMissingMaterials,
    addMissingManufacturers,
    addMissingZones,
    addMissingTags,
    getCellWithAdditionalData,
    getRowTagIds,
    findMaterialByName,
    getCostCodeId,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { project } = useProject();
  const { estimatedItemsMap } = useProjectMaps(project);
  const { setError } = useGlobalError();
  const [updateEstimatedItems, { loading: updating }] =
    useUpdateEstimatedItemsMutation();
  const { materials } = useMaterials();
  const viewState = useTableViewStore((state) => state.viewState);
  const [saving, setSaving] = useState(false);
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const { zones } = useProjectZones();
  const { hasPhaseCodes } = useOrgSettings();
  const getProjectItem = useGetProjectItemFromSku();

  useEffect(() => {
    setSaving(updating);
  }, [updating]);

  const syncEstimatedItems = async (trigger?: SpreadsheetSaveType) => {
    if (viewState !== TableViewState.spreadsheet) {
      return true;
    }

    if (!project) {
      return false;
    }

    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.PositiveQuantity,
      ])) ||
      !(await validateRowValues([
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ]))
    ) {
      gotoInvalidRow();
      return false;
    }

    const items =
      project.items
        .map((pi) =>
          pi.estimatedItems.map((ei) => ({
            ...pi,
            ...estimatedItemsMap.get(ei.id),
          })),
        )
        .flat() || [];

    const newItems: AddEstimatedItemInput[] = [];
    const itemsToUpdate: UpdateEstimatedItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(items ?? []);

    setSaving(true);
    const newManufacturers = (await addMissingManufacturers()) || [];
    const newMaterials = (await addMissingMaterials(newManufacturers)) || [];
    const newZones = (await addMissingZones(project.id)) || [];
    await addMissingTags(project.id);
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      const material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);
      if (!material || rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }

      const uom = getCellValue(row, COLUMN_TYPE.UOM);
      const quantityDecimal = getCellValue(row, COLUMN_TYPE.PositiveQuantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.PrefilledPrice);
      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;

      const zone = [...(zones || []), ...newZones].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );
      const manufacturer = [...manufacturers, ...newManufacturers].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );

      const existingItem = items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.material.id === material.id;
      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          itemsToUpdate.push({
            id: row.id,
            quantityDecimal,
            unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
            clearUnitPrice: !hasUnitPrice,
            uom,
            clearManufacturer: !manufacturer,
            manufacturerId: manufacturer?.id,
            zoneId: zone?.id,
            position: index,
            tags: getRowTagIds(row),
            costCodeId: getCostCodeId(row),
            clearCostCode: !getCostCodeId(row),
            clearZone: !zone,
          });
        }
      } else {
        newItems.push({
          item: getProjectItem(material, uom),
          quantityDecimal,
          unitPrice: unitPrice ? String(unitPrice) : undefined,
          manufacturerId: manufacturer?.id,
          zoneId: zone?.id,
          tags: getRowTagIds(row),
          position: index,
          costCodeId: getCostCodeId(row),
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    if (itemsToRemove && itemsToRemove.length > 50) {
      setSuccessAlert(intl.$t({ id: "SAVING" }));
    }

    if (
      itemsToUpdate.length > 0 ||
      newItems.length > 0 ||
      itemsToRemove.length > 0
    ) {
      try {
        const { errors } = await updateEstimatedItems({
          variables: {
            input: {
              projectId: project?.id ?? "",
              updates: itemsToUpdate,
              addedItems: newItems,
              removedItems: itemsToRemove,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: PROJECT,
              variables: { id: project?.id, excludePhantoms: true },
            },
            {
              query: UomsDocument,
            },
          ],
        });

        setError(errors);
        if (!errors) {
          resetPreviousData();
          setSuccessAlert(intl.$t({ id: "ESTIMATED_ITEMS_SAVED_SUCCESS" }));
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    } else if (trigger === SpreadsheetSaveType.SaveButton) {
      setSuccessAlert(intl.$t({ id: "NOTHING_TO_SAVE" }));
    }
    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncEstimatedItems,
        saving,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncEstimatedItems = (): ProviderContextType =>
  useContext(ProviderContext);
