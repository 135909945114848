import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

interface AssetDateProps {
  date?: number;
  className?: string;
}

const DateContainer = tw.div`text-gray-600 text-[0.675rem] text-center`;

export const AssetDate = ({ date, className }: AssetDateProps) => {
  return (
    <If isTrue={date}>
      <DateContainer className={className}>
        <DateView date={date} options={SHORT_DATE_OPTION} />
      </DateContainer>
    </If>
  );
};
