import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { ProjectCostCodeFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";

export const getSpendingReportCostCodeOptions = (
  spendingReportCostCodes: (Identity | null | undefined)[],
  allCostCodes: ProjectCostCodeFieldsFragment[],
): ProjectCostCodeFieldsFragment[] =>
  allCostCodes.filter((costCode) =>
    spendingReportCostCodes.some(
      (spendingReportCostCode) =>
        spendingReportCostCode?.id === costCode.id ||
        (!spendingReportCostCode && costCode.id === UNSPECIFIED_COST_CODE_ID),
    ),
  );
