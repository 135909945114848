import Loader from "@/assets/images/grid.svg?react";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const LoaderContainer = tw.div`
  absolute px-8 py-4 bg-gray-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
  grid place-items-center rounded-lg shadow-lg gap-2 text-sm
`;

const LoaderStyled = tw(Loader)`
  w-7 h-7 text-blue-500
`;

type Props = {
  loading: boolean;
  saving?: boolean;
};

export const GridLoader: FC<Props> = ({ loading, saving }) => {
  if (!loading && !saving) {
    return null;
  }

  return (
    <LoaderContainer>
      <LoaderStyled />
      <FormattedMessage id={loading ? "LOADING_ITEMS" : "SAVING_CHANGES"} />
    </LoaderContainer>
  );
};
