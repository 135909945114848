import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";
import {
  ExternalGeneralLedgerAccountsProvider,
  ExternalLedgerAccountType,
  useExternalGeneralLedgerAccounts,
} from "./ExternalGeneralLedgerAccountsProvider";
import { useGeneralLedgerTableConfiguration } from "./GeneralLedgerTable.configuration";

type Props = {
  onChange?: (value?: ExternalLedgerAccountType | null) => void;
  title?: string;
};

const ToJobGeneralLedgerAccountWithProviders: FC<Props> = ({
  onChange,
  title,
}) => {
  const intl = useIntl();
  const { externalLedgerAccounts, loadingExternalLedgerAccounts } =
    useExternalGeneralLedgerAccounts();
  const { selectedToJobLedgerAccount, setSelectedToJobLedgerAccount } =
    useAgaveHostedWizard();
  const configuration = useGeneralLedgerTableConfiguration(
    selectedToJobLedgerAccount ? [selectedToJobLedgerAccount] : [],
  );
  useEffect(() => {
    if (selectedToJobLedgerAccount && onChange) {
      onChange(
        externalLedgerAccounts.find(
          (i) => i.externalId === selectedToJobLedgerAccount,
        ),
      );
    }
  }, [selectedToJobLedgerAccount, externalLedgerAccounts, onChange]);

  return (
    <LocalSearchableList<ExternalLedgerAccountType>
      tableConfiguration={configuration}
      items={externalLedgerAccounts}
      loadingItems={loadingExternalLedgerAccounts}
      searchBarTitle={
        title ??
        intl.$t({
          id: "SELECT_GENERAL_LEDGER_ACCOUNT_NUMBER_TO_JOB",
        })
      }
      searchKeys={["number", "name"]}
      itemSelection={{
        type: "single",
        selectedItemId: selectedToJobLedgerAccount,
        setSelectedItemId: setSelectedToJobLedgerAccount,
        setSelectedItem: onChange,
      }}
    />
  );
};

export const ToJobGeneralLedgerAccount: FC<Props> = (props) => (
  <ExternalGeneralLedgerAccountsProvider>
    <ToJobGeneralLedgerAccountWithProviders {...props} />
  </ExternalGeneralLedgerAccountsProvider>
);
