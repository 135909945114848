import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterItemInputContainer,
  FooterPropsWithTaxes,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { BuyoutStatus } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../../document/providers/BuyoutGroupedProvider";
import { BuyoutInlineAllowance } from "./BuyoutInlineAllowance";

const marginRightClass = "pr-4";
const SalesTax = tw.div`font-light`;

export const BuyoutTotalFooter: FC<FooterPropsWithTaxes> = ({
  Wrapper,
  includeAdditionalCharges,
}) => {
  const { salesTax, netAmount, total } = useBuyoutGrouped();
  const { buyout } = useContractorBuyout();
  const isActiveOrDeclined = useMemo(
    () =>
      buyout?.status === BuyoutStatus.Active ||
      buyout?.status === BuyoutStatus.Declined,
    [buyout?.status],
  );

  return (
    <FooterCol>
      <Wrapper className="flex-col items-end justify-end">
        <If isTrue={includeAdditionalCharges}>
          <FooterItemInputContainer>
            <If isTrue={!isActiveOrDeclined}>
              <BuyoutInlineAllowance />
            </If>
          </FooterItemInputContainer>
        </If>
        <FooterHeader className={marginRightClass}>
          <If isTrue={isActiveOrDeclined}>
            <FormattedMessage id="TOTAL" tagName={FooterHeaderText} />
          </If>
        </FooterHeader>
        <FooterCell className={marginRightClass}>
          <Price
            price={netAmount}
            className="font-light"
            testId="totalSubtotal"
          />
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={salesTax}>
            <SalesTax data-testid="totalSalesTax">{salesTax}</SalesTax>
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal className="pr-0">
          <FooterCellCol className={marginRightClass}>
            <Price price={total} testId="totalTotal" />
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
