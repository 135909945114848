import tw from "tailwind-styled-components";

export const HeaderContainer = tw.div`
    flex bg-gray-100 lg:bg-white sticky pb-12 pt-5 z-20 border-gray-200 
    space-x-1 justify-end
    xl:top-[15px] top-0
`;

export const AdminHeaderContainer = tw(
  HeaderContainer,
)`top-1 xl:top-1 min-h-20 pb-5`;

export const HeaderContainerWithSecondaryNavigation = tw(HeaderContainer)`
    grid grid-flow-col gap-5 items-center justify-between lg:bg-gray-100 top-[57px] xl:top-[57px]
`;
