import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { QuotePriceWithIndex } from "@/contractor/pages/home/rfq-quotes/providers/BidsPricesProvider";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { PricePositionRank } from "./PricePositionRank";
import { WarningIconWithDetails } from "./WarningIconWithDetails";

const Container = tw.div`
  grid grid-cols-[auto_auto_1fr] grid-rows-[1px_auto_1px]
  w-full h-fit items-center py-1 
`;

const PriceText = tw(Price)`
  text-right font-medium text-xs
`;

const PricePositionContainer = tw.div`
  rounded-3xl row-start-1 row-end-4 col-start-1 col-end-2 h-full
`;

type PriceContProps = {
  $selected?: boolean;
};

const PriceCont = tw.div<PriceContProps>`
  grid grid-flow-col
  row-start-2 row-end-3 col-start-1 col-end-4 rounded-3xl 
  items-center px-3 py-1 transition-all
  ${({ $selected }) => ($selected ? "bg-gray-300" : "bg-gray-200 ")}
`;

const ExpirationDate = tw.div`
  grid grid-flow-col items-center text-2xs gap-1
  row-start-2 row-end-3 col-start-2 col-end-3 pl-1
`;

type Props = {
  quoteItemPrice?: QuotePriceWithIndex;
  price?: number;
  isSelected?: boolean;
  leadTimeDays?: number | null;
};

export const PricePositionRow = ({
  quoteItemPrice,
  price,
  isSelected,
  leadTimeDays,
}: Props) => {
  const intl = useIntl();
  const leadTimeDaysFormatted = useMemo(() => {
    if (!leadTimeDays) {
      return null;
    }
    if (leadTimeDays % 30 === 0) {
      return intl.$t(
        {
          id: "LEAD_TIME_MONTHS",
        },
        {
          value: leadTimeDays / 30,
        },
      );
    } else if (leadTimeDays % 7 === 0) {
      return intl.$t(
        {
          id: "LEAD_TIME_WEEKS",
        },
        {
          value: leadTimeDays / 7,
        },
      );
    }
    return intl.$t(
      {
        id: "LEAD_TIME_DAYS",
      },
      {
        value: leadTimeDays,
      },
    );
  }, [intl, leadTimeDays]);

  return (
    <Container>
      <PriceCont $selected={isSelected}>
        <PriceText
          price={price}
          maximumFractionDigits={2}
          zeroValuePlaceholder={<FormattedMessage id="INCLUDED" />}
        />
      </PriceCont>
      <ExpirationDate>
        <If isTrue={leadTimeDays}>
          <WarningIconWithDetails
            warning={
              <FormattedMessage
                id="LEAD_TIME_WARNING"
                values={{ time: leadTimeDaysFormatted }}
              />
            }
          />
          {leadTimeDaysFormatted}
        </If>
      </ExpirationDate>
      <If isTrue={quoteItemPrice && quoteItemPrice.index !== -1}>
        <PricePositionContainer>
          <PricePositionRank position={quoteItemPrice} />
        </PricePositionContainer>
      </If>
    </Container>
  );
};
