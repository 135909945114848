import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import {
  MdFixedContainer,
  XlContainer,
} from "@/common/layout/ResponsiveClasses";
import { ExternalServiceCodeFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";

export const useImportExternalServiceCodesTableConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ExternalServiceCodeFieldsFragment>> => {
  return [
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item, index, count }) => (
        <SelectableItem
          item={item}
          index={index}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) => item.name}
          count={count}
        />
      ),
    },
    {
      wrapper: MdFixedContainer,
      header: <FormattedMessage id="SERVICE_CODE" />,
      item: ({ item }) => item.code,
      position: "center",
    },
  ];
};
