import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { DateRangePickerValue } from "@/common/components/date-range-picker/types";
import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { VendorPickerControlled } from "@/common/components/vendor-picker/VendorPickerControlled";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-row items-center`;
const Container = tw(
  Row,
)`justify-between items-center bg-blue-100 rounded-2xl h-20 px-5 sticky top-0 z-10 border-white border-b border-b-[20px]`;
const Title = tw.div`text-lg font-medium mr-4`;

export const ExternalPOsTableHeader = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { setValue, watch, setError } =
    useFormContext<ExternalPOsQueryInputType>();

  const filteredProjects = useMemo(
    () =>
      projects.filter((project) =>
        project.externalProjects.some(
          (m) => m.sourceSystem === connectedSourceSystem,
        ),
      ),
    [projects, connectedSourceSystem],
  );

  useEffect(() => {
    if (filteredProjects.length === 1) {
      setValue("projectId", filteredProjects[0].id);
    }
  }, [filteredProjects, setValue]);

  const minDate = watch("minDate");
  const maxDate = watch("maxDate");

  const selectedDateRangeValue = useMemo(() => {
    if (minDate || maxDate) {
      return {
        startDate: minDate ? new Date(minDate) : new Date(),
        endDate: maxDate ? new Date(maxDate) : new Date(),
      };
    }
    return undefined;
  }, [minDate, maxDate]);

  const handleChangeDateRange = useCallback(
    (dateRange: DateRangePickerValue) => {
      setValue("minDate", dateRange.startDate?.getTime());
      setValue("maxDate", dateRange.endDate?.getTime());
    },
    [setValue],
  );

  const isProjectSelectionRequired = useMemo(
    () =>
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ExternalPoReadsProjectSpecific,
      ),
    [hasFeatureInConnectedSourceSystem],
  );

  const projectId = watch("projectId");
  useEffect(() => {
    if (!projectId && isProjectSelectionRequired) {
      setError("projectId", {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <Container>
      <Row>
        <FormattedMessage
          id="IMPORT_PO_FROM_SOURCE_SYSTEM"
          tagName={Title}
          values={{
            sourceSystem: intl.$t({
              id: `INTEGRATION_${connectedSourceSystem}`,
            }),
          }}
        />
        <SelectControlled
          name="projectId"
          label={intl.$t({ id: "PROJECT" })}
          required={isProjectSelectionRequired}
          options={filteredProjects}
          getLabel={(p) => getProjectSelectorLabel(p)}
          getValue={(p) => p.id}
          loading={loadingProjects}
          className="mr-2"
          disableClearable={false}
        />
        <VendorPickerControlled
          name="sellerOrgLocationID"
          autoSelectDependencies={false}
          filterResults={(vendor) =>
            vendor.externalVendors.some(
              (m) => m.sourceSystem === connectedSourceSystem,
            )
          }
          disableClearable={false}
        />
        <DateRangePicker
          selectedValue={selectedDateRangeValue}
          isUTC
          onChange={handleChangeDateRange}
          titleId="SELECT_EXTERNAL_PO_DATE"
        />
      </Row>
      <Row>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH_PO_SYMBOL" })}
          value={watch("search")}
          onChange={(value) => setValue("search", value)}
        />
      </Row>
    </Container>
  );
};
