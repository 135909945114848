import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import {
  LocalPaginationProvider,
  useLocalPagination,
} from "@/common/components/pagination-local/LocalPaginationProvider";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { Switch } from "@/common/components/switch/Switch";
import { SwitchGroup } from "@/common/components/switch/SwitchGroup";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrgSettings } from "../../../org-settings/hooks/useOrgSettings";
import { LoaderStyled } from "../common/CostStructure.styles";
import { ImportExternalTaxCodesButton } from "./components/import/ImportExternalTaxCodesButton";
import { ImportExternalTaxCodesModal } from "./components/import/ImportExternalTaxCodesModal";
import { TaxCodeList } from "./components/list/TaxCodeList";
import { useTaxCodes } from "./hooks/useTaxCodes";
import { ImportExternalTaxCodesProvider } from "./providers/ImportExternalTaxCodesProvider";

const ITEMS_PER_PAGE = 24;
const HeaderContainerStyled = tw(HeaderContainer)`
  grid grid-flow-col gap-5 items-center justify-between lg:bg-gray-100 top-[57px] xl:top-[57px]
`;
const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start
`;

const TaxCodesWithProvider = () => {
  const intl = useIntl();
  const { taxCodes } = useTaxCodes();
  const { loading } = useOrgSettings();
  const [searchQuery, setSearchQuery] = useState("");
  const [includeArchived, setIncludeArchived] = useState(false);
  const { getPaginatedItems } = useLocalPagination();

  const hasArchived = useMemo(() => {
    return taxCodes.some((taxCode) => taxCode.archivedAt);
  }, [taxCodes]);

  const filteredTaxCodes = useMemo(() => {
    const filteredList = taxCodes
      .filter((taxCode) => includeArchived || !taxCode.archivedAt)
      .toSorted((a, b) =>
        (b.description || "").localeCompare(a.description || ""),
      )
      .toSorted((a) => (a.archivedAt ? 1 : -1));
    if (!searchQuery) {
      return filteredList;
    }
    return filteredList.filter((taxCode) => {
      return (
        ((taxCode.description || "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) &&
          (taxCode.description || "").length > 0) ||
        taxCode.code.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [taxCodes, searchQuery, includeArchived]);

  const currentTaxCodes = useMemo(() => {
    const newItem = taxCodes.find((c) => c.id === "");
    if (newItem && searchQuery) {
      return getPaginatedItems([newItem, ...filteredTaxCodes]);
    }
    return getPaginatedItems(filteredTaxCodes);
  }, [taxCodes, filteredTaxCodes, getPaginatedItems, searchQuery]);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "TAX_CODES" })}</title>
      </Helmet>
      <LoaderStyled loading={loading}>
        <HeaderContainerStyled>
          <Filters>
            <SearchInput
              placeHolder={intl.$t({ id: "SEARCH_TAX_CODE" })}
              onChange={setSearchQuery}
            />
          </Filters>
          <HeaderRightPanel>
            <ImportExternalTaxCodesButton />
          </HeaderRightPanel>
        </HeaderContainerStyled>
        <TaxCodeList
          items={currentTaxCodes}
          totalCount={taxCodes.length}
          loading={loading}
        />
        <If isTrue={hasArchived}>
          <SwitchGroup
            label={<FormattedMessage id="SHOW_DELETED_TYPES" />}
            className="justify-end"
          >
            <Switch
              value={includeArchived}
              onChange={setIncludeArchived}
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
              testId="form-include-additional-charges-switch"
            />
          </SwitchGroup>
        </If>
        <ImportExternalTaxCodesModal />
      </LoaderStyled>
    </>
  );
};

export const TaxCodes = () => {
  return (
    <NestedStepperProvider>
      <ImportExternalTaxCodesProvider>
        <LocalPaginationProvider itemsPerPage={ITEMS_PER_PAGE}>
          <TaxCodesWithProvider />
        </LocalPaginationProvider>
      </ImportExternalTaxCodesProvider>
    </NestedStepperProvider>
  );
};
