import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateContractorReleasesInput,
  useUpdateReleasesMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useReleasesUpdate = () => {
  const { setError } = useGlobalError();

  const [updateReleasesMutation, { loading }] = useUpdateReleasesMutation();
  const updateReleases = useCallback(
    async (input: UpdateContractorReleasesInput): Promise<boolean> => {
      try {
        const { data, errors } = await updateReleasesMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        return Boolean(!!data?.updateContractorReleases && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, updateReleasesMutation],
  );

  return { updateReleases, loading };
};
