import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { Check, Close } from "@mui/icons-material";
import { createRef, FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { CreateGroupFunction, Group } from "../../common/types/types";

const Container = tw.div`min-w-52`;

type Props = {
  removing?: boolean;
  onClose: () => void;
  createGroup: CreateGroupFunction;
  groups: Group[];
  updateEntity: (data: {
    assignedFolderIds?: string[];
    unassignedFolderIds?: string[];
  }) => void;
};

export const GroupSelector: FC<Props> = ({
  removing,
  onClose,
  createGroup,
  groups,
  updateEntity,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const ref = createRef<HTMLDivElement>();
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedGroupsIds, setSelectedGroupsIds] = useState<string[]>([]);

  const options = useMemo(() => {
    return (
      groups?.toSorted((a, b) => (a.name || "").localeCompare(b.name || "")) ??
      []
    );
  }, [groups]);

  const updateGroups = useCallback(
    async (folderIds: string[] | null) => {
      const existingGroups =
        folderIds?.filter((id) => options.find((option) => option.id === id)) ||
        [];
      const notExistingGroups = folderIds?.filter(
        (id) => !options.find((option) => option.id === id),
      );
      if (notExistingGroups?.length) {
        for (let i = 0; i < notExistingGroups?.length; i++) {
          const enteredValue = notExistingGroups[i].trim();
          let group = options.find(
            (option) => option.name === enteredValue,
          )?.id;
          if (!group) {
            group = await createGroup({ name: enteredValue });
          }
          if (group && !existingGroups.includes(group)) {
            existingGroups.push(group);
          }
        }
      }
      setSelectedGroupsIds(existingGroups);
    },
    [options, createGroup],
  );

  const saveChanges = useCallback(async () => {
    const enteredValue = inputValue.trim();
    if (!selectedGroupsIds.length || enteredValue) {
      if (enteredValue) {
        let group = options.find((option) => option.name === enteredValue)?.id;
        if (!group) {
          group = await createGroup({ name: enteredValue });
        }
        if (group) {
          updateEntity({
            assignedFolderIds: [...selectedGroupsIds, group],
          });
        }
      }

      onClose();
      return;
    }

    if (removing) {
      updateEntity({
        unassignedFolderIds: selectedGroupsIds,
      });
    } else {
      updateEntity({
        assignedFolderIds: selectedGroupsIds,
      });
    }
    onClose();
  }, [
    selectedGroupsIds,
    removing,
    onClose,
    inputValue,
    createGroup,
    updateEntity,
    options,
  ]);

  const creatable = useMemo(() => !removing, [removing]);

  return (
    <>
      <Container ref={ref}>
        <Multiselect
          testId="selectGroup"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          inputProps={{
            placeholder: selectedGroupsIds?.length
              ? ""
              : removing
                ? intl.$t({ id: "SEARCH_FOR_GROUP" })
                : intl.$t({ id: "SEARCH_OR_ADD_NEW" }),
          }}
          options={options}
          getLabel={(option) => option.name}
          getValue={(option) => option.id}
          values={selectedGroupsIds}
          onMultipleChange={updateGroups}
          onInputChange={
            creatable ? (value) => setInputValue(value || "") : undefined
          }
          includeCheckbox
          disableCloseOnSelect
          inputValue={inputValue}
          creatable={creatable}
        />
      </Container>
      <IconButtonBorderless
        className="h-6 w-6 text-white"
        onClick={saveChanges}
      >
        <Check />
      </IconButtonBorderless>
      <IconButtonBorderless
        className="mr-2 h-6 w-6 text-white"
        onClick={onClose}
      >
        <Close />
      </IconButtonBorderless>
    </>
  );
};
