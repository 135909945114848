export const sanitizeValue = (
  value: string,
  skipSpaceReplacement: boolean = false,
) => {
  if (typeof value === "string") {
    return skipSpaceReplacement
      ? value
          .replace(/<([^>]*)>/g, "$1")
          .replace(/:\w+:/g, "")
          .trim()
      : value
          ?.replace(/<([^>]*)>/g, "$1")
          .replace(/:\w+:/g, "")
          .replace(/\s+/g, " ")
          .trim();
  } else {
    return value;
  }
};
