import { useCountGeneration } from "@/common/components/grid-table/hooks/useCountGeneration";
import { If } from "@/common/components/if/If";
import { useRfqItemsGrouped } from "@/contractor/pages/home/rfq/providers/RfqItemsGroupedProvider";
import { FC, Fragment } from "react";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { CostCodesHeader } from "./cost-codes/CostCodesHeader";
import { RfqItemContainer } from "./rfq-item/RfqItemContainer";

export const RfqItemsLeveling: FC = () => {
  const { rfq } = useRfqQuotes();
  const { categories } = useRfqItemsGrouped();
  const countMap: Map<string, number> = new Map();
  const { generateCount } = useCountGeneration(countMap);

  if (!rfq) {
    return null;
  }

  return (
    <>
      {categories.map((category, index) => (
        <Fragment key={category.name}>
          <CostCodesHeader category={category} topOffset={60} />
          {category.items.map((item, index2) => (
            <If isTrue={category.isOpened} key={index2}>
              <RfqItemContainer
                key={item.id}
                rfqItem={item}
                quotes={rfq.quotes}
                countMap={countMap}
                count={generateCount(index, index2)}
              />
            </If>
          ))}
        </Fragment>
      ))}
    </>
  );
};
