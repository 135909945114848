import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { useSorting } from "@/common/stores/hooks/useSorting";
import { ObjectType } from "@/common/stores/useSortingStore";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  readValueFromKeys,
  removeValue,
  setValue,
} from "@/common/utils/localStorage";
import {
  CreateReceiptInput,
  InvoiceType,
  QueryInvoicesFilter,
  QueryInvoicesSortBy,
  ReceiptSummaryFieldsFragment,
  UpdateInvoicesInput,
  useArchiveInvoicesMutation,
  useReceiptsQuery,
  useUpdateInvoicesMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
  NoFunctionStringPromise,
} from "@/types/NoFunction";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useShallow } from "zustand/react/shallow";
import { useArchiveReceipt } from "../hooks/useArchiveReceipt";
import { useCreateReceipt } from "../hooks/useCreateReceipt";

type ProviderContextType = {
  receipts: ReceiptSummaryFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryInvoicesFilter;
  setFilter: (filter: QueryInvoicesFilter) => void;
  isFiltered: boolean;
  createReceipt: (input: CreateReceiptInput) => Promise<string | undefined>;
  archiveReceipt: (receiptId: string) => Promise<boolean>;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  creating: boolean;
  updateReceipts: (input: UpdateInvoicesInput) => Promise<boolean>;
  archiveReceipts: (invoiceIds: string[]) => Promise<boolean>;
  refetchReceipts: () => Promise<void>;
};

const ProviderContext = createContext<ProviderContextType>({
  receipts: [],
  totalCount: 0,
  loading: false,
  error: false,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
  createReceipt: NoFunctionStringPromise,
  archiveReceipt: NoFunctionBooleanPromise,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  creating: false,
  updateReceipts: NoFunctionBooleanPromise,
  archiveReceipts: NoFunctionBooleanPromise,
  refetchReceipts: NoFunctionPromise,
});

export enum ReceiptType {
  Approved = "APPROVED",
  AwaitingApproval = "AWAITING_APPROVAL",
  Paid = "PAID",
}

export const DEFAULT_RECEIPTS_FILTER: QueryInvoicesFilter = {
  archived: false,
  closedProjects: false,
};

export const ReceiptsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { paginationArgs, page, setPageInfo, previousPage } = usePagination();
  const { sortEntity } = useSorting(ObjectType.Receipt);
  const { setError } = useGlobalError();
  const { orgId } = useStartupDataStore(
    useShallow((state) => ({
      orgId: state.viewer?.org.id,
    })),
  );

  const getLocalStorageSettings = useCallback(() => {
    const localStorageSettings = readValueFromKeys<QueryInvoicesFilter>([
      [orgId || "", LOCAL_STORAGE_KEYS.RECEIPTS_LIST_FILTER],
      LOCAL_STORAGE_KEYS.RECEIPTS_LIST_FILTER,
    ]) as QueryInvoicesFilter;
    return {
      ...(localStorageSettings || {}),
    };
  }, [orgId]);
  const [filter, setFilter] = useState<QueryInvoicesFilter | undefined>(
    undefined,
  );
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const { createReceipt: createReceiptHook, loading: creating } =
    useCreateReceipt();

  const setFilterWithType = useCallback((filter: QueryInvoicesFilter) => {
    setFilter({
      ...filter,
      type: InvoiceType.Receipt,
    });
  }, []);

  const setFilterAndUpdateQueryString = useCallback(
    (updatedFilter: QueryInvoicesFilter) => {
      setFiltersQueryParams(updatedFilter);
      removeValue(LOCAL_STORAGE_KEYS.RECEIPTS_LIST_FILTER);
      setValue(
        [orgId || "", LOCAL_STORAGE_KEYS.RECEIPTS_LIST_FILTER],
        updatedFilter,
      );
      setFilterWithType(updatedFilter);
    },
    [orgId, setFilterWithType, setFiltersQueryParams],
  );

  useEffect(() => {
    setFilterWithType({
      ...DEFAULT_RECEIPTS_FILTER,
      ...getLocalStorageSettings(),
      ...getFiltersQueryParam(),
    });
  }, [setFilterWithType, getFiltersQueryParam, getLocalStorageSettings]);

  useEffect(() => {
    if (page !== 0 && data?.receipts.edges.length === 0) {
      previousPage();
    }
  });

  const { data, loading, error, refetch } = useReceiptsQuery({
    variables: {
      filter,
      ...paginationArgs,
      sort: sortEntity
        ? {
            by: sortEntity.field as QueryInvoicesSortBy,
            descending: sortEntity.descending,
          }
        : undefined,
    },
    skip: !filter,
    fetchPolicy: "cache-and-network",
  });

  const { archiveReceipt: archiveReceiptFn } = useArchiveReceipt({
    filter,
    page,
    paginationArgs,
    previousPage,
  });

  useEffect(() => {
    if (data?.receipts) {
      setPageInfo(data.receipts.pageInfo);
    }
  }, [data, setPageInfo]);

  const createReceipt = async (input: CreateReceiptInput) => {
    const receiptId = await createReceiptHook(input);
    if (receiptId) {
      await refetch();
      return receiptId;
    }
  };

  const archiveReceipt = (id: string) => {
    return archiveReceiptFn(id);
  };

  const [updateInvoicesMutation] = useUpdateInvoicesMutation();
  const updateReceipts = useCallback(
    async (input: UpdateInvoicesInput): Promise<boolean> => {
      try {
        const { data, errors } = await updateInvoicesMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        await refetch();
        return Boolean(!!data?.updateInvoices && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, updateInvoicesMutation, refetch],
  );

  const [archiveReceiptsMutation] = useArchiveInvoicesMutation();
  const archiveReceipts = useCallback(
    async (ids: string[]) => {
      try {
        const { data, errors } = await archiveReceiptsMutation({
          variables: {
            ids,
          },
        });
        setError(errors);
        await refetch();
        return Boolean(!!data?.archiveInvoices && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [archiveReceiptsMutation, refetch, setError],
  );

  const refetchReceipts = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        receipts: data?.receipts?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.receipts?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        isFiltered: !!filter?.statuses?.length || !!filter?.projectIds?.length,
        createReceipt,
        archiveReceipt,
        exportEnabled,
        setExportEnabled,
        creating,
        updateReceipts,
        archiveReceipts,
        refetchReceipts,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceipts = (): ProviderContextType =>
  useContext(ProviderContext);
