import { TagsSelector } from "@/common/components/tags-selector/TagsSelector";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useSpendingReport } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { TagFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";

type Props = {
  options: TagFieldsFragment[];
  disabled?: boolean;
  limitTags?: number;
};

export const SpendingReportTagsSelector: FC<Props> = ({
  options,
  disabled,
  limitTags = 1,
}) => {
  const intl = useIntl();
  const { tags, setTags } = useSpendingReport();
  const { hasPhaseCodes } = useOrgSettings();

  const tagOptions = useMemo(() => {
    return options
      .filter((tag) => !hasPhaseCodes || tag.hasMapping)
      .map((tag) => {
        return {
          ...tag,
          disabled: false,
        };
      });
  }, [options, hasPhaseCodes]);

  return (
    <TagsSelector
      options={tagOptions}
      selectedTags={tags}
      setSelectedTags={setTags}
      disabled={disabled}
      limitTags={limitTags}
      placeholder={intl.$t({
        id: hasPhaseCodes ? "PHASE_CODE" : "TAGS",
      })}
      multiple={false}
      creatable={false}
    />
  );
};
