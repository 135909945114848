import { ClearFiltersButton } from "@/common/components/clean-filters-button/ClearFiltersButton";
import { If } from "@/common/components/if/If";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { StatusesFilter } from "@/common/components/status-filter/StatusesFilter";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { useUser } from "@/common/providers/UserProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InvoiceHeader } from "@/contractor/pages/home/invoices/common/components/InvoiceHeader";
import { InfoOutlined } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  DEFAULT_INVOICE_EMAILS_FILTER,
  useInvoiceEmails,
} from "../../hooks/useInvoiceEmails";
import { InvoiceEmailsAdditionalFilters } from "./InvoiceEmailsAdditionalFilters";

const Filters = tw.div`
  grid grid-flow-col gap-2 items-center
`;
const EmailInfo = tw.div`flex flex-row items-center text-2xs text-gray-600 gap-2`;

enum InvoiceEmailType {
  AwaitingApproval = "AWAITING_APPROVAL",
  Processed = "PROCESSED",
}

export const InvoiceEmailsHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInvoiceEmails();
  const { fullEmail } = useUser();
  const { settings } = useOrgSettings();
  const emailInvoices = useMemo(
    () => settings?.invoices.localPart,
    [settings?.invoices.localPart],
  );

  const options = useMemo(() => {
    return Object.entries(InvoiceEmailType).map(([, opt]) => {
      return {
        value: opt,
        name: intl.$t({ id: `INVOICE_EMAIL_STATUS_${opt}` }),
      };
    });
  }, [intl]);

  const appliedFilters = useMemo(() => {
    return Number(filter?.archived !== false);
  }, [filter?.archived]);

  const countNonDefaultFilters = useMemo(() => {
    return (
      appliedFilters +
      Number(filter?.search != null && filter?.search !== "") +
      Number((filter?.statuses || []).length !== 0) +
      Number((filter?.sellerOrgLocationIds || []).length !== 0)
    );
  }, [
    appliedFilters,
    filter?.search,
    filter?.sellerOrgLocationIds,
    filter?.statuses,
  ]);

  const resetFilterToDefault = useCallback(() => {
    setFilter(DEFAULT_INVOICE_EMAILS_FILTER);
  }, [setFilter]);

  return (
    <InvoiceHeader>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          tooltip="INVOICE_EMAILS_SEARCH_INFO"
          applyHeaderAccent
          clear={() => setFilter({ ...filter, search: undefined })}
        />
        <VendorsFilterSelector
          value={filter?.sellerOrgLocationIds?.[0]}
          onChange={(value) => {
            setFilter({
              ...filter,
              sellerOrgLocationIds: value ? [value] : undefined,
            });
          }}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              sellerOrgLocationIds: undefined,
            })
          }
        />
        <StatusesFilter
          options={options}
          selectedStatuses={filter?.statuses}
          onChange={(values) => {
            setFilter({
              ...filter,
              statuses: values,
            });
          }}
          applyHeaderAccent
        />
        <ListHeaderFilterButton
          filter={<InvoiceEmailsAdditionalFilters />}
          isDefault={appliedFilters === 0}
          appliedFilters={appliedFilters}
        />
        <ClearFiltersButton
          count={countNonDefaultFilters}
          onClick={resetFilterToDefault}
        />
      </Filters>
      <If isTrue={emailInvoices}>
        <EmailInfo>
          <InfoOutlined />
          <FormattedMessage
            id="INVOICES_RECEIVED_AT_EMAIL"
            values={{ email: fullEmail, br: <br /> }}
          />
        </EmailInfo>
      </If>
    </InvoiceHeader>
  );
};
