import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ClearFiltersButton } from "@/common/components/clean-filters-button/ClearFiltersButton";
import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { ListStatusesFilter } from "@/common/components/list-statuses-filter/ListStatusesFilter";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectListOptions } from "../../projects/hooks/useProjectListOptions";
import { useBuyoutSequence } from "../hooks/useBuyoutSequence";
import {
  BuyoutsFilterSelection,
  DEFAULT_BUYOUTS_FILTER,
  useBuyouts,
} from "../providers/BuyoutsProvider";
import { BuyoutsAdditionalFilters } from "./BuyoutsAdditionalFilters";
import { BuyoutSequence } from "./BuyoutSequence";
import { ToggleBuyoutForm } from "./new-buyout/ToggleBuyoutsForm";

const HeaderContainerStyled = tw(HeaderContainer)`
  justify-between
`;

const Filters = tw.div`
  grid grid-flow-col gap-2 items-center
`;

const Actions = tw.div`flex gap-3 items-center`;

export const BuyoutsHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useBuyouts();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });
  const { sequenceEnabled } = useBuyoutSequence();

  const options = useMemo(() => {
    return [
      {
        id: BuyoutsFilterSelection.Draft,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_DRAFT" }),
      },
      {
        id: BuyoutsFilterSelection.Active,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_ACTIVE" }),
      },
      {
        id: BuyoutsFilterSelection.Requested,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_REQUESTED" }),
      },
      {
        id: BuyoutsFilterSelection.Depleted,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_DEPLETED" }),
      },
      {
        id: BuyoutsFilterSelection.Cancelled,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_CANCELED" }),
      },
    ];
  }, [intl]);

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.deleted !== false) +
      Number(filter?.exported != null) +
      Number(filter?.poExists != null) +
      Number(filter?.closedProjects !== false)
    );
  }, [
    filter?.deleted,
    filter?.exported,
    filter?.poExists,
    filter?.closedProjects,
  ]);

  const countNonDefaultFilters = useMemo(() => {
    return (
      appliedFilters +
      Number(filter?.search != null && filter?.search !== "") +
      Number((filter?.statuses || []).length !== 0) +
      Number((filter?.projectIDs || []).length !== 0) +
      Number((filter?.sellerOrgLocationIds || []).length !== 0)
    );
  }, [
    appliedFilters,
    filter?.projectIDs,
    filter?.search,
    filter?.sellerOrgLocationIds,
    filter?.statuses,
  ]);

  const resetFilterToDefault = useCallback(() => {
    setFilter(DEFAULT_BUYOUTS_FILTER);
  }, [setFilter]);

  return (
    <HeaderContainerStyled>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          applyHeaderAccent
          tooltip="BUYOUTS_SEARCH_INFO"
          clear={() => setFilter({ ...filter, search: undefined })}
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIDs: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIDs?.[0]}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              projectIDs: undefined,
            })
          }
        />
        <VendorsFilterSelector
          value={filter?.sellerOrgLocationIds?.[0]}
          onChange={(value) => {
            setFilter({
              ...filter,
              sellerOrgLocationIds: value ? [value] : undefined,
            });
          }}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              sellerOrgLocationIds: undefined,
            })
          }
        />
        <ListStatusesFilter
          options={options}
          filter={filter}
          setFilter={setFilter}
          values={filter?.statuses || null}
          applyHeaderAccent
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<BuyoutsAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ClearFiltersButton
          count={countNonDefaultFilters}
          onClick={resetFilterToDefault}
        />
      </Filters>
      <Actions>
        <BuyoutSequence />
        <If isTrue={!sequenceEnabled}>
          <ToggleBuyoutForm label={intl.$t({ id: "NEW_BUYOUT" })}>
            <PrimaryButton testId="new-buyout-button">
              <FormattedMessage id="NEW_BUYOUT" />
            </PrimaryButton>
          </ToggleBuyoutForm>
        </If>
      </Actions>
    </HeaderContainerStyled>
  );
};
