import { If } from "@/common/components/if/If";
import { InlineNotesAndAttachments } from "@/common/components/inline-notes-and-attachments/InlineNotesAndAttachments";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  DistributorReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly: boolean;
};

const DistributorReleaseNotesDetailsWithProvider: FC<Props> = ({
  item,
  readonly,
}) => {
  const { updateVendorRelease, setExpandedItems, expandedItems } =
    useDistributorRelease();
  const { assets } = useUploadAssets();

  const closeItem = useCallback(
    () => setExpandedItems(expandedItems.filter((id) => id !== item.id)),
    [expandedItems, item, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (notes: string) => {
      const updates = [
        {
          releaseItemId: item.id,
          notes,
          assetUrls: assets.map((a) => a.url),
        },
      ];
      await updateVendorRelease({
        updates,
      });

      closeItem();
    },
    [assets, closeItem, item, updateVendorRelease],
  );

  return (
    <If isTrue={expandedItems.includes(item.id)}>
      <InlineNotesAndAttachments
        closeItem={closeItem}
        item={item}
        readonly={readonly}
        saveItem={handleOnSave}
      />
    </If>
  );
};

export const DistributorReleaseNotesDetails: FC<Props> = (props) => {
  const { item } = props;
  const { release } = useDistributorRelease();

  return (
    <UploadAssetProvider
      initialAssets={item.assets}
      context={AssetContext.Note}
      projectId={release?.project?.id}
    >
      <DistributorReleaseNotesDetailsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
