import { DialogProps } from "@/common/components/dialog/Dialog";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useAddItemsModal = () => {
  const { openDialog } = useDialog();
  const { setWarningAlert } = useSnackbar();
  const intl = useIntl();

  const showModal = useCallback(
    (data: Partial<DialogProps>, totalCount: number, missingCount: number) => {
      if (totalCount === 0) {
        setWarningAlert(intl.$t({ id: "NO_ITEMS_SCANNED" }));
        return;
      }
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        text: intl.$t({
          id: missingCount
            ? "SOME_SCANNED_ITEMS_ALREADY_IN_ORDER"
            : "ALL_SCANNED_ITEMS_ALREADY_IN_ORDER",
        }),
        customButtonText: missingCount
          ? intl.$t({ id: "ADD_ALL_ITEMS" }, { items: totalCount })
          : undefined,
        confirmButtonText: missingCount
          ? intl.$t({ id: "ADD_MISSING_ITEMS" }, { items: missingCount })
          : intl.$t({ id: "ADD_ALL_ITEMS" }, { items: totalCount }),
        sx: { "& .MuiDialog-paper": { maxWidth: "650px" } },
        closeOnConfirm: true,
        closeOnCustom: true,
        includeWarningIcon: true,
        ...data,
        handleConfirm: missingCount
          ? data.handleConfirm
          : data.handleCustomButtonAction,
      });
    },
    [openDialog, intl, setWarningAlert],
  );

  return {
    showModal,
  };
};
