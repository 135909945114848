import { Loader } from "@/common/components/loader/Loader";
import { ResizableColumns } from "@/common/components/resizable-columns/ResizableColumns";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { NoteDocumentDetails } from "@/contractor/pages/home/common/note-document/components/note-document-details/NoteDocumentDetails";
import { useRfq } from "@/contractor/pages/home/rfq/hooks/useRfq";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useReplaceDocumentOnRfq } from "../hooks/useReplaceDocumentOnRfq";
import { useCreateRfqFromNoteConfig } from "./components/CreateRfqFromNote.config";
import { CreateRfqFromNoteBreadcrumbs } from "./components/CreateRfqFromNoteBreadcrumbs";
import { CreateRfqFromNoteDetails } from "./components/CreateRfqFromNoteDetails";
import { CreateRfqFromNoteFooter } from "./components/CreateRfqFromNoteFooter";

const Container = tw.div`
  mt-5 xl:-mx-10 sticky 
`;

const CreateRfqFromNoteWithProvider: FC = () => {
  const { rfq, loading } = useRfq();
  const replaceDocument = useReplaceDocumentOnRfq();

  if (loading) {
    return <Loader loading />;
  }

  if (!rfq) {
    return (
      <Container>
        <CreateRfqFromNoteBreadcrumbs />
        <ResizableColumns hasFooter>
          <CreateRfqFromNoteDetails />
          <NoteDocumentDetails replaceCallback={replaceDocument} />
        </ResizableColumns>
        <CreateRfqFromNoteFooter />
      </Container>
    );
  }

  return (
    <Container>
      <CreateRfqFromNoteBreadcrumbs />
      <ResizableColumns hasFooter>
        <CreateRfqFromNoteDetails />
        <NoteDocumentDetails replaceCallback={replaceDocument} />
      </ResizableColumns>
      <CreateRfqFromNoteFooter />
    </Container>
  );
};

export const CreateRfqFromNote: FC = () => {
  const spreadsheetConfig = useCreateRfqFromNoteConfig();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <CreateRfqFromNoteWithProvider />
    </ColumnMapperProvider>
  );
};
