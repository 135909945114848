import { CallbackParams, QueueItem } from "@/common/stores/types/QueueTypes";
import { useGenericApolloClientStore } from "@/common/stores/useApolloClientStore";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  ApproveReleaseInput,
  ApproveReleaseMutation,
  ApproveReleaseMutationResult,
  namedOperations,
  SubmitReleaseInput,
  SubmitReleaseMutation,
  SubmitReleaseMutationResult,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseMutation,
  UpdateContractorReleaseMutationResult,
  useApproveReleaseMutation,
  useSubmitReleaseMutation,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useRef } from "react";

enum ReleaseChainType {
  UpdateContractorRelease = "update",
  SubmitRelease = "submit",
  ApproveRelease = "approve",
}

export type ReleaseChainInput =
  | UpdateContractorReleaseInput
  | SubmitReleaseInput
  | (Omit<ApproveReleaseInput, "version"> & {
      version: UpdateContractorReleaseInput["version"];
    });

type ReleaseChainMutation =
  | UpdateContractorReleaseMutation
  | SubmitReleaseMutation
  | ApproveReleaseMutation;
type ReleaseChainMutationResult =
  | UpdateContractorReleaseMutationResult
  | SubmitReleaseMutationResult
  | ApproveReleaseMutationResult;

export const useReleaseMutations = () => {
  const { chain, queue, executionQueue } = useGenericApolloClientStore<
    ReleaseChainInput,
    ReleaseChainMutation
  >();
  const [updateContractorReleaseMutation] =
    useUpdateContractorReleaseMutation();

  const [submitReleaseMutation] = useSubmitReleaseMutation({
    update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
  });
  const [approveReleaseMutation] = useApproveReleaseMutation({
    update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
  });
  const executionCount = useRef<number>(0);

  const defaultCallback = useCallback(
    (
      queueItem: QueueItem<ReleaseChainInput, ReleaseChainMutation>,
      paramsList: ReleaseChainInput[],
      result: ReleaseChainMutationResult,
      additionalParams: CallbackParams | undefined,
    ) => {
      const paramsListCopy = [...paramsList];
      paramsListCopy.forEach((params) => {
        const version =
          (result as UpdateContractorReleaseMutationResult).data
            ?.updateContractorRelease?.version ??
          (result as SubmitReleaseMutationResult).data?.submitRelease
            ?.version ??
          (result as ApproveReleaseMutationResult).data?.approveRelease
            ?.version;
        params.version = version;
      });
      if (additionalParams?.isExecutionQueueCallback) {
        queueItem.callback?.(paramsList, result);
      }
      return paramsListCopy;
    },
    [],
  );

  const chainUpdateContractorReleaseMutation = useCallback(
    ({
      queueItem,
    }: {
      queueItem: QueueItem<
        UpdateContractorReleaseInput,
        UpdateContractorReleaseMutation
      >;
    }) => {
      chain({
        id: queueItem.id,
        fn: (params) => {
          return updateContractorReleaseMutation({
            variables: { input: params },
            update: (cache, result, options) =>
              queueItem.updateApolloCache?.(cache, result, options),
          });
        },
        params: queueItem.params,
        callback: (paramsList, result, additionalParams) =>
          defaultCallback(
            queueItem as QueueItem<ReleaseChainInput, ReleaseChainMutation>,
            paramsList,
            result as ReleaseChainMutationResult,
            additionalParams,
          ),
        name: ReleaseChainType.UpdateContractorRelease,
      });
    },
    [chain, updateContractorReleaseMutation, defaultCallback],
  );

  const chainSubmitReleaseMutation = useCallback(
    ({
      queueItem,
    }: {
      queueItem: QueueItem<SubmitReleaseInput, SubmitReleaseMutation>;
    }) => {
      chain({
        id: queueItem.id,
        fn: (params) => {
          return submitReleaseMutation({
            variables: { input: params },
            update: (cache, result, options) =>
              queueItem.updateApolloCache?.(cache, result, options),
          });
        },
        params: queueItem.params,
        callback: (paramsList, result, additionalParams) =>
          defaultCallback(
            queueItem as QueueItem<ReleaseChainInput, ReleaseChainMutation>,
            paramsList,
            result as ReleaseChainMutationResult,
            additionalParams,
          ),
        name: ReleaseChainType.SubmitRelease,
      });
    },
    [chain, defaultCallback, submitReleaseMutation],
  );

  const chainApproveReleaseMutation = useCallback(
    ({
      queueItem,
    }: {
      queueItem: QueueItem<ApproveReleaseInput, ApproveReleaseMutation>;
    }) => {
      chain({
        id: queueItem.id,
        fn: (params) => {
          return approveReleaseMutation({
            variables: { input: params },
            update: (cache, result, options) =>
              queueItem.updateApolloCache?.(cache, result, options),
          });
        },
        params: queueItem.params,
        callback: (paramsList, result, additionalParams) =>
          defaultCallback(
            queueItem as QueueItem<ReleaseChainInput, ReleaseChainMutation>,
            paramsList as ReleaseChainInput[],
            result as ReleaseChainMutationResult,
            additionalParams,
          ) as ApproveReleaseInput[],
        name: ReleaseChainType.ApproveRelease,
      });
    },
    [chain, approveReleaseMutation, defaultCallback],
  );

  const removingReleaseItemIds = useMemo(
    () =>
      queue
        .map(
          (item) =>
            (item.params as UpdateContractorReleaseInput).removedItems ?? [],
        )

        .flat()
        .concat(
          executionQueue
            .map(
              (item) =>
                (item.params as UpdateContractorReleaseInput).removedItems ??
                [],
            )
            .flat(),
        ),
    [queue, executionQueue],
  );

  const updatingReleaseItemIds = useMemo(
    () =>
      queue
        .map((item) => {
          const params = item.params as UpdateContractorReleaseInput;
          if (!params) {
            return [];
          }
          return (params.updates || []).map((a) => a.releaseItemId);
        })
        .flat()
        .concat(
          executionQueue
            .map((item) => {
              const params = item.params as UpdateContractorReleaseInput;
              if (!params) {
                return [];
              }
              return (params.updates || []).map((a) => a.releaseItemId);
            })
            .flat(),
        ),
    [queue, executionQueue],
  );

  const syncingReleaseItemIds = useMemo(
    () => removingReleaseItemIds.concat(updatingReleaseItemIds),
    [removingReleaseItemIds, updatingReleaseItemIds],
  );

  useEffect(() => {
    executionCount.current = syncingReleaseItemIds.length;
  }, [syncingReleaseItemIds.length]);

  const approving = useMemo(
    () =>
      executionQueue.some(
        (item) => item.name === ReleaseChainType.ApproveRelease,
      ) || queue.some((item) => item.name === ReleaseChainType.ApproveRelease),
    [executionQueue, queue],
  );

  const submitting = useMemo(
    () =>
      executionQueue.some(
        (item) => item.name === ReleaseChainType.SubmitRelease,
      ) || queue.some((item) => item.name === ReleaseChainType.SubmitRelease),
    [executionQueue, queue],
  );

  const updating = useMemo(
    () =>
      executionQueue.some(
        (item) => item.name === ReleaseChainType.UpdateContractorRelease,
      ) ||
      queue.some(
        (item) => item.name === ReleaseChainType.UpdateContractorRelease,
      ),
    [executionQueue, queue],
  );

  return {
    chainUpdateContractorReleaseMutation,
    chainSubmitReleaseMutation,
    chainApproveReleaseMutation,
    executionCount,
    submitting,
    approving,
    updatingReleaseItemIds,
    removingReleaseItemIds,
    syncingReleaseItemIds,
    updating,
  };
};
