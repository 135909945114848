import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { ReceiptSummaryFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import {
  ReceiptSequenceData,
  useReceiptSequence,
} from "../../providers/ReceiptsSequenceProvider";

const Container = tw.div`flex`;

type Props = {
  receipt: ReceiptSummaryFieldsFragment;
};

export const ReceiptCheckbox: FC<Props> = ({ receipt }) => {
  const { sequenceIds, setSelectedReceipts, selectedReceipts } =
    useReceiptSequence();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedReceipts(
        newChecked
          ? ([...selectedReceipts, receipt] as ReceiptSequenceData[])
          : selectedReceipts.filter((i) => i.id !== receipt.id),
      );
    },
    [receipt, selectedReceipts, setSelectedReceipts],
  );

  return (
    <Container>
      <SelectionCheckbox
        itemId={receipt.id}
        items={sequenceIds}
        setSelection={handleChange}
      />
    </Container>
  );
};
