import { useImportExternalPos } from "@/common/components/import-external-po/hooks/useImportExternalPos";
import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { ExternalPOItems } from "@/contractor/pages/home/common/external-po/ExternalPOItems";
import { TaxType } from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ImportExternalPoValidation } from "../../import-external-po-validation/ImportExternalPoValidation";
import { useExternalPoItemsValidation } from "./hooks/useExternalPoItemsValidation";

type Props = {
  includeDate?: boolean;
};

export const ImportableExternalPOItems: FC<Props> = ({ includeDate }) => {
  const {
    reset,
    hasMissingPoLink,
    importExternalCostCodes,
    includedItemsCategory,
    importingExternalCostCodes,
    missingTagsCategory,
    externalPo,
    loadingExternalPo,
    missingCostCodesCategory,
    unsupportedCostTypesCategory,
    variables: {
      sellerOrgLocationId,
      importedTaxCodeId,
      importedTaxType,
      orderTypeId,
      release,
      date,
    },
    options: { readonlyMode },
    setOptions,
    setVariables,
  } = useImportExternalPos();
  const externalPOsQueryForm = useFormContext<ExternalPOsQueryInputType>();
  const projectId = externalPOsQueryForm?.watch("projectId");
  const { poAlreadyImported, validationError } = useExternalPoItemsValidation({
    release,
    readonly: readonlyMode,
  });

  useEffect(() => {
    setOptions({ includeDate });
  }, [includeDate, setOptions]);

  const setSelectedSellerOrgLocationId = useCallback(
    (sellerOrgLocationId: string | null) =>
      setVariables({ sellerOrgLocationId }),
    [setVariables],
  );

  const setOrderTypeId = useCallback(
    (orderTypeId: string | null) => setVariables({ orderTypeId }),
    [setVariables],
  );

  const setImportedTaxCodeId = useCallback(
    (importedTaxCodeId?: string | null) => setVariables({ importedTaxCodeId }),
    [setVariables],
  );

  const setImportedTaxType = useCallback(
    (importedTaxType?: TaxType | null) => setVariables({ importedTaxType }),
    [setVariables],
  );

  const setDate = useCallback(
    (date: number | null) => setVariables({ date }),
    [setVariables],
  );

  return (
    <ExternalPOItems
      close={reset}
      projectId={projectId}
      externalPO={externalPo}
      loading={loadingExternalPo}
      missingCostCodesCategory={missingCostCodesCategory}
      missingTagsCategory={missingTagsCategory}
      unsupportedCostTypesCategory={unsupportedCostTypesCategory}
      includedItemsCategory={includedItemsCategory}
      readonly={poAlreadyImported || !!hasMissingPoLink || readonlyMode}
      validationError={validationError}
      importExternalCostCodes={importExternalCostCodes}
      importingExternalCostCodes={importingExternalCostCodes}
      selectedSellerOrgLocationId={sellerOrgLocationId}
      setSelectedSellerOrgLocationId={setSelectedSellerOrgLocationId}
      orderTypeId={orderTypeId}
      setOrderTypeId={setOrderTypeId}
      importedTaxCodeId={importedTaxCodeId}
      setImportedTaxCodeId={setImportedTaxCodeId}
      importedTaxType={importedTaxType}
      setImportedTaxType={setImportedTaxType}
      date={date}
      setDate={setDate}
      includeDate={includeDate}
      hideNavigation={readonlyMode}
    >
      <ImportExternalPoValidation externalPo={externalPo} release={release} />
    </ExternalPOItems>
  );
};
