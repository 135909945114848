import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import {
  Base,
  MdFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Connections } from "../../../../../home/projects/components/Connections";
import { CostType } from "../providers/CostTypesListProvider";
import { CostTypeActions } from "./CostTypeActions";
import { CostTypeInput } from "./CostTypeInput";

const ActionsWrapper = tw(SmFixedContainer)`
  absolute right-2 top-0 lg:relative 
`;

const WideContainer = tw(Base)`
  lg:basis-auto lg:w-5/12 2xl:basis-auto 2xl:w-6/12
`;

export const useCostTypesConfiguration = () => {
  const intl = useIntl();

  const configuration: Array<GridCol<CostType>> = useMemo(
    () => [
      {
        wrapper: WideContainer,
        header: <FormattedMessage id="DESCRIPTION" />,
        item: ({ item, editMode, rowError }) => (
          <CostTypeInput
            costType={item}
            error={rowError}
            key={item.id}
            prop="description"
            placeholder="DESCRIPTION"
            readonly={!editMode && item.id !== ""}
          />
        ),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="COST_TYPE" />,
        item: ({ item, editMode, rowError }) => (
          <CostTypeInput
            costType={item}
            error={rowError}
            key={item.id}
            prop="code"
            placeholder="COST_TYPE"
            required
            readonly={!editMode && item.id !== ""}
          />
        ),
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="IMPORTED_FROM" />,
        item: ({ item }) => <Connections connections={item.mappings} />,
        position: "center",
      },
      {
        wrapper: ActionsWrapper,
        item: ({ item, editMode, setEditMode }) => {
          return (
            <CostTypeActions
              costType={item}
              readonly={!editMode && item.id !== ""}
              setEditMode={setEditMode}
            />
          );
        },
        position: "center",
      },
    ],
    [],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "DESCRIPTION" }),
        columnId: "description",
        columnType: COLUMN_TYPE.CodeDescription,
      },
      {
        header: intl.$t({ id: "COST_TYPE" }),
        columnId: "type",
        columnType: COLUMN_TYPE.Code,
      },
    ],
    [intl],
  );

  return { configuration, spreadsheetConfig };
};
