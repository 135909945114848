import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { PaymentMethodFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { usePaymentMethodsConfiguration } from "./PaymentMethodsList.config";

type Props = {
  items: PaymentMethodFieldsFragment[];
  loading: boolean;
};

export const PaymentMethodsList: FC<Props> = ({ items, loading }) => {
  const { configuration } = usePaymentMethodsConfiguration();

  return (
    <ListRenderer>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            header: "top-[134px] bg-transparent",
          },
        }}
        items={items}
        loading={loading}
        emptyList={
          <NoResults
            isFiltered={false}
            translationKey="PAYMENT_METHODS_LIST_NO_ITEMS"
          />
        }
      />
    </ListRenderer>
  );
};
