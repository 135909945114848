import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { AddToBuyoutItemInput } from "@/generated/graphql";
import { ControlPoint, DeleteOutline, DragHandle } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import { useRfq } from "../../hooks/useRfq";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";
import { RfqBulkCostCodeSelector } from "./RfqBulkCostCodeSelector";

export const RfqItemsSideActions = () => {
  const { selectedRfqItemIds, setSelectedRfqItemIds } = useRfqGroupedItems();
  const { rfq, updateRfq } = useRfq();
  const [loading, setLoading] = useState(false);
  const viewState = useTableViewStore((state) => state.viewState);
  // TODO enable duplicates when we have the backend support
  const [enableDuplicate] = useState(false);

  const canModifyItems = useMemo(
    () => selectedRfqItemIds.length > 0,
    [selectedRfqItemIds],
  );

  const deleteItems = useCallback(async () => {
    if (rfq && selectedRfqItemIds) {
      setLoading(true);
      await updateRfq({
        rfqId: rfq.id,
        removedItems: selectedRfqItemIds,
      });
      setLoading(false);
      setSelectedRfqItemIds([]);
    }
  }, [rfq, selectedRfqItemIds, setSelectedRfqItemIds, updateRfq]);

  const duplicate = useCallback(async () => {
    if (rfq && selectedRfqItemIds) {
      setLoading(true);
      await updateRfq({
        rfqId: rfq.id,
        addedItems: selectedRfqItemIds
          .map((id) => {
            const item = rfq.items.find((i) => i.id === id);
            if (item) {
              return {
                description: item.description,
                quantityDecimal: item.quantityDecimal,
                manufacturerId: item.manufacturer?.id,
                costCodeId: item.costCode?.id,
                projectItem: {
                  estimateUom: item.projectItem.estimateUom.pluralDescription,
                  orgCatalogSkuId: isOrgCatalogSku(
                    item.projectItem?.material.material,
                  )
                    ? item.projectItem?.material.material.id
                    : undefined,
                  masterProductId: isProductSku(
                    item.projectItem?.material.material,
                  )
                    ? item.projectItem?.material.material.id
                    : undefined,
                  masterSkuId: isMasterSku(item.projectItem?.material.material)
                    ? item.projectItem?.material.material.id
                    : undefined,
                },
              } as AddToBuyoutItemInput;
            }
            return null;
          })
          .filter((i) => i !== null) as AddToBuyoutItemInput[],
        updates: [],
      });
      setLoading(false);
      setSelectedRfqItemIds([]);
    }
  }, [rfq, selectedRfqItemIds, updateRfq, setSelectedRfqItemIds]);

  const actions = useMemo(() => {
    return [
      ...(enableDuplicate
        ? [
            {
              icon: <ControlPoint />,
              tooltipKey: "DUPLICATE_PROJECT_ITEMS",
              callback: duplicate,
            },
          ]
        : []),
      ...(canModifyItems
        ? [
            {
              icon: <DragHandle />,
              tooltipKey: "ASSIGN_COST_CODE",
              component: <RfqBulkCostCodeSelector setLoading={setLoading} />,
            },
            {
              icon: <DeleteOutline />,
              tooltipKey: "DELETE_ITEMS",
              callback: deleteItems,
            },
          ]
        : []),
    ];
  }, [enableDuplicate, duplicate, canModifyItems, deleteItems]);

  return (
    <SideActionBar
      loading={loading}
      visible={
        selectedRfqItemIds.length > 0 && viewState === TableViewState.normal
      }
      actions={actions}
    ></SideActionBar>
  );
};
