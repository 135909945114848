import { useWindowSize } from "@/common/hooks/useWindowResize";
import { useWindowScroll } from "@/common/providers/ScrollingProvider";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { useShallow } from "zustand/react/shallow";
import { CategoryType } from "../../enums/CategoryType";
import { Category } from "../../GridTable.styles";
import { extractRootPositionFromCountKey } from "../../hooks/useCountGeneration";
import { useGridTableCategoryVisibility } from "../../hooks/useGridTableCategoryVisibility";
import { useGridTable } from "../../providers/GridTableProvider";
import { CategoryTypeRenderItemsMap } from "../../types/CategoryTypeRenderItemsMap";

const GRID_VIRTUALIZE_ADDITIONAL_MARGIN = {
  [CategoryType.ONE_LEVEL]: 1000,
  [CategoryType.TWO_LEVELS]: 500,
  [CategoryType.THREE_LEVELS]: 500,
};
type Props = PropsWithChildren<{
  itemKey: string;
  skipVirtualization?: boolean;
}>;

export const GridTableVirtualizationContainer = ({
  children,
  skipVirtualization,
  itemKey,
}: Props) => {
  const { categoryType, virtualized } = useGridTable(
    useShallow((state) => ({
      categoryType: state.table.categoryType,
      virtualized: state.table.virtualized,
    })),
  );
  const initialInView = useMemo(
    () =>
      extractRootPositionFromCountKey(itemKey) <
      (categoryType ? CategoryTypeRenderItemsMap[categoryType] || 0 : 0),
    [categoryType, itemKey],
  );

  const { visible, setVisible } = useGridTableCategoryVisibility(itemKey);
  const { windowScroll } = useWindowScroll();
  const { ref, inView, entry } = useInView({
    threshold: 0,
    initialInView,
    trackVisibility: true,
    delay: 300,
    skip: skipVirtualization,
  });
  const { height } = useWindowSize({ skip: skipVirtualization });

  useEffect(() => {
    if (!virtualized || skipVirtualization || categoryType === undefined) {
      return;
    }
    const inRange =
      inView ||
      (!!entry?.boundingClientRect.top &&
        Math.abs(entry?.boundingClientRect.top - windowScroll.Top) <
          height + GRID_VIRTUALIZE_ADDITIONAL_MARGIN[categoryType]);
    if (inRange !== visible) {
      setVisible(inRange);
    }
  }, [
    categoryType,
    entry?.boundingClientRect.top,
    height,
    inView,
    virtualized,
    visible,
    windowScroll.Top,
    setVisible,
    itemKey,
    skipVirtualization,
  ]);

  return <Category ref={ref}>{children}</Category>;
};
