import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";

export const useGetOrCreateLumpSumMaterial = () => {
  const { updateMaterials, materials } = useMaterials();

  const getLumpSumOrgSKU = useCallback(
    () => ({
      defaultUom: LUMP_SUM_UOM,
      name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
    }),
    [],
  );

  const getOrCreateLumpSumMaterial = useCallback(async () => {
    const lumpSumMaterial = materials.find(
      (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
    );

    let newMaterials = lumpSumMaterial ? [lumpSumMaterial] : [];
    if (!lumpSumMaterial) {
      newMaterials = (await updateMaterials({
        addedMaterials: [
          {
            newOrgCatalogSKU: getLumpSumOrgSKU(),
          },
        ],
      })) as OrgMaterialFieldsFragment[];
    }
    return newMaterials[0];
  }, [getLumpSumOrgSKU, materials, updateMaterials]);

  return { getOrCreateLumpSumMaterial, getLumpSumOrgSKU };
};
