import { useAllItemsCheckboxSelection } from "@/common/components/pagination/hooks/useAllItemsCheckboxSelection";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useDeliverySlipSequence } from "../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlips } from "../../providers/DeliverySlipsProvider";

const Container = tw.div`
  flex -ml-2
`;

export const DeliverySlipsAllItemsCheckbox: FC = () => {
  const { sequenceIds, setSelectedDeliverySlips, selectedDeliverySlips } =
    useDeliverySlipSequence();
  const { deliverySlips } = useDeliverySlips();
  const { checked, handleChange } = useAllItemsCheckboxSelection(
    deliverySlips,
    selectedDeliverySlips,
    setSelectedDeliverySlips,
  );

  return (
    <Container>
      <SelectionCheckbox
        items={sequenceIds}
        setSelection={handleChange}
        checked={checked}
      />
    </Container>
  );
};
