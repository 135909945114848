import { If } from "@/common/components/if/If";
import { TextField } from "@/common/components/textfield/TextField";
import { ReceiptFieldsFragment } from "@/generated/graphql";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const NoteLabel = tw.div`font-medium text-sm`;
const NotesContainer = tw.div`flex flex-col pt-2`;
const NotesEdit = tw.div`flex items-center`;
const ButtonsView = tw.div`flex w-14 text-blue-500 ml-2 gap-1`;
const Button = tw.div`w-6 h-6 flex items-center justify-center cursor-pointer`;

type Props = {
  receipt: ReceiptFieldsFragment | null;
  onSaveNotes: (notes: string) => void;
};
export const ReceiptNote: FC<Props> = ({ receipt, onSaveNotes }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [note, setNote] = useState(receipt?.notes ?? "");

  useEffect(() => {
    setNote(receipt?.notes ?? "");
  }, [receipt?.notes]);

  return (
    <NotesContainer>
      <NotesEdit>
        <FormattedMessage id="NOTES" tagName={NoteLabel} />
        <ButtonsView>
          {isEdit ? (
            <>
              <Button
                onClick={() => {
                  onSaveNotes(note);
                  setIsEdit(false);
                }}
              >
                <CheckOutlined fontSize="small" />
              </Button>
              <Button
                onClick={() => {
                  setNote(receipt?.notes ?? "");
                  setIsEdit(false);
                }}
              >
                <CloseOutlined fontSize="small" />
              </Button>
            </>
          ) : (
            <Button onClick={() => setIsEdit(true)}>
              <EditOutlined fontSize="small" />
            </Button>
          )}
        </ButtonsView>
      </NotesEdit>
      <If isTrue={!!note || isEdit}>
        <TextField
          value={note}
          onChange={(e) => setNote(e.target.value)}
          className={`flex-1 ${isEdit ? "bg-white" : ""}`}
          multiline
          staticText={!isEdit}
          InputProps={{
            className: "text-xs text-gray-600",
          }}
        />
      </If>
    </NotesContainer>
  );
};
