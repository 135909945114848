import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import { Check, CloseOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  DialogActions,
  DialogClasses,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MaterialUIDialog,
  Slide,
  SxProps,
  Toolbar,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FC, forwardRef, useEffect, useMemo } from "react";
import tw from "tailwind-styled-components";
import { WarningIcon } from "../dialog-icons/WarningIcon";
import { If } from "../if/If";
import { Loader } from "../loader/Loader";

type ButtonConfiguration = {
  wide?: boolean;
  narrow?: boolean;
  type?: "primary" | "outlined";
};

export type DialogProps = {
  show: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string | null;
  customButtonText?: string | null;
  handleCancel?: () => void;
  handleConfirm?: () => Promise<void | boolean> | undefined | void;
  handleCustomButtonAction?: () => Promise<void | boolean> | undefined | void;
  disabledConfirmButton?: boolean;
  disabledCustomButton?: boolean;
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  closeOnConfirm?: boolean;
  closeOnCustom?: boolean;
  saving?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullScreen?: boolean;
  fullScreenCentered?: boolean;
  extraButtonMargin?: boolean;
  buttonsConfiguration?: {
    cancel?: ButtonConfiguration;
    confirm?: ButtonConfiguration;
    custom?: ButtonConfiguration;
  };
  className?: string;
  titleClassName?: string;
  classes?: Partial<DialogClasses>;
  loading?: boolean;
  closingTimer?: number;
  hideTitle?: boolean;
  hasCloseIcon?: boolean;
  sx?: SxProps;
  includeWarningIcon?: boolean;
};

const IconContainer = tw.div`flex items-center justify-center mb-4`;
const DialogContentStyled = tw(DialogContent)<{
  $fullScreenCentered?: boolean;
}>`
  flex justify-flex-start flex-col
  ${({ $fullScreenCentered }: { $fullScreenCentered?: boolean }) => ($fullScreenCentered ? "flex-initial" : "")} `;
const DialogActionsStyled = tw(DialogActions)<{ $extraButtonMargin?: boolean }>`
  grid grid-flow-col gap-2.5 justify-center py-5 sticky -bottom-5 bg-white
  ${({ $extraButtonMargin }: { $extraButtonMargin?: boolean }) =>
    $extraButtonMargin ? "mt-10" : ""}`;
const DialogTitleStyled = tw(DialogTitle)<{ $fullScreenCentered?: boolean }>`
  text-center font-bold text-2xl pt-5
  ${({ $fullScreenCentered }: { $fullScreenCentered?: boolean }) => ($fullScreenCentered ? "-mt-16" : "my-auto")}`;
const DialogContentTextStyled = tw(
  DialogContent,
)`text-center text-black sm:px-0 md:px-10 font-normal text-lg self-center max-w-[800px]`;
const ToolbarStyled = tw(Toolbar)`
  gap-2
`;
const AppBarStyled = tw(AppBar)`
  bg-blue-800
`;
const TitleStyled = tw.div`
  grid grid-flow-col gap-2 items-center
  text-white font-medium text-xl w-fit mr-auto ml-5
`;

const ButtonContent = tw.div`flex flex-row`;
const IconButtonStyled = tw(IconButton)`absolute top-1 right-1`;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Dialog: FC<DialogProps> = ({
  confirmButtonText,
  cancelButtonText,
  show,
  handleConfirm,
  handleCancel,
  disabledConfirmButton,
  title,
  text,
  content,
  icon: defaultIcon = null,
  saving = false,
  maxWidth = "sm",
  fullScreen = false,
  fullScreenCentered = false,
  extraButtonMargin = true,
  buttonsConfiguration,
  className,
  classes,
  loading = false,
  titleClassName,
  closingTimer,
  hideTitle = false,
  hasCloseIcon = false,
  customButtonText,
  handleCustomButtonAction,
  disabledCustomButton,
  sx,
  includeWarningIcon,
}) => {
  const icon = useMemo(
    () => defaultIcon ?? (includeWarningIcon ? <WarningIcon /> : null),
    [defaultIcon, includeWarningIcon],
  );
  useEffect(() => {
    if (closingTimer) {
      const timeout = setTimeout(() => {
        handleCancel?.();
      }, closingTimer);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [closingTimer, handleCancel]);

  return (
    <MaterialUIDialog
      data-testid="dialog"
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullScreen={fullScreen || fullScreenCentered}
      className={className}
      classes={{
        paper: fullScreenCentered ? "justify-center top-16" : "rounded-3xl",
        ...classes,
      }}
      sx={sx}
      TransitionComponent={fullScreen ? Transition : undefined}
      onKeyDown={(e) => {
        if (e.key === EventKeysConstants.Escape) {
          handleCancel?.();
        }
      }}
    >
      {fullScreen ? (
        <AppBarStyled sx={{ position: "relative" }}>
          <ToolbarStyled>
            <TitleStyled id="alert-dialog-title" className={titleClassName}>
              {icon}
              {title}
            </TitleStyled>
            <PrimaryButton
              autoFocus
              color="inherit"
              onClick={handleCancel}
              wide={buttonsConfiguration?.cancel?.wide}
              narrow={buttonsConfiguration?.cancel?.narrow}
            >
              <ButtonContent>
                {cancelButtonText}
                <CloseOutlined />
              </ButtonContent>
            </PrimaryButton>
            {confirmButtonText && handleConfirm && (
              <OutlinedButton
                autoFocus
                color="inherit"
                onClick={handleConfirm}
                wide={buttonsConfiguration?.confirm?.wide}
                narrow={buttonsConfiguration?.confirm?.narrow}
              >
                <ButtonContent>
                  {confirmButtonText}
                  <Check />
                </ButtonContent>
              </OutlinedButton>
            )}
            {customButtonText && handleCustomButtonAction && (
              <OutlinedButton
                autoFocus
                color="inherit"
                onClick={handleCustomButtonAction}
                wide={buttonsConfiguration?.custom?.wide}
                narrow={buttonsConfiguration?.custom?.narrow}
              >
                <ButtonContent>
                  {customButtonText}
                  <Check />
                </ButtonContent>
              </OutlinedButton>
            )}
          </ToolbarStyled>
        </AppBarStyled>
      ) : (
        <If isTrue={!hideTitle}>
          <DialogTitleStyled
            className={titleClassName}
            id="alert-dialog-title"
            $fullScreenCentered={fullScreenCentered}
          >
            <If isTrue={hasCloseIcon}>
              <IconButtonStyled
                data-testid="close-icon-button"
                aria-label="close"
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButtonStyled>
            </If>
            <If isTrue={icon}>
              <IconContainer>{icon}</IconContainer>
            </If>
            {title}
          </DialogTitleStyled>
        </If>
      )}
      <DialogContentStyled $fullScreenCentered={fullScreenCentered}>
        <Loader loading={loading}>
          <If isTrue={text}>
            <DialogContentTextStyled id="alert-dialog-description">
              {text}
            </DialogContentTextStyled>
          </If>
          <If isTrue={content}>{content}</If>
          <If isTrue={!fullScreen && (cancelButtonText || confirmButtonText)}>
            <DialogActionsStyled $extraButtonMargin={extraButtonMargin}>
              {cancelButtonText ? (
                <OutlinedButton
                  onClick={handleCancel}
                  wide={buttonsConfiguration?.cancel?.wide}
                  narrow={buttonsConfiguration?.cancel?.narrow}
                  testId="modal-cancel-button"
                >
                  {cancelButtonText}
                </OutlinedButton>
              ) : null}
              {customButtonText && handleCustomButtonAction && (
                <>
                  {buttonsConfiguration?.custom?.type === "primary" ? (
                    <PrimaryButton
                      onClick={handleCustomButtonAction}
                      autoFocus
                      loading={saving}
                      disabled={disabledCustomButton}
                      wide={buttonsConfiguration?.custom?.wide}
                      narrow={buttonsConfiguration?.custom?.narrow}
                      testId="modal-custom-button"
                    >
                      {customButtonText}
                    </PrimaryButton>
                  ) : (
                    <OutlinedButton
                      onClick={handleCustomButtonAction}
                      autoFocus
                      loading={saving}
                      disabled={disabledCustomButton}
                      wide={buttonsConfiguration?.custom?.wide}
                      narrow={buttonsConfiguration?.custom?.narrow}
                      testId="modal-custom-button"
                    >
                      {customButtonText}
                    </OutlinedButton>
                  )}
                </>
              )}
              {confirmButtonText && handleConfirm && (
                <PrimaryButton
                  onClick={handleConfirm}
                  autoFocus
                  loading={saving}
                  disabled={disabledConfirmButton}
                  wide={buttonsConfiguration?.confirm?.wide}
                  narrow={buttonsConfiguration?.confirm?.narrow}
                  testId="modal-confirm-button"
                >
                  {confirmButtonText}
                </PrimaryButton>
              )}
            </DialogActionsStyled>
          </If>
        </Loader>
      </DialogContentStyled>
    </MaterialUIDialog>
  );
};
