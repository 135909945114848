import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { isUUID } from "@/common/utils/uuidUtils";
import {
  OrgMaterialFieldsFragment,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import {
  AddCircleOutlineOutlined,
  Check,
  Close,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useMaterialPrices } from "../../providers/MaterialPricesProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  index?: number;
  readonly?: boolean;
  price?: VendorPriceFieldsFragment | null;
  setEditMode: (value: boolean) => void;
};
const IconButtonContainer = tw.div`grid gap-2 grid-cols-[repeat(3,_19px)] px-1`;

export const MaterialPriceItemActions: FC<Props> = ({
  item,
  index,
  readonly = false,
  price,
  setEditMode,
}: Props) => {
  const {
    commitUpdate,
    removePrice,
    addNewPriceToMaterial,
    updating,
    adding,
    clearSubmittedPrice,
  } = useMaterialPrices();
  const { openDialog } = useDialog();
  const intl = useIntl();

  const onEdit = useCallback(() => {
    if (item.vendorPrices.length === 0) {
      addNewPriceToMaterial(item.id);
    }
    setEditMode(true);
  }, [setEditMode, item, addNewPriceToMaterial]);

  const onCancel = useCallback(async () => {
    clearSubmittedPrice(item.id);
    if (!isUUID(price?.id)) {
      await removePrice(price?.id || "");
    }
    setEditMode(false);
  }, [setEditMode, clearSubmittedPrice, item, price, removePrice]);

  const onSave = useCallback(async () => {
    const result = await commitUpdate(item.id, index ?? 0);
    if (result) {
      setEditMode(false);
    }
  }, [commitUpdate, item, setEditMode, index]);

  const onAdd = useCallback(() => {
    addNewPriceToMaterial(item.id);
  }, [addNewPriceToMaterial, item]);

  const onRemove = useCallback(() => {
    openDialog({
      confirmButtonText: intl.$t({ id: "REMOVE" }),
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      includeWarningIcon: true,
      title: intl.$t({ id: "REMOVE_MATERIAL_PRICE" }),
      text: intl.$t({ id: "REMOVE_MATERIAL_PRICE_CONFIRMATION" }),
      handleConfirm: async () => {
        if (price) {
          await removePrice(price?.id);
        }
      },
    });
  }, [openDialog, intl, price, removePrice]);

  const showAddButton = useMemo(() => {
    return (
      ((item.vendorPrices.length > 1 &&
        index === item.vendorPrices.length - 1) ||
        (item.vendorPrices.length === 1 && index === undefined)) &&
      isUUID(price?.id)
    );
  }, [item, index, price]);

  const icons = useMemo(() => {
    if (!readonly) {
      return [
        <If key={0} isTrue={showAddButton}>
          <IconButtonBorderless onClick={onAdd} className="col-start-1">
            <AddCircleOutlineOutlined />
          </IconButtonBorderless>
        </If>,
        <IconButtonBorderless
          onClick={onSave}
          key={1}
          className="col-start-2"
          loading={updating || adding}
        >
          <Check />
        </IconButtonBorderless>,
        <IconButtonBorderless
          key={2}
          onClick={onCancel}
          className="col-start-3"
        >
          <Close />
        </IconButtonBorderless>,
      ];
    } else {
      return [
        <If key={0} isTrue={showAddButton}>
          <IconButtonBorderless onClick={onAdd} className="col-start-1">
            <AddCircleOutlineOutlined />
          </IconButtonBorderless>
        </If>,
        <IconButtonBorderless
          key={1}
          onClick={onEdit}
          loading={(updating || adding) && !readonly}
          className="col-start-2"
        >
          <EditOutlined />
        </IconButtonBorderless>,
        <If isTrue={item.vendorPrices.length > 0} key={2}>
          <IconButtonBorderless onClick={onRemove} className="col-start-3">
            <DeleteOutline />
          </IconButtonBorderless>
        </If>,
      ];
    }
  }, [
    item,
    readonly,
    onEdit,
    onCancel,
    onSave,
    onRemove,
    onAdd,
    showAddButton,
    updating,
    adding,
  ]);

  return <IconButtonContainer>{icons.map((icon) => icon)}</IconButtonContainer>;
};
