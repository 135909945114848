import { DistributorBuyoutFieldsFragment } from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ItemContainer } from "../additional-charges/AdditionalCharges.styles";
import { If } from "../if/If";
import { NotNullableRenderer } from "../not-nullable-renderer/NotNullableRenderer";
import { NumericalInput } from "../numerical-input/NumericalInput";

const StyledNumericalInput = tw(NumericalInput)`w-full`;
const Item = tw.div`font-medium text-sm`;

type Props = {
  includePaymentTerms?: boolean;
  readonly?: boolean;
  item:
    | Pick<DistributorBuyoutFieldsFragment, "id" | "paymentTermDays">
    | undefined
    | null;
  onChange: (value: number) => void;
};

export const PaymentTerms: FC<Props> = ({
  item,
  includePaymentTerms = true,
  readonly,
  onChange,
}) => {
  const [paymentTerm, setPaymentTerm] = useState(item?.paymentTermDays || 0);

  return (
    <If isTrue={includePaymentTerms}>
      <If isTrue={readonly}>
        <ItemContainer className="mt-0 h-10">
          <FormattedMessage id="PAYMENT_TERMS" tagName={Item} />
          <NotNullableRenderer value={paymentTerm}>
            <FormattedMessage
              id="PAYMENT_TERM_NET_WITH_DAYS"
              values={{ days: item?.paymentTermDays }}
            />
          </NotNullableRenderer>
        </ItemContainer>
      </If>
      <If isTrue={!readonly}>
        <ItemContainer className="h-10">
          <FormattedMessage id="PAYMENT_TERMS" tagName={Item} />
          <StyledNumericalInput
            value={paymentTerm}
            onChange={(e) => setPaymentTerm(Number(e.target.value))}
            onBlur={() => {
              if (!item) {
                return;
              }
              onChange(paymentTerm);
            }}
            className="w-full"
            xs
            inputProps={{
              className: "text-right bg-white",
            }}
          />
        </ItemContainer>
      </If>
    </If>
  );
};
