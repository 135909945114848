import { Dialog } from "@/common/components/dialog/Dialog";
import { ReleaseDateTimePicker } from "@/contractor/pages/home/release/pages/specify-details/components/release-date-time-picker/ReleaseDateTimePicker";
import { useReleaseActions } from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const DateTimeContainer = tw.div`flex items-center justify-center pt-2`;

interface DeliverySlipReceiveDialogProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  handleConfirm: () => Promise<void>;
  confirming: boolean;
}

export const DeliverySlipReceiveDialog = ({
  visible,
  setVisible,
  handleConfirm,
  confirming,
}: DeliverySlipReceiveDialogProps) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { requestedDate, setRequestedDate } = useReleaseActions();
  const { updateRelease, updating } = useReleaseUpdate();

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onDateConfirm = async (date: Date | null | undefined) => {
    const isTBD = date === undefined;
    setRequestedDate(date ?? null);
    const currentRelease = release;

    if (currentRelease) {
      const input: UpdateContractorReleaseInput = {
        releaseId: currentRelease?.id,
        version: currentRelease?.version,
      };

      input.requestedTime = !isTBD && date ? date.getTime() : undefined;
      input.timeTBD = isTBD;

      await updateRelease(input);
    }
  };

  return (
    <Dialog
      includeWarningIcon
      title={
        <FormattedMessage
          id={"RECEIVE_PACKING_SLIP_CONFIRMATION"}
          values={{ br: <br /> }}
        />
      }
      saving={confirming || updating}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      disabledConfirmButton={!release?.time}
      confirmButtonText={intl.$t({
        id: "PROCEED",
      })}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      loading={false}
      show={visible}
      content={
        <DateTimeContainer>
          <ReleaseDateTimePicker
            value={requestedDate}
            onChange={onDateConfirm}
            includeCustomAction={false}
          />
        </DateTimeContainer>
      }
    />
  );
};
