import { If } from "@/common/components/if/If";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { usaStates } from "@/common/utils/usaStates";
import { isEmptyString } from "@/common/utils/validationUtils";
import { Add } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  LineItem,
  LineItemLabel,
  LineItemSplit,
  ListItem,
} from "../../Vendors.styles";
import { useOrgs } from "../../providers/OrgsProvider";
import { ExtendedOrgFieldsFragment } from "./PreferredVendorDetailsSection";

const PreferredVendorDetailsContainer = tw.div`flex flex-col gap-2`;

type Props = {
  vendorId?: string;
  readonly?: boolean;
  extraOrgs?: ExtendedOrgFieldsFragment[];
};

export const NEW_BRANCH_ID = "NEW_BRANCH_ID";

export const VendorBranchSection: FC<Props> = ({
  vendorId,
  readonly,
  extraOrgs,
}) => {
  const intl = useIntl();
  const { orgs } = useOrgs();
  const { watch, setValue } = useFormContext();
  const state = watch("state");
  const country = watch("newVendor.location.country");
  const isRequiredState = useMemo(() => isDefaultCountry(country), [country]);

  const customRender = useCallback(
    (item: { id: string; name: string }) => (
      <ListItem $isAdd={item.id === NEW_BRANCH_ID}>
        <If isTrue={item.id === NEW_BRANCH_ID}>
          <Add />
        </If>
        {item.name}
      </ListItem>
    ),
    [],
  );

  const locationOptions = useMemo(() => {
    const completeOrgs = (orgs as ExtendedOrgFieldsFragment[]).concat(
      extraOrgs || [],
    );

    const filterOrgs = completeOrgs
      .filter((org) => !!org)
      .filter((org) => org.id === vendorId)
      .flatMap((org) =>
        org.locations.filter((location) => location.address?.state === state),
      )
      .map((location) => ({
        id: location.id,
        name: `${location.address?.addressLine1}, ${location.address?.city}`,
      }));

    return [
      {
        id: NEW_BRANCH_ID,
        name: intl.$t({ id: "ADD_NEW_BRANCH" }),
      },
    ].concat(filterOrgs);
  }, [extraOrgs, orgs, state, vendorId, intl]);

  return (
    <PreferredVendorDetailsContainer>
      <FormattedMessage id="PREFERRED_BRANCH" tagName={LineItemLabel} />
      <LineItem>
        <LineItemSplit>
          {isRequiredState ? (
            <SelectControlled
              options={usaStates}
              name="state"
              getLabel={(option) => option.abbreviation}
              getValue={(option) => option.abbreviation}
              className="min-w-32"
              placeholder={intl.$t({ id: "STATE" })}
              rules={{ required: true }}
              staticText={readonly}
            />
          ) : (
            <TextFieldControlled
              size="small"
              name="country"
              label={intl.$t({ id: "ADDRESS_STATE" })}
              rules={{ required: isRequiredState }}
              staticText={readonly}
            />
          )}
          <If isTrue={!isEmptyString(state)}>
            <SelectControlled
              options={locationOptions}
              placeholder={intl.$t({
                id: readonly ? "BRANCH" : "SEARCH_BRANCH",
              })}
              name="location"
              getLabel={(option) => option.name}
              getOptionLabel={(option) =>
                option.id === NEW_BRANCH_ID ? "" : option.name
              }
              onChange={(value) => {
                if (value === NEW_BRANCH_ID) {
                  setValue("location", NEW_BRANCH_ID);
                } else {
                  setValue("location", value);
                }
              }}
              customRender={customRender}
              getValue={(option) => option.id}
              className="min-w-32"
              rules={{ required: true }}
              staticText={readonly}
            />
          </If>
        </LineItemSplit>
      </LineItem>
    </PreferredVendorDetailsContainer>
  );
};
