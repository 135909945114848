import { Group } from "@/common/components/groups/common/types/types";
import { GroupSelector } from "@/common/components/groups/components/group-selector/GroupSelector";
import { UpdateInvoicesInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useOrderGroups } from "../../hooks/useOrderGroups";
import { useReleasesUpdate } from "../../hooks/useReleasesUpdate";
import { useReleaseSequence } from "../../providers/ReleaseSequenceProvider";

type Props = {
  removing?: boolean;
  onClose: () => void;
  existingGroups?: Group[];
};

export const ReleaseGroupSelector: FC<Props> = ({
  removing,
  onClose,
  existingGroups = [],
}) => {
  const { groups } = useOrderGroups();
  const { sequenceIds, selectedReleases, setSelectedReleases } =
    useReleaseSequence();
  const { createGroup } = useOrderGroups();
  const { updateReleases } = useReleasesUpdate();

  const filteredGroups = useMemo(
    () => (removing ? existingGroups : groups),
    [groups, removing, existingGroups],
  );

  const updateEntity = useCallback(
    async (input: Omit<UpdateInvoicesInput, "invoiceIds">) => {
      const updateResult = await updateReleases({
        releaseIds: sequenceIds,
        assignedGroupIds: input.assignedFolderIds,
        unassignedGroupIds: input.unassignedFolderIds,
      });
      if (!updateResult) {
        return;
      }
      const selectedReleasesCopy = selectedReleases.map((release) => {
        const item = {
          ...release,
          groups: release.groups ? [...release.groups] : [],
        };
        input.assignedFolderIds?.forEach((id) => {
          const originalGroup = groups.find((group) => group.id === id);
          if (originalGroup && !item.groups.find((group) => group.id === id)) {
            item.groups.push({
              id: originalGroup.id,
              name: originalGroup.name,
            });
          }
        });
        input.unassignedFolderIds?.forEach((id) => {
          item.groups = item.groups.filter((group) => group.id !== id);
        });
        return item;
      });
      setSelectedReleases(selectedReleasesCopy);
    },
    [
      updateReleases,
      sequenceIds,
      groups,
      selectedReleases,
      setSelectedReleases,
    ],
  );

  return (
    <GroupSelector
      createGroup={createGroup}
      groups={filteredGroups}
      onClose={onClose}
      removing={removing}
      updateEntity={updateEntity}
    />
  );
};
