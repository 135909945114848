import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { ProjectFormProjectInformationSection } from "@/contractor/pages/home/projects/components/project-form/forms/ProjectFormProjectInformationSection";
import { AssetContext } from "@/generated/graphql";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { ExternalProjectMappingModal } from "../../components/project-mapping/ExternalProjectMappingModal";
import { ExternalProjectMappingProvider } from "../../components/project-mapping/ExternalProjectMappingProvider";
import { AgaveExternalProjectProvider } from "../../providers/AgaveExternalProjectProvider";

const Container = tw.div`my-6`;

const ProjectDetailsWithProvider = () => {
  const { project } = useProject();

  const readonly = useMemo(() => !!project?.deletedAt, [project]);

  if (!project) {
    return null;
  }

  return (
    <>
      <Container>
        <ProjectFormProjectInformationSection
          project={project}
          readonly={readonly}
        />
      </Container>
      <ExternalProjectMappingModal />
    </>
  );
};

export const ProjectDetails = () => {
  const { project } = useProject();

  return (
    <UploadAssetProvider
      initialAssets={project?.releaseInstructions?.assets}
      context={AssetContext.Instruction}
      projectId={project?.id}
    >
      <AgaveExternalProjectProvider>
        <ExternalProjectMappingProvider>
          <ProjectDetailsWithProvider />
        </ExternalProjectMappingProvider>
      </AgaveExternalProjectProvider>
    </UploadAssetProvider>
  );
};
