import { BuyoutStandaloneReleaseItemFieldsFragment } from "@/generated/graphql";

import { GridTable } from "@/common/components/grid-table/GridTable";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../../document/providers/BuyoutGroupedProvider";
import { useBuyoutStandaloneReleaseItems } from "../table-configurations/BuyoutStandaloneReleaseItems.configuration";

export type BuyoutStandaloneReleaseItemFieldsFragmentWithId =
  BuyoutStandaloneReleaseItemFieldsFragment & { id: string };

const Container = tw.div`mt-5`;

export const BuyoutStandaloneReleaseItemsList = () => {
  const { buyout, expandedItems } = useContractorBuyout();
  const {
    groupedStandaloneItemsByCostCode,
    toggleStandaloneCategory,
    standaloneItems,
  } = useBuyoutGrouped();

  const configuration = useBuyoutStandaloneReleaseItems({
    buyout,
  });
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const items = useMemo(() => {
    if (groupedStandaloneItemsByCostCode) {
      return standaloneItems;
    }
    return [
      {
        id: unassignedCostCode.id,
        name: unassignedCostCode.description,
        isOpened: true,
        items:
          buyout?.standaloneReleaseItems.map((i, key) => ({
            ...i,
            id: key.toString(),
          })) || [],
      },
    ];
  }, [
    buyout?.standaloneReleaseItems,
    groupedStandaloneItemsByCostCode,
    standaloneItems,
    unassignedCostCode.description,
    unassignedCostCode.id,
  ]);

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <If isTrue={buyout.standaloneReleaseItems.length}>
      <GridTable<BuyoutStandaloneReleaseItemFieldsFragmentWithId>
        configuration={{
          container: Container,
          columns: configuration,
          classNames: {
            header: "top-12",
            category: "top-[36px] lg:top-[86px] bg-yellow-200",
            detailsContent: "lg:p-0",
          },
          toggle: {
            category: toggleStandaloneCategory,
          },
        }}
        items={items}
        hideGroup={!groupedStandaloneItemsByCostCode}
        expandedItems={(item) => getExpandedItems(item, expandedItems)}
      />
    </If>
  );
};
