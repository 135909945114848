import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  SmFixedContainer,
  XlContainer,
} from "@/common/layout/ResponsiveClasses";
import { ServiceCodeFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";

export const useImportExternalServiceCodesResultsConfiguration = (): Array<
  GridCol<ServiceCodeFieldsFragment>
> => {
  return [
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item }) => item.description,
    },
    {
      wrapper: SmFixedContainer,
      header: <FormattedMessage id="EQUIPMENT_NUMBER" />,
      item: ({ item }) => item.code,
      position: "center",
    },
  ];
};
