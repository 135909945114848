import { If } from "@/common/components/if/If";
import { UserAvatar } from "@/common/components/user-avatar/UserAvatar";
import { APPROVED_INVOICES_STATUS } from "@/common/const";
import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { ReceiptFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-row`;
const Container = tw.div`mt-2 grid grid-cols-2`;
const CreatedInfoContainer = tw.div`flex flex-col items-start gap-0.5 text-xs text-gray-600`;
const Col = tw.div`ml-2`;
const SmallGrayText = tw.div`text-xs text-gray-600`;

type Props = {
  receipt: ReceiptFieldsFragment;
};

export const ReceiptHeaderCreatedApproved: FC<Props> = ({ receipt }) => {
  const { isContractor } = useUser();

  if (!receipt.createdAt && !receipt.createdBy) {
    return null;
  }

  return (
    <Container>
      <CreatedInfoContainer>
        <Row>
          <UserAvatar
            firstName={receipt.createdBy?.firstName}
            lastName={receipt.createdBy?.lastName}
          />
          <Col>
            <FormattedMessage
              id={"ADDED_BY"}
              values={{
                username: getUserName(receipt.createdBy),
              }}
            />
            <SmallGrayText>
              <FormattedDate
                value={receipt.createdAt || undefined}
                year="numeric"
                month="2-digit"
                day="2-digit"
                hour="2-digit"
                minute="2-digit"
              />
            </SmallGrayText>
          </Col>
        </Row>
      </CreatedInfoContainer>
      <If
        isTrue={
          receipt?.status &&
          APPROVED_INVOICES_STATUS.includes(receipt.status) &&
          isContractor
        }
      >
        <CreatedInfoContainer>
          <Row>
            <If isTrue={receipt.approvedBy}>
              <UserAvatar
                firstName={receipt.approvedBy?.firstName}
                lastName={receipt.approvedBy?.lastName}
              />
            </If>
            <Col>
              <If isTrue={receipt.approvedBy}>
                <FormattedMessage
                  id={"APPROVED_BY"}
                  values={{
                    username: getUserName(receipt.approvedBy),
                  }}
                />
              </If>
              <SmallGrayText>
                <FormattedDate
                  value={receipt.approvedAt || undefined}
                  year="numeric"
                  month="2-digit"
                  day="2-digit"
                  hour="2-digit"
                  minute="2-digit"
                />
              </SmallGrayText>
            </Col>
          </Row>
        </CreatedInfoContainer>
      </If>
    </Container>
  );
};
