import { Loader } from "@/common/components/loader/Loader";
import { FC } from "react";
import { BuyoutDocument } from "./components/document/BuyoutDocument";
import {
  ContractorBuyoutProvider,
  useContractorBuyout,
} from "./providers/ContractorBuyoutProvider";

type Props = { openedMessages?: boolean };

const BuyoutWithProvider: FC<Props> = () => {
  const { buyout } = useContractorBuyout();

  if (!buyout) {
    return <Loader loading />;
  }

  return <BuyoutDocument />;
};

export const Buyout: FC<Props> = (props) => {
  return (
    <ContractorBuyoutProvider>
      <BuyoutWithProvider {...props} />
    </ContractorBuyoutProvider>
  );
};
