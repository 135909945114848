import { LoadingButton } from "@/common/components/button/LoadingButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { useTableHeaders } from "@/common/components/spreadsheet-table/hooks/useTableHeaders";
import { HotTable, HotTableClass } from "@handsontable/react";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { OrgsProvider, useOrgs } from "../vendors/providers/OrgsProvider";
import { GMVCharts } from "./components/GMVCharts";
import { useDataProcessor } from "./hooks/useDataProcessor";
import { useExcludedOrgs } from "./hooks/useExcludedOrgs";
import { useGMVReport } from "./hooks/useGMVReport";

const FORMAT = "yyyy-MM-dd";

const Filters = tw.div`flex gap-2 items-center mt-5`;
const Content = tw.div`mt-3 grid w-full relative`;
const Container = tw.div`flex justify-center flex-col w-full`;

export const camelToTitle = (camel: string) =>
  camel.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

const GMVReportWithProviders = () => {
  const intl = useIntl();
  const { orgs, loading: loadingOrgs } = useOrgs();
  const { data, loading: loadingGMVReport, loadGMVReport } = useGMVReport();
  const { regularHeader } = useTableHeaders();
  const { process } = useDataProcessor();
  const { getExcludedOrgsByDate, excludedOrgs, updateExcludedOrgs } =
    useExcludedOrgs();

  const [filter, setFilter] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const hotTableComponent = useRef<HotTableClass>(null);

  const processedData = useMemo(
    () =>
      data?.systemReport
        ? process(data?.systemReport).toSorted(
            (a, b) => (b.total as number) - (a.total as number),
          )
        : [],
    [data?.systemReport, process],
  );

  const headers = useMemo(() => {
    if (processedData.length) {
      return Object.keys(processedData[0]).map((key) =>
        regularHeader(camelToTitle(key)),
      );
    }
    return [];
  }, [processedData, regularHeader]);

  const columns = useMemo(() => {
    if (processedData.length) {
      const firstRow = processedData[0];
      if (firstRow) {
        return Object.keys(firstRow).map((key, index) => ({
          data: key,
          type: "numeric",
          width: index === 0 ? 200 : 75,
          numericFormat: key.toLowerCase().includes("count")
            ? {
                pattern: "0,0",
                culture: "en-US",
              }
            : {
                pattern: "$0,0.00",
                culture: "en-US",
              },
        }));
      }
    }
    return [];
  }, [processedData]);

  const load = useCallback(() => {
    if (filter.from && filter.to && excludedOrgs) {
      loadGMVReport({
        variables: {
          excludedOrgIDs: getExcludedOrgsByDate(excludedOrgs, filter.from),
          from: format(filter.from, FORMAT),
          to: format(filter.to, FORMAT),
        },
      });
    }
  }, [filter, excludedOrgs, getExcludedOrgsByDate, loadGMVReport]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedOrgs]);

  const handleChangeExcludedOrgs = useCallback(
    (orgs: string[] | null) => {
      updateExcludedOrgs(orgs ?? []);
    },
    [updateExcludedOrgs],
  );

  const downloadCSV = useCallback(() => {
    const hotInstance = hotTableComponent.current?.hotInstance;
    if (hotInstance) {
      const exportPlugin = hotInstance.getPlugin("exportFile");
      exportPlugin.downloadFile("csv", {
        bom: false,
        columnDelimiter: ",",
        columnHeaders: true,
        exportHiddenColumns: true,
        exportHiddenRows: true,
        fileExtension: "csv",
        filename: "GMVReport",
        mimeType: "text/csv",
        rowDelimiter: "\r\n",
        rowHeaders: true,
      });
    }
  }, []);

  return (
    <Container>
      <Filters>
        <DateRangePicker
          selectedValue={
            filter?.from || filter?.to
              ? {
                  startDate: filter?.from ? filter.from : new Date(),
                  endDate: filter?.to ? filter.to : new Date(),
                }
              : undefined
          }
          isUTC
          onChange={(dateRange) =>
            setFilter({
              from: dateRange.startDate,
              to: dateRange.endDate,
            })
          }
          titleId="SELECT_EXTERNAL_NOTIFICATIONS_DATE_RANGE"
          includeLabel
        />
        <Multiselect
          label={intl.$t({ id: "EXCLUDED_ORGS" })}
          loading={loadingOrgs}
          options={orgs}
          getLabel={(o) => o.name}
          getValue={(o) => o.id}
          values={excludedOrgs}
          onMultipleChange={handleChangeExcludedOrgs}
          disableCloseOnSelect
          chipSize="small"
          noWrap
          limitTags={1}
        />
        <LoadingButton
          button={PrimaryButton}
          onClick={load}
          loading={loadingGMVReport}
          $small
          className="h-6 pr-4"
        >
          <FormattedMessage id="LOAD" />
        </LoadingButton>
      </Filters>
      <Content>
        <If isTrue={loadingGMVReport}>
          <Loader loading />
        </If>
        <If isTrue={processedData.length && !loadingGMVReport}>
          <HotTable
            ref={hotTableComponent}
            data={processedData}
            colHeaders={headers}
            rowHeaders={true}
            columns={columns}
            height="auto"
            autoWrapRow={true}
            autoWrapCol={true}
            stretchH="all"
            width="100%"
            columnSorting
            licenseKey="non-commercial-and-evaluation"
          />
        </If>
        <PrimaryButton
          className="mt-2 justify-self-start"
          onClick={downloadCSV}
        >
          <FormattedMessage id="DOWNLOAD" />
        </PrimaryButton>
      </Content>
      <GMVCharts excludedOrgs={excludedOrgs} />
    </Container>
  );
};

export const GMVReport = () => (
  <OrgsProvider>
    <GMVReportWithProviders />
  </OrgsProvider>
);
