import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { TaxMode } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const SalesTaxPicker = () => {
  const intl = useIntl();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const hasTaxAuthorities = useMemo(
    () =>
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.IncludeTaxAuthorities,
      ),
    [hasFeatureInConnectedSourceSystem],
  );

  const options = useMemo(() => {
    return [
      TaxMode.Blended,
      TaxMode.Majority,
      TaxMode.Separate,
      ...(hasTaxAuthorities ? [TaxMode.Column] : []),
    ].map((mode) => ({
      value: mode,
      label: intl.$t({ id: `SALES_TAX_${mode}` }),
    }));
  }, [hasTaxAuthorities, intl]);

  return (
    <SelectControlled
      name="taxMode"
      getLabel={(option) => option.label}
      getValue={(option) => option.value}
      options={options}
      placeholder={intl.$t({ id: "SALES_TAX" })}
      rules={{ required: true }}
    />
  );
};
