import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useMissingBudgetCombinations } from "../../../../../providers/MissingBudgetCombinationsProvider";

export const MissingBudgetCombinationsModal = () => {
  const { modalOpened, pages, closeModal } = useMissingBudgetCombinations();

  return (
    <WizardModal
      mode={WizardModalMode.SINGLE_PAGE}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};
