import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { PoValidationFieldsFragment, SourceSystem } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { ErrorOutline } from "@mui/icons-material";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { MissingBudgetCombinationsList } from "../components/connection-types/hosted/steps/missing-budget-combinations/MissingBudgetCombinationsList";

const Header = tw.div`flex flex-row gap-2 items-center`;
const ErrorOutlineStyled = tw(ErrorOutline)`text-red-500`;
const Title = tw.div`font-bold text-lg`;

export type MissingBudgetCombinationType =
  PoValidationFieldsFragment["missingBudgetCombinations"][0] & {
    id: string;
  };

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null | undefined;
  modalOpened: boolean;
  openModal: ({
    sourceSystem,
    missingBudgetCombinations,
    projectId,
  }: {
    sourceSystem?: SourceSystem;
    missingBudgetCombinations: MissingBudgetCombinationType[];
    projectId: string | undefined;
  }) => void;
  closeModal: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,
});

export const MissingBudgetCombinationsProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [missingBudgetCombinations, setMissingBudgetCombinations] = useState<
    MissingBudgetCombinationType[]
  >([]);
  const { setNestedStep, setStep } = useNestedStepper();
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  useSetCurrentProjectId(projectId);

  const { zones } = useProjectStore(
    useShallow((state) => ({ zones: state.zones })),
  );

  const openModal = useCallback(
    ({
      sourceSystem,
      missingBudgetCombinations,
      projectId,
    }: {
      sourceSystem?: SourceSystem;
      missingBudgetCombinations: MissingBudgetCombinationType[];
      projectId: string | undefined;
    }) => {
      setSourceSystem(sourceSystem ?? null);
      setMissingBudgetCombinations(missingBudgetCombinations);
      setModalOpened(true);
      setProjectId(projectId);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSourceSystem(null);
    setNestedStep(0);
    setStep(0);
  }, [setNestedStep, setStep]);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
  }, [resetModal]);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            classNames: {
              innerHeader: "bg-white",
            },
            customHeader: (
              <Header>
                <ErrorOutlineStyled />
                <FormattedMessage
                  id="MISSING_BUDGET_COMBINATIONS_TITLE"
                  tagName={Title}
                />
                <InfoTooltip
                  message={intl.$t(
                    {
                      id: "MISSING_BUDGET_COMBINATIONS_TOOLTIP",
                    },
                    {
                      integration: sourceSystem
                        ? intl.$t({
                            id: `INTEGRATION_${sourceSystem}`,
                          })
                        : "",
                    },
                  )}
                />
              </Header>
            ),
            component: (
              <MissingBudgetCombinationsList
                missingBudgetCombinations={missingBudgetCombinations}
                includeZones={
                  zones.some((zone) => zone.externalSubJob?.id) &&
                  hasFeatureInConnectedSourceSystem(
                    IntegrationFeature.SupportPhasesOrExtrasAsZones,
                  )
                }
              />
            ),
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
            ],
          },
        ],
      },
    ],
    [
      intl,
      sourceSystem,
      missingBudgetCombinations,
      zones,
      hasFeatureInConnectedSourceSystem,
      closeModal,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMissingBudgetCombinations = () => useContext(ProviderContext);
