import { QueryInvoiceEmailsFilter } from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  filter: QueryInvoiceEmailsFilter | undefined;
  setFilter: (step: QueryInvoiceEmailsFilter) => void;
};

export const useInvoicesStore = create<State>()(
  devtools((set) => ({
    filter: undefined,
    setFilter: (filter: QueryInvoiceEmailsFilter) =>
      set(() => ({
        filter,
      })),
  })),
);
