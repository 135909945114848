import { useUser } from "@/common/providers/UserProvider";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
 border border-gray-500 p-2 w-40 rounded-lg cursor-pointer text-gray-500 text-center relative flex justify-center
`;
const Image = tw.img`
  h-24 w-24 rounded-full
`;

type Props = {
  removeImage: () => void;
};

export const ProfileImage: FC<Props> = () => {
  const { viewer } = useUser();

  return (
    <Container>
      {viewer?.photoUrl ? (
        <Image loading="lazy" src={viewer.photoUrl} alt={viewer.firstName} />
      ) : (
        <FormattedMessage id="PHOTO_UPLOAD_PLACEHOLDER" />
      )}
    </Container>
  );
};
