import { AdditionalFiles } from "@/common/components/additional-files/AdditionalFiles";
import { If } from "@/common/components/if/If";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { CustomPaymentTerms } from "@/common/components/terms-and-discounts/CustomPaymentTerms";
import { NotesAndTerms } from "@/common/components/terms-and-discounts/NotesAndTerms";
import { useUser } from "@/common/providers/UserProvider";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import {
  AssetContext,
  ReleaseStatus,
  UpdateContractorReleaseInput,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ReleaseAdditionalChargesAndTaxesProps } from "../release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "../upload-asset/UploadAssetProvider";
import { Label } from "./ReleaseTermsAndNotes.styles";

const Container = tw.div`flex flex-col gap-4`;

type Props = {
  onClose: () => void;
} & Pick<ReleaseAdditionalChargesAndTaxesProps, "release" | "updateRelease">;

const ReleaseTermsAndNotesPanelWithProvider: FC<Props> = ({
  onClose,
  release,
  updateRelease,
}) => {
  const intl = useIntl();
  const { isContractor } = useUser();
  const { assets } = useUploadAssets();

  const [paymentTerm, setPaymentTerm] = useState(
    release?.paymentTerm || release?.paymentTerm === 0
      ? release.paymentTerm.toString()
      : null,
  );
  const [notes, setNotes] = useState(release?.notes || "");

  useEffect(() => {
    setPaymentTerm(
      release?.paymentTerm || release?.paymentTerm === 0
        ? release.paymentTerm.toString()
        : null,
    );
  }, [release?.paymentTerm]);

  useEffect(() => {
    setNotes(release?.notes || "");
  }, [release?.notes]);

  const save = async () => {
    if (release) {
      let input: UpdateVendorReleaseInput | UpdateContractorReleaseInput = {
        releaseId: release.id,
        version: release.version,
        paymentTerm: Number(paymentTerm ?? 0),
      };
      if (!isContractor) {
        input = {
          ...input,
          notes,
          assetUrls: assets.map((asset) => asset.url),
        };
      }
      const result = await updateRelease?.(input);
      if (
        !result &&
        !isContractor &&
        !checkReleaseStatus(release, [ReleaseStatus.Scheduled])
      ) {
        return;
      }
    }
    onClose();
  };

  return (
    <OverlayPanel
      title={intl.$t({ id: "NOTES_TERMS_DOCS" })}
      onSave={!isContractor ? save : undefined}
      onCancel={onClose}
    >
      <Container>
        <If isTrue={!isContractor}>
          <CustomPaymentTerms
            paymentTerm={paymentTerm}
            updateItem={setPaymentTerm}
            staticText={isContractor}
          />
        </If>
        <If isTrue={!isContractor || release?.notes}>
          <If isTrue={!isContractor}>
            <FormattedMessage id="NOTES_AND_TERMS" tagName={Label} />
          </If>
          <NotesAndTerms
            notes={notes}
            updateItem={setNotes}
            readonly={isContractor}
          />
        </If>
        <AdditionalFiles readonly={isContractor} />
      </Container>
    </OverlayPanel>
  );
};

export const ReleaseTermsAndNotesPanel: FC<Props> = (props) => {
  const { release } = props;
  return (
    <UploadAssetProvider
      initialAssets={release?.assets}
      context={AssetContext.Note}
      projectId={release?.project?.id}
    >
      <ReleaseTermsAndNotesPanelWithProvider {...props} />
    </UploadAssetProvider>
  );
};
