import { FilterAltOff } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../button/IconButton";
import { FilterCountIndicator } from "../list-header-filter-button/FilterCountIndicator";
import { Tooltip } from "../tooltip/Tooltip";

const Container = tw.div`relative`;

export const ClearFiltersButton = ({
  onClick,
  count,
}: {
  onClick: () => void;
  count: number;
}) => {
  if (count === 0) {
    return null;
  }

  return (
    <Container>
      <Tooltip
        element={
          <IconButtonBorderless autoScale onClick={onClick}>
            <FilterAltOff />
          </IconButtonBorderless>
        }
      >
        <FormattedMessage id={"RESET_FILTERS"} />
      </Tooltip>
      <FilterCountIndicator>{count}</FilterCountIndicator>
    </Container>
  );
};
