import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { isLumpSumItem } from "../../../../../../../../../../common/utils/lumpSumItemUtils";
import { useInvoiceValidation } from "../../../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

type Props = {
  items: ExpandedReleaseItem[];
};

const Container = tw.div`flex`;

export const InvoiceReleaseItemWholeSelectionCheckbox: FC<Props> = ({
  items,
}) => {
  const { updateInvoice, invoice } = useInvoiceVerification();
  const { release } = useRelease();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { refetchInvoiceValidation } = useInvoiceValidation();

  const checked = useMemo(
    () =>
      items
        .filter((item) => !hasOrderItemPoItemReferences(item, release))
        .every(
          (item) =>
            item.invoiceItems.length &&
            item.invoiceItems.every((invoiceItem) => invoiceItem.id),
        ),
    [hasOrderItemPoItemReferences, items, release],
  );

  const handleChange = useCallback(
    async (newChecked: boolean) => {
      const addedInvoicedReleaseItems = newChecked
        ? items
            .filter((item) => (item.invoiceItems || []).length === 0)
            .map((item) => ({
              id: item.id,
              releaseItemId: item.id,
              quantity: item.quantityDecimal || "",
              unitPrice: item.unitPrice
                ? item.unitPrice.toString()
                : isLumpSumItem(item)
                  ? "1"
                  : "0",
            }))
        : undefined;
      const removedInvoicedReleaseItems = !newChecked
        ? (items
            .map((item) => item.invoiceItems?.[0].id)
            .filter((id) => id) as string[])
        : undefined;
      await updateInvoice(
        {
          id: invoice?.id || "",
          addedInvoicedReleaseItems,
          removedInvoicedReleaseItems,
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
      refetchInvoiceValidation();
    },
    [
      items,
      updateInvoice,
      invoice?.id,
      invoice?.release?.id,
      refetchInvoiceValidation,
    ],
  );

  const hasSelectableItems = useMemo(
    () => !items.some((item) => hasOrderItemPoItemReferences(item, release)),
    [hasOrderItemPoItemReferences, items, release],
  );

  if (!hasSelectableItems) {
    return null;
  }

  return (
    <Container>
      <SelectionCheckbox checked={checked} setSelection={handleChange} />
    </Container>
  );
};
