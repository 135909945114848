import { If } from "@/common/components/if/If";
import { Address as TAdress } from "@/types/Address";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  address?: TAdress | null;
  className?: string;
  inline?: boolean;
  customEmptyText?: string;
};

const Container = tw.div``;
const Label = tw.div`${({ $inline }: { $inline?: boolean }) =>
  $inline && "inline"}`;

export const Address: FC<Props> = ({
  address,
  className,
  inline,
  customEmptyText,
}) => {
  if (!address) {
    return <FormattedMessage id={customEmptyText || "NO_ADDRESS"} />;
  }
  return (
    <Container className={className}>
      <Label $inline={inline}>{`${address?.addressLine1.trim()},`}</Label>
      <If isTrue={Boolean(address?.addressLine2)}>
        <Label $inline={inline}>{` ${address?.addressLine2},`}</Label>
      </If>
      <Label
        $inline={inline}
      >{` ${address?.city}${address.state || address.postalCode ? `, ${address?.state} ${address?.postalCode}` : ""}, ${address.country}`}</Label>
    </Container>
  );
};
