import { If } from "@/common/components/if/If";
import { useAllItemsCheckboxSelection } from "@/common/components/pagination/hooks/useAllItemsCheckboxSelection";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import {
  AuthorizationStatus,
  InvoiceSummaryFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceSequence } from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";

const Container = tw.div`
  flex -ml-2
`;

export const InvoiceAllItemsCheckbox: FC = () => {
  const { sequenceIds, setSelectedInvoices, selectedInvoices } =
    useInvoiceSequence();
  const { invoices } = useInvoices();
  const filterItems = useCallback(
    (invoice: InvoiceSummaryFieldsFragment) =>
      invoice.permissions.export === AuthorizationStatus.Authorized,
    [],
  );
  const { checked, handleChange } = useAllItemsCheckboxSelection(
    invoices,
    selectedInvoices,
    setSelectedInvoices,
    filterItems,
  );

  return (
    <Container>
      <If isTrue={invoices.some(filterItems)}>
        <SelectionCheckbox
          items={sequenceIds}
          setSelection={handleChange}
          checked={checked}
        />
      </If>
    </Container>
  );
};
