import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { OrderTypeFieldsFragment } from "@/generated/graphql";
import {
  DeleteOutline,
  EditOutlined,
  UnarchiveOutlined,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderTypes } from "../hooks/useOrderTypes";
import { useOrderTypesActions } from "../hooks/useOrderTypesActions";
import { EditOrderTypeId } from "./edit-order-type/ToggleEditOrderType";

type Props = {
  orderType: OrderTypeFieldsFragment;
};
const IconButtonContainer = tw.div`flex flex-row-reverse w-full gap-1`;

export const OrderTypeActions: FC<Props> = ({ orderType }: Props) => {
  const { archiveOrderType, unArchiveOrderType, unarchiving, archiving } =
    useOrderTypesActions();
  const intl = useIntl();
  const { orderTypes } = useOrderTypes();
  const { openDialog } = useDialog();
  const { toggle } = useGlobalDrawer();

  const onEdit = useCallback(() => {
    toggle(EditOrderTypeId, true, orderType.id);
  }, [orderType.id, toggle]);

  const onRemove = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "REMOVE" }),
      includeWarningIcon: true,
      title: intl.$t({ id: "REMOVE_ORDER_TYPE" }),
      text: intl.$t({ id: "REMOVE_ORDER_TYPE_WARNING" }),
      handleConfirm: () => archiveOrderType(orderType.id),
    });
  }, [archiveOrderType, intl, openDialog, orderType.id]);

  const icons = useMemo(() => {
    if (!orderType.archivedAt) {
      const actions = [];

      if (orderTypes.length > 1) {
        actions.push(
          <IconButtonBorderless
            key={2}
            onClick={() => onRemove()}
            className="col-start-3"
            loading={archiving}
          >
            <DeleteOutline />
          </IconButtonBorderless>,
        );
      }

      actions.push(
        <IconButtonBorderless key={1} onClick={onEdit} className="col-start-2">
          <EditOutlined />
        </IconButtonBorderless>,
      );
      return actions;
    } else {
      return [
        <IconButtonBorderless
          key={1}
          onClick={() => unArchiveOrderType(orderType.id)}
          loading={unarchiving}
          className="col-start-2"
        >
          <UnarchiveOutlined />
        </IconButtonBorderless>,
      ];
    }
  }, [
    orderType.archivedAt,
    orderType.id,
    onEdit,
    orderTypes.length,
    archiving,
    onRemove,
    unarchiving,
    unArchiveOrderType,
  ]);

  return <IconButtonContainer>{icons.map((icon) => icon)}</IconButtonContainer>;
};
