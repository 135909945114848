import { CheckButton } from "@/common/components/button/CheckButton";
import { DiscardButton } from "@/common/components/button/DiscardButton";
import { If } from "@/common/components/if/If";
import {
  TextField,
  TextFieldProps,
} from "@/common/components/textfield/TextField";
import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { getFileName } from "@/common/components/upload-asset/getFileName";
import { ATTACHMENTS_MIME_TYPES } from "@/common/components/upload/FileUploadArea";
import { Base } from "@/common/layout/ResponsiveClasses";
import { AssetFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { AssetDate } from "../asset-date/AssetDate";
import { AssetItem } from "../asset-item/AssetItem";
import { UploadAsset } from "../upload-asset/UploadAsset";

const Container = tw.div`grid col-span-full lg:grid-cols-[2fr_minmax(500px,_1fr)_auto] flex-1 items-center gap-4 p-2 rounded-md bg-blue-100 border border-gray-400`;
const TextFieldStyled = tw(TextField)<{
  staticText: TextFieldProps;
}>`${({ staticText }) => !staticText && "bg-white"} flex-full flex ml-7`;

const TextFieldContainer = tw.div`w-full`;
const UploadContainer = tw.div`min-w-80 grid grid-flow-col gap-2 justify-start items-center`;
const ActionsWrapper = tw(Base)`lg:basis-56 justify-end`;
const Attachments = tw.div`grid gap-2 grid-cols-3 items-center`;

type ItemWithNotes = {
  id: string;
  notes?: string | null;
  assets?: AssetFieldsFragment[];
  expirationDate?: number | null;
};

type Props = {
  item: ItemWithNotes;
  readonly: boolean;
  closeItem: () => void;
  saveItem?: (notes: string, assets?: AssetFieldsFragment[]) => void;
  labelKey?: string;
};

export const InlineNotesAndAttachments: FC<Props> = ({
  item,
  readonly,
  saveItem,
  closeItem,
  labelKey,
}) => {
  const intl = useIntl();
  const [notes, setNotes] = useState(item.notes || "");
  const { assets, removeAsset } = useUploadAssets();

  const getImageDetails = useCallback((url: string, createDate?: number) => {
    return <AssetDate date={createDate} className="mt-1" />;
  }, []);

  return (
    <Container>
      <TextFieldContainer>
        <If isTrue={!readonly || notes}>
          <TextFieldStyled
            value={notes}
            size="small"
            multiline
            label={intl.$t({ id: labelKey || "INLINE_NOTES" })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setNotes(event.target.value)
            }
            staticText={readonly}
            onClickEnter={() => {
              saveItem?.(notes, assets);
            }}
          />
        </If>
      </TextFieldContainer>
      <UploadContainer>
        <If isTrue={!readonly}>
          <UploadAsset
            includeAssets={false}
            accept={ATTACHMENTS_MIME_TYPES}
            mode="horizontal"
            classes={{
              container: "bg-white",
              dndContainer: "py-2",
              uploadContainer: {
                container: "px-2",
                image: "w-10 h-10",
                text: "w-24 text-xs",
              },
            }}
          />
        </If>
        <Attachments>
          {assets?.map((asset, index) => (
            <AssetItem
              key={getFileName(asset.url)}
              asset={asset}
              readonly={readonly}
              small
              remove={removeAsset}
              imageDetails={getImageDetails}
              index={index}
            />
          ))}
        </Attachments>
      </UploadContainer>
      <If isTrue={!readonly}>
        <ActionsWrapper>
          <CheckButton
            onClick={() => {
              saveItem?.(notes, assets);
            }}
          />
          <DiscardButton onClick={closeItem} />
        </ActionsWrapper>
      </If>
    </Container>
  );
};
