import { CountType } from "@/common/components/circle-counter/CountType";
import { useGetProjectItemFromSku } from "@/common/hooks/useGetProjectItem";
import { isEmptyString } from "@/common/utils/validationUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useVendorPrice } from "@/contractor/pages/home/release/hooks/useVendorPrice";
import { AddOrgMaterialWithCounter } from "@/contractor/pages/home/rfq/components/add-org-sku/AddOrgMaterialWithCounter";
import {
  BuyoutItemFieldsFragment,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";
import { BuyoutItemMaterialView } from "./BuyoutItemMaterialView";

type Props = {
  item: BuyoutItemFieldsFragment;
  index?: number;
  readonly: boolean;
  count: CountType;
  error?: boolean;
};

export const BuyoutItemMaterialEditableView = (props: Props) => {
  const { newBuyoutItem, setNewBuyoutItem } = useBuyoutGrouped();
  const { updateBuyout, buyout } = useContractorBuyout();
  const { getPrice } = useVendorPrice();
  const { materials } = useMaterials();
  const getProjectItem = useGetProjectItemFromSku();

  const updateDescription = useCallback(
    async (description: string) => {
      await updateBuyout({
        updates: [
          {
            buyoutItemId: props.item.id,
            description,
          },
        ],
      });
    },
    [updateBuyout, props.item.id],
  );

  return !props.item.id ? (
    <AddOrgMaterialWithCounter
      count={props.count}
      setSelectedOrgMaterial={(material) => {
        if (material) {
          const orgMaterial = materials.find(
            (m) => m.material.id === material.material.id,
          );
          setNewBuyoutItem({
            ...newBuyoutItem,
            projectItem: {
              ...newBuyoutItem.projectItem,
              ...getProjectItem(
                material as OrgMaterialFieldsFragment,
                material.defaultEstimateUom?.pluralDescription || "",
              ),
              estimateUom: material.defaultEstimateUom?.pluralDescription || "",
            },
            description: material.material.name,
            orgCatalogSkuName: !material.id
              ? material.material.name
              : undefined,
            uomId: material.defaultEstimateUom?.id || "",
            costCodeId: material.costCode?.id || "",
            requestedUnitPrice:
              getPrice(
                orgMaterial?.id || "",
                orgMaterial?.defaultEstimateUom.id || "",
                buyout?.sellerOrgLocation?.id,
                newBuyoutItem.manufacturerId || "",
              ) || undefined,
          });
        }
      }}
      error={props.error && isEmptyString(newBuyoutItem.description)}
    />
  ) : (
    <BuyoutItemMaterialView {...props} updateDescription={updateDescription} />
  );
};
