import { Select } from "@/common/components/select/components/single/Select";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";

const Container = tw.div`
  grid grid-flow-col items-center gap-2 p-0.5 px-1
`;

type Props = {
  setLoading: (loading: boolean) => void;
  update: (code: string) => Promise<void>;
  count: number;
};

export const BulkPhaseCodeSelector: FC<Props> = ({
  setLoading,
  update,
  count,
}) => {
  const { phaseCodes } = useProjectStore(
    useShallow((state) => ({
      phaseCodes: state.phaseCodes,
    })),
  );
  const intl = useIntl();
  const [phaseCodeId, setPhaseCodeId] = useState<string | null>(null);

  const updatePhaseCode = useCallback(
    async (code: string | null) => {
      if (!code) {
        return;
      }
      setPhaseCodeId(code);
      setLoading(true);
      await update(code);
      setLoading(false);
    },
    [setLoading, update],
  );

  return (
    <Container>
      <FormattedMessage id="ASSIGN_PHASE_CODE_WITH_NUM" values={{ count }} />
      <Select
        testId="phase-code-bulk-selector"
        options={phaseCodes || []}
        className="w-72"
        value={phaseCodeId}
        label={intl.$t({ id: "SELECT_PHASE_CODE" })}
        InputLabelProps={{
          classes: {
            focused: "text-transparent",
          },
        }}
        onChange={(value) => updatePhaseCode(value as string)}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        noOptionsText={intl.$t({ id: "NO_COST_CODES_FOUND" })}
      />
    </Container>
  );
};
