import { NativePdfViewer } from "@/common/components/asset-viewer/NativePdfViewer";
import { If } from "@/common/components/if/If";
import { getFileName } from "@/common/components/upload-asset/getFileName";
import { Attachment } from "@mui/icons-material";
import { ErrorBoundary } from "@sentry/react";
import { FC } from "react";
import { isSafari } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { PdfViewer } from "../../../../../../../../../common/components/asset-viewer/PdfViewer";

const Container = tw.div`h-full`;
const Header = tw.div`flex flex-col px-5 pt-5`;
const Title = tw.div`text-base font-medium`;
const File = tw.div`flex items-center text-gray-600`;
const AttachmentIcon = tw(Attachment)``;
const Content = tw.div`group h-[calc(100%-84px)] overflow-scroll relative`;

type Props = {
  url: string;
  page: number;
};

export const DocumentViewer: FC<Props> = ({ url, page }) => {
  return (
    <Container>
      <Header>
        <FormattedMessage id="INVOICE_HEADER_WIZARD" tagName={Title} />
        <File>
          <AttachmentIcon />
          {getFileName(url)}
        </File>
      </Header>
      <Content>
        <If isTrue={!isSafari}>
          <ErrorBoundary
            fallback={
              <p>
                <FormattedMessage id="FAILED_TO_LOAD_PDF" />
              </p>
            }
          >
            <PdfViewer url={url} page={page} />
          </ErrorBoundary>
        </If>
        <If isTrue={isSafari}>
          <NativePdfViewer url={url} />
        </If>
      </Content>
    </Container>
  );
};
