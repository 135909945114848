import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { defaultTableItemSelectionClassNames } from "@/common/components/searchable-list/utils";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { routes } from "@/config/routes";
import { BuyoutStatus, BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { useBuyoutsConfiguration } from "./components/Buyouts.configuration";
import { BuyoutsHeader } from "./components/BuyoutsHeader";
import { useBuyoutSequence } from "./hooks/useBuyoutSequence";
import { BuyoutsProvider, useBuyouts } from "./providers/BuyoutsProvider";

const BuyoutsWithProvider = () => {
  const intl = useIntl();

  const { buyouts, loading, totalCount, isFiltered } = useBuyouts();
  const navigate = useNavigate();
  const {
    sequenceEnabled,
    selectedEntities,
    setSelectedEntities,
    sequenceIds,
  } = useBuyoutSequence();

  const gotoBuyout = (item: BuyoutsBuyoutFieldsFragment) => {
    if (sequenceEnabled) {
      const isIncluded = sequenceIds.includes(item.id);
      setSelectedEntities(
        !isIncluded
          ? [...selectedEntities, item]
          : selectedEntities.filter((d) => d.id !== item.id),
      );
    } else if (item.quoteDocument?.id && item.status === BuyoutStatus.Draft) {
      navigate(
        generatePath(routes.editBuyoutFromQuote, {
          quoteDocumentId: item.quoteDocument.id,
          buyoutId: item.id,
        }),
      );
    } else {
      navigate(generatePath(routes.buyout, { id: item.id }));
    }
  };

  const configuration = useBuyoutsConfiguration({
    includeCheckboxes: sequenceEnabled,
  });

  const itemFn = useCallback(
    (item: BuyoutsBuyoutFieldsFragment) => {
      if (item.deletedAt) {
        return {
          className: "bg-gray-300 opacity-50",
        };
      }
      if (sequenceEnabled && sequenceIds.includes(item.id)) {
        return { className: defaultTableItemSelectionClassNames };
      }
      return { className: "" };
    },
    [sequenceEnabled, sequenceIds],
  );

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "BUYOUTS" })}</title>
      </Helmet>
      <BuyoutsHeader />
      <ListRenderer totalCount={totalCount} count={buyouts.length} paginated>
        <GridTable
          configuration={{
            columns: configuration,
            classNames: {
              header: "top-[90px]",
              root: "pt-2",
              itemFn,
            },
            toggle: {
              item: gotoBuyout,
            },
            hasCardItems: true,
          }}
          items={buyouts}
          loading={loading}
          emptyList={
            <NoResults
              isFiltered={isFiltered}
              filteredTranslationKey="BUYOUT_LIST_NO_BUYOUTS_FILTERED"
              translationKey="BUYOUT_LIST_NO_BUYOUTS"
            />
          }
        />
      </ListRenderer>
    </>
  );
};

export const Buyouts = () => (
  <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
    <BuyoutsProvider>
      <BuyoutsWithProvider />
    </BuyoutsProvider>
  </PaginationProvider>
);
