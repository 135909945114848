import { Search } from "@mui/icons-material";
import { FaSort, FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa";
import tw from "tailwind-styled-components";

export const PingEffect = tw.span`absolute left-[50%] inline-flex h-2 w-10 animate-ping bg-gray-400 opacity-75 z-0`;

export const SearchContainer = tw.span`flex flex-1 justify-end mr-[5%]`;
export const SearchStyled = tw(Search)`hover:text-blue-500 cursor-pointer`;

export const SortContainer = tw.span`ml-0.5 w-3 text-center`;
export const SortDesc = tw(
  FaSortAmountDown,
)`hover:text-blue-500 cursor-pointer group-hover:text-blue-500`;
export const SortAsc = tw(
  FaSortAmountDownAlt,
)`hover:text-blue-500 cursor-pointer group-hover:text-blue-500`;
export const Sort = tw(
  FaSort,
)`hover:text-blue-500 cursor-pointer group-hover:text-blue-500`;

export const Header = tw.div<{
  $sortable?: boolean;
}>` flex flex-row items-center 
  ${({ $sortable }) => $sortable && "hover:text-blue-500 cursor-pointer group"}
`;
