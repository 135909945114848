import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { LUMP_SUM_UOM } from "@/common/const";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { routes } from "@/config/routes";
import {
  ProjectReportReleasesFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { AccessTime, Check } from "@mui/icons-material";
import { FC } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useSpendingReportValuesGroupedByUom } from "../../hooks/useSpendingReportValuesGroupedByUom";

const Container = tw.div`grid px-5 grid-cols-[2fr_1fr_1fr_1px_1fr_1fr] items-start `;
const StyledCheck = tw(Check)`w-5 mr-1.5`;
const StyledClock = tw(AccessTime)`w-5 mr-1.5`;
const DeliveryNumber = tw.div`font-medium text-sm`;
const Row = tw.div`flex h-16 items-center`;
const VendorImage = tw.div`flex flex-row items-center mr-2`;
const OrderDetailsContainer = tw.div`flex flex-col gap-y-0.5`;
const IconContainer = tw.div`flex flex-row items-center`;
const DeliveryDate = tw.div`flex flex-row items-center font-normal text-xs`;
const OrderLinksContainer = tw.div`flex flex-row items-center gap-x-2`;
const BuyoutNumber = tw.div` text-xs font-normal`;
const EmptyIconView = tw.div`w-5 mr-2`;
const StyledPrice = tw(
  Price,
)`text-sm font-medium items-center flex justify-center h-16`;
const ItemWrapper = tw.div`h-16 items-center justify-center flex`;
const StyledQuantity = tw(
  ValueUnit,
)`text-center text-sm font-medium h-16 bg-red-500`;
const Separator = tw.div`w-[1px] h-[70%] bg-gray-100`;
const Col = tw.div`flex flex-col`;
const SeparatorCol = tw.div`flex h-full items-center justify-center`;

const getIconForReleaseStatus = (releaseStatus: ReleaseStatus) => {
  switch (releaseStatus) {
    case ReleaseStatus.Scheduled:
    case ReleaseStatus.Requested:
      return <StyledClock />;
    case ReleaseStatus.Received:
    case ReleaseStatus.PartiallyReceived:
      return <StyledCheck />;
    default:
      return <EmptyIconView />;
  }
};

const getTimeForReleaseStatus = (item: ReleaseReportItem) => {
  switch (item.release.status) {
    case ReleaseStatus.Received:
    case ReleaseStatus.PartiallyReceived:
    case ReleaseStatus.Scheduled:
    case ReleaseStatus.Requested:
      return item.release.time;
    default:
      return null;
  }
};

export type ReleaseReportItem =
  ProjectReportReleasesFieldsFragment["elements"][number];

type ReleaseItemProps = {
  item: ReleaseReportItem;
  gross: boolean;
  includeVendorHeader?: boolean;
  withBorder?: boolean;
  costCodeId?: string;
};

export const ReportModalReleaseItem: FC<ReleaseItemProps> = ({
  item,
  gross,
  withBorder,
  includeVendorHeader,
  costCodeId,
}) => {
  const time = getTimeForReleaseStatus(item);
  const valuesGroupedByUom = useSpendingReportValuesGroupedByUom(
    item,
    gross,
    costCodeId,
  );

  return (
    <Container className={withBorder ? "border-b border-gray-100" : ""}>
      <Row>
        <If isTrue={includeVendorHeader}>
          <VendorImage>
            <VendorLogo
              logoImageUrl={item.release.sellerOrgLocation?.org.photoUrl}
              orgName={item.release.sellerOrgLocation?.org.name as string}
              address={item.release.sellerOrgLocation?.address}
            />
          </VendorImage>
        </If>
        <IconContainer>
          {getIconForReleaseStatus(item.release.status)}
        </IconContainer>
        <OrderDetailsContainer>
          <OrderLinksContainer>
            <LinkLike
              target="_blank"
              to={generatePath(routes.delivery, {
                deliveryId: item.release.id,
              })}
            >
              <FormattedMessage
                id="DELIVERY_WITH_NUMBER"
                values={{ number: item.release.sequenceNumber }}
                tagName={DeliveryNumber}
              />
              {item.release.buyout ? ", " : ""}
            </LinkLike>
            {item.release.buyout ? (
              <LinkLike
                target="_blank"
                to={generatePath(routes.buyout, {
                  id: item.release.buyout.id,
                })}
              >
                <FormattedMessage
                  id="BUYOUT"
                  values={{
                    number: item.release.buyout.clientIdentifier,
                  }}
                  tagName={BuyoutNumber}
                />
              </LinkLike>
            ) : null}
          </OrderLinksContainer>
          <If isTrue={time}>
            <DeliveryDate>
              <FormattedDate value={time ?? undefined} />
            </DeliveryDate>
          </If>
        </OrderDetailsContainer>
      </Row>
      <Col>
        {valuesGroupedByUom.map((value, index) => (
          <ItemWrapper key={index}>
            <NotNullableRenderer
              defaultValue={LUMP_SUM_UOM}
              value={!isLumpSumItem(value)}
            >
              <StyledQuantity value={value.orderedQuantity} uom={value.uom} />
            </NotNullableRenderer>
          </ItemWrapper>
        ))}
      </Col>
      <Col>
        {valuesGroupedByUom.map((value, key) => (
          <ItemWrapper key={key}>
            <LinkLike
              target="_blank"
              to={generatePath(routes.delivery, {
                deliveryId: item.release.id,
              })}
            >
              <StyledPrice price={value.orderedTotal} />
            </LinkLike>
          </ItemWrapper>
        ))}
      </Col>
      <SeparatorCol>
        <Separator />
      </SeparatorCol>
      <Col>
        {valuesGroupedByUom.map((value, index) => (
          <ItemWrapper key={index}>
            <NotNullableRenderer
              defaultValue={LUMP_SUM_UOM}
              value={!isLumpSumItem(value)}
            >
              <StyledQuantity value={value.invoicedQuantity} uom={value.uom} />
            </NotNullableRenderer>
          </ItemWrapper>
        ))}
      </Col>
      <Col>
        {valuesGroupedByUom.map((value, key) => (
          <ItemWrapper key={key}>
            {value.invoicedTotal && item.release.invoices.length ? (
              <LinkLike
                target="_blank"
                to={generatePath(routes.invoiceVerification, {
                  invoiceId: item.release.invoices[0].id,
                })}
              >
                <StyledPrice price={value.invoicedTotal} />
              </LinkLike>
            ) : (
              <StyledPrice price={value.invoicedTotal} />
            )}
          </ItemWrapper>
        ))}
      </Col>
    </Container>
  );
};
