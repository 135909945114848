import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  SourceSystem,
  useExternalPaymentMethodsQuery,
} from "@/generated/graphql";

export const useExternalPaymentMethods = (
  defaultSourceSystem?: SourceSystem,
) => {
  const { connectedSourceSystem } = useOrgSettings();
  const {
    data,
    loading: loadingExternalPaymentMethods,
    error: externalPaymentMethodsError,
  } = useExternalPaymentMethodsQuery({
    variables: {
      sourceSystem:
        defaultSourceSystem ?? connectedSourceSystem ?? SourceSystem.Foundation,
    },
    skip: !defaultSourceSystem && !connectedSourceSystem,
  });

  useErrorEffect(externalPaymentMethodsError);

  return {
    externalPaymentMethods: data?.externalBankAccounts ?? [],
    loadingExternalPaymentMethods,
    externalPaymentMethodsError,
  };
};
