import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DateView } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useReceipt } from "../../../providers/ReceiptProvider";

const LinkLikeStyled = tw(LinkLike)`flex items-center gap-1 text-blue-500`;
const Container = tw.div`flex gap-1 mb-5`;
const PoNumberContainer = tw.div`grid grid-flow-col items-center gap-1`;
const InnerContainer = tw.div`flex flex-col gap-0.5`;
const DetailsGroup = tw.div`flex flex-row gap-1 text-xs`;
const Label = tw.div`font-medium`;
const Name = tw.div``;
const PoContainer = tw.div`flex flex-row items-center gap-2`;

export const ReceiptOrderInputSelector: FC<PropsWithChildren> = ({
  children,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { receipt } = useReceipt();

  const onReleaseClick = useCallback(() => {
    if (receipt?.release?.id) {
      navigate(
        generatePath(routes.delivery, { deliveryId: receipt.release.id }),
      );
    }
  }, [receipt?.release?.id, navigate]);

  const value = useMemo(() => {
    if (receipt?.release) {
      return (
        <>
          {intl.$t(
            { id: "ORDER_WITH_NUMBER" },
            { orderNumber: receipt?.release?.sequenceNumber },
          )}
        </>
      );
    }
    return null;
  }, [receipt, intl]);

  return (
    <Container>
      <If isTrue={value}>
        <InnerContainer>
          <PoNumberContainer>
            <LinkLikeStyled onClick={onReleaseClick}>{value}</LinkLikeStyled>
            {children}
          </PoNumberContainer>
          <DetailsGroup>
            <Label>
              <FormattedMessage id="DELIVERY" />:
            </Label>
            <Name>
              <DateView date={receipt?.release?.time} />
            </Name>
          </DetailsGroup>
          <DetailsGroup>
            <Label>
              <FormattedMessage id="ORDER_TYPE" />:
            </Label>
            <Name>{receipt?.release?.type.name}</Name>
          </DetailsGroup>
          <If isTrue={receipt?.release?.poNumber}>
            <PoContainer>
              <FormattedMessage id="PO_HASH" />
              {receipt?.release?.poNumber}
            </PoContainer>
          </If>
        </InnerContainer>
      </If>
    </Container>
  );
};
