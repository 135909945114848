import { CostTypeFieldsFragment } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useCostTypes } from "../hooks/useCostTypes";
import { useUpdateCostTypes } from "../hooks/useUpdateCostTypes";

export type CostType = CostTypeFieldsFragment & {
  isSubmitted: boolean;
};

type ProviderContextType = {
  initialTypes: CostTypeFieldsFragment[];
  costTypes: CostType[];
  setCostTypes: (costTypes: CostType[]) => void;
  loading: boolean;
  addEmptyCostType: () => void;
  updateTypeEditState: (id: string, inEditMode: boolean) => void;
  saveCostType: (id: string) => void;
  deleteCostType: (id: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  initialTypes: [],
  costTypes: [],
  setCostTypes: NoFunction,
  loading: false,
  addEmptyCostType: NoFunction,
  updateTypeEditState: NoFunction,
  saveCostType: NoFunction,
  deleteCostType: NoFunction,
});

export const CostTypesListProvider: FC<{
  children: React.ReactNode;
  includeUnassigned?: boolean;
}> = ({ children, includeUnassigned = false }) => {
  const [types, setTypes] = useState<CostType[]>([]);
  const { costTypes, loading } = useCostTypes();
  const { updateCostTypes } = useUpdateCostTypes();

  useEffect(() => {
    setTypes(
      costTypes.map((type) => ({
        ...type,
        description: type.description.trim(),
        type: type.code.trim(),
        isSubmitted: false,
      })),
    );
  }, [includeUnassigned, costTypes]);

  const addEmptyCostType = () => {
    if (types.find((c) => c.id === "")) {
      return;
    }
    setTypes([
      {
        id: "",
        code: "",
        description: "",
        isSubmitted: false,
        mappings: [],
        org: { id: "" },
      },
      ...types,
    ]);
  };

  const updateTypeEditState = (id: string, inEditMode: boolean) => {
    const type = types.find((c) => c.id === id);
    if (type) {
      if (!inEditMode && !type.code && !type.description) {
        setTypes(types.filter((c) => c.id !== id));
      } else {
        setTypes(
          types.map((c) => (c.id === type.id ? { ...c, inEditMode } : c)),
        );
      }
    }
  };

  const saveCostType = async (id: string) => {
    const type = types.find((c) => c.id === id);
    if (type) {
      if (type.id) {
        await updateCostTypes({
          updates: [
            {
              id: type.id,
              code: type.code,
              description: type.description,
            },
          ],
        });
      } else {
        await updateCostTypes({
          addedCostTypes: [
            {
              code: type.code,
              description: type.description,
            },
          ],
        });
      }
    }
  };

  const deleteCostType = async (id: string) => {
    await updateCostTypes({
      removedCostTypes: [id],
    });
    setTypes(types.filter((c) => c.id !== id));
  };

  return (
    <ProviderContext.Provider
      value={{
        initialTypes: costTypes || [],
        costTypes: types,
        setCostTypes: setTypes,
        loading,
        addEmptyCostType,
        updateTypeEditState,
        saveCostType,
        deleteCostType,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useCostTypesList = (): ProviderContextType =>
  useContext(ProviderContext);
