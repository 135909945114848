import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  BuyoutDocument,
  ReleaseDocument,
  useUnlinkPoMutation,
} from "@/generated/graphql";

export const useUnlinkPo = () => {
  const [unlinkPoMutation] = useUnlinkPoMutation();
  const { setError } = useGlobalError();

  const unlinkPo = async (
    poLinkId?: string,
    releaseId?: string,
    buyoutId?: string,
  ) => {
    if (poLinkId) {
      try {
        const { data, errors } = await unlinkPoMutation({
          variables: {
            poLinkId,
          },
          refetchQueries: [
            ...(releaseId
              ? [{ query: ReleaseDocument, variables: { id: releaseId } }]
              : []),
            ...(buyoutId
              ? [{ query: BuyoutDocument, variables: { id: buyoutId } }]
              : []),
          ],
        });
        setError(errors);

        return !!data?.unlinkPO;
      } catch (error) {
        setError(error);
        return false;
      }
    }
  };

  return { unlinkPo };
};
