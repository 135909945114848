import { If } from "@/common/components/if/If";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  item: BuyoutsBuyoutFieldsFragment;
};

const Container = tw.div`grid flex-col text-sm w-full`;
const NumberContainer = tw.div`font-bold`;
const DateContainer = tw.div`font-thin`;
const Description = tw.span`truncate text-[10px] font-normal text-gray-600 leading-[12px]`;

export const BuyoutNo: FC<Props> = ({ item }) => {
  return (
    <Container>
      <NumberContainer>{item.clientIdentifier}</NumberContainer>
      <DateContainer>
        <DateView date={item.createdAt} options={SHORT_DATE_OPTION} />
      </DateContainer>
      <If isTrue={item?.description}>
        <Description>{item.description}</Description>
      </If>
    </Container>
  );
};
