import {
  FooterCell,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterItemInputContainer,
  FooterPropsWithTaxes,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { FC } from "react";

export const BuyoutEmptyFooter: FC<FooterPropsWithTaxes> = ({
  Wrapper,
  includeAdditionalCharges,
}) => {
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  if (!canViewPrices) {
    return null;
  }
  return (
    <FooterCol>
      <Wrapper className="flex-col">
        <If isTrue={includeAdditionalCharges}>
          <FooterItemInputContainer />
        </If>
        <FooterHeader />
        <FooterCell />
        <FooterCell />
        <FooterDashedLine />
        <FooterTotal />
      </Wrapper>
    </FooterCol>
  );
};
