import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import {
  InstructionInput,
  TaxType,
  UpdateChargeInput,
} from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

export type InvoiceCreateReleaseFormValues = {
  projectId: string;
  fulfillmentLocationId: string;
  vendorId: string;
  poNumber: string | null;
  orderDate: Date | null | undefined;
  orderTypeId: string;
  costCodeId: string;
  phaseCodeId: string;
  netAmount: number;
  paymentTerm: string | undefined;
  additionalCharges: UpdateChargeInput[] | undefined;
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  total: string | undefined;
  taxType: TaxType | undefined;
  taxCodeId: string | undefined;
  taxVariance: string | undefined | null;
  instructions: InstructionInput | undefined;
  description?: string;
  timeTBD: boolean;
} & ExternalPOsQueryInputType;

type InvoiceCreateReleaseForm = UseFormReturn<
  InvoiceCreateReleaseFormValues,
  unknown
>;

export const invoiceCreateReleaseFormDefaultValues: InvoiceCreateReleaseFormValues =
  {
    projectId: "",
    fulfillmentLocationId: "",
    vendorId: "",
    poNumber: null,
    orderDate: null,
    orderTypeId: "",
    netAmount: 0,
    total: undefined,
    taxRate: undefined,
    taxVariance: undefined,
    customTaxAmount: undefined,
    additionalCharges: undefined,
    costCodeId: "",
    phaseCodeId: "",
    paymentTerm: "",
    taxCodeId: undefined,
    taxType: undefined,
    sellerOrgLocationID: null,
    search: null,
    minDate: null,
    maxDate: null,
    instructions: {
      text: "",
      assetUrls: [],
    },
    description: "",
    timeTBD: false,
  };

export const InvoiceVerificationForm: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const invoiceCreateReleaseForm: InvoiceCreateReleaseForm =
    useForm<InvoiceCreateReleaseFormValues>({
      defaultValues: {
        ...invoiceCreateReleaseFormDefaultValues,
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  return <FormProvider {...invoiceCreateReleaseForm}>{children}</FormProvider>;
};
