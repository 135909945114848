import { If } from "@/common/components/if/If";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { CurrencyDisplay } from "./CurrencyDisplay";

const Container = tw.div`
  grid justify-items-end
`;

type Props = {
  item:
    | {
        allowance?: string | null | undefined;
        estimated?: string | null | undefined;
        unallotted?: string | null | undefined;
      }
    | null
    | undefined;
  overall?: boolean;
  className?: string;
};

export const ReportItemDisplay: FC<Props> = ({ item, overall, className }) => {
  if (!item) {
    return null;
  }
  return (
    <Container>
      <If isTrue={item.allowance}>
        <CurrencyDisplay
          value={item.allowance}
          info={overall ? "OVERALL_BUDGET_ALLOWANCE" : "ALLOWANCE"}
          className={className}
        />
      </If>
      <If isTrue={!item.allowance}>
        <CurrencyDisplay value={item.estimated} info="SUM_OF_ESTIMATED_ITEMS" />
      </If>
    </Container>
  );
};
